import './toggle-mode.css';
import { ChangeEventHandler, useEffect } from 'react';
import { useThemeMode } from 'components/common/AppStoreType';
import sun_darkmode from '../../assets/icon/sun_darkmode.png';
import sun_lightmode from '../../assets/icon/sun_lightmode.png';
import moon_darkmode from '../../assets/icon/moon_darkmode.png';
import moon_lightmode from '../../assets/icon/moon_lightmode.png';

const ToggleLightMode = () => {
  const { isDarkMode, lightMode, darkMode } = useThemeMode();
  const setDark = () => {
    localStorage.setItem('theme', 'dark');
    document.documentElement.setAttribute('data-theme', 'dark');
    darkMode();
  };

  const setLight = () => {
    localStorage.setItem('theme', 'light');
    document.documentElement.setAttribute('data-theme', 'light');
    lightMode();
  };

  const toggleTheme: ChangeEventHandler<HTMLInputElement> = (e) => {
    alert('Coming soon');
    return;
    if (e.target.checked) {
      setDark();
    } else {
      setLight();
    }
  };
  return (
    <div className="toggle-theme-wrapper">
      {/* <span>☀️</span> */}
      <label className="toggle-theme" htmlFor="checkbox">
        <input
          type="checkbox"
          id="checkbox"
          onChange={toggleTheme}
          // defaultChecked={darkMode}
          checked={isDarkMode}
        />
        <div className="slider-mode round">
          {isDarkMode ? (
            <img src={sun_darkmode} alt="sun" className="icon-thememode" />
          ) : (
            <img src={sun_lightmode} alt="sun" className="icon-thememode" />
          )}
          {isDarkMode ? (
            <img src={moon_darkmode} alt="moon" className="icon-thememode" />
          ) : (
            <img src={moon_lightmode} alt="moon" className="icon-thememode" />
          )}
        </div>
      </label>
      {/* <span>🌒</span> */}
    </div>
  );
};

export default ToggleLightMode;
