export const getPriceRecentlyListedItems = (price: number) => {
  if (price > 0) {
    const parts = price.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${parts.join('.')}`;
  }
  const priceList = price?.toString()?.split('.') || [];
  let priceLength;
  if (priceList?.length > 1) priceLength = priceList[1]?.length;
  if (!Number.isInteger(price) && price > 0)
    return priceLength > 7 ? `${price.toFixed(7)}` : `${price.toFixed(priceLength)}`;
  else return 'FREE';
};
