import React, { useEffect, useState } from 'react';
import { getCollectionListInfi } from '../../services/services';
import SearchBar from 'components/searchbar/SearchBar';
import { MBoxTypes } from '../../types/MBoxTypes';
import { Link } from 'react-router-dom';
import { getPrice } from 'utils/getPrice';
import { getComma } from 'utils/getComma';
import { getNetworkNameById } from 'utils/getNetworkNameById';
import InfiniteScroll from 'react-infinite-scroll-component';
import useScreenSize from 'components/common/useScreenSize';
import { getPriceRecentlyListedItems } from 'utils/getPriceRecentlyListedItems';
import { HandleMediaError } from 'components/common/HandleMediaError';
type ExMBoxType = MBoxTypes & {
  remainingAmount: number | null;
};
const limit = 100;
const Drops = () => {
  const [collectionList, setCollectionList] = useState<ExMBoxType[]>([]);
  const [keyword, setKeyword] = useState('');
  const [sortBy, setSortBy] = useState('createdAt:DESC');
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const selectList = [
    // { value: 'dropsOpenDatetime:DESC', label: 'Recently listed' },
    { value: 'createdAt:DESC', label: 'Recently listed' },
    { value: 'price:ASC', label: 'Price low to high' },
    { value: 'price:DESC', label: 'Price high to low' },
  ];
  const categoryList = [
    { value: '1', label: 'Category1' },
    { value: '2', label: 'Category2' },
  ];
  //drops
  const fetchCollectionList = async (keyword?: string, sortBy?: string) => {
    const res = await getCollectionListInfi({
      isCollection: true,
      limit: limit,
      keyword,
      sortBy,
      page,
    });
    if (res?.data?.data?.list) {
      const newList = await Promise.all(
        res.data.data.list.map(async (item: MBoxTypes) => {
          const remaining = item.totalAmount! - item.soldAmount!;
          const milliseconds = new Date().getTime() - Date.parse(item.releaseDatetime);
          return {
            ...item,
            remainingAmount: remaining,
            onsale: milliseconds >= 0 ? true : false,
          };
        })
      );
      setCollectionList(newList);
      setTotalRows(res?.data?.data?.headers?.x_pages_count || 0);
    } else {
      setCollectionList([]);
      setTotalRows(res?.data?.data?.headers?.x_pages_count || 0);
    }
  };
  useEffect(() => {
    fetchCollectionList(keyword, sortBy);
  }, [keyword, sortBy]);

  const changePage = async (page) => {
    setPage(page);
    const res = await getCollectionListInfi({ isCollection: true, limit: limit, keyword, sortBy, page });
    if (res?.data?.data?.list) {
      const newList = await Promise.all(
        res.data.data.list.map(async (item: MBoxTypes) => {
          const remaining = item.totalAmount! - item.soldAmount!;
          const milliseconds = new Date().getTime() - Date.parse(item.releaseDatetime);
          return {
            ...item,
            remainingAmount: remaining,
            onsale: milliseconds >= 0 ? true : false,
          };
        })
      );
      setCollectionList([...collectionList, ...newList]);
      setTotalRows(res?.data?.data?.headers?.x_pages_count || 0);
    }
  };
  const screenSize = useScreenSize();

  return (
    <div className="featured-page min-height-content">
      <div className="section-02">
        {/* Featured Collections */}
        <div className="featured-collections padding-bottom-30">
          <div className="wrapper-header title-header">{/* <div className="header-name pb-2">Drops</div> */}</div>
          <div className="p-r-12 p-r-xs-0">
            <SearchBar
              titlePage="Drops"
              onSearch={(search: string) => {
                setPage(1);
                setKeyword(search?.trim());
              }}
              onSelect={(value: string) => {
                setPage(1);
                setSortBy(value);
              }}
              onSelectCategoryAll={() => {
                console.log('all');
              }}
              onSelectCategory={(value: string) => {
                console.log(value);
              }}
              selectList={selectList}
              categoryList={categoryList}
              showFirstTwoSelects={false}
              totalItems={collectionList?.length}
            ></SearchBar>
          </div>
          <InfiniteScroll
            dataLength={collectionList.length}
            next={() => {
              changePage(page + 1);
            }}
            hasMore={page < totalRows}
            loader={<h4>Loading...</h4>}
            className="hot-collectibles w-100 d-grid grid-col-4 grid-col-xs-2 responsive-list-nft"
          >
            {collectionList.map((item: any, index) => {
              return (
                <Link
                  to={
                    item.itemAmount === 1 && item.mysteryboxItems
                      ? `/collection/${item.id}/${item.mysteryboxItems[0]?.id}`
                      : `/collections/${item.id}`
                  }
                  className="button custom-box"
                  key={index}
                >
                  <div className="hot-ollectibles-wrapper">
                    <div className="header-left hot-ollectibles-item">
                      <span className="total-run">Total Items: {getComma(item.totalAmount)}</span>
                      <div className="chain-name">{getNetworkNameById(item.chainId)}</div>
                    </div>
                    {/* <div className="hot-ollectibles-item" style={{ textTransform: 'capitalize' }}>
                      <div>{getNetworkNameById(item.chainId)}</div>
                    </div> */}
                    <div className="hot-ollectibles-item">
                      <div className="img-token">
                        {item?.packageImage?.split('.').pop() === 'mp4' ? (
                          <video
                            playsInline
                            muted
                            controlsList="nodownload"
                            width={'100%'}
                            onError={HandleMediaError}
                            key={item.packageImage
                              ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                              .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}
                          >
                            <source
                              src={item.packageImage
                                ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                                .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}
                              type="video/mp4"
                            />
                          </video>
                        ) : (
                          <img src={item.packageImage} alt="" draggable={false} onError={HandleMediaError} />
                        )}
                        <div className="position-absolute price-and-remaining">
                          <div className="wrapper-price text-center">
                            <div className="price-header color-aaa fs-12 fs-xs-10">Price</div>
                            {/* <div className="current-price fs-14 fs-xs-12 fw-7">
                              {getPrice(item.fiatPrice || item.price, item.fiatPrice ? 'KRW' : item.quote)}
                            </div> */}
                            <div className="fs-14 fw-7">
                              {getPriceRecentlyListedItems(item.fiatPrice || item.price)}
                              {item.fiatPrice !== null ||
                                (item.price !== null && (
                                  <span className="fs-14 fs-xs-10 m-l-4">
                                    {item.fiatPrice
                                      ? 'KRW'
                                      : item.quote?.toUpperCase() === 'ETHEREUM'
                                      ? 'ETH'
                                      : item.quote?.toUpperCase()}
                                  </span>
                                ))}
                            </div>
                          </div>
                          <div className="wrapper-remaining text-center">
                            <div className="remaining-header color-aaa fs-12 fs-xs-10">
                              {item.isSoldOut || !item.remainingAmount ? null : 'Remaining'}
                            </div>
                            <div
                              className={`quantity-remaining fs-14 fs-xs-12 fw-7 ${
                                (item.isSoldOut || !item.remainingAmount) && screenSize < 640 ? 'p-t-12' : ''
                              }`}
                            >
                              {!item.isSoldOut && item.remainingAmount ? item.remainingAmount : 'Sold Out'}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hot-ollectibles-item">
                      <div className="wrapper-item">
                        <div className="content-left">
                          <div className="avatar">
                            <img src={item.featured.company.image} alt="" draggable={false} />
                          </div>
                          <div className="mb-0 mt-0">{item.featured.company.name.en}</div>
                        </div>
                        {/*<div className="content-right">{item.onsale ? 'Buy Now' : 'Waiting'}</div>*/}
                      </div>
                    </div>
                    <div className="hot-ollectibles-item">
                      <div className="product-name">{item.title.en}</div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default Drops;
