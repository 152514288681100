import website_icon from '../../assets/icon/website_icon.svg';
import icon_discord from '../../assets/img/icon_discord.png';
import icon_instagram from '../../assets/img/icon_instagram.png';
import icon_twitter from '../../assets/img/icon_twitter.png';
import icon_discord_black from '../../assets/img/icon_discord_black.png';
import icon_instagram_black from '../../assets/img/icon_instagram_black.png';
import icon_twitter_black from '../../assets/img/icon_twitter_black.png';
import website_icon_black from '../../assets/icon/website_icon_black.svg';
import { useThemeMode } from 'components/common/AppStoreType';
import icon_share from '../../assets/img/icon_share.png';
import icon_share_black from '../../assets/img/icon_share_black.png';
import useScreenSize from 'components/common/useScreenSize';
import useCopyToClipBoard from 'hooks/useCopyToClipboard';
import CSnackbar from 'components/common/CSnackbar';
import { useEffect, useState } from 'react';

type LinkTypes = {
  type: string;
  url: string;
  useExternalUrl: boolean;
};

type GetSnsButtonsProps = {
  links?: LinkTypes[];
  fromMyItems: boolean;
};
const GetSnsMobileButtons = ({ links = [], fromMyItems }: GetSnsButtonsProps) => {
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    type: '',
    message: '',
  });
  const { isDarkMode } = useThemeMode();
  const screenSize = useScreenSize();
  const { copyToClipBoard, copyResult, setCopyResult } = useCopyToClipBoard();
  const handleCloseSnackbar = () => {
    setOpenSnackbar({
      open: false,
      type: 'info',
      message: '',
    });
    setCopyResult(false);
  };
  useEffect(() => {
    setOpenSnackbar({
      open: copyResult,
      type: 'success',
      message: 'Copied!',
    });
  }, [copyResult]);
  if (links?.length) {
    <ul className="dropdown-box-sns">
      {links?.map((link: LinkTypes, index) => (
        <li className="list-dropdown-item" key={index}>
          <button className="dropdown-item-nft  button">
            <a href={link.url} target="_blank" className="custom-link-sns">
              <div className="image-sns">
                {link.type === 'SITE' &&
                  (isDarkMode ? (
                    <img src={website_icon} alt="Website Icon" />
                  ) : (
                    <img src={website_icon_black} alt="Website Icon" />
                  ))}
                {link.type === 'DISCORD' &&
                  (isDarkMode ? (
                    <img src={icon_discord} alt="Website Icon" />
                  ) : (
                    <img src={icon_discord_black} alt="Website Icon" />
                  ))}
                {link.type === 'TWITTER' &&
                  (isDarkMode ? (
                    <img src={icon_twitter} alt="Website Icon" />
                  ) : (
                    <img src={icon_twitter_black} alt="Website Icon" />
                  ))}
                {link.type === 'INSTAGRAM' &&
                  (isDarkMode ? (
                    <img src={icon_instagram} alt="Website Icon" />
                  ) : (
                    <img src={icon_instagram_black} alt="Website Icon" />
                  ))}
              </div>
              {link.type === 'SITE' && 'Website'}
              {link.type === 'DISCORD' && 'Discord'}
              {link.type === 'TWITTER' && 'Twitter'}
              {link.type === 'INSTAGRAM' && 'Instagram'}
            </a>
          </button>
        </li>
      ))}
    </ul>;
    return (
      <>
        <ul className="dropdown-box-sns">
          {links?.map((link: LinkTypes, index) => (
            <li key={index} className="list-dropdown-item">
              <button className="dropdown-item-nft  button">
                <a href={link.url} target="_blank" className="custom-link-sns">
                  <div className="image-sns">
                    {link.type === 'SITE' &&
                      (isDarkMode ? (
                        <img src={website_icon} alt="Website Icon" />
                      ) : (
                        <img src={website_icon_black} alt="Website Icon" />
                      ))}
                    {link.type === 'DISCORD' &&
                      (isDarkMode ? (
                        <img src={icon_discord} alt="Website Icon" />
                      ) : (
                        <img src={icon_discord_black} alt="Website Icon" />
                      ))}
                    {link.type === 'TWITTER' &&
                      (isDarkMode ? (
                        <img src={icon_twitter} alt="Website Icon" />
                      ) : (
                        <img src={icon_twitter_black} alt="Website Icon" />
                      ))}
                    {link.type === 'INSTAGRAM' &&
                      (isDarkMode ? (
                        <img src={icon_instagram} alt="Website Icon" />
                      ) : (
                        <img src={icon_instagram_black} alt="Website Icon" />
                      ))}
                  </div>
                  {link.type === 'SITE' && 'Website'}
                  {link.type === 'DISCORD' && 'Discord'}
                  {link.type === 'TWITTER' && 'Twitter'}
                  {link.type === 'INSTAGRAM' && 'Instagram'}
                </a>
              </button>
            </li>
          ))}
          {screenSize < 540 && fromMyItems && (
            <li
              className="list-dropdown-item"
              style={{ cursor: 'pointer' }}
              onClick={() => copyToClipBoard(window.location.href)}
            >
              <button className="dropdown-item-nft  button">
                <div className="custom-link-sns">
                  <div className="image-sns">
                    {isDarkMode ? (
                      <img src={icon_share} alt="Share Icon" width="20px" />
                    ) : (
                      <img src={icon_share_black} alt="Share Icon" width="20px" />
                    )}
                  </div>
                  Copy
                </div>
              </button>
            </li>
          )}
        </ul>
        <CSnackbar
          open={openSnackbar.open}
          type={openSnackbar.type}
          message={openSnackbar.message}
          handleClose={handleCloseSnackbar}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default GetSnsMobileButtons;
