import { SCAN_URL } from '../config';

export const moveToScope = (
  chainId: number | undefined,
  txHash: string | null,
  isContract?: boolean,
  tokenId?: string | number
) => {
  const isKlaytn = (chain: number) => {
    if (chain === 8217 || chain === 1001) return true;
    else return false;
  };

  const url = `${SCAN_URL[chainId]}${isContract ? (isKlaytn(chainId) ? 'account' : 'address') : 'tx'}/${txHash}`;

  window.open(url, '_blank');
};
