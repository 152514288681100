import React from 'react';

const TermsConditions = () => {
  return (
    <div className="terms-and-conditions-container">
      <div>
        <div className="terms-and-conditions-header">
          <p> Terms & Conditions</p>
        </div>
        <div className="terms-and-conditions-body">
          <div className="terms-and-conditions-line">
            <p>
              Welcome to Talken! We highly recommend that you read our Platform Terms of Conditions (“Terms of Use”).
              The Terms are a contract between you and Talken GmbH(“us”, “we” “Talken” “The Company”), which is
              applicable when you use our mobile application, web sites (including- but not limited to- talken.io), our
              services, products, and content provided by us, (individually and collectively, the “Platform”), and our
              Platform on other websites or applications (regardless of the exact URL), globally, in existence now or in
              the future. You are referred to herein as “you” or “your”, and when we use “you” or “your” we are
              referring to you and any person, party or entity who accesses the Platform or uses the Services. Together,
              you and Talken are referred to herein as ‘Parties.’{' '}
            </p>
            <p>
              The Platform allows artists, talent, brands, performers, celebrities, sports teams, sports stars, and
              anyone with a fan base or brand to create unique, one-of-kind digital merchandise (“Digital Collectibles”)
              for their fans and other consumers to collect through purchases, giveaways or other means.{' '}
            </p>
            <p>
              To ensure each Digital Collectible’s unique authenticity, Talken uses blockchain-based non-fungible token
              (“NFT”) technology to demonstrate rights ownership and authenticity. Possession of an NFT is similar to
              receiving a “certificate of authenticity” along with unique physical merchandise, such as a signed the
              artist's work.
            </p>
            <p>
              Talken mints each Digital Collectibles series on the public blockchain, and once committed to the public
              blockchain, the series cannot be edited or changed, including by Talken or any other third party. Talken’s
              use of the public blockchain offers an open ledger reporting and tracking capability that can help to
              ensure rights ownership and authenticity.
            </p>
            <p>
              THESE TERMS OF USE CONTAINS A MANDATORY ARBITRATION PROVISION THAT REQUIRES THE USE OF ARBITRATION ON AN
              INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR ANY OTHER COURT PROCEEDINGS, OR CLASS
              ACTIONS OF ANY KIND.
            </p>
            <p>
              We may update these Terms of Use from time to time. We will let you know if we make changes of these Terms
              of Use by any reasonable means, including by posting the updated version of the Terms of Use to the
              Platform. Any such changes will not apply to any dispute between you and us arising prior to the date on
              which we posted the revised Terms of Use incorporating such changes, or otherwise notified you of such
              changes. If you continue to use the Platform after the stated effective date of the updated Terms of Use,
              you will be deemed to have accepted the change.
            </p>
            <p>
              Talken shall also have the right, at any time and without liability, to update, modify, alter, enhance, or
              eliminate the features, layout, structure, front-end, navigation, appearance, functionality and other
              elements of all or a part of the Platform, and any aspect, portion or feature thereof.
            </p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">DEFINITIONS</div>
            <p>“Account” refers to a User Account or a Brand Account as applicable to you.</p>
            <p>
              “Affiliates” means a party’s subsidiaries, successors, assigns, or owners, and any company that has a
              business relationship with them, including without limitation, managers, entertainers, agents, companies,
              distributors, vendors, suppliers, licensees and any of their shareholders, officers, directors, employees,
              attorneys, accountants, or other representatives.
            </p>
            <p>
              “Brands” refers to any person or entity with a Brand Account on the Platform. Brand accounts are intended
              for artists, talent, retail brands, performers, celebrities, sports teams, sports stars, or any person or
              entity that has a user or fan base and has an account on the Platform (“Brand Account”).
            </p>
            <p>
              “Rewards” include, but are not limited to, Digital Collectibles, unique Brand merchandise, prizes, and
              experiences, or Talken provided rewards and experiences. Both Talken and Brands have flexibility to use
              the Platform to offer many kinds of prizes and rewards including unique prizes and one-of-a-kind items or
              experiences, many of which are not generally for sale or available as a service by the Brand.
            </p>
            <p>
              “Services” means any features, functions, services, content, rewards, offers or other information
              available on or through the Platform.
            </p>
            <p>
              “Users” refer to any person, fan, or entity that has a standard account (“User Account”) on the Platform.
            </p>
            <p>
              The words “use” or “using” in these Terms of Use, means any time an individual, directly or indirectly,
              with or without the aid of a machine or device, does or attempts to access, interact with, use, display,
              view, print or copy from the Platform, receives data from or transmits data to the Platform, or in any way
              utilizes, benefits, takes advantage of or interacts with any function, service or feature of the Platform,
              for any purpose whatsoever.
            </p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">USE OF THE TALKEN PLATFORM</div>
            <p>
              The Platform is offered and made available only to users 13 years of age or older. and the Platform does
              not permit access to users located in Afghanistan, Belarus, Crimea, Cuba, Donetsk, Iran, Luhansk, North
              Korea, Russia, Syria, and Venezuela (“Prohibited Jurisdictions”), based on applicable laws and regulations
              and other criteria. In using the Platform, you confirm that you are not located in, incorporated or
              otherwise organized in, or a citizen or resident of, a Prohibited Jurisdiction. the Platform routinely
              reviews and makes updates to its Prohibited Jurisdictions list as appropriate. If your use of the Platform
              is prohibited or restricted in any way by the laws, regulations, rules or other governmental requirements
              of the jurisdiction from which you access the Platform or in which you live or reside, or if, for any
              reason and at any time, you do not agree with all of the terms and conditions contained in these Terms of
              Use, please discontinue using the Platform immediately.
            </p>
            <p>
              BY ACCESSING THE PLATFORM: (I) YOU CERTIFY THAT YOU ARE AT LEAST 13 YEARS OF AGE AND HAVE A PARENT’S OR
              GUARDIAN’S PERMISSION TO ACCESS THE PLATFORM OR YOU ARE AT LEAST 18 YEARS OF AGE AND (II) YOU (OR YOUR
              PARENT OR GUARDIAN IF APPLICABLE) UNDERSTAND, ACKNOWLEDGE AND AGREE TO BE BOUND BY THESE TERMS OF USE.
              YOUR REMEDY FOR DISSATISFACTION WITH THE PLATFORM, OR ANY SERVICES, IS TO STOP USING THE PLATFORM AND/OR
              THOSE PARTICULAR SERVICES. YOUR AGREEMENT WITH US REGARDING COMPLIANCE WITH THESE TERMS BECOMES EFFECTIVE
              IMMEDIATELY UPON ACCESSING THE PLATFORM.
            </p>
            <p>
              IF YOU ARE AN INDIVIDUAL ACCESSING OR USING THE PLATFORM ON BEHALF OF, OR FOR THE BENEFIT OF, ANY
              CORPORATION, PARTNERSHIP OR OTHER ENTITY WITH WHICH YOU ARE ASSOCIATED (AN “ORGANIZATION”), THEN YOU ARE
              AGREEING TO THESE TERMS OF USE ON BEHALF OF YOURSELF AND SUCH ORGANIZATION, AND YOU REPRESENT AND WARRANT
              THAT YOU HAVE THE LEGAL AUTHORITY TO BIND SUCH ORGANIZATION TO THESE TERMS OF USE. References to “you” and
              “your” in these Terms of Use will refer to both the individual using the Platform and to any such
              Organization.
            </p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">ACCOUNT REGISTRASTION</div>
            <p>
              To access and participate with the Platform and our Services you will need to regist an Account, which can
              be a Brand Account, as a Brand, or User Account, as an individual User. Accounts are not created on the
              platform itself. You can register accounts of popular social media such as Google, Apple and Facebook on
              the platform. By registration an Account, you agree to: (a) provide accurate, current and complete account
              information as required, (b) maintain and promptly update from time to time as necessary your account
              information, (c) maintain the security of your Account, (d) accept all risks of unauthorized access to
              your Account and the information you provide to us, and (e) immediately notify us by using the “Support”
              or “Official customer center” component of the Platform if you discover or otherwise suspect any security
              breaches related to the Platform or your Account. Your Account is personal to you and you are responsible
              if you allow others to use it. We are not liable for any direct or indirect harm caused or related to the
              theft or misappropriation or disclosure of all or any portion of your Account access details, or your
              authorization of anyone else to use your Account.
            </p>
            <p>
              As part of the registration process, you will need to choose a nickname (which serves as your platform
              “handle"). Your User Account must be unique and not previously registered with the Platform, and we may
              request that you provide other personally identifiable information such as your email address or SNS IDs.
              We may reject, or require that you change, any nickname or other information that you provide to us in
              registering. Talken may choose to utilize two-factor authentication which may be required by Talken once
              your Account reaches certain milestones or before certain actions or levels of engagement are allowed. For
              two-factor authentication we will send a secret authorization code to the email address you provided (or
              for Users that registered via Social Authentication to the email address associated with their personal
              social account) to complete the two-factor authentication set-up. If a User uses Social Authentication as
              part of their registration process, then they are subject not only to these Terms of Use, but also to the
              terms and conditions of the Social Network used for authentication.
            </p>
            <p>
              You acknowledge and agree that to ensure the safety of your Account and to enforce the internal security
              policies of Talken, we may use and maintain certain internal controls and programs. Talken, through these
              programs and controls, may collect and store information about you and your transactions, including
              personal information about you, provided to us upon your creation of your Account or requested by us upon
              your use of the Platform. Talken may require further identification information from you to the extent
              requested by any competent law enforcement or judicial authority or if applicable to meet requirements for
              the Services pertaining to laws of a certain jurisdiction or governmental authority.
            </p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">IN APP PURCHASES</div>
            <p>
              In-App Purchases. Through the mobile applications, you may purchase (“In App Purchase”) Digital
              Collectibles (“Goods”). When you purchase Goods, you are doing so through either Apple iTunes service or
              the Google Play service, as applicable, and you are agreeing to their respective Terms and Conditions.
              (https://www.apple.com/legal/internet-services/itunes/ ,
              https://play.google.com/intl/en_us/about/play-terms/). We are not a party to any In App Purchase.
            </p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">PROMOTIONS</div>
            <p>
              Any sweepstakes, contests, surveys or similar promotions (collectively, “Promotions”) made available
              through or in connection with the Platform may be governed by rules that are separate from these Terms of
              Use. If you participate in any Promotions, please review the applicable rules as well as our Privacy
              Policy. If the rules for a Promotion conflict with these Terms of Use, the Promotion rules will govern. We
              note that the rules in connection with a Promotion involving a particular Brand may be issued by such
              Brand, rather than by us and may either appear (or be accessible via a link) in the “Custom Terms” section
              below or be made available in connection with such Promotion as a separate, standalone set of terms and
              conditions.
            </p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">DIGITAL COLLECTIBLES (NFTS)</div>
            <p>
              Talken may create, or coordinate with Brands to create the definition for each collectible series on the
              public blockchain, including the total number available and the collectible images. Once committed to the
              public blockchain, the series cannot be edited or changed by any party including Talken or the Brand.
              Talken uses blockchain which offers an open ledger reporting and tracking capability suitable to ensure
              rights ownership and authenticity.
            </p>
            <p>
              When a user obtains a collectible within a series, a specific collectible “child” (an NFT) is created by
              Talken with a distinct sequence number. The sequence number of the collectible will never exceed the total
              inventory requested by the Brand for the series. An NFT is a type of cryptographic token which represents
              something unique. Unlike cryptocurrencies and other fungible digital assets, NFTs are not used to make
              payments. Non-fungible Tokens are not mutually interchangeable and can’t be divided into smaller parts.
              These design features limit the usefulness of NFTs as a form of payment or substitute for currency.
              Instead, NFTs are enjoyed as Digital Collectibles.
            </p>
            <p>
              Each NFT has its own unique “immutable data” such as total inventory, artwork, video files, and other
              information which can never be changed. In some cases, Users may be able to add on to, customize, or
              enhance an NFT but any such changes will be additional enhancements and not change the underlying NFT.
            </p>
            <p>
              Brands using the Platform to distribute Digital Collectibles understand that any future value of a Digital
              Collectible is based solely on consumer interest and demand for that Digital Collectible and not something
              that Talken or the Brand can control or will attempt to control or influence. Brands agree to not promise
              any future value of Digital Collectibles in, among other things, promotion or marketing of such
              collectibles.
            </p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">
              LIMITED LICENSE TO LOGOS, ARTWORK, ILLUSTRATIONS AND OTHER CONTENT EMBEDDED IN DIGITAL COLLECTIBLES
            </div>
            <p>
              You acknowledge and agree that you do not own any logos, illustrations, photos, or any other forms of
              artwork, nor any copyrights or other proprietary rights therein, collectively the “Content” embedded in
              Digital Collectibles as part of the immutable data. Such Content is owned by Talken, Brands, licensors,
              artists, or other partners, and is licensed, not sold. For clarity, each Digital Collectible is comprised
              of an NFT and Content that is associated with such NFT, such that if you own the NFT, and only for so long
              as you own such NFT, you will receive a license to access, display, and/or perform such Content through
              the NFT, subject to applicable terms and conditions.
            </p>
            <p>
              Subject to your compliance with these Terms of Use and any separate licensing terms from a Brand relating
              to Digital Collectibles associates with such Brand (which may appear in the “Custom Terms” section below
              or in a set of terms and conditions separately made available in connection with such Brand’s Digital
              Collectibles) (“Brand License Terms”), you are granted a limited worldwide, non-exclusive,
              non-transferable, license to use and display the Content associated with NFTs that you own, solely for the
              period of time that you own such NFTs, as embedded in a Digital Collectible. To the extent that, with
              respect to a particular Digital Collectible, there is a conflict between the license grant above and the
              licensing terms set out in the Brand License Terms, the Brand License Terms shall govern.
            </p>
            <p>
              You agree that you may not modify or attempt to modify the original Content associated with your Digital
              Collectibles in any way, and you agree you will not use the Content embedded in Digital Collectibles for
              any commercial benefit or distribute to any third party for commercial benefit.
            </p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">DIGITAL COLLECTIBLE USE</div>
            <p>
              YOU REPRESENT AND ACKNOWLEDGE THAT YOU ARE COLLECTING, TRADING OR PURCHASING DIGITAL COLLECTIBLES SOLELY
              FOR YOUR OWN COLLECTION, USE AND ENJOYMENT, AND NOT FOR SPECULATIVE OR INVESTMENT PURPOSES, FOR USE AS A
              SUBSTITUTE FOR CURRENCY OR MEDIUM OF EXCHANGE, RESALE OR REDISTRIBUTION AND THAT YOU ARE NOT ACQUIRING ANY
              EQUITY OR OTHER OWNERSHIP OR PROFIT-SHARING INTEREST IN TALKEN, ITS AFFILIATES OR ANY BRAND AS A RESULT OF
              YOUR ACQUISITION OF DIGITAL COLLECTIBLES.
            </p>
            <p>
              YOU WARRANT AND COVENANT THAT YOU WILL NOT PORTRAY THE DIGITAL COLLECTIBLES AS AN OPPORTUNITY TO GAIN AN
              ECONOMIC BENEFIT OR PROFIT, OR AS AN INVESTMENT, EQUITY, OR ANY OTHER OWNERSHIP OR PROFIT-SHARING INTEREST
              IN TALKEN, ITS AFFILIATES OR ANY BRANDS.
            </p>
            <p>
              YOU REPRESENT AND ACKNOWLEDGE THAT LEGISLATIVE OR REGULATORY CHANGES AT THE STATE, FEDERAL OR
              INTERNATIONAL LEVEL MAY ADVERSELY AFFECT THE USE, TRANSFER, OR EXCHANGE OF THE DIGITAL COLLECTIBLES.
            </p>
            <p>
              The Platform enables Brands to add additional utility to collectibles where proof of ownership can grant
              Users personalized benefits such as VIP access at a concert or event. Subject to any applicable conditions
              provided by Talken and/or your local jurisdiction you may also, if available to you, choose to move your
              owned Digital Collectibles off the Platform to hold, use or display outside of the Platform in any manner
              of your choosing in which case Talken shall have no further visibility of such Digital Collectibles.
            </p>
            <p>
              Although many Users have no interest in parting with their Digital Collectibles, should you ultimately
              decide in the future to trade or sell your Digital Collectible, you understand that your Digital
              Collectibles are unique, and the value of your Digital Collectible is solely dependent on another person’s
              interest in your unique Digital Collectible and what he or she would be willing to trade for it or spend
              to purchase it. You understand that the value of your unique Digital Collectible is driven by many factors
              outside of your control and outside of Talken and its Brand partners’ control, such as a buyer’s own
              connection and affinity with your unique Digital Collectible, the general interest in the talent or brand
              associated with the Digital Collectible (which can increase or decrease over time), general interest in
              collectibles both physical and digital overall, and many other factors. Given the above factors, you
              understand there is no guarantee that, should you ultimately want to sell or trade your unique Digital
              Collectible, you will be able to find a buyer for it or a person to trade with for your Digital
              Collectible, and if you are able to find a buyer or person to trade with, there is no guarantee that value
              of what you receive or the price you receive will be higher or lower at any time as compared to what you
              perceive as your value or what you spent or traded for to obtain your Digital Collectible. Ownership of a
              Digital Collectible does not give you any rights to equity or other ownership in Talken or any of its
              affiliates or Brands.
            </p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">TRANSACTIONS, FEES AND TAXES</div>
            <p>
              We may make available the ability to purchase or otherwise obtain Digital Collectibles through the
              Platform (a “Transaction”). If you wish to make a Transaction, you may be asked to supply certain relevant
              information, such as your credit card number and its expiration date, your billing address and your
              shipping information. YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT TO USE ANY CREDIT CARD THAT YOU
              SUBMIT IN CONNECTION WITH A TRANSACTION. By submitting such information, you grant to us the right to
              provide such information to third parties for purposes of facilitating Transactions. Verification of
              information may be required prior to the acknowledgment or completion of any Transaction. By making a
              Transaction, you represent that the applicable Digital Collectibles will be used only in a lawful manner.
            </p>
            <p>
              Talken reserves the right, including without prior notice, to limit the available quantity of or
              discontinue making available any Digital Collectible; to impose conditions on the honoring of any coupon,
              discount or similar promotion; to bar any user from making any Transaction; and to refuse to provide any
              user with any Digital Collectible.
            </p>
            <p>
              Ownership of the NFT products you have purchased will be transferred to your wallet immediately after the
              'Transfer to Blockchain Wallet' request is completed and recorded on the blockchain. This transfer of
              ownership is permanent and irreversible. Therefore, NFT products that have already been purchased are
              non-refundable. However, if the purchased NFT has not been transferred to the blockchain wallet, you can
              request a refund within 7 days of purchase.
            </p>
            <p>
              You agree to pay all charges incurred by you or on your behalf through the Platform, at the prices in
              effect when such charges are incurred. You are responsible for compliance with any tax obligations arising
              from your use of the Platform and are advised to consult with a tax advisor regarding your specific state,
              local, federal requirements for your jurisdiction or country. We shall bear no liability or responsibility
              with respect to any non-Talken tax obligation.
            </p>
            <p>
              Talken may provide functionality for a Digital Collectible such that certain downstream transfers for
              value of such Digital Collectible by you (e.g., to another consumer who collects Digital Collectibles) or
              to you (e.g., from another consumer transferring the Digital Collectible) that occur on the Platform may
              generate Content-related royalties to a Brand or its designee(s) based on the value of any such transfer
              and Talken may charge a fee for any transactions taking place on or in connection with the Platform.
            </p>
            <p>
              Talken shall also be entitled to recoup or pass on any third-party transaction fees (including but not
              limited to debit card, credit card, PayPal fees) and, to the extent applicable, sales tax amounts incurred
              from your use of the Platform.
            </p>
            <p>
              All transfers of U.S. dollars and KRW between Users and Talken, as applicable, are processed by
              third-party payment service providers, and your use of these services is subject to such third-party
              service providers’ terms and conditions.
            </p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">OWNERSHIP AND PROPRIETARY RIGHTS</div>
            <p>
              You agree and acknowledge that Talken and its licensors own all right, title and interest in and to the
              Platform and the Services, including, without limitation, any and all code, algorithms, analytics or
              metrics associated with the Platform or Services or derived from performing Services (“Talken IP”), and
              that, other as expressly provided in these Terms of Use, no licenses or other rights to the Talken IP are
              granted to you by these Terms of Use or otherwise. Unless otherwise authorized by Talken, you shall not
              sublicense, distribute, transfer, copy, reproduce, download, modify or timeshare the Talken IP or any
              portion thereof, or use the Talken IP as a component of or a base for products or services prepared for
              commercial sale, sublicense, lease, access, or distribution. You shall not prepare any derivative work
              based on the Talken IP, nor shall you translate, reverse engineer, decompile or disassemble the Talken IP.
            </p>
            <p>
              The names, logos, trade names, trademarks, service marks and other distinctive identifications on or off
              the Platform, including, without limitation, TALKEN and / or our stylized logo: (among others)
              (collectively “Marks”) are the trademarks and intellectual property of and are proprietary to Talken. In
              addition, the Brands may have their own trademarks and logos. You have no right to use any of our Marks or
              those of the Brands, or any confusingly similar marks thereto, for any purpose without the express, prior,
              written consent of Talken GmbH. or the respective Brands. Nothing contained on the Platform should be
              construed as granting any right to use any trade names, trademarks, service marks or logos without the
              express prior written consent of the owner.
            </p>
            <p>
              Talken is granted a limited, non-exclusive, license to use Brand trademarks and logos in connection with
              Brand’s deployment of the Platform and to promote, enable discovery of, and provide Users navigation and
              access to the Brand deployment of the Platform for the term of these Terms of Use.
            </p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">LICENSE AND PLATFORM ACCESS</div>
            <p>
              Subject to your compliance with these Terms of Use, and solely for so long as you are permitted by Talken
              to use the Platform, we hereby permit you, on a limited, non-exclusive, revocable, non-transferable,
              non-sublicensable basis, to (i) view one (1) copy of any portion or the website version of the Platform to
              which we provide you access under these Terms of Use, on any single device, and (ii) install and use the
              mobile application version of the Platform on a mobile device that you own or control, in each case of (i)
              and (ii), solely for your personal, non-commercial use. If you fail to comply with any of the terms or
              conditions of these Terms of Use, you must immediately cease using the Platform and remove (that is,
              uninstall and delete) the mobile application from your mobile device.
            </p>
            <p>
              Talken presumes that if you permit your minor child to go online and access your Account, or if you
              establish an Account for any minor child, then such minor child has your approval and express permission
              to have independent access to the Platform or any other subpage on the Platform. You understand and agree
              that if you do not wish your minor child to have independent access to the Platform, it is your duty to
              make sure that you do not give your minor child your Account access details or establish an Account for
              them. Remember, even if a minor child has your Account access details, you can change it by changing your
              password. As stated above, a minor child under age 13 is prohibited from using the Platform, even with
              permission from a parent or guardian. We note that some of our Brand partners may require a User to be age
              18 or older to obtain Digital Collectibles associated with such Brand partners; please refer to the
              applicable Brand License Terms or Promotion rules for any minimum age requirements.
            </p>
            <p>
              As an express condition of your use of the Platform, you represent and warrant to us that you shall not
              use the Platform for any fraudulent or unlawful purpose or purpose prohibited by these Terms of Use or the
              laws or regulations in the jurisdiction in which you live or reside or where you may engage in any
              activity with the Platform. Unlawful purposes include, but are not limited to, engaging or assisting in
              (i) money laundering or terrorist financing, (iii) activities that violate a sanctions program, such as
              U.S. Department of Treasury’s Office of Foreign Assets Control (“OFAC”) sanctions requirements, (iii)
              fraudulent conduct, and (iv) illegal offerings of securities. Any violation of any of the above
              representations and warranties, including use in contravention of these Terms of Use, may subject you to
              civil liability, criminal prosecution, termination of your Account and all associated benefits as provided
              herein. If you violate any part of these Terms of Use, then your license to use the Services or Platform
              may be terminated by Talken in its sole and absolute discretion.
            </p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">THIRD PARTY MATERIALS; LINKS</div>
            <p>
              Certain Platform functionality may make available access to information, products, services and other
              materials made available by third parties (“Third Party Materials”) or allow for the routing or
              transmission of such Third Party Materials, including via links. By using such functionality, you are
              directing us to access, route and transmit to you the applicable Third Party Materials.
            </p>
            <p>
              We neither control nor endorse, nor are we responsible for, any Third Party Materials, including the
              accuracy, validity, timeliness, completeness, reliability, integrity, quality, legality, usefulness or
              safety of Third Party Materials, or any intellectual property rights therein. Certain Third Party
              Materials may, among other things, be inaccurate, misleading or deceptive. Nothing in these Terms of Use
              shall be deemed to be a representation or warranty by Talken with respect to any Third Party Materials. We
              have no obligation to monitor Third Party Materials, and we may block or disable access to any Third Party
              Materials (in whole or part) through the Platform at any time. In addition, the availability of any Third
              Party Materials through the Platform does not imply our endorsement of, or our affiliation with, any
              provider of such Third Party Materials, nor does such availability create any legal relationship between
              you and any such provider.
            </p>
            <p>
              YOUR USE OF THIRD PARTY MATERIALS IS AT YOUR OWN RISK AND IS SUBJECT TO ANY ADDITIONAL TERMS, CONDITIONS
              AND POLICIES APPLICABLE TO SUCH THIRD PARTY MATERIALS (SUCH AS TERMS OF SERVICE OR PRIVACY POLICIES OF THE
              PROVIDERS OF SUCH THIRD PARTY MATERIALS).
            </p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">ACCOUNT SUSPENSIONS AND TERMINATION</div>
            <p>
              In the event that a User or Brand is deemed by Talken in its reasonable discretion to be or have been
              non-compliant with any of the terms of these Terms of Use or, with respect to a User, any applicable Brand
              License Terms, Talken may terminate or suspend such User’s or Brand’s Account.
            </p>
            <p>
              If we terminate an Account, we will permanently close such Account. If you are a User and your Account is
              terminated, you acknowledge that you may lose your ability to access the Digital Collectibles associated
              with such Account.
            </p>
            <p>
              If your Account is terminated or suspended by Talken, you agree to cease all access to or use of the
              Platform or Services
            </p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">PROCEDURE FOR MAKING COPYRIGHT INFRINGEMENT CLAIMS</div>
            <p>
              If you believe in good faith that materials available on the Platform infringe your copyright, you (or
              your agent) may send to Talken a written notice by e-mail, requesting that Talken remove such material or
              block access to it. e-mail address is talken@talken.io.
            </p>
            <p>We suggest that you consult your legal advisor before filing a notice.</p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">DATA, EMAIL AND SNS IDs</div>
            <p>
              In some instances, we will jointly offer Digital Collectibles with a Brand on our Platform. When you
              purchase one of or trade for such Brand’s Digital Collectible, then that Brand will collect and use your
              information in accordance with its privacy policy, and Talken may share your information with that
              specific Brand. In those cases, in addition to reviewing our privacy policy, please review the Brand’s own
              privacy privacy which will be made available on their website prior to your purchase or trade. We will
              also share your information with Brands if you direct us to do so. We may share de-identified and/or
              aggregated information relating to your use of and activities on our Platform with Brands where you have
              not purchased or traded for an NFT.
            </p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">INTEGRATIONS</div>
            <p>
              In order to use all the features of the Platform and for Talken to capture the completion of tasks
              performed on third party social media sites, Talken may ask you to Open Authorize (“OAuth”) your social
              media accounts with your Account on the Platform. By OAuth-ing your social media accounts, you are giving
              the Platform certain permissions to access data about you, and also access your social media accounts for
              purposes such as, but not limited to, posting, liking, sharing, and commenting, directly through the
              Platform. Talken does not post content that you have not provided, or edit posts that are made through the
              Platform directly to social media sites. Talken reserves the right to add any promotion or advertising
              disclaimer to an action taken if deemed necessary to comply with regulations for posting in certain types
              of actions. All posts on third party social media sites are governed by the respective third party and you
              are solely responsible for following their terms of use. Your posting on third party social media sites is
              entirely at your own risk.
            </p>
            <p>
              We may (but have no obligation to) monitor, evaluate, alter or remove postings before or after they appear
              on the Platform, or analyze your access to or use of the Platform. The Platform does not represent or
              guarantee the truthfulness, accuracy, timeliness, or reliability of your postings. TALKEN ASSUMES NO DUTY
              TO MONITOR YOUR POSTINGS. TALKEN DOES NOT REPRESENT OR GUARANTEE THE TRUTHFULNESS, ACCURACY OR RELIABILITY
              OF SUCH POSTINGS OR THAT SUCH POSTINGS COMPLY WITH THE TERMS OTHER GOVERNMENTAL REQUIREMENTS OF THE
              JURISDICTION IN WHICH YOU LIVE OR RESIDE. YOU SHOULD NEVER RELY UPON ANY POSTING AS NECESSARILY BEING
              TRUE, ACCURATE GENUINE OR LAWFUL.
            </p>
            <p>
              You understand, acknowledge, and agree that postings originating from your Account are the sole
              responsibility of the individual associated with that Account. THIS MEANS THAT YOU (AND NOT BRANDS, THE
              PLATFORM OR ANY THIRD PARTY SOCIAL MEDIA SITE) ARE ENTIRELY RESPONSIBLE FOR THE CONSEQUENCES OF ALL
              POSTINGS ON THE PLATFORM OR THIRD PARTY SOCIAL MEDIA SITES ORIGINATING FROM YOUR ACCOUNT. Your postings do
              not reflect the views of Talken, Brands or any Affiliates. In no event shall Talken, Brands or any
              Affiliates have or be construed to have any responsibility or liability for or in connection with any User
              posting whatsoever; provided, however, if we determine, in our sole and absolute discretion and judgment,
              that any posting does or may violate any of the terms of these Terms of Use, we reserve the right, at any
              time and without limiting any and all other rights we may have under these Terms of Use, at law or in
              equity, to: (a) refuse to allow you to post; (b) notify the third party social media site, or (c) use any
              technological, legal, operational or other means available to us to enforce the provisions of these Terms
              of Use, including, without limitation, Terminating your Account on Talken.
            </p>
            <p>
              If a posting originates from you or your Account, you hereby: (a) represent and warrant that (i) the
              posting is original to you or fully cleared for use by the copyright owner as contemplated herein, (ii)
              the posting does not and will not, in any way, violate or breach any of the terms of these Terms of Use or
              any other Third Party platform, (iii) the posting does not contain libelous, tortious, or otherwise
              unlawful information, infringe or violate any copyright or other personal right, or contain any matter the
              publication or sale of which will violate any law, regulation or other governmental requirement or
              restriction, (iv) the posting does not contain content which would be deemed unlawful or restricted by
              law, and (v) the posting shall not be damaging or injurious to Talken, Brands any Affiliates or any User.
              In addition, if your posting incorporates the name, logo, brand, service or trademark, voice, likeness or
              image of any person, firm, or enterprise, you specifically represent and warrant that you have the right
              to make such posting.
            </p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">RULES OF CONDUCT</div>
            <p>
              Your use of the Platform is subject to all applicable local, state, national laws, rules, and regulations
              and, in some cases, international treaties. You are solely responsible for all of your postings on the
              Talken Platform, in the Talken app, or through any third party social media, and all other activities,
              acts and omissions that occur in, from, through or under your Account. You agree to abide by these Rules
              of Conduct. You shall not use, allow, post, encourage or enable others to use, allow, or knowingly condone
              the use of the Platform in any manner that is, attempts to, or is likely to:
            </p>
            <p>
              a. be libelous, threatening, abusive, harassing, deceptive, offensive, profane, obscene, vulgar, contains
              or depicts nudity, contains or depicts sexual activity, invasive of another’s privacy, tortious,
              defamatory, bullying, or harmful in any way that is deemed unlawful or restricted by law;
            </p>
            <p>
              b. use the Platform in any manner that could interfere with, disrupt, or inhibit others from fully
              enjoying the Platform, or that could damage, disable, overburden or impair the functioning of the Platform
              in any manner;
            </p>
            <p>
              c. send junk email chain letters, duplicative or unsolicited messages or transmissions deemed “spamming”
              or “phishing”;
            </p>
            <p>
              d. transmit, distribute or upload programs or material that contain malicious code, such as viruses, time
              bombs, cancel-bots, worms, Trojan horses, spyware, or other potentially harmful programs or other material
              or information. Notwithstanding the foregoing, and subject to compliance with any instructions posted in
              the robots.txt file located in the Platform’s root directory, Talken grants to the operators of public
              search engines permission to use spiders to copy materials from the Platform for the sole purpose of (and
              solely to the extent necessary for) creating publicly available, searchable indices of such materials, but
              not caches or archives of such materials. Talken reserves the right to revoke such permission either
              generally or in specific cases, at any time and without notice;
            </p>
            <p>
              e. use any deep-link, page-scrape, robot, crawl, index, spider, offline reader, macro programs, internet
              agent, or other automatic device, program, algorithm or methodology which does the same thing, to use,
              access, generate impressions, input information, or generate postings on social media sites;
            </p>
            <p>
              f. forge any TCP/IP packet header or part of the header information in any email or message, forum, tweet,
              comment or other social media posting for any reason or otherwise provide false or misleading information
              to Talken;
            </p>
            <p>
              g. use the Platform for any illegal, fraudulent, tortious, unauthorized, or otherwise unlawful purpose, or
              engage in, encourage or promote any activity that violates these Terms of Use or violates any laws,
              regulations (including, without limitation, laws regarding the transmission of technical data or software
              exported from the United States of America), judicial or governmental order, any treaties or violations or
              infringements upon any intellectual property rights, rights of publicity or privacy, or any other rights
              of Talken, Brands, Affiliates or of any other person, firm or enterprise or to use the Platform to carry
              out any illegal activities;
            </p>
            <p>
              h. gain unauthorized access to the Platform or parts of the site not authorized for your use or attempt to
              use another’s Account without proper authorization, or to use the Platform in any manner which violates or
              is inconsistent with the terms and conditions of these Terms of Use;
            </p>
            <p>i. harvest or collect information about users of the Platform;</p>
            <p>
              j. reproduce, adapt, translate, create derivative works of, sell, rent, lease, loan, timeshare, distribute
              or otherwise exploit any portion of (or any use of) the Platform except as expressly authorized herein,
              without Talken’s express prior written consent;
            </p>
            <p>k. remove any copyright, trademark or other proprietary rights notice from the Platform;</p>
            <p>
              l. frame or mirror any portion of the Platform, or otherwise incorporate any portion of the Platform into
              any product or service, without Talken’s express prior written consent;
            </p>
            <p>m. systematically download and store materials or content from the Platform; or</p>
            <p>
              n. modify, disrupt, impair, alter or interfere with the use, features, functions, operation or maintenance
              of the Platform or reverse engineer, disassemble or decompile any aspect of the Platform, or do anything
              that might discover source code or bypass or circumvent measures employed to prevent or limit access to
              any content, area or code of the Platform.
            </p>
            <p>
              Any reported offensive content is subject to be removed from the Platform within 48 hours, and the User
              creating such content is subject to being terminated from the Platform within 48 hours.
            </p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">DISCLAIMER AND LIMITATIONS OF LIABILITY</div>
            <p>
              TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, THE PLATFORM AND ALL SERVICES, DIGITAL COLLECTIBLES,
              REWARDS AND CONTENT THEREON ARE MADE AVAILABLE ON AN “AS IS,” “WHERE IS” AND “AS AVAILABLE” BASIS, WITHOUT
              ANY REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY. Without limiting the foregoing,
              neither Talken, Brands nor any Affiliates, suppliers or collaborators are responsible or liable for any
              malicious code, delays, inaccuracies, errors, or omissions arising out of your use of the Platform. You
              understand, acknowledge, and agree that you are assuming the entire risk as to the quality, accuracy,
              performance, timeliness, adequacy, completeness, correctness, authenticity, security and validity of any
              and all Services, features and functions of the Platform.
            </p>
            <p>Talken cannot guarantee continuous service and access to the Platform without interruption or delay.</p>
            <p>
              It also is expected that occasional planned outages will be required to maintain and enhance the Platform.
              These updates and releases are done in the interest of improving the Talken Platform functionality for the
              Brand and experience for the User. You indemnify Talken against any losses associated with an inability to
              use or access the Platform based on such system unavailability or limitation of access or functionality.
            </p>
            <p>
              In some cases, Talken integrates directly with third parties, including but not limited to, online
              merchant platforms, mailing list platforms, and social media platforms (“Third Party APIs”). You
              acknowledge that Talken has no control over the uptime and functionality made available through Third
              Party APIs and as such certain aspects of the Platform could incur an outage outside of Talken’s control,
              and certain aspects of the Platform’s functionality could be impacted by changes of features made
              available through Third Party APIs.
            </p>
            <p>
              You understand that Talken shall not be liable to you or any third party for any claims or damages which
              may be suffered by you or any third party, including, but not limited to, losses or damages of any and
              every nature, resulting from the inability to access the Internet, inability to access the Platform, or
              inability to transmit or receive information, caused by, or resulting from, delays, non-deliveries, or
              service interruptions whether or not caused by the fault or negligence of Talken. YOU UNDERSTAND AND AGREE
              THAT, TO THE FULLEST EXTENT PERMISSIBLE BY LAW, TALKEN AND ITS BRAND PARTNERS, AND ITS AND THEIR
              AFFILIATES AND RESPECTIVE SUCCESSORS AND ASSIGNS, OR ANY OF ITS OR THEIR RESPECTIVE OFFICERS, DIRECTORS,
              EMPLOYEES, AGENTS, REPRESENTATIVES, LICENSORS, OPERATIONAL SERVICE PROVIDERS, ADVERTISERS, OR SUPPLIERS,
              SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE, OF ANY KIND, IN CONNECTION WITH OR ARISING FROM YOUR USE OF
              THE PLATFORM OR FROM THESE TERMS OF USE, INCLUDING, BUT NOT LIMITED TO, CONSEQUENTIAL, INCIDENTAL,
              INDIRECT, SPECIAL OR PUNITIVE DAMAGES (INCLUDING, WITHOUT LIMITATION, LOST PROFITS, LOSS OF DATA AND LOST
              REVENUES) ARISING OUT OF ANY BREACH OF, OR DISPUTE RELATED TO, THIS THESE TERMS OF USE. These exclusions
              apply regardless of the theory of liability, or whether related to your use or inability to use the
              Platform, or otherwise. These exclusions apply even if either party has been advised of the possibility of
              such damages. TO THE MAXIMUM EXTENT ALLOWED BY LAW, THE MAXIMUM LIABILITY OF TALKEN (WHICH INCLUDES,
              COLLECTIVELY, ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS AND LICENSORS) TO YOU
              SHALL NOT EXCEED THREE HUNDRED DOLLARS ($300.00). The limitation on liability set forth in this section
              apply whether the claims are contract, tort (including negligence), or otherwise. This limitation of
              liability for the Platform is in the aggregate and not per incident. Some jurisdictions do not allow for
              the limitations on damages and remedies; for that reason, some of the limitations described above may not
              apply to you.
            </p>
            <p>
              TALKEN AND BRANDS DISCLAIM ALL OTHER WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED, INCLUDING, WITHOUT
              LIMITATION, IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE
              AND NON-INFRINGEMENT AS TO ANY PART OF THE PLATFORM. TALKEN DOES NOT REPRESENT OR WARRANT THE PLATFORM IS
              ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE. TALKEN WILL NOT BE LIABLE FOR ANY LOSS OF ANY KIND
              FROM ANY ACTION TAKEN - OR TAKEN IN RELIANCE - ON MATERIAL OR INFORMATION, CONTAINED ON THE PLATFORM.
              WHILE TALKEN ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF THE PLATFORM SAFE, TALKEN CANNOT AND DOES NOT
              REPRESENT OR WARRANT THAT THE PLATFORM OR OUR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS;
              THEREFORE, YOU SHOULD USE INDUSTRY- RECOGNIZED SOFTWARE TO DETECT AND DISINFECT VIRUSES FROM ANY DOWNLOAD
              FROM THE PLATFORM. TALKEN CANNOT GUARANTEE THE SECURITY OF ANY DATA THAT YOU DISCLOSE ONLINE. YOU ACCEPT
              THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET AND WILL NOT
              HOLD US RESPONSIBLE FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR GROSS NEGLIGENCE.
            </p>
            <p>
              TALKEN WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND WE ACCEPT NO RESPONSIBILITY FOR AND WILL
              NOT BE LIABLE TO YOU FOR ANY USE OF THE PLATFORM, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR
              CLAIMS ARISING FROM: (A) YOUR ERRORS SUCH AS FORGOTTEN PASSWORDS, INCORRECTLY CONSTRUCTED TRANSACTIONS, OR
              MISTYPED ADDRESSES; (B) SERVER FAILURE OR DATA LOSS; (C) UNAUTHORIZED ACCESS TO APPLICATIONS; (D) ANY
              UNAUTHORIZED THIRD PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF VIRUSES, PHISHING,
              BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST THE PLATFORM. NOTHING IN THESE TERMS OF USE EXCLUDES OR
              LIMITS LIABILITY FOR DEATH OR PERSONAL INJURY CAUSED BY NEGLIGENCE, FRAUDULENT MISREPRESENTATION OR ANY
              OTHER LIABILITY, WHICH MAY NOT OTHERWISE BE LIMITED OR EXCLUDED UNDER APPLICABLE LAW.
            </p>
            <p>
              ALL DISCLAIMERS OF ANY KIND (INCLUDING IN THIS SECTION AND ELSEWHERE IN THESE TERMS OF USE) ARE MADE FOR
              THE BENEFIT OF BOTH TALKEN AND ITS BRAND PARTNERS, AND ITS AND THEIR AFFILIATES AND RESPECTIVE
              SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, AFFILIATES, AGENTS, REPRESENTATIVES, LICENSORS, SUPPLIERS
              AND SERVICE PROVIDERS (COLLECTIVELY, THE “AFFILIATED ENTITIES”), AND THEIR RESPECTIVE SUCCESSORS AND
              ASSIGNS.
            </p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">ADS AND MALWARE</div>
            <p>
              We take great pride in creating the Platform. We keep an eye out for material technical glitches that
              affect how the Platform works and, when we find them, we will seek to fix them. Unfortunately, your
              computer or other device, or your online service provider or other entity, may cause some glitches that
              affect how you see the Platform -- and that is totally beyond our control.
            </p>
            <p>
              If you experience any unusual behavior, content, or ads on the Platform, it may be the result of Malware
              on your computer or other device. Malware -- short for ‘Malicious Software’ -- is a term used to broadly
              classify a form of software which is installed in a computer system with malicious intentions, usually
              without the owner’s knowledge or permission. Malware includes computer viruses, keyloggers, malicious
              active content, rogue programs and dialers, among others. While we continuously work closely with our
              partners to ensure that everything on the Platform is working properly, sometimes Malware programs on your
              personal computer or devices may interfere with your experience on the Platform and on other sites that
              you visit. If you do discover any Malware on your system, we suggest you speak with a qualified computer
              technician. If you even suspect that there is any kind of Malware on the Platform or servers, please use
              the Contact Us and notify us immediately.
            </p>
            <p>© 2023 Talken GmbH. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
