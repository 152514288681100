import React, { MutableRefObject, useEffect, useState, useRef } from 'react';
import klaytnIcon from 'assets/svg/klaytnFaucet.svg';
import polygon from 'assets/icon/polygon.svg';
import eth from 'assets/icon/eth.svg';
import talk from 'talkclaim/talk.png';
import binance from 'assets/img/icon_binance.png';
import { ReactComponent as SendCircle } from '../../assets/icon/send_circle.svg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-multi-carousel/lib/styles.css';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import {
  getItemPrice,
  getMyMBoxList,
  getClaimableCount,
  requestSetIsRevealed,
  getSellbookInfoByUID,
  requestClaim,
  getMyDbOnlyList,
} from '../../services/services';
import { MBoxTypes } from '../../types/MBoxTypes';
import { ResRevealItemType } from '../../types/ResRevealItemType';
import CSnackbar from '../../components/common/CSnackbar';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AggregateUserInfor from 'components/AggregateUserInfor/AggregateUserInfor';
import {
  claimMysteryBox,
  getItemBalance,
  getItemMetadata,
  getKeyBalance,
  getTokenIds,
} from '../../utils/marketTransactions';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { setApproveForAll } from '../../utils/transactions';
import { ChainId, SUCCESS } from '../../config';
import Popup from 'reactjs-popup';
import WalletConnector from 'components/auth/WalletConnector/WalletConnector';
import { Col, Row, Form } from 'react-bootstrap';
import ListNFTModal from 'components/ListNFTModal/ListNFTModal';
import ListingCompleteModal from 'components/detailnftmodal/ListingCompleteModal';
import { ReactComponent as IconDropdown } from 'assets/icon/arrow_down.svg';
import getAbcWallet from '../../utils/getAbcWallet';
import getAccountTokens from 'utils/getAccountTokens';
import { RasOnchainItemType } from '../../types/RasOnchainItemType';
import { useOpenTransferItem, useOpenTransferItemSuccess } from 'components/common/AppStore';
import TransferItem from 'components/detailnftmodal/TransferItem';
import TransferItemOTP from 'components/detailnftmodal/TransferItemOTP';
import TransferItemSuccess from 'components/detailnftmodal/TransferItemSuccess';
import HelperUtil from '../../utils/HelperUtil';
import { getActiveWallet } from '../../utils/getActiveWallet';
import env from '../../env';
import { isMobile } from 'react-device-detect';
import Skeleton from 'components/common/skeleton/Skeleton';
import { getNetworkNameById } from '../../utils/getNetworkNameById';
import Select from 'react-select';
import { useThemeMode } from '../../components/common/AppStoreType';
import { ReactComponent as MenuSelect } from 'assets/icon/menuselect.svg';
import { ReactComponent as ArrowUpDown } from '../../assets/svg/arrow_up_down.svg';
import { ethers } from 'ethers';
import useScreenSize from 'components/common/useScreenSize';
import { HandleMediaError } from 'components/common/HandleMediaError';

const overlayStyle = { background: 'rgba(0,0,0,0.8)' };
const closeOnDocumentClick = false;
const lockScroll = true;

type ExMBoxTypes = MBoxTypes & {
  companyimage: string;
  companyname: { ko: string; en: string };
};
const optionAllItem = [
  { value: 'All Items', label: 'All Items' },
  { value: 'On-chain', label: 'On-chain' },
];
const optionsChains = [
  { value: 'Chains', label: 'Chains' },
  { value: 'Ethereum', label: 'Ethereum' },
  { value: 'Polygon', label: 'Polygon' },
  { value: 'Klaytn', label: 'Klaytn' },
  { value: 'binance', label: 'Binance' },
];
const MyCollectibles = () => {
  const { deactivate, account, library } = useActiveWeb3React();
  // const { isAbcReady, abcAddress } = getAbcWallet();
  const myWallet = getActiveWallet();
  const login = window.localStorage.getItem('login');
  const [myMBoxList, setMyMBoxList] = useState<ExMBoxTypes[]>([]);
  const [myMBoxListOrg, setMyMBoxListOrg] = useState<ExMBoxTypes[]>([]);
  const [revealItems, setRevealItems] = useState<ResRevealItemType[]>([]);
  const [onchainItems, setOnchainItems] = useState<any>([]);
  const [revealItemsList, setRevealItemsList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(2);
  const [selectedItem, setSelectedItem] = useState();
  const [isOpenListNFTModal, setIsOpenNFTModdal] = useState(false);
  const [isCompleteListing, setIsCompleteListing] = useState(false);
  const [typeListNFT, setTypeList] = useState(false);
  const [mySellbooks, setMySellbooks] = useState([]);
  const [transferItem, setTransferItem] = useState(null);
  const [dataItemTransfer, setDataItemTransfer] = useState();
  // const [nameTransferItem, setNameTransferItem] = useState(null);
  const user = useSelector((state: any) => state.user.user);
  const uid = user.uid;
  const isLogin = uid !== '';
  const [reload, setReload] = useState(false);
  const { isDarkMode } = useThemeMode();
  const styleSelectRecently = {
    control: (base, state) => ({
      ...base,
      background: '#02021e',
      borderRadius: isMobile ? '8px' : '8px',
      borderColor: state.isFocused ? '#444444' : '#444444',
      boxShadow: state.isFocused ? 'none' : 'none',
      fontSize: isMobile ? '14px' : '16px',
      fontWeight: 500,
      width: isMobile ? '125px' : '125px',
      height: isMobile ? '42px' : '42px',
      minHeight: isMobile ? '38px' : '38px',
      cursor: 'pointer',
    }),
    singleValue: (base) => ({
      ...base,
      color: isDarkMode ? '#FFFFFF' : '#000000',
    }),
    valueContainer: (base) => ({
      ...base,
      height: '100%',
      placeContent: 'center',
      marginLeft: isMobile ? '6px' : '2px',
    }),
    option: (base, state) => ({
      ...base,
      fontSize: isMobile ? '14px' : '16px',
      fontWeight: 500,
      width: isMobile ? '125px' : '125px',
      // color: state.isSelected ? '#2f8af5' : '#FFFFFF',
      backgroundColor: state.isSelected ? '#23235c' : '#23235c',
      ':active': {
        backgroundColor: state.isSelected ? '#23235c' : '#23235c',
      },
      ':hover': {
        // backgroundColor: '#23235c',
        color: '#2f8af5',
      },
      cursor: 'pointer',
    }),

    menu: (base) => ({
      ...base,
      fontSize: isMobile ? '14px' : '16px',
      background: '#2f8af5',
      borderColor: '#23235c',
      // override border radius to match the box
      borderRadius: isMobile ? '6px' : '6px',
      // kill the gap
      marginTop: 0,
      width: isMobile ? '125px' : '125px',
    }),
    menuList: (base) => ({
      ...base,
      fontSize: '1px',
      // kill the white space on last option
      padding: '0',
      width: isMobile ? '125px' : '125px',
      borderRadius: isMobile ? '6px' : '6px',
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: isDarkMode ? '#fff' : '#000000',
      placeContent: 'center',
      padding: isMobile ? '4px' : '8px',
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
  };
  const [appState, setChange] = useState<any>({
    objects: [
      {
        id: 1,
        name: 'Newest',
      },
      {
        id: 2,
        name: 'Oldest',
      },
      {
        id: 3,
        name: 'Listed first',
      },
      // {
      //   id: 3,
      //   name: 'Title: A-Z',
      // },
      // {
      //   id: 4,
      //   name: 'Title: Z-A',
      // },
    ],
    activeObject: null,
    activeElement: 0,
  });

  const [open, setOpen] = useState(false);
  const [isShowSort, setIsShowSort] = useState(isMobile ? false : true);
  const [selection, setSelection] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRevealed, setIsRevealed] = useState(false);
  const [myItems, setMyItems] = useState([]);
  const [balance, setBalance] = useState(0);
  const [balances, setBalances] = useState([]);
  const [isKaikas, setIsKaikas] = useState(false);
  const [claimableCount, setClaimableCount] = useState(0);
  const [countDownFinish, setCountDownFinish] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [signupOpen, setSignupOpen] = useState(false);
  const [status, setStatus] = useState(true);
  const [loadingAccountTokens, setLoadingAccountTokens] = useState(false);
  const [loadingMyMBoxList, setLoadingMyMBoxList] = useState(false);
  const [accountTokenOrigin, setAccountTokenOrigin] = useState([]);
  const _balance = [];
  const _items = [];
  const _revealItemsList = [];
  const userEthAddr = user.eth_address?.toLowerCase();
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    type: '',
    message: '',
  });
  const [filter, setFilter] = useState('All Items');
  const [count, setCount] = useState(0);

  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  const [sellData, setSellData] = useState({});
  const [isExpanded, setExpanded] = useState(false);
  const [selectType, setSelectType] = useState({
    fixed: false,
    auction: false,
    drops: false,
    notlisted: false,
  });
  const [selectChains, setSelectChains] = useState({
    klaytn: false,
    ethereum: false,
    polygon: false,
    binance: false,
  });
  const [isClickAllChains, setIsClickAllChains] = useState(true);
  const [isClickAllType, setIsClickAllType] = useState(true);
  const [isTotalFilter, setIsTotalFilter] = useState(0);
  const screenSize = useScreenSize();

  const handleCloseSnackbar = () => {
    setOpenSnackbar({
      open: false,
      type: 'info',
      message: '',
    });
  };

  useEffect(() => {
    const handler = (event: any) => {
      if (!ref?.current?.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  function toggleActive(index: number) {
    setChange({ ...appState, activeObject: appState.objects[index] });
  }

  function handleSortClick(item: any, multipleSelect = false) {
    if (!selection.some((current: any) => current.id === item.id)) {
      if (!multipleSelect) {
        setSelection([item]);
      } else if (multipleSelect) {
        setSelection([...selection, item]);
      }
    } else {
      let selectionAfterRemoval = selection;
      selectionAfterRemoval = selectionAfterRemoval.filter((current: any) => current.id !== item.id);
      setSelection([...selectionAfterRemoval]);
    }
  }

  function isItemInSelection(item: any) {
    if (selection.find((current: any) => current.id === item.id)) {
      return true;
    }
    return false;
  }

  const fetchMyMBoxList = async () => {
    if (uid) {
      setLoadingMyMBoxList(true);
      const sort = selectedIndex !== 0 ? 'ASC' : 'DESC';
      const res = await getMyDbOnlyList(uid, sort); // Claim 하지 않은 장부 거래 목록
      if (res.data.status === 1) {
        setMyMBoxListOrg(res.data.data);
      }
      setLoadingMyMBoxList(false);
    }
  };
  useEffect(() => {
    fetchMyMBoxList();
  }, [uid, selectedIndex]);

  const filterMyMBoxList = async () => {
    setLoadingMyMBoxList(true);

    const newMBox = myMBoxListOrg.filter((item) => {
      const chain = item.chainId;

      const selected = [];
      if (selectChains.ethereum) {
        selected.push(ChainId.MAINNET);
        selected.push(ChainId.SEPOLIA);
      }
      if (selectChains.klaytn) {
        selected.push(ChainId.KLAYTN);
        selected.push(ChainId.BAOBAB);
      }
      if (selectChains.polygon) {
        selected.push(ChainId.POLYGON);
        selected.push(ChainId.MUMBAI);
        selected.push(ChainId.AMOY);
      }
      if (selectChains.binance) {
        selected.push(ChainId.BSCMAINNET);
        selected.push(ChainId.BSCTESTNET);
      }
      // All
      if (!selectChains.klaytn && !selectChains.ethereum && !selectChains.polygon && !selectChains.binance) {
        selected.push(ChainId.MAINNET);
        selected.push(ChainId.SEPOLIA);
        selected.push(ChainId.KLAYTN);
        selected.push(ChainId.BAOBAB);
        selected.push(ChainId.POLYGON);
        selected.push(ChainId.MUMBAI);
        selected.push(ChainId.AMOY);
        selected.push(ChainId.BSCMAINNET);
        selected.push(ChainId.BSCTESTNET);
      }

      return selected.includes(chain);
    });

    if (selectType.drops || (!selectType.drops && !selectType.fixed && !selectType.auction && !selectType.notlisted))
      setMyMBoxList(newMBox);
    else setMyMBoxList([]);

    setLoadingMyMBoxList(false);
  };
  useEffect(() => {
    filterMyMBoxList();
  }, [myMBoxListOrg, selectedIndex, selectChains, selectType]);

  useEffect(() => {
    for (let i = 0; i < myMBoxListOrg?.length; i++) {
      fetchBalance(myMBoxListOrg[i]);
      fetchRevealItem(myMBoxListOrg[i]);
    }
  }, [myMBoxListOrg]);

  useEffect(() => {
    setTimeout(() => {
      const list = revealItemsList.flatMap((arr) => arr);
      setRevealItems(list);
    }, 1000);
  }, [revealItemsList]);

  const accountTokensSorting = (accountTokens: any) => {
    if (accountTokens) {
      const selected = [];
      if (selectChains.ethereum) {
        selected.push('ethereum');
      }
      if (selectChains.klaytn) {
        selected.push('klaytn');
      }
      if (selectChains.polygon) {
        selected.push('polygon');
      }
      if (selectChains.binance) {
        selected.push('binance');
      }
      // All
      if (!selectChains.klaytn && !selectChains.ethereum && !selectChains.polygon && !selectChains.binance) {
        selected.push('ethereum');
        selected.push('klaytn');
        selected.push('polygon');
        selected.push('binance');
      }

      let _newList = accountTokens.slice().filter((item) => {
        return selected.includes(item.chain);
      });

      _newList = _newList.slice().filter((item) => {
        if (selectType.fixed && selectType.auction && selectType.notlisted)
          return item.sellbook?.type === 1 || item.sellbook?.type === 2 || item.sellbook?.type === undefined;

        if (selectType.fixed && selectType.auction && !selectType.notlisted)
          return item.sellbook?.type === 1 || item.sellbook?.type === 2;
        if (!selectType.fixed && selectType.auction && selectType.notlisted)
          return item.sellbook?.type === 2 || item.sellbook?.type === undefined;
        if (selectType.fixed && !selectType.auction && selectType.notlisted)
          return item.sellbook?.type === 1 || item.sellbook?.type === undefined;

        if (selectType.fixed && !selectType.auction && !selectType.notlisted) return item.sellbook?.type === 1;
        if (!selectType.fixed && selectType.auction && !selectType.notlisted) return item.sellbook?.type === 2;
        if (!selectType.fixed && !selectType.auction && selectType.notlisted) return item.sellbook?.type === undefined;
        return true; // All is selected
      });
      _newList = _newList.sort((a: any, b: any) => {
        return (
          new Date(b.updatedAt || b.last_token_uri_sync).getTime() -
          new Date(a.updatedAt || a.last_token_uri_sync).getTime()
        );
      });
      _newList.sort((a: any, b: any) => {
        if (selectedIndex === 0)
          return (
            new Date(b.updatedAt || b.last_token_uri_sync).getTime() -
            new Date(a.updatedAt || a.last_token_uri_sync).getTime()
          );
        else if (selectedIndex === 1)
          return (
            new Date(a.updatedAt || a.last_token_uri_sync).getTime() -
            new Date(b.updatedAt || b.last_token_uri_sync).getTime()
          );
        else if (selectedIndex === 2) {
          if (a.sellbook === null && b.sellbook === null) {
            return 0;
          } else if (a.sellbook !== null && b.sellbook === null) {
            return -1;
          } else if (a.sellbook === null && b.sellbook !== null) {
            return 1;
          } else {
            return 0; // 만약 sellbook이 둘 다 null이 아니면 순서를 변경하지 않습니다.
          }
        }
      });
      if (selectType.drops && !selectType.fixed && !selectType.auction && !selectType.notlisted) setOnchainItems([]);
      else setOnchainItems(_newList);
    }
  };

  const fetchAccountTokens = async () => {
    setLoadingAccountTokens(true);
    // const accountTokens = await getAccountTokens(userEthAddr);
    const tokens = await getAccountTokens(myWallet);
    console.log('========>', myWallet, userEthAddr, tokens);
    const accountTokens = tokens?.map((item, index) => {
      const onSale = findInMySeelbooks(
        item.contractAddress,
        item.chain === 'klaytn' ? parseInt(item.extras?.tokenId, 16).toString() : item.token_id
      );
      const newItem = { ...item, sellbook: onSale ? onSale.sellbook : null };
      return newItem;
    });
    // set origin array for  loading time.
    setAccountTokenOrigin(accountTokens);
    accountTokensSorting(accountTokens);
    setLoadingAccountTokens(false);
  };

  const fetchMySellbooks = async () => {
    const resp = await getSellbookInfoByUID(uid);
    console.log('mySellbooks:::', resp.data?.data?.sellbooks);
    if (resp.data?.data?.sellbooks) setMySellbooks(resp.data?.data?.sellbooks);
  };

  useEffect(() => {
    fetchMySellbooks();
    fetchAccountTokens();
  }, [account, myWallet]);
  useEffect(() => {
    accountTokensSorting(accountTokenOrigin);
  }, [account, accountTokenOrigin, selectType, selectChains, selectedIndex]);

  useEffect(() => {
    if (!selectChains?.ethereum && !selectChains?.polygon && !selectChains?.klaytn && !selectChains?.binance) {
      setIsClickAllChains(true);
    }
    if (!selectType?.fixed && !selectType?.auction && !selectType?.drops) {
      setIsClickAllType(true);
    }
    const setClickFixed = selectType?.fixed ? 1 : 0;
    const setClickAuction = selectType?.auction ? 1 : 0;
    const setClickDrops = selectType?.drops ? 1 : 0;
    const setClickNotlisted = selectType?.notlisted ? 1 : 0;
    const setClickEthereum = selectChains?.ethereum ? 1 : 0;
    const setClickPolygon = selectChains?.polygon ? 1 : 0;
    const setClickKlaytn = selectChains?.klaytn ? 1 : 0;
    const setClickBinance = selectChains?.binance ? 1 : 0;
    setIsTotalFilter(
      setClickFixed +
        setClickAuction +
        setClickDrops +
        setClickNotlisted +
        setClickEthereum +
        setClickPolygon +
        setClickKlaytn +
        setClickBinance
    );
  }, [selectChains, selectType]);
  useEffect(() => {
    const numDrops = filter === 'All Items' || filter === 'Drops' ? myMBoxList?.length : 0;
    const numOnchain = filter === 'All Items' || filter === 'On-chain' ? onchainItems?.length : 0;
    setCount(numDrops + numOnchain);
  }, [myMBoxList, onchainItems, filter]);

  useEffect(() => {
    if (!isLogin) location.replace('/accounts?redirectUrl=/my-items');
  }, []);

  const findInMySeelbooks = (contract: string, tokenId: number) => {
    let selected = null;
    const sellbook = mySellbooks.filter((sellbook: any) => {
      if (sellbook.nftInfo.contractAddress.toLowerCase() === contract.toLowerCase() && sellbook.tokenId === tokenId) {
        selected = sellbook;
        return true;
      } else return false;
    });
    if (sellbook?.length > 0) return { result: true, sellbook: selected };
    else return { result: false, sellbook: null };
  };

  const handleRevealClick = async (mboxInfo: any) => {
    setIsLoading(true);

    try {
      let provider;
      if (login === 'sns') {
        provider = await HelperUtil.getAbcWeb3Provider(mboxInfo.chainId);
      }

      const setApprove = await setApproveForAll(
        mboxInfo?.keyContractAddress,
        mboxInfo?.boxContractAddress,
        myWallet,
        login === 'sns' ? provider : library,
        isKaikas
      );

      if (!setApprove) {
        setIsLoading(false);
        throw `setApproveFailed:::${setApprove}`;
      }
      const result: number = await claimMysteryBox(
        mboxInfo?.boxContractAddress,
        balance,
        myWallet,
        login === 'sns' ? provider : library,
        isKaikas
      );
      // setOpenSnackbar({
      //   show: true,
      //   color: result === SUCCESS ? 'green' : 'red',
      //   message: result === SUCCESS ? 'Success Reveal.' : 'Failed Reveal',
      // });
      const mysteryboxData = {
        mysterybox_id: mboxInfo?.id,
        buyer: uid,
        buyer_address: userEthAddr,
      };
      if (result === SUCCESS) {
        await requestSetIsRevealed(mysteryboxData);
      }
      setOpenSnackbar({
        open: true,
        type: 'success',
        message: 'Success',
      });
      fetchBalance(mboxInfo);
      setIsRevealed(true);
    } catch (error) {
      console.log(error);
      setOpenSnackbar({
        open: true,
        type: 'error',
        message: 'Failed.',
      });
      // setOpenSnackbar({ show: true, color: 'red', message: 'Failed Reveal.' });
      setIsLoading(false);
      return false;
    }
    setIsLoading(false);
  };

  const handleClaimClick = async (mboxInfo: any) => {
    setIsLoading(true);
    try {
      let provider;
      if (login === 'sns') {
        provider = await HelperUtil.getAbcWeb3Provider(mboxInfo.chainId);
      }
      const web3Provider = login === 'sns' ? provider : library;
      const chainId = await web3Provider.getSigner().getChainId();
      // eslint-disable-next-line max-len
      const message = `apps.talken.io wants you to sign in with your Ethereum account. Talken Drops Signature Request Type: Claim NFTs: ${claimableCount}`;
      const domain = { chainId }; // All properties on a domain are optional
      const types = { Mail: [{ name: 'contents', type: 'string' }] };
      const mail = { contents: message }; // The data to sign

      const signature = await web3Provider
        .getSigner()
        ._signTypedData(domain, types, mail)
        .catch(() => deactivate());
      if (!signature) return; // 서명 거부
      const msgParams = JSON.stringify({ domain, types, mail });
      const data = {
        mysterybox_id: mboxInfo?.id,
        contract: mboxInfo?.boxContractAddress,
        msgParams,
        signature,
      };

      console.log('!! ABC signTypedMessageV4 :::::', { msgParams, signature });
      const res = await requestClaim(data);
      setOpenSnackbar({
        open: true,
        type: 'success',
        message: 'Success',
      });
      await fetchMyMBoxList();
      await fetchAccountTokens();
    } catch (error) {
      console.log(error);
      setOpenSnackbar({
        open: true,
        type: 'error',
        message: 'Failed.',
      });
      setIsLoading(false);
      return false;
    }
    setIsLoading(false);
  };

  const fetchBalance = async (mboxInfo: any) => {
    if (mboxInfo?.isCollection !== true && mboxInfo?.keyContractAddress !== null) {
      const balance = await getKeyBalance(mboxInfo?.keyContractAddress, myWallet, null, false);
      const balanceData = {
        balance: balance,
        keyContractAddress: mboxInfo?.keyContractAddress,
      };
      _balance.push(balanceData);
      setBalances(_balance);
    }

    const count = await getItemBalance(mboxInfo?.boxContractAddress, myWallet, null, false);
    const itemData = {
      balance: count,
      boxContractAddress: mboxInfo?.boxContractAddress,
    };
    _items?.push(itemData);
    setMyItems(_items);

    // const talkenData = localStorage.getItem('talken.data');
    // let _talkenData;
    // let talkenUid = null;
    // if (talkenData) {
    //   _talkenData = JSON.parse(talkenData);
    //   talkenUid = _talkenData.uid;
    // }
    if (uid) {
      const claimableCount = await getClaimableCount(mboxInfo?.id || 0, uid);
      setClaimableCount(claimableCount.data?.data || 0);
    }
  };

  const fetchRevealItem = async (mboxInfo: any) => {
    const items = await getItemBalance(mboxInfo?.boxContractAddress, myWallet, null, false);
    let tokenURI: string[] = [];
    let tokenIds: number[] = [];
    if (items > 0) {
      tokenURI = await getItemMetadata(mboxInfo?.boxContractAddress, items, myWallet, null, false);
      // TODO : Comment out to display only revealed items
      // } else {
      //   tokenURI[0] = await getKeyMetadata(
      //     mboxInfo?.keyContractAddress,
      //     account,
      //     library
      //   );
      tokenIds = await getTokenIds(mboxInfo?.boxContractAddress, items, myWallet, null, false);
    }

    if (tokenURI?.length > 0) {
      const result = await Promise.all(
        tokenURI.map(async (uri, index) => {
          const res = await axios.get(uri);
          const rlt = await getItemPrice(uri);
          res.data.price = rlt.data?.data?.price ?? '-';
          res.data.no = rlt.data?.data?.no;
          res.data.tokenId = tokenIds[index];
          res.data.companyname = mboxInfo?.companyname;
          res.data.companyimage = mboxInfo?.companyimage;
          res.data.mboxInfo = mboxInfo;
          return res.data;
        })
      );
      _revealItemsList.push(result);
      console.log('revealItems:::', _revealItemsList);
      setRevealItemsList(_revealItemsList);
    }
  };

  const closeLogin = () => {
    setLoginOpen(false);
  };
  const closeSignup = () => {
    setSignupOpen(false);
  };
  const openListForSale = (item) => {
    setSelectedItem(item);
    setTypeList(false);
    setIsOpenNFTModdal(true);
  };
  const { openTransferItem, closeTransferItem } = useOpenTransferItem();
  const { openTransferItemSuccess, closeTransferItemSuccess } = useOpenTransferItemSuccess();

  const handleFilter = (selectedValue: any) => {
    console.log('========>', selectedValue);
    setFilter(selectedValue);
  };
  const handleExpand = () => {
    if (!isExpanded && isMobile) setIsShowSort(false);
    setExpanded(!isExpanded);
  };
  const handleSort = () => {
    if (!isShowSort && isMobile) setExpanded(false);
    setIsShowSort(!isShowSort);
  };
  const handleClickedChains = (chain: 'ethereum' | 'polygon' | 'klaytn' | 'binance') => {
    const newSelectChains = { ...selectChains, [chain]: !selectChains[chain] };
    setSelectChains(newSelectChains);
    setIsClickAllChains(false);
  };
  const clickButtonAllChains = () => {
    setIsClickAllChains(true);
    setSelectChains({
      klaytn: false,
      ethereum: false,
      polygon: false,
      binance: false,
    });
  };
  const handleClickedType = (type: 'fixed' | 'auction' | 'drops' | 'notlisted') => {
    const newSelectType = { ...selectType, [type]: !selectType[type] };
    setSelectType(newSelectType);
    setIsClickAllType(false);
  };
  const clickButtonAllType = () => {
    setIsClickAllType(true);
    setSelectType({
      fixed: false,
      auction: false,
      drops: false,
      notlisted: false,
    });
  };
  return (
    <main className="my-wallet-container min-height-content">
      {!isMobile && <AggregateUserInfor />}
      {/*check*/}
      <div className="wallet-wp">
        {/* banner  */}
        <div className="banner mb-4">
          <div className="collectibles">
            <div className="banner-title">My Items</div>
          </div>
        </div>
        {/* {isMobile && <AggregateUserInfor />} */}

        {/* list button sort  */}
        <div className="wallet-sort m-0 p-t-0">
          {/* <div className="count-label">{`${!Number.isNaN(count) ? count : 0} items`}</div> */}
          {/*<Form.Select*/}
          {/*  className="border-dark form-select-short d-inline-block py-2 fs-14 dark:bg-select-lst text-black dark:text-white"*/}
          {/*  onChange={handleFilter}*/}
          {/*>*/}
          {/*  <option>All Items</option>*/}
          {/*  <option>On-chain</option>*/}
          {/*  <option>Drops</option>*/}
          {/*</Form.Select>*/}
          {/* <Select
            isSearchable={false}
            options={optionAllItem}
            defaultValue={optionAllItem[0]}
            styles={styleSelectRecently}
            components={{
              IndicatorSeparator: () => null,
            }}
            onChange={(selectedOption) => {
              handleFilter(selectedOption.value);
            }}
          /> */}
        </div>
        {/* <div className="line mt-2 m-b-20"></div> */}
        <div className={`wallet-sort mt-0 pt-0 p-t-xs-16 ${isMobile ? 'flex-column' : ''}`}>
          {/*<Form.Select className="border-dark form-select-short d-inline-block py-2 fs-14 dark:bg-select-lst text-black dark:text-white">*/}
          {/*  <option>Chains</option>*/}
          {/*  <option>Ethereum</option>*/}
          {/*  <option>Polygon</option>*/}
          {/*  <option>Klaytn</option>*/}
          {/*</Form.Select>*/}
          {/* <Select
            isSearchable={false}
            options={optionsChains}
            defaultValue={optionsChains[0]}
            styles={styleSelectRecently}
            components={{
              IndicatorSeparator: () => null,
            }}
            onChange={(selectedOption) => {
              handleFilter(selectedOption.value);
            }}
          /> */}
          <div className={`align-items-center d-flex gap-16px ${isMobile ? 'w-100 justify-content-between' : ''}`}>
            <div className="fs-24 fw-5">
              Items <span className="fw-400 text-2f8af5">{!Number.isNaN(count) ? count : 0}</span>
            </div>
            <div className="d-flex align-items-center d-flex gap-8px">
              <div
                className={`${
                  isShowSort
                    ? 'dark:stroke-updown-select stroke-black updown-border-select'
                    : 'dark:stroke-updown stroke-black updown-border'
                } align-items-center button d-flex gap-10px h-100 hidden-pc justify-content-between max-h-30px max-w-30px pe-2 pointer-event ps-2 rounded-3 w-auto`}
                onClick={() => handleSort()}
              >
                <ArrowUpDown width={21} height={30} />
              </div>
              <div
                className={`button gray-border pointer-event rounded-3 d-flex justify-content-between align-items-center w-auto gap-10px h-36px h-xs-30px svg-path-black dark:svg-path-white ${
                  isExpanded
                    ? 'dark:svg-path-updown-select updown-border-select'
                    : 'dark:svg-path-updown updown-border '
                } ${isMobile ? 'col-4 ps-2 pe-2' : 'col-4 p-l-10 p-r-10 hover-opa-7'}`}
                onClick={() => handleExpand()}
              >
                <MenuSelect width={isMobile ? 12 : 14} height={16} />
                {isTotalFilter > 0 && (
                  <div className={`fw-400 fs-xs-12 ${isExpanded ? 'text-2f8af5' : ''}`}>{isTotalFilter}</div>
                )}
              </div>
            </div>
          </div>
          {isShowSort && (
            <div
              className={`sort-box updown-border p-b-4 p-l-12 p-r-12 p-t-4 border-radius-8 hover-cusor-pointer ${
                isMobile ? 'w-100 m-t-16' : ''
              }`}
              ref={ref}
              onClick={() => setOpen((open) => !open)}
            >
              <span className={`sort-selected`}>
                {appState.objects.map((item: any, index: number) => {
                  return (
                    <div
                      className={`${selectedIndex === index ? 'name-sort-selected' : 'not-selected'}`}
                      style={isMobile ? { marginLeft: '10px' } : {}}
                      key={index}
                    >
                      {item.name?.en || item.name}
                    </div>
                  );
                })}
              </span>
              <div className={`dark:fill-525298 fill-black sort-dropdown-button`}>
                {/* <img src={ic_dropdown_button_arrow} alt="" /> */}
                <IconDropdown />
              </div>
              {open && (
                <ul className="sort-dropdown-box w-100">
                  {appState.objects.map((item: any, index: number) => {
                    return (
                      <li key={index} className="name-sort-dropdown-box">
                        <button
                          type="button"
                          className={`sort-button`}
                          onClick={() => {
                            handleSortClick(item);
                            toggleActive(index);
                            setSelectedIndex(index);
                            setOpen(false);
                          }}
                        >
                          <span style={isMobile ? { fontSize: '14px' } : { fontSize: '16px' }}>
                            {item.name?.en || item.name}
                          </span>
                          <span>{isItemInSelection(item) && ''}</span>
                        </button>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          )}
          {/*<div ref={ref}>*/}
          {/*  <Form.Select*/}
          {/*    className="border-dark form-select-short d-inline-block py-2 fs-14 dark:bg-select-lst text-black dark:text-white"*/}
          {/*    onChange={(e) => {*/}
          {/*      const selectedIndex = parseInt(e.target.value, 10);*/}
          {/*      const selectedObject = appState.objects[selectedIndex];*/}
          {/*      handleSortClick(selectedObject);*/}
          {/*      toggleActive(selectedIndex);*/}
          {/*      setSelectedIndex(selectedIndex);*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {appState.objects.map((item: any, index: number) => (*/}
          {/*      <option key={index} value={index}>*/}
          {/*        {item.name}*/}
          {/*      </option>*/}
          {/*    ))}*/}
          {/*  </Form.Select>*/}
          {/*</div>*/}
        </div>
        {/* filter  */}
        {isExpanded && (
          <div className="d-grid w-100 fs-16 fs-xs-12 p-l-10 fw-7 gap-8px gap-xs-4px p-l-xs-0 p-b-6">
            <div className="d-flex gap-16px lh-16">
              <div className={`mode-color-white ${isMobile ? 'w-auto min-w-50px' : 'w-6'}`}>Chains</div>
              <div className="d-flex flex-wrap dark:text-525298 gap-16px gap-0-16 text-black fw-400">
                <Form.Check
                  checked={isClickAllChains}
                  label="All"
                  id="all-chains"
                  onChange={clickButtonAllChains}
                  className={isClickAllChains ? 'text-2f8af5' : ''}
                />
                <Form.Check
                  checked={selectChains['ethereum']}
                  label="Ethereum"
                  id="ethereum"
                  onChange={() => handleClickedChains('ethereum')}
                  className={selectChains['ethereum'] ? 'text-2f8af5' : ''}
                />
                <Form.Check
                  checked={selectChains['binance']}
                  label="BNB Chain"
                  id="binance"
                  onChange={() => handleClickedChains('binance')}
                  className={selectChains['binance'] ? 'text-2f8af5' : ''}
                />
                <Form.Check
                  checked={selectChains['polygon']}
                  label="Polygon"
                  id="polygon"
                  onChange={() => handleClickedChains('polygon')}
                  className={selectChains['polygon'] ? 'text-2f8af5' : ''}
                />
                <Form.Check
                  checked={selectChains['klaytn']}
                  label="Klaytn"
                  id="klaytn"
                  onChange={() => handleClickedChains('klaytn')}
                  className={selectChains['klaytn'] ? 'text-2f8af5' : ''}
                />
              </div>
            </div>
            <div className="d-flex gap-16px lh-16">
              <div className={`mode-color-white ${isMobile ? 'w-auto min-w-50px' : 'w-6'}`}>Type</div>
              <div className="d-flex dark:text-525298 gap-16px text-black fw-400">
                <Form.Check
                  checked={isClickAllType}
                  label="All"
                  id="all-types"
                  onChange={clickButtonAllType}
                  className={isClickAllType ? 'text-2f8af5' : ''}
                />
                <Form.Check
                  checked={selectType['fixed']}
                  label="Fixed"
                  id="fixed"
                  onChange={() => handleClickedType('fixed')}
                  className={selectType['fixed'] ? 'text-2f8af5' : ''}
                />
                <Form.Check
                  checked={selectType['auction']}
                  label="Auction"
                  id="auction"
                  onChange={() => handleClickedType('auction')}
                  className={selectType['auction'] ? 'text-2f8af5' : ''}
                />
              </div>
            </div>
          </div>
          // <div className="m-b-40 m-b-xs-20">
          //   <div className="mt-3 fs-xs-12 d-flex gap-4 gap-xs-16px m-b-10">
          //     <div className={`d-flex justify-content-between gap-14px gap-xs-12px ${isMobile ? 'col-3' : 'col-2'}`}>
          //       <span className={`pt-2`}>Chains</span>
          //       <div
          //         className={`button gray-border p-l-10 p-r-10 py-2 pointer-event rounded-2 text-center ${
          //           isMobile ? 'h-xs-36px' : 'hover-opa-7'
          //         } ${isClickAllChains ? 'bg-clicked-item-filter' : ''}`}
          //         onClick={clickButtonAllChains}
          //       >
          //         All
          //       </div>
          //     </div>
          //     <div className="d-flex gap-20px gap-xs-12px flex-wrap">
          //       <div
          //         className={`button gray-border p-l-10 p-r-10 py-2 pointer-event rounded-2 text-center ${
          //           isMobile ? '' : 'hover-opa-7'
          //         } ${selectChains.ethereum ? 'bg-clicked-item-filter' : ''}`}
          //         onClick={() => handleClickedChains('ethereum')}
          //       >
          //         Ethereum
          //       </div>
          //       <div
          //         className={`button gray-border p-l-10 p-r-10 py-2 pointer-event rounded-2 text-center ${
          //           isMobile ? '' : 'hover-opa-7'
          //         } ${selectChains.binance ? 'bg-clicked-item-filter' : ''}`}
          //         onClick={() => handleClickedChains('binance')}
          //       >
          //         BNB Chain
          //       </div>
          //       <div
          //         className={`button gray-border p-l-10 p-r-10 py-2 pointer-event rounded-2 text-center ${
          //           isMobile ? '' : 'hover-opa-7'
          //         } ${selectChains.polygon ? 'bg-clicked-item-filter' : ''}`}
          //         onClick={() => handleClickedChains('polygon')}
          //       >
          //         Polygon
          //       </div>
          //       <div
          //         className={`button gray-border p-l-10 p-r-10 py-2 pointer-event rounded-2 text-center ${
          //           isMobile ? '' : 'hover-opa-7'
          //         } ${selectChains.klaytn ? 'bg-clicked-item-filter' : ''}`}
          //         onClick={() => handleClickedChains('klaytn')}
          //       >
          //         Klaytn
          //       </div>
          //     </div>
          //   </div>
          //   <div className="mt-3 fs-xs-12 d-flex gap-4 gap-xs-16px m-b-10">
          //     <div
          //       className={`d-flex justify-content-between gap-14px gap-xs-12px ${
          //         isMobile ? 'col-3 h-xs-36px' : 'col-2'
          //       }`}
          //     >
          //       <span className={`pt-2`}>Type</span>
          //       <div
          //         className={`button gray-border p-l-10 p-r-10 py-2 pointer-event rounded-2 text-center ${
          //           isMobile ? '' : 'hover-opa-7'
          //         } ${isClickAllType ? 'bg-clicked-item-filter' : ''}`}
          //         onClick={clickButtonAllType}
          //       >
          //         All
          //       </div>
          //     </div>
          //     <div className="d-flex gap-20px gap-xs-12px flex-wrap">
          //       <div
          //         className={`button gray-border p-l-10 p-r-10 py-2 pointer-event rounded-2 text-center ${
          //           isMobile ? '' : 'hover-opa-7'
          //         } ${selectType.drops ? 'bg-clicked-item-filter' : ''}`}
          //         onClick={() => handleClickedType('drops')}
          //       >
          //         Drops
          //       </div>
          //       <div
          //         className={`button gray-border p-l-10 p-r-10 py-2 pointer-event rounded-2 text-center ${
          //           isMobile ? '' : 'hover-opa-7'
          //         } ${selectType.fixed ? 'bg-clicked-item-filter' : ''}`}
          //         onClick={() => handleClickedType('fixed')}
          //       >
          //         Fixed price
          //       </div>
          //       <div
          //         className={`button gray-border p-l-10 p-r-10 py-2 pointer-event rounded-2 text-center ${
          //           isMobile ? '' : 'hover-opa-7'
          //         } ${selectType.auction ? 'bg-clicked-item-filter' : ''}`}
          //         onClick={() => handleClickedType('auction')}
          //       >
          //         Auction
          //       </div>

          //       <div
          //         className={`button gray-border p-l-10 p-r-10 py-2 pointer-event rounded-2 text-center ${
          //           isMobile ? '' : 'hover-opa-7'
          //         } ${selectType.notlisted ? 'bg-clicked-item-filter' : ''}`}
          //         onClick={() => handleClickedType('notlisted')}
          //       >
          //         Not Listed
          //       </div>
          //     </div>
          //   </div>
          // </div>
        )}
        {/* item  */}
        <div className="page-grid w-100 p-r-1">
          {/* {myMBoxList.map((item) => (
            <myMBoxList key={item.id} item={item} />
          ))} */}
          {(filter === 'All Items' || filter === 'Drops') &&
            myMBoxList.map((item, index) => {
              // const matchingBalances = balances.filter(
              //   (balance) => item.keyContractAddress === balance?.keyContractAddress
              // );
              const isRevelable =
                balances.find((balance) => item.keyContractAddress === balance?.keyContractAddress)?.balance > 0;
              const myBalance = myItems.find(
                (myItem) => item.boxContractAddress === myItem.boxContractAddress
              )?.balance;
              // if (matchingBalances.length > 0) {
              // return matchingBalances.map((matchingBalance, balanceIndex) => (
              return (
                <div className="my-collectibles" key={index}>
                  {/* <Link to={'/my-items/details'} className="my-collectibles" key={index} state={{ item }}> */}
                  {/* <div
                    key={`${item.keyContractAddress}_${balanceIndex}`}
                    onClick={() => {
                      !isWalletConnected
                        ? setLoginOpen(true)
                        : claimableCount > 0
                        ? undefined
                        : handleRevealClick(item);
                    }}
                    className="my-collectibles"
                  > */}
                  <div className="item-product">
                    <div className="item-product-detail">
                      <div className="card-image">
                        {item?.packageImage?.split('.')?.pop() === 'mp4' ? (
                          <video
                            playsInline
                            webkit-playsinline="true"
                            muted
                            loop
                            controlsList="nodownload"
                            width={'100%'}
                            onError={HandleMediaError}
                            key={`${item.packageImage
                              ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                              .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}#t=0.001`}
                          >
                            <source
                              src={`${item.packageImage
                                ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                                .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}#t=0.001`}
                              type="video/mp4"
                            />
                          </video>
                        ) : (
                          <img src={item.packageImage} alt="" draggable={false} onError={HandleMediaError} />
                        )}
                      </div>
                    </div>
                    <div className="item-product-detail" style={{ paddingBottom: '4px' }}>
                      <div className="flex-start gap-8px p-b-8">
                        <div className="blockchain-symbol">
                          <img
                            src={
                              item.quote === 'KLAY'
                                ? klaytnIcon
                                : item.quote === 'ETH'
                                ? eth
                                : item.quote === 'MATIC'
                                ? polygon
                                : item.quote === 'BNB'
                                ? binance
                                : talk
                            }
                            alt="chain"
                          />
                        </div>
                        <div className="fw-7 text-over-ellip fs-12 color-name-box p-b-8">
                          {item.title.en.length > 25 ? `${item.title.en.slice(0, 22)}...` : item.title.en}
                        </div>
                      </div>
                      <div className="box-info">
                        <div className="box-product-name">
                          {/*<div className="wrapper-product-type">*/}
                          {/*  <div className="avatar">*/}
                          {/*    <img src={item.companyimage} alt="Avatar" />*/}
                          {/*  </div>*/}
                          {/*  <div className="product-type">{item.companyname.en}</div>*/}
                          {/*</div>*/}
                          <div className="product-name line-clamp-1">
                            {item.title.en.length > 30 ? item.title.en.slice(0, 28) + '...' : item.title.en}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item-product-detail mx-auto flex-between">
                      {
                        //   !isWalletConnected ? (
                        //   <Col
                        //     xs="8"
                        //     className="bg-button-sale"
                        //     onClick={() => setLoginOpen(true)}
                        //   >
                        //     Connect Wallet
                        //   </Col>
                        // ) :
                        // claimableCount > 0 ? (
                        isRevelable ? (
                          <div className="bg-button-sale" onClick={() => handleRevealClick(item)}>
                            {isLoading ? <CircularProgress size={16} color={'inherit'} /> : 'Reveal'}
                          </div>
                        ) : (
                          <div className="bg-button-sale" onClick={() => handleClaimClick(item)}>
                            {isLoading ? <CircularProgress size={16} color={'inherit'} /> : 'Claim'}
                          </div>
                        )
                      }
                      <button
                        className={`button button-transfer-box disabled-send`}
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   openTransferItem();
                        //   setTransferItem(item);
                        // }}
                        disabled={true}
                      >
                        <SendCircle />
                      </button>
                    </div>
                  </div>
                </div>
                // </Link>
              );
              // ));
              // } else {
              //   return null;
              // }
            })}

          {(filter === 'All Items' || filter === 'On-chain') &&
            onchainItems?.map((item, index) => {
              const onSale = findInMySeelbooks(
                item.contractAddress,
                item.chain === 'klaytn' ? parseInt(item.extras?.tokenId, 16).toString() : item.token_id
              );
              item.sellbook = onSale.sellbook;
              return (
                <Link
                  to={
                    !onSale.result
                      ? '/my-items/details'
                      : `/sellbook/${item.sellbook?.id}/${getNetworkNameById(
                          item?.chain === 'klaytn' ? env.REACT_APP_TARGET_NETWORK_KLAY : env.REACT_APP_TARGET_NETWORK
                        )?.toLowerCase()}/${item.contractAddress}/${
                          item.chain === 'klaytn' ? parseInt(item.extras?.tokenId, 16).toString() : item.token_id
                        }`
                  }
                  className="my-collectibles"
                  key={index}
                  state={!onSale.result ? { item } : onSale.sellbook}
                >
                  <div className="item-product">
                    <div className="item-product-detail">
                      <div className="card-image position-relative">
                        {item?.alt_url?.split('.').pop() === 'mp4' ||
                        item?.extension === 'mp4' ||
                        item?.metadata?.alt_url?.split('.').pop() === 'mp4' ||
                        item?.metadata?.extension === 'mp4' ||
                        item?.image?.split('.')?.pop() === 'mp4' ||
                        item?.metadata?.image?.split('.')?.pop() === 'mp4' ? (
                          <>
                            <video
                              playsInline
                              webkit-playsinline="true"
                              muted
                              loop
                              controlsList="nodownload"
                              width={'100%'}
                              onError={HandleMediaError}
                              key={`${(item.image || item.metadata?.image)
                                ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                                .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}#t=0.001`}
                            >
                              <source
                                src={`${(item.image || item.metadata?.image)
                                  ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                                  .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}#t=0.001`}
                                type="video/mp4"
                              />
                            </video>
                            {onSale.result && (
                              <div
                                className="px-2 py-1 rounded-5 price-type-box left-xs-4"
                                style={{ position: 'absolute', top: '4%', left: '6%' }}
                              >
                                {item?.sellbook?.type == 2 ? 'Auction' : 'Fixed price'}
                              </div>
                            )}
                            {(Number(item.balance) > 1 || item.amount > 1) && (
                              <div className="collectibles-item-amount">
                                {`x${Number(item.balance) || item.amount}`}
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <img
                              src={
                                item.alt_url ||
                                item.image ||
                                item.metadata?.alt_url ||
                                item.metadata?.image
                                  ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                                  .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')
                              }
                              alt=""
                              draggable={false}
                              onError={HandleMediaError}
                            />
                            {onSale.result && (
                              <div
                                className="px-2 py-1 rounded-5 price-type-box left-xs-4"
                                style={{ position: 'absolute', top: '4%', left: '6%' }}
                              >
                                {item?.sellbook?.type == 2 ? 'Auction' : 'Fixed price'}
                              </div>
                            )}
                            {(Number(item.balance) > 1 || item.amount > 1) && (
                              <div className="collectibles-item-amount">
                                {`x${Number(item.balance) || item.amount}`}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="item-product-detail" style={{ paddingBottom: '4px' }}>
                      <div className="flex-start gap-8px p-b-8">
                        <div className="blockchain-symbol">
                          <img
                            src={
                              item.chain === 'klaytn'
                                ? klaytnIcon
                                : item.chain === 'ethereum'
                                ? eth
                                : item.chain === 'polygon'
                                ? polygon
                                : item.chain === 'binance'
                                ? binance
                                : talk
                            }
                            alt="chain"
                          />
                        </div>
                        <div className="fw-7 text-over-ellip fs-12 color-name-box">
                          {item?.contractName
                            ? item?.contractName?.length > 25
                              ? item?.contractName.slice(0, 22) + '...'
                              : item?.contractName
                            : typeof item.name === 'string'
                            ? item.name?.length > 25
                              ? item.name.slice(0, 22) + '...'
                              : item.name
                            : item.name?.en}
                        </div>
                      </div>

                      <div className="box-info">
                        <div className="box-product-name">
                          {/* TODO : How we can get the creator avatar and name ? */}
                          {/*<div className="wrapper-product-type">*/}
                          {/*  <div className="avatar">*/}
                          {/*    <img src={item?.companyimage} alt="Avatar" />*/}
                          {/*  </div>*/}
                          {/*  <div className="product-type">{item?.companyname?.en}</div>*/}
                          {/*</div>*/}
                          {/* <div className="product-name">{item.title.en}</div> */}
                          <div className="product-name line-clamp-1">
                            {item.metadata?.name
                              ? item.metadata?.name
                              : typeof item.name === 'string'
                              ? item.name?.length > 30
                                ? item.name.slice(0, 28) + '...'
                                : item.name
                              : item.name?.en}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item-product-detail mx-auto flex-between">
                      {!onSale.result ? (
                        <div
                          className={`bg-button-sale w-list-sale ${
                            (item?.contract_type === 'ERC1155' || item?.kind === 'mt') && 'collectibles-disable-list'
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            if (!(item?.contract_type === 'ERC1155' || item?.kind === 'mt')) {
                              openListForSale(item);
                            } else {
                              setOpenSnackbar({
                                open: true,
                                type: 'success',
                                message: 'NFT in ERC-1155 standard will be supported soon.',
                              });
                            }
                          }}
                        >
                          List for Sale
                        </div>
                      ) : (
                        <div>
                          <Link
                            to={`/sellbook/${item.sellbook?.id}/${getNetworkNameById(
                              item?.chain === 'ethereum'
                                ? env.REACT_APP_TARGET_NETWORK_ETH
                                : item?.chain === 'klaytn'
                                ? env.REACT_APP_TARGET_NETWORK_KLAY
                                : item?.chain === 'polygon'
                                ? env.REACT_APP_TARGET_NETWORK_MATIC
                                : item?.chain === 'binance'
                                ? env.REACT_APP_TARGET_NETWORK_BNB
                                : env.REACT_APP_TARGET_NETWORK
                            )?.toLowerCase()}/${item.contractAddress}/${
                              item.chain === 'klaytn' ? parseInt(item.extras?.tokenId, 16).toString() : item.token_id
                            }`}
                            key={index}
                            // state={{ item }}
                            state={item?.sellbook}
                            className="border-0 bg-button-sale w-sale-info"
                          >
                            Sales Info
                          </Link>
                        </div>
                      )}
                      <button
                        className={`button button-transfer-box ${onSale.result && 'disabled-send'}`}
                        onClick={(e) => {
                          e.preventDefault();
                          openTransferItem();
                          setTransferItem(item);
                        }}
                        disabled={onSale.result ? true : false}
                      >
                        <SendCircle />
                      </button>
                    </div>
                  </div>
                </Link>
              );
            })}
          {(loadingAccountTokens || loadingMyMBoxList) &&
            [1, 2, 3, 4].map((item) => (
              <div className="my-collectibles" key={item}>
                <Skeleton key={item} style={screenSize > 768 ? { height: '318px' } : { height: '262px' }} />
              </div>
            ))}
        </div>
      </div>
      <Popup
        modal
        open={loginOpen}
        onOpen={closeSignup}
        onClose={closeLogin}
        {...{ overlayStyle, closeOnDocumentClick, lockScroll }}
      >
        {/* <LoginForm close={closeLogin} onConfirm={() => setSignupOpen(true)} /> */}
        <WalletConnector close={closeLogin} onConfirm={() => setSignupOpen(true)} />
      </Popup>
      {isOpenListNFTModal && (
        <ListNFTModal
          show={isOpenListNFTModal}
          onHide={() => setIsOpenNFTModdal(false)}
          typeSale={typeListNFT}
          onListingComplete={(sellData) => {
            setIsCompleteListing(true);
            setSellData(sellData);
          }}
          data={selectedItem}
        ></ListNFTModal>
      )}
      {isCompleteListing && (
        <ListingCompleteModal
          show={isCompleteListing}
          onHide={() => {
            setIsCompleteListing(false);
            window.location.reload();
          }}
          data={selectedItem}
          sellData={sellData}
        ></ListingCompleteModal>
      )}
      <TransferItem
        selected={transferItem}
        setReload={setReload}
        openWinningComplete={(data) => {
          closeTransferItem();
          setDataItemTransfer(data);
          openTransferItemSuccess();
        }}
      />
      <CSnackbar
        open={openSnackbar.open}
        type={openSnackbar.type}
        message={openSnackbar.message}
        handleClose={handleCloseSnackbar}
      />
      {/*<TransferItemOTP />*/}
      <TransferItemSuccess
        selected={transferItem}
        dataItemTransfer={dataItemTransfer}
        setReload={() => {
          closeTransferItemSuccess();
          window.location.reload();
        }}
      />
    </main>
  );
};

export default MyCollectibles;
