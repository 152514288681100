import ReactModal from 'react-modal';
import { ReactComponent as IconClose } from 'assets/icon/close_icon.svg';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

type MintErc = {
  setReload?: any;
  openWinningComplete?: any;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  contractAddress: string;
  uri: string;
  to: string;
  currentNetwork: string;
  currentIcon: any;
  currentTokenId: any;
};

const MintErc: React.FC<MintErc> = ({
  isOpen,
  onConfirm,
  onClose,
  contractAddress,
  uri,
  to,
  currentNetwork,
  currentIcon,
  currentTokenId,
}) => {
  const [isConfirming, setConfirming] = useState(false);

  const handleConfirmClick = async () => {
    setConfirming(true);
    await onConfirm();
    setConfirming(false);
    onClose();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className="modal-primary w-540px w-xs-338px h-fit-content padding-modal">
        <div className="close-button-modal" onClick={onClose}>
          <IconClose />
        </div>
        <div className="fs-24 fs-xs-16 fw-bold text-center">Mint ERC721 NFT</div>
        <div className="line-banner"></div>
        <div className="item-mint-admin">
          <div className="content-01">Contract address</div>
          <div className="content-02">{contractAddress}</div>
        </div>
        <div className="item-mint-admin align-items-center">
          <div className="content-01">Chain</div>
          <div className="content-02 d-flex align-items-center">
            <img width={24} height={24} className="m-r-4" src={currentIcon} alt="" />
            {currentNetwork}
          </div>
        </div>
        <div className="item-mint-admin">
          <div className="content-01">Token ID</div>
          <div className="content-02">{Number(currentTokenId) + 1}</div>
        </div>
        <div className="item-mint-admin">
          <div className="content-01">Token URI</div>
          <div className="content-02 line-clamp-2" data-tip={uri}>
            {uri}
          </div>
          <ReactTooltip
            className="tooltip-mint-admin"
            place="top"
            type="dark"
            effect="solid"
            multiline={true}
          ></ReactTooltip>
        </div>
        <div className="item-mint-admin">
          <div className="content-01">To Address</div>
          <div className="content-02">{to}</div>
        </div>
        <button
          className="button btn-detail-nft m-t-12 m-t-xs-30 mb-0 h-50px"
          onClick={handleConfirmClick}
          disabled={isConfirming}
        >
          {isConfirming ? 'Confirming...' : 'Confirm'}
        </button>
      </div>
    </ReactModal>
  );
};
export default MintErc;
