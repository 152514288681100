import { BigNumber, ethers } from 'ethers';
import { mysteryBoxAbi } from '../config/abi/MysteryBox';
import { erc721Abi } from '../config/abi/ERC721Token';
import { erc1155Abi } from '../config/abi/ERC1155Token';
import { whiteListAbi } from '../config/abi/WhiteListNFT';
import { subscriptionAbi } from '../config/abi/Subscription';
import { CANCELED, ChainId, FAILURE, RPC_URLS, SUCCESS } from '../config';
import Caver, { AbiItem } from 'caver-js';
import { evenAllocAbi } from '../config/abi/EventAllocation';
import env from '../env';
import tokenAbi from '../config/abi/ERC20Token.json';
import { collectionAbi } from '../config/abi/Collection';
import { collectionData } from '../contracts';
import getSelectedNodeUrl from './getRpcUrl';
import { airDropAbi } from '../config/abi/AirDrop';
import { wklayAbi } from '../config/abi/Wklay';
import { oftV2Abi } from '../config/abi/OftV2';
import { proxyOftV2Abi } from '../config/abi/ProxyOftV2';
import { SetStateAction } from 'react';
import axios from 'axios';

const rpcUrl = RPC_URLS[env.REACT_APP_TARGET_NETWORK_KLAY ?? 8217];
const caver = new Caver(rpcUrl);

export function calculateGasMargin(value: BigNumber) {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000));
}

interface txResult {
  status: number;
  txHash?: string;
  tokenId?: number;
  error?: string;
}

interface Overrides {
  value?: string | number;
  from: string | null | undefined;
  gasLimit: BigNumber;
  gasPrice?: string;
}

export async function registerItems(
  address: string,
  uris: string[],
  amounts: string[],
  account: string | undefined | null,
  library: any
): Promise<number> {
  const gasPrice = await caver.rpc.klay.getGasPrice();
  const isKaikas =
    library.provider.bridge !== 'https://bridge.walletconnect.org' &&
    (library.connection.url !== 'metamask' || library.connection.url === 'eip-1193:');

  console.log(isKaikas);
  let contract: any;
  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    const boxAbi: AbiItem[] = mysteryBoxAbi as AbiItem[];
    contract = new caver.klay.Contract(boxAbi, address);
  } else {
    contract = new ethers.Contract(address, mysteryBoxAbi, library?.getSigner());
  }

  let tx;
  // gasLimit 계산
  let gasLimit;

  if (isKaikas) {
    gasLimit = await contract.methods.registerItems(uris, amounts).estimateGas({
      from: account,
    });
  } else gasLimit = await contract.estimateGas.registerItems(uris, amounts);

  // registerItems 요청
  let receipt;
  try {
    let overrides: Overrides = {
      from: account,
      gasLimit: calculateGasMargin(BigNumber.from(gasLimit)),
    };

    if (isKaikas) {
      tx = await contract.methods
        .registerItems(uris, amounts)
        .send(overrides)
        .catch(async (err: any) => {
          return FAILURE;
        });
      if (tx?.status) {
        return SUCCESS;
      } else return FAILURE;
    } else {
      // if (library._network.chainId === 8217)
      overrides = { ...overrides, gasPrice };

      tx = await contract.registerItems(uris, amounts, overrides);

      // receipt 대기
      try {
        receipt = await tx.wait();
      } catch (e) {
        return FAILURE;
      }
      if (receipt.status === 1) {
        return SUCCESS;
      } else return FAILURE;
    }
  } catch (e) {
    console.log(e);
    return FAILURE;
  }
}

export async function batchMint(
  address: string,
  uri: string,
  amount: number,
  mysteryBox: string,
  account: string | undefined | null,
  library: any
): Promise<number> {
  const gasPrice = await caver.rpc.klay.getGasPrice();
  const isKaikas =
    library.provider.bridge !== 'https://bridge.walletconnect.org' &&
    (library.connection.url !== 'metamask' || library.connection.url === 'eip-1193:');
  let contract: any;
  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    const keyAbi: AbiItem[] = erc721Abi as AbiItem[];
    contract = new caver.klay.Contract(keyAbi, address);
  } else {
    contract = new ethers.Contract(address, erc721Abi, library?.getSigner());
  }
  let tx;

  // gasLimit 계산
  let gasLimit;
  if (isKaikas)
    gasLimit = await contract?.methods.safeBatchMintLight(mysteryBox, uri, amount).estimateGas({
      from: account,
    });
  else gasLimit = await contract.estimateGas.safeBatchMintLight(mysteryBox, uri, amount);

  // registerItems 요청
  let receipt;
  try {
    let overrides: Overrides = {
      from: account,
      gasLimit: calculateGasMargin(BigNumber.from(gasLimit)),
    };

    if (isKaikas) {
      tx = await contract.methods
        .safeBatchMintLight(mysteryBox, uri, amount)
        .send(overrides)
        .catch(async (err: any) => {
          return FAILURE;
        });
      if (tx?.status) {
        return SUCCESS;
      } else return FAILURE;
    } else {
      // if (library._network.chainId === 8217)
      overrides = { ...overrides, gasPrice };

      tx = await contract.safeBatchMintLight(mysteryBox, uri, amount, overrides);

      // receipt 대기
      try {
        receipt = await tx.wait();
      } catch (e) {
        return FAILURE;
      }
      if (receipt.status === 1) {
        return SUCCESS;
      } else return FAILURE;
    }
  } catch (e) {
    console.log(e);
    return FAILURE;
  }
}

export async function setHardCap(
  address: string,
  uri: string,
  amount: number,
  account: string | undefined | null,
  library: any
): Promise<number> {
  const gasPrice = await caver.rpc.klay.getGasPrice();
  const isKaikas =
    library.provider.bridge !== 'https://bridge.walletconnect.org' &&
    (library.connection.url !== 'metamask' || library.connection.url === 'eip-1193:');
  let contract: any;
  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    const keyAbi: AbiItem[] = erc721Abi as AbiItem[];
    contract = new caver.klay.Contract(keyAbi, address);
  } else {
    contract = new ethers.Contract(address, erc721Abi, library?.getSigner());
  }
  let tx;

  // gasLimit 계산
  let gasLimit;
  if (isKaikas)
    gasLimit = await contract?.methods.setHardCap(amount, uri).estimateGas({
      from: account,
    });
  else gasLimit = await contract.estimateGas.setHardCap(amount, uri);

  // registerItems 요청
  let receipt;
  try {
    let overrides: Overrides = {
      from: account,
      gasLimit: calculateGasMargin(BigNumber.from(gasLimit)),
    };

    if (isKaikas) {
      tx = await contract.methods
        .setHardCap(amount, uri)
        .send(overrides)
        .catch(async (err: any) => {
          return FAILURE;
        });
      if (tx?.status) {
        return SUCCESS;
      } else return FAILURE;
    } else {
      // if (library._network.chainId === 8217)
      overrides = { ...overrides, gasPrice };

      tx = await contract.setHardCap(amount, uri, overrides);

      // receipt 대기
      try {
        receipt = await tx.wait();
      } catch (e) {
        return FAILURE;
      }
      if (receipt.status === 1) {
        return SUCCESS;
      } else return FAILURE;
    }
  } catch (e) {
    console.log(e);
    return FAILURE;
  }
}

export async function setMysteryBox(
  address: string,
  mysteryBox: string,
  account: string | undefined | null,
  library: any
): Promise<number> {
  const gasPrice = await caver.rpc.klay.getGasPrice();
  const isKaikas =
    library.provider.bridge !== 'https://bridge.walletconnect.org' &&
    (library.connection.url !== 'metamask' || library.connection.url === 'eip-1193:');
  let contract: any;
  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    const keyAbi: AbiItem[] = erc721Abi as AbiItem[];
    contract = new caver.klay.Contract(keyAbi, address);
  } else {
    contract = new ethers.Contract(address, erc721Abi, library?.getSigner());
  }
  let tx;

  // gasLimit 계산
  let gasLimit;
  if (isKaikas)
    gasLimit = await contract?.methods.setMysteryBox(mysteryBox).estimateGas({
      from: account,
    });
  else gasLimit = await contract.estimateGas.setMysteryBox(mysteryBox);

  // registerItems 요청
  let receipt;
  try {
    let overrides: Overrides = {
      from: account,
      gasLimit: calculateGasMargin(BigNumber.from(gasLimit)),
    };

    if (isKaikas) {
      tx = await contract.methods
        .setMysteryBox(mysteryBox)
        .send(overrides)
        .catch(async (err: any) => {
          return FAILURE;
        });
      if (tx?.status) {
        return SUCCESS;
      } else return FAILURE;
    } else {
      // if (library._network.chainId === 8217)
      overrides = { ...overrides, gasPrice };

      tx = await contract.setMysteryBox(mysteryBox, overrides);

      // receipt 대기
      try {
        receipt = await tx.wait();
      } catch (e) {
        return FAILURE;
      }
      if (receipt.status === 1) {
        return SUCCESS;
      } else return FAILURE;
    }
  } catch (e) {
    console.log(e);
    return FAILURE;
  }
}

export async function setApproveForAll(
  address: string,
  target: string,
  account: string | undefined | null,
  library: any,
  isKaikas: boolean
): Promise<number> {
  const gasPrice = await caver.rpc.klay.getGasPrice();
  let contract: any;
  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    const keyAbi: AbiItem[] = erc721Abi as AbiItem[];
    contract = new caver.klay.Contract(keyAbi, address);
  } else {
    contract = new ethers.Contract(address, erc721Abi, library?.getSigner());
  }
  let tx;

  // gasLimit 계산
  let gasLimit;
  if (isKaikas)
    gasLimit = await contract?.methods.setApprovalForAll(target, true).estimateGas({
      from: account,
    });
  else gasLimit = await contract.estimateGas.setApprovalForAll(target, true);

  // registerItems 요청
  let receipt;
  try {
    let overrides: Overrides = {
      from: account,
      gasLimit: calculateGasMargin(BigNumber.from(gasLimit)),
    };

    if (isKaikas) {
      tx = await contract.methods
        .setApprovalForAll(target, true)
        .send(overrides)
        .catch(async (err: any) => {
          return FAILURE;
        });
      if (tx?.status) {
        return SUCCESS;
      } else return FAILURE;
    } else {
      // if (library._network.chainId === 8217)
      overrides = { ...overrides, gasPrice };

      tx = await contract.setApprovalForAll(target, true, overrides);

      // receipt 대기
      try {
        receipt = await tx.wait();
      } catch (e: any) {
        console.log('setApproveForAllTxErr1::', e.message);
        return FAILURE;
      }
      if (receipt.status === 1) {
        return SUCCESS;
      } else return FAILURE;
    }
  } catch (e) {
    console.log('setApproveForAllTxErr2::', e);
    return FAILURE;
  }
}

export async function requestRandomNumber(
  address: string,
  rndFee: string,
  account: string | undefined | null,
  library: any,
  mechanism?: number | undefined
): Promise<number> {
  const gasPrice = await caver.rpc.klay.getGasPrice();
  const isKaikas =
    library.provider.bridge !== 'https://bridge.walletconnect.org' &&
    (library.connection.url !== 'metamask' || library.connection.url === 'eip-1193:');

  let contract: any;
  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    const contractAbi: AbiItem[] =
      mechanism === undefined
        ? (mysteryBoxAbi as AbiItem[])
        : mechanism === 1
        ? (subscriptionAbi as AbiItem[])
        : (evenAllocAbi as AbiItem[]);
  } else {
    contract = new ethers.Contract(
      address,
      mechanism === undefined ? mysteryBoxAbi : mechanism === 1 ? subscriptionAbi : evenAllocAbi,
      library?.getSigner()
    );
  }

  let tx;
  // gasLimit 계산
  let gasLimit;
  // const rndFeet = parseUnits('1', 'eth').toString();
  // const rndFee = ethers.utils.parseEther('1.0').toString();

  if (isKaikas) {
    gasLimit = await contract.methods.requestRandomNumber().estimateGas({
      from: account,
      value: rndFee,
    });
  } else
    gasLimit = await contract.estimateGas.requestRandomNumber({
      value: rndFee,
    });

  // requestRandomNumber 요청
  let receipt;
  try {
    let overrides: Overrides = {
      value: rndFee,
      from: account,
      gasLimit: calculateGasMargin(BigNumber.from(gasLimit)),
    };

    if (isKaikas) {
      tx = await contract.methods
        .requestRandomNumber()
        .send(overrides)
        .catch(async (err: any) => {
          return FAILURE;
        });
      if (tx?.status) {
        return SUCCESS;
      } else return FAILURE;
    } else {
      // if (library._network.chainId === 8217)
      overrides = { ...overrides, gasPrice };

      tx = await contract.requestRandomNumber(overrides);

      // receipt 대기
      try {
        receipt = await tx.wait();
      } catch (e) {
        return FAILURE;
      }
      if (receipt.status === 1) {
        return SUCCESS;
      } else return FAILURE;
    }
  } catch (e) {
    console.log(e);
    return FAILURE;
  }
}

export async function addWhitelist(
  address: string,
  whitelist: string[],
  round: number,
  account: string | undefined | null,
  library: any
): Promise<number> {
  const gasPrice = await caver.rpc.klay.getGasPrice();
  const isKaikas =
    library.provider.bridge !== 'https://bridge.walletconnect.org' &&
    (library.connection.url !== 'metamask' || library.connection.url === 'eip-1193:');
  let contract: any;
  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    const whitelistAbi: AbiItem[] = whiteListAbi as AbiItem[];
    contract = new caver.klay.Contract(whitelistAbi, address);
  } else {
    contract = new ethers.Contract(address, whiteListAbi, library?.getSigner());
  }
  let tx;

  // gasLimit 계산
  let gasLimit;
  if (isKaikas)
    gasLimit = await contract?.methods.addWhitelist(whitelist, round).estimateGas({
      from: account,
    });
  else gasLimit = await contract.estimateGas.addWhitelist(whitelist, round);

  // registerItems 요청
  let receipt;
  try {
    let overrides: Overrides = {
      from: account,
      gasLimit: calculateGasMargin(BigNumber.from(gasLimit)),
    };

    if (isKaikas) {
      tx = await contract.methods
        .addWhitelist(whitelist, round)
        .send(overrides)
        .catch(async (err: any) => {
          return FAILURE;
        });

      if (tx?.status) {
        return SUCCESS;
      } else return FAILURE;
    } else {
      // if (library._network.chainId === 8217)
      overrides = { ...overrides, gasPrice };

      tx = await contract.addWhitelist(whitelist, round, overrides);

      // receipt 대기
      try {
        receipt = await tx.wait();
      } catch (e) {
        return FAILURE;
      }
      if (receipt.status === 1) {
        return SUCCESS;
      } else return FAILURE;
    }
  } catch (e) {
    console.log(e);
    return FAILURE;
  }
}

export async function safeBatchMintToWhitelist(
  address: string,
  uri: string,
  index: number, // white list index, default = 0
  account: string | undefined | null,
  library: any
): Promise<number> {
  const gasPrice = await caver.rpc.klay.getGasPrice();
  const isKaikas =
    library.provider.bridge !== 'https://bridge.walletconnect.org' &&
    (library.connection.url !== 'metamask' || library.connection.url === 'eip-1193:');
  let contract: any;
  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    const whitelistAbi: AbiItem[] = whiteListAbi as AbiItem[];
    contract = new caver.klay.Contract(whitelistAbi, address);
  } else {
    contract = new ethers.Contract(address, whiteListAbi, library?.getSigner());
  }
  let tx;

  // gasLimit 계산
  let gasLimit;
  if (isKaikas)
    gasLimit = await contract?.methods.safeBatchMintToWhitelist(uri, index).estimateGas({
      from: account,
    });
  else gasLimit = await contract.estimateGas.safeBatchMintToWhitelist(uri, index);

  // registerItems 요청
  let receipt;
  try {
    let overrides: Overrides = {
      from: account,
      gasLimit: calculateGasMargin(BigNumber.from(gasLimit)),
    };

    if (isKaikas) {
      tx = await contract.methods
        .safeBatchMintToWhitelist(uri, index)
        .send(overrides)
        .catch(async (err: any) => {
          return FAILURE;
        });
      if (tx?.status) {
        return SUCCESS;
      } else return FAILURE;
    } else {
      // if (library._network.chainId === 8217)
      overrides = { ...overrides, gasPrice };

      tx = await contract.safeBatchMintToWhitelist(uri, index, overrides);

      // receipt 대기
      try {
        receipt = await tx.wait();
      } catch (e) {
        return FAILURE;
      }
      if (receipt.status === 1) {
        return SUCCESS;
      } else return FAILURE;
    }
  } catch (e) {
    console.log(e);
    return FAILURE;
  }
}

export async function setSubscription(
  mysteryBox: string, // mysterybox contract address
  subscription: string, // subscription contract address
  contractType: number,
  allocAmount: number,
  account: string | undefined | null,
  library: any
): Promise<number> {
  const gasPrice = await caver.rpc.klay.getGasPrice();
  const isKaikas =
    library.provider.bridge !== 'https://bridge.walletconnect.org' &&
    (library.connection.url !== 'metamask' || library.connection.url === 'eip-1193:');

  let contract: any;
  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    const boxAbi: AbiItem[] = mysteryBoxAbi as AbiItem[];
    contract = new caver.klay.Contract(boxAbi, mysteryBox);
  } else {
    contract = new ethers.Contract(mysteryBox, mysteryBoxAbi, library?.getSigner());
  }

  let tx;
  // gasLimit 계산
  let gasLimit;
  console.log(contract, subscription);
  if (isKaikas) {
    gasLimit = await contract.methods.setSubscription(subscription, contractType, allocAmount).estimateGas({
      from: account,
    });
  } else gasLimit = await contract.estimateGas.setSubscription(subscription, contractType, allocAmount);

  // registerItems 요청
  let receipt;
  try {
    let overrides: Overrides = {
      from: account,
      gasLimit: calculateGasMargin(BigNumber.from(gasLimit)),
    };

    if (isKaikas) {
      tx = await contract.methods
        .setSubscription(subscription, contractType, allocAmount)
        .send(overrides)
        .catch(async (err: any) => {
          return FAILURE;
        });
      if (tx?.status) {
        return SUCCESS;
      } else return FAILURE;
    } else {
      // if (library._network.chainId === 8217)
      overrides = { ...overrides, gasPrice };

      tx = await contract.setSubscription(subscription, contractType, allocAmount, overrides);

      // receipt 대기
      try {
        receipt = await tx.wait();
      } catch (e) {
        return FAILURE;
      }
      if (receipt.status === 1) {
        return SUCCESS;
      } else return FAILURE;
    }
  } catch (e) {
    console.log(e);
    return FAILURE;
  }
}

export async function setLaunch(
  mysteryBox: string, // mysterybox contract address
  launch: number, // epoch time
  account: string | undefined | null,
  library: any
): Promise<number> {
  const gasPrice = await caver.rpc.klay.getGasPrice();
  const isKaikas =
    library.provider.bridge !== 'https://bridge.walletconnect.org' &&
    (library.connection.url !== 'metamask' || library.connection.url === 'eip-1193:');

  let contract: any;
  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    const boxAbi: AbiItem[] = mysteryBoxAbi as AbiItem[];
    contract = new caver.klay.Contract(boxAbi, mysteryBox);
  } else {
    contract = new ethers.Contract(mysteryBox, mysteryBoxAbi, library?.getSigner());
  }

  let tx;
  // gasLimit 계산
  let gasLimit;
  console.log(contract, launch);
  if (isKaikas) {
    gasLimit = await contract.methods.setLaunch(launch).estimateGas({
      from: account,
    });
  } else gasLimit = await contract.estimateGas.setLaunch(launch);

  // registerItems 요청
  let receipt;
  try {
    let overrides: Overrides = {
      from: account,
      gasLimit: calculateGasMargin(BigNumber.from(gasLimit)),
    };

    if (isKaikas) {
      tx = await contract.methods
        .setLaunch(launch)
        .send(overrides)
        .catch(async (err: any) => {
          return FAILURE;
        });
      if (tx?.status) {
        return SUCCESS;
      } else return FAILURE;
    } else {
      // if (library._network.chainId === 8217)
      overrides = { ...overrides, gasPrice };

      tx = await contract.setLaunch(launch, overrides);

      // receipt 대기
      try {
        receipt = await tx.wait();
      } catch (e) {
        return FAILURE;
      }
      if (receipt.status === 1) {
        return SUCCESS;
      } else return FAILURE;
    }
  } catch (e) {
    console.log(e);
    return FAILURE;
  }
}

export async function allocation(
  address: string, // subscription contract address
  account: string | undefined | null,
  library: any
): Promise<number> {
  const gasPrice = await caver.rpc.klay.getGasPrice();
  const isKaikas =
    library.provider.bridge !== 'https://bridge.walletconnect.org' &&
    (library.connection.url !== 'metamask' || library.connection.url === 'eip-1193:');

  let contract: any;
  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    const subAbi: AbiItem[] = subscriptionAbi as AbiItem[];
    contract = new caver.klay.Contract(subAbi, address);
  } else {
    contract = new ethers.Contract(address, subscriptionAbi, library?.getSigner());
  }

  let tx;
  // gasLimit 계산
  let gasLimit;
  if (isKaikas) {
    gasLimit = await contract.methods.allocation().estimateGas({
      from: account,
    });
  } else {
    gasLimit = await contract.estimateGas.allocation();
  }

  // registerItems 요청
  let receipt;
  try {
    let overrides: Overrides = {
      from: account,
      gasLimit: calculateGasMargin(BigNumber.from(gasLimit)),
    };

    if (isKaikas) {
      tx = await contract.methods
        .allocation()
        .send(overrides)
        .catch(async (err: any) => {
          return FAILURE;
        });
      if (tx?.status) {
        return SUCCESS;
      } else return FAILURE;
    } else {
      // if (library._network.chainId === 8217)
      overrides = { ...overrides, gasPrice };

      tx = await contract.allocation(overrides);

      // receipt 대기
      try {
        receipt = await tx.wait();
      } catch (e) {
        return FAILURE;
      }
      if (receipt.status === 1) {
        return SUCCESS;
      } else return FAILURE;
    }
  } catch (e) {
    console.log(e);
    return FAILURE;
  }
}

export async function setStaking(
  address: string,
  subscription: string,
  account: string | undefined | null,
  library: any
): Promise<number> {
  const gasPrice = await caver.rpc.klay.getGasPrice();
  const isKaikas =
    library.provider.bridge !== 'https://bridge.walletconnect.org' &&
    (library.connection.url !== 'metamask' || library.connection.url === 'eip-1193:');
  let contract: any;
  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    const whitelistAbi: AbiItem[] = whiteListAbi as AbiItem[];
    contract = new caver.klay.Contract(whitelistAbi, address);
  } else {
    contract = new ethers.Contract(address, whiteListAbi, library?.getSigner());
  }
  let tx;

  // gasLimit 계산
  let gasLimit;
  if (isKaikas)
    gasLimit = await contract?.methods.setStaking(subscription).estimateGas({
      from: account,
    });
  else gasLimit = await contract.estimateGas.setStaking(subscription);

  // registerItems 요청
  let receipt;
  try {
    let overrides: Overrides = {
      from: account,
      gasLimit: calculateGasMargin(BigNumber.from(gasLimit)),
    };

    if (isKaikas) {
      tx = await contract.methods
        .setStaking(subscription)
        .send(overrides)
        .catch(async (err: any) => {
          return FAILURE;
        });
      if (tx?.status) {
        return SUCCESS;
      } else return FAILURE;
    } else {
      // if (library._network.chainId === 8217)
      overrides = { ...overrides, gasPrice };

      tx = await contract.setStaking(subscription, overrides);

      // receipt 대기
      try {
        receipt = await tx.wait();
      } catch (e) {
        return FAILURE;
      }
      if (receipt.status === 1) {
        return SUCCESS;
      } else return FAILURE;
    }
  } catch (e) {
    console.log(e);
    return FAILURE;
  }
}

export async function estimateRandomizeFee(
  address: string,
  account: string | undefined | null,
  library: any,
  mechanism?: number | undefined
): Promise<string> {
  const gasPrice = await caver.rpc.klay.getGasPrice();
  const isKaikas =
    library.provider.bridge !== 'https://bridge.walletconnect.org' &&
    (library.connection.url !== 'metamask' || library.connection.url === 'eip-1193:');

  let contract: any;
  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    const contractAbi: AbiItem[] =
      mechanism === undefined
        ? (mysteryBoxAbi as AbiItem[])
        : mechanism === 1
        ? (subscriptionAbi as AbiItem[])
        : (evenAllocAbi as AbiItem[]);
    contract = new caver.klay.Contract(contractAbi, address);
  } else {
    contract = new ethers.Contract(
      address,
      mechanism === undefined ? mysteryBoxAbi : mechanism === 1 ? subscriptionAbi : evenAllocAbi,
      library?.getSigner()
    );
  }

  let participants;
  try {
    if (isKaikas) {
      participants = await contract.methods
        .estimateRandomizeFee(gasPrice)
        .call()
        .catch(async (err: any) => {
          console.log('#####', address, account);
          console.log('estimateRandomizeFee Error : ', err);
        });
    } else {
      participants = await contract.estimateRandomizeFee(gasPrice);
    }
  } catch (e) {
    console.log('#####', address, account);
    console.log('estimateRandomizeFee Error : ', e);
  }
  return participants.toString();
}

export async function getTotalSupply(
  address: string,
  account: string | undefined | null,
  library: any
): Promise<number> {
  const isKaikas =
    library.provider.bridge !== 'https://bridge.walletconnect.org' &&
    (library.connection.url !== 'metamask' || library.connection.url === 'eip-1193:');

  let contract: any;
  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    const keyAbi: AbiItem[] = erc721Abi as AbiItem[];
    contract = new caver.klay.Contract(keyAbi, address);
  } else {
    contract = new ethers.Contract(address, erc721Abi, library?.getSigner());
  }

  let totalSupply = 0;
  try {
    if (isKaikas) {
      totalSupply = await contract.methods
        .totalSupply()
        .call()
        .catch(async (err: any) => {
          console.log('#####', address);
          console.log('getTotalSupply Error : ', err);
        });
    } else {
      const result: BigNumber = await contract.totalSupply();
      totalSupply = result.toNumber();
    }
  } catch (e) {
    console.log('#####', address);
    console.log('getTotalSupply Error : ', e);
  }
  return totalSupply;
}

export async function getTotalSupplyNoSigner(
  address: string,
  account: string | undefined | null,
  chainId: number
): Promise<number> {
  const provider = ethers.getDefaultProvider(getSelectedNodeUrl(chainId));
  const contract = new ethers.Contract(address, erc721Abi, provider);

  let totalSupply = 0;
  try {
    const result: BigNumber = await contract.totalSupply();
    totalSupply = result.toNumber();
  } catch (e) {
    console.log('#####', address);
    console.log('getTotalSupplyNoSigner Error : ', e);
  }
  return totalSupply;
}

export async function approveKIP7(
  address: string,
  spender: string,
  amount: string,
  account: string | undefined | null,
  library: any,
  isKaikas: boolean
): Promise<number> {
  const gasPrice = await caver.rpc.klay.getGasPrice();
  let contract: any;
  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    const erc20Abi: AbiItem[] = tokenAbi as AbiItem[];
    contract = new caver.klay.Contract(erc20Abi, address);
  } else {
    contract = new ethers.Contract(address, tokenAbi, library?.getSigner());
  }
  let tx;

  // gasLimit 계산
  let gasLimit;
  if (isKaikas)
    gasLimit = await contract?.methods.approve(spender, amount).estimateGas({
      from: account,
    });
  else gasLimit = await contract.estimateGas.approve(spender, amount);

  // registerItems 요청
  let receipt;
  try {
    let overrides: Overrides = {
      from: account,
      gasLimit: calculateGasMargin(BigNumber.from(gasLimit)),
    };

    if (isKaikas) {
      tx = await contract.methods
        .approve(spender, amount)
        .send(overrides)
        .catch(async (err: any) => {
          return FAILURE;
        });
      if (tx?.status) {
        return SUCCESS;
      } else return FAILURE;
    } else {
      // if (library._network.chainId === 8217)
      overrides = { ...overrides, gasPrice };

      tx = await contract.approve(spender, amount, overrides);

      // receipt 대기
      try {
        receipt = await tx.wait();
      } catch (e) {
        return FAILURE;
      }
      if (receipt.status === 1) {
        return SUCCESS;
      } else return FAILURE;
    }
  } catch (e: any) {
    console.log(e.message);
    if (e?.message?.includes('Incorrect twofactor verify token')) alert('Incorrect twofactor verify token');
    if (e?.message?.includes('insufficient funds for intrinsic transaction cost'))
      alert('insufficient funds for intrinsic transaction cost');
    if (e?.message?.includes('User canceled transaction')) return CANCELED;
    else return FAILURE;
  }
}

// // get all item's amount at once
// export async function getItemAmounts(
//   address: string,
//   account: string | undefined | null,
//   library: any
// ): Promise<string[]> {
//   const isKaikas =
//     library.connection.url !== 'metamask' ||
//     library.connection.url === 'eip-1193:';
//
//   console.log(isKaikas);
//   let contract: any;
//   if (isKaikas) {
//     // @ts-ignore : In case of Klaytn Kaikas Wallet
//     const caver = new Caver(window.klaytn);
//     const mboxAbi: AbiItem[] = mysteryBoxAbi as AbiItem[];
//     contract = new caver.klay.Contract(mboxAbi, address);
//   } else {
//     contract = new ethers.Contract(
//       address,
//       mysteryBoxAbi,
//       library?.getSigner()
//     );
//   }
//
//   let itemAmounts = [];
//   try {
//     if (isKaikas) {
//       itemAmounts = await contract.methods
//         .getItemAmounts()
//         .call()
//         .catch(async (err: any) => {
//           console.log('#####', address);
//           console.log('getItemAmounts Error : ', err);
//         });
//     } else {
//       itemAmounts = await contract.getItemAmounts();
//     }
//   } catch (e) {
//     console.log('#####', address);
//     console.log('getItemAmounts Error : ', e);
//   }
//   return itemAmounts;
// }

export async function getItemAmount(
  address: string,
  index: number,
  type: number, // 1 = MysteryBox, 2 = Collection
  account: string | undefined | null,
  library: any,
  isKaikas: boolean
): Promise<number> {
  let contract: any;
  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    let klayAbi: AbiItem[];
    if (type === 1) {
      klayAbi = mysteryBoxAbi as AbiItem[];
    } else {
      klayAbi = collectionAbi as AbiItem[];
    }
    contract = new caver.klay.Contract(klayAbi, address);
  } else {
    contract = new ethers.Contract(address, type === 1 ? mysteryBoxAbi : collectionAbi, library?.getSigner());
  }

  let remains;
  try {
    if (isKaikas) {
      if (type === 1) {
        remains = await contract.methods
          .itemAmounts(index)
          .call()
          .catch(async (err: any) => {
            console.log('#####', address);
            console.log('getItemAmount Error : ', err);
          });
      } else {
        const itemAmount = await contract.methods
          .itemAmounts(index)
          .call()
          .catch(async (err: any) => {
            console.log('#####', address);
            console.log('getItemAmount Error : ', err);
          });
        const itemSold = await contract.methods
          .itemSolds(index)
          .call()
          .catch(async (err: any) => {
            console.log('#####', address);
            console.log('getItemAmount Error : ', err);
          });
        remains = itemAmount - itemSold;
      }
    } else {
      if (type === 1) {
        const result: BigNumber = await contract.itemAmounts(index);
        remains = result.toNumber();
      } else {
        const result1: BigNumber = await contract.itemAmounts(index);
        const itemAmount = result1.toNumber();
        const result2: BigNumber = await contract.itemSolds(index);
        const itemSold = result2.toNumber();
        remains = itemAmount - itemSold;
      }
    }
  } catch (e) {
    console.log('#####', address);
    console.log('getItemAmount Error : ', e);
  }
  return remains;
}

export async function getItemAmountNoSigner(
  address: string,
  index: number,
  type: number, // 1 = MysteryBox, 2 = Collection, 3 = AirDrops
  account: string | undefined | null,
  chainId: number
): Promise<number> {
  const provider = ethers.getDefaultProvider(getSelectedNodeUrl(chainId));
  const contract = new ethers.Contract(
    address,
    type === 1 ? mysteryBoxAbi : type == 2 ? collectionAbi : airDropAbi,
    provider
  );

  let remains = 0;
  try {
    if (type === 1) {
      const result: BigNumber = await contract.itemAmounts(index);
      remains = result.toNumber();
    } else {
      const result1: BigNumber = await contract.itemAmounts(index);
      const itemAmount = result1.toNumber();
      const result2: BigNumber = await contract.itemSolds(index);
      const itemSold = result2.toNumber();
      remains = itemAmount - itemSold;
    }
  } catch (e) {
    console.log('#####', address);
    console.log('getItemAmountNoSigner Error : ', e);
  }
  return remains;
}

export async function getItemRemains(
  address: string,
  account: string | undefined | null,
  library: any,
  isKaikas: boolean
): Promise<number> {
  let contract: any;
  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    const mboxAbi: AbiItem[] = mysteryBoxAbi as AbiItem[];
    contract = new caver.klay.Contract(mboxAbi, address);
  } else {
    contract = new ethers.Contract(address, mysteryBoxAbi, library?.getSigner());
  }

  let remains = 0;
  try {
    if (isKaikas) {
      remains = await contract.methods
        .getItemRemains()
        .call()
        .catch(async (err: any) => {
          console.log('#####', address);
          console.log('getItemRemains Error : ', err);
        });
    } else {
      const result: BigNumber = await contract.getItemRemains();
      remains = result.toNumber();
    }
  } catch (e) {
    console.log('#####', address);
    console.log('getItemRemains Error : ', e);
  }
  return remains;
}

export async function getItemRemainsNoSigner(
  address: string,
  account: string | undefined | null,
  chainId: number
): Promise<number> {
  const provider = ethers.getDefaultProvider(getSelectedNodeUrl(chainId));
  new ethers.Contract(address, mysteryBoxAbi, provider);
  const contract = new ethers.Contract(address, mysteryBoxAbi, provider);

  let remains = 0;
  try {
    const result: BigNumber = await contract.getItemRemains();
    remains = result.toNumber();
  } catch (e) {
    console.log('#####', address);
    console.log('getItemRemainsNoSigner Error : ', e);
  }
  return remains;
}

export async function buyItem(
  address: string,
  index: number,
  amount: number,
  payment: string,
  quote: string,
  account: string | undefined | null,
  library: any,
  isKaikas: boolean
): Promise<txResult> {
  const gasPrice = await caver.rpc.klay.getGasPrice();

  let contract: any;
  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    const collectionAbi: AbiItem[] = collectionData.abi as AbiItem[];
    contract = new caver.klay.Contract(collectionAbi, address);
  } else {
    contract = new ethers.Contract(address, collectionAbi, library?.getSigner());
  }

  let tx;
  // gasLimit 계산
  let gasLimit;
  if (isKaikas) {
    if (quote === '0x0000000000000000000000000000000000000000') {
      gasLimit = await contract.methods.buyItemEth(index, amount).estimateGas({
        value: payment,
        from: account,
      });
    } else {
      gasLimit = await contract.methods.buyItemQuote(index, payment, amount).estimateGas({
        from: account,
      });
    }
  } else {
    if (quote === '0x0000000000000000000000000000000000000000') {
      gasLimit = await contract.estimateGas.buyItemEth(index, amount, {
        value: payment,
      });
    } else {
      gasLimit = await contract.estimateGas.buyItemQuote(index, payment, amount);
    }
  }
  // registerItems 요청
  let receipt;
  const result: txResult = { status: 0, txHash: '', tokenId: 0, error: '' };
  try {
    let overrides: Overrides = {
      from: account,
      gasLimit: calculateGasMargin(BigNumber.from(gasLimit)),
    };

    if (isKaikas) {
      if (quote === '0x0000000000000000000000000000000000000000') {
        overrides = { ...overrides, value: payment };

        tx = await contract.methods
          .buyItemEth(index, amount)
          .send(overrides)
          .catch(async (err: any) => {
            result.status = FAILURE;
          });
      } else {
        tx = await contract.methods
          .buyItemQuote(index, payment, amount)
          .send(overrides)
          .catch(async (err: any) => {
            result.status = FAILURE;
          });
      }
      if (tx?.status) {
        result.status = SUCCESS;
        result.txHash = tx.transactionHash;
      } else {
        result.status = FAILURE;
      }
      return result;
    } else {
      // if (library._network.chainId === 8217)
      overrides = { ...overrides, gasPrice };

      if (quote === '0x0000000000000000000000000000000000000000') {
        overrides = { ...overrides, value: payment };

        tx = await contract.buyItemEth(index, amount, overrides);
      } else {
        tx = await contract.buyItemQuote(index, payment, amount, overrides);
      }
      // receipt 대기
      try {
        receipt = await tx.wait();
      } catch (e) {
        result.status = FAILURE;
      }
      if (receipt.status === 1) {
        console.log('!!!!!!!!!! buyItem receipt = ', receipt, result);
        const events = receipt.logs;
        let tokenId = 0;
        for (let i = 0; i < events.length; i++) {
          // Transfer
          if (
            events[i].topics[0] == '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef' &&
            events[i].topics[3] !== undefined
          ) {
            const tokenIdHex = ethers.utils.defaultAbiCoder.decode(['uint256'], events[i].topics[3]);
            tokenId = parseInt(tokenIdHex?.toString());
            break;
          }
        }
        result.status = SUCCESS;
        result.txHash = receipt.transactionHash;
        result.tokenId = tokenId;
      } else {
        result.status = FAILURE;
      }
      return result;
    }
  } catch (e: any) {
    console.log(e.message);
    result.status = FAILURE;
    result.error = e?.message.includes('insufficient funds for intrinsic transaction cost')
      ? 'insufficient KLAY'
      : e?.message.includes('Incorrect twofactor verify token')
      ? 'Incorrect twofactor verify token'
      : e.message;
    return result;
  }
}

export async function getAllBalances(account: string | undefined | null): Promise<string[]> {
  const balances: string[] = [];
  const Chains = [
    env.REACT_APP_TARGET_NETWORK_ETH,
    env.REACT_APP_TARGET_NETWORK_KLAY,
    env.REACT_APP_TARGET_NETWORK_MATIC,
    env.REACT_APP_TARGET_NETWORK_BNB,
  ];

  for (let i = 0; i < Chains.length; i++) {
    const provider = ethers.getDefaultProvider(getSelectedNodeUrl(Chains[i]));

    try {
      const balance = await provider.getBalance(account);
      balances.push(ethers.utils.formatEther(balance));
    } catch (e) {
      console.log('#####', account);
      console.log('getAllBalances Error : ', e);
      balances.push('0.0');
    }
  }
  return balances;
}

export async function getEthBalance(account: string | undefined | null): Promise<string> {
  const provider = ethers.getDefaultProvider(getSelectedNodeUrl(env.REACT_APP_TARGET_NETWORK_ETH));

  let balance = '';
  try {
    const val = await provider.getBalance(account);
    balance = ethers.utils.formatEther(val);
  } catch (e) {
    console.log('#####', account);
    console.log('getEthBalance Error : ', e);
  }

  return balance;
}

export async function getKlayBalance(account: string | undefined | null): Promise<string> {
  const provider = ethers.getDefaultProvider(getSelectedNodeUrl(env.REACT_APP_TARGET_NETWORK_KLAY));

  let balance = '';
  try {
    const val = await provider.getBalance(account);
    balance = ethers.utils.formatEther(val);
  } catch (e) {
    console.log('#####', account);
    console.log('getKlayBalance Error : ', e);
  }

  return balance;
}

export async function getMaticBalance(account: string | undefined | null): Promise<string> {
  const provider = ethers.getDefaultProvider(getSelectedNodeUrl(env.REACT_APP_TARGET_NETWORK_MATIC));

  let balance = '';
  try {
    const val = await provider.getBalance(account);
    balance = ethers.utils.formatEther(val);
  } catch (e) {
    console.log('#####', account);
    console.log('getMaticBalance Error : ', e);
  }

  return balance;
}

export async function getBnbBalance(account: string | undefined | null): Promise<string> {
  const provider = ethers.getDefaultProvider(getSelectedNodeUrl(env.REACT_APP_TARGET_NETWORK_BNB));

  let balance = '';
  try {
    const val = await provider.getBalance(account);
    balance = ethers.utils.formatEther(val);
  } catch (e) {
    console.log('#####', account);
    console.log('getMaticBalance Error : ', e);
  }

  return balance;
}

export async function getErc20Balance(
  address: string,
  account: string | undefined | null,
  chainId: number,
  decimal: number
): Promise<string> {
  if (chainId === 80002) return '0.0'; // amoy TALK 임시 제한
  const provider = ethers.getDefaultProvider(getSelectedNodeUrl(chainId));
  const contract = new ethers.Contract(address, tokenAbi, provider);

  let retBalance: string;
  try {
    const balance = await contract.balanceOf(account);
    retBalance = ethers.utils.formatUnits(balance, decimal);
  } catch (e) {
    console.log('#####', { address, account });
    console.log('getErc20Balance Error : ', e);
    return '0.0';
  }
  return retBalance;
}

export async function erc20Transfer(
  address: string,
  receipient: string,
  amount: string,
  account: string,
  library: any,
  isKaikas: boolean,
  setTxHash: SetStateAction<any>
): Promise<number> {
  const gasPrice = await getGasPriceFromAPI();
  let contract: any;
  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    const erc20Abi: AbiItem[] = tokenAbi as AbiItem[];
    contract = new caver.klay.Contract(erc20Abi, address);
  } else {
    contract = new ethers.Contract(address, tokenAbi, library?.getSigner());
  }
  let tx;

  // gasLimit 계산
  let gasLimit;
  if (isKaikas)
    gasLimit = await contract?.methods.transfer(receipient, amount).estimateGas({
      from: account,
    });
  else gasLimit = await contract.estimateGas.transfer(receipient, amount);

  // registerItems 요청
  let receipt;
  try {
    let overrides: Overrides = {
      from: account,
      gasLimit: calculateGasMargin(BigNumber.from(gasLimit)),
    };

    if (isKaikas) {
      tx = await contract.methods
        .transfer(receipient, amount)
        .send(overrides)
        .catch(async (err: any) => {
          return FAILURE;
        });
      if (tx?.status) {
        return SUCCESS;
      } else return FAILURE;
    } else {
      // if (library._network.chainId === 8217)
      overrides = { ...overrides, gasPrice };

      tx = await contract.transfer(receipient, amount, overrides);

      // receipt 대기
      try {
        receipt = await tx.wait();
      } catch (e) {
        return FAILURE;
      }
      if (receipt.status === 1) {
        setTxHash(receipt.transactionHash);
        return SUCCESS;
      } else return FAILURE;
    }
  } catch (e) {
    console.log(e);
    if (e === 'User canceled transaction') return CANCELED;
    else return FAILURE;
  }
}

interface Result {
  state: number;
  error: any;
}
export async function ethTransfer(
  receipient: string,
  amount: string,
  account: string,
  library: any,
  isKaikas: boolean,
  setTxHash: SetStateAction<any>,
  chainId?: number
): Promise<Result> {
  let rawTx: any;
  if (isKaikas) {
    rawTx = {
      type: 'VALUE_TRANSFER',
      to: receipient,
      value: amount,
    };
  } else {
    rawTx = {
      to: receipient,
      value: amount,
    };
  }

  const gasPrice = await getGasPriceFromAPI(chainId);
  // let nonce: any;
  // let gasLimit: any;
  // if (isKaikas) {
  // @ts-ignore : In case of Klaytn Kaikas Wallet
  // const caver = new Caver(window.klaytn);
  // nonce = await caver.klay.getTransactionCount(account);
  // gasLimit = await caver.klay.estimateGas(rawTx);
  // } else {
  // nonce = await library?.getSigner().getProvider().getTransactionCount(account);
  // gasLimit = await library?.getSigner().estimateGas.sendTransaction(rawTx);
  // }

  // rawTx = { ...rawTx, from: account, gasPrice, gasLimit, nonce };
  rawTx = { ...rawTx, from: account, gasPrice };

  // registerItems 요청
  let tx;
  let receipt;
  try {
    if (isKaikas) {
      // @ts-ignore : In case of Klaytn Kaikas Wallet
      const caver = new Caver(window.klaytn);

      caver.klay
        .sendTransaction(rawTx)
        .once('transactionHash', (transactionHash) => {
          console.log('txHash', transactionHash);
        })
        .once('receipt', (r) => {
          console.log('receipt', r);
          receipt = r;
        })
        .once('error', (error) => {
          console.log('error', error);
        });

      // @ts-ignore
      if (receipt?.status) {
        return { state: SUCCESS, error: null };
      } else return { state: FAILURE, error: null };
    } else {
      tx = await library?.getSigner().sendTransaction(rawTx);
      // receipt 대기
      try {
        receipt = await tx.wait();
      } catch (e: any) {
        return { state: FAILURE, error: e.message };
      }
      if (receipt.status === 1) {
        setTxHash(receipt.transactionHash);
        return { state: SUCCESS, error: null };
      } else return { state: FAILURE, error: null };
    }
  } catch (e: any) {
    console.log('transactions error::', e.message);
    if (e === 'User canceled transaction') return { state: CANCELED, error: 'User canceled transaction.' };
    if (e?.message.includes('insufficient funds for intrinsic transaction cost'))
      return { state: FAILURE, error: 'insufficient funds for intrinsic transaction cost.' };
    return { state: FAILURE, error: e?.message };
  }
}

export async function getContractName(address: string, chainId: number): Promise<string> {
  const provider = ethers.getDefaultProvider(getSelectedNodeUrl(chainId));
  const contract = new ethers.Contract(address, erc721Abi, provider);

  let name = '';
  try {
    name = await contract.name();
  } catch (e) {
    console.log('#####', { address, chainId });
    console.log('getContractName Error : ', e);
  }
  return name;
}

export async function nftTransferFrom(
  address: string,
  receipient: string,
  tokenId: string,
  is1155: boolean,
  amount: number,
  account: string,
  library: any,
  isKaikas: boolean,
  setTxHash: SetStateAction<any>
): Promise<number> {
  const gasPrice = await getGasPriceFromAPI();
  let contract: any;

  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    const collAbi: AbiItem[] = collectionAbi as AbiItem[];
    const e1155Abi: AbiItem[] = erc1155Abi as AbiItem[];
    contract = new caver.klay.Contract(is1155 ? e1155Abi : collAbi, address);
  } else {
    contract = new ethers.Contract(address, is1155 ? erc1155Abi : collectionAbi, library?.getSigner());
  }
  let tx;

  // gasLimit 계산
  let gasLimit;
  if (isKaikas)
    gasLimit = is1155
      ? await contract?.methods.safeTransferFrom(account, receipient, tokenId, amount, []).estimateGas({
          from: account,
        })
      : await contract?.methods.transferFrom(account, receipient, tokenId).estimateGas({
          from: account,
        });
  else
    gasLimit = is1155
      ? await contract.estimateGas.safeTransferFrom(account, receipient, tokenId, amount, [])
      : await contract.estimateGas.transferFrom(account, receipient, tokenId);
  // registerItems 요청
  let receipt;
  try {
    let overrides: Overrides = {
      from: account,
      gasLimit: calculateGasMargin(BigNumber.from(gasLimit)),
    };
    if (isKaikas) {
      tx = is1155
        ? await contract.methods
            .safeTransferFrom(account, receipient, tokenId, amount, [])
            .send(overrides)
            .catch(async (err: any) => {
              return FAILURE;
            })
        : await contract.methods
            .transferFrom(account, receipient, tokenId)
            .send(overrides)
            .catch(async (err: any) => {
              return FAILURE;
            });
      if (tx?.status) {
        return SUCCESS;
      } else return FAILURE;
    } else {
      // if (library._network.chainId === 8217)
      overrides = { ...overrides, gasPrice };

      tx = is1155
        ? await contract.safeTransferFrom(account, receipient, tokenId, amount, [], overrides)
        : await contract.transferFrom(account, receipient, tokenId, overrides);
      // receipt 대기
      try {
        receipt = await tx.wait();
      } catch (e) {
        return FAILURE;
      }
      if (receipt.status === 1) {
        setTxHash(receipt.transactionHash);
        return SUCCESS;
      } else return FAILURE;
    }
  } catch (e: any) {
    console.log('transactions error::', e);
    if (e === 'User canceled transaction') return CANCELED;
    if (e.message.includes('User denied transaction signature')) return CANCELED;
    return FAILURE;
  }
}

export async function wrapKlay(
  address: string,
  account: string,
  amount: string,
  library: any,
  isKaikas: boolean,
  setTxHash: SetStateAction<any>
): Promise<any> {
  const gasPrice = await getGasPriceFromAPI();
  let contract: any;
  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    const wAbi: AbiItem[] = wklayAbi as AbiItem[];
    contract = new caver.klay.Contract(wAbi, address);
  } else {
    contract = new ethers.Contract(address, wklayAbi, library?.getSigner());
  }
  let tx;

  // gasLimit 계산
  let gasLimit;
  if (isKaikas)
    gasLimit = await contract?.methods.deposit().estimateGas({
      from: account,
      value: amount,
    });
  else
    gasLimit = await contract.estimateGas.deposit({
      value: amount,
    });

  let receipt;
  try {
    let overrides: Overrides = {
      value: amount,
      from: account,
      gasLimit: calculateGasMargin(BigNumber.from(gasLimit)),
    };

    if (isKaikas) {
      tx = await contract.methods
        .deposit()
        .send(overrides)
        .catch(async (err: any) => {
          return FAILURE;
        });
      if (tx?.status) {
        return SUCCESS;
      } else return FAILURE;
    } else {
      // if (library._network.chainId === 8217)
      overrides = { ...overrides, gasPrice };

      tx = await contract.deposit(overrides);

      // receipt 대기
      try {
        receipt = await tx.wait();
      } catch (e) {
        return FAILURE;
      }
      if (receipt.status === 1) {
        setTxHash(receipt.transactionHash);
        return SUCCESS;
      } else return FAILURE;
    }
  } catch (e) {
    console.log(e);
    return e;
  }
}

export async function unwrapKlay(
  address: string,
  account: string,
  amount: string,
  library: any,
  isKaikas: boolean,
  setTxHash: SetStateAction<any>
): Promise<any> {
  const gasPrice = await getGasPriceFromAPI();
  let contract: any;
  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    const wAbi: AbiItem[] = wklayAbi as AbiItem[];
    contract = new caver.klay.Contract(wAbi, address);
  } else {
    contract = new ethers.Contract(address, wklayAbi, library?.getSigner());
  }
  let tx;

  // gasLimit 계산
  let gasLimit;
  if (isKaikas)
    gasLimit = await contract?.methods.withdraw(amount).estimateGas({
      from: account,
    });
  else gasLimit = await contract.estimateGas.withdraw(amount);

  // registerItems 요청
  let receipt;
  try {
    let overrides: Overrides = {
      from: account,
      gasLimit: calculateGasMargin(BigNumber.from(gasLimit)),
    };

    if (isKaikas) {
      tx = await contract.methods
        .withdraw(amount)
        .send(overrides)
        .catch(async (err: any) => {
          return FAILURE;
        });
      if (tx?.status) {
        return SUCCESS;
      } else return FAILURE;
    } else {
      // if (library._network.chainId === 8217)
      overrides = { ...overrides, gasPrice };

      tx = await contract.withdraw(amount, overrides);

      // receipt 대기
      try {
        receipt = await tx.wait();
      } catch (e) {
        return FAILURE;
      }
      if (receipt.status === 1) {
        setTxHash(receipt.transactionHash);
        return SUCCESS;
      } else return FAILURE;
    }
  } catch (e) {
    console.log(e);
    return e;
  }
}

interface BridgeFee {
  nativeFee: string;
  zroFee: string;
}
export async function sendFrom(
  address: string,
  chainId: number,
  account: string,
  recipient: string,
  amount: string,
  library: any,
  isKaikas: boolean,
  setTxHash: SetStateAction<any>
): Promise<any> {
  const gasPrice = await getGasPriceFromAPI();
  let contract: any;
  if (isKaikas) {
    // @ts-ignore : In case of Klaytn Kaikas Wallet
    const caver = new Caver(window.klaytn);
    const pAbi: AbiItem[] = proxyOftV2Abi as AbiItem[];
    contract = new caver.klay.Contract(pAbi, address);
  } else {
    contract = new ethers.Contract(address, proxyOftV2Abi, library?.getSigner());
  }
  let tx;

  const to = ethers.utils.defaultAbiCoder.encode(['address'], [recipient]);

  const defaultAdapterParams = ethers.utils.solidityPack(['uint16', 'uint256'], [1, 200000]);
  const callParams = [];
  callParams.push(account);
  callParams.push(ethers.constants.AddressZero);
  callParams.push(defaultAdapterParams);

  let nativeFee = '';
  if (isKaikas) {
    const fee: BridgeFee = await contract.methods
      .estimateSendFee(chainId, to, amount, false, defaultAdapterParams)
      .call()
      .catch(async (err: any) => {
        console.log('#####', address, account);
        console.log('estimateSendFee Error : ', err);
      });
    nativeFee = fee.nativeFee;
  } else {
    const fee: BridgeFee = await contract.estimateSendFee(chainId, to, amount, false, defaultAdapterParams);
    nativeFee = fee.nativeFee;
  }

  // gasLimit 계산
  let gasLimit;
  if (isKaikas)
    gasLimit = await contract?.methods.sendFrom(account, chainId, to, amount, callParams).estimateGas({
      value: nativeFee,
      from: account,
    });
  else
    gasLimit = await contract.estimateGas.sendFrom(account, chainId, to, amount, callParams, {
      value: nativeFee,
      from: account,
    });
  console.log('###################', gasLimit, calculateGasMargin(BigNumber.from(gasLimit)));

  // registerItems 요청
  let receipt;
  try {
    let overrides: Overrides = {
      value: nativeFee,
      from: account,
      gasLimit: calculateGasMargin(BigNumber.from(gasLimit)),
    };

    if (isKaikas) {
      tx = await contract.methods
        .sendFrom(account, chainId, to, amount, callParams)
        .send(overrides)
        .catch(async (err: any) => {
          return { result: FAILURE, txHash: null };
        });
      if (tx?.status) {
        return { result: SUCCESS, txHash: tx.transactionHash };
      } else return { result: FAILURE, txHash: null };
    } else {
      // if (library._network.chainId === 8217)
      overrides = { ...overrides, gasPrice };

      tx = await contract.sendFrom(account, chainId, to, amount, callParams, overrides);

      // receipt 대기
      try {
        receipt = await tx.wait();
      } catch (e) {
        return { result: FAILURE, txHash: null };
      }
      if (receipt.status === 1) {
        setTxHash(receipt.transactionHash);
        return { result: SUCCESS, txHash: receipt.transactionHash };
      } else return { result: FAILURE, txHash: null };
    }
  } catch (e: any) {
    console.log(e?.message);
    if (e?.message?.includes('Incorrect twofactor verify token')) alert('Incorrect twofactor verify token');
    if (e?.message?.includes('insufficient funds for intrinsic transaction cost'))
      alert('insufficient funds for intrinsic transaction cost');
    return { result: FAILURE, txHash: null };
  }
}

export async function getSendFee(
  address: string,
  chainId: number,
  destination: number,
  recipient: string,
  amount: string
): Promise<any> {
  const provider = ethers.getDefaultProvider(getSelectedNodeUrl(chainId));
  const contract = new ethers.Contract(address, proxyOftV2Abi, provider);

  const to = ethers.utils.defaultAbiCoder.encode(['address'], [recipient]);
  const defaultAdapterParams = ethers.utils.solidityPack(['uint16', 'uint256'], [1, 200000]);
  console.log('!! getSendFee : ', { destination, to, amount, defaultAdapterParams });
  const fee: BridgeFee = await contract.estimateSendFee(destination, to, amount, false, defaultAdapterParams);
  return fee.nativeFee;
}

async function getGasPriceFromAPI(chainId?: number) {
  const target = parseInt(localStorage.getItem('target') ?? env.REACT_APP_TARGET_NETWORK.toString());
  if (chainId && chainId !== target) console.log('!! getGasPriceFromAPI Error : Chain mismatch ...');
  const provider = ethers.getDefaultProvider(RPC_URLS[target]);

  let gasPrice = '';

  if (target === 1001 || target === 8217) {
    gasPrice = await caver.rpc.klay.getGasPrice();
    console.log('!! gasPrice 1:::', gasPrice);
    // } else if (target === 80001) {
    //   const result = await axios.get('https://gasstation-testnet.polygon.technology/v2');
    //   gasPrice = ethers.utils.parseUnits(result.data.fast.maxFee.toFixed(5).toString(), 'gwei').toHexString();
    // } else if (target === 137) {
    //   const result = await axios.get('https://gasstation.polygon.technology/v2');
    //   gasPrice = ethers.utils.parseUnits(result.data.fast.maxFee.toFixed(5).toString(), 'gwei').toHexString();
  } else if (
    target === 56 ||
    target === 97 ||
    target === 1 ||
    target === 11155111 ||
    target === 137 ||
    target === 80002
  ) {
    console.log('!! gasPrice 2:::', gasPrice);
    const result = await provider.getGasPrice();
    gasPrice = result.toHexString();
  }
  console.log('!! getGasPriceFromAPI getPrice = ', gasPrice);
  return gasPrice;
}
