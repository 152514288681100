import { useEffect, useState } from 'react';
import useActiveWeb3React from '../../../hooks/useActiveWeb3React';
import { setActivatingConnector } from '../../../redux/slices/wallet';
import {
  injected,
  kaikas,
  abc,
  // walletconnect,
  walletconnectv2,
  // talkenwallet,
} from '../../../hooks/connectors';
import { useDispatch } from 'react-redux';
import logo_talken_dark from '../../../assets/svg/logo_header.svg';
import logo_talken_light from '../../../assets/svg/logo_talken_blue.svg';
import logo_google from '../../../assets/icon/logo_google.png';
import logo_apple_black from '../../../assets/icon/logo_apple_black.png';
import logo_facebook from '../../../assets/icon/logo_facebook.png';
import { Link, useNavigate } from 'react-router-dom';
import { useThemeMode } from 'components/common/AppStoreType';
import { requestWalletLogin, requestNativeLogin } from '../../../services/services';
import { getUA } from 'react-device-detect';
import env from '../../../env';
import check_all_connect from '../../../assets/icon/check_all_connect.png';
import check_all_connect_light from '../../../assets/icon/check_all_connect_light.png';
import checked_all_connect from '../../../assets/icon/checked_all_connect.png';

declare global {
  interface Window {
    appAuthCallback: any;
    webkit: any;
    TalkenInterface: any;
    klaytn: any;
  }
}
const appInitialState = {
  osType: '',
  appName: '',
  appVersion: '',
};
const ConnectPage = () => {
  const navigate = useNavigate();
  const { isDarkMode } = useThemeMode();
  const dispatch = useDispatch();
  const { activate, deactivate, account, library } = useActiveWeb3React();
  const [doSign, setDoSign] = useState(false);
  const [isCheckTerms, setIsCheckTerms] = useState(false);
  const appInfo = appInitialState;
  const ua = getUA;
  const arr = ua.split(' ');
  const osType = arr.find((el) => {
    return el.indexOf('osType:') > -1;
  });
  const appName = arr.find((el) => {
    return el.indexOf('appName:') > -1;
  });
  const appVersion = arr.find((el) => {
    return el.indexOf('appVersion:') > -1;
  });
  if (osType) appInfo.osType = osType.split(':')[1];
  if (appName) appInfo.appName = appName.split(':')[1];
  if (appVersion) appInfo.appVersion = appVersion.split(':')[1];
  const isTalkenApp = appInfo.appName === 'Talken';

  const handleWalletLogin = async (id: number) => {
    setDoSign(true);
    try {
      // deactivate before handle wallet
      await deactivate();
      if (id === 0) {
        await activate(injected, undefined, true);
        dispatch(setActivatingConnector(injected));
      } else if (id === 4) {
        // const wc = walletconnect(true);
        // await activate(wc, undefined, true);
        const wc2 = walletconnectv2(env.REACT_APP_TARGET_NETWORK_KLAY);
        await activate(wc2, undefined, true);
      } else if (id === 1) {
        if (isTalkenApp) {
          // Talken App일 경우 메타마스크를 호출.
          await activate(injected, undefined, true);
          dispatch(setActivatingConnector(injected));
        } else {
          // const wc = talkenwallet(true);
          // await activate(wc, undefined, true);
          const wc2 = walletconnectv2(env.REACT_APP_TARGET_NETWORK_KLAY);
          await activate(wc2, undefined, true);
        }
      } else if (id === 2) {
        await activate(kaikas, undefined, true);
        await dispatch(setActivatingConnector(kaikas));
      } else if (id === 3) {
        await activate(abc, undefined, true);
        await dispatch(setActivatingConnector(abc));
      }
    } catch (error: any) {
      if (error.name === 'UnsupportedChainIdError') alert('Please check wallet Chain ID.');
      else alert(error.message);
    }
  };

  useEffect(() => {
    const walletLogin = async () => {
      if (!library || !doSign) return;
      const target_copy = Object.assign({}, library.provider);
      const isAbc = target_copy.isABC === true;
      const isKaikas = typeof target_copy._kaikas !== 'undefined';
      const chainId = await library.getSigner().getChainId();
      const message = `apps.talken.io wants you to sign in with your Ethereum account. Talken Drops Signature Request Type: Login request`;
      const domain = { chainId }; // All properties on a domain are optional
      const types = { Mail: [{ name: 'contents', type: 'string' }] };
      const mail = { contents: message }; // The data to sign
      const signature = await library
        .getSigner()
        ._signTypedData(domain, types, mail)
        .catch(() => deactivate());
      if (!signature) return; // 서명 거부
      const msgParams = JSON.stringify({ domain, types, mail });
      const data = { msgParams, signature };
      const res = await requestWalletLogin(data);
      if (res.data === 'loginSuccess') location.replace('/');
      if (res.data === 'User not found!') {
        deactivate();
        alert('Please continue with SNS and register wallet address on My Profile page.');
      }
      setDoSign(false);
    };

    walletLogin();
  }, [library]);
  const handleSnsLogin = async (snsType: string) => {
    const lang = 'en';
    window.localStorage.setItem('login', 'sns');
    if (isTalkenApp && snsType !== 'apple') {
      // native app 분기
      // Google, Facebook 동일하게{ callback: "appAuthCallback" }
      // apple은 native 분기를 태우지 않는다.
      const data = Object.assign({}, { type: snsType, locale: lang }, { callback: 'appAuthCallback' });
      const _data = JSON.stringify(data);
      if (appInfo.osType === 'IOS') {
        window.webkit.messageHandlers.auth.postMessage(_data);
      } else {
        window.TalkenInterface.auth(_data);
      }
    } else {
      window.location.href = `${process.env.REACT_APP_API_SERVER}/auth/${snsType}?redirectUrl=/`;
    }
  };

  useEffect(() => {
    localStorage.removeItem('walletconnect');
    window.appAuthCallback = nativeAppAuthCallback;
    return () => {
      window.appAuthCallback = undefined;
    };
  }, []);
  const nativeAppAuthCallback = async (response: string) => {
    if (response) {
      const obj = JSON.parse(response);
      const res = await requestNativeLogin(obj);
      if (res.data === 'loginSuccess') location.replace('/');
      else if (res.data === 'notRegisterd') location.replace('/accounts/register');
      else alert('Native App Login Error!!');
    }
  };

  return (
    <div className="signup-page">
      <div className="logo-talken" onClick={() => navigate('/')}>
        {isDarkMode ? (
          <img src={logo_talken_dark} alt="Logo Talken" />
        ) : (
          <img src={logo_talken_light} alt="Logo Talken" />
        )}
      </div>
      <div className="form-connect">
        <div className="title-head">Welcome back</div>
        <div className="line"></div>
        <div className="wrapper-term-conditions">
          <div className="fs-20 text-center fw-4">Terms & Conditions</div>
          <div className="fs-12 lh-20 p-t-16 p-b-24 fw-4">
            Talken does not permit access to users located in Afghanistan, Belarus, Crimea, Cuba, Donetsk, Iran,
            Luhansk, North Korea, Russia, Syria, and Venezuela (“Prohibited Jurisdictions”), based on applicable laws
            and regulations and other criteria. In using Talken, you confirm that you are not located in, incorporated
            or otherwise organized in, or a citizen or resident of, a Prohibited Jurisdiction. Talken routinely reviews
            and makes updates to its Prohibited Jurisdictions list as appropriate.
          </div>
          <div className="d-flex gap-14px gap-xs-8px justify-content-center align-items-center">
            <div className="icon-check-all hover-cusor-pointer" onClick={() => setIsCheckTerms(!isCheckTerms)}>
              {isCheckTerms ? (
                <img src={checked_all_connect} width={20} alt="Check All" />
              ) : isDarkMode ? (
                <img src={check_all_connect} width={20} alt="Check All" />
              ) : (
                <img src={check_all_connect_light} width={20} alt="Check All" />
              )}
            </div>
            <div className="fw-5 fs-12 lh-14 color-dbdbdb">
              I confirm that I have read, understand and accept the
              <Link to={'/terms-conditions'} className="color-inherit text-decoration-underline hover-opa-7">
                {'  '}
                Terms of service{'  '}
              </Link>
              and the
              <Link to={'/privacy-policy'} className="color-inherit text-decoration-underline hover-opa-7">
                {'  '}
                Privacy policy{'  '}
              </Link>
            </div>
          </div>
        </div>
        {isCheckTerms && (
          <div className="wrapper-content-connect">
            <div className="title-content">Sign Up With</div>
            <div className="list-sns-connect">
              <div className="border-icon-sns">
                <div className="icon-sns" onClick={() => handleSnsLogin('google')}>
                  <img src={logo_google} alt="Google Icon" />
                </div>
              </div>

              <div className="border-icon-sns">
                <div className="icon-sns" onClick={() => handleSnsLogin('apple')}>
                  <img src={logo_apple_black} alt="Apple Icon" />
                </div>
              </div>

              <div className="border-icon-sns">
                <div className="icon-sns" onClick={() => handleSnsLogin('facebook')}>
                  <img src={logo_facebook} alt="Facebook Icon" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConnectPage;
