import React, { useState, useEffect } from 'react';
import aquarium_sounhong from '../../assets/img/aquarium_sounhong3.png';
import aquarium_02 from '../../assets/img/aquarium_02.png';
import arrow_white from '../../assets/icon/arrow_white.png';
import group_wave_vector from '../../assets/svg/group_wave_vector.svg';
import { useTranslation, Trans } from 'react-i18next';
import i18n from 'i18next';

const Aquarium1402One = () => {
  const [font, setFont] = useState('');
  const [scrollY, setScrollY] = useState(0);
  const [isTop, setIsTop] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    const lang = window.localStorage.getItem('language');
    if (lang) {
      setFont(lang);
    } else {
      window.localStorage.setItem('language', 'ko');
      setFont('ko');
    }
  }, []);
  const handleLanguage = (target: string) => {
    window.localStorage.setItem('language', target);
    i18n.changeLanguage(target);
    const lang = window.localStorage.getItem('language');
    if (lang) setFont(lang);
  };
  const checkScroll = () => {
    setScrollY(window.scrollY);
    if (scrollY > 100) {
      setIsTop(false);
    } else {
      setIsTop(true);
    }
  };
  const scrollListner = () => {
    window.addEventListener('scroll', checkScroll);
  };
  const scrollToAquarium1402 = () => {
    window.scrollTo(0, 800);
  };
  useEffect(() => {
    checkScroll();
    scrollListner();
    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  });
  const moreWrapper = isTop ? 'more-wrapper appear' : 'more-wrapper hide';
  return (
    <div className={'main-aquarium-01 min-height-content-landingpage' + ' ' + font}>
      <div className="background-image-aquarium"></div>
      <div className="aquarium1402-1">
        <div className="lang-switch">
          <span className="kr" onClick={() => handleLanguage('ko')}>
            KR
          </span>
          <span>|</span>
          <span className="en" onClick={() => handleLanguage('en')}>
            EN
          </span>
        </div>
        <div className="section-01">
          <div className="header-content">
            {/* <div>Soun-Hong’s</div> */}
            <div>Aquarium-1402</div>
          </div>
          <div className="bottom-content">
            <div className="box">
              <div className="wrapper-sounhong">
                <div className="avt-sounhong">
                  <img src={aquarium_sounhong} alt="Avatar Soun-Hong" />
                </div>
                <div className="about-sounhong">
                  <div className="name-sounhong">{t('about-title')}</div>
                  <div className="about-sidescape">{t('about-sounhong')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-02">
          <div className="wrapper-header">
            <div className="title">
              {/* <strong>Aquarium-1402</strong> is an oil painting on{' '}
              <strong>91 canvases</strong> */}
              <div>{t('about-works')}</div>
            </div>
            <div className="content-header">{t('about-aquarium-1402')}</div>
          </div>
          <div className="wrapper-bottom">
            <div className="image-aquarium">
              <img src={aquarium_02} alt="aquarium 1402" />
            </div>
            <div className="wrapper-infor-nft">
              <div className="title">{t('info-nft-title')}</div>
              <ul className="info-nft-ul">
                <li>{t('info-nft-1')}</li>
                <li>{t('info-nft-2')}</li>
                <li>{t('info-nft-3')}</li>
                <li>
                  <Trans
                    defaults={t('info-nft-4') || ''} // optional defaultValue
                    values={{ link: t('hotpinkdolphins') }}
                    components={{
                      a: <a href="http://hotpinkdolphins.org/" target="_blank" />,
                    }}
                  />
                </li>
                <li>
                  <Trans
                    defaults={t('info-nft-5') || ''} // optional defaultValue
                    values={{ link: t('totalmuseum') }}
                    components={{
                      a: <a href="http://totalmuseum.org/" target="_blank" />,
                    }}
                  />
                </li>
                <li>{t('info-nft-6')}</li>
              </ul>
              <button className="btn-buy-now button">Buy Now</button>
            </div>
          </div>
        </div>
        <div className="vector-background-left"></div>
        <div className="vector-background-right"></div>
        <div className={moreWrapper}>
          <div className="more-box" onClick={scrollToAquarium1402}>
            MORE
            <img src={arrow_white} />
          </div>
        </div>
        {/* <div className="wrapper-wave-border">
          <img src={group_wave_vector} alt="Waves" />
        </div> */}
      </div>
    </div>
  );
};

export default Aquarium1402One;
