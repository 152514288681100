import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="terms-and-conditions-container">
      <div>
        <div className="terms-and-conditions-header">
          <p> TALKEN PRIVACY POLICY</p>
        </div>
        <div className="terms-and-conditions-body">
          <div className="terms-and-conditions-line">
            <p>Last Updated: December 1, 2023</p>
            <p>
              This Privacy Policy explains how Talken GmbH, (“Talken,” “we,” or“us”) collects, uses, and discloses
              information about you.
            </p>
            <p>
              This Privacy Policy applies when you use our websites, mobile applications, and other online content,
              products and services that link to this Privacy Policy (collectively, our“Services”), or otherwise
              interact with us.
            </p>
            <p>
              We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising
              the date at the top of this policy and, in some cases, we may provide you with additional notice (such as
              adding a statement to our website or sending you a notification).
            </p>
            <p>
              We encourage you to review this Privacy Policy regularly to stay informed about our information practices
              and the choices available to you.
            </p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">CONTENTS</div>
            <ul>
              <li>Collection of Information</li>
              <li>Use of Information</li>
              <li>Sharing of Information</li>
              <li>Analytics</li>
              <li>Transfer of Information to the United States</li>
              <li>Your Choices</li>
              <li>Additional Disclosures for Individuals in Europe</li>
              <li>Contact Us</li>
            </ul>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">COLLECTION OF INFORMATION</div>
            <p>Information You Provide to Us.</p>
            <p>
              We collect information you provide directly to us. For example, you share information directly with us
              when you create an account, fill out a form, submit or post content through our Services, make a purchase,
              communicate with us via third-party platforms, participate in a contest or promotion, request customer
              support, or otherwise communicate with us. The types of personal information we may collect include your
              name, email address, postal address, phone number, credit card and other payment information, and any
              other information you choose to provide.
            </p>
          </div>
          <p>We also maintain information relating to your transactions on our Services including:</p>
          <p>
            Activity Information: We collect information about your activity on our Services, such as the purchase,
            sale, trade, or publishing of unique, one-of-a-kind digital merchandise (“Digital Collectibles”), including
            non-fungible tokens (NFTs), or the artists, brands and other users you interact with through the Services.
          </p>
          <p>
            Transactional Information: When you purchase, trade, sell or publish your own Digital Collectibles, we
            collect information about the transaction, such as product details, purchase price, and the date and
            location of the transaction.
          </p>
          <p>Information We Collect Automatically</p>
          <p>
            When you access or use our Services or otherwise transact business with us, we automatically collect certain
            information, including:
          </p>
          <ul>
            <li>
              Device and Usage Information: We collect information about how you access our Services, including data
              about the device and network you use, operating system version, mobile network, IP address, unique device
              identifiers, browser type, and app version. We also collect information about your activity on our
              Services, such as access times, pages viewed, links clicked, and the page you visited before navigating to
              our Services.
            </li>
            <li>
              Location Information: In accordance with your device permissions, we may collect information about the
              precise location of your device. You may stop the collection of precise location information at any time
              (see the Your Choices section below for details).
            </li>
            <li>
              Information Collected by Cookies and Similar Tracking Technologies: We (and our service providers) use
              tracking technologies, such as cookies and web beacons, to collect information about you. Cookies are
              small data files stored on your hard drive or in device memory that help us improve our Services and your
              experience, see which areas and features of our Services are popular, and count visits. Web beacons (also
              known as “pixel tags” or “clear GIFs”) are electronic images that we use on our Services and in our emails
              to help deliver cookies, count visits, and understand usage and campaign effectiveness. For more
              information about cookies and how to disable them, see the Your Choices section below.
            </li>
          </ul>
          <br />
          <p>Information We Collect from Other Sources</p>
          <p>
            We obtain information from third-party sources. For example, if you create or log into your Talken account
            through a third-party platform (such as Apple, Facebook, Kakao, or Google), we will have access to certain
            information from that platform, such as your email address, name, birthday, and profile picture, in
            accordance with the authorization procedures determined by such platform.
          </p>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">USE OF INFORMATION</div>
            <p>
              We use the information we collect to provide the Services, including facilitating your transaction,
              collection, trading and sale of Digital Collectibles, and to customize your experience with us. also use
              the information we collect to:
            </p>
            <ul>
              <li>Provide, maintain, and improve our products and Services;</li>
              <li>
                Process transactions and send you related information, including confirmations, receipts, invoices,
                customer experience surveys, and recall notices;
              </li>
              <li>Personalize and improve your experience on our Services;</li>
              <li>Send you technical notices, security alerts, and support and administrative messages;</li>
              <li>Respond to your comments and questions and provide customer service;</li>
              <li>
                Communicate with you about products, services, and events offered by Talken and others and provide news
                and information that we think will interest you (see the Your Choices section below for information
                about how to opt out of these communications at any time);
              </li>
              <li>Monitor and analyze trends, usage, and activities in connection with our Services;</li>
              <li>Facilitate contests, sweepstakes, and promotions and process and deliver entries and rewards;</li>
              <li>
                Detect, investigate, and prevent security incidents and other malicious, deceptive, fraudulent, or
                illegal activity and protect the rights and property of Talken and others;
              </li>
              <li>Debug to identify and repair errors in our Services;</li>
              <li>Comply with our legal and financial obligations; and</li>
              <li>Carry out any other purpose described to you at the time the information was collected.</li>
            </ul>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">SHARING OF INFORMATION</div>
            <p>
              We share personal information in the following circumstances or as otherwise described in this policy:
            </p>
            <ul>
              <li>
                We share personal information with vendors, service providers, and consultants that need access to
                personal information in order to perform services for us, such as companies that assist us with web
                hosting, shipping and delivery, payment processing, fraud prevention, customer service, and marketing
                and advertising.
              </li>
              <li>
                If you post content in public area of our Services, we share this information publicly on our Services.
              </li>
              <li>
                If you choose to use integrations we offer on our Services, we may share certain information with the
                integration partners.
              </li>
              <li>
                We may disclose personal information if we believe that disclosure is in accordance with, or required
                by, any applicable law or legal process, including lawful requests by public authorities to meet
                national security or law enforcement requirements.
              </li>
              <li>
                We may share personal information if we believe that your actions are inconsistent with our user
                agreements or policies, if we believe that you have violated the law, or if we believe it is necessary
                to protect the rights, property, and safety of Talken, our users, the public, or others.
              </li>
              <li>
                We share personal information with our lawyers and other professional advisors where necessary to obtain
                advice or otherwise protect and manage our business interests.
              </li>
              <li>
                We may share personal information in connection with, or during negotiations concerning, any merger,
                sale of company assets, financing, or acquisition of all or a portion of our business by another
                company.
              </li>
              <li>
                Personal information may be shared between and among Talken and our current and future parents,
                affiliates, and subsidiaries and other companies under common control and ownership.
              </li>
              <li>We share personal information with your consent or at your direction.</li>
            </ul>
            <p>We also share aggregated or de-identified information that cannot reasonably be used to identify you.</p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">
              USE AND SHARING OF INFORMATION FOR JOINTLY OFFERED OR MARKETED DIGITAL COLLECTIBLES
            </div>
            <p>
              In some cases, we make available Digital Collectibles to you jointly with or on behalf of certain third
              parties through Talken Services.
            </p>
            <p>
              When you purchase a jointly offered or marketed Digital Collectible through the Talken Services, we will
              indicate that by enabling that third party to post its privacy policy along with ours on the particular
              page or service to provide you notice of how it collects and uses your information on those pages.
            </p>
            <p>
              We will share your personal information related to those transactions with that third party who will also
              collect that information in connection with those transactions and use it in accordance with their posted
              privacy policy.
            </p>
            <p>
              By engaging with us and those third parties with whom we offer Digital Collectibles, you intentionally
              interact with both of us and direct us to share your information with such third parties.
            </p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">ANALYTICS</div>
            <p>We allow others to provide analytics services on our behalf across the web and in mobile apps.</p>
            <p>
              These entities may use cookies, web beacons, device identifiers, and other technologies to collect
              information about your use of our Services and other websites and applications, including your IP address,
              web browser, mobile network information, pages viewed, time spent on pages or in mobile apps, links
              clicked, and conversion information.
            </p>
            <p>
              This information may be used by Talken and others to, among other things, analyze and track data,
              determine the popularity of certain content, and better understand your online activity.
            </p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">
              TRANSFER OF INFORMATION TO THE SwITZERLAND AND OTHER COUNTRIES
            </div>
            <p>
              Talken is headquartered in the Switzerland, and we have operations and service providers in the Republic
              of Korea.
            </p>
            <p>
              Therefore, we and our service providers may transfer your personal information to, or store or access it
              in, jurisdictions that may not provide levels of data protection that are equivalent to those of your home
              jurisdiction.
            </p>
            <p>
              We will take steps to ensure that your personal information receives an adequate level of protection in
              the jurisdictions in which we process it.
            </p>
          </div>
          <div className="terms-and-conditions-line">
            <div className="terms-and-conditions-line-title">YOUR CHOICES</div>
            <p>Account Information</p>
            <p>
              You may update and correct certain account information at any time by logging into your account, emailing
              us at talken@talken.io, requesting help via our website or mobile application. If you wish to delete your
              account, please email us at talken@talken.io, but note that we may retain certain information as required
              by law or for our legitimate business purposes.
            </p>
            <p>Location Information</p>
            <p>
              When you first launch a feature of our mobile applications that collects precise location information, you
              will be asked to consent to the app’s collection of this information. If you initially consent to our
              collection of such location information, you can subsequently stop the collection of this information at
              any time by changing the preferences on your mobile device. You may also stop our collection of this
              location information by following the standard uninstall process to remove all of our mobile apps from
              your device. If you stop our collection of this location information, some features of our Services may no
              longer function properly.
            </p>
            <p>Cookies</p>
            <p>
              Most web browsers are set to accept cookies by default. If you prefer, you can usually adjust your browser
              settings to remove or reject browser cookies. Please note that removing or rejecting cookies could affect
              the availability and functionality of our Services.
            </p>
            <p>Communications Preferences</p>
            <p>
              You may opt out of receiving promotional emails from Talken by following the instructions in those
              communications or by sending us an email at talken@talken.io. If you opt out, we may still send you
              non-promotional emails, such as those about your account or our ongoing business relations.
            </p>
            <p>Mobile Push Notifications</p>
            <p>
              With your consent, we may send push notifications to your mobile device. You can deactivate these messages
              at any time by changing the notification settings on your mobile device.
            </p>
            <p>ADDITIONAL DISCLOSURES FOR INDIVIDUALS IN EUROPE</p>
            <p>
              If you are located in the European Economic Area (EEA), the United Kingdom, or Switzerland, you have
              certain rights and protections under the law regarding the processing of your personal data, and this
              section applies to you.
            </p>
            <p>Legal Basis for Processing</p>
            <p>When we process your personal data, we will do so in reliance on the following lawful bases:</p>
            <ul>
              <li>
                To perform our responsibilities under our contract with you (e.g., processing payments for and providing
                the products and services you requested).
              </li>
              <li>
                When we have a legitimate interest in processing your personal data to operate our business or protect
                our interests (e.g., to provide, maintain, and improve our products and services, conduct data
                analytics, and communicate with you).
              </li>
              <li>
                To comply with our legal obligations (e.g., to maintain a record of your consents and track those who
                have opted out of marketing communications).
              </li>
              <li>
                When we have your consent to do so (e.g., when you opt in to receive marketing communications from us).
                When consent is the legal basis for our processing your personal data, you may withdraw such consent at
                any time.
              </li>
            </ul>
            <p>Data Retention</p>
            <p>
              We store personal data associated with your account for as long as your account remains active. If you
              close your account, we will delete your account data within 30 days. We store other personal data for as
              long as necessary to carry out the purposes for which we originally collected it and for other legitimate
              business purposes, including to meet our legal, regulatory, or other compliance obligations.
            </p>
            <p>Data Subject Requests</p>
            <p>
              Subject to certain limitations, you have the right to request access to the personal data we hold about
              you and to receive your data in a portable format, the right to ask that your personal data be corrected
              or erased, and the right to object to, or request that we restrict, certain processing.
            </p>
            <p>If you would like to exercise any of these rights, please email us at talken@talken.io.</p>
            <p>Questions or Complaints</p>
            <p>
              If you have a concern about our processing of personal data that we are not able to resolve, you have the
              right to lodge a complaint with the Data Protection Authority where you reside. Contact details for your
              Data Protection Authority can be found using the links below:
            </p>
            <p>
              For individuals in the EEA:{' '}
              <a href="https://edpb.europa.eu/about-edpb/board/members_en">
                https://edpb.europa.eu/about-edpb/board/members_en
              </a>
            </p>
            <p>
              For individuals in the UK:{' '}
              <a href="https://ico.org.uk/global/contact-us/">https://ico.org.uk/global/contact-us/</a>
            </p>
            <p>
              For individuals in Switzerland:{' '}
              <a href="https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact.html">
                https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact.html
              </a>
            </p>
          </div>
          <p>CONTACT US</p>
          <p>If you have any questions about this Privacy Policy, please email us at talken@talken.io.</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
