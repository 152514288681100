import React, { useEffect, useState } from 'react';
import close_icon from '../../assets/icon/close_icon.svg';
import arrow_btn_back_dark from '../../assets/svg/arrow_btn_back_dark.svg';
import arrow_btn_back_light from '../../assets/svg/arrow_btn_back_light.svg';
import icon_seemore from '../../assets/icon/icon_seemore.png';
import { CircularProgress } from '@mui/material';
import PaymentWallets from '../../components/modal/PaymentWallets';
import PaymentWalletsSuccess from '../../components/modal/PaymentWalletsSuccess';
import Popup from 'reactjs-popup';
import WalletConnector from '../../components/auth/WalletConnector/WalletConnector';
import CSnackbar from '../../components/common/CSnackbar';
import { useNavigate, useParams } from 'react-router-dom';
import { MBoxItemTypes } from '../../types/MBoxItemTypes';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import CountDownTimer from '../../components/TimeCounter/CountDownTimer';
import { buyItem, getItemAmountNoSigner } from '../../utils/transactions';
import contracts from '../../config/constants/contracts';
import { SUCCESS } from '../../config';
import { getCollectionInfo, getFeaturedById, registerBuy, getFreeDroppedCount } from '../../services/services';
import { parseEther, parseUnits } from 'ethers/lib/utils';
import { getNetworkNameById } from '../../utils/getNetworkNameById';
import { useSelector } from 'react-redux';
import ReactModal from 'react-modal';
import { MBoxTypes } from '../../types/MBoxTypes';
import { FeaturedTypes } from '../../types/FeaturedTypes';
import useCopyToClipBoard from '../../hooks/useCopyToClipboard';
import { BigNumber } from 'ethers';
import { checkKaikasWallet, getTargetNetworkName } from '../../utils/wallet';
import { getPrice } from '../../utils/getPrice';
import ConvertUrlsToLinks from '../../utils/convertUrlsToLinks';
import GetSnsButtons from 'components/getsnsbuttons/GetSnsButtons';
import { useThemeMode } from 'components/common/AppStoreType';
import getAbcWallet from '../../utils/getAbcWallet';
import { HandleMediaError } from 'components/common/HandleMediaError';

type ExMBoxItemTypes = MBoxItemTypes & {
  collectionInfo: any;
  companyLogo: string;
  companyName: string;
  price: number;
  quote: string;
  index: number;
};

const overlayStyle = { background: 'rgba(0,0,0,0.8)' };
const closeOnDocumentClick = false;
const lockScroll = true;

const CollectionSaleDetail = () => {
  // const dropsAccount = useSelector((state: any) => state.account.account);
  const user = useSelector((state: any) => state.user.user);
  const uid = user.uid;
  const userEthAddr = user.eth_address?.toLowerCase();
  const params = useParams();
  const navigate = useNavigate();
  const { account, library, chainId } = useActiveWeb3React();
  const { copyToClipBoard, copyResult, setCopyResult } = useCopyToClipBoard();

  const [isLoading, setIsLoading] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [isZoomImage, setIsZoomImage] = React.useState(false);
  const [openPaymentWallets, setOpenPaymentWallets] = useState(false);
  const [signupOpen, setSignupOpen] = useState(false);
  const [openPaymentWalletsSuccess, setOpenPaymentWalletsSuccess] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [collectionItemInfo, setCollectionItemInfo] = useState<ExMBoxItemTypes | null>(null);
  const [featuredInfo, setFeaturedInfo] = useState<FeaturedTypes | null>(null);
  const [countDownFinish, setCountDownFinish] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    type: '',
    message: '',
  });
  const [freedropAble, setFreedropAble] = useState(false);
  const wallet = useSelector((state: any) => state.wallet);
  const [isIap, setIsIap] = useState(false);
  const [quote, setQuote] = useState('');
  // useOnClickOutsideDropdown(refDropdown, () => setDropdownOpen(false));

  // get abc wallet address and check OTP ready
  const { isAbcReady, abcAddress } = getAbcWallet();

  const handeCheckCountDownFinish = () => {
    setCountDownFinish(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar({
      open: false,
      type: 'info',
      message: '',
    });
  };

  const handleClosePaymentWallet = async () => {
    setOpenPaymentWalletsSuccess(false);
    fetchRemaining();
  };
  const { isDarkMode } = useThemeMode();
  const arrow_btn_back = isDarkMode ? arrow_btn_back_dark : arrow_btn_back_light;
  const fetchRemaining = async () => {
    // const remaining = await getItemAmount(
    //   collectionItemInfo?.collectionInfo.boxContractAddress,
    //   collectionItemInfo?.index ? collectionItemInfo?.index : 0,
    //   2, // 1 = MysteryBox, 2 = Collection
    //   account,
    //   library
    // );
    const remaining = await getItemAmountNoSigner(
      collectionItemInfo?.collectionInfo.boxContractAddress,
      collectionItemInfo?.no ? collectionItemInfo?.no - 1 : 0,
      2, // 1 = MysteryBox, 2 = Collection
      account,
      collectionItemInfo?.collectionInfo.chainId
    );
    setRemainingAmount(remaining);
  };
  const closeLogin = () => {
    setLoginOpen(false);
  };
  const closeSignup = () => {
    setSignupOpen(false);
  };

  const handleBuyClick = async () => {
    setIsLoading(true);
    const isKaikas = checkKaikasWallet(wallet, getTargetNetworkName(collectionItemInfo?.collectionInfo?.chainId) ?? '');
    const contract = collectionItemInfo?.collectionInfo?.boxContractAddress;
    const quote = collectionItemInfo?.collectionInfo?.quote;
    const index = collectionItemInfo?.index ?? 0;
    const amount = 1;

    let quoteToken: string;
    let payment: BigNumber;
    if (quote === 'klay' || quote === 'wklay') {
      quoteToken = quote === 'klay' ? contracts.klay[chainId] : contracts.wklay[chainId];
      payment = parseEther(collectionItemInfo?.price.toString() ?? '0').mul(amount);
    } else if (quote === 'usdt' || quote === 'usdc') {
      quoteToken = quote === 'usdt' ? contracts.usdt[chainId] : contracts.usdc[chainId];
      payment = parseUnits(collectionItemInfo?.price.toString() ?? '0', 6).mul(amount);
    }
    const result = await buyItem(contract, index, 1, payment!.toString(), quoteToken!, account, library, isKaikas);

    if (result.status === SUCCESS) {
      const data = {
        mysterybox_id: collectionItemInfo?.collectionInfo?.id,
        buyer: user.uid,
        buyer_address: account,
        isSent: true,
        txHash: result?.txHash,
        price: collectionItemInfo?.price,
        itemId: collectionItemInfo?.id,
      };

      const res = await registerBuy(data);
      if (res.data.status === SUCCESS) {
        setOpenSnackbar({
          open: true,
          type: 'success',
          message: 'Success',
        });
      }
    }
    setIsLoading(false);
  };

  const getRemaining = async (boxContractAddress: string, isAirdrop: boolean, chainId: number) => {
    const remaining = await getItemAmountNoSigner(
      boxContractAddress,
      0,
      isAirdrop ? 3 : 2, // 1 = MysteryBox, 2 = Collection, 3 = AirDrop
      account,
      // library
      chainId
    );
    setRemainingAmount(remaining);
  };
  const getItemUrl = (infoId: number, itemId: number, item: MBoxTypes) => {
    let url = '/';
    if (item.isCollection === false) {
      if (item.isAirdrop === false) {
        url = `/mbox/${item.id}`;
      } else {
        url = `/airdrop/${item.id}`;
      }
    } else {
      url = `/collection/${infoId}/${item.id}`;
    }
    return url;
  };

  const moveToFeatured = () => {
    navigate(`/creator/${collectionItemInfo?.collectionInfo.featuredId}`);
  };

  const fetchCollectionItemInfo = async () => {
    const res = await getCollectionInfo(params.collectionId!);
    if (res.data.status === SUCCESS) {
      const collectionInfo = res.data.data;
      const collectionItem = collectionInfo.mysteryboxItems.find((item: any) => item.id.toString() === params.id);
      const milliseconds = new Date().getTime() - Date.parse(collectionInfo.releaseDatetime);
      collectionInfo.onsale = milliseconds >= 0 ? true : false;
      const data = {
        collectionInfo: collectionInfo,
        ...collectionItem,
        companyLogo: collectionInfo.featured.company.image,
        companyName: collectionInfo.featured.company.name.en,
        quote: collectionInfo.quote,
      };
      if (collectionInfo.remainingAmount === undefined) {
        getRemaining(collectionInfo.boxContractAddress, collectionInfo.isAirdrop, collectionInfo.chainId);
      } else {
        setRemainingAmount(collectionInfo.remainingAmount);
      }
      const featuredInfoRes = await getFeaturedById(res.data.data.featuredId);
      if (featuredInfoRes.data !== '') {
        setFeaturedInfo(featuredInfoRes.data);
      }
      setCollectionItemInfo(data);
    }
  };

  const checkFreedropAble = async () => {
    const mysteryBoxId = collectionItemInfo?.collectionInfo?.id;
    if ((account || isAbcReady) && mysteryBoxId) {
      const freedropAbleRes = await getFreeDroppedCount(mysteryBoxId, uid);
      if (freedropAbleRes.data != '') {
        if (freedropAbleRes.data?.data === 0) {
          setFreedropAble(true);
        }
      }
    }
  };
  const hasCJK = (string) => {
    const regex = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f\u3131-\uD79D]/;
    return string?.match(regex) ? true : false;
  };
  useEffect(() => {
    setOpenSnackbar({
      open: copyResult,
      type: 'success',
      message: 'Copied!',
    });
  }, [copyResult]);

  useEffect(() => {
    fetchCollectionItemInfo();
    if (collectionItemInfo) {
      setQuote(
        collectionItemInfo.collectionInfo?.fiatPrice
          ? 'KRW'
          : collectionItemInfo?.quote?.toUpperCase() === 'ETHEREUM'
          ? 'ETH'
          : collectionItemInfo?.quote?.toUpperCase()
      );
    }
  }, [params]);

  useEffect(() => {
    checkFreedropAble();
  }, [account, collectionItemInfo]);

  return (
    <main className="collection-container min-height-content">
      <div>
        <div className="back-button-wrapper">
          <button className="back-button" onClick={() => navigate(-1)}>
            <img src={arrow_btn_back} alt="arrow back" /> Back
          </button>
        </div>
        <div className="price-collection-view-page">
          <div className="price-collection-box">
            <div className="token-details-box-mobile">
              <div className="wrapper-head-token">
                <div className="box-owner-product" onClick={moveToFeatured}>
                  <button className="btn-avatar-owner-product">
                    <img src={collectionItemInfo?.companyLogo} alt={collectionItemInfo?.companyName} />
                  </button>
                  <div className="name-owner-product">
                    <div className="creator-title">Creator</div>
                    <button className="btn-name-owner-product">{collectionItemInfo?.companyName}</button>
                  </div>
                </div>
                <GetSnsButtons
                  links={featuredInfo?.links}
                  chainId={collectionItemInfo?.collectionInfo.chainId}
                  boxContractAddress={collectionItemInfo?.collectionInfo?.boxContractAddress}
                />
              </div>
            </div>
            <div className="token-showcase-box">
              {collectionItemInfo && collectionItemInfo?.originalImage!.indexOf('.mp4') > -1 ? (
                <div>
                  <video
                    controls
                    muted
                    autoPlay
                    loop
                    playsInline
                    onError={HandleMediaError}
                    key={collectionItemInfo?.originalImage
                      ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                      .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}
                  >
                    <source
                      src={collectionItemInfo?.originalImage
                        ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                        .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}
                      type="video/mp4"
                    />
                  </video>
                </div>
              ) : (
                <>
                  <div className="bg-linear"></div>
                  <img src={collectionItemInfo?.originalImage} alt="" onError={HandleMediaError} />
                  {/* modal zoom image */}
                  <div className="zoom-image" onClick={() => setIsZoomImage(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 41 40" fill="none">
                      <g filter="url(#filter0_b_5137_8622)">
                        <circle cx="20.5098" cy="20" r="20" fill="white" fillOpacity="0.8" />
                      </g>
                      <path
                        d="M29.5098 17V11H23.5098"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.5098 23V29H17.5098"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M29.5098 11L22.0098 18.5"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.0098 21.5L11.5098 29"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <defs>
                        <filter
                          id="filter0_b_5137_8622"
                          x="-4.49023"
                          y="-5"
                          width="50"
                          height="50"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood floodOpacity="0" result="BackgroundImageFix" />
                          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.5" />
                          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5137_8622" />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_backgroundBlur_5137_8622"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                  <ReactModal isOpen={isZoomImage} className={'modal-zoom-image'}>
                    <div className="close-modal" onClick={() => setIsZoomImage(false)}>
                      <img src={close_icon} alt="Close Icon" />
                    </div>
                    <img className="image" src={collectionItemInfo?.originalImage} alt="" />
                  </ReactModal>
                </>
              )}
            </div>
            {/* <div className="straight-line"></div> */}
            <div className="token-details-box">
              <div className="wrapper-head-token">
                <div className="box-owner-product" onClick={moveToFeatured}>
                  <button className="btn-avatar-owner-product">
                    <img src={collectionItemInfo?.companyLogo} alt={collectionItemInfo?.companyName} />
                  </button>
                  <div className="name-owner-product">
                    <div className="creator-title">Creator</div>
                    <button className="btn-name-owner-product">{collectionItemInfo?.companyName}</button>
                  </div>
                </div>
                <GetSnsButtons
                  links={featuredInfo?.links}
                  chainId={collectionItemInfo?.collectionInfo.chainId}
                  boxContractAddress={collectionItemInfo?.collectionInfo?.boxContractAddress}
                />
              </div>
              {/* <div className="bottom-line" /> */}
              {/* <div>
                {freedropAble && (
                  <div className="btn-buy-now">
                    {collectionItemInfo?.collectionInfo.onsale
                      ? collectionItemInfo?.price === 0
                        ? 'Get Now'
                        : 'Buy Now'
                      : 'Waiting'}
                  </div>
                )}
              </div> */}
              <div>
                <div className="box-name-collection">
                  <div className="name-collection fw-600">{collectionItemInfo?.name}</div>
                </div>
              </div>
              <div>
                <div className={`content-collection ${hasCJK(collectionItemInfo?.description) ? 'cjk-font' : ''}`}>
                  <ConvertUrlsToLinks text={collectionItemInfo?.description || 'no description'} />
                </div>
              </div>
              {/*<div>*/}
              {/*  <a className="authenticity-button">*/}
              {/*    <img src={ic_info} style={{ marginRight: '5px' }} alt="" />{' '}*/}
              {/*    Authenticity*/}
              {/*  </a>*/}
              {/*</div>*/}
              <div>
                <div className="box-price-collection">
                  <div className="box-price-detail-collection">
                    <div className="lable-top">Total Items</div>
                    <div className="lable-bottom fw-600">{collectionItemInfo?.issueAmount}</div>
                  </div>
                  <div className="box-price-detail-collection">
                    <div className="lable-top">Availability</div>
                    <div className="lable-bottom fw-600">
                      {collectionItemInfo?.collectionInfo.isSoldOut ? '0' : remainingAmount}
                    </div>
                  </div>
                  <div className="box-price-detail-collection">
                    <div className="lable-top">Chain</div>
                    <div className="lable-bottom fw-600" style={{ textTransform: 'capitalize' }}>
                      {getNetworkNameById(collectionItemInfo?.collectionInfo.chainId)}
                    </div>
                  </div>
                </div>
              </div>
              {collectionItemInfo?.collectionInfo.isCollection === false &&
                collectionItemInfo?.collectionInfo.isAirdrop === true && (
                  <>
                    <div className="airdrop-condition">
                      {collectionItemInfo?.collectionInfo.whitelists.length > 0 ? (
                        collectionItemInfo?.collectionInfo.useAndCondition ? (
                          <span>Please purchase all of the NFTs below first.</span>
                        ) : (
                          <span>Please purchase at least one of the NFTs below first. </span>
                        )
                      ) : null}
                    </div>
                    {collectionItemInfo?.collectionInfo.whitelists && (
                      <>
                        <div className="grid-list-nft">
                          {collectionItemInfo?.collectionInfo.whitelists &&
                            collectionItemInfo?.collectionInfo.whitelists
                              .filter((item: MBoxTypes, index: number) => (seeMore ? item : index < 3))
                              .map((item: MBoxTypes) => (
                                <div className="grid-item-nft" key={item.id}>
                                  <div className="image-nft">
                                    <img src={item.packageImage} alt={item.title.en} />
                                  </div>
                                  <div className="title-nft">{item.title.en}</div>
                                </div>
                              ))}
                        </div>
                        {collectionItemInfo?.collectionInfo.whitelists.length > 3 && (
                          <button className="see-more button" onClick={() => setSeeMore((cur) => !cur)}>
                            <div className="title-see-more">See more</div>
                            <div className="icon-see-more">
                              <img src={icon_seemore} alt="icon see more" />
                            </div>
                          </button>
                        )}
                      </>
                    )}
                  </>
                )}
              <div></div>
              <div>
                <div className="box-purchase-price">
                  <div className="lable-top">Purchase price</div>
                  <div className="lable-bottom fw-7">
                    {collectionItemInfo?.price === 0
                      ? 'FREE'
                      : getPrice(
                          collectionItemInfo?.collectionInfo?.fiatPrice || collectionItemInfo?.price || 0,
                          quote || collectionItemInfo?.quote?.toUpperCase() || 'null'
                        )}
                    {/* ?.replace(quote || collectionItemInfo?.quote?.toUpperCase() || 'null', '')} */}
                    {/* {collectionItemInfo?.price !== 0 && (
                      <span className="fw-400">
                        {quote === 'ethereum'
                          ? 'ETH'
                          : quote || collectionItemInfo?.quote === 'ethereum'
                          ? 'ETH'
                          : collectionItemInfo?.quote || ''}
                      </span>
                    )} */}
                  </div>
                </div>
                {!countDownFinish && (
                  <CountDownTimer
                    handeCheckCountDownFinish={handeCheckCountDownFinish}
                    targetDate={new Date(collectionItemInfo?.collectionInfo?.releaseDatetime)}
                  />
                )}
                {((account && library?.connection) || isAbcReady) && userEthAddr !== '' ? (
                  <>
                    {countDownFinish && freedropAble && (
                      <button
                        className={'btn-sale-collection'}
                        disabled={isLoading || remainingAmount === 0 || collectionItemInfo?.collectionInfo.isSoldOut}
                        onClick={() => setOpenPaymentWallets(true)}
                        // onClick={handleBuyClick}
                      >
                        {isLoading ? (
                          <CircularProgress size={30} color={'inherit'} />
                        ) : remainingAmount === 0 || collectionItemInfo?.collectionInfo.isSoldOut ? (
                          'Sold out'
                        ) : collectionItemInfo?.price === 0 ? (
                          'Get Now'
                        ) : (
                          'Buy Now'
                        )}
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    className={'btn-sale-collection'}
                    onClick={() => {
                      if (uid !== '') {
                        // setLoginOpen(true);
                        alert('Please activate Wallet.');
                        navigate('/profile');
                      } else {
                        navigate('/accounts');
                      }
                    }}
                  >
                    Buy Now
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <PaymentWallets
          itemInfo={collectionItemInfo!}
          isCollection={true}
          show={openPaymentWallets}
          onHide={() => setOpenPaymentWallets(false)}
          openPaymentWalletsSuccess={() => setOpenPaymentWalletsSuccess(true)}
        />
        <PaymentWalletsSuccess
          itemInfo={collectionItemInfo!}
          isCollection={true}
          show={openPaymentWalletsSuccess}
          onHide={handleClosePaymentWallet}
        />
        <Popup
          modal
          open={loginOpen}
          onOpen={closeSignup}
          onClose={closeLogin}
          {...{ overlayStyle, closeOnDocumentClick, lockScroll }}
        >
          {/* <LoginForm close={closeLogin} onConfirm={() => setSignupOpen(true)} /> */}
          <WalletConnector close={closeLogin} onConfirm={() => setSignupOpen(true)} />
        </Popup>
        <CSnackbar
          open={openSnackbar.open}
          type={openSnackbar.type}
          message={openSnackbar.message}
          handleClose={handleCloseSnackbar}
        />
      </div>
    </main>
  );
};

export default CollectionSaleDetail;
