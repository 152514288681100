import React from 'react';
import arrow_left_carousel from '../../assets/icon/arrow_left_carousel.png';
import arrow_right_carousel from '../../assets/icon/arrow_right_carousel.png';

const ArrowCarouselCollections = ({ totalItems, goToSlide, ...rest }: any) => {
  const {
    carouselState: { currentSlide, slidesToShow, deviceType },
  } = rest;
  return (
    <div className="arrow-featured-collections">
      <button
        className="button"
        onClick={() => goToSlide(currentSlide - slidesToShow)}
        disabled={
          totalItems < 6 && deviceType === 'desktop'
            ? true
            : totalItems < 5 && deviceType === 'laptopLarge'
            ? true
            : totalItems < 4 && deviceType === 'laptop'
            ? true
            : totalItems < 3 && deviceType === 'tablet'
            ? true
            : false
        }
      >
        <div className="arrow-one">
          <img src={arrow_left_carousel} alt="arrow left" />
        </div>
      </button>
      <button
        className="button"
        onClick={() => goToSlide(currentSlide + slidesToShow)}
        disabled={
          totalItems < 6 && deviceType === 'desktop'
            ? true
            : totalItems < 5 && deviceType === 'laptopLarge'
            ? true
            : totalItems < 4 && deviceType === 'laptop'
            ? true
            : totalItems < 3 && deviceType === 'tablet'
            ? true
            : false
        }
      >
        <div className="arrow-two">
          <img src={arrow_right_carousel} alt="arrow left" />
        </div>
      </button>
    </div>
  );
};

export default ArrowCarouselCollections;
