import { useEffect, useState, useMemo } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './talkclaim.css';
import Spinner from './spinner/spinner';
import talkenLogo from './talk.png';
import icon_ethereum from 'assets/img/icon_ethereum.png';
import icon_klaytn from 'assets/img/icon_klaytn.png';
import icon_polygon from 'assets/img/icon_polygon.png';
import { getRewardsClaim, getRewardsSent, requestRewardClaim } from '../services/services';
import icBannerReward from './ic_banner_reward.png';
import icQuestion1 from './ic_question_01.png';
import icQuestion2 from './ic_question_02.png';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import useInterval from './useInterval';

const isInterval = true;

const Reward = () => {
  const [uid, setUid] = useState(null);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rewardsArray, setRewardsArray] = useState([]);
  const [rewardsSentArray, setRewardsSentArray] = useState([]);
  const [openTime, setOpenTime] = useState('');
  const [closeTime, setCloseTime] = useState('');
  const [showCounter, setShowCounter] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [q1, setQ1] = useState(undefined);
  const [q2, setQ2] = useState(undefined);
  const [q1sent, setQ1sent] = useState(undefined);
  const [q2sent, setQ2sent] = useState(undefined);
  const navigate = useNavigate();
  const userAgent = navigator.userAgent;
  const isTalkenApp = userAgent.match(/Talken/i);

  const checkWalletIsConnected = async () => {
    const { ethereum } = window;
    if (!ethereum) {
      console.log('Make sure you have Metamask installed!');
      return;
    } else {
      console.log(`Wallet exists! We're ready to go!`);
    }
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
    // if (ethereum.networkVersion !== '1' && ethereum.networkVersion !== '0x1' && ethereum.networkVersion !== '0x01') return alert('Please change to Ethereum mainnet');
    if (accounts.length !== 0) {
      try {
        const account = accounts[0];
        setCurrentAccount(account);
      } catch (err) {
        console.log(err);
        alert(err);
      }
    } else {
      console.log('No authorized account found');
    }
  };

  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }
  const params = useQuery();
  const paramUid = params.get('uid');
  const setUidToLocalStorage = () => {
    if (paramUid) localStorage.setItem('talken.data', JSON.stringify({ uid: paramUid }));
    navigate('/Reward');
  };
  const setUidFromLocalStorage = () => {
    const lsUid = JSON.parse(localStorage.getItem('talken.data'))?.uid;
    if (lsUid) setUid(lsUid);
  };

  useEffect(() => {
    setUidToLocalStorage();
    setUidFromLocalStorage();
    checkWalletIsConnected();
    timeCheck();
  }, []);

  useEffect(() => {
    checkWalletIsConnected();
  }, [currentAccount]);

  useEffect(() => {
    fetchRewardClaim();
    fetchRewardSent();
  }, [uid]);

  useEffect(() => {
    if (!isTalkenApp) {
      alert('Please use Talken App.');
      location.replace('/');
    }
  }, []);

  const fetchRewardClaim = async () => {
    try {
      if (uid) {
        const res = await getRewardsClaim(uid);
        const resRewards = res?.data?.data;
        if (resRewards) {
          setRewardsArray(resRewards);
          // console.log('fetchRewardClaim:::', resRewards);
          const q1 = resRewards.find((i) => i.type === 'quest1');
          const q2 = resRewards.find((i) => i.type === 'quest2');
          setQ1(q1);
          setQ2(q2);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchRewardSent = async () => {
    try {
      if (uid) {
        const res = await getRewardsSent(uid);
        const resRewards = res?.data?.data;
        if (resRewards) {
          setRewardsSentArray(resRewards);
          // console.log('fetchRewardSent:::', resRewards);
          const q1 = resRewards.find((i) => i.type === 'quest1');
          const q2 = resRewards.find((i) => i.type === 'quest2');
          setQ1sent(q1);
          setQ2sent(q2);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const connectWalletHandler = async () => {
    const { ethereum } = window;
    if (!ethereum) alert('Please use Talken web3 browser!');

    try {
      const accounts = await ethereum.request({
        method: 'eth_requestAccounts',
      });
      // if (ethereum.networkVersion !== '1' && ethereum.networkVersion !== '0x1' && ethereum.networkVersion !== '0x01') return alert('Please change to Ethereum mainnet');
      setCurrentAccount(accounts[0]);
    } catch (err) {
      console.log(err);
    }
  };

  const connectWalletButton = () => {
    return (
      <Button onClick={connectWalletHandler} variant="primary" className="button-claim">
        Connect Wallet
      </Button>
    );
  };

  const signMessage = async (reward) => {
    const { ethereum } = window;
    if (!ethereum || !uid) alert('Please use Talken web3 browser!');
    try {
      setLoading(true);
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      if (accounts.length === 0) return console.log('No authorized account found');
      const from = accounts[0];
      const message = `You need to sign with an Ethereum account to use Talken's 'Claim Rewards' service. Talken's 'Claim Rewards' service Signature Request Type: Signature`;
      const msg = `0x${Buffer.from(message, 'utf8').toString('hex')}`;
      const signature = await ethereum.request({
        method: 'personal_sign',
        params: [msg, from, 'Random text'],
      });
      const data = {
        id: reward.id,
        signature,
        uid,
      };
      // console.log('signature : ' + signature);
      const result = await requestRewardClaim(data);
      // console.log('result:::', result);
      if (result.data.receipt) {
        alert('Request claim success!\nPlease check Block Explorer');
        fetchRewardClaim();
        fetchRewardSent();
      }
    } catch (err) {
      console.error(err);
    } finally {
      fetchRewardClaim();
      fetchRewardSent();
      setLoading(false);
    }
  };
  useInterval(
    () => {
      timeCheck();
    },
    isInterval ? 1000 : null
  );

  useEffect(() => {
    const now = new Date();
    const _openTime = new Date(openTime).getTime();
    const _closeTime = new Date(closeTime).getTime();
    const timeLimit = _openTime > now ? _openTime : _closeTime;
    const poll = () => {
      const countDown = timeLimit - new Date().getTime();
      const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
      const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) + days * 24;
      const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));

      const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
      if (hours + minutes + seconds >= 0) {
        setShowCounter({
          ...showCounter,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        });
      }
    };
    poll();
    const interval = setInterval(poll, 1000);
    return () => clearInterval(interval);
  }, [openTime]);
  const timeCheck = () => {
    const questClaimOpen = '2023/04/13 12:00:00';
    const questClaimClose = '2023/05/12 12:00:00';

    setOpenTime(questClaimOpen);
    setCloseTime(questClaimClose);
  };
  // const claimButtons = () => {
  //   // console.log('claimButtons:::', rewardsArray);
  //   // console.log(rewardsArray.length);
  //   const buttons =
  //     rewardsArray?.length > 0 ? (
  //       rewardsArray.map((reward) => (
  //         <button
  //           key={reward.id}
  //           onClick={() => signMessage(reward)}
  //           disabled={loading}
  //           className={'cta-button apply-button reward-button'}
  //         >
  //           <div>
  //             <img
  //               src={
  //                 reward.platform === 'ETHEREUM'
  //                   ? icon_ethereum
  //                   : reward.platform === 'POLYGON'
  //                   ? icon_polygon
  //                   : reward.platform === 'KLAYTN'
  //                   ? icon_klaytn
  //                   : null
  //               }
  //               style={{
  //                 width: '24px',
  //                 height: '24px',
  //                 verticalAlign: 'middle',
  //               }}
  //               alt=""
  //             />
  //           </div>
  //           <div>{reward.symbol}</div>
  //           <div>{reward.amount}</div>
  //         </button>
  //       ))
  //     ) : (
  //       <div>클레임 가능한 리워드가 없습니다.</div>
  //     );
  //   return buttons;
  // };

  // const rewardView = (
  //   <>
  //     <div className="apply-container">
  //       <img src={talkenLogo} className="logo-image" />
  //       {loading ? <Spinner /> : null}
  //       <h1>리워드 안내</h1>
  //       <div className="apply-text-div">
  //         <ul>
  //           <li>설명설명설명1...</li>
  //           <li>설명설명설명2...</li>
  //           <li>설명설명설명3...</li>
  //         </ul>
  //       </div>
  //       <div className="apply-button-div">
  //         <div className="reward-buttons-div">
  //           {currentAccount ? claimButtons() : connectWalletButton()}
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );

  // const isReady =
  //   showCounter.hours === 0 &&
  //   showCounter.minutes === 0 &&
  //   showCounter.seconds === 0;
  const _openTime = new Date(openTime);
  const _closeTime = new Date(closeTime);
  const now = new Date();
  const isEnd = _closeTime < now;
  const isReady = _openTime < now && !isEnd;
  const claimTimeLimitText = _openTime < now ? 'Claims end May 12' : 'Claims begin APR 13';

  return (
    <div className="reward-page">
      <div className="title-claim-rewards">Claim Rewards</div>
      <div className="banner-reward">
        <div className="text-banner-reward">Claim rewards for Talken quest you’ve participated in</div>
        <img src={icBannerReward} alt="banner" />
      </div>
      <div className="title-rewards">
        <span>Rewards</span>
        <span className="uid-text">UID : {uid}</span>
      </div>
      <div className="wrapper-question">
        <div className="content-wrapper-question">
          <div className="ic_question">
            <img src={icQuestion1} alt="icon question" />
          </div>
          <div className="content-right-question">
            <div className="title-question">Sign up Talken app and follow SNS</div>
            {/* <div className="text-mission">Sign up Talken app and follow SNS</div> */}
            <div className="content-question-bottom">
              <div className="date-claim-start">
                <div className="text-claim-start">{claimTimeLimitText}</div>
              </div>
              <div className="time-counter-quest">
                <div>
                  {`${showCounter.hours > 9 ? showCounter.hours : '0' + showCounter.hours}`}
                  <p className="time-counter-dhm">H</p>
                </div>
                <span>:</span>
                <div>
                  {`${showCounter.minutes > 9 ? showCounter.minutes : '0' + showCounter.minutes}`}
                  <p className="time-counter-dhm">M</p>
                </div>
                <span>:</span>
                <div>
                  {`${showCounter.seconds > 9 ? showCounter.seconds : '0' + showCounter.seconds}`}
                  <p className="time-counter-dhm">S</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {currentAccount ? (
          <Button
            variant="primary"
            className="button-claim"
            disabled={
              loading ||
              !isReady || // openTime OK
              (!q1 && !q1sent) || // DB에 없는경우
              q1sent?.tx === 'PENDING'
            }
            onClick={() => {
              if (q1) {
                signMessage(q1);
              } else if (q1sent?.tx !== 'PENDING') {
                window.open(`https://polygonscan.com/tx/${q1sent?.tx}`, '_blank');
              }
            }}
          >
            {loading
              ? 'PLEASE WAIT'
              : isEnd
              ? 'Claim Period Ended'
              : !isReady
              ? 'COMING SOON'
              : q1 // tx is null
              ? `CLAIM ${q1.amount} ${q1.symbol}`
              : q1sent && q1sent?.tx !== 'PENDING' // has tx, and is not null
              ? 'GO TO TRNASACTION'
              : q1sent?.tx === 'PENDING' // tx is 'PENDING'
              ? 'PENDING'
              : 'NO CLAIMABLE REWARD'}
          </Button>
        ) : (
          connectWalletButton()
        )}
      </div>
      <div className="wrapper-question">
        <div className="content-wrapper-question">
          <div className="ic_question">
            <img src={icQuestion2} alt="icon question" />
          </div>
          <div className="content-right-question">
            <div className="title-question">Creating ABC wallet in Talken</div>
            {/* <div className="text-mission">Creating ABC wallet in Talken</div> */}
            <div className="content-question-bottom">
              <div className="date-claim-start">
                <div className="text-claim-start">{claimTimeLimitText}</div>
              </div>
              <div className="time-counter-quest">
                <div>
                  {`${showCounter.hours > 9 ? showCounter.hours : '0' + showCounter.hours}`}
                  <p className="time-counter-dhm">H</p>
                </div>
                <span>:</span>
                <div>
                  {`${showCounter.minutes > 9 ? showCounter.minutes : '0' + showCounter.minutes}`}
                  <p className="time-counter-dhm">M</p>
                </div>
                <span>:</span>
                <div>
                  {`${showCounter.seconds > 9 ? showCounter.seconds : '0' + showCounter.seconds}`}
                  <p className="time-counter-dhm">S</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {currentAccount ? (
          <Button
            variant="primary"
            className="button-claim"
            disabled={
              loading ||
              !isReady || // openTime OK
              (!q2 && !q2sent) || // DB에 없는경우
              q2sent?.tx === 'PENDING'
            }
            onClick={() => {
              if (q2) {
                signMessage(q2);
              } else if (q2sent?.tx !== 'PENDING') {
                window.open(`https://www.klaytnfinder.io/tx/${q2sent?.tx}`, '_blank');
              }
            }}
          >
            {loading
              ? 'PLEASE WAIT'
              : isEnd
              ? 'Claim Period Ended'
              : !isReady
              ? 'COMING SOON'
              : q2 // tx is null
              ? `CLAIM ${q2.amount} ${q2.symbol}`
              : q2sent && q2sent?.tx !== 'PENDING' // has tx, and is not null
              ? 'GO TO TRNASACTION'
              : q2sent?.tx === 'PENDING' // tx is 'PENDING'
              ? 'PENDING'
              : 'NO CLAIMABLE REWARD'}
          </Button>
        ) : (
          connectWalletButton()
        )}
      </div>
      {loading ? <Spinner /> : null}
    </div>
  );
};

export default Reward;
