import React, { useEffect, useState } from 'react';
import { FeaturedTypes } from '../../types/FeaturedTypes';
import { getFeaturedCollectionsInfi } from '../../services/services';
import FeaturedCard from '../../components/card/FeaturedCard';
import SearchBar from 'components/searchbar/SearchBar';
import { useMediaQuery } from 'react-responsive';
import InfiniteScroll from 'react-infinite-scroll-component';
const Collections = () => {
  const [featuredCollections, setFeaturedCollections] = useState<FeaturedTypes[]>([]);
  const [keyword, setKeyword] = useState('');
  const [sortBy, setSortBy] = useState('createdAt:DESC');
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const selectList = [
    // { value: 'dropsOpenDatetime:DESC', label: 'Recently listed' },
    { value: 'createdAt:DESC', label: 'Recently listed' },
  ];
  const categoryList = [
    { value: '1', label: 'Category1' },
    { value: '2', label: 'Category2' },
  ];

  const isMobile = useMediaQuery({
    query: '(max-width: 640px)',
  });
  //featured
  const fetchFeaturedCollections = async (keyword?: string, sortBy?: string) => {
    const res = await getFeaturedCollectionsInfi({ page, keyword, sortBy });
    if (res.data.status === 1) {
      setFeaturedCollections(res?.data?.data?.list || null);
      setTotalRows(res?.data?.data?.headers?.x_pages_count || 0);
    } else {
      setFeaturedCollections([]);
      setTotalRows(res?.data?.data?.headers?.x_pages_count || 0);
    }
  };

  useEffect(() => {
    fetchFeaturedCollections(keyword, sortBy);
  }, [keyword, sortBy]);
  const fetchFeaturedCollectionsScroll = async (page) => {
    setPage(page);
    const res = await getFeaturedCollectionsInfi({ isCollection: true, keyword, sortBy, page });
    if (res.data.status === 1) {
      setFeaturedCollections([...featuredCollections, ...res?.data?.data?.list]);
      setTotalRows(res?.data?.data?.headers?.x_pages_count || 0);
    }
  };
  return (
    <div className="featured-page min-height-content">
      <div className="section-02">
        {/* Featured Collections */}
        <div className="featured-collections">
          <div className="wrapper-header title-header">
            {/* <div className="header-name pb-2">Creators</div> */}
            {/*{isMobile && <div className="header-describe">Creators description.</div>}*/}
          </div>
          <div className="p-l-xs-4 p-r-12 p-r-xs-8">
            <SearchBar
              titlePage="Creators"
              onSearch={(search: string) => {
                setPage(1);
                setKeyword(search?.trim());
              }}
              onSelect={(value: string) => {
                setPage(1);
                setSortBy(value);
              }}
              onSelectCategoryAll={() => {
                console.log('all');
              }}
              onSelectCategory={(value: string) => {
                console.log(value);
              }}
              selectList={selectList}
              categoryList={categoryList}
              showFirstTwoSelects={false}
              totalItems={featuredCollections?.length}
            ></SearchBar>
          </div>
          <InfiniteScroll
            className="grid-container-featured w-100 d-grid grid-col-5 grid-col-xs-2 over-flow-x-hidden"
            dataLength={featuredCollections.length}
            next={() => fetchFeaturedCollectionsScroll(page + 1)}
            hasMore={page < totalRows}
            loader={<h4>Loading...</h4>}
          >
            {featuredCollections.map((item: FeaturedTypes, index) => (
              <FeaturedCard key={item.id} item={item} page={'creator'} />
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default Collections;
