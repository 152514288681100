import { ChainId } from '../config';

export const getNetworkNameById = (id: number) => {
  const name = Object.keys(ChainId)[Object.values(ChainId).indexOf(id)];
  if (name === 'BSCMAINNET' || name === 'BSCTESTNET') return 'BNB Chain';
  if (name === 'MAINNET' || name === 'SEPOLIA') return 'Ethereum';
  if (name === 'KLAYTN' || name === 'BAOBAB') return 'Klaytn';
  if (name === 'POLYGON' || name === 'AMOY') return 'Polygon';
  return name;
};
