import React from 'react';
// import { Link } from 'react-router-dom';
import { getPrice } from 'utils/getPrice';
import { MBoxItemTypes } from '../../types/MBoxItemTypes';
import { getNetworkNameById } from '../../utils/getNetworkNameById';
import { MBoxTypes } from '../../types/MBoxTypes';
import { getPriceRecentlyListedItems } from 'utils/getPriceRecentlyListedItems';
import { HandleMediaError } from 'components/common/HandleMediaError';

type MBoxItemCardProps = {
  item: MBoxItemTypes;
  chainId: number;
  mBoxInfo: MBoxTypes;
  mBoxName: string;
  mBoxImage: string;
  quote: string | null;
  price: number | null;
};
const MBoxItemCard: React.FC<MBoxItemCardProps> = ({
  item,
  chainId,
  // mBoxName,
  mBoxInfo,
  // mBoxImage,
  quote,
  price,
}) => {
  return (
    <div className="slide-item">
      {/*<Link to={``} className="button">*/}
      <div className="hot-ollectibles-wrapper">
        <div className="header-left hot-ollectibles-item">
          <span className="total-run">Total Items: {item.issueAmount}</span>
          <div className="chain-name">{getNetworkNameById(chainId)}</div>
        </div>
        {/* <div className="hot-ollectibles-item" style={{ textTransform: 'capitalize' }}>
          <div>{getNetworkNameById(chainId)?.toLowerCase()}</div>
        </div> */}
        <div className="hot-ollectibles-item">
          <div className="img-token">
            {item?.originalImage?.split('.')?.pop() === 'mp4' ? (
              <video
                playsInline
                autoPlay
                controls
                muted
                loop
                controlsList="nodownload"
                width={'100%'}
                onError={HandleMediaError}
                key={item.originalImage
                  ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                  .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}
              >
                <source
                  src={item.originalImage
                    ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                    .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}
                  type="video/mp4"
                />
              </video>
            ) : (
              <img src={item.itemImage} alt="" onError={HandleMediaError} />
            )}
            <div className="position-absolute price-and-remaining">
              <div className="wrapper-price text-center">
                <div className="price-header color-aaa fs-12 fs-xs-10">Price</div>
                {/* <div className="current-price fs-14 fs-xs-12 fw-7">{getPrice(Number(price), quote!)}</div> */}
                <div className="fs-14 fw-7">
                  {getPriceRecentlyListedItems(price)}
                  {price !== null && <span className="fs-14 fs-xs-10 m-l-4">{quote!}</span>}
                </div>
              </div>
              <div className="wrapper-remaining text-center">
                <div className="remaining-header color-aaa fs-12 fs-xs-10">Remaining</div>
                <div className={`quantity-remaining fs-14 fs-xs-12 fw-7`}>{item.remainingAmount}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="hot-ollectibles-item">
          <div className="wrapper-item">
            <div className="content-left">
              <div className="avatar">
                <img src={mBoxInfo.featured?.company.image} alt="" />
              </div>
              <div className="name-label">{mBoxInfo.featured?.company.name.en}</div>
            </div>
            {/* <div className="content-right">Buy Now</div> */}
          </div>
        </div>
        <div className="hot-ollectibles-item">
          <div className="name-label">{item.name}</div>
        </div>
        {/* <div className="hot-ollectibles-item">
          <div className="wrapper-price">
            <div className="price-header font-size-14">Price</div>
            <div className="current-price font-size-18">{getPrice(Number(price), quote!)}</div>
          </div>
        </div>
        <div className="hot-ollectibles-item">
          <div className="wrapper-remaining">
            <div className="remaining-header font-size-14">Remaining </div>
            <div className="quantity-remaining font-size-18">{item.remainingAmount!}</div>
          </div>
        </div> */}
      </div>
      {/*</Link>*/}
    </div>
  );
};

export default MBoxItemCard;
