import { useEffect, useRef, useState } from 'react';
import './admp.css';
import { ethers, ContractFactory } from 'ethers';
import { ChainId, NETWORK_NAME, SCAN_URL } from '../config';
import { BigNumber as eBigNumber } from '@ethersproject/bignumber';
import ERC721 from './ERC721.json';
import KIP17 from './KIP17.json';
import { ReactComponent as PasteIcon } from 'assets/icon/paste_admin.svg';
import { ReactComponent as TokenURIIcon } from 'assets/icon/icon_tooltip_adm.svg';
import ReactTooltip from 'react-tooltip';
import useCopyToClipBoard from 'hooks/useCopyToClipboard';
import CSnackbar from 'components/common/CSnackbar';
import Deploy from '../components/modal/Deploy';
import MintErc from '../components/modal/MintErc';
import klaytnIcon from 'assets/svg/klaytnFaucet.svg';
import polygon from 'assets/icon/polygon.svg';
import eth from 'assets/icon/eth.svg';
import crmc from 'assets/svg/icon_crmc.svg';
import binance from 'assets/img/icon_binance.png';

let provider, signer, chainId, abi, bytecode;

function Admp() {
  const [currentAccount, setCurrentAccount] = useState(null);
  const [inputs, setInputs] = useState({ name: '', symbol: '', contractAddress: '', to: '', uri: '' });
  const { name, symbol, contractAddress, to, uri } = inputs;
  const onChange = (e) => {
    const { value, name } = e.target;
    setInputs({ ...inputs, [name]: value });
  };
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    type: '',
    message: '',
  });
  const [canScroll, setCanScroll] = useState(true);
  const [currentNetwork, setCurrentNetwork] = useState(null);
  const [currentIcon, setCurrentIcon] = useState(crmc);
  const [currentTokenId, setCurrentTokenId] = useState(0);
  const [currentChainId, setCurrentChainId] = useState(0);
  const [isOpenDeploy, setOpenDeploy] = useState(false);
  const [isOpenMintErc, setOpenMintErc] = useState(false);
  const detectNetwork = async () => {
    if (window.ethereum) {
      // Metamask가 설치되어 있고 사용 가능한 경우
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      try {
        // 현재 네트워크 정보 가져오기
        const network = await provider.getNetwork();
        // 네트워크 이름 설정
        let networkName = NETWORK_NAME[network.chainId];
        let icon;
        switch (network.chainId) {
          case ChainId.MAINNET:
            icon = eth;
            break;
          case ChainId.GOERLI:
            icon = eth;
            break;
          case ChainId.SEPOLIA:
            icon = eth;
            break;
          case ChainId.BSCMAINNET:
            icon = binance;
            break;
          case ChainId.BSCTESTNET:
            icon = binance;
            break;
          case ChainId.KLAYTN:
            icon = klaytnIcon;
            break;
          case ChainId.BAOBAB:
            icon = klaytnIcon;
            break;
          case ChainId.POLYGON:
            icon = polygon;
            break;
          case ChainId.MUMBAI:
            icon = polygon;
            break;
          case ChainId.AMOY:
            icon = polygon;
            break;
          default:
            networkName = 'Unknown Network';
            icon = crmc;
        }
        setCurrentNetwork(networkName);
        setCurrentIcon(icon);
        setCurrentChainId(network.chainId);
      } catch (error) {
        console.error('Error fetching network information:', error);
      }
    } else {
      console.warn('Metamask를 찾을 수 없습니다. Metamask를 설치하세요.');
    }
  };
  useEffect(() => {
    detectNetwork();
  }, [isOpenDeploy, isOpenMintErc]);

  useEffect(() => {
    // 컴포넌트가 마운트되었을 때 실행되는 부분
    const checkingNetwork = setInterval(() => {
      if (currentAccount) detectNetwork();
    }, 1000);

    // 컴포넌트가 언마운트되거나 업데이트되기 전에 clearInterval을 호출하여 setInterval 정리
    return () => clearInterval(checkingNetwork);
  }, [currentAccount]);

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth, innerHeight } = window;
      const isFullHDScreen = innerWidth >= 1920 && innerHeight >= 1080;
      setCanScroll(!isFullHDScreen);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const messagesEndRef = useRef(null);

  // Scroll to the most recent message whenever messages are updated
  useEffect(() => {
    if (message.length > 0) {
      const lastMessageRef = messagesEndRef?.current;
      lastMessageRef?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [message]);
  // useEffect(() => {
  //   connectWalletHandler();
  // }, []);

  const connectWalletHandler = () => {
    const task = async () => {
      const accounts = await provider.send('eth_requestAccounts', []);
      if (accounts.length !== 0) {
        try {
          const account = accounts[0];
          console.log('Found an authorized account: ', account);
          setCurrentAccount(account);
        } catch (err) {
          console.log(err.message);
        }
      } else {
        console.log('No authorized account found');
      }
    };
    excute(task);
  };

  const excute = async (task) => {
    try {
      setLoading(true);
      const { ethereum } = window;
      if (!ethereum) return console.log('Ethereum object does not exist');
      provider = new ethers.providers.Web3Provider(ethereum);
      signer = provider.getSigner();
      chainId = (await provider.getNetwork())?.chainId;
      const isKlaytn = chainId === 1001 || chainId === 8217;
      abi = isKlaytn ? KIP17.abi : ERC721.abi;
      bytecode = isKlaytn ? KIP17.bytecode : ERC721.bytecode;
      await task();
    } catch (err) {
      console.log(err.message);
      setMessage([...message, err.message]);
      // alert(err.message);
    } finally {
      setLoading(false);
    }
  };

  const confirmDeploy = () => {
    const task = async () => {
      const factory = new ContractFactory(abi, bytecode, signer);
      const contract = await factory.deploy(name, symbol);
      // 배포완료
      const deployResult = await contract.deployTransaction.wait();
      setMessage([...message, `Deployed contractAddress: ${deployResult.contractAddress}`]);
      console.log(`Mined, hash: ${deployResult.transactionHash}`);
      setInputs({ ...inputs, ['contractAddress']: deployResult.contractAddress });
    };
    excute(task);
  };

  const confirmMintErc = () => {
    const task = async () => {
      const contract = new ethers.Contract(contractAddress, abi, signer);
      const safeMint = await contract.safeMint(to, uri);
      setMessage([...message, `safeMint hash: ${safeMint.hash}`]);
      console.log(`Mined, hash: ${safeMint.hash}`);
      const safeMintResult = await safeMint.wait();
      console.log('safeMintResult:::', safeMintResult);
    };
    excute(task);
  };

  const deployContractButton = () => {
    return (
      <button
        onClick={() => {
          if (!name || !symbol) {
            setMessage([...message, 'Error(DEPLOY): Please check the contract name or symbol.']);
            return;
          }
          setOpenDeploy(true);
        }}
        disabled={loading}
        className="button-adm w-100 border-radius-8"
      >
        {loading ? 'Processing' : 'Deploy ERC-721 contract'}
      </button>
    );
  };
  const mintButton = () => {
    return (
      <button
        onClick={() => {
          const isContractAddress = ethers.utils.isAddress(contractAddress);
          const isToAddress = ethers.utils.isAddress(to);
          if (!contractAddress || !uri || !to) {
            setMessage([...message, 'Error(MINT): Please check the contract address, Token URI or To Address.']);
            return;
          }
          if (!isContractAddress) return setMessage([...message, 'Invalid Contract Address.']);
          if (!isToAddress) return setMessage([...message, 'Invalid To Address.']);
          setOpenMintErc(true);
        }}
        disabled={loading}
        className="button-adm w-100 border-radius-8"
      >
        {loading ? 'Processing' : 'Mint ERC-721 NFT'}
      </button>
    );
  };
  const getCurrentIdButton = () => {
    return (
      <button
        onClick={() => {
          const task = async () => {
            const contract = new ethers.Contract(contractAddress, abi, signer);
            const getCurrentId = await contract.getCurrentId();
            setMessage([...message, `${contractAddress} Current TokenId: ${getCurrentId}`]);
            setCurrentTokenId(getCurrentId.toString());
          };
          excute(task);
        }}
        disabled={loading}
        className="btn-paste min-w-160px"
      >
        {loading ? 'Processing' : 'Get Current TokenID'}
      </button>
    );
  };
  const { copyToClipBoard, copyResult, setCopyResult } = useCopyToClipBoard();

  const handleCloseSnackbar = () => {
    setOpenSnackbar({
      open: false,
      type: '',
      message: '',
    });
  };
  useEffect(() => {
    setOpenSnackbar({
      open: copyResult,
      type: 'success',
      message: 'Copied!',
    });
  }, [copyResult]);
  const handlePaste = async (name) => {
    try {
      const clipboardData = await navigator.clipboard.readText();
      setInputs((prevState) => ({
        ...prevState,
        [name]: clipboardData,
      }));
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err);
    }
  };
  const renderMessageWithLink = (message) => {
    // "safeMint hash:" 또는 "Deployed contractAddress:"로 시작하는 부분을 찾기 위한 정규식
    const safeMintHashRegex = /^(safeMint hash:|Deployed contractAddress:)\s*(0x[a-fA-F0-9]+)/;
    // 정규식을 사용하여 메시지를 분석
    const match = message.match(safeMintHashRegex);
    if (!match) {
      // 정규식에 맞지 않는 형식의 메시지는 그대로 반환
      return message;
    }
    // 매칭된 부분을 기반으로 링크를 생성
    const prefix = match[1]; // "safeMint hash:" 또는 "Deployed contractAddress:"
    const address = match[2]; // Ethereum 주소
    let link;
    if (prefix === 'safeMint hash:') {
      // "safeMint hash:"로 시작하는 경우 다른 링크 생성
      link = (
        <a href={`${SCAN_URL[currentChainId]}tx/${address}`} target="_blank" rel="noopener noreferrer">
          {address}
        </a>
      );
    } else {
      // "Deployed contractAddress:"로 시작하는 경우 scan 링크 생성
      link = (
        <a
          href={`${SCAN_URL[currentChainId]}${
            currentChainId === ChainId.KLAYTN || currentChainId === ChainId.BAOBAB ? 'account' : 'address'
          }/${address}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {address}
        </a>
      );
    }
    // 링크가 삽입된 문자열을 반환
    return (
      <>
        {prefix} {link}
      </>
    );
  };
  return (
    <div className={`${currentAccount ? 'main-app' : 'wallet-not-connect'}`}>
      {!currentAccount ? (
        <button onClick={connectWalletHandler} className="cta-button connect-wallet-button">
          Connect Wallet
        </button>
      ) : (
        <div className="wrapper-adm-page">
          {/* <h1>NFT Admin Page</h1>
          <br />
          <div>{message}</div>
          <br />
          <br />
          <input name="name" class="input-box" placeholder="Name" onChange={onChange} value={name} />
          <input name="symbol" class="input-box" placeholder="Symbol" onChange={onChange} value={symbol} />
          <div class="button-div">{deployContractButton()}</div>
          <br />
          <br />
          <div>Contract Address</div>
          <input
            name="contractAddress"
            class="input-box"
            placeholder="Contract Address"
            onChange={onChange}
            value={contractAddress}
          />
          <br />
          <br />
          <input name="to" class="input-box" placeholder="To" onChange={onChange} value={to} />
          <input name="uri" class="input-box" placeholder="Uri" onChange={onChange} value={uri} />
          <div class="button-div">{mintButton()}</div>
          <br />
          <div class="button-div">{getCurrentIdButton()}</div>
          <br />
          <br /> */}
          <div className="wrapper-header-adm">
            NFT contract(ERC-721) deploy & minting tool
            <div className="fs-14 fw-600 lh-12 d-flex align-items-center color-777">
              <img width={20} height={20} src={currentIcon} className="m-r-8" alt="icon-header-adm" />
              {currentNetwork}
            </div>
          </div>
          <div className="d-flex gap-10px">
            <div className="wrapper-content-adm deploy-adm">
              {/* contract name  */}
              <div className="wrapper-input-adm">
                <div className="title-input-adm">Contract name (Collection name)</div>
                <div className="d-flex gap-8px w-100">
                  <input className="custom-input-adm" name="name" onChange={onChange} value={name} />
                  <button className="btn-paste min-w-50px" onClick={() => handlePaste('name')}>
                    <PasteIcon />
                  </button>
                </div>
                <div className="validation-adm">
                  Only alphabets, numbers, spaces, and special character(-) are allowed.
                </div>
              </div>
              {/* contract symbol  */}
              <div className="wrapper-input-adm">
                <div className="title-input-adm">Contract symbol</div>
                <div className="d-flex gap-8px w-100">
                  <input className="custom-input-adm" name="symbol" onChange={onChange} value={symbol} />
                  <button className="btn-paste min-w-50px" onClick={() => handlePaste('symbol')}>
                    <PasteIcon />
                  </button>
                </div>
                <div className="validation-adm">
                  The symbol is usually a combination of 3 to 5 letters of the alphabet and numbers.
                </div>
              </div>
              {/* button deploy  */}
              {/* <button className="button-adm w-100 border-radius-8" onClick={deployContractButton}>
                Deploy ERC-721 contract
              </button> */}
              <>
                {deployContractButton()}
                {isOpenDeploy && (
                  <Deploy
                    isOpen={isOpenDeploy}
                    onClose={() => setOpenDeploy(false)}
                    onConfirm={confirmDeploy}
                    currentNetwork={currentNetwork}
                    currentIcon={currentIcon}
                    name={name}
                    symbol={symbol}
                  />
                )}
              </>
              <div className="line-adm m-t-16 m-b-16"></div>
              {/* contract address  */}
              <div className="wrapper-input-adm">
                <div className="title-input-adm">Contract address</div>
                <div className="d-flex gap-8px w-100">
                  <input
                    className="custom-input-adm"
                    name="contractAddress"
                    onChange={onChange}
                    value={contractAddress}
                  />
                  <>{getCurrentIdButton()}</>
                  <button className="btn-paste min-w-50px" onClick={() => handlePaste('contractAddress')}>
                    <PasteIcon />
                  </button>
                </div>
                <div className="validation-adm">Only contracts previously deployed using this tool are valid.</div>
              </div>
              {/* Token url  */}
              <div className="wrapper-input-adm">
                <div className="flex-start gap-4px">
                  <div className="title-input-adm">Token URI</div>
                  <div>
                    <TokenURIIcon
                      data-tip={`The tokenURI function in your ERC721 or the uri function in your ERC1155 contract should return an HTTP or IPFS URL. When queried, this URL should return a JSON blob of data with the metadata for your token.`}
                    />
                  </div>

                  <ReactTooltip className="tooltip-token-uri" place="top" type="dark" effect="solid" multiline={true} />
                </div>
                <div className="d-flex gap-8px w-100">
                  <input className="custom-input-adm" name="uri" onChange={onChange} value={uri} />
                  <button className="btn-paste min-w-50px" onClick={() => handlePaste('uri')}>
                    <PasteIcon />
                  </button>
                </div>
              </div>
              {/* To address  */}
              <div className="wrapper-input-adm">
                <div className="title-input-adm">To Address</div>
                <div className="d-flex gap-8px w-100">
                  <input className="custom-input-adm" name="to" onChange={onChange} value={to} />
                  <button className="btn-paste min-w-50px" onClick={() => handlePaste('to')}>
                    <PasteIcon />
                  </button>
                </div>
              </div>
              {/* button mint  */}
              <>
                {mintButton()}
                {isOpenMintErc && (
                  <MintErc
                    isOpen={isOpenMintErc}
                    currentNetwork={currentNetwork}
                    currentIcon={currentIcon}
                    currentTokenId={currentTokenId}
                    onClose={() => setOpenMintErc(false)}
                    onConfirm={confirmMintErc}
                    contractAddress={contractAddress}
                    uri={uri}
                    to={to}
                  />
                )}
              </>
            </div>
            <div className="wrapper-content-adm console-adm">
              <div className="header-console">
                <div>Console</div>
                <div className="flex-between gap-10px">
                  <button className="btn-paste min-w-80px h-40px" onClick={() => copyToClipBoard(message)}>
                    Copy
                  </button>
                  <button className="btn-paste min-w-80px h-40px" onClick={() => setMessage([])}>
                    Clear
                  </button>
                </div>
              </div>
              <div className="line-adm"></div>
              <div className="terminal-scroll-adm">
                <div className="terminal-adm">
                  {message.length > 0 &&
                    message?.map((line, index) => (
                      <div className="terminal-text" key={index}>
                        {renderMessageWithLink(line, 'contractAddress')}
                      </div>
                    ))}
                </div>
                <div ref={messagesEndRef} />
              </div>
            </div>
          </div>
          <CSnackbar
            open={openSnackbar.open}
            type={openSnackbar.type}
            message={openSnackbar.message}
            handleClose={handleCloseSnackbar}
          />
        </div>
      )}
    </div>
  );
}

export default Admp;
