import ReactModal from 'react-modal';
import { ReactComponent as IconClose } from 'assets/icon/close_icon.svg';
import { ReactComponent as IconLinkLightMode } from 'assets/icon/link.svg';
import { ReactComponent as IconLinkDarkMode } from 'assets/icon/linkDarkMode.svg';
import { ReactComponent as IconSuccess } from 'assets/svg/confetti.svg';
import { useOpenTransferItemSuccess } from 'components/common/AppStore';
import { useThemeMode } from '../common/AppStoreType';
type TransferItemSuccess = {
  selected: any;
  setReload?: any;
  dataItemTransfer?: any;
};
const TransferItemSuccess: React.FC<TransferItemSuccess> = ({ selected, setReload, dataItemTransfer }) => {
  const { isOpenTransferItemSuccess } = useOpenTransferItemSuccess();
  const { isDarkMode } = useThemeMode();

  const handleGetOutLink = () => {
    let url = '';
    let prefix = '';
    if (selected?.chain === 'klaytn') {
      prefix = process.env.REACT_APP_PHASE === 'production' ? 'www' : 'baobab';
      url = `https://${prefix}.klaytnfinder.io/tx/${dataItemTransfer?.txid}`;
    } else if (selected?.chain === 'ethereum') {
      prefix = process.env.REACT_APP_PHASE === 'production' ? 'www' : 'sepolia';
      url = `https://${prefix}.etherscan.io/tx/${dataItemTransfer?.txid}`;
    } else if (selected?.chain === 'binance') {
      prefix = process.env.REACT_APP_PHASE === 'production' ? 'www' : 'testnet';
      url = `https://${prefix}.bscscan.com/tx/${dataItemTransfer?.txid}`;
    } else if (selected?.chain === 'polygon') {
      prefix = process.env.REACT_APP_PHASE === 'production' ? 'www' : 'amoy';
      url = `https://${prefix}.polygonscan.com/tx/${dataItemTransfer?.txid}`;
    }
    window.open(url, '_blank');
  };
  return (
    <ReactModal
      isOpen={isOpenTransferItemSuccess}
      onRequestClose={setReload}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className="modal-primary w-540px h-fit-content w-xs-338px padding-modal mode-color-8787d6">
        <div className="close-button-modal" onClick={setReload}>
          <IconClose className="close-icon dark:close-icon" />
        </div>
        <div className="fs-24 fs-xs-16 fw-bold text-center">Transfer Item</div>
        <div className="line-banner mb-3"></div>
        <div className="">
          <div className="flex-center gap-8px flex-column m-b-24 m-b-xs-8">
            <IconSuccess className="w-xs-40px h-xs-40px" />
            <div className="fs-xs-14 lh-19 lh-xs-inherit">Your item transfer was successful.</div>
          </div>
          <div className="fs-14 fs-xs-12 p-b-8 title-input-modal mode-color-8787d6 lh-16 lh-xs-inherit">Item</div>
          <div className="detail-box-modal fs-xs-12 m-b-24 m-b-xs-16  mode-color-8787d6">
            {selected?.metadata?.name
              ? selected?.metadata?.name
              : typeof selected?.name === 'string'
              ? selected?.name
              : selected?.name?.en}
          </div>
          {(selected?.contract_type === 'ERC1155' || selected?.kind === 'mt') && (
            <>
              <div className="fs-14 fs-xs-12 p-b-8 title-input-modal mode-color-8787d6 lh-16 lh-xs-inherit fw-4">
                Quantity
              </div>
              <div className="detail-box-modal fs-xs-12 m-b-24 m-b-xs-16 color-8787d6">{dataItemTransfer?.amount}</div>
            </>
          )}
          <div className="fs-14 fs-xs-12 p-b-8 title-input-modal mode-color-8787d6 lh-16 lh-xs-inherit">
            Recipient wallet address{' '}
          </div>
          <div className="detail-box-modal fs-xs-12 m-b-24 m-b-xs-16 mode-color-8787d6">
            {dataItemTransfer?.recipient}
          </div>
          <div className="fs-14 fs-xs-12 p-b-8 title-input-modal mode-color-8787d6 lh-16 lh-xs-inherit">TXID</div>
          <div className="detail-box-modal fs-xs-12 m-b-24 m-b-xs-16 flex-between mode-color-8787d6">
            <div>{dataItemTransfer?.txid?.slice(0, 11) + '...' + dataItemTransfer?.txid?.slice(-9)}</div>
            <div className="circle-box-icon-transfer-item variant-circle-1" onClick={handleGetOutLink}>
              {isDarkMode ? (
                <IconLinkDarkMode className="w-12px w-xs-8px h-12px h-xs-8px" />
              ) : (
                <IconLinkLightMode className="w-12px w-xs-8px h-12px h-xs-8px" />
              )}
            </div>
          </div>
          <div className="btn-detail-nft button m-0 h-50px h-xs-40px" onClick={setReload}>
            Confirm
          </div>
        </div>
      </div>
    </ReactModal>
  );
};
export default TransferItemSuccess;
