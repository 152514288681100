import React, { useEffect, useState, useMemo, useRef } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import background from '../../assets/img/home_01.png';
import background_daymode from '../../assets/img/home_01_daymode.jpg';
import backgroundMobile from '../../assets/img/home_01_mobile.png';
import backgroundMobile_dm from '../../assets/img/home_01_mobile_dm.jpg';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useScreenSize from 'components/common/useScreenSize';
import {
  getAirdropList,
  getCollectibleList,
  getCollectionList,
  getEventList,
  getFeaturedCollections,
  getSellBooks,
} from '../../services/services';
import { FeaturedTypes } from '../../types/FeaturedTypes';
import FeaturedCard from '../../components/card/FeaturedCard';
import ArrowCarouselCollections from 'components/common/ArrowCarouselCollections';
import CustomArrowCarousel from 'components/common/CustomArrowCarousel';
import { MBoxTypes } from '../../types/MBoxTypes';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import { getPrice } from '../../utils/getPrice';
import { getComma } from '../../utils/getComma';
import ArrowCarouselBannerMain from 'components/common/ArrowCarouselBannerMain';
import { getRarityToString } from '../../utils/getRarityToString';
import { getNetworkNameById } from '../../utils/getNetworkNameById';
import Skeleton from 'components/common/skeleton/Skeleton';
import { useMediaQuery } from 'react-responsive';
import GoogleAnalytics from 'react-ga';
import { useThemeMode } from 'components/common/AppStoreType';
import see_all from 'assets/icon/see_all.png';
import eternal from 'assets/icon/eternal_editions.png';
import talk from '../../talkclaim/talk.png';
// import talk from 'assets/icon/talken.svg';
import { getPriceRecentlyListedItems } from 'utils/getPriceRecentlyListedItems';
import env from '../../env';
import { getContractName } from '../../utils/transactions';
import ArrowFirstCarousel from 'components/common/ArrowFirstCarousel';
import { getTargetChainId } from '../../config';
import LeaderBoard from 'components/board/LeaderBoard';
import TopNfts from 'components/board/TopNfts';
import { ReactComponent as IconMoreBoard } from '../../assets/icon/double_down_arrow.svg';
import { HandleMediaError } from 'components/common/HandleMediaError';

type ExMBoxType = MBoxTypes & {
  remainingAmount: number | null;
};

const isProduction = process.env.REACT_APP_PHASE === 'production';

const Homepage = () => {
  const sliderRef = useRef(null);
  const sliderActiveIndexRef = useRef(0);
  const { account, library, chainId } = useActiveWeb3React();
  const isMobile = useMediaQuery({
    query: '(max-width: 639px)',
  });
  const navigate = useNavigate();
  const screenSize = useScreenSize();
  const [slideData, setSlideData] = useState<FeaturedTypes[]>([]);
  const [featuredCollections, setFeaturedCollections] = useState<FeaturedTypes[]>([]);
  const [collectionList, setCollectionList] = useState<ExMBoxType[]>([]);
  const [collectibleList, setCollectibleList] = useState<ExMBoxType[]>([]);
  const [sellbooks, setSellbooks] = useState([]);
  const [airdropList, setAirdropList] = useState<ExMBoxType[]>([]);
  const [backgroundBanner, setBackgroundBanner] = useState('');
  const [isSeeMore, setIsSeeMore] = useState(false);
  const navigateToUrl = (item: FeaturedTypes) => {
    GoogleAnalytics.event({
      category: 'MoveActivity',
      action: 'Move',
      label: item.eventUrl ? 'pathUrl : ' + item.eventUrl : 'pathUrl : ' + `/creator/${item.id}`,
    });
    if (item.eventUrl) {
      window.open(item.eventUrl, item.newWindow ? '_blank' : '_self');
    } else {
      window.open(`/creator/${item.id}`, item.newWindow ? '_blank' : '_self');
    }
  };

  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }

  useEffect(() => {
    const handleScroll = () => {
      localStorage.setItem('scrollPosition', window.scrollY.toString());
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // popstate 이벤트가 발생할 때 (뒤로가기 등) 스크롤 위치를 저장된 위치로 조정
  useEffect(() => {
    const handlePopState = () => {
      const savedScrollPosition = localStorage.getItem('scrollPosition');
      if (savedScrollPosition) {
        window.scrollTo(0, Number(savedScrollPosition));
      }
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  // 페이지를 떠날 때 (다음 페이지로 이동할 때) 스크롤 위치 정보 삭제
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('scrollPosition');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // const params = useQuery();
  // const uid = params.get('uid');
  // const trigger = params.get('trigger');

  // const storeTalkenData = (uid: string) => {
  //   const storeSet = { uid: uid };
  //   const _storeSet = JSON.stringify(storeSet);
  //   localStorage.setItem('talken.data', _storeSet);
  // };

  // useEffect(() => {
  //   if (uid) {
  //     storeTalkenData(uid);
  //   }
  //   navigate('/');
  // }, []);

  useEffect(() => {
    const fetchSlideData = async () => {
      const res = await getEventList();
      if (res.data.status === 1) {
        setSlideData(res.data.data.list);
      }
    };

    const fetchFeaturedCollections = async () => {
      const res = await getFeaturedCollections(10);
      if (res.data.status === 1) {
        setFeaturedCollections(res.data.data.list);
      }
    };

    const fetchCollectionList = async () => {
      const res = await getCollectionList(true);
      if (res.data?.data?.list) {
        const newList = await Promise.all(
          res.data?.data?.list.map(async (item: MBoxTypes) => {
            // const remainFrChain = await getItemRemainsNoSigner(
            //   item.boxContractAddress,
            //   account,
            //   chainId
            // );
            const remaining = item.totalAmount! - item.soldAmount!;
            const milliseconds = new Date().getTime() - Date.parse(item.releaseDatetime);
            return {
              ...item,
              remainingAmount: remaining,
              onsale: milliseconds >= 0 ? true : false,
            };
          })
        );
        setCollectionList(newList);
      }
    };

    const fetchCollectibleList = async () => {
      const res = await getCollectibleList();
      if (res.data?.data) {
        const newList = await Promise.all(
          res.data.data.map(async (item: any) => {
            const id = Math.floor(Math.random() * item.mysteryboxItems[0].issueAmount) + 1;
            const milliseconds = new Date().getTime() - Date.parse(item.releaseDatetime);
            return {
              ...item,
              itemId: id,
              onsale: milliseconds >= 0 ? true : false,
            };
          })
        );
        setCollectibleList(newList);
      }
    };

    const fetchSellbooks = async () => {
      const res = await getSellBooks(1);
      if (res.data?.data) {
        const newList = await Promise.all(
          res.data?.data.sellbooks.map(async (sell: any) => {
            const chain = getTargetChainId(sell.nftInfo.chain);
            const name = await getContractName(sell.nftInfo.contractAddress, chain);
            return {
              ...sell,
              collectionName: name,
            };
          })
        );
        setSellbooks(newList);
      }
    };

    const fetchAirdropList = async () => {
      const res = await getAirdropList();
      if (res.data?.data?.list) {
        const newList = await Promise.all(
          res.data?.data?.list.map(async (item: MBoxTypes) => {
            const remaining = item.totalAmount! - item.soldAmount!;
            const milliseconds = new Date().getTime() - Date.parse(item.releaseDatetime);
            return {
              ...item,
              remainingAmount: remaining,
              onsale: milliseconds >= 0 ? true : false,
            };
          })
        );
        setAirdropList(newList);
      }
    };

    fetchSlideData();
    fetchFeaturedCollections();
    fetchCollectibleList();
    fetchCollectionList();
    fetchAirdropList();
    fetchSellbooks();
    const savedScrollPosition = localStorage.getItem('scrollPosition');
    if (savedScrollPosition) {
      setTimeout(() => window.scrollTo(0, Number(savedScrollPosition)), 600);
    }
  }, [library]);

  const carouselFirst = {
    className: 'center custom-slider-main',
    centerMode: true,
    infinite: true,
    centerPadding: '36px',
    slidesToShow: 2,
    speed: 500,
    dots: true,
    prevArrow: <ArrowFirstCarousel to="prev" />,
    nextArrow: <ArrowFirstCarousel to="next" />,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1299,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '26px',
        },
      },
    ],
  };
  const carouselOption = {
    additionalTransfrom: 0,
    arrows: false,
    autoPlay: false,
    autoPlaySpeed: 5000,
    draggable: true,
    focusOnSelect: false,
    keyBoardControl: true,
    minimumTouchDrag: 80,
    pauseOnHover: true,
    renderArrowsWhenDisabled: false,
    renderButtonGroupOutside: true,
    renderDotsOutside: true,
    rewind: false,
    rewindWithAnimation: false,
    rtl: false,
    shouldResetAutoplay: true,
    showDots: true,
    // slidesToSlide: 1,
    swipeable: true,
    infinite: true,
  };
  const responsiveFeaturedCreator = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1367,
      },
      items: 5,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: {
        max: 640,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 640,
      },
      items: 2,
      partialVisibilityGutter: 30,
    },
    laptopLarge: {
      breakpoint: {
        max: 1367,
        min: 1180,
      },
      items: 4,
      partialVisibilityGutter: 30,
    },
    laptop: {
      breakpoint: {
        max: 1180,
        min: 1024,
      },
      items: 3,
      partialVisibilityGutter: 30,
    },
  };
  const responsiveCarousel = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1300,
      },
      items: 4,
      partialVisibilityGutter: 40,
    },
    laptop: {
      breakpoint: {
        max: 1299,
        min: 1000,
      },
      items: 3,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 999,
        min: 680,
      },
      items: 2,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: {
        max: 679,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 30,
    },
  };
  const { isDarkMode } = useThemeMode();
  const handleSlideImg = (item: any) => {
    if (item?.companyId === 'eternaledtions' || item?.companyId === 'dokdoverse') return item.image;
    else return talk;
  };
  const handleSlideTite = (item: any) => {
    if (item?.companyId === 'eternaledtions' || item?.companyId === 'dokdoverse') return item.name?.en;
    else return 'Talken Drops';
  };

  useEffect(() => {
    if (screenSize < 768) {
      setBackgroundBanner(
        slideData[slideData?.length - 1]?.eventMobileBanner || slideData[slideData?.length - 1]?.mobileBanner
      );
    } else {
      setBackgroundBanner(slideData[slideData?.length - 1]?.eventBanner);
    }
  }, [slideData]);
  const hasCJK = (string) => {
    const regex = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f\u3131-\uD79D]/;
    return string.match(regex) ? true : false;
  };
  return (
    <div className="home-page min-height-content">
      {/* section 01 */}
      <div className="section-01">
        <div className="background-section-01">
          {/* {isDarkMode ? <img src={backgroundBanner} alt="" /> : <img src={background_daymode} alt="" />} */}
          <img src={backgroundBanner} alt="" />
        </div>
        {/* <div className="background-section-01-mobile">
          {isDarkMode ? <img src={backgroundMobile} alt="" /> : <img src={backgroundMobile_dm} alt="" />}
          <img src={backgroundBanner} alt="" />
        </div> */}
        {/* <div className="content-header">
          <div className="text-head">Talken Drops</div>
          <div className="text-bottom">
            Check out NFTs and Collectibles from popular teams,&nbsp;
            <br className="text-head-pc" />
            famous brands&nbsp;and world renown artists
          </div>
        </div> */}
        {/* <div className="carousel-main-page">
          <Carousel
            {...carouselOption}
            customButtonGroup={<ArrowCarouselBannerMain />}
            renderButtonGroupOutside={false}
            centerMode={screenSize > 1023}
            dotListClass="custom-dot"
            containerClass="container-with-dots home-carousel banner-main-carousel"
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 0,
                },
                items: 2,
                partialVisibilityGutter: 0,
              },
            }}
          >
            {slideData.length
              ? slideData.map((item: FeaturedTypes, index) => {
                  return (
                    <div className="slide-item" key={index} onClick={() => navigateToUrl(item)}>
                      <div>
                        <img
                          src={isMobile && item.eventMobileBanner ? item.eventMobileBanner : item.eventBanner!}
                          alt=""
                          draggable={false}
                        />
                      </div>
                    </div>
                  );
                })
              : [1, 2, 3].map((item) => (
                  <div className="slide-item" key={item}>
                    <div>
                      <Skeleton
                        style={{
                          width: '100%',
                          aspectRatio: '24/7',
                          height: 'unset',
                        }}
                      />
                    </div>
                  </div>
                ))}
          </Carousel>
        </div> */}
        <div className="p-t-114 p-t-xs-90">
          <Slider
            ref={sliderRef}
            {...carouselFirst}
            beforeChange={(oldIndex, newIndex) => {
              if (screenSize < 768) {
                setBackgroundBanner(
                  slideData[newIndex >= 0 ? newIndex : slideData?.length - 1]?.eventMobileBanner ||
                    slideData[newIndex >= 0 ? newIndex : slideData?.length - 1]?.mobileBanner
                );
                sliderActiveIndexRef.current = newIndex;
              } else {
                setBackgroundBanner(slideData[newIndex - 1 >= 0 ? newIndex - 1 : slideData?.length - 1]?.eventBanner);
                sliderActiveIndexRef.current = newIndex;
              }
            }}
          >
            {[...slideData].map((item: FeaturedTypes, index, arr) => {
              return (
                <div
                  className="slide-item"
                  key={index}
                  onClick={(event) => {
                    const currentClick: HTMLDivElement = (event.target as HTMLDivElement).closest('.slick-slide');
                    // desktop
                    if (currentClick.classList.contains('slick-current') && screenSize > 1024) {
                      sliderRef.current?.slickNext();
                    }
                    if (currentClick.nextElementSibling.classList.contains('slick-current') && screenSize > 1024) {
                      navigateToUrl(item);
                    }
                    if (currentClick.previousElementSibling.classList.contains('slick-current') && screenSize > 1024) {
                      sliderRef.current?.slickGoTo((sliderActiveIndexRef.current + 2) % arr.length);
                    }
                    if (
                      currentClick.nextElementSibling.nextElementSibling.classList.contains('slick-current') &&
                      screenSize > 767
                    ) {
                      sliderRef.current?.slickPrev();
                    }
                    // mobile
                    if (currentClick.classList.contains('slick-current') && screenSize <= 1024) {
                      navigateToUrl(item);
                    }
                    if (currentClick.previousElementSibling.classList.contains('slick-current') && screenSize <= 1024) {
                      sliderRef.current?.slickPrev();
                      sliderRef.current?.slickNext();
                    }
                    if (currentClick.nextElementSibling.classList.contains('slick-current') && screenSize <= 1024) {
                      sliderRef.current?.slickPrev();
                    }
                  }}
                >
                  <div>
                    <img
                      src={
                        screenSize < 768 && item.eventMobileBanner
                          ? item.eventMobileBanner || item.mobileBanner
                          : item.eventBanner!
                      }
                      alt=""
                      draggable={false}
                      className="img-event-banner"
                    />
                    <div className="slide-item-info">
                      <div className="wrapper-editions">
                        <img src={handleSlideImg(item)} alt="eternal" className="icon-eternal" />
                        <div className="title-eternal">{handleSlideTite(item)}</div>
                      </div>
                      <div className="slide-content-bottom">
                        <div className="slide-info-bottom">
                          <div className={`slide-title-bottom ${screenSize > 1299 ? 'line-clamp-1' : ''}`}>
                            {item?.name?.en}
                          </div>
                          <div className="slide-total">
                            Items:{' '}
                            {item.companyId?.toUpperCase() === 'ETERNALEDTIONS'
                              ? 6
                              : item.companyId?.toUpperCase() === 'NURITOPIA'
                              ? 98
                              : item.companyId?.toUpperCase() === 'DOKDOVERSE'
                              ? getComma(54021)
                              : getComma(item.totalAmount) || 0}
                          </div>
                        </div>
                        <Link to={'/drops'} onClick={(e) => e.stopPropagation()} className="btn-view-drops">
                          View Drops
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>

      {/* section 02 */}
      <div className="section-02">
        {/* Top Collections and Leadboard */}
        {!isProduction && (
          <div className="list-boards">
            <TopNfts seeMore={isSeeMore} />
            <LeaderBoard seeMore={isSeeMore} />
            {screenSize > 768 &&
              (isSeeMore ? (
                <div className="wrapper-hide" onClick={() => setIsSeeMore(false)}>
                  <IconMoreBoard className="icon-hide-board" />
                  <span>Hide</span>
                </div>
              ) : (
                <div className="wrapper-more" onClick={() => setIsSeeMore(true)}>
                  <span>More</span>
                  <IconMoreBoard />
                </div>
              ))}
          </div>
        )}
        {/* Featured Collections */}
        <div
          className={`featured-collections padding-section01 ${
            featuredCollections?.length > 5 && screenSize > 1367
              ? 'hover-featured-collections'
              : featuredCollections?.length > 4 && screenSize < 1368
              ? 'hover-featured-collections'
              : featuredCollections?.length > 3 && screenSize < 1181
              ? 'hover-featured-collections'
              : featuredCollections?.length > 2 && screenSize < 1024
              ? 'hover-featured-collections'
              : ''
          } ${screenSize < 640 ? 'pb-4' : ''}
          `}
        >
          <div className="wrapper-header title-header spacing-featured">
            <div className="header-name">Featured Creators</div>
            <Link to={'/creators'} className="show-all-item button">
              <img src={see_all} alt="See all" className="icon-see-all" />
            </Link>
          </div>
          {isMobile ? (
            <div className="featured-creators-mb">
              {featuredCollections.map((item: FeaturedTypes) => (
                <FeaturedCard key={item.id} item={item} page={'homepage'} />
              ))}
            </div>
          ) : (
            <Carousel
              {...carouselOption}
              customButtonGroup={<ArrowCarouselCollections totalItems={featuredCollections?.length} />}
              keyBoardControl
              removeArrowOnDeviceType=""
              containerClass="grid-container-featured"
              responsive={responsiveFeaturedCreator}
              showDots={false}
            >
              {featuredCollections?.length
                ? featuredCollections.map((item: FeaturedTypes) => (
                    <FeaturedCard key={item.id} item={item} page={'homepage'} />
                  ))
                : [1, 2, 3, 4, 5].map((item) => (
                    <div className="custom-link" key={item}>
                      <Skeleton key={item} />
                    </div>
                  ))}
            </Carousel>
          )}
        </div>

        <div
          className={`page-grid featured-collections ${
            collectionList?.length > 4 && screenSize > 1299
              ? 'hover-carousel'
              : collectionList?.length > 3 && screenSize < 1300
              ? 'hover-carousel'
              : collectionList?.length > 2 && screenSize < 1000
              ? 'hover-carousel'
              : collectionList?.length > 1 && screenSize < 680
              ? 'hover-carousel'
              : ''
          }`}
        >
          {/* <div className="title-header">Talken Drops</div> */}
          <div className="wrapper-header title-header">
            <div className="header-name">Talken Drops</div>
            <Link to={'/drops'} className="show-all-item button">
              <img src={see_all} alt="See all" className="icon-see-all" />
            </Link>
          </div>
          {!!collectionList?.length &&
            (isMobile ? (
              <div className="hot-collectibles-mb">
                {collectionList.map((item: any, index) => {
                  return (
                    <Link
                      to={
                        item.itemAmount === 1 && item.mysteryboxItems
                          ? `/collection/${item.id}/${item.mysteryboxItems[0]?.id}`
                          : `/collections/${item.id}`
                      }
                      className="button custom-box"
                      key={index}
                    >
                      <div className="hot-ollectibles-wrapper">
                        <div className="header-left hot-ollectibles-item">
                          <span className="total-run">Total Items: {getComma(item.totalAmount)}</span>
                          <div className="chain-name">{getNetworkNameById(item.chainId)}</div>
                        </div>
                        {/* <div className="hot-ollectibles-item" style={{ textTransform: 'capitalize' }}>
                          <div>{getNetworkNameById(item.chainId)}</div>
                        </div> */}
                        <div className="hot-ollectibles-item">
                          <div className="img-token position-relative">
                            {item?.packageImage?.split('.')?.pop() === 'mp4' ? (
                              <video
                                playsInline
                                autoPlay
                                muted
                                loop
                                controlsList="nodownload"
                                width={'100%'}
                                onError={HandleMediaError}
                                key={item.packageImage}
                              >
                                <source src={item.packageImage} type="video/mp4" />
                              </video>
                            ) : (
                              <img src={item.packageImage} alt="" draggable={false} onError={HandleMediaError} />
                            )}
                            <div className="position-absolute price-and-remaining">
                              <div className="wrapper-price text-center">
                                <div className="price-header color-aaa fs-10">Price</div>
                                {/* <div className="current-price fs-12 fw-7">
                                  {getPrice(item.fiatPrice || item.price, item.fiatPrice ? 'KRW' : item.quote)}
                                </div> */}
                                <div className="fs-14 fw-7">
                                  {getPriceRecentlyListedItems(item.fiatPrice || item.price)}
                                  {item.fiatPrice !== null ||
                                    (item.price !== null && (
                                      <span className="fs-14 fs-xs-10 m-l-4">
                                        {item.fiatPrice
                                          ? 'KRW'
                                          : item.quote?.toUpperCase() === 'ETHEREUM'
                                          ? 'ETH'
                                          : item.quote?.toUpperCase()}
                                      </span>
                                    ))}
                                </div>
                              </div>
                              <div className="wrapper-remaining text-center">
                                <div className="remaining-header color-aaa fs-10">
                                  {item.isSoldOut || !item.remainingAmount ? null : 'Remaining'}
                                </div>
                                <div
                                  className={`quantity-remaining fs-12 fw-7 ${
                                    (item.isSoldOut || !item.remainingAmount) && screenSize < 640 ? 'p-t-12' : ''
                                  }`}
                                >
                                  {!item.isSoldOut && item.remainingAmount ? item.remainingAmount : 'Sold Out'}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="hot-ollectibles-item">
                          <div className="wrapper-item">
                            <div className="content-left">
                              <div className="avatar">
                                <img src={item.featured.company.image} alt="" draggable={false} />
                              </div>
                              <div className="mb-0 mt-0">{item.featured.company.name.en}</div>
                            </div>
                            {/*<div className="content-right">{item.onsale ? 'Buy Now' : 'Waiting'}</div>*/}
                          </div>
                        </div>
                        <div className="hot-ollectibles-item">
                          <div className={`product-name ${hasCJK(item.title.en) ? 'cjk-font' : ''}`}>
                            {item.title.en}
                          </div>
                        </div>
                        {/* <div className="hot-ollectibles-item">
                        <div className="name-label">{item.details}</div>
                      </div> */}
                      </div>
                    </Link>
                  );
                })}
              </div>
            ) : (
              <Carousel
                {...carouselOption}
                customButtonGroup={<CustomArrowCarousel totalItems={collectionList?.length} />}
                keyBoardControl
                removeArrowOnDeviceType=""
                containerClass="hot-collectibles"
                responsive={responsiveCarousel}
                showDots={false}
              >
                {collectionList.map((item: any, index) => {
                  return (
                    <Link
                      to={
                        item.itemAmount === 1 && item.mysteryboxItems
                          ? `/collection/${item.id}/${item.mysteryboxItems[0]?.id}`
                          : `/collections/${item.id}`
                      }
                      // state={
                      //   item.itemAmount === 1
                      //     ? {
                      //         item: {
                      //           collectionInfo: item,
                      //           ...item.mysteryboxItems[0],
                      //           companyLogo: item.featured.company.image,
                      //           companyName: item.featured.company.name.en,
                      //           quote: item.quote,
                      //         },
                      //       }
                      //     : {
                      //         item: {
                      //           ...item,
                      //           companyLogo: item.featured.company.image,
                      //           companyName: item.featured.companyId,
                      //           quote: item.quote,
                      //         },
                      //       }
                      // }
                      className="button custom-box"
                      key={index}
                    >
                      <div className="hot-ollectibles-wrapper">
                        <div className="header-left hot-ollectibles-item">
                          <span className="total-run">Total Items: {getComma(item.totalAmount)}</span>
                          <div className="chain-name">{getNetworkNameById(item.chainId)}</div>
                        </div>
                        <div className="hot-ollectibles-item">
                          <div className="img-token position-relative">
                            {item.packageImage?.split('.')?.pop() === 'mp4' ? (
                              <video
                                playsInline
                                autoPlay
                                muted
                                loop
                                controlsList="nodownload"
                                width={'100%'}
                                onError={HandleMediaError}
                                key={item.packageImage}
                              >
                                <source src={item.packageImage} type="video/mp4" />
                              </video>
                            ) : (
                              <img src={item.packageImage} alt="" draggable={false} onError={HandleMediaError} />
                            )}
                            <div className="position-absolute price-and-remaining">
                              <div className="wrapper-price text-center">
                                <div className="price-header color-aaa fs-12">Price</div>
                                <div className="current-price fs-14 fw-7">
                                  {getPrice(
                                    item.fiatPrice || item.price,
                                    item.fiatPrice
                                      ? 'KRW'
                                      : item.quote?.toUpperCase() === 'ETHEREUM'
                                      ? 'ETH'
                                      : item.quote?.toUpperCase()
                                  )}
                                </div>
                              </div>
                              <div className="wrapper-remaining text-center">
                                <div className="remaining-header color-aaa fs-12">
                                  {item.isSoldOut || !item.remainingAmount ? null : 'Remaining'}
                                </div>
                                <div className={`quantity-remaining fs-14 fw-7 ${!item.remainingAmount ? '' : ''}`}>
                                  {!item.isSoldOut && item.remainingAmount ? item.remainingAmount : 'Sold Out'}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="hot-ollectibles-item">
                          <div className="wrapper-item">
                            <div className="content-left">
                              <div className="avatar">
                                <img src={item.featured.company.image} alt="" draggable={false} />
                              </div>
                              <div className="mb-0 mt-0">{item.featured.company.name.en}</div>
                            </div>
                            {/*<div className="content-right">{item.onsale ? 'Buy Now' : 'Waiting'}</div>*/}
                          </div>
                        </div>
                        <div className="hot-ollectibles-item">
                          <div className={`product-name ${hasCJK(item.title.en) ? 'cjk-font' : ''}`}>
                            {item.title.en}
                          </div>
                        </div>
                        {/* <div className="hot-ollectibles-item">
                        <div className="name-label">{item.details}</div>
                      </div> */}
                      </div>
                    </Link>
                  );
                })}
              </Carousel>
            ))}
          {/*{hotCollectiblesTestData && (*/}
          {/*  <Carousel*/}
          {/*    {...carouselOption}*/}
          {/*    arrows={false}*/}
          {/*    renderButtonGroupOutside*/}
          {/*    customButtonGroup={<CustomArrowCarousel />}*/}
          {/*    keyBoardControl*/}
          {/*    removeArrowOnDeviceType=""*/}
          {/*    containerClass="container hot-collectibles"*/}
          {/*    responsive={{*/}
          {/*      desktop: {*/}
          {/*        breakpoint: {*/}
          {/*          max: 3000,*/}
          {/*          min: 1420,*/}
          {/*        },*/}
          {/*        items: 5,*/}
          {/*        partialVisibilityGutter: 40,*/}
          {/*      },*/}
          {/*      mobile: {*/}
          {/*        breakpoint: {*/}
          {/*          max: 640,*/}
          {/*          min: 0,*/}
          {/*        },*/}
          {/*        items: 1,*/}
          {/*        partialVisibilityGutter: 30,*/}
          {/*      },*/}
          {/*      tablet: {*/}
          {/*        breakpoint: {*/}
          {/*          max: 1024,*/}
          {/*          min: 640,*/}
          {/*        },*/}
          {/*        items: 2,*/}
          {/*        partialVisibilityGutter: 30,*/}
          {/*      },*/}
          {/*      laptopLarge: {*/}
          {/*        breakpoint: {*/}
          {/*          max: 1420,*/}
          {/*          min: 1180,*/}
          {/*        },*/}
          {/*        items: 4,*/}
          {/*        partialVisibilityGutter: 30,*/}
          {/*      },*/}
          {/*      laptop: {*/}
          {/*        breakpoint: {*/}
          {/*          max: 1180,*/}
          {/*          min: 1024,*/}
          {/*        },*/}
          {/*        items: 3,*/}
          {/*        partialVisibilityGutter: 30,*/}
          {/*      },*/}
          {/*    }}*/}
          {/*    showDots={false}*/}
          {/*  >*/}
          {/*    {hotCollectiblesTestData.map((item: any, index) => (*/}
          {/*      <Link*/}
          {/*        to={`/sale/${item.id}`}*/}
          {/*        className="button custom-box"*/}
          {/*        key={index}*/}
          {/*      >*/}
          {/*        <div className="hot-ollectibles-wrapper">*/}
          {/*          <div className="header-left hot-ollectibles-item">*/}
          {/*            <span className="total-run">Total Run: 35000</span>*/}
          {/*          </div>*/}
          {/*          <div className="hot-ollectibles-item">*/}
          {/*            <div>erc721</div>*/}
          {/*          </div>*/}
          {/*          <div className="hot-ollectibles-item">*/}
          {/*            <div className="img-token">*/}
          {/*              <img src={item.image} alt="" draggable={false} />*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*          <div className="hot-ollectibles-item">*/}
          {/*            <div className="wrapper-item">*/}
          {/*              <div className="content-left">*/}
          {/*                <div className="avatar">*/}
          {/*                  <img src={item.imageAvt} alt="" draggable={false} />*/}
          {/*                </div>*/}
          {/*                <div className="name-label">연동필요</div>*/}
          {/*              </div>*/}
          {/*              <div className="content-right">Buy Now</div>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*          <div className="hot-ollectibles-item">*/}
          {/*            <div className="name-label">{item.details}</div>*/}
          {/*          </div>*/}
          {/*          <div className="hot-ollectibles-item">*/}
          {/*            <div className="wrapper-price">*/}
          {/*              <div className="price-header">Price</div>*/}
          {/*              <div className="current-price">*/}
          {/*                ${item.currentPrice}*/}
          {/*              </div>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*          <div className="hot-ollectibles-item">*/}
          {/*            <div className="wrapper-remaining">*/}
          {/*              <div className="remaining-header">Remaining </div>*/}
          {/*              <div className="quantity-remaining">*/}
          {/*                {item.quantityRemaining}*/}
          {/*              </div>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </Link>*/}
          {/*    ))}*/}
          {/*  </Carousel>*/}
          {/*)}*/}
        </div>
        {/* Hot Items Recently Listed Items */}
        <div
          className={`page-grid featured-collections ${
            sellbooks?.length > 4 && screenSize > 1299
              ? 'hover-carousel'
              : sellbooks?.length > 3 && screenSize < 1300
              ? 'hover-carousel'
              : sellbooks?.length > 2 && screenSize < 1000
              ? 'hover-carousel'
              : sellbooks?.length > 1 && screenSize < 680
              ? 'hover-carousel'
              : ''
          }`}
        >
          {/* <div className="title-header">Hot Collectibles</div> */}
          <div className="wrapper-header title-header">
            <div className="header-name">Talken Market</div>
            <Link to={'/market'} className="show-all-item button">
              <img src={see_all} alt="See all" className="icon-see-all" />
            </Link>
          </div>
          {!!sellbooks?.length &&
            (isMobile ? (
              <div className="hot-collectibles-mb">
                {sellbooks?.map((item: any, index) => {
                  return (
                    <Link
                      to={`/sellbook/${item.id}/${item.nftInfo?.chain}/${item.nftInfo?.contractAddress}/${item.tokenId}`}
                      state={{
                        item: {
                          ...item,
                        },
                      }}
                      // to={`/${getNetworkNameById(item.chainId)?.toLowerCase()}/${item.boxContractAddress}/${
                      //   item.mysteryboxItems[0]?.no
                      // }/${item.itemId}`}
                      // // to={`/klaytn/${item.boxContractAddress}/${item.mysteryboxItems[0]?.no}/${item.itemId}`}
                      // state={{
                      //   item: {
                      //     ...item,
                      //     companyLogo: item.featured.company.image,
                      //     companyName: item.featured.companyId,
                      //     quote: item.quote,
                      //   },
                      // }}
                      className="button custom-box"
                      key={index}
                    >
                      <div className="hot-ollectibles-wrapper">
                        {/* <div className="header-left hot-ollectibles-item fw-600">
                            <span className="total-run">{`#${item.itemId}/${item.mysteryboxItems[0]?.issueAmount}`}</span>
                          </div> */}
                        <div className="hot-ollectibles-item">
                          <div className="hot-items-editions">
                            <img src={talk} alt="eternal" className="icon-eternal" />
                          </div>
                          <div className="chain-name">{getNetworkNameById(item.chainId)}</div>
                        </div>
                        {/* <div className="hot-ollectibles-item" style={{ textTransform: 'capitalize' }}>
                          <div>{item?.nftInfo?.chain?.toLowerCase()}</div>
                        </div> */}
                        <div className="hot-ollectibles-item position-relative">
                          <div
                            className="img-token"
                            // style={{ width: '100%', height: '156px', margin: '14px auto 14px' }}
                          >
                            {item?.nftInfo?.image?.split('.')?.pop() === 'mp4' ||
                            item?.nftInfo?.extension === 'mp4' ||
                            item?.nftInfo?.metadata?.image?.split('.')?.pop() === 'mp4' ||
                            item?.nftInfo?.metadata?.extension === 'mp4' ? (
                              <video
                                playsInline
                                webkit-playsinline="true"
                                muted
                                controlsList="nodownload"
                                width={'100%'}
                                onError={HandleMediaError}
                                key={`${item?.nftInfo?.image}#t=0.001`}
                              >
                                <source src={`${item?.nftInfo?.image}#t=0.001`} type="video/mp4" />
                              </video>
                            ) : (
                              <img
                                src={
                                  item?.nftInfo?.alt_url ||
                                  item?.nftInfo?.image ||
                                  item?.nftInfo?.metadata?.alt_url ||
                                  item?.nftInfo?.metadata?.image
                                    ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                                    .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')
                                }
                                alt=""
                                draggable={false}
                                onError={HandleMediaError}
                              />
                            )}
                            <div className="position-absolute wrapper-price-type">
                              <div className="price-type-home">{item.type === 1 ? 'Fixed price' : 'Auction'}</div>
                              <div className="fs-12 fw-7">
                                {getPriceRecentlyListedItems(item?.fiatPrice || item?.topPrice)}
                                <span className="fs-12 m-l-4 text-uppercase">{item.quote}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="name-nft-card">{item.collectionName}</div>
                        <div className="fw-7 line-clamp-1 fs-xs-14 w-100 p-t-4">
                          {item.nftInfo?.metadata?.name
                            ? item.nftInfo?.metadata?.name
                            : typeof item.nftInfo?.name === 'string'
                            ? item.nftInfo?.name
                            : item.nftInfo?.name?.en}
                        </div>
                        {/* <div className="name-nft-card m-l-12 m-r-12 m-b-10">
                          <div className="fs-xs-14">
                            {getPriceRecentlyListedItems(item?.topPrice || item?.fiatPrice)}
                            <span className="fs-xs-14 m-l-4 text-uppercase">{item.quote}</span>
                          </div>
                        </div> */}
                        {/* <div className="hot-ollectibles-item">
                            <div className="wrapper-item">
                              <div className="content-left">
                                <div className="avatar">
                                  <img src={item.featured.company.image} alt="" draggable={false} />
                                </div>
                                <div className="name-label">{item.featured.company.name.en}</div>
                              </div>
                              <div className="content-right">{item.onsale ? 'Buy Now' : 'Waiting'}</div>
                            </div>
                          </div>
                          <div className="hot-ollectibles-item">
                            <div className="product-name">{item.mysteryboxItems[0]?.name}</div>
                          </div> */}
                        {/* <div className="hot-ollectibles-item">
                            <div className="name-label">{item.details}</div>
                          </div> */}
                      </div>
                    </Link>
                  );
                })}
              </div>
            ) : (
              <Carousel
                {...carouselOption}
                arrows={false}
                renderButtonGroupOutside
                customButtonGroup={<CustomArrowCarousel totalItems={sellbooks?.length} />}
                keyBoardControl
                removeArrowOnDeviceType=""
                containerClass="hot-collectibles"
                responsive={responsiveCarousel}
                showDots={false}
              >
                {sellbooks.map((item: any, index) => {
                  return (
                    <Link
                      to={`/sellbook/${item.id}/${item.nftInfo?.chain}/${item.nftInfo?.contractAddress}/${item.tokenId}`}
                      // to={`/${getNetworkNameById(item.chainId)?.toLowerCase()}/${item.boxContractAddress}/${
                      //   item.mysteryboxItems[0]?.no
                      // }/${item.itemId}`}
                      // // to={`/klaytn/${item.boxContractAddress}/${item.mysteryboxItems[0]?.no}/${item.itemId}`}
                      state={{
                        item: {
                          ...item,
                          // companyLogo: item.featured.company.image,
                          // companyName: item.featured.companyId,
                          // quote: item.quote,
                        },
                      }}
                      className="button custom-box"
                      key={index}
                    >
                      <div className="hot-ollectibles-wrapper">
                        {/* <div className="header-left hot-ollectibles-item fw-600">
                          <span className="total-run">{`#${item.itemId}/${item.mysteryboxItems[0]?.issueAmount}`}</span>
                        </div> */}
                        <div className="hot-ollectibles-item">
                          <div className="hot-items-editions">
                            <img src={talk} alt="eternal" className="icon-eternal" />
                          </div>
                          <div className="chain-name">{getNetworkNameById(item.chainId)}</div>
                        </div>
                        {/* <div className="hot-ollectibles-item" style={{ textTransform: 'capitalize' }}>
                          <div>{item?.nftInfo?.chain?.toLowerCase()}</div>
                        </div> */}
                        <div className="hot-ollectibles-item">
                          <div
                            className="img-token position-relative"
                            // style={{ width: '234px', height: '234px', marginBottom: '4px' }}
                          >
                            {item?.nftInfo?.image?.split('.')?.pop() === 'mp4' ||
                            item?.nftInfo?.extension === 'mp4' ||
                            item?.nftInfo?.metadata?.image?.split('.')?.pop() === 'mp4' ||
                            item?.nftInfo?.metadata?.extension === 'mp4' ? (
                              <video
                                playsInline
                                webkit-playsinline="true"
                                muted
                                controlsList="nodownload"
                                width={'100%'}
                                onError={HandleMediaError}
                                key={`${item?.nftInfo?.image}#t=0.001`}
                              >
                                <source src={`${item?.nftInfo?.image}#t=0.001`} type="video/mp4" />
                              </video>
                            ) : (
                              <img
                                src={
                                  item?.nftInfo?.alt_url ||
                                  item?.nftInfo?.image ||
                                  item?.nftInfo?.metadata?.alt_url ||
                                  item?.nftInfo?.metadata?.image
                                    ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                                    .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')
                                }
                                alt=""
                                draggable={false}
                                onError={HandleMediaError}
                              />
                            )}
                            <div className="position-absolute wrapper-price-type">
                              <div className="price-type-home">{item.type === 1 ? 'Fixed price' : 'Auction'}</div>
                              <div className="fs-14 fw-7">
                                {getPriceRecentlyListedItems(item?.fiatPrice || item?.topPrice)}
                                <span className="fs-14 m-l-4 text-uppercase">{item.quote}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="hot-ollectibles-item">
                          <div className="wrapper-item">
                            <div className="content-left">
                              <div className="avatar">
                                <img src={item.featured.company.image} alt="" draggable={false} />
                              </div>
                              <div className="name-label">{item.featured.company.name.en}</div>
                            </div>
                            <div className="content-right">{item.onsale ? 'Buy Now' : 'Waiting'}</div>
                          </div>
                        </div> */}
                        <div className="name-nft-card">{item.collectionName}</div>
                        <div className="fw-7 line-clamp-1 fs-xs-14 w-100 p-t-4">
                          {item.nftInfo?.metadata?.name
                            ? item.nftInfo?.metadata?.name
                            : typeof item.nftInfo?.name === 'string'
                            ? item.nftInfo?.name
                            : item.nftInfo?.name.en}
                        </div>

                        {/* <div className="hot-ollectibles-item">
                          <div className="product-name">{item.mysteryboxItems[0]?.name}</div>
                        </div> */}
                        {/* <div className="hot-ollectibles-item">
                          <div className="name-label">{item.details}</div>
                        </div> */}
                      </div>
                    </Link>
                  );
                })}
              </Carousel>
            ))}
        </div>
        {/* Free Drops */}
        <div
          className={`page-grid featured-collections ${
            airdropList?.length > 4 && screenSize > 1299
              ? 'hover-carousel'
              : airdropList?.length > 3 && screenSize < 1300
              ? 'hover-carousel'
              : airdropList?.length > 2 && screenSize < 1000
              ? 'hover-carousel'
              : airdropList?.length > 1 && screenSize < 680
              ? 'hover-carousel'
              : ''
          }`}
        >
          {/* <div className="title-header">Free Drops</div> */}
          <div className="wrapper-header title-header">
            <div className="header-name">Free Drops</div>
            <Link to={'/free-drops'} className="show-all-item button">
              <img src={see_all} alt="See all" className="icon-see-all" />
            </Link>
          </div>
          {!!airdropList?.length &&
            (isMobile ? (
              <div className="hot-collectibles-mb">
                {airdropList.map((item: any, index) => {
                  return (
                    <Link
                      to={`/airdrop/${item.id}/${item.mysteryboxItems[0]?.id}`}
                      state={{
                        item: {
                          ...item,
                          companyLogo: item.featured?.company?.image,
                          companyName: item.featured?.companyId,
                          quote: item.quote,
                        },
                      }}
                      className="button custom-box"
                      key={index}
                    >
                      <div className="hot-ollectibles-wrapper">
                        <div className="header-left hot-ollectibles-item">
                          <span className="total-run">Total Items: {getComma(item.totalAmount)}</span>
                          <div className="chain-name">{getNetworkNameById(item.chainId)}</div>
                        </div>
                        {/* <div className="hot-ollectibles-item" style={{ textTransform: 'capitalize' }}>
                          <div>{getNetworkNameById(item.chainId)?.toLowerCase()}</div>
                        </div> */}
                        <div className="hot-ollectibles-item">
                          <div className="img-token">
                            {item.packageImage?.split('.')?.pop() === 'mp4' ? (
                              <video
                                playsInline
                                webkit-playsinline="true"
                                muted
                                controlsList="nodownload"
                                width={'100%'}
                                autoPlay
                                loop
                                onError={HandleMediaError}
                                key={`${item.packageImage}#t=0.001`}
                              >
                                <source src={`${item.packageImage}#t=0.001`} type="video/mp4" />
                              </video>
                            ) : (
                              <img src={item.packageImage} alt="" draggable={false} onError={HandleMediaError} />
                            )}
                            <div className="position-absolute price-and-remaining">
                              <div className="wrapper-price text-center">
                                <div className="price-header color-aaa fs-10">Price</div>
                                {/* <div className="current-price fs-12 fw-7">
                                  {getPrice(item.fiatPrice || item.price, item.fiatPrice ? 'KRW' : item.quote)}
                                </div> */}
                                <div className="fs-14 fw-7">
                                  {getPriceRecentlyListedItems(item.fiatPrice || item.price)}
                                  {item.fiatPrice !== null ||
                                    (item.price !== null && (
                                      <span className="fs-14 fs-xs-10 m-l-4">
                                        {item.fiatPrice
                                          ? 'KRW'
                                          : item.quote?.toUpperCase() === 'ETHEREUM'
                                          ? 'ETH'
                                          : item.quote?.toUpperCase()}
                                      </span>
                                    ))}
                                </div>
                              </div>
                              <div className="wrapper-remaining text-center">
                                <div className="remaining-header color-aaa fs-10">
                                  {item.isSoldOut || !item.remainingAmount ? null : 'Remaining'}
                                </div>
                                <div
                                  className={`quantity-remaining fs-12 fw-7 ${
                                    (item.isSoldOut || !item.remainingAmount) && screenSize < 640 ? 'p-t-12' : ''
                                  }`}
                                >
                                  {!item.isSoldOut && item.remainingAmount ? item.remainingAmount : 'Sold Out'}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="hot-ollectibles-item">
                          <div className="wrapper-item">
                            <div className="content-left">
                              <div className="avatar">
                                <img src={item.featured?.company?.image} alt="" draggable={false} />
                              </div>
                              <div className="name-label">{item.featured?.company?.name?.en}</div>
                            </div>
                            {/*<div className="content-right">{item.onsale ? 'Get Now' : 'Waiting'}</div>*/}
                          </div>
                        </div>
                        <div className="hot-ollectibles-item">
                          <div className={`product-name ${hasCJK(item.title.en) ? 'cjk-font' : ''}`}>
                            {item.title?.en}
                          </div>
                        </div>
                        {/* <div className="hot-ollectibles-item">
                          <div className="name-label">{item.details}</div>
                        </div> */}
                      </div>
                    </Link>
                  );
                })}
              </div>
            ) : (
              <Carousel
                {...carouselOption}
                arrows={false}
                renderButtonGroupOutside
                customButtonGroup={<CustomArrowCarousel totalItems={airdropList?.length} />}
                keyBoardControl
                removeArrowOnDeviceType=""
                containerClass="hot-collectibles"
                responsive={responsiveCarousel}
                showDots={false}
              >
                {airdropList
                  // .filter((item) => item.price === null || item.price === 0)
                  .map((item: any, index) => {
                    return (
                      <Link
                        to={`/airdrop/${item.id}/${item.mysteryboxItems[0]?.id}`}
                        state={{
                          item: {
                            ...item,
                            companyLogo: item.featured?.company?.image,
                            companyName: item.featured?.companyId,
                            quote: item.quote,
                          },
                        }}
                        className="button custom-box"
                        key={index}
                      >
                        <div className="hot-ollectibles-wrapper">
                          <div className="header-left hot-ollectibles-item">
                            <span className="total-run">Total Items: {getComma(item.totalAmount)}</span>
                            <div className="chain-name">{getNetworkNameById(item.chainId)}</div>
                          </div>
                          {/* <div className="hot-ollectibles-item" style={{ textTransform: 'capitalize' }}>
                            <div>{getNetworkNameById(item.chainId)?.toLowerCase()}</div>
                          </div> */}
                          <div className="hot-ollectibles-item">
                            <div className="img-token position-relative">
                              {item.packageImage?.split('.')?.pop() === 'mp4' ? (
                                <video
                                  playsInline
                                  autoPlay
                                  muted
                                  loop
                                  controlsList="nodownload"
                                  width={'100%'}
                                  onError={HandleMediaError}
                                  key={item.packageImage}
                                >
                                  <source src={item.packageImage} type="video/mp4" />
                                </video>
                              ) : (
                                <img src={item.packageImage} alt="" draggable={false} onError={HandleMediaError} />
                              )}
                              <div className="position-absolute price-and-remaining">
                                <div className="wrapper-price text-center">
                                  <div className="price-header color-aaa fs-12">Price</div>
                                  <div className="current-price fs-14 fw-7">
                                    {getPrice(
                                      item.fiatPrice || item.price,
                                      item.fiatPrice ? 'KRW' : item.quote?.toUpperCase()
                                    )}
                                  </div>
                                </div>
                                <div className="wrapper-remaining text-center">
                                  <div className="remaining-header color-aaa fs-12">
                                    {item.isSoldOut || !item.remainingAmount ? null : 'Remaining'}
                                  </div>
                                  <div className={`quantity-remaining fs-14 fw-7 ${!item.remainingAmount ? '' : ''}`}>
                                    {!item.isSoldOut && item.remainingAmount ? item.remainingAmount : 'Sold Out'}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="hot-ollectibles-item">
                            <div className="wrapper-item">
                              <div className="content-left">
                                <div className="avatar">
                                  <img src={item.featured?.company?.image} alt="" draggable={false} />
                                </div>
                                <div className="name-label">{item.featured?.company?.name?.en}</div>
                              </div>
                              {/*<div className="content-right">{item.onsale ? 'Get Now' : 'Waiting'}</div>*/}
                            </div>
                          </div>
                          <div className="hot-ollectibles-item">
                            <div className={`product-name ${hasCJK(item.title.en) ? 'cjk-font' : ''}`}>
                              {item.title?.en}
                            </div>
                          </div>
                          {/* <div className="hot-ollectibles-item">
                          <div className="name-label">{item.details}</div>
                        </div> */}
                        </div>
                      </Link>
                    );
                  })}
              </Carousel>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Homepage;
