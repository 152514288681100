import React, { useEffect, useState } from 'react';
import useActiveWeb3React from '../../../hooks/useActiveWeb3React';
import { setEthereum, setActivatingConnector, setKlaytn } from '../../../redux/slices/wallet';
import {
  injected,
  kaikas,
  abc,
  // walletconnect,
  walletconnectv2,
  // talkenwallet,
} from '../../../hooks/connectors';
import Caver from 'caver-js';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CircleCheck } from '../../../assets/svg/check-circle-outline.svg';
import logo_talken_dark from '../../../assets/svg/logo_header.svg';
import logo_talken_light from '../../../assets/svg/logo_talken_blue.svg';
import logo_google from '../../../assets/icon/logo_google.png';
import logo_apple from '../../../assets/icon/logo_apple.png';
import logo_apple_black from '../../../assets/icon/logo_apple_black.png';
import logo_facebook from '../../../assets/icon/logo_facebook.png';
import logo_kakao from '../../../assets/icon/logo_kakao.png';
import icon_ethereum from 'assets/img/icon_ethereum.png';
import icon_ethereum_dark from 'assets/img/icon_ethereum_dark.png';
import icon_ethereum_light from 'assets/img/icon_ethereum_light.png';
import icon_klaytn from 'assets/img/icon_klaytn.png';
import icon_binance from 'assets/img/icon_binance.png';
import icon_polygon from 'assets/img/icon_polygon.png';
import icon_abc from 'assets/img/icon_abc.png';
import icon_metamask from 'assets/img/icon_metamask.png';
import talk from 'assets/img/icon_talk.png';
import IconABC from 'assets/icon/abcwallet.webp';
import klip from 'assets/icon/klip.png';
import dcent from 'assets/icon/Dcent.png';
import burrito from 'assets/icon/burrito.png';
import icon_kaikas_wallet from 'assets/img/icon_kaikas_wallet.png';
import icon_talk from 'assets/img/icon_talk.png';
import icon_walletconnect from 'assets/img/icon_walletconnect.png';
import { Link, useNavigate } from 'react-router-dom';
import { useThemeMode } from 'components/common/AppStoreType';
import { requestWalletLogin, requestNativeLogin } from '../../../services/services';
import { getUA, isMobile } from 'react-device-detect';
import ReactTooltip from 'react-tooltip';
import env from '../../../env';
import { ReactComponent as IconClose } from 'assets/icon/close_icon.svg';
import check_all_connect from '../../../assets/icon/check_all_connect.png';
import check_all_connect_light from '../../../assets/icon/check_all_connect_light.png';
import checked_all_connect from '../../../assets/icon/checked_all_connect.png';

declare global {
  interface Window {
    appAuthCallback: any;
    webkit: any;
    TalkenInterface: any;
    klaytn: any;
  }
}
const appInitialState = {
  osType: '',
  appName: '',
  appVersion: '',
};
const ConnectPage = () => {
  const [isCheck, setIsCheck] = useState(false);
  const navigate = useNavigate();
  const { isDarkMode } = useThemeMode();
  const dispatch = useDispatch();
  const { activate, deactivate, account, library } = useActiveWeb3React();
  const [doSign, setDoSign] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const redirectUrl = params.get('redirectUrl');
  const ref = params.get('ref');
  if (ref) window.localStorage.setItem('ref', ref);
  const appInfo = appInitialState;
  const ua = getUA;
  const arr = ua.split(' ');
  const osType = arr.find((el) => el.indexOf('osType:') > -1);
  const appName = arr.find((el) => el.indexOf('appName:') > -1);
  const appVersion = arr.find((el) => el.indexOf('appVersion:') > -1);
  if (osType) appInfo.osType = osType.split(':')[1];
  if (appName) appInfo.appName = appName.split(':')[1];
  if (appVersion) appInfo.appVersion = appVersion.split(':')[1];
  const isTalkenApp = appInfo.appName === 'Talken';

  const [walletName, setWalletName] = useState('');
  useEffect(() => {
    if (walletName !== '' && account !== '') {
      dispatch(setKlaytn({ wallet: walletName, address: account }));
    }
  }, [walletName, account]);

  const handleWalletLogin = async (id: number) => {
    // if (!isCheck) {
    //   alert('Please agree to the terms and privacy policy below.');
    //   return;
    // }
    setDoSign(true);
    try {
      // deactivate before handle wallet
      await deactivate();
      window.localStorage.removeItem('walletStatus');
      if (id === 0) {
        setWalletName('metamask');
        await activate(injected, undefined, true);
        dispatch(setActivatingConnector(injected));
        window.localStorage.setItem('login', 'metamask');
      } else if (id === 4) {
        // const wc = walletconnect(true);
        // await activate(wc, undefined, true);
        setWalletName('walletConnector');
        const wc2 = walletconnectv2(env.REACT_APP_TARGET_NETWORK_KLAY);
        await activate(wc2, undefined, true);
        window.localStorage.setItem('login', 'walletconnector');
      } else if (id === 1) {
        if (isTalkenApp) {
          // Talken App일 경우 메타마스크를 호출.
          await activate(injected, undefined, true);
          dispatch(setActivatingConnector(injected));
        } else {
          // const wc = talkenwallet(true);
          // await activate(wc, undefined, true);
          const wc2 = walletconnectv2(env.REACT_APP_TARGET_NETWORK_KLAY);
          await activate(wc2, undefined, true);
        }
      } else if (id === 2) {
        await activate(kaikas, undefined, true);
        await dispatch(setActivatingConnector(kaikas));
      } else if (id === 3) {
        await activate(abc, undefined, true);
        await dispatch(setActivatingConnector(abc));
      }
      // TODO: useEagerConnect()에서 지갑 유지에 필수
      window.localStorage.setItem('walletStatus', 'connected');
    } catch (error: any) {
      if (error.name === 'UnsupportedChainIdError') {
        if (error.message.includes('Unsupported chain id: 0.')) {
          console.log('!! user cancelled connection');
        } else {
          alert('Please check wallet Chain ID.');
        }
      } else alert(error.message);
    }
  };

  useEffect(() => {
    const walletLogin = async () => {
      if (!library || !doSign) return;
      const target_copy = Object.assign({}, library.provider);
      const isAbc = target_copy.isABC === true;
      const isKaikas = typeof target_copy._kaikas !== 'undefined';
      const chainId = await library.getSigner().getChainId();
      const message = `apps.talken.io wants you to sign in with your Ethereum account. Talken Drops Signature Request Type: Login request`;
      const domain = { chainId }; // All properties on a domain are optional
      const types = { Mail: [{ name: 'contents', type: 'string' }] };
      const mail = { contents: message }; // The data to sign
      const signature = await library
        .getSigner()
        ._signTypedData(domain, types, mail)
        .catch(() => deactivate());
      if (!signature) return; // 서명 거부
      const msgParams = JSON.stringify({ domain, types, mail });
      const data = { msgParams, signature };
      const res = await requestWalletLogin(data);
      if (res.data === 'loginSuccess') location.replace(redirectUrl || '/');
      else if (res.data === 'RedirectToRegister') location.replace('/accounts/register');
      // if (res.data === 'User not found!') {
      //   deactivate();
      //   alert('Please continue with SNS and register wallet address on My Profile page.');
      // }
      setDoSign(false);
    };

    walletLogin();
  }, [library]);
  const handleSnsLogin = async (snsType: string) => {
    // if (!isCheck) {
    //   alert('Please agree to the terms and privacy policy below.');
    //   return;
    // }
    const lang = 'en';
    window.localStorage.setItem('login', 'sns');
    // if (isTalkenApp) window.ethereum = undefined;
    if (isTalkenApp && snsType !== 'apple') {
      // native app 분기
      // Google, Facebook 동일하게{ callback: "appAuthCallback" }
      // apple은 native 분기를 태우지 않는다.
      const data = Object.assign({}, { type: snsType, locale: lang }, { callback: 'appAuthCallback' });
      const _data = JSON.stringify(data);
      if (appInfo.osType === 'IOS') {
        window.webkit.messageHandlers.auth.postMessage(_data);
      } else {
        window.TalkenInterface.auth(_data);
      }
    } else {
      window.location.href = `${process.env.REACT_APP_API_SERVER}/auth/${snsType}?redirectUrl=${redirectUrl || '/'}`;
    }
  };

  useEffect(() => {
    localStorage.removeItem('walletconnect');
    window.appAuthCallback = nativeAppAuthCallback;
    return () => {
      window.appAuthCallback = undefined;
    };
  }, []);
  const nativeAppAuthCallback = async (response: string) => {
    if (response) {
      const obj = JSON.parse(response);
      const res = await requestNativeLogin(obj);
      if (res.data === 'loginSuccess') location.replace('/');
      else if (res.data === 'notRegisterd') location.replace('/accounts/register');
      else alert('Native App Login Error!!');
    }
    console.log('No nativeAppAuthCallback response');
  };
  return (
    <div className="connect-page">
      <div className="logo-talken" onClick={() => navigate('/')}>
        {isDarkMode ? (
          <img src={logo_talken_dark} alt="Logo Talken" />
        ) : (
          <img src={logo_talken_light} alt="Logo Talken" />
        )}
      </div>
      <div className="form-connect position-relative">
        <div className="title-head">Welcome back</div>
        <div
          className="close-button-modal"
          onClick={() => {
            navigate('/');
          }}
        >
          <IconClose className="close-icon dark:close-icon" />
        </div>
        <div className="line w-92 w-xs-85"></div>
        <div className="wrapper-content-connect">
          <div className="title-content">Log In / Sign Up With </div>
          <div className="list-sns-connect">
            <div className="border-icon-sns">
              <div className="icon-sns" onClick={() => handleSnsLogin('google')}>
                <img src={logo_google} alt="Google Icon" />
              </div>
            </div>

            <div className="border-icon-sns">
              <div className="icon-sns" onClick={() => handleSnsLogin('apple')}>
                <img src={logo_apple_black} alt="Apple Icon" />
              </div>
            </div>

            <div className="border-icon-sns">
              <div className="icon-sns" onClick={() => handleSnsLogin('facebook')}>
                <img src={logo_facebook} alt="Facebook Icon" />
              </div>
            </div>
            {!isTalkenApp && (
              <div className="border-icon-sns">
                <div className="icon-sns" onClick={() => handleWalletLogin(0)}>
                  <img src={icon_metamask} alt="Metamask Icon" />
                </div>
              </div>
            )}

            {/* <Link to={'/accounts/kakao'}>
              <div className="icon-sns">
                <img src={logo_kakao} alt="Kakao Icon" />
              </div>
            </Link> */}
          </div>
          {!isTalkenApp && (
            <div className="line-or">
              <span className="title-line-or">Or Continue With</span>
            </div>
          )}
          {/* <div className="list-mainnet">
            <div className="box-mainnet" data-for="tooltip-comming-soon" data-tip>
              <div className="icon-mainnet">
                {isDarkMode ? <img src={icon_ethereum_dark} alt="" /> : <img src={icon_ethereum_light} alt="" />}
              </div>
            </div>
            <div className="box-mainnet" data-for="tooltip-comming-soon" data-tip>
              <div className="icon-mainnet">
                <img src={icon_binance} alt="" />
              </div>
            </div>
            <div className="box-mainnet" data-for="tooltip-comming-soon" data-tip>
              <div className="icon-mainnet">
                <img src={icon_polygon} alt="" />
              </div>
            </div>
            <div className="box-mainnet activate">
              <div className="icon-mainnet">
                <img src={icon_klaytn} alt="" />
              </div>
            </div>
            <ReactTooltip
              id="tooltip-comming-soon"
              // place="top"
              effect={'solid'}
              backgroundColor={isDarkMode ? 'white' : '#1f1f1f'}
              textColor={isDarkMode ? '#1f1f1f' : 'white'}
            >
              Coming soon..
            </ReactTooltip>
          </div> */}
          {isTalkenApp ? null : !isMobile ? (
            <>
              <div className="wallet-container">
                <div className="list-wallet">
                  <div className="box-item-wallet" onClick={() => handleWalletLogin(0)}>
                    <div className="icon-wallet">
                      <img src={icon_metamask} alt="MetaMask" />
                    </div>
                    <div className="name-wallet">MetaMask</div>
                  </div>
                  {/* <div className="box-item-wallet" onClick={() => handleWalletLogin(1)}>
              <div className="icon-wallet">
                <img src={icon_talk} alt="Talken Wallet" />
              </div>
              <div className="name-wallet">Talken Wallet</div>
            </div> */}
                  {/* <div className="box-item-wallet" onClick={() => handleWalletLogin(2)}>
              <div className="icon-wallet">
                <img src={icon_kaikas_wallet} alt="Kaikas" />
              </div>
              <div className="name-wallet">Kaikas</div>
            </div> */}
                  {/* <div className="box-item-wallet" onClick={() => handleWalletLogin(3)}>
              <div className="icon-wallet">
                <img src={icon_abc} alt="ABC Wallet" />
              </div>
              <div className="name-wallet">ABC Wallet</div>
            </div> */}
                  <div className="box-item-wallet" onClick={() => handleWalletLogin(4)}>
                    <div className="icon-wallet">
                      <img src={icon_walletconnect} alt="Icon WalletConnect" />
                    </div>
                    <div className="name-wallet">WalletConnect</div>
                  </div>
                  <div className="box-item-wallet" onClick={() => handleWalletLogin(0)}>
                    <div className="icon-wallet">
                      <img src={talk} alt="MetaMask" />
                    </div>
                    <div className="name-wallet">Talken Wallet</div>
                  </div>
                  <div className="box-item-wallet" onClick={() => handleWalletLogin(4)}>
                    <div className="icon-wallet">
                      <img src={IconABC} alt="Icon WalletConnect" />
                    </div>
                    <div className="name-wallet">ABC Wallet</div>
                  </div>
                  <div className="box-item-wallet" onClick={() => handleWalletLogin(4)}>
                    <div className="icon-wallet">
                      <img src={klip} alt="MetaMask" />
                    </div>
                    <div className="name-wallet">Klip</div>
                  </div>
                  <div className="box-item-wallet" onClick={() => handleWalletLogin(4)}>
                    <div className="icon-wallet">
                      <img src={dcent} alt="Icon WalletConnect" />
                    </div>
                    <div className="name-wallet">D'Cent</div>
                  </div>
                  <div className="box-item-wallet" onClick={() => handleWalletLogin(4)}>
                    <div className="icon-wallet">
                      <img src={burrito} alt="Icon WalletConnect" />
                    </div>
                    <div className="name-wallet">Burrito</div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="wallet-container">
              <div className="list-wallet-clone variant-1">
                <div className="box-item-wallet" onClick={() => handleWalletLogin(0)}>
                  <div className="icon-wallet">
                    <img src={icon_metamask} alt="MetaMask" />
                  </div>
                  <div className="name-wallet">MetaMask</div>
                </div>
                <div className="box-item-wallet" onClick={() => handleWalletLogin(4)}>
                  <div className="icon-wallet">
                    <img src={icon_walletconnect} alt="Icon WalletConnect" />
                  </div>
                  <div className="name-wallet">WalletConnect</div>
                </div>
                <div className="box-item-wallet" onClick={() => handleWalletLogin(0)}>
                  <div className="icon-wallet">
                    <img src={talk} alt="MetaMask" />
                  </div>
                  <div className="name-wallet">Talken Wallet</div>
                </div>
                <div className="box-item-wallet" onClick={() => handleWalletLogin(4)}>
                  <div className="icon-wallet">
                    <img src={IconABC} alt="Icon WalletConnect" />
                  </div>
                  <div className="name-wallet">ABC Wallet</div>
                </div>
                <div className="box-item-wallet" onClick={() => handleWalletLogin(4)}>
                  <div className="icon-wallet">
                    <img src={klip} alt="MetaMask" />
                  </div>
                  <div className="name-wallet">Klip</div>
                </div>
                <div className="box-item-wallet" onClick={() => handleWalletLogin(4)}>
                  <div className="icon-wallet">
                    <img src={dcent} alt="Icon WalletConnect" />
                  </div>
                  <div className="name-wallet">D'Cent</div>
                </div>
                <div className="box-item-wallet" onClick={() => handleWalletLogin(4)}>
                  <div className="icon-wallet">
                    <img src={burrito} alt="Icon WalletConnect" />
                  </div>
                  <div className="name-wallet">Burrito</div>
                </div>
              </div>
            </div>
          )}
          <div className="line"></div>
          <div
            className="d-flex gap-6px lh-28 align-item-center hover-cusor-pointer"
            onClick={() => setIsCheck(!isCheck)}
          >
            {/* <CircleCheck
              className={`w-100 max-w-20px max-w-xs-16px fs-12 color-reward lh-14 ${isCheck && 'svg-color-525298'}`}
            /> */}
            {/* <div className="icon-check-all hover-cusor-pointer" onClick={() => setIsCheck(!isCheck)}>
              {isCheck ? (
                <img src={checked_all_connect} className="w-24px w-xs-18px" alt="Check All" />
              ) : isDarkMode ? (
                <img src={check_all_connect} className="w-24px w-xs-18px" alt="Check All" />
              ) : (
                <img src={check_all_connect_light} className="w-24px w-xs-18px" alt="Check All" />
              )}
            </div> */}
            <div className="fs-12 lh-14 color-dbdbdb">
              By connecting a wallet, you agree to Talken's{' '}
              <Link to={'/terms-conditions'} className="color-inherit text-decoration-underline hover-opa-7">
                Terms of service
              </Link>{' '}
              and consent to its{' '}
              <Link to={'/privacy-policy'} className="color-inherit text-decoration-underline hover-opa-7">
                Privacy policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectPage;
