import axios from 'axios';
import env from '../env';
import { ethers } from 'ethers';
import Caver from 'caver-js';
import getSelectedNodeUrl from '../../../../utils/getRpcUrl';

export async function getGasPriceFromAPI(rpcUrl: string, target: number) {
    const caver = new Caver(rpcUrl);
    // const target = localStorage.getItem('target');
    // const target = env.REACT_APP_TARGET_NETWORK;

    const provider = ethers.getDefaultProvider(rpcUrl);

    let gasPrice = '';

    if (target === 1001 || target === 8217) {
        gasPrice = await caver.rpc.klay.getGasPrice();
        // } else if (target === 80001) {
        //     const result = await axios.get('https://gasstation-testnet.polygon.technology/v2');
        //     gasPrice = ethers.utils.parseUnits(result.data.fast.maxFee.toFixed(5).toString(), 'gwei').toHexString();
        // } else if (target === 137) {
        //     const result = await axios.get('https://gasstation.polygon.technology/v2');
        //     gasPrice = ethers.utils.parseUnits(result.data.fast.maxFee.toFixed(5).toString(), 'gwei').toHexString();
    } else if (
        target === 56 ||
        target === 97 ||
        target === 1 ||
        target === 11155111 ||
        target === 80002 ||
        target === 137
    ) {
        const result = await provider.getGasPrice();
        gasPrice = result.toHexString();
    }
    console.log('!! getGasPriceFromAPI getPrice = ', gasPrice);
    return gasPrice;
}
