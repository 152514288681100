import { useThemeMode } from 'components/common/AppStoreType';
import ReactModal from 'react-modal';
import close_icon from '../../assets/icon/close_icon.svg';
import close_icon_black from '../../assets/icon/close_icon_black.svg';
import klay from '../../assets/icon/klaytn.png';
import polygon from '../../assets/icon/polygon.svg';
import binance from 'assets/img/icon_binance.png';
import eth from '../../assets/icon/eth.png';
import talkKlaytn from '../../assets/icon/talkKlaytn.svg';
import talkPolygon from '../../assets/icon/talkPolygon.svg';
import talkBinance from '../../assets/icon/talkBinance.svg';
import talk from '../../talkclaim/talk.png';
import Select, { components } from 'react-select';
import { useMediaQuery } from 'react-responsive';
import useScreenSize from 'components/common/useScreenSize';
import { ReactComponent as Link } from '../../assets/icon/link.svg';
import React, { useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import klaytn from '../../assets/icon/klaytn.png';
import env from '../../env';
import { SCAN_URL } from '../../config';
import { Typography } from '@mui/material';

type TalkenBridgeSuccess = {
  show: any;
  onHide: any;
  typeModal: string;
  openWinningComplete: any;
  data: any;
  setReload?: any;
};
const TalkenBridgeSuccess: React.FC<TalkenBridgeSuccess> = ({
  show,
  onHide,
  typeModal,
  openWinningComplete,
  data,
  setReload,
}) => {
  console.log('hahahaha', data);

  const { isDarkMode } = useThemeMode();
  const screenSize = useScreenSize();
  const isMobile = useMediaQuery({
    query: '(max-width: 641px)',
  });
  const stylesPayType = {
    control: (base, state) => ({
      ...base,
      background: 'transparent',
      borderRadius: isMobile ? '6px' : '10px',
      borderColor: state.isFocused ? '#CCCCCC' : '#CCCCCC',
      boxShadow: state.isFocused ? 'none' : 'none',
      fontSize: isMobile ? '11px' : '14px',
      fontWeight: 500,
      width: '100%',
      height: isMobile ? '32px' : '48px',
      minHeight: isMobile ? '32px' : '38px',
      cursor: 'pointer',
    }),
    singleValue: (base) => ({
      ...base,
      color: isDarkMode ? '#FFFFFF' : '#000000',
    }),
    valueContainer: (base) => ({
      ...base,
      height: '100%',
      padding: isMobile ? '8px' : '2px 8px',
    }),
    option: (base, state) => ({
      ...base,
      fontSize: isMobile ? '11px' : '14px',
      fontWeight: 500,
      width: '100%',

      color: state.isSelected ? '#944FFF' : '#8C8C8C',
      backgroundColor: state.isSelected ? '#F0F5FF' : '#FFFFFF',
      ':active': {
        // backgroundColor: state.isSelected ? '#FFFFFF' : '#FFFFFF',
      },
      ':hover': {
        backgroundColor: '#944FFF',
        color: '#FFFFFF',
      },
      cursor: 'pointer',
    }),

    menu: (base) => ({
      ...base,
      fontSize: isMobile ? '11px' : '14px',
      background: 'white',
      borderRadius: isMobile ? '6px' : '10px',
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      fontSize: '12px',
      padding: '0',
      borderRadius: isMobile ? '6px' : '10px',
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: isDarkMode ? '#FFFFFF' : '#000000',
      placeContent: 'center',
      padding: isMobile ? '4px' : '8px',
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
  };
  const [selectedOptionFrom, setSelectedOptionFrom] = useState(data?.from);
  const getDefaultSelectedOptionTo = (selectedOptionFrom) => {
    return selectedOptionFrom === 0 ? 1 : 0;
  };
  const [selectedOptionTo, setSelectedOptionTo] = useState(data?.to);

  const options = [
    { value: 0, label: 'ETHEREUM', customAbbreviation: eth },
    { value: 1, label: 'POLYGON', customAbbreviation: polygon },
    { value: 2, label: 'KLAYTN', customAbbreviation: klay },
    { value: 3, label: 'BINANCE', customAbbreviation: binance },
  ];
  const chains = [
    env.REACT_APP_TARGET_NETWORK_ETH,
    env.REACT_APP_TARGET_NETWORK_MATIC,
    env.REACT_APP_TARGET_NETWORK_KLAY,
    env.REACT_APP_TARGET_NETWORK_BNB,
  ];

  const formatOptionLabel = ({ value, label, customAbbreviation }) => (
    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      <img style={{ width: '20px', height: '20px' }} src={customAbbreviation} className="rounded-5"></img>
      <div>{label}</div>
    </div>
  );
  const handleConfirmModal = async () => {
    setReload(true);
    openWinningComplete();
    onHide();
  };
  const handleTypeSelect = (e) => {
    setSelectedOptionFrom(e.value);
  };
  const CustomSelectControl = (props) => <components.Control {...props}>{props.children}</components.Control>;
  const NoDropdownIndicator = () => null;

  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className="otp-code-modal otp-code-modal-cust-2">
        <div className="close-button" onClick={onHide}>
          {isDarkMode ? <img src={close_icon} alt="icon close" /> : <img src={close_icon_black} alt="icon close" />}
        </div>
        <div className="header-otp">Successful</div>
        <div className="line-banner"></div>
        <div className="body-otp">
          <div className="title-input-otp">From chain</div>
          <div className={`${screenSize < 641 ? 'mb-4' : 'mb-36'}`}>
            <Select
              formatOptionLabel={formatOptionLabel}
              options={options}
              styles={stylesPayType}
              isSearchable={false}
              onChange={handleTypeSelect}
              isDisabled={true}
              value={options.find((option) => option.value === selectedOptionFrom) || options[0]}
              placeholder={'Select Token'}
              components={{
                Control: CustomSelectControl,
                DropdownIndicator: NoDropdownIndicator,
              }}
            />
          </div>
          <div className="title-input-otp">To chain</div>
          <div className={`${screenSize < 641 ? 'mb-4' : 'mb-36'}`}>
            <Select
              formatOptionLabel={formatOptionLabel}
              options={options}
              styles={stylesPayType}
              isSearchable={false}
              onChange={(selectedOptionFrom) => {
                setSelectedOptionTo(getDefaultSelectedOptionTo(selectedOptionFrom));
              }}
              isDisabled={true}
              value={options.find((option) => option.value === selectedOptionTo) || options[0]}
              placeholder={'Select Token'}
              components={{
                Control: CustomSelectControl,
                DropdownIndicator: NoDropdownIndicator,
              }}
            />
          </div>
          <div className="title-input-otp mb-1">Recipient</div>
          <div className={`wrapper-input-otp p-0 ${screenSize < 641 ? 'mb-4' : 'mb-36'}`}>
            <input type="text" placeholder="Recipient" value={data?.address} disabled={true} />
          </div>
          <div className="title-input-otp mb-1">Amount</div>
          <div className={`wrapper-input-success p-0 ${screenSize < 641 ? 'mb-4' : 'mb-36'}`}>
            <div className="wrapper-input-success-icon-plholder">
              {selectedOptionTo == 1 &&
                (isMobile ? (
                  <img width={20} height={20} className="m-r-10" src={talkPolygon} alt="" />
                ) : (
                  <img width={24} height={24} className="m-r-10" src={talkPolygon} alt="" />
                ))}
              {selectedOptionTo == 2 &&
                (isMobile ? (
                  <img width={20} height={20} className="m-r-10" src={talkKlaytn} alt="" />
                ) : (
                  <img width={24} height={24} className="m-r-10" src={talkKlaytn} alt="" />
                ))}
              {selectedOptionTo == 3 &&
                (isMobile ? (
                  <img width={20} height={20} className="m-r-10" src={talkBinance} alt="" />
                ) : (
                  <img width={24} height={24} className="m-r-10" src={talkBinance} alt="" />
                ))}
            </div>
            <input type="text" placeholder="Amount" value={data?.amount} disabled={true} />
          </div>
          {/*<div className="title-input-otp mb-1">Recipient wallet Address</div>*/}
          {/*<div className={`wrapper-input-otp p-0 ${screenSize < 641 ? 'mb-4' : 'mb-36'}`}>*/}
          {/*  <input type="text" placeholder="Enter the wallet adress" value={data?.address} disabled={true} />*/}
          {/*</div>*/}
          <div className="title-input-otp mb-1">TXID</div>
          <div className={`wrapper-input-otp position-relative p-0 ${screenSize < 641 ? 'mb-4' : 'mb-36'}`}>
            <input
              type="text"
              placeholder="TXID"
              value={data?.txid?.slice(0, 11) + '...' + data?.txid?.slice(-9)}
              disabled={true}
            />
            <button
              className="border-0 rounded-circle btn-circle m-r-10 position-absolute left-93 left-xs-90 bg-box-modal"
              onClick={() => window.open(`${SCAN_URL[chains[selectedOptionFrom]]}tx/${data?.txid}`)}
            >
              <Link />
            </button>
          </div>
          <button className="button m-0 btn-detail-nft" onClick={handleConfirmModal}>
            Confirm
          </button>
          {/*<Typography align={'center'}>참고 : 브리지 성공 후 잔고 확인에 약 1분 정도 소요됩니다.</Typography>*/}
        </div>
      </div>
    </ReactModal>
  );
};
export default TalkenBridgeSuccess;
