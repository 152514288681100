import ReactModal from 'react-modal';
import { ReactComponent as IconClose } from 'assets/icon/close_icon.svg';

type WinningBidComplete = {
  show: any;
  onHide: any;
};
const WinningBidComplete: React.FC<WinningBidComplete> = ({ show, onHide }) => {
  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className="modal-primary w-400px w-xs-338px h-542px h-xs-370px">
        <div className="close-button-modal" onClick={onHide}>
          <IconClose className="svg-color dark:svg-color" />
        </div>
        <div className="fs-24 fs-xs-16 p-t-xs-20 p-b-xs-0 p-b-10 p-t-26 fw-bold text-center">Complete</div>
        <div className="line-banner"></div>
        <div className="p-t-xs-12 p-b-xs-28 p-l-xs-20 p-r-xs-20 p-b-30 p-l-30 p-r-30">
          <div className="p-b-xs-16 p-b-24">
            <div className="p-b-12 p-b-xs-8 fs-xs-10">Price</div>
            <div className="border-radius-10 border-radius-xs-6 p-t-xs-8 p-b-xs-8 p-l-xs-16 p-r-xs-16 p-l-20 p-r-20 p-t-12 p-b-12 h-xs-32 h-48 d-flex justify-content-start align-items-center gap-10px bg-input-modal">
              <div className="fw-6 fs-xs-12 fw-xs-7">1200 KLAY</div>
              <div className="fs-16 fs-xs-12 title-input-modal">$123</div>
            </div>
          </div>
          <div className="p-b-xs-16 p-b-24">
            <div className="p-b-12 p-b-xs- fs-xs-10">Floor Difference</div>
            <div className="border-radius-10 p-t-xs-8 p-b-xs-8 p-l-xs-16 p-r-xs-16 p-l-20 p-r-20 p-t-12 p-b-12 h-xs-32 h-48 bg-input-modal">
              <div className="fw-6 fs-xs-12 fw-xs-7">17% above</div>
            </div>
          </div>
          <div className="">
            <div className="p-b-12 p-b-xs-8 fs-xs-10">From</div>
            <div className="border-radius-10 p-t-xs-8 p-b-xs-8 p-l-xs-16 p-r-xs-16 p-l-20 p-r-20 p-t-12 p-b-12 h-xs-32 h-48 bg-input-modal">
              <div className="fw-6 fs-xs-12 fw-xs-7">0x123...12312</div>
            </div>
          </div>
          <button className="btn-detail-nft button" onClick={onHide}>
            Confirm
          </button>
        </div>
      </div>
    </ReactModal>
  );
};
export default WinningBidComplete;
