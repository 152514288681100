import { useThemeMode } from 'components/common/AppStoreType';
import ReactModal from 'react-modal';
import close_icon from '../../assets/icon/close_icon.svg';
import close_icon_black from '../../assets/icon/close_icon_black.svg';
import close_icon_8787d6 from '../../assets/icon/close_icon_8787d6.svg';
import { Col, Container, Row } from 'react-bootstrap';
import polygon from '../../assets/icon/polygon.png';
import { ReactComponent as Link } from '../../assets/icon/link2.svg';
import { ReactComponent as Copy } from '../../assets/icon/copy2.svg';
import useCopyToClipBoard from 'hooks/useCopyToClipboard';
import CSnackbar from 'components/common/CSnackbar';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import useScreenSize from 'components/common/useScreenSize';

type OTPCodeModal = {
  show: any;
  onHide: any;
};
const WalletDetailModal = ({ show, onHide, data }) => {
  const { isDarkMode } = useThemeMode();
  const { copyToClipBoard, copyResult, setCopyResult } = useCopyToClipBoard();
  const [isOpenTransfer, setIsOpenTransfer] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    type: '',
    message: '',
  });
  const handleCloseSnackbar = () => {
    setOpenSnackbar({
      open: false,
      type: 'info',
      message: '',
    });
    setCopyResult(false);
  };
  const handleLink = (url) => {
    window.open(url);
  };
  useEffect(() => {
    setOpenSnackbar({
      open: copyResult,
      type: 'success',
      message: 'Copied!',
    });
  }, [copyResult]);
  const screenSize = useScreenSize();
  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className="wallet-detail-modal">
        <div className="close-button" onClick={onHide}>
          {isDarkMode ? (
            <img src={close_icon_8787d6} alt="icon close" />
          ) : (
            <img src={close_icon_black} alt="icon close" />
          )}
        </div>
        <div className="wrapper-wallet-detail p-24 gap-0-16">
          <div className="header-wallet">My Wallet</div>
          <div className="line-my-wallet"></div>
          <div className="fs-12 fw-400 lh-20 lh-xs-inherit color-reward m-b-24 m-b-xs-16">
            To receive assets in my wallet, please send them to the address below.
          </div>
          <div className="d-flex flex-column fs-16 fw-600 gap-3">
            <div className="bg-accordion-body p-16 p-l-24 p-r-24 p-l-xs-16 p-r-xs-16 rounded-3">
              <div className="d-grid justify-content-center">
                <img width={40} height={40} src={data?.img_url} alt="" />
              </div>
              <div className="fs-16 lh-18 p-b-24 p-r-0 p-t-16 word-break text-center">
                {data?.token?.slice(0, 17) +
                  '...' +
                  (screenSize > 768 ? data?.token?.slice(-15) : data?.token?.slice(-10))}
              </div>
              <div className="p-0 d-flex justify-content-center gap-8px">
                <div className="p-t-2 circle hover-text-bg-primary" onClick={() => handleLink(data?.linkTo)}>
                  <Link className="align-self-center hover-cusor-pointer" />
                </div>
                <div className="circle hover-text-bg-primary" onClick={() => copyToClipBoard(data?.token)}>
                  <Copy className="align-self-center hover-cusor-pointer" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CSnackbar
        open={openSnackbar.open}
        type={openSnackbar.type}
        message={openSnackbar.message}
        handleClose={handleCloseSnackbar}
      />
    </ReactModal>
  );
};
export default WalletDetailModal;
