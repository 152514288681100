type ENV = {
  REACT_APP_API_SERVER: string;
  REACT_APP_ALT_URL?: string;
  REACT_APP_TARGET_NETWORK_KLAY: number;
  REACT_APP_TARGET_NETWORK_ETH: number;
  REACT_APP_TARGET_NETWORK_BNB: number;
  REACT_APP_TARGET_NETWORK_SOL?: number;
  REACT_APP_TARGET_NETWORK_MATIC?: number;
  REACT_APP_TARGET_NETWORK_MSG?: string;
  REACT_APP_MBOX_FEE?: number;
  REACT_APP_TREASURY?: string;
  //abc
  REACT_APP_TARGET_NETWORK: number;
  REACT_APP_API_URL: string;
  MPC_PROTOCOL: string;
  APP_SERVER_ADDRESS_PROTOCOL: string;
  APP_SERVER_ADDRESS: string;
  ABC_SERVER_ADDRESS: string;
  ABC_SERVICE_ID: string;
  INFURA_ID: string | undefined;
  ALCHEMY_ID: string | undefined;
  KLAYTN_API_USERNAME: string | undefined;
  KLAYTN_API_PASSWORD: string | undefined;
  KLAYTN_AUTH_TOKEN: string | undefined;
  ETHERSCAN_API_KEY: string | undefined;
  BSCSCAN_API_KEY: string | undefined;
  POLIGONSCAN_API_KEY: string | undefined;
  ISS: string;
  SECURE_LOCAL_STORAGE_HASH_KEY: string | undefined;
};

const development: ENV = {
  REACT_APP_API_SERVER: 'http://localhost:5001',
  // REACT_APP_API_SERVER: 'https://dev.nftapi.talken.io',
  REACT_APP_TARGET_NETWORK_KLAY: 1001, // TMP
  REACT_APP_TARGET_NETWORK_ETH: 11155111,
  REACT_APP_TARGET_NETWORK_BNB: 97,
  // REACT_APP_TARGET_NETWORK_SOL:
  REACT_APP_TARGET_NETWORK_MATIC: 80002,
  REACT_APP_TREASURY: '0x22ae69E7b2DE09548B6b15b5D92D2b04De5592d9',
  //abc
  REACT_APP_TARGET_NETWORK: 1001, // baobab
  REACT_APP_TARGET_NETWORK_MSG: '',
  REACT_APP_API_URL: 'http://localhost:5001',
  MPC_PROTOCOL: 'wss',
  APP_SERVER_ADDRESS_PROTOCOL: 'https',
  APP_SERVER_ADDRESS: 'cs.dev-mw.myabcwallet.com:9000',
  ABC_SERVER_ADDRESS: 'dev-api.id.myabcwallet.com',
  ABC_SERVICE_ID: 'https://mw.myabcwallet.com',
  INFURA_ID: process.env.REACT_APP_INFURA_ID,
  ALCHEMY_ID: process.env.REACT_APP_ALCHEMY_ID,
  KLAYTN_API_USERNAME: process.env.REACT_APP_KLAYTN_API_USERNAME,
  KLAYTN_API_PASSWORD: process.env.REACT_APP_KLAYTN_API_PASSWORD,
  KLAYTN_AUTH_TOKEN: process.env.REACT_APP_KLAYTN_API_PASSWORD,
  ETHERSCAN_API_KEY: process.env.REACT_APP_ETHERSCAN_API_KEY,
  BSCSCAN_API_KEY: process.env.REACT_APP_BSCSCAN_API_KEY,
  POLIGONSCAN_API_KEY: process.env.REACT_APP_POLIGONSCAN_API_KEY,
  ISS: 'abc',
  SECURE_LOCAL_STORAGE_HASH_KEY: process.env.REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY,
};

const staging: ENV = {
  // REACT_APP_API_SERVER: 'http://localhost:5001',
  REACT_APP_API_SERVER: 'https://dev.nftapi.talken.io',
  REACT_APP_TARGET_NETWORK_KLAY: 1001, // TMP
  REACT_APP_TARGET_NETWORK_ETH: 5,
  REACT_APP_TARGET_NETWORK_BNB: 97,
  // REACT_APP_TARGET_NETWORK_SOL:
  REACT_APP_TARGET_NETWORK_MATIC: 80002,
  REACT_APP_TREASURY: '0x22ae69E7b2DE09548B6b15b5D92D2b04De5592d9',
  //abc
  REACT_APP_TARGET_NETWORK: 1001, // baobab
  REACT_APP_TARGET_NETWORK_MSG: '',
  REACT_APP_API_URL: 'https://dev.api.wayb.io',
  MPC_PROTOCOL: 'wss',
  APP_SERVER_ADDRESS_PROTOCOL: 'https',
  APP_SERVER_ADDRESS: 'cs.dev-mw.myabcwallet.com:9000',
  ABC_SERVER_ADDRESS: 'dev-api.id.myabcwallet.com',
  ABC_SERVICE_ID: 'https://mw.myabcwallet.com',
  INFURA_ID: process.env.REACT_APP_INFURA_ID,
  ALCHEMY_ID: process.env.REACT_APP_ALCHEMY_ID,
  KLAYTN_API_USERNAME: process.env.REACT_APP_KLAYTN_API_USERNAME,
  KLAYTN_API_PASSWORD: process.env.REACT_APP_KLAYTN_API_PASSWORD,
  KLAYTN_AUTH_TOKEN: process.env.REACT_APP_KLAYTN_API_PASSWORD,
  ETHERSCAN_API_KEY: process.env.REACT_APP_ETHERSCAN_API_KEY,
  BSCSCAN_API_KEY: process.env.REACT_APP_BSCSCAN_API_KEY,
  POLIGONSCAN_API_KEY: process.env.REACT_APP_POLIGONSCAN_API_KEY,
  ISS: 'abc',
  SECURE_LOCAL_STORAGE_HASH_KEY: process.env.REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY,
};

const production: ENV = {
  REACT_APP_API_SERVER: 'https://nftapi.talken.io',
  REACT_APP_TARGET_NETWORK_KLAY: 8217, // TMP
  REACT_APP_TARGET_NETWORK_ETH: 1,
  REACT_APP_TARGET_NETWORK_BNB: 56,
  // REACT_APP_TARGET_NETWORK_SOL:
  REACT_APP_TARGET_NETWORK_MATIC: 137,
  REACT_APP_TREASURY: '0x1424C82f9Cb4BeeaD8294087aa731A0c4FB2767D',
  //abc
  REACT_APP_TARGET_NETWORK: 8217, // mainnet
  REACT_APP_TARGET_NETWORK_MSG: '',
  REACT_APP_API_URL: 'https://api.wayb.io',
  MPC_PROTOCOL: 'wss',
  APP_SERVER_ADDRESS_PROTOCOL: 'https',
  APP_SERVER_ADDRESS: 'cs.mw.myabcwallet.com:9000',
  ABC_SERVER_ADDRESS: 'api.id.myabcwallet.com',
  ABC_SERVICE_ID: 'https://mw.myabcwallet.com',
  INFURA_ID: process.env.REACT_APP_INFURA_ID,
  ALCHEMY_ID: process.env.REACT_APP_ALCHEMY_ID,
  KLAYTN_API_USERNAME: process.env.REACT_APP_KLAYTN_API_USERNAME,
  KLAYTN_API_PASSWORD: process.env.REACT_APP_KLAYTN_API_PASSWORD,
  KLAYTN_AUTH_TOKEN: process.env.REACT_APP_KLAYTN_API_PASSWORD,
  ETHERSCAN_API_KEY: process.env.REACT_APP_ETHERSCAN_API_KEY,
  BSCSCAN_API_KEY: process.env.REACT_APP_BSCSCAN_API_KEY,
  POLIGONSCAN_API_KEY: process.env.REACT_APP_POLIGONSCAN_API_KEY,
  ISS: 'abc',
  SECURE_LOCAL_STORAGE_HASH_KEY: process.env.REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY,
};

const env =
  process.env.REACT_APP_PHASE === 'staging'
    ? staging
    : process.env.REACT_APP_PHASE === 'production'
    ? production
    : development;

export default env;
