import { useState } from 'react';

const useCopyToClipBoard = () => {
  const [copyDone, setCopyDone] = useState(false);
  const [copyResult, setCopyResult] = useState(false);
  const [copyMessage, setCopyMessage] = useState('');

  const copyToClipBoard = async (copyMe: string | string[] | null | undefined) => {
    try {
      setCopyDone(false);
      setCopyResult(false);
      if (copyMe) {
        let formattedText = '';
        if (Array.isArray(copyMe)) {
          formattedText = copyMe.join('\n'); // Join array elements with line breaks
        } else {
          formattedText = copyMe;
        }
        await navigator.clipboard.writeText(formattedText);
        setCopyResult(true);
        setCopyMessage(`'${formattedText}' Copied!`);
      }
    } catch (err) {
      setCopyResult(false);
      setCopyMessage('Failed to copy!');
    } finally {
      setCopyDone(true);
    }
  };

  return {
    copyToClipBoard,
    copyResult,
    copyMessage,
    copyDone,
    setCopyDone,
    setCopyResult,
  };
};

export default useCopyToClipBoard;
