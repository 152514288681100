export enum AbcMobileService {
    MW_ANDROID = 4,
    MW_IOS = 8,
    EXT = 16,
}

export enum AbcLanguage {
    KOR = 1,
    EN = 2,
    JA = 4,
}
