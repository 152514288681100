import ReactModal from 'react-modal';
import { ReactComponent as IconClose } from 'assets/icon/close_icon.svg';
import iconTest from 'assets/img/home_10_avt.jpg';
import useScreenSize from 'components/common/useScreenSize';
import Select from 'react-select';
import { useMediaQuery } from 'react-responsive';
import { useThemeMode } from 'components/common/AppStoreType';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ethers } from 'ethers';
import { RPC_URLS, SUCCESS } from '../../config';
import { seaport } from '../../utils/seaport';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import getAbcWallet from '../../utils/getAbcWallet';
import HelperUtil from '../../utils/HelperUtil';
import env from '../../env';
import { cancelSell, registerSell } from '../../services/services';
import { CircularProgress } from '@mui/material';
import contracts from '../../config/constants/contracts';
import { useSelector } from 'react-redux';
import { getActiveWallet } from '../../utils/getActiveWallet';
import { useNavigate } from 'react-router-dom';
import { useCountdown } from 'hooks/useCountdown';
import { setupNetwork } from '../../utils/wallet';
import { HandleMediaError } from 'components/common/HandleMediaError';

type EditListing = {
  show: any;
  onHide: any;
  typeSale: string;
  confirm: any;
  cancel: any;
  dataDetailMyItems?: any;
  openError?: any;
  isEmptyBids?: boolean;
};
const optionsMinTypeMoneyAuction = [{ value: 'talk', label: 'TALK' }];
const optionsMinTypeMoneyFixed = {
  ['ethereum']: [
    { value: 'eth', label: 'ETH' },
    { value: 'talk', label: 'TALK' },
  ],
  ['polygon']: [
    { value: 'matic', label: 'MATIC' },
    { value: 'talk', label: 'TALK' },
  ],
  ['klaytn']: [
    { value: 'klay', label: 'KLAY' },
    { value: 'talk', label: 'TALK' },
  ],
  ['binance']: [
    { value: 'bnb', label: 'BNB' },
    { value: 'talk', label: 'TALK' },
  ],
};
const optionsMonth = [
  { value: '1month', label: '1 Month' },
  { value: '2month', label: '2 Month' },
  { value: '3month', label: '3 Month' },
  { value: '4month', label: '4 Month' },
  { value: '5month', label: '5 Month' },
  { value: '6month', label: '6 Month' },
];
const CountDownEditListing = ({ endDate, handleFinish }: any) => {
  const [days, hours, minutes, seconds, timeTotal] = useCountdown(endDate);

  useEffect(() => {
    if (timeTotal <= 0) handleFinish();
  }, [timeTotal]);

  if (days + hours + minutes + seconds <= 0) {
    return <div className="fw-6 color-secondary fs-14 fs-xs-12 lh-16 lh-xs-inherit">The period has ended.</div>;
  } else {
    return (
      <>
        {!isNaN(seconds) && (
          <div className="fw-6 color-secondary fs-14 fs-xs-12 lh-16 lh-xs-inherit">
            {days} days {hours} hours {minutes} Minutes left
          </div>
        )}
      </>
    );
  }
};

const EditListing: React.FC<EditListing> = ({
  show,
  onHide,
  typeSale,
  confirm,
  cancel,
  dataDetailMyItems,
  isEmptyBids,
  openError,
}) => {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user.user);
  const uid = user.uid;
  const { account, chainId, library } = useActiveWeb3React();
  const { isAbcReady, abcAddress } = getAbcWallet();
  const myWallet = getActiveWallet();
  const [isCheckedSwitch, setIsCheckedSwitch] = useState(true);
  const screenSize = useScreenSize();
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });
  const { isDarkMode } = useThemeMode();

  const [endDate, setEndDate] = useState('');
  const [endTime, setEndTime] = useState('');
  const login = window.localStorage.getItem('login');
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [endEpoch, setEndEpoch] = useState(0);
  const [quote, setQuote] = useState('');
  const [countDownFinish, setCountDownFinish] = useState(false);

  const [chain, setChain] = useState(dataDetailMyItems?.sellbook?.nftInfo.chain ?? 'klaytn');

  const [target, setTarget] = useState(0);
  useEffect(() => {
    switch (dataDetailMyItems?.sellbook?.nftInfo?.chain) {
      case 'ethereum':
        setTarget(env.REACT_APP_TARGET_NETWORK_ETH);
        break;
      case 'klaytn':
        setTarget(env.REACT_APP_TARGET_NETWORK_KLAY);
        break;
      case 'binance':
        setTarget(env.REACT_APP_TARGET_NETWORK_BNB);
        break;
      case 'polygon':
        setTarget(env.REACT_APP_TARGET_NETWORK_MATIC);
        break;
    }
  }, [dataDetailMyItems]);

  useEffect(() => {
    const strEndDate = new Date(dataDetailMyItems?.sellbook?.endDate).toLocaleDateString();
    const strEndTime = new Date(dataDetailMyItems?.sellbook?.endDate).toLocaleTimeString();

    setEndDate(strEndDate);
    setEndTime(strEndTime);
    setQuote(dataDetailMyItems?.sellbook?.quote);
    setChain(dataDetailMyItems?.sellbook?.nftInfo.chain);
    setStartPrice(dataDetailMyItems?.sellbook?.price);
  }, [dataDetailMyItems]);

  const [startPrice, setStartPrice] = useState('0.0');
  const [minIncrement, setMinIncrement] = useState('0.0');
  const getTargetChainId = () => {
    switch (dataDetailMyItems.sellbook?.nftInfo?.chain) {
      case 'ethereum':
        return env.REACT_APP_TARGET_NETWORK_ETH;
      case 'binance':
        return env.REACT_APP_TARGET_NETWORK_BNB;
      case 'polygon':
        return env.REACT_APP_TARGET_NETWORK_MATIC;
      case 'klaytn':
      default:
        return env.REACT_APP_TARGET_NETWORK_KLAY;
    }
  };
  const [targetChain, setTargetChain] = useState(getTargetChainId());
  useEffect(() => {
    const targetChain = getTargetChainId();
    setTargetChain(targetChain);
  }, [dataDetailMyItems]);

  const switchNetwork = async () => {
    if (login !== 'sns') {
      await setupNetwork(targetChain, true, onHide);
    }
  };
  useEffect(() => {
    switchNetwork();
  }, [targetChain]);

  const handleInputPrice = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, '').slice(0, 6);
    if (numericValue < 0) {
      alert('Price should be greater than zero!!');
      return;
    }
    setStartPrice(inputValue);
  };
  const handleInputBlur = () => {
    console.log('Input lost focus. Do something here.');
  };

  const handleInputMinIncreament = (event) => {
    setMinIncrement(event.target.value);
  };
  const handleQuote = (selected) => {
    setQuote(selected.value.toUpperCase());
  };
  const handleConfirmModal = async () => {
    if (Number(startPrice) <= 0) {
      alert('Cannot set price <= 0');
      setStartPrice(dataDetailMyItems?.sellbook?.price);
      return;
    }
    if (!isEmptyBids) return alert('Because there are bidders, edit are not possible.');
    const numericValue = parseFloat(startPrice);
    const PriceBefore = parseFloat(dataDetailMyItems?.sellbook?.price);
    // setStartPrice(dataDetailMyItems?.sellbook?.price);
    const numericValueMinIncrement = parseFloat(minIncrement);

    if (numericValue == PriceBefore || startPrice == '0.0') {
      // alert('The price is the same as before.');
      openError('The price is the same as before.');
      return;
    }
    if (isNaN(numericValue) || isNaN(numericValueMinIncrement)) {
      alert('Please enter a valid number!');
      return;
    }
    if (numericValue <= 0) {
      alert('Price should be greater than zero!!');
      return;
    }
    setIsLoadingConfirm(true);

    // 1. Cancel Order fisrt
    let provider;
    if (login === 'sns') {
      provider = await HelperUtil.getAbcWeb3Provider(target);
    }
    const order = dataDetailMyItems?.sellbook.sellInfo;
    const params = [];
    params.push(order?.parameters);
    const receipt = await seaport.cancelOrders(login === 'sns' ? provider : library.getSigner(), params);
    console.log('!! cancelOrders receipt :::::', receipt);

    if (receipt?.status === SUCCESS) {
      // Remove from sellbooks table
      const id = dataDetailMyItems?.sellbook.id;
      const uid = dataDetailMyItems?.sellbook.uid;
      const result = await cancelSell(id, uid);
      console.log('!! cancelSell result :::::', result);

      // 2. Create a new order
      try {
        const chain = target;
        let quoteAddress = null;
        if (quote.toUpperCase() === 'TALK') {
          quoteAddress = contracts.talken[chain];
        }

        const order = await seaport.createOrder({
          signer: login === 'sns' ? provider : library.getSigner(),
          tokenContractAddress: dataDetailMyItems?.sellbook?.nftInfo?.contractAddress,
          tokenId: dataDetailMyItems?.sellbook?.tokenId.toString(),
          itemType: dataDetailMyItems?.sellbook?.contract_type === 'ERC1155' ? 3 : 2,
          price: startPrice.toString(),
          quoteContractAddress: quoteAddress,
          endTime:
            endEpoch === 0
              ? new Date(dataDetailMyItems?.sellbook?.endDate).getTime()?.toString()
              : Math.floor(endEpoch / 1000).toString(), // about one month
          creatorAddress: '', //  creator,
          quote,
        });

        if (order && typeof order === 'object' && order?.parameters) {
          // TODO : Save order into DB
          const sellOrder = {
            uid: user.uid,
            ethAddress: myWallet,
            type: typeSale === 'fixedsale' ? 1 : 2,
            tokenId: dataDetailMyItems?.sellbook?.tokenId,
            sellInfo: order,
            price: startPrice,
            startDate: new Date().getTime(),
            endDate: endEpoch === 0 ? new Date(dataDetailMyItems?.sellbook?.endDate).getTime() : endEpoch,
            creatorFee: 7.5,
            minInc: typeSale === 'fixedsale' ? 0 : dataDetailMyItems?.sellbook?.minInc,
            nftInfo: dataDetailMyItems?.sellbook?.nftInfo,
            quote,
            chainId: chain,
          };
          console.log('!! sellOrder for DB = ', sellOrder);

          const result = await registerSell(sellOrder);
          console.log('!! Sell Result : ', result);
          const sellData = result.data.data;

          if (result.status === 200) {
            console.log('... success !!');
            setIsLoadingConfirm(false);
            onHide();
            confirm();
            // navigate('/my-items');
          } else {
            console.log('... Failed !!');
          }
        }
      } catch (e: any) {
        console.log('!!!!! create order error', e.message);
      }
    } else {
      console.log('!! cancelSell failed');
      if (receipt) alert(receipt);
      setIsLoadingConfirm(false);
      return;
    }
    setIsLoadingConfirm(false);
    // onHide();
    // confirm();
  };
  const handleCancelListing = async () => {
    // Cancel Order
    setIsLoadingCancel(true);
    let provider;
    if (login === 'sns') {
      provider = await HelperUtil.getAbcWeb3Provider(target);
    }
    const order = dataDetailMyItems?.sellbook.sellInfo;
    const params = [];
    params.push(order?.parameters);
    const receipt = await seaport.cancelOrders(login === 'sns' ? provider : library.getSigner(), params);
    console.log('!! cancelOrders receipt :::::', receipt);

    if (receipt?.status === SUCCESS) {
      // Remove from sellbooks table
      const id = dataDetailMyItems?.sellbook.id;
      const uid = dataDetailMyItems?.sellbook.uid;
      const result = await cancelSell(id, uid);
      console.log('!! cancelSell result :::::', result);
      // TODO : Go to My Items
      // navigate('/my-items');
      setIsLoadingCancel(false);
      onHide();
      cancel();
    } else {
      console.log('!! cancelSell failed');
      if (receipt) alert(receipt);
      setIsLoadingCancel(false);
      return;
    }
    setIsLoadingCancel(false);
  };
  const stylesTypeMoney = {
    control: (base, state) => ({
      ...base,
      background: isDarkMode ? '#202052' : '#FFFFFF1A',
      borderRadius: isMobile ? '4px' : '4px',
      borderColor: state.selectProps.menuIsOpen ? '#2F8AF5' : '#525298',
      boxShadow: state.isFocused ? 'none' : 'none',
      fontSize: isMobile ? '9px' : '12px',
      fontWeight: 500,
      width: isMobile ? '62px' : '80px',
      height: isMobile ? '28px' : '34px',
      minHeight: isMobile ? '28px' : '34px',
      cursor: 'pointer',
    }),
    singleValue: (base, state) => ({
      ...base,
      color: state.selectProps.menuIsOpen ? '#2F8AF5' : isDarkMode ? '#525298' : '#000000',
      marginLeft: isMobile ? '2px' : '0',
    }),
    valueContainer: (base) => ({
      ...base,
      height: '100%',
      placeContent: 'center',
      marginLeft: isMobile ? '2px' : '2px',
      padding: isMobile ? '2px 0px' : '',
    }),
    option: (base, state) => ({
      ...base,
      fontSize: isMobile ? '9px' : '12px',
      fontWeight: state.isSelected ? 600 : 400,
      width: isMobile ? '62px' : '80px',
      color: state.isSelected ? '#2F8AF5' : '#777',
      backgroundColor: state.isSelected ? '#F0F5FF' : '#FFFFFF',
      ':active': {
        backgroundColor: state.isSelected ? '#FFFFFF' : '#FFFFFF',
        fontWeight: 600,
      },
      ':hover': {
        backgroundColor: '#2F8AF5',
        color: '#FFFFFF',
      },
      cursor: 'pointer',
    }),

    menu: (base) => ({
      ...base,
      fontSize: isMobile ? '9px' : '12px',
      background: '#FFFFFF',
      // override border radius to match the box
      borderRadius: isMobile ? '4px' : '4px',
      // kill the gap
      marginTop: 0,
      width: isMobile ? '62px' : '80px',
    }),
    menuList: (base) => ({
      ...base,
      fontSize: '12px',
      // kill the white space on last option
      padding: '0',
      width: isMobile ? '62px' : '80px',
      borderRadius: isMobile ? '4px' : '4px',
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: state.selectProps.menuIsOpen ? '#2F8AF5' : isDarkMode ? '#525298' : '#000000',
      placeContent: 'center',
      padding: isMobile ? '4px' : '6px',
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      width: isMobile ? '24px' : '28px',
    }),
  };

  const handleCheckSwitch = (e) => {
    setIsCheckedSwitch(e.target.checked);
  };

  const handleChangeEnddate = (selected: any) => {
    const cur = new Date().getTime();
    let epoch: number;

    switch (selected.value) {
      case '1month':
        epoch = cur + 2592000000;
        break;
      case '2month':
        epoch = cur + 2592000000 * 2;
        break;
      case '3month':
        epoch = cur + 2592000000 * 3;
        break;
      case '4month':
        epoch = cur + 2592000000 * 4;
        break;
      case '5month':
        epoch = cur + 2592000000 * 5;
        break;
      case '6month':
        epoch = cur + 2592000000 * 6;
        break;
    }
    const strEndDate = new Date(epoch).toLocaleDateString();
    const strEndTime = new Date(epoch).toLocaleTimeString();
    setEndDate(strEndDate);
    setEndTime(strEndTime);
    setEndEpoch(epoch);
  };

  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className={`modal-primary w-540px w-xs-338px h-fit-content p-24 p-xs-20`}>
        <div className="close-button-modal" onClick={onHide}>
          <IconClose className="svg-color dark:svg-color" />
        </div>
        <div className="fs-24 fs-xs-16 p-b-2 fw-bold text-center lh-28 lh-xs-inherit">Edit listing</div>
        <div className="line-banner margin-bottom-16"></div>
        <div className="p-b-8 p-b-xs-4">
          <div className="d-flex gap-24px">
            {screenSize > 768 && (
              <div className="w-80px h-80px min-w-80px">
                {dataDetailMyItems?.sellbook?.nftInfo?.image?.split('.')?.pop() === 'mp4' ||
                dataDetailMyItems?.sellbook?.nftInfo?.extension === 'mp4' ||
                dataDetailMyItems?.sellbook?.nftInfo?.metadata?.image?.split('.')?.pop() === 'mp4' ||
                dataDetailMyItems?.sellbook?.nftInfo?.metadata?.extension === 'mp4' ? (
                  <video
                    playsInline
                    webkit-playsinline="true"
                    muted
                    controlsList="nodownload"
                    width={'100%'}
                    className="w-100 h-100 border-radius-8 object-fit-cover"
                    onError={HandleMediaError}
                    key={`${
                      dataDetailMyItems?.sellbook?.nftInfo?.image ||
                      dataDetailMyItems?.sellbook?.nftInfo?.metadata?.image
                        ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                        .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')
                    }#t=0.001`}
                  >
                    <source
                      src={`${
                        dataDetailMyItems?.sellbook?.nftInfo?.image ||
                        dataDetailMyItems?.sellbook?.nftInfo?.metadata?.image
                          ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                          .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')
                      }#t=0.001`}
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <img
                    src={
                      dataDetailMyItems?.alt_url ??
                      (dataDetailMyItems?.sellbook?.nftInfo?.alt_url ||
                        dataDetailMyItems?.sellbook?.nftInfo?.image ||
                        dataDetailMyItems?.sellbook?.nftInfo?.metadata?.alt_url ||
                        dataDetailMyItems?.sellbook?.nftInfo?.metadata?.image
                          ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                          .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/'))
                    }
                    alt="Icon Item"
                    className="w-100 h-100 border-radius-8 object-fit-cover"
                    onError={HandleMediaError}
                  />
                )}
                {/* <img
                  src={dataDetailMyItems?.alt_url ?? dataDetailMyItems?.sellbook?.nftInfo?.alt_url}
                  alt="Icon Item"
                  className="w-100 h-100 border-radius-10 object-fit-cover"
                /> */}
              </div>
            )}
            <div className="d-flex flex-column gap-8px align-items-start w-100">
              <div className="fw-9 fs-22 fs-xs-18 w-75 w-xs-100 line-clamp-2 lh-26 lh-xs-21">
                {typeof dataDetailMyItems?.sellbook?.nftInfo?.name === 'string'
                  ? dataDetailMyItems?.sellbook?.nftInfo?.name
                  : dataDetailMyItems?.sellbook?.nftInfo?.contractName
                  ? dataDetailMyItems?.sellbook?.nftInfo?.contractName
                  : dataDetailMyItems?.sellbook?.nftInfo?.metadata?.name
                  ? dataDetailMyItems?.sellbook?.nftInfo?.metadata?.name
                  : dataDetailMyItems?.sellbook?.nftInfo?.name?.en}
              </div>
              <div className="fw-6 color-secondary fs-14 fs-xs-12">
                {/* Expires in 10 days */}
                {countDownFinish ? (
                  'Out of time'
                ) : (
                  <CountDownEditListing
                    handleFinish={() => setCountDownFinish(true)}
                    endDate={dataDetailMyItems?.sellbook?.endDate}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="line-banner margin-bottom-16"></div>
          <div className="fs-14 fs-xs-12 title-input-modal p-b-8 p-b-xs-4">Set new price</div>
          {typeSale !== 'fixedsale' && (
            <div className="fs-12 lh-14 color-primary fw-400">
              If you want to increase minimum bid, you have to cancel your listing and create a new listing
            </div>
          )}
          <Row
            className={`d-flex p-t-4 p-t-xs-4 ${
              typeSale === 'fixedsale' ? 'justify-content-between gap-18px gap-xs-16px' : ''
            }`}
          >
            {typeSale === 'fixedsale' ? (
              <Col>
                <div className="fs-12 fs-xs-9 p-b-4 title-input-modal text-white-xs-4">Price</div>
                <div className="position-relative w-100">
                  <input
                    type="text"
                    placeholder="Amount"
                    className="w-100 h-48px h-xs-32px border-radius-8 border-radius-xs-6 bg-box-modal p-t-16 p-b-16 p-l-20 p-r-126 p-t-xs-10 p-b-xs-10 p-l-xs-14 p-r-xs-68 border-unset fs-14 fs-xs-9 text-base"
                    value={startPrice}
                    onChange={handleInputPrice}
                  />
                  <div className="position-absolute top-8px right-8px top-xs-2px right-xs-4px">
                    <Select
                      options={optionsMinTypeMoneyFixed[chain]}
                      styles={stylesTypeMoney}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      isSearchable={false}
                      value={
                        quote === ''
                          ? optionsMinTypeMoneyFixed[chain].find(
                              (item) => item.label === dataDetailMyItems?.sellbook?.quote
                            )
                          : optionsMinTypeMoneyFixed[chain].find((item) => item.label === quote)
                      }
                      onChange={handleQuote}
                    />
                  </div>
                </div>
              </Col>
            ) : (
              <>
                <Col>
                  <div className="fs-12 fs-xs-9 p-b-4 title-input-modal text-white-xs-4">Starting Price</div>
                  <div className="position-relative w-100">
                    <input
                      type="number"
                      placeholder="Amount"
                      className="w-100 h-48px h-xs-32px border-radius-8 bg-box-modal p-t-16 p-b-16 p-l-20 p-r-126 p-t-xs-10 p-b-xs-10 p-l-xs-14 p-r-xs-68 border-unset fs-14 fs-xs-9 text-base"
                      value={startPrice}
                      onBlur={handleInputBlur}
                      onChange={handleInputPrice}
                    />
                    <div className="position-absolute top-8px right-8px top-xs-2px right-xs-4px">
                      <Select
                        options={optionsMinTypeMoneyAuction}
                        styles={stylesTypeMoney}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        isSearchable={false}
                        value={
                          quote === ''
                            ? optionsMinTypeMoneyAuction.find(
                                (item) =>
                                  item.label === dataDetailMyItems?.sellbook?.quote ||
                                  optionsMinTypeMoneyFixed[chain][0]
                              )
                            : optionsMinTypeMoneyAuction.find(
                                (item) => item.label === quote || optionsMinTypeMoneyFixed[chain][0]
                              )
                        }
                        onChange={handleQuote}
                      />
                    </div>
                  </div>
                </Col>
                {/* <Col>
                  <div className="fs-12 fs-xs-9 p-b-4 title-input-modal text-white-xs-4">Maximum Bid Quantities</div>
                  <div className="position-relative w-100">
                    <input
                      type="number"
                      placeholder="Amount"
                      className="w-100 h-48px h-xs-32px border-radius-10 border-radius-xs-6 bg-box-modal p-t-16 p-b-16 p-l-20 p-r-20 p-t-xs-10 p-b-xs-10 p-l-xs-14 p-r-xs-14 border-unset fs-14 fs-xs-9 text-base"
                      value={minIncrement === '0.0' ? dataDetailMyItems?.sellbook?.minInc : minIncrement}
                      onChange={handleInputMinIncreament}
                    />
                  </div>
                </Col> */}
              </>
            )}
          </Row>
          {/*<div className="d-flex justify-content-between p-t-40 p-b-12 p-t-xs-30 p-b-xs-8">*/}
          {/*  <div className="title-input-modal fw-6 fs-14 fs-xs-12">Use previous listing expiration date</div>*/}
          {/*  <label className="switch-edit-auction-sale">*/}
          {/*    <input type="checkbox" onChange={handleCheckSwitch} checked={isCheckedSwitch} />*/}
          {/*    <span className="slider-edit-auction-sale"></span>*/}
          {/*  </label>*/}
          {/*</div>*/}
          {/*<div className="d-flex align-items-center gap-14px gap-xs-8px">*/}
          {/*  {!isCheckedSwitch && (*/}
          {/*    <Select*/}
          {/*      options={optionsMonth}*/}
          {/*      styles={stylesTypeMoney}*/}
          {/*      components={{*/}
          {/*        IndicatorSeparator: () => null,*/}
          {/*      }}*/}
          {/*      isSearchable={false}*/}
          {/*      onChange={handleChangeEnddate}*/}
          {/*    />*/}
          {/*  )}*/}
          {/*  <div*/}
          {/*    className={`detail-box-modal d-flex align-items-center gap-4px ${*/}
          {/*      isCheckedSwitch ? '' : 'justify-content-between'*/}
          {/*    }`}*/}
          {/*  >*/}
          {/*    <div>{endDate}</div>*/}
          {/*    <div>{endTime}</div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <button
            className="btn-detail-nft m-t-24 m-t-xs-16 m-b-16 m-b-xs-8 button h-50px h-xs-40px"
            onClick={handleConfirmModal}
            disabled={isLoadingCancel}
          >
            {isLoadingConfirm ? <CircularProgress size={30} color={'inherit'} /> : <>Continue</>}
          </button>
          <button
            className="btn-detail-nft m-t-0 mb-0 bg-box-modal hover-opa-7 button text-black dark:text-white h-50px h-xs-40px"
            onClick={handleCancelListing}
            disabled={isLoadingConfirm}
          >
            {isLoadingCancel ? <CircularProgress size={30} color={'inherit'} /> : <>Cancel Listing</>}
          </button>
        </div>
      </div>
    </ReactModal>
  );
};
export default EditListing;
