import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import { useThemeMode } from 'components/common/AppStoreType';

const ShowCounter = ({ days, hours, minutes, seconds, detailNFT, salesEnd }: any) => {
  const { isDarkMode } = useThemeMode();
  const date = new Date(salesEnd ?? '2024-12-31');
  const formattedDate = date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  });
  return (
    <div className="show-counter">
      <div className={`countdown-link justify-content-center flex-column ${isDarkMode ? 'text-2f8af5' : ' '}`}>
        <div className="d-flex gap-8px fs-xs-11">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="9.0711" stroke="#2F8AF5" strokeWidth="1.8578" />
            <path
              d="M9.76758 5.34766V10.9291H14.1862"
              stroke="#2F8AF5"
              strokeWidth="1.8578"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="d-flex justify-center align-items-center">
            <DateTimeDisplay value={days} type={'days'} isDanger={days <= 3} />
            {/* <p>:</p> */}
            <DateTimeDisplay value={hours} type={'hours'} isDanger={false} />
            {/* <p>:</p> */}
            <DateTimeDisplay value={minutes} type={'minutes'} isDanger={false} />
            {/* <p>:</p> */}
            {/* <DateTimeDisplay value={seconds} type={'seconds'} isDanger={false} /> */}
            <div className="countdown">left</div>
          </div>
        </div>
        <div className={`d-flex w-100 lh-21 ${detailNFT ? 'justify-content-between' : 'justify-content-around'}`}>
          <div className="color-reward fw-600">Sales end</div>
          <div className="text-black dark:text-white fw-400">{formattedDate}</div>
        </div>
      </div>
    </div>
  );
};

export default ShowCounter;
