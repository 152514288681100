import StudioLayout from '../components/layouts/studioLayout/StudioLayout';
import React from 'react';
import Homepage from 'pages/homepage/HomePage';
import Collections from 'pages/Collections/Collections';
import SaleCollectibles from 'pages/SaleCollectibles/SaleCollectibles';
import Collection from 'pages/Collection/Collection';
import MyCollectiblesDetails from 'pages/MyCollectibles/MyCollectiblesDetails';
import Landing from 'pages/Landingpage/LandingPage';
import LandingLayout from 'components/layouts/studioLayout/LandingLayout';
import MyCollectibles from 'pages/MyCollectibles/MyCollectibles';
import Profile from 'pages/MyProfile/MyProfile';
import PurchaseHistory from 'pages/PurchaseHistory/PurchaseHistory';
import PurchaseHistoryDetail from 'pages/PurchaseHistory/PurchaseHistoryDetail';
import CollectionSale from 'pages/CollectionSale/CollectionSale';
import CollectionSaleDetail from 'pages/CollectionSale/CollectionSaleDetail';
import Test from 'talkclaim/Test';
import ClaimTalk from 'talkclaim/ClaimTalk';
import Reward from 'talkclaim/Reward';
import Web3LandingPage from 'web3LandingPage/web3LandingPage';
import ClaimOwner from 'talkclaim/ClaimOwner';
import Aquarium1402One from 'pages/Landingpage/Aquarium1402-1';
import Aquarium1402Two from 'pages/Landingpage/Aquarium1402-2';
import ItemDetails from 'pages/MyCollectibles/ItemDetails';
import ConnectPage from 'components/auth/connectpage/ConnectPage';
import FullLayout from 'components/layouts/fullLayout/FullLayout';
import RegisterPage from 'components/auth/connectpage/RegisterPage';
import AggregateUserInfor from 'components/AggregateUserInfor/AggregateUserInfor';
import MyProfileNewDesign from 'pages/MyProfile/MyProfileNewDesign';
import Drops from 'pages/Drops/Drops';
import RecentlyListedItems from 'pages/RecentlyListedItems/RecentlyListedItems';
import FreeDrops from 'pages/FreeDrops/FreeDrops';
import SignUpPage from 'components/auth/connectpage/signUpPage';
import SellDetails from '../pages/MyCollectibles/SellDetails';
import TalkenBridges from '../pages/TalkenBridges/TalkenBridge';
import Faucet from 'pages/Faucet/Faucet';
import Rewards from 'pages/Rewards/Rewards';
import PrivacyPolicy from 'pages/Agreements/PrivacyPolicy';
import TermsConditions from 'pages/Agreements/TermsConditions';
import Admp from 'admp/Admp';

const Router = () => [
  {
    path: '/',
    element: <StudioLayout />,
    children: [
      { path: '/', element: <Homepage /> },
      { path: '/aggregate-user-information', element: <AggregateUserInfor /> },
      // { path: '/collection/:id', element: <Collection /> },
      // { path: '/collections', element: <Collections /> },
      // { path: '/collection-sale/:id', element: <CollectionSale /> },
      // { path: '/collection-sale/sale/:id', element: <CollectionSaleDetail /> },
      {
        path: '/collection-sale/sale/single',
        element: <CollectionSaleDetail />,
      },
      // { path: '/sale/:id', element: <SaleCollectibles /> },
      { path: '/my-items', element: <MyCollectibles /> },
      {
        path: '/my-items/details',
        element: <MyCollectiblesDetails />,
      },
      // { path: '/my-profile', element: <Profile /> },
      { path: '/profile', element: <MyProfileNewDesign /> },
      { path: '/rewards', element: <Rewards /> },
      { path: '/history', element: <PurchaseHistory /> },
      { path: '/series', element: <PurchaseHistoryDetail /> },

      { path: '/creator/:id', element: <Collection /> },
      { path: '/creators', element: <Collections /> },
      { path: '/drops', element: <Drops /> },
      { path: '/market', element: <RecentlyListedItems /> },
      { path: '/faucet', element: <Faucet /> },
      { path: '/free-drops', element: <FreeDrops /> },
      { path: '/collections/:id', element: <CollectionSale /> },
      {
        path: '/collection/:collectionId/:id',
        element: <CollectionSaleDetail />,
      },
      { path: '/mbox/:id', element: <SaleCollectibles /> },
      {
        path: '/airdrop/:collectionId/:id',
        element: <CollectionSaleDetail />,
      },
      {
        path: '/klaytn/:contractAddress/:itemNo/:id',
        element: <ItemDetails />,
      },
      {
        path: '/:network/:contractAddress/:itemNo/:id',
        element: <ItemDetails />,
      },
      {
        path: '/sellbook/:sellbookId/:network/:contractAddress/:tokenId',
        element: <SellDetails />,
      },
      {
        path: '/bridge',
        element: <TalkenBridges />,
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicy />,
      },
      {
        path: '/terms-conditions',
        element: <TermsConditions />,
      },
      // { path: '/klaytn/featured/:id', element: <Collection /> },
      // { path: '/klaytn/featureds', element: <Collections /> },
      // { path: '/klaytn/collections/:id', element: <CollectionSale /> },
      // {
      //   path: '/klaytn/collection/:collectionId/:id',
      //   element: <CollectionSaleDetail />,
      // },
      // { path: '/klaytn/mbox/:id', element: <SaleCollectibles /> },
      // {
      //   path: '/klaytn/airdrop/:collectionId/:id',
      //   element: <CollectionSaleDetail />,
      // },
    ],
  },
  {
    element: <LandingLayout />,
    children: [
      { path: '/oldnavy', element: <Landing /> },
      { path: '/aquarium-1402-1', element: <Aquarium1402One /> },
      { path: '/aquarium-1402-2', element: <Aquarium1402Two /> },
    ],
  },

  { path: '/test', element: <Test /> },
  { path: '/claimTalk', element: <ClaimTalk /> },
  { path: '/admp', element: <ClaimOwner /> },
  { path: '/reward', element: <Reward /> },
  { path: '/web3', element: <Web3LandingPage /> },
  { path: '/mintadmp', element: <Admp /> },
  {
    element: <FullLayout />,
    children: [
      { path: 'accounts', element: <ConnectPage /> },
      { path: 'sign-up', element: <SignUpPage /> },
      { path: 'accounts/register', element: <RegisterPage /> },
    ],
  },
];

export default Router;
