import React, { ChangeEvent, useEffect, useState, useMemo } from 'react';
import { useThemeMode } from 'components/common/AppStoreType';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  getSession,
  userRegister,
  usernameDuplicateCheck,
  refererUidCheck,
  abcLogin,
  abcJoin,
  getUser,
} from '../../../services/services';
import logo_talken_dark from '../../../assets/svg/logo_header.svg';
import logo_talken_light from '../../../assets/svg/logo_talken_blue.svg';
import logo_google from '../../../assets/icon/logo_google.png';
import logo_apple from '../../../assets/icon/logo_apple.png';
import logo_apple_black from '../../../assets/icon/logo_apple_black.png';
import logo_facebook from '../../../assets/icon/logo_facebook.png';
import logo_walletconnect from '../../../assets/svg/walletconnect.svg';
import logo_metamask from '../../../assets/img/metamask.svg';
import logo_talken from '../../../assets/img/icon_talk.png';
import kakao_border from '../../../assets/icon/kakao_border.png';
import check_all_connect from '../../../assets/icon/check_all_connect.png';
import check_all_connect_light from '../../../assets/icon/check_all_connect_light.png';
import checked_all_connect from '../../../assets/icon/checked_all_connect.png';
import check_connect from '../../../assets/icon/check_connect.png';
import check_connect_light from '../../../assets/icon/check_connect_light.png';
import checked_connect from '../../../assets/icon/checked_connect.png';
import { accountRestApi, controllers } from '../../../abc/background/init';
import { AbcLoginResult, AbcSnsAddUserDto } from '../../../abc/main/abc/interface';
import { AbcLoginResponse } from '../../../abc/schema/account';
import ErrorIcon from '@mui/icons-material/Error';
import HelperUtil from '../../../utils/HelperUtil';
import { CircularProgress } from '@mui/material';

const RegisterPage = () => {
  const navigate = useNavigate();
  const { isDarkMode } = useThemeMode();
  const { abcController, accountController } = controllers;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [refererUid, setRefererUid] = useState(window.localStorage.getItem('ref') || '');
  const [isDuplicateName, setIsDuplicateName] = useState(false);
  const [isCheckName, setIsCheckName] = useState(false);
  const [isCheckEmail, setIsCheckEmail] = useState(false);
  const [provider, setProvider] = useState('');
  const [providerName, setProviderName] = useState('');
  const [idToken, setIdToken] = useState('');
  const [providerLogo, setProviderLogo] = useState(logo_talken);
  const isProduction = process.env.REACT_APP_PHASE === 'production';
  const [isCheck, setIsCheck] = useState({
    check1: false,
    check2: false,
    check3: false,
  });
  const [isCheckABC, setIsCheckABC] = useState({
    check1: false,
    check2: false,
    check3: false,
    check4: false,
    check5: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleCheckItem = (check: 'check1' | 'check2' | 'check3') => {
    const newCheck = { ...isCheck, [check]: !isCheck[check] };
    setIsCheck(newCheck);
  };
  const handleCheckABC = (check: 'check1' | 'check2' | 'check3' | 'check4' | 'check5') => {
    const newCheck = { ...isCheckABC, [check]: !isCheckABC[check] };
    setIsCheckABC(newCheck);
  };
  const isCheckAll = isCheck.check1 && isCheck.check2 && isCheck.check3;
  const isCheckAllABC =
    isCheckABC.check1 && isCheckABC.check2 && isCheckABC.check3 && isCheckABC.check4 && isCheckABC.check5;
  const handleCheckAll = () => {
    setIsCheck({
      check1: !isCheckAll,
      check2: !isCheckAll,
      check3: !isCheckAll,
    });
  };
  const handleCheckAllABC = () => {
    setIsCheckABC({
      check1: !isCheckAllABC,
      check2: !isCheckAllABC,
      check3: !isCheckAllABC,
      check4: !isCheckAllABC,
      check5: !isCheckAllABC,
    });
  };
  const handleChangeInputValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const _value = value.trim().replace(/[^A-Za-z0-9_-]/g, '');
    if (name === 'name') setName(_value);
    else if (name === 'email') setEmail(value);
    if (name === 'name') {
      const regex = /^[a-zA-Z0-9-]*$/;
      const checkName = regex.test(_value) && _value.length < 17 && _value.length > 1;
      !checkName ? setIsCheckName(true) : setIsCheckName(false);
    }
    if (name === 'email') {
      const regex = /^[a-zA-Z0-9\-_.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
      // const regex = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
      const checkEmail = regex.test(value);
      setIsCheckEmail(!checkEmail);
      !checkEmail ? setIsCheckEmail(true) : setIsCheckEmail(false);
    }
  };
  const handleChangeInputCodeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setRefererUid(event.target.value.trim());
  };
  const handleOnBlur = async () => {
    // if (name === dropsAccount.name) {
    //   setIsDuplicateName(false);
    //   return;
    // }
    const data = { username: name };
    const res = await usernameDuplicateCheck(data);
    if (res.data?.result?.result !== true) setIsDuplicateName(true);
    else setIsDuplicateName(false);
  };
  const handleProviderLogo = (provider: string) => {
    if (provider === 'google') {
      setProviderLogo(logo_google);
    } else if (provider === 'facebook') {
      setProviderLogo(logo_facebook);
    } else if (provider === 'apple') {
      isDarkMode ? setProviderLogo(logo_apple) : setProviderLogo(logo_apple_black);
    } else if (provider === 'metamask') {
      setProviderLogo(logo_metamask);
    } else if (provider === 'walletconnector') {
      setProviderLogo(logo_walletconnect);
    } else {
      setProviderLogo(logo_talken);
    }
  };
  useEffect(() => {
    const fetchSession = async () => {
      const res = await getSession();
      if (res.data?.providerAuthInfo) {
        // TODO : Save id token for ABC wallet
        setIdToken(res.data?.providerAuthInfo.provider_token);
        const _provider = JSON.parse(res.data?.providerAuthInfo?.provider_data);
        const provider_email = _provider?.email;
        const provider = res.data?.providerAuthInfo?.provider;
        const loginType = localStorage.getItem('login');
        setProvider(provider);
        if (loginType === 'walletconnector') setProviderName('WalletConnect');
        else if (loginType === 'metamask') setProviderName('MetaMask');
        else setProviderName(provider);
        if (provider === 'WALLET') {
          setIsCheckABC({
            check1: true,
            check2: true,
            check3: true,
            check4: true,
            check5: true,
          });
          handleProviderLogo(loginType);
        } else handleProviderLogo(res.data?.providerAuthInfo?.provider);
        setEmail(provider_email || '');
      }
      // if (res.data.status === 1) {
      // }
    };
    fetchSession();
  }, []);

  const checkDisable = () => {
    return (
      isCheckName ||
      name === '' ||
      isDuplicateName ||
      !isCheck?.check1 ||
      !isCheck?.check2 ||
      isCheckEmail ||
      !isCheckABC?.check1 ||
      !isCheckABC?.check2 ||
      !isCheckABC?.check3 ||
      !isCheckABC?.check4
    );
  };

  const registerAbc = async () => {
    // console.log('!! Start register ABC wallet ...', { provider, isCheckABC, email });

    // ABC 지갑 가입 여부 확인
    const isExist = await abcController.getUser({
      email,
      successIfUserExist: true,
    });
    // console.log('!! ABC is exist =', isExist);

    if (!isExist) {
      // 신규 가입자 생성

      // ABC 로그인
      // console.log('!! New ABC user : ', {
      //   token: idToken,
      //   provider,
      //   audience: 'https://mw.myabcwallet.com',
      // });
      const result = await abcLogin({
        token: idToken,
        service: provider,
        audience: 'https://mw.myabcwallet.com',
      });
      // console.log('!! 신규 가입자 abcLogin = ', result);

      const sixCode = JSON.parse(result.data.msg).sixcode;
      // console.log('!! sixcode =', sixCode, email);

      const dto: AbcSnsAddUserDto = {
        username: email,
        code: sixCode,
        joinpath: 'https://talken.io',
        overage: isCheckABC?.check1 ? 1 : 0,
        agree: isCheckABC?.check2 ? 1 : 0,
        collect: isCheckABC?.check3 ? 1 : 0,
        thirdparty: isCheckABC?.check4 ? 1 : 0,
        advertise: isCheckABC?.check5 ? 1 : 0,
      };

      try {
        const newAccount = await abcJoin(dto);
        // console.log('!! created account =', newAccount);
      } catch (e) {
        console.log('!! snsAddUser Error =', e);
      }

      // 신규 가입 후 ABC 로그인
      // console.log('!! start to abc sns login !!');
      const abcAuth = await abcController.snsLogin(idToken, provider);
      // console.log('!! abc sns login result =', abcAuth);

      // 토큰 저장
      window.localStorage.setItem('abcAuth', JSON.stringify(abcAuth));

      // MPC 지갑 생성
      await accountController.createMpcBaseAccount(
        {
          accountName: email,
          password: '!Colligence001',
          email: email,
        },
        null,
        null
      );

      // 생성된 MPC 지갑 정보 조회
      const { user, wallets } = await accountRestApi.getWalletsAndUserByAbcUid(abcAuth);
      // console.log('!! user =', user);

      await accountController.recoverShare({ password: '!owdin001', user, wallets, keepDB: false }, null);

      const abcWallet = user.accounts[0].ethAddress;

      const userRes = await getUser();
      // console.log('!! Register a new ABC wallet user ... done : ', userRes);

      return abcWallet;
    } else {
      // ABC 기존 가입자
      await HelperUtil.abcRecoverNew();

      const abcUser = JSON.parse(window.localStorage.getItem('abcUser'));
      const abcWallet = abcUser?.accounts[0]?.ethAddress;
      // console.log('!! Register an ABC wallet user ... done : ', abcWallet);

      return abcWallet;
    }
  };

  const handlePasteFromClipboard = () => {
    navigator.clipboard.readText().then((text) => {
      setRefererUid(text.trim());
    });
  };

  return (
    <div className="kakao-page">
      <div className="logo-talken" onClick={() => navigate('/')}>
        {isDarkMode ? (
          <img src={logo_talken_dark} alt="Logo Talken" />
        ) : (
          <img src={logo_talken_light} alt="Logo Talken" />
        )}
      </div>
      <div className="form-kakao">
        <div className="logo-kakao">
          <img src={providerLogo} alt="KaKao Icon" />
        </div>
        <div className="title-kakao">{providerName} Account</div>
        <div className="wrapper-nickname">
          <div className="title-nickname">
            <div className="nickname">Nickname</div>
          </div>
          <div style={{ display: 'flex', gap: '16px' }}>
            <input
              className="input-nickname"
              type="text"
              name="name"
              value={name}
              onChange={handleChangeInputValue}
              onBlur={handleOnBlur}
              placeholder="2-16 characters.(No special characters/space)"
            />
            {/* <button
              className="nickname-check"
              onClick={() => {
                null;
              }}
              disabled={name == '' || name == null || name == undefined}
            >
              Check
            </button> */}
          </div>
        </div>
        {isDuplicateName && !isCheckName && (
          <div className="duplicate-wrapper">
            <ErrorIcon className="duplicate-info-icon" />
            <span className="duplicate-info">That nickname is duplicated. Try another.</span>
          </div>
        )}
        {isCheckName && (
          <div className="duplicate-wrapper">
            <ErrorIcon className="duplicate-info-icon" />
            <span className="duplicate-info">Invalid username.</span>
          </div>
        )}
        {provider !== 'WALLET' && (
          <div className="wrapper-email">
            <div className="title-email">
              <div className="email">Email</div>
              <span>*</span>
            </div>
            <input
              disabled={true}
              className="input-email"
              type="text"
              name="email"
              value={email}
              onChange={handleChangeInputValue}
            />
          </div>
        )}
        {isCheckEmail && (
          <div className="duplicate-wrapper">
            <ErrorIcon className="duplicate-info-icon" />
            <span className="duplicate-info">Invalid email.</span>
          </div>
        )}
        {!isProduction && (
          <div className="wrapper-invitation-code">
            <div className="title-invitation-code">
              <div className="nickname">Invitation code</div>
            </div>
            <div style={{ display: 'flex', gap: '16px' }}>
              <input
                className="input-invitation-code"
                type="text"
                name="code"
                value={refererUid}
                onChange={handleChangeInputCodeValue}
              />
              <button className="invitation-code-check" onClick={handlePasteFromClipboard}>
                Paste
              </button>
            </div>
          </div>
        )}
        <div className="agree-background">
          <div className="agree-all">
            <div className="icon-check-all" onClick={handleCheckAll}>
              {isCheckAll ? (
                <img src={checked_all_connect} alt="Check All" />
              ) : isDarkMode ? (
                <img src={check_all_connect} alt="Check All" />
              ) : (
                <img src={check_all_connect_light} alt="Check All" />
              )}
            </div>
            <div className="content-agree">Agree to all</div>
          </div>
          <div className="wrapper-agree">
            <div className="agree-item">
              <div className="icon-check-agree" onClick={() => handleCheckItem('check1')}>
                {isCheck.check1 ? (
                  <img src={checked_connect} alt="Check Icon" />
                ) : isDarkMode ? (
                  <img src={check_connect} alt="Check Icon" />
                ) : (
                  <img src={check_connect_light} alt="Check Icon" />
                )}
              </div>
              <div className="wrapper-agree-left">
                <span>*</span>
                <div>Agree</div>
                <a href="/terms-conditions" target={'_blank'} className="text-terms">
                  Terms and Conditions
                </a>
              </div>
            </div>
            <div className="agree-item">
              <div className="icon-check-agree" onClick={() => handleCheckItem('check2')}>
                {isCheck.check2 ? (
                  <img src={checked_connect} alt="Check Icon" />
                ) : isDarkMode ? (
                  <img src={check_connect} alt="Check Icon" />
                ) : (
                  <img src={check_connect_light} alt="Check Icon" />
                )}
              </div>
              <div className="wrapper-agree-left">
                <span>*</span>
                <div>Agree</div>
                <a href="/privacy-policy" target={'_blank'} className="text-terms a">
                  Privacy Policy
                </a>
              </div>
            </div>
            <div className="agree-item">
              <div className="icon-check-agree" onClick={() => handleCheckItem('check3')}>
                {isCheck.check3 ? (
                  <img src={checked_connect} alt="Check Icon" />
                ) : isDarkMode ? (
                  <img src={check_connect} alt="Check Icon" />
                ) : (
                  <img src={check_connect_light} alt="Check Icon" />
                )}
              </div>
              <div className="wrapper-agree-left">
                <div>Receive SMS and E-mails for promotions</div>
              </div>
            </div>
          </div>
        </div>
        {provider !== 'WALLET' && (
          <div className="agree-background">
            <div className="agree-all">
              <div className="icon-check-all" onClick={handleCheckAllABC}>
                {isCheckAllABC ? (
                  <img src={checked_all_connect} alt="Check All" />
                ) : isDarkMode ? (
                  <img src={check_all_connect} alt="Check All" />
                ) : (
                  <img src={check_all_connect_light} alt="Check All" />
                )}
              </div>
              <div className="content-agree">ABC Wallet Terms and Conditions</div>
            </div>
            <div className="wrapper-agree">
              <div className="agree-item">
                <div className="icon-check-agree" onClick={() => handleCheckABC('check1')}>
                  {isCheckABC.check1 ? (
                    <img src={checked_connect} alt="Check Icon" />
                  ) : isDarkMode ? (
                    <img src={check_connect} alt="Check Icon" />
                  ) : (
                    <img src={check_connect_light} alt="Check Icon" />
                  )}
                </div>
                <div className="wrapper-agree-left">
                  <span>*</span>
                  <div>I am over 14 years old.</div>
                </div>
              </div>
              <div className="agree-item">
                <div className="icon-check-agree" onClick={() => handleCheckABC('check2')}>
                  {isCheckABC.check2 ? (
                    <img src={checked_connect} alt="Check Icon" />
                  ) : isDarkMode ? (
                    <img src={check_connect} alt="Check Icon" />
                  ) : (
                    <img src={check_connect_light} alt="Check Icon" />
                  )}
                </div>
                <div className="wrapper-agree-left">
                  <span>*</span>
                  <div>I agree to</div>
                  <a
                    href="https://api.id.myabcwallet.com/query/terms?language=2&service=4"
                    target={'_blank'}
                    className="text-terms a"
                  >
                    the Terms of Use.
                  </a>
                </div>
              </div>
              <div className="agree-item">
                <div className="icon-check-agree" onClick={() => handleCheckABC('check3')}>
                  {isCheckABC.check3 ? (
                    <img src={checked_connect} alt="Check Icon" />
                  ) : isDarkMode ? (
                    <img src={check_connect} alt="Check Icon" />
                  ) : (
                    <img src={check_connect_light} alt="Check Icon" />
                  )}
                </div>
                <div className="wrapper-agree-left">
                  <span>*</span>
                  <div>I agree to</div>
                  <a
                    href="https://api.id.myabcwallet.com/query/privacy?language=2&service=4"
                    target={'_blank'}
                    className="text-terms a"
                  >
                    the Privacy Policy.
                  </a>
                </div>
              </div>
              <div className="agree-item">
                <div className="icon-check-agree" onClick={() => handleCheckABC('check4')}>
                  {isCheckABC.check4 ? (
                    <img src={checked_connect} alt="Check Icon" />
                  ) : isDarkMode ? (
                    <img src={check_connect} alt="Check Icon" />
                  ) : (
                    <img src={check_connect_light} alt="Check Icon" />
                  )}
                </div>
                <div className="wrapper-agree-left">
                  <span>*</span>
                  <div>I agree to provide</div>
                  <a
                    href="https://api.id.myabcwallet.com/query/third-party?language=2&service=4"
                    target={'_blank'}
                    className="text-terms a"
                  >
                    personal information to third-party.
                  </a>
                </div>
              </div>
              <div className="agree-item">
                <div className="icon-check-agree" onClick={() => handleCheckABC('check5')}>
                  {isCheckABC.check5 ? (
                    <img src={checked_connect} alt="Check Icon" />
                  ) : isDarkMode ? (
                    <img src={check_connect} alt="Check Icon" />
                  ) : (
                    <img src={check_connect_light} alt="Check Icon" />
                  )}
                </div>
                <div className="wrapper-agree-left">
                  <div className="lh-xs-16">I agree to receive</div>
                  <a
                    href="https://api.id.myabcwallet.com/query/marketing?language=2&service=4"
                    target={'_blank'}
                    className="text-terms a lh-xs-16"
                  >
                    marketing information.
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        <button
          disabled={checkDisable()}
          className="signup-kakao button"
          onClick={() => {
            // 가입
            const data = {
              username: name,
              isAgreeResv: isCheck.check3,
              email: email,
              abc_address: '',
              abc_license: {},
              refererUid,
            };
            const register = async () => {
              setIsLoading(true);
              try {
                if (refererUid !== '') {
                  const isExist = (await refererUidCheck({ refererUid }))?.data;
                  if (!isExist) return alert('Invitation code is not valid.');
                }

                data.abc_address = provider === 'WALLET' ? null : await registerAbc();
                data.abc_license = provider === 'WALLET' ? null : isCheckABC;
                // console.log('!!!!!!!!!! Register new user : ', { data });
                const res = await userRegister(data);
                const loginType = localStorage.getItem('login');
                if (res.data.type === 'RESULT') {
                  // 성공. 리다이렉트..
                  // alert('가입이 완료되었습니다. \n다시 로그인 해주세요.');
                  if (loginType !== 'metamask') alert('You have signed up. \nPlease log in again.');
                  window.localStorage.removeItem('ref');
                  location.replace('/');
                }
              } finally {
                setIsLoading(false);
              }
            };
            register();
          }}
        >
          {isLoading ? <CircularProgress size={30} color={'inherit'} /> : <>Sign Up</>}
        </button>
      </div>
    </div>
  );
};

export default RegisterPage;
