import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getMboxItemListMboxId } from '../../services/services';
import { MBoxTypes } from '../../types/MBoxTypes';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import { MBoxItemTypes } from '../../types/MBoxItemTypes';
import { getItemAmount, getItemAmountNoSigner, getItemRemains } from 'utils/transactions';
import { getPrice } from '../../utils/getPrice';
import getAbcWallet from '../../utils/getAbcWallet';
import { getPriceRecentlyListedItems } from 'utils/getPriceRecentlyListedItems';

type CollectionItemType = MBoxItemTypes & {
  remainingAmount: number;
  price: number;
  onsale: boolean;
};

type ExMBoxType = MBoxTypes & {
  companyLogo: string;
  companyName: string;
};

type CollectionListProps = {
  collectionId: string | undefined;
  collectionInfo: ExMBoxType | null;
  companyLogo: string | undefined;
  companyName: string | undefined;
  quote: string | null | undefined;
};

const CollectionSaleItems: React.FC<CollectionListProps> = ({
  collectionId,
  collectionInfo,
  companyLogo,
  companyName,
  quote,
}) => {
  const params = useParams();

  const { account, library, activate, chainId } = useActiveWeb3React();
  const [collectionItemList, setCollectionItemList] = useState<CollectionItemType[]>([]);

  useEffect(() => {
    const fetchMBoxList = async () => {
      if (collectionId) {
        const res = await getMboxItemListMboxId(collectionId.toString());
        // get abc wallet address and check OTP ready
        const { isAbcReady, abcAddress } = getAbcWallet();

        // if (res.data.list && ((library && library.connection) || isAbcReady)) {
        if (res.data.list) {
          const newList = await Promise.all(
            res.data.list.map(async (item: MBoxTypes, index: number) => {
              // let remaining = null;
              // if (library && library.connection)
              //   remaining = await getItemAmount(
              const remaining = await getItemAmountNoSigner(
                collectionInfo?.boxContractAddress,
                index,
                2, // 1 = MysteryBox, 2 = Collection
                account,
                // library
                collectionInfo?.chainId ?? 8217
              );

              return {
                ...item,
                remainingAmount: remaining,
                index,
                collectionInfo: collectionInfo,
              };
            })
          );
          setCollectionItemList(newList);
        }
      }
    };

    fetchMBoxList();
  }, [library]);

  return (
    <div className="marketplace min-height-content">
      <div className="marketplace-collection-tittle">Featured Collectibles</div>
      <div className="marketplace-items">
        {collectionItemList.map((item, index) => {
          return (
            <Link
              to={`/collection/${params.id}/${item.id}`}
              // state={{ item: { ...item, companyLogo, companyName, quote } }}
              key={index}
            >
              <div className="item_product">
                <div className="item_product_detail MARKETPLACE_TOTAL_KEY fw-600">
                  <div className="total_item">Total Items: {'1a'}</div>
                  <div className="chain-name">ERC-1155</div>
                </div>
                {/* <div className="item_product_detail MARKETPLACE_TYPE_KEY fw-600"><div>ERC-721</div></div> */}
                <div className="item_product_detail MARKETPLACE_GRAPHICS_KEY">
                  {/* <div className="card-image"> */}
                  {/* <img src={item.itemImage} alt="" /> */}
                  {/* </div> */}
                  <div className="img-token">
                    <img src={item.itemImage} alt="" />
                    <div className="position-absolute price-and-remaining">
                      <div className="wrapper-price text-center">
                        <div className="price-header color-aaa fs-12 fs-xs-10">Price</div>
                        {/* <div className="current-price fs-14 fs-xs-12 fw-7">{getPrice(item.price, quote!)}</div> */}
                        <div className="fs-14 fw-7">
                          {getPriceRecentlyListedItems(item.price)}
                          {item.price !== null && <span className="fs-14 fs-xs-10 m-l-4">{quote!}</span>}
                        </div>
                      </div>
                      <div className="wrapper-remaining text-center">
                        <div className="remaining-header color-aaa fs-12 fs-xs-10">Remaining</div>
                        <div className={`quantity-remaining fs-14 fs-xs-12 fw-7`}>{item.remainingAmount}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item_product_detail MARKETPLACE_AUTHOR_KEY">
                  <div className="wrapper-item">
                    <div className="content-left">
                      <div className="avatar">
                        <img src={companyLogo} alt="" draggable={false} />
                      </div>
                      <div className="mb-0 mt-0">{companyName}</div>
                    </div>
                  </div>
                  {/* <div className="owner_product"> */}
                  {/* <div className="owner_product_box"> */}
                  {/* <div className="owner_product_avatar"> */}
                  {/* <img src={companyLogo} alt="" /> */}
                  {/* </div> */}
                  {/* <div className="">{companyName}</div> */}
                  {/* </div> */}
                  {/* <div> */}
                  {/*<Link*/}
                  {/*  to={`/collection/${params.id}/${item.id}`}*/}
                  {/*  // state={{*/}
                  {/*  //   item: { ...item, companyLogo, companyName, quote },*/}
                  {/*  // }}*/}
                  {/*>*/}
                  {/* <div className="status ">{item.onsale ? 'Buy Now' : 'Waiting'}</div> */}
                  {/*</Link>*/}
                  {/* </div> */}
                  {/* </div> */}
                </div>
                <div className="item_product_detail MARKETPLACE_NAME_KEY">
                  <div className="product_name ">{item.name}</div>
                </div>
                {/* <div className="item_product_detail MARKETPLACE_BID_KEY">
                  <div className="box-price">
                    <div className="price ">Price</div>
                    <div className="currency ">{getPrice(item.price, quote!)}</div>
                  </div>
                </div>
                <div className="item_product_detail MARKETPLACE_NAME_TIME">
                  <div>
                    <div className="remaining ">Remaining</div>
                    <div className="remaining-total ">{item.remainingAmount}</div>
                  </div>
                </div> */}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default CollectionSaleItems;
