/******************************************************************************
 *
 *  (C) 2022 AhnLab Blockchain Company, Inc. All rights reserved.
 *  Any part of this source code can not be copied with any method without
 *  prior written permission from the author or authorized person.
 *
 ******************************************************************************/

import { isHexString, isValidAddress, isValidChecksumAddress } from 'ethereumjs-util';

export const ascii_to_hexa = (str) => {
    var arr1 = [];
    for (var n = 0, l = str.length; n < l; n++) {
        var hex = Number(str.charCodeAt(n)).toString(16);
        arr1.push(hex);
    }
    return arr1.join('');
};

export const padWithZeroes = (number: string, length: number): string => {
    let myString = `${number}`;
    while (myString.length < length) {
        myString = `0${myString}`;
    }
    return myString;
};

export const addHexPrefix = (str) => {
    if (typeof str !== 'string' || str.match(/^-?0x/u)) {
        return str;
    }

    if (str.match(/^-?0X/u)) {
        return str.replace('0X', '0x');
    }

    if (str.startsWith('-')) {
        return str.replace('-', '-0x');
    }

    return `0x${str}`;
};

/**
 * Validates that the input is a hex address. This utility method is a thin
 * wrapper around ethereumjs-util.isValidAddress, with the exception that it
 * does not throw an error when provided values that are not hex strings. In
 * addition, and by default, this method will return true for hex strings that
 * meet the length requirement of a hex address, but are not prefixed with `0x`
 * Finally, if the mixedCaseUseChecksum flag is true and a mixed case string is
 * provided this method will validate it has the proper checksum formatting.
 * @param {string} possibleAddress - Input parameter to check against
 * @param {Object} [options] - options bag
 * @param {boolean} [options.allowNonPrefixed] - If true will first ensure '0x'
 *  is prepended to the string
 * @param {boolean} [options.mixedCaseUseChecksum] - If true will treat mixed
 *  case addresses as checksum addresses and validate that proper checksum
 *  format is used
 * @returns {boolean} whether or not the input is a valid hex address
 */
export const isValidHexAddress = (possibleAddress, { allowNonPrefixed = true, mixedCaseUseChecksum = false } = {}) => {
    const addressToCheck = allowNonPrefixed ? addHexPrefix(possibleAddress) : possibleAddress;
    if (!isHexString(addressToCheck)) {
        return false;
    }

    if (mixedCaseUseChecksum) {
        const prefixRemoved = addressToCheck.slice(2);
        const lower = prefixRemoved.toLowerCase();
        const upper = prefixRemoved.toUpperCase();
        const allOneCase = prefixRemoved === lower || prefixRemoved === upper;
        if (!allOneCase) {
            return isValidChecksumAddress(addressToCheck);
        }
    }

    return isValidAddress(addressToCheck);
};

export const trimUrl = (url: string) => {
    return url.replace(/\/$/, '');
};

export const isValidURL = (string: string) => {
    var res = string.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    );
    return res !== null;
};
