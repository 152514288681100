import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import close_icon from '../../assets/icon/close_icon.svg';
import close_icon_black from '../../assets/icon/close_icon_black.svg';
import mockLogo from 'assets/svg/rare_logo.svg';
import { useThemeMode } from 'components/common/AppStoreType';
import CountDownTimer from 'components/TimeCounter/CountDownTimer';
import useScreenSize from 'components/common/useScreenSize';
import Select from 'react-select';
import { useMediaQuery } from 'react-responsive';
import { CircularProgress } from '@mui/material';
import { ethers } from 'ethers';
import { ChainId, FAILURE, RPC_URLS, SUCCESS } from '../../config';
import { seaport } from '../../utils/seaport';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import getAbcWallet from '../../utils/getAbcWallet';
import contracts from '../../config/constants/contracts';
import env from '../../env';
import { useSelector } from 'react-redux';
import { cancelSell, registerBidOffer, registerHistory, getUserById } from '../../services/services';
import HelperUtil from '../../utils/HelperUtil';
import { useNavigate } from 'react-router-dom';
import { getActiveWallet } from '../../utils/getActiveWallet';
import { approveKIP7, getAllBalances, getErc20Balance, wrapKlay } from '../../utils/transactions';
import talken_logo from '../../../src/talkclaim/talk.png';
import makeBlockie from 'ethereum-blockies-base64';
import { setupNetwork } from '../../utils/wallet';
import talk from '../../talkclaim/talk.png';
import talkPolygon from '../../assets/icon/talkPolygon.svg';
import talkKlaytn from '../../assets/icon/TANK_symbol-Klaytn.svg';
import talkBinance from '../../assets/icon/talkBinance.svg';
import { getNetworkNameById } from '../../utils/getNetworkNameById';

type DetailNFTModal = {
  show: any;
  onHide: any;
  typeSale: string;
  openOTPCodeModal: any;
  sellData?: any;
  klayPrice?: any;
  maticPrice?: any;
  bnbPrice?: any;
  ethPrice?: any;
  talkPrice?: any;
  floorPrice?: any;
  setReload?: any;
  setTopPrice?: any;
  topPrice?: string;
  minInc?: any;
  openFullfill?: any;
  openPurchaseComplete?: any;
};
const optionsTypeMoney = [
  { value: 'talk', label: 'TALK' },
  // { value: 'eth', label: 'ETH' },
  // { value: 'bnb', label: 'BNB' },
  // { value: 'matic', label: 'MATIC' },
  // { value: 'klay', label: 'KLAY' },
];
const optionsPayType = [
  { value: 'witheth', label: 'Pay with ETH' },
  { value: 'withklay', label: 'Pay with KLAY' },
  { value: 'withmatic', label: 'Pay with MATIC' },
  { value: 'withbnb', label: 'Pay with BNB' },
  { value: 'withtalk', label: 'Pay with TALK' },
];

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
const DetailNFTModal: React.FC<DetailNFTModal> = ({
  show,
  onHide,
  typeSale,
  openOTPCodeModal,
  sellData,
  klayPrice,
  maticPrice,
  bnbPrice,
  ethPrice,
  talkPrice,
  floorPrice,
  setReload,
  setTopPrice,
  topPrice,
  openFullfill,
  openPurchaseComplete,
}) => {
  if (!sellData) console.log('!!!! DetailNFTModal : sellData null or undefined');
  const { isDarkMode } = useThemeMode();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user.user);
  const uid = user.uid;
  const checkLogin = user.uid !== ''; // window.localStorage.getItem('nftapiJwtToken');
  const [loading, setLoading] = useState(false);
  const timer = useRef<number>();
  const [inputPrice, setInputPrice] = useState('');

  const { account, library, chainId } = useActiveWeb3React();
  const { isAbcReady, abcAddress } = getAbcWallet();
  const myWallet = getActiveWallet();
  const login = window.localStorage.getItem('login');

  const [balances, setBalances] = useState([]);
  const [talkBals, setTalkBals] = useState([]);
  // const [wklayBal, setWklayBal] = useState('0.0');
  // const [usdcBal, setUsdcBal] = useState('0.0');
  const [owner, setOwner] = useState<any>();

  const [index, setIndex] = useState(1);
  const Chains = [
    env.REACT_APP_TARGET_NETWORK_ETH,
    env.REACT_APP_TARGET_NETWORK_KLAY,
    env.REACT_APP_TARGET_NETWORK_MATIC,
    env.REACT_APP_TARGET_NETWORK_BNB,
  ];
  const getTargetChainId = (chain: string) => {
    switch (chain) {
      case 'ethereum':
        setIndex(0);
        return env.REACT_APP_TARGET_NETWORK_ETH;
      case 'binance':
        setIndex(3);
        return env.REACT_APP_TARGET_NETWORK_BNB;
      case 'polygon':
        setIndex(2);
        return env.REACT_APP_TARGET_NETWORK_MATIC;
      case 'klaytn':
      default:
        setIndex(1);
        return env.REACT_APP_TARGET_NETWORK_KLAY;
    }
  };

  const [target, setTarget] = useState(0);
  const switchNetwork = async (chain: number) => {
    await setupNetwork(chain, false, onHide);
  };
  useEffect(() => {
    if (sellData && sellData.nftInfo && show) {
      const targetChain = getTargetChainId(sellData?.nftInfo?.chain);
      setTarget(targetChain);
      if (login !== 'sns' && target !== 0) switchNetwork(targetChain);
    }
  }, [sellData, show]);

  const fetchTalkBalances = async () => {
    const values = [];
    for (let i = 0; i < Chains.length; i++) {
      const contract = contracts?.talken[Chains[i]];
      if (contract !== '' && contract !== undefined && contract !== null) {
        const value = await getErc20Balance(contract, myWallet, Chains[i], 18);
        values.push(value);
      }
    }
    setTalkBals(values);
  };

  const fetchAllBalances = async () => {
    const baseBalances = await getAllBalances(myWallet);
    setBalances(baseBalances);

    // let value = await getErc20Balance(contracts?.wklay[chainId ?? target], myWallet, chainId ?? target, 18);
    // setWklayBal(value);
    //
    // value = await getErc20Balance(contracts?.usdc[chainId ?? target], myWallet, chainId ?? target, 6);
    // setUsdcBal(value);
  };
  useEffect(() => {
    if (myWallet) fetchAllBalances();
  }, [account, myWallet]);
  useEffect(() => {
    if (myWallet) fetchTalkBalances();
  }, [account, myWallet]);

  useEffect(() => {
    const getOwner = async () => {
      const res = await getUserById(sellData?.uid);
      if (res.status === 200) {
        setOwner(res.data?.user);
      }
    };
    getOwner();
  }, [sellData]);

  const handleSetSale = async () => {
    const valueTyping = parseFloat(inputPrice);
    if (isNaN(valueTyping) && typeSale === 'auctionsale') {
      alert('Please enter a price.');
      return;
    }
    if (!checkLogin) navigate('/accounts');

    let maximum = 0.0;
    if (sellData.quote === 'TALK') {
      if (parseFloat(talkBals[index]) < parseFloat(topPrice)) {
        console.log('!! Does not have the balances needed to fulfill : ', {
          index,
          balance: talkBals[index],
          topPrice,
        });
        // alert('Does not have the balances needed to fulfill.');
        openFullfill();
        return;
      }
      maximum = parseFloat(talkBals[index]);
    } else {
      if (parseFloat(balances[index]) < parseFloat(topPrice) * 0.95) {
        console.log('!! Does not have the balances needed to fulfill : ', {
          index,
          balance: balances[index],
          topPrice,
        });
        // alert('Does not have the balances needed to fulfill.');
        openFullfill();
        return;
      }
      maximum = parseFloat(balances[index]);
    }

    console.log('!!!!! start purchase : ', sellData);

    if (parseFloat(inputPrice) > maximum) {
      alert('Can not bid bigger than my balance !!');
      return;
    }

    if (!loading) {
      setLoading(true);

      if (typeSale === 'auctionsale') {
        if (floorPrice === 0) floorPrice = sellData.price;
        if (parseFloat(inputPrice) < parseFloat(floorPrice) + parseFloat(sellData.minInc)) {
          alert(`Input price >= ${parseFloat(floorPrice) + parseFloat(sellData.minInc)}`);
          setLoading(false);
          return;
        }

        let provider;
        if (login === 'sns') {
          console.log('!!!! DetailNFTModal target : ', target);
          provider = await HelperUtil.getAbcWeb3Provider(target);
        }

        // let result = SUCCESS;
        // if (sellData.quote.toUpperCase() === 'WKLAY') {
        //   // TODO : Wrap KLAY
        //   const gap = parseFloat(inputPrice) - parseFloat(wklayBal);
        //   if (gap > 0) {
        //     result = await wrapKlay(
        //       contracts.talk[chainId ?? target],
        //       myWallet,
        //       ethers.utils.parseEther(gap.toString()).toString(),
        //       login === 'sns' ? provider : library,
        //       false,
        //       (updatedTxHash) => {
        //         // if (updatedTxHash && updatedTxHash !== '') {
        //         // }
        //       }
        //     );
        //   }
        // }

        // if (result === SUCCESS) {
        let offer: any;
        try {
          /// TODO : Bid Offer for Time Auction
          console.log('!! start bid offer ...');

          try {
            const chain = target;
            let quoteAddress = null;
            if (sellData.quote.toUpperCase() === 'TALK') {
              quoteAddress = contracts.talken[chain];
            }

            offer = await seaport.bidOffer({
              signer: login === 'sns' ? provider : library.getSigner(),
              tokenContractAddress: sellData.nftInfo.contractAddress,
              tokenId: sellData.tokenId.toString(),
              itemType: sellData.contract_type === 'ERC1155' ? 3 : 2,
              price: inputPrice,
              quoteContractAddress: quoteAddress,
              endTime: Math.floor(new Date(sellData.endDate).getTime() / 1000).toString(),
              // creatorAddress: '0x1716C4d49E9D81c17608CD9a45b1023ac9DF6c73',
              quote: sellData.quote,
            });
          } catch (e: any) {
            console.log('!!!!! bid offer error', e.message);
            alert(e.message);
          }
        } catch (err: any) {
          console.log(err.message);
          alert(err.message);
        } finally {
          // TODO : Store into bid table ...
          if (
            offer &&
            typeof offer === 'object' &&
            offer?.parameters &&
            offer !== 'MetaMask Typed Message Signature: User denied message signature.'
          ) {
            const data = {
              uid,
              ethAddress: myWallet,
              sellbookId: sellData.id,
              bidInfo: offer,
              price: parseFloat(inputPrice),
              quote: sellData.quote,
            };
            const result = await registerBidOffer(data);
            console.log('!! offerWithCrypto result = ', { data, result });
            if (result.data.status === SUCCESS) {
              console.log('!!!!!!!!!!!!!! Place Bid : SUCCESS');
              setReload(true);
              setTopPrice(inputPrice);
            } else {
              console.log('!!!!!!!!!!!!!! Place Bid : FAILURE');
            }
          }
          if (offer === 'MetaMask Typed Message Signature: User denied message signature.') {
            alert(offer);
          }
          if (typeof offer === 'string' && offer.includes('call revert exception')) {
            alert('call revert exception');
          }
          setLoading(false);
        }
        // } else {
        //   alert('wrapping KLAY ... failed');
        //   setLoading(false);
        // }
      } else {
        // TODO : fulfillment for Fixed Price
        try {
          console.log('!! start fulfillment ...');
          let provider;
          if (login === 'sns') {
            provider = await HelperUtil.getAbcWeb3Provider(target);
          } else {
            onHide();
            await setupNetwork(parseInt(target.toString() ?? env.REACT_APP_TARGET_NETWORK.toString()));
          }

          try {
            const receipt = await seaport.seaportFulfillOrder(
              login === 'sns' ? provider : library.getSigner(),
              sellData.sellInfo
            );
            console.log('!! receipt : ', receipt);
            if (receipt.status === SUCCESS) {
              // TODO : Remove sellbook
              let result = await cancelSell(sellData.id, sellData.uid);
              console.log('!!!!! cancelSell result :::::', result);

              // TODO : Store History
              const data = {
                fromUid: sellData.uid,
                toUid: uid,
                nftInfo: sellData.nftInfo,
                price: sellData.price,
                type: 'FIXED_BUY',
                txHash: receipt.transactionHash,
                quote: sellData.quote,
              };
              result = await registerHistory(data);
              console.log('!!!!! registerHistory result :::::', result);

              // TODO: Store Seller History
              // const sellerData = {
              //   fromUid: sellData.uid,
              //   toUid: uid,
              //   nftInfo: sellData.nftInfo,
              //   price: sellData.price,
              //   type: 'FIXED_BUY',
              //   txHash: receipt.transactionHash,
              //   quote: sellData.quote,
              //   seller: sellData.uid,
              // };
              // result = await registerHistory(sellerData);
              // console.log('!!!!! seller registerHistory result :::::', result);

              setReload(true);

              // TODO : to go where ???
              // navigate('/my-items');
              openPurchaseComplete();
            } else {
              console.log('!!!!! fulfillment failed');
              if (receipt === 'Incorrect twofactor verify token') return alert(receipt);
              if (receipt === 'MetaMask Tx Signature: User denied transaction signature.')
                return alert('User denied transaction signature.');
              if (receipt === 'MetaMask Tx Signature: User denied message signature.')
                return alert('User denied message signature.');
              if (receipt === 'The fulfiller does not have the balances needed to fulfill.')
                // return alert('The fulfiller does not have the balances needed to fulfill.');
                return openFullfill();
            }
          } catch (e: any) {
            return console.log('!!!!! fulfillment error', e.message);
          }
        } catch (err: any) {
          return console.log(err.message);
        } finally {
          setLoading(false);
        }
      }

      onHide();
    }
    onHide();
    // openOTPCodeModal();
  };

  const screenSize = useScreenSize();
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });
  const [countDownFinish, setCountDownFinish] = useState(false);

  const stylesTypeMoney = {
    control: (base, state) => ({
      ...base,
      background: isDarkMode ? '#202052' : '#FFFFFF1A',
      borderRadius: isMobile ? '4px' : '4px',
      borderColor: state.isFocused ? '#2F8AF5' : '#525298',
      boxShadow: state.isFocused ? 'none' : 'none',
      fontSize: isMobile ? '9px' : '12px',
      fontWeight: 500,
      width: isMobile ? '82px' : '80px',
      height: isMobile ? '30px' : '34px',
      minHeight: isMobile ? '30px' : '38px',
      cursor: 'pointer',
    }),
    singleValue: (base, state) => ({
      ...base,
      color: state.selectProps.menuIsOpen ? '#2F8AF5' : isDarkMode ? '#525298' : '#000000',
    }),
    valueContainer: (base) => ({
      ...base,
      height: '100%',
      placeContent: 'center',
      marginLeft: isMobile ? '6px' : '2px',
    }),
    option: (base, state) => ({
      ...base,
      fontSize: isMobile ? '9px' : '12px',
      fontWeight: state.isSelected ? 600 : 400,
      width: isMobile ? '82px' : '80px',
      color: state.isSelected ? '#2F8AF5' : '#777',
      backgroundColor: state.isSelected ? '#F0F5FF' : '#FFFFFF',
      ':active': {
        backgroundColor: state.isSelected ? '#FFFFFF' : '#FFFFFF',
        fontWeight: 600,
      },
      ':hover': {
        backgroundColor: '#944FFF',
        color: '#FFFFFF',
      },
      cursor: 'pointer',
    }),

    menu: (base) => ({
      ...base,
      fontSize: isMobile ? '9px' : '12px',
      background: '#FFFFFF',
      // override border radius to match the box
      borderRadius: isMobile ? '4px' : '4px',
      // kill the gap
      marginTop: 0,
      width: isMobile ? '82px' : '80px',
    }),
    menuList: (base) => ({
      ...base,
      fontSize: '12px',
      // kill the white space on last option
      padding: '0',
      width: isMobile ? '82px' : '80px',
      borderRadius: isMobile ? '4px' : '4px',
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: state.selectProps.menuIsOpen ? '#2F8AF5' : isDarkMode ? '#525298' : '#000000',
      placeContent: 'center',
      padding: isMobile ? '0px 2px 0px' : '0px 2px 0px',
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
  };
  const stylesPayType = {
    control: (base, state) => ({
      ...base,
      background: 'transparent',
      borderRadius: isMobile ? '6px' : '8px',
      borderColor: state.isFocused ? '#525298' : '#525298',
      boxShadow: state.isFocused ? 'none' : 'none',
      fontSize: isMobile ? '11px' : '16px',
      fontWeight: 500,
      width: '100%',
      height: isMobile ? '32px' : '48px',
      minHeight: isMobile ? '32px' : '38px',
      cursor: 'pointer',
    }),
    singleValue: (base) => ({
      ...base,
      color: isDarkMode ? '#525298' : '#000000',
    }),
    valueContainer: (base) => ({
      ...base,
      height: '100%',
      padding: isMobile ? '8px' : '2px 8px',
    }),
    option: (base, state) => ({
      ...base,
      fontSize: isMobile ? '11px' : '14px',
      fontWeight: 500,
      width: '100%',

      color: state.isSelected ? '#944FFF' : '#8C8C8C',
      backgroundColor: state.isSelected ? '#F0F5FF' : '#FFFFFF',
      ':active': {
        // backgroundColor: state.isSelected ? '#FFFFFF' : '#FFFFFF',
      },
      ':hover': {
        backgroundColor: '#944FFF',
        color: '#FFFFFF',
      },
      cursor: 'pointer',
    }),

    menu: (base) => ({
      ...base,
      fontSize: isMobile ? '11px' : '14px',
      background: 'white',
      borderRadius: isMobile ? '6px' : '10px',
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      fontSize: '12px',
      padding: '0',
      borderRadius: isMobile ? '6px' : '10px',
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: isDarkMode ? '#525298' : '#000000',
      placeContent: 'center',
      padding: isMobile ? '4px' : '8px',
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
  };
  const handeCheckCountDownFinish = () => {
    setCountDownFinish(true);
  };
  const handleInputPrice = (event) => {
    setInputPrice(event.target.value);
  };

  const priceInPlaceholder = (quote) => {
    switch (quote) {
      case 'talk':
      case 'ethereum':
        return talkBals[0] > 0 ? parseFloat(talkBals[0]).toFixed(8) : 0;
      case 'klaytn':
        return talkBals[1] > 0 ? parseFloat(talkBals[1]).toFixed(8) : 0;
      case 'polygon':
        return talkBals[2] > 0 ? parseFloat(talkBals[2]).toFixed(8) : 0;
      case 'binance':
        return talkBals[3] > 0 ? parseFloat(talkBals[3]).toFixed(8) : 0;
      default:
        return talkBals[0] > 0 ? parseFloat(talkBals[0]).toFixed(8) : 0;
    }
  };

  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className={`detail-nft-modal h-fit-content`}>
        <div className="close-button" onClick={onHide}>
          {isDarkMode ? <img src={close_icon} alt="icon close" /> : <img src={close_icon_black} alt="icon close" />}
        </div>
        <div className="title-nft line-clamp-2 p-t-32 p-t-xs-0">
          {sellData?.nftInfo?.metadata?.name
            ? sellData?.nftInfo?.metadata?.name
            : typeof sellData?.nftInfo?.name === 'string'
            ? sellData?.nftInfo?.name
            : sellData?.nftInfo?.name.en}
        </div>
        <div className="info-creator m-t-16">
          <img
            src={
              owner?.profile_image
                ? owner?.profile_image
                : owner?.eth_address
                ? makeBlockie(owner?.eth_address?.toLowerCase())
                : owner?.abc_address
                ? makeBlockie(owner?.abc_address?.toLowerCase())
                : talken_logo
            }
            alt="logo"
            className="logo-img"
          />
          <div className="logo-info opacity-7">
            <div className="creator">Owned by</div>
            <div className="name">{owner?.name || 'Talken'}</div>
          </div>
        </div>
        <div className="line-banner"></div>
        {!countDownFinish && (
          <CountDownTimer
            handeCheckCountDownFinish={handeCheckCountDownFinish}
            targetDate={sellData?.endDate}
            detailNFT={true}
            salesEnd={sellData?.endDate}
          />
        )}
        {/* sale period */}
        {/* <div className={`wrapper-bidding-period ${screenSize < 641 ? 'pt-12' : ''}`}>
          <div className="bidding-period-item">
            <div className="title-bidding-period">Listed on</div>
            <div className="detailed-bidding-period">{new Date(sellData?.startDate).toLocaleString()}</div>
          </div>
          <div className="bidding-period-item">
            <div className="title-bidding-period">Sales end</div>
            <div className="detailed-bidding-period">{new Date(sellData?.endDate).toLocaleString()}</div>
          </div>
        </div>
        <div className={`line-banner ${screenSize < 641 ? 'my-3' : 'my-4'}`}></div> */}
        {/* current price */}
        <div className="wrapper-bidding-period">
          {typeSale === 'auctionsale' && (
            <div className="bidding-period-item">
              <div className="title-bidding-period">Starting Price</div>
              <div className="detailed-bidding-period">
                {`${sellData?.price} ${sellData?.quote}`}
                <span className="fs-14 fs-xs-11 fw-400">
                  (~ $
                  {sellData?.quote === 'KLAY'
                    ? parseFloat((sellData?.price * klayPrice).toString()).toFixed(2)
                    : sellData?.quote === 'MATIC'
                    ? parseFloat((sellData?.price * maticPrice).toString()).toFixed(2)
                    : sellData?.quote === 'BNB'
                    ? parseFloat((sellData?.price * bnbPrice).toString()).toFixed(2)
                    : sellData?.quote === 'ETH'
                    ? parseFloat((sellData?.price * ethPrice).toString()).toFixed(2)
                    : sellData?.quote === 'TALK'
                    ? parseFloat((sellData?.price * talkPrice).toString()).toFixed(2)
                    : sellData?.price}
                  )
                </span>
              </div>
            </div>
          )}
          {typeSale === 'auctionsale' && (
            <div className="bidding-period-item">
              <div className="title-bidding-period">Minimum Bid Increments</div>
              <div className="detailed-bidding-period">
                {`${sellData?.minInc} ${sellData?.quote}`}
                <span className="fs-14 fs-xs-11 fw-400">
                  (~ $
                  {sellData?.quote === 'KLAY'
                    ? parseFloat((parseFloat(sellData?.minInc) * klayPrice).toString()).toFixed(2)
                    : sellData?.quote === 'MATIC'
                    ? parseFloat((parseFloat(sellData?.minInc) * maticPrice).toString()).toFixed(2)
                    : sellData?.quote === 'BNB'
                    ? parseFloat((parseFloat(sellData?.minInc) * bnbPrice).toString()).toFixed(2)
                    : sellData?.quote === 'ETH'
                    ? parseFloat((parseFloat(sellData?.minInc) * ethPrice).toString()).toFixed(2)
                    : sellData?.quote === 'TALK'
                    ? parseFloat((parseFloat(sellData?.minInc) * talkPrice).toString()).toFixed(2)
                    : sellData?.minInc}
                  )
                </span>
              </div>
            </div>
          )}
          <div className="bidding-period-item">
            <div className="title-bidding-period fw-400">Current Price</div>
            <div className="detailed-bidding-period fw-6">
              {`${topPrice} ${sellData?.quote}`}{' '}
              <span className="fs-14 fs-xs-11 fw-400">
                (~ $
                {sellData?.quote === 'KLAY'
                  ? parseFloat((parseFloat(topPrice) * klayPrice).toString()).toFixed(2)
                  : sellData?.quote === 'MATIC'
                  ? parseFloat((parseFloat(topPrice) * maticPrice).toString()).toFixed(2)
                  : sellData?.quote === 'BNB'
                  ? parseFloat((parseFloat(topPrice) * bnbPrice).toString()).toFixed(2)
                  : sellData?.quote === 'ETH'
                  ? parseFloat((parseFloat(topPrice) * ethPrice).toString()).toFixed(2)
                  : sellData?.quote === 'TALK'
                  ? parseFloat((parseFloat(topPrice) * talkPrice).toString()).toFixed(2)
                  : topPrice}
                )
              </span>
            </div>
          </div>
        </div>
        <div className="title-set-price">Price</div>
        {/* set price */}
        {typeSale === 'auctionsale' ? (
          <>
            <div className="wrapper-set-price-ver d-flex">
              <div className="wrapper-set-price-ver-icon-plholder">
                {sellData?.nftInfo?.chain === 'talk' &&
                  (isMobile ? (
                    <img width={20} height={20} className="m-r-10" src={talk} alt="" />
                  ) : (
                    <img width={24} height={24} className="m-r-10" src={talk} alt="" />
                  ))}
                {sellData?.nftInfo?.chain === 'ethereum' &&
                  (isMobile ? (
                    <img width={20} height={20} className="m-r-10" src={talk} alt="" />
                  ) : (
                    <img width={24} height={24} className="m-r-10" src={talk} alt="" />
                  ))}
                {sellData?.nftInfo?.chain === 'polygon' &&
                  (isMobile ? (
                    <img width={20} height={20} className="m-r-10" src={talkPolygon} alt="" />
                  ) : (
                    <img width={24} height={24} className="m-r-10" src={talkPolygon} alt="" />
                  ))}
                {sellData?.nftInfo?.chain === 'klaytn' &&
                  (isMobile ? (
                    <img width={20} height={20} className="m-r-10" src={talkKlaytn} alt="" />
                  ) : (
                    <img width={24} height={24} className="m-r-10" src={talkKlaytn} alt="" />
                  ))}
                {sellData?.nftInfo?.chain === 'binance' &&
                  (isMobile ? (
                    <img width={20} height={20} className="m-r-10" src={talkBinance} alt="" />
                  ) : (
                    <img width={24} height={24} className="m-r-10" src={talkBinance} alt="" />
                  ))}
                {/*<img width={24} height={24} className="m-r-10" src={talk} alt="" />*/}
              </div>
              <input
                type="text"
                placeholder={`Balance: ${priceInPlaceholder(sellData?.nftInfo?.chain?.toLowerCase())}`}
                onChange={handleInputPrice}
              />
              <div className="position-absolute right-1 top-12 right-xs-2 top-xs-4">
                <Select
                  value={
                    optionsTypeMoney[0] //   : 0 //   ? 3 //   : sellData?.quote === 'MATIC' //   ? 2 //   : sellData?.quote === 'BNB' //   ? 1 //   : sellData?.quote === 'ETH' //   ? 4 // sellData?.quote === 'KLAY'
                  }
                  options={optionsTypeMoney}
                  styles={stylesTypeMoney}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isSearchable={false}
                />
              </div>
            </div>
            <button className="btn-detail-nft button mb-0 h-50px" onClick={handleSetSale} disabled={loading}>
              {loading ? <CircularProgress size={30} color={'inherit'} /> : 'Place Bid'}
            </button>
          </>
        ) : (
          <>
            <div className={`${screenSize < 641 ? 'm-b-16' : 'mb-24'}`}>
              <Select
                value={
                  optionsPayType[
                    sellData?.quote === 'ETH'
                      ? 0
                      : sellData?.quote === 'KLAY'
                      ? 1
                      : sellData?.quote === 'MATIC'
                      ? 2
                      : sellData?.quote === 'BNB'
                      ? 3
                      : 4
                  ]
                }
                options={optionsPayType}
                styles={stylesPayType}
                isSearchable={false}
                isDisabled={true}
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder={'Select Pay type'}
              />
            </div>
            <button className="btn-detail-nft button mb-0 fw-7" onClick={handleSetSale} disabled={loading}>
              {loading ? <CircularProgress size={30} color={'inherit'} /> : 'Complete Purchase'}
            </button>
          </>
        )}
      </div>
    </ReactModal>
  );
};
export default DetailNFTModal;
