import React, { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-multi-carousel/lib/styles.css';
import { useParams } from 'react-router-dom';
import { FeaturedTypes } from '../../types/FeaturedTypes';
import { getFeaturedById } from '../../services/services';
import CollectionList from './CollectionList';
import useCopyToClipBoard from 'hooks/useCopyToClipboard';
import CSnackbar from '../../components/common/CSnackbar';
import { useMediaQuery } from 'react-responsive';
import GetSnsButtons from 'components/getsnsbuttons/GetSnsButtons';
import ConvertUrlsToLinks from '../../utils/convertUrlsToLinks';

const Collection = () => {
  const params = useParams();
  const isMobile = useMediaQuery({
    query: '(max-width: 640px)',
  });
  const { copyToClipBoard, copyResult, setCopyResult } = useCopyToClipBoard();
  const [featured, setFeatured] = useState<FeaturedTypes | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    type: '',
    message: '',
  });

  const handleCloseSnackbar = () => {
    setOpenSnackbar({
      open: false,
      type: 'info',
      message: '',
    });
    setCopyResult(false);
  };
  const hasCJK = (string) => {
    const regex = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f\u3131-\uD79D]/;
    return string?.match(regex) ? true : false;
  };
  useEffect(() => {
    const fetchFeatured = async () => {
      const res = await getFeaturedById(params.id!);
      if (res.status === 200) {
        setFeatured(res.data);
      }
    };
    fetchFeatured();
  }, []);

  useEffect(() => {
    setOpenSnackbar({
      open: copyResult,
      type: 'success',
      message: 'Copied!',
    });
  }, [copyResult]);

  return (
    <main className="collection-container pt-0 min-height-content">
      {featured ? (
        <>
          <div
            className="collection-banner-image"
            style={{
              backgroundImage: `url("${
                // isMobile ? featured.mobileBanner : featured.banner
                featured.banner
              }")`,
            }}
          ></div>
          <div className="box-collection">
            <div className="collection-details-box">
              <div className="collection-info">
                <div className="collection-info-left">
                  <img src={featured.image} alt="" draggable={false} />
                  <div className="name">
                    <div className="fullname">{featured.name.en}</div>
                    {/*<div className="username">{featured.company.name.en}</div>*/}
                  </div>
                </div>
                <GetSnsButtons links={featured.links} isCreator />
              </div>
              <div className={`collection-info-content ${hasCJK(featured.description.en) ? 'cjk-font' : ''}`}>
                <ConvertUrlsToLinks text={featured.description.en} />
              </div>
            </div>
            <CollectionList
              featuredId={featured.id}
              companyLogo={featured.company.image}
              companyName={featured.company.name.en}
            />
            {/*<div className="marketplace">*/}
            {/*  <div className="marketplace-collection-tittle">*/}
            {/*    Featured Collectibles*/}
            {/*  </div>*/}

            {/*  <div className="marketplace-items">*/}
            {/*    {list_products.map((item, index) => {*/}
            {/*      return (*/}
            {/*        <Link to={`/sale/${index}`} key={index}>*/}
            {/*          <div className="item_product">*/}
            {/*            <div className="item_product_detail MARKETPLACE_TOTAL_KEY fw-600">*/}
            {/*              <div className="total_item">Total Run: 50</div>*/}
            {/*            </div>*/}
            {/*            <div className="item_product_detail MARKETPLACE_TYPE_KEY fw-600">*/}
            {/*              <div>erc721</div>*/}
            {/*            </div>*/}
            {/*            <div className="item_product_detail MARKETPLACE_GRAPHICS_KEY">*/}
            {/*              <div className="card-image alt="" />*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*            <div className="item_product_detail MARKETPLACE_AUTHOR_KEY">*/}
            {/*              <div className="owner_product">*/}
            {/*                <div className="owner_product_box">*/}
            {/*                  <div className="owner_product_avatar">*/}
            {/*                    <img src={avatar} alt="" />*/}
            {/*                  </div>*/}
            {/*                  <div className="">{item.owner_name}</div>*/}
            {/*                </div>*/}
            {/*                <Link to="/sale">*/}
            {/*                  <div className="status ">Buy Now</div>*/}
            {/*                </Link>*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*            <div className="item_product_detail MARKETPLACE_NAME_KEY">*/}
            {/*              <div className="product_name ">{item.name}</div>*/}
            {/*            </div>*/}
            {/*            <div className="item_product_detail MARKETPLACE_BID_KEY">*/}
            {/*              <div className="box-price">*/}
            {/*                <div className="price ">Price</div>*/}
            {/*                <div className="currency ">$50.00</div>*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*            <div className="item_product_detail MARKETPLACE_NAME_TIME">*/}
            {/*              <div>*/}
            {/*                <div className="remaining ">Remaining</div>*/}
            {/*                <div className="remaining-total ">0</div>*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </Link>*/}
            {/*      );*/}
            {/*    })}*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          <CSnackbar
            open={openSnackbar.open}
            type={openSnackbar.type}
            message={openSnackbar.message}
            handleClose={handleCloseSnackbar}
          />
        </>
      ) : null}
    </main>
  );
};

export default Collection;
