import { initUser, setUser } from '../redux/slices/user';
import { getUser } from '../services/services';
import { Dispatch } from '@reduxjs/toolkit';

const updateUserRedux = async (dispatch: Dispatch) => {
  const userRes = await getUser();
  if (userRes.status === 200 && userRes.data.status != 0) dispatch(setUser(userRes.data.user));
  else dispatch(initUser()); // 서버에 세션 없으면 초기화
};

export default updateUserRedux;
