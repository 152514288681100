import { useEffect, useState } from 'react';
import logo_header from './logo_header.svg';
import round1 from './round1.png';
import round2 from './round2.png';
import './web3LandingPage.css';
const Web3LandingPage = () => {
  useEffect(() => {
    localStorage.setItem('theme', 'light');
  }, []);
  return (
    <div className="web3-landing-main">
      <div className="header">
        <div className="position-round">
          <img className="round1" src={round1} />
          <img className="round2" src={round2} />
        </div>
        <img src={logo_header} alt="" />
      </div>
      <div className="body">
        <div className="title">
          <h1>BROWSE YOUR FAVORITE WEB3 APPS</h1>
        </div>
        <div className="sub">
          <h5>With Talken Wallet</h5>
          <h5>That supports a total of 8 mainnets</h5>
          <h5>Including Ethereum, Solana, and Klaytn,</h5>
          <h5>You can use Web3 services of various mainnets.</h5>
        </div>
      </div>
    </div>
  );
};

export default Web3LandingPage;
