import ReactModal from 'react-modal';
import { ReactComponent as IconClose } from 'assets/icon/close_icon.svg';
import { ReactComponent as ModalSvg } from 'assets/svg/modal.svg';
import iconTest from 'assets/img/home_10_avt.jpg';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import getAbcWallet from '../../utils/getAbcWallet';
import { useNavigate } from 'react-router-dom';
import HelperUtil from '../../utils/HelperUtil';
import { seaport } from '../../utils/seaport';
import { deleteBid } from '../../services/services';
import { SUCCESS } from '../../config';
import env from '../../env';

type CancelOffer = {
  show: any;
  onHide: any;
  selected: any;
  setReload?: any;
  dataDetailMyItems?: any;
};
const CancelOffer: React.FC<CancelOffer> = ({ show, onHide, selected, setReload, dataDetailMyItems }) => {
  const { account, library, chainId } = useActiveWeb3React();
  const { isAbcReady, abcAddress } = getAbcWallet();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const login = window.localStorage.getItem('login');

  const [target, setTarget] = useState(0);
  useEffect(() => {
    switch (dataDetailMyItems?.sellbook?.nftInfo?.chain) {
      case 'ethereum':
        setTarget(env.REACT_APP_TARGET_NETWORK_ETH);
        break;
      case 'klaytn':
        setTarget(env.REACT_APP_TARGET_NETWORK_KLAY);
        break;
      case 'binance':
        setTarget(env.REACT_APP_TARGET_NETWORK_BNB);
        break;
      case 'polygon':
        setTarget(env.REACT_APP_TARGET_NETWORK_MATIC);
        break;
    }
  }, [dataDetailMyItems]);

  const handleCancel = async () => {
    setIsLoading(true);

    // TODO : Cancel Offer
    let provider;
    if (login === 'sns') {
      provider = await HelperUtil.getAbcWeb3Provider(target);
    }
    const order = selected.bidInfo;
    const params = [];
    params.push(order?.parameters);
    const receipt = await seaport.cancelOrders(login === 'sns' ? provider : library.getSigner(), params);
    console.log('!! cancelBids receipt :::::', receipt);

    if (receipt?.status === SUCCESS) {
      // Remove from bids table
      const result = await deleteBid(selected.id);
      setReload(true);
      console.log('!! deleteBid result :::::', result);
      onHide();
      window.location.reload();
    } else {
      console.log('!! deleteBid failed');
      setIsLoading(false);
      if (receipt === 'Incorrect twofactor verify token') alert(receipt);
      if (receipt === 'MetaMask Tx Signature: User denied transaction signature.')
        return alert('User denied transaction signature.');
      if (receipt === 'MetaMask Tx Signature: User denied message signature.')
        return alert('User denied message signature.');
    }

    setIsLoading(false);
    // onHide();
    // window.location.reload();
  };

  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className="modal-primary w-430px h-266px w-xs-338px h-xs-253px">
        {/* <div className="close-button-modal" onClick={onHide}>
          <IconClose className="svg-color dark:svg-color" />
        </div> */}
        <div className="p-t-24 p-t-xs-16 p-b-32 p-b-xs-26 p-l-24 p-r-24 p-l-xs-20 p-r-xs-20 d-flex flex-column align-items-center gap-8px">
          {/* <div className="w-182px h-182px w-xs-120px h-xs-120px">
            <img
              src={
                dataDetailMyItems?.sellbook?.nftInfo?.alt_url ||
                dataDetailMyItems?.sellbook?.nftInfo?.image ||
                dataDetailMyItems?.sellbook?.nftInfo?.metadata?.alt_url ||
                dataDetailMyItems?.sellbook?.nftInfo?.metadata?.image
                  ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                  .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')
              }
              alt="icon nft"
              className="w-100 h-100"
              style={{ objectFit: 'cover' }}
            />
          </div> */}
          <ModalSvg />
          <div className="text-center fs-20 fs-xs-16 fw-9 fw-xs-7 m-t-10 lh-24">Cancel this offer?</div>
          <div className="fs-12 fs-xs-10 title-input-modal fw-400 p-l-34 p-r-34 text-center d-grid">
            <span>You will cancel this offer for</span>
            <span className="fw-7 color-primary"> {dataDetailMyItems?.sellbook?.nftInfo?.name} </span>
          </div>
          <button className="button btn-detail-nft m-t-16 m-t-xs-24 mb-0 h-50px" onClick={handleCancel}>
            {isLoading ? <CircularProgress size={30} color={'inherit'} /> : <>Confirm</>}
          </button>
        </div>
      </div>
    </ReactModal>
  );
};
export default CancelOffer;
