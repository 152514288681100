import React, { useEffect, useRef, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-multi-carousel/lib/styles.css';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowUpRight } from '../../assets/icon/share.svg';
import { ReactComponent as SearchIcon } from '../../assets/icon/search-short.svg';
import { ReactComponent as MenuSelect } from 'assets/icon/menuselect.svg';
import { useSelector } from 'react-redux';
import { getHistory, getItemHistory } from '../../services/services';
import splitAddress from '../../utils/splitAddress';
import { moveToScope } from '../../utils/moveToScope';
import AggregateUserInfor from 'components/AggregateUserInfor/AggregateUserInfor';
import { getPrice } from '../../utils/getPrice';
import { Col, Row, Form, InputGroup } from 'react-bootstrap';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import Select from 'react-select';
import { useThemeMode } from '../../components/common/AppStoreType';
import ReactTooltip from 'react-tooltip';
import env from 'env';
import { ChainId, getTargetChainId } from '../../config';
import search_icon_dark_2 from '../../assets/svg/search_icon_dark_2.svg';
import search_icon_light_2 from '../../assets/svg/search_icon_light_2.svg';
// const optionType = [
//   { value: 'Type', label: 'Type' },
//   { value: 'Fixed price', label: 'Fixed price' },
//   { value: 'Auction', label: 'Auction' },
//   { value: 'Crypto', label: 'Crypto' },
// ];
// const optionChains = [
//   { value: 'Chains', label: 'Chains' },
//   { value: 'Ethereum', label: 'Ethereum' },
//   { value: 'Binance', label: 'BNB Chain' },
//   { value: 'Polygon', label: 'Polygon' },
//   { value: 'Klaytn', label: 'Klaytn' },
// ];
const PurchaseHistory = () => {
  const navigate = useNavigate();
  const [dbHistory, setDbHistory] = useState<any[]>([]);
  const [history, setHistory] = useState<any[]>([]);
  const [itemHistory, setItemHistory] = useState<any[]>([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [type, setType] = useState('Type');
  const [chainType, setChainType] = useState('Chains');
  const { binance } = useSelector((state: any) => state.wallet);
  const user = useSelector((state: any) => state.user.user);
  const uid = user.uid;
  const isLogin = uid !== '';
  const { isDarkMode } = useThemeMode();
  const [isExpanded, setExpanded] = useState(false);
  const [isTotalFilter, setIsTotalFilter] = useState(0);
  const [isClickAllChains, setIsClickAllChains] = useState(true);
  const [isClickAllType, setIsClickAllType] = useState(true);
  const [selectType, setSelectType] = useState({
    fixed: false,
    auction: false,
    crypto: false,
  });
  const [selectChains, setSelectChains] = useState({
    klaytn: false,
    ethereum: false,
    polygon: false,
    binance: false,
  });
  // const styleSelectRecently = {
  //   control: (base, state) => ({
  //     ...base,
  //     background: isDarkMode ? '#02021e' : '#ffffff',
  //     borderRadius: isMobile ? '8px' : '8px',
  //     borderColor: isDarkMode ? '#23235c' : '#D9D9D9',
  //     boxShadow: state.isFocused ? 'none' : 'none',
  //     fontSize: isMobile ? '14px' : '16px',
  //     fontWeight: 500,
  //     width: isMobile ? '125px' : '140px',
  //     height: isMobile ? '42px' : '42px',
  //     minHeight: isMobile ? '38px' : '38px',
  //     cursor: 'pointer',
  //   }),
  //   singleValue: (base) => ({
  //     ...base,
  //     color: isDarkMode ? '#FFFFFF' : '#000000',
  //   }),
  //   valueContainer: (base) => ({
  //     ...base,
  //     height: '100%',
  //     placeContent: 'center',
  //     marginLeft: isMobile ? '6px' : '2px',
  //   }),
  //   option: (base, state) => ({
  //     ...base,
  //     fontSize: isMobile ? '14px' : '16px',
  //     fontWeight: 500,
  //     width: isMobile ? '125px' : '140px',
  //     color: state.isSelected ? '#2f8af5' : isDarkMode ? '#FFFFFF' : '#000000',
  //     backgroundColor: isDarkMode ? '#23235c' : '#ffffff',
  //     ':active': {
  //       backgroundColor: state.isSelected ? '#23235c' : '#23235c',
  //       color: '#2f8af5',
  //     },
  //     ':hover': {
  //       // backgroundColor: '#23235c',
  //       color: '#2f8af5',
  //     },
  //     cursor: 'pointer',
  //   }),

  //   menu: (base) => ({
  //     ...base,
  //     fontSize: isMobile ? '14px' : '16px',
  //     background: isDarkMode ? '#23235c' : '',
  //     borderColor: isDarkMode ? '#23235c' : '',
  //     // override border radius to match the box
  //     borderRadius: isMobile ? '6px' : '6px',
  //     // kill the gap
  //     marginTop: 0,
  //     width: isMobile ? '125px' : '140px',
  //   }),
  //   menuList: (base) => ({
  //     ...base,
  //     fontSize: '1px',
  //     // kill the white space on last option
  //     padding: '0',
  //     width: isMobile ? '125px' : '140px',
  //     borderRadius: isMobile ? '6px' : '6px',
  //   }),
  //   dropdownIndicator: (base, state) => ({
  //     ...base,
  //     color: isDarkMode ? '#fff' : '#000000',
  //     placeContent: 'center',
  //     padding: isMobile ? '4px' : '8px',
  //     transition: 'all .2s ease',
  //     transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
  //   }),
  // };
  const moveToUrl = (item: any) => {
    let url;
    if (item.mysteryboxInfo.isCollection) {
      url = `/collection/${item.mysteryBoxId}/${item.itemId}`;
    } else {
      if (item.mysteryboxInfo.isAirdrop) {
        url = `/airdrop/${item.mysteryBoxId}/${item.itemId}`;
      } else {
        url = `/mbox/${item.mysteryboxInfo.id}`;
      }
    }

    navigate(url);
  };

  // useEffect(() => {
  //   getAllHistories();
  // }, [type, chainType, searchKeyword]);

  useEffect(() => {
    fetchItemHistory();
    fetchHistory();
  }, []);

  useEffect(() => {
    // getAllHistories();
    getAllHistoriesV2();
  }, [dbHistory, itemHistory]);

  const fetchHistory = async () => {
    let drops: React.SetStateAction<any[]> = [];
    if (uid) {
      const res = await getHistory(uid);
      if (res.status === 200) {
        drops = drops.concat(res.data.data.drops);
      }
    }

    if (binance?.address) {
      const res = await getHistory(binance?.address);
      if (res.status === 200) {
        drops = drops.concat(res.data.data.drops);
      }
    }
    setDbHistory(drops);
  };

  const fetchItemHistory = async () => {
    let c2cs: React.SetStateAction<any[]> = [];
    if (uid) {
      const res = await getItemHistory(uid);
      if (res.status === 200) {
        c2cs = c2cs.concat(res.data.data);
      }
      setItemHistory(c2cs);
    }
  };

  // const getAllHistories = () => {
  //   const allHistories = [];
  //   let _allHistories = [];
  //   // 두개의 data를 합치므로 id를 고유값으로 볼 수 없어서 새로운 아이디를 만든다.
  //   dbHistory.map((item, index) => {
  //     item.historyId = `db-${index}`;
  //     item.isC2c = false;
  //     allHistories.push(item);
  //   });
  //   itemHistory.map((item, index) => {
  //     item.historyId = `c2c-${index}`;
  //     item.isC2c = true;
  //     allHistories.push(item);
  //   });
  //   _allHistories = allHistories.slice().filter((drop) => {
  //     if (type === 'Fixed price') return drop.type === 'FIXED_BUY';
  //     if (type === 'Auction') return drop.type === 'AUCTION_BUY';
  //     if (type === 'Crypto') return drop.type === undefined;
  //     else return true;
  //   });
  //   _allHistories = _allHistories.filter((drop) => {
  //     if (chainType === 'Klaytn') return drop.mysteryboxInfo?.quote === 'klay' || drop.nftInfo?.chain === 'klaytn';
  //     if (chainType === 'Ethereum') return drop.mysteryboxInfo?.quote === 'eth' || drop.nftInfo?.chain === 'ethereum';
  //     if (chainType === 'Polygon') return drop.mysteryboxInfo?.quote === 'matic' || drop.nftInfo?.chain === 'polygon';
  //     if (chainType === 'Binance') return drop.mysteryboxInfo?.quote === 'bnb' || drop.nftInfo?.chain === 'binance';
  //     else return true;
  //   });
  //   _allHistories = _allHistories.filter((drop) => {
  //     return (
  //       drop.mysteryboxInfo?.title?.en?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
  //       drop.nftInfo?.name?.toLowerCase().includes(searchKeyword.toLowerCase())
  //     );
  //   });
  //   _allHistories = _allHistories.sort((a: any, b: any) => {
  //     return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  //   });
  //   setHistory(_allHistories);
  // };

  const getType = (type: string) => {
    if (type === 'FIXED_BUY') return 'Fixed price';
    if (type === 'AUCTION_BUY') return 'Auction';
    else return 'Crypto';
  };
  const tooltipContent = `From: ${'drop.fromUID'} To: ${'drop.toUID'}`;

  const getChainId = (chain) => {
    let chainId;
    switch (chain) {
      case 'klaytn':
        chainId = env.REACT_APP_TARGET_NETWORK_KLAY;
        break;
      case 'ethereum':
        chainId = env.REACT_APP_TARGET_NETWORK_ETH;
        break;
      case 'polygon':
        chainId = env.REACT_APP_TARGET_NETWORK_MATIC;
        break;
      case 'binance':
        chainId = env.REACT_APP_TARGET_NETWORK_BNB;
        break;
      default:
        break;
    }
    return chainId;
  };
  const getValueBlockchain = (data) => {
    let value = 'Binance';
    if (
      data?.mysteryboxInfo?.chainId === ChainId.KLAYTN ||
      data?.mysteryboxInfo?.chainId === ChainId.BAOBAB ||
      data?.nftInfo?.chain === 'klaytn'
    ) {
      value = 'Klaytn';
    } else if (
      data?.mysteryboxInfo?.chainId === ChainId.MAINNET ||
      data?.mysteryboxInfo?.chainId === ChainId.SEPOLIA ||
      data?.nftInfo?.chain === 'ethereum'
    ) {
      value = 'Ethereum';
    } else if (
      data?.mysteryboxInfo?.chainId === ChainId.POLYGON ||
      data?.mysteryboxInfo?.chainId === ChainId.MUMBAI ||
      data?.mysteryboxInfo?.chainId === ChainId.AMOY ||
      data?.nftInfo?.chain === 'polygon'
    ) {
      value = 'Polygon';
    } else if (
      data?.mysteryboxInfo?.chainId === ChainId.BSCMAINNET ||
      data?.mysteryboxInfo?.chainId === ChainId.BSCTESTNET ||
      data?.nftInfo?.chain === 'binance'
    ) {
      value = 'BNB Chain';
    }
    return value;
  };

  const handleExpand = () => {
    setExpanded(!isExpanded);
  };

  const clickButtonAllChains = () => {
    setIsClickAllChains(true);
    setSelectChains({
      klaytn: false,
      ethereum: false,
      polygon: false,
      binance: false,
    });
  };

  const clickButtonAllType = () => {
    setIsClickAllType(true);
    setSelectType({
      fixed: false,
      auction: false,
      crypto: false,
    });
  };

  const handleClickedChains = (chain: 'ethereum' | 'polygon' | 'klaytn' | 'binance') => {
    const newSelectChains = { ...selectChains, [chain]: !selectChains[chain] };
    setSelectChains(newSelectChains);
    setIsClickAllChains(false);
  };

  const handleClickedType = (type: 'fixed' | 'auction' | 'drops' | 'crypto') => {
    const newSelectType = { ...selectType, [type]: !selectType[type] };
    setSelectType(newSelectType);
    setIsClickAllType(false);
  };

  useEffect(() => {
    if (!selectChains?.ethereum && !selectChains?.polygon && !selectChains?.klaytn && !selectChains?.binance) {
      setIsClickAllChains(true);
    }
    if (!selectType?.fixed && !selectType?.auction && !selectType?.crypto) {
      setIsClickAllType(true);
    }
    const setClickFixed = selectType?.fixed ? 1 : 0;
    const setClickAuction = selectType?.auction ? 1 : 0;
    const setClickCrypto = selectType?.crypto ? 1 : 0;
    const setClickEthereum = selectChains?.ethereum ? 1 : 0;
    const setClickPolygon = selectChains?.polygon ? 1 : 0;
    const setClickKlaytn = selectChains?.klaytn ? 1 : 0;
    const setClickBinance = selectChains?.binance ? 1 : 0;
    setIsTotalFilter(
      setClickFixed +
        setClickAuction +
        setClickCrypto +
        setClickEthereum +
        setClickPolygon +
        setClickKlaytn +
        setClickBinance
    );
  }, [selectChains, selectType]);

  useEffect(() => {
    console.log('check');

    getAllHistoriesV2();
  }, [selectType, selectChains, searchKeyword]);

  useEffect(() => {
    if (!isLogin) location.replace('/accounts?redirectUrl=/history');
  }, []);

  const getAllHistoriesV2 = () => {
    const allHistories = [];
    let _allHistories = [];
    // 두개의 data를 합치므로 id를 고유값으로 볼 수 없어서 새로운 아이디를 만든다.
    dbHistory?.map((item, index) => {
      item.historyId = `db-${index}`;
      item.isC2c = false;
      allHistories.push(item);
    });
    itemHistory?.map((item, index) => {
      item.historyId = `c2c-${index}`;
      item.isC2c = true;
      allHistories.push(item);
    });

    const typeList = [];
    const chainList = [];
    const chainList2 = [];
    const chainList3 = [];
    if (isClickAllType || (!selectType['fixed'] && !selectType['auction'] && !selectType['crypto'])) {
      typeList.push('FIXED_BUY');
      typeList.push('AUCTION_BUY');
      typeList.push(undefined);
    } else {
      if (selectType['fixed']) typeList.push('FIXED_BUY');
      if (selectType['auction']) typeList.push('AUCTION_BUY');
      if (selectType['crypto']) typeList.push(undefined);
    }
    if (
      isClickAllChains ||
      (!selectChains['ethereum'] && !selectChains['binance'] && !selectChains['polygon'] && !selectChains['klaytn'])
    ) {
      chainList.push('eth');
      chainList.push('ethereum');
      chainList2.push('ethereum');
      chainList3.push(getTargetChainId('ethereum'));
      chainList.push('bnb');
      chainList2.push('binance');
      chainList3.push(getTargetChainId('binance'));
      chainList.push('polygon');
      chainList2.push('polygon');
      chainList3.push(getTargetChainId('polygon'));
      chainList.push('matic');
      chainList2.push('matic');
      chainList.push('klay');
      chainList2.push('klaytn');
      chainList3.push(getTargetChainId('klay'));
    } else {
      if (selectChains['ethereum']) {
        chainList.push('eth');
        chainList.push('ethereum');
        chainList2.push('ethereum');
        chainList3.push(getTargetChainId('ethereum'));
      }
      if (selectChains['binance']) {
        chainList.push('bnb');
        chainList2.push('binance');
        chainList3.push(getTargetChainId('binance'));
      }
      if (selectChains['polygon']) {
        chainList.push('polygon');
        chainList2.push('polygon');
        chainList.push('matic');
        chainList2.push('matic');
        chainList3.push(getTargetChainId('polygon'));
      }
      if (selectChains['klaytn']) {
        chainList.push('klay');
        chainList2.push('klaytn');
        chainList3.push(getTargetChainId('klay'));
      }
    }

    _allHistories = allHistories.filter((item) => {
      const typeCondition = typeList.includes(item.type);
      const chainCondition = chainList.includes(item.mysteryboxInfo?.quote);
      const chainCondition2 = chainList2.includes(item.nftInfo?.chain);
      const chainCondition3 = chainList3.includes(item.mysteryboxInfo?.chainId);
      return typeCondition && (chainCondition || chainCondition2 || chainCondition3);
    });

    _allHistories = _allHistories.filter((drop) => {
      return (
        drop.mysteryboxInfo?.title?.en?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        drop.nftInfo?.name?.toLowerCase().includes(searchKeyword.toLowerCase())
      );
    });

    _allHistories = _allHistories.sort((a: any, b: any) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

    setHistory(_allHistories);
    if (history?.length <= 0 && 'overflow-x-hidden') tableRef.current.scrollLeft -= 100;
  };
  const tableRef = useRef(null);
  return (
    <main className="purchase-container min-height-content">
      {!isMobile && <AggregateUserInfor />}
      <div className="purchase-history-page">
        <div className="purchase-history-header mb-4">History</div>
        {/* {isMobile && <AggregateUserInfor />} */}
        <div className={`d-flex justify-content-between gap-31px gap-xs-16px m-b-16 ${isMobile ? 'mt-4' : ''}`}>
          <div className="w-auto">
            <div
              className={`button gray-border pointer-event rounded-3 d-flex justify-content-between align-items-center  gap-10px h-40px svg-path-black dark:svg-path-white ${
                isExpanded ? 'dark:svg-path-updown-select updown-border-select' : 'dark:svg-path-updown updown-border '
              } ${isMobile ? 'col-4 ps-2 pe-2' : 'col-4 p-l-10 p-r-10 hover-opa-7'}${
                isTotalFilter > 0 ? ' w-55px' : ' w-40px'
              }`}
              onClick={() => handleExpand()}
            >
              <MenuSelect width={isTotalFilter > 0 ? 16 : 20} height={20} />
              {isTotalFilter > 0 && (
                <div className={`fw-400 fs-xs-12 ${isExpanded ? 'text-2f8af5' : ''}`}>{isTotalFilter}</div>
              )}
            </div>
          </div>
          <div className="d-flex flex-end w-50 w-xs-100">
            <div className="search h-40px w-63 w-xs-100">
              <input
                className="search-input fs-xs-12"
                placeholder="Search"
                value={searchKeyword}
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                }}
              ></input>
              <div className="search-icon">
                {isDarkMode ? (
                  <img src={search_icon_dark_2} alt="search icon dark" />
                ) : (
                  <img src={search_icon_light_2} alt="search icon" />
                )}
              </div>
            </div>
            {/* <InputGroup size="lg" className="mb-3 rounded-2 outline-search-history">
              <InputGroup.Text id="search-history" className="dark:bg-white-10 border-0">
                <SearchIcon width="14" height="14" />
              </InputGroup.Text>
              <Form.Control
                aria-label="Search"
                placeholder="Search by Item name"
                aria-describedby="search-history"
                className="dark:bg-white-10 border-0 text-black dark:text-white custom-placeholder"
                style={{ fontSize: '16px', lineHeight: '1.875' }}
                value={searchKeyword}
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                }}
              />
            </InputGroup> */}
          </div>
          {/* <Col sm="6" className="mb-4">
            <div className={`d-flex justify-content-end`}>
              <div className={``}>
                <Select
                  id="type_form"
                  options={optionType}
                  defaultValue={optionType[0]}
                  value={optionType.find((option) => option.value === type)}
                  isSearchable={false}
                  styles={styleSelectRecently}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(selectedOption) => {
                    setType(selectedOption.value);
                  }}
                />
              </div>
              <div className={`ms-3`}>
                <Select
                  id="type_form"
                  options={optionChains}
                  defaultValue={optionChains[0]}
                  value={optionChains.find((option) => option.value === type)}
                  isSearchable={false}
                  styles={styleSelectRecently}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(selectedOption) => {
                    setChainType(selectedOption.value);
                  }}
                />
              </div>
            </div>
          </Col> */}
        </div>
        {isExpanded && (
          <div className="d-grid w-100 fs-16 fs-xs-12 p-l-10 fw-7 gap-8px gap-xs-4px p-l-xs-0 p-b-6">
            <div className="d-flex gap-16px lh-16">
              <div className={`mode-color-white ${isMobile ? 'w-auto min-w-50px' : 'w-12'}`}>Chains</div>
              <div className="d-flex flex-wrap dark:text-525298 gap-16px gap-0-16 text-black fw-400">
                <Form.Check
                  checked={isClickAllChains}
                  label="All"
                  id="all-chains"
                  onChange={clickButtonAllChains}
                  className={isClickAllChains ? 'text-2f8af5' : ''}
                />
                <Form.Check
                  checked={selectChains['ethereum']}
                  label="Ethereum"
                  id="ethereum"
                  onChange={() => handleClickedChains('ethereum')}
                  className={selectChains['ethereum'] ? 'text-2f8af5' : ''}
                />
                <Form.Check
                  checked={selectChains['binance']}
                  label="BNB Chain"
                  id="binance"
                  onChange={() => handleClickedChains('binance')}
                  className={selectChains['binance'] ? 'text-2f8af5' : ''}
                />
                <Form.Check
                  checked={selectChains['polygon']}
                  label="Polygon"
                  id="polygon"
                  onChange={() => handleClickedChains('polygon')}
                  className={selectChains['polygon'] ? 'text-2f8af5' : ''}
                />
                <Form.Check
                  checked={selectChains['klaytn']}
                  label="Klaytn"
                  id="klaytn"
                  onChange={() => handleClickedChains('klaytn')}
                  className={selectChains['klaytn'] ? 'text-2f8af5' : ''}
                />
              </div>
            </div>
            <div className="d-flex gap-16px lh-16">
              <div className={`mode-color-white ${isMobile ? 'w-auto min-w-50px' : 'w-12'}`}>Type</div>
              <div className="d-flex dark:text-525298 gap-16px text-black fw-400">
                <Form.Check
                  checked={isClickAllType}
                  label="All"
                  id="all-types"
                  onChange={clickButtonAllType}
                  className={isClickAllType ? 'text-2f8af5' : ''}
                />
                <Form.Check
                  checked={selectType['fixed']}
                  label="Fixed"
                  id="fixed"
                  onChange={() => handleClickedType('fixed')}
                  className={selectType['fixed'] ? 'text-2f8af5' : ''}
                />
                <Form.Check
                  checked={selectType['auction']}
                  label="Auction"
                  id="auction"
                  onChange={() => handleClickedType('auction')}
                  className={selectType['auction'] ? 'text-2f8af5' : ''}
                />
                <Form.Check
                  checked={selectType['crypto']}
                  label="Crypto"
                  id="crypto"
                  onChange={() => handleClickedType('crypto')}
                  className={selectType['crypto'] ? 'text-2f8af5' : ''}
                />
              </div>
            </div>
          </div>
        )}
        <div className={`purchase-history-body ${history?.length <= 0 && 'overflow-x-hidden'}`} ref={tableRef}>
          <div className="purchase-history-content">
            <div className="table-row heading-row">
              <div className="title purchase_date">Date</div>
              <div className="title payment_type">Type</div>
              <div className="title name-header">Item</div>
              <div className="title amount">Price</div>
              <div className="title blockchain">Chain</div>
              <div className="title explorer_url-header">Tx Hash</div>
              <div className="icon-header title"></div>
            </div>
          </div>
          {history &&
            history.map((drop: any) => {
              const isCrypto = getType(drop.type) === 'Crypto';
              return (
                <div key={drop.historyId} className="purchase-history-content">
                  <div className="table-row">
                    <div className="value purchase_date">{moment(drop.createdAt).format('MMM DD, YYYY')}</div>
                    <div className="value payment_type">{getType(drop.type)}</div>
                    <div className="value nft name">
                      {drop.mysteryboxInfo?.creator && (
                        <div className="campaign_name fs-12 m-b-10">{drop.mysteryboxInfo?.creator}</div>
                      )}
                      <div
                        className="name line-clamp-1 hover-opa-7"
                        data-tip={
                          (drop.mysteryboxInfo?.title.en || drop.nftInfo?.name) &&
                          (drop.mysteryboxInfo?.title.en || drop.nftInfo?.name).length > 32
                            ? drop.mysteryboxInfo?.title.en || drop.nftInfo?.name
                            : ''
                        }
                        onClick={() => (!drop.isC2c ? moveToUrl(drop) : undefined)}
                      >
                        {drop.mysteryboxInfo?.title.en || drop.nftInfo?.name}
                      </div>
                      <ReactTooltip
                        className="react-tooltip"
                        place="bottom"
                        type="dark"
                        effect="solid"
                        multiline={true}
                      />
                    </div>
                    <div className="value amount">
                      {getPrice(
                        drop.price || drop.fiatPrice || 0,
                        drop.quote || drop.mysteryboxInfo?.quote?.toUpperCase() || 'null'
                      )}
                    </div>
                    {/* <ReactTooltip
                      className="react-tooltip"
                      place="bottom"
                      type="dark"
                      effect="solid"
                      multiline={true}
                    /> */}
                    <div className="value blockchain">{getValueBlockchain(drop)}</div>
                    <div
                      className="value explorer_url hover-opa-7"
                      data-tip={
                        !isCrypto && drop.txHash
                          ? `From: ${drop.fromUser?.name || drop.fromUid || 'None'}   To: ${
                              drop.toUser?.name || drop.toUid || 'None'
                            }`
                          : null
                      }
                      onClick={() => {
                        const chain = drop.mysteryboxInfo?.chainId || getChainId(drop.nftInfo?.chain);
                        if (drop.txHash) moveToScope(chain, drop.txHash);
                      }}
                    >
                      {drop.txHash ? splitAddress(drop.txHash) : ''}
                    </div>
                    {!isCrypto && drop.txHash && (
                      <ReactTooltip
                        className="react-tooltip"
                        place="bottom"
                        type="dark"
                        effect="solid"
                        multiline={true}
                      />
                    )}

                    <div className="icon value  hover-opa-7">
                      {drop.txHash && (
                        <div
                          className="arrow-up-right"
                          onClick={() => {
                            const chain = drop.mysteryboxInfo?.chainId || getChainId(drop.nftInfo?.chain);
                            if (drop.txHash) moveToScope(chain, drop.txHash);
                          }}
                        >
                          <ArrowUpRight className="path-black dark:path-white" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          {history?.length <= 0 && (
            <div className="text-black dark:text-white fs-14 fw-4 text-center bg-box-card p-t-16 p-b-24">
              “There is no history”
            </div>
          )}
        </div>
        {/* <div className="grid-col-2 d-grid grid-gap-20 d-sm-none">
          {history &&
            history.map((drop: any, index) => {
              return (
                <div key={index} className="border border-dark p-12 rounded-4">
                  <Row key={drop.historyId} className="m-b-10">
                    <Col xs="8">
                      <div className="text-white d-inline-block fw-bold fs-12 badge rounded-pill text-bg-purple">
                        {getType(drop.type)}
                      </div>
                    </Col>
                    <Col xs="4" className="d-flex justify-content-end">
                      {drop.txHash && (
                        <div
                          className="arrow-up-right"
                          onClick={() => {
                            const chain = drop.mysteryboxInfo?.chainId || getChainId(drop.nftInfo?.chain);
                            if (drop.txHash) moveToScope(chain, drop.txHash);
                          }}
                        >
                          <ArrowUpRight className="path-black dark:path-white" />
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="h-48px">
                      <Link
                        to={drop}
                        className="text-black fw-bolder word-break-history line-clamp-2 d-inline-block dark:text-white"
                      >
                        {(drop.mysteryboxInfo?.title.en || drop.nftInfo?.name).length > 26
                          ? `${(drop.mysteryboxInfo?.title.en || drop.nftInfo?.name).substring(0, 26)}...`
                          : drop.mysteryboxInfo?.title.en || drop.nftInfo?.name}
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="border border-dark border-top-0 mx-2 my-3 w-75"></Col>
                  </Row>
                  <Row className="fs-12">
                    <Col xs="3" className="opacity-50">
                      Date
                    </Col>
                    <Col xs="9" className="d-flex justify-content-end">
                      {moment(drop.createdAt).format('MMM DD, YYYY')}
                    </Col>
                    <Col xs="4" className="opacity-50">
                      Price
                    </Col>
                    <Col xs="8" className="d-flex justify-content-end">
                      {getPrice(
                        drop.price || drop.fiatPrice || 0,
                        drop.quote || drop.mysteryboxInfo?.quote?.toUpperCase() || 'null'
                      )}
                    </Col>
                    <Col xs="5" className="opacity-50">
                      Chain
                    </Col>
                    <Col xs="7" className="d-flex justify-content-end">
                      {getValueBlockchain(drop)}
                    </Col>
                    <Col xs="3" className="opacity-50">
                      From
                    </Col>
                    <Col xs="9" className="d-flex justify-content-end">
                      {drop.fromUser?.name || drop.fromUid || 'None'}
                    </Col>
                    <Col xs="3" className="opacity-50">
                      To
                    </Col>
                    <Col xs="9" className="d-flex justify-content-end">
                      {drop.toUser?.name || drop.toUid || 'None'}
                    </Col>
                  </Row>
                </div>
              );
            })}
        </div> */}
      </div>
    </main>
  );
};
export default PurchaseHistory;
