import ReactModal from 'react-modal';
import { ReactComponent as IconClose } from 'assets/icon/close_icon.svg';
import iconTest from 'assets/img/home_10_avt.jpg';
import { useNavigate } from 'react-router-dom';
import { HandleMediaError } from 'components/common/HandleMediaError';

type CancelListing = {
  show: any;
  onHide: any;
  dataDetailMyItems: any;
};
const CancelListing: React.FC<CancelListing> = ({ show, onHide, dataDetailMyItems }) => {
  const navigate = useNavigate();
  const goBack = () => {
    onHide();
    navigate('/my-items');
  };
  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className="modal-primary w-400px h-fit-content w-xs-338px">
        <div className="close-button-modal" onClick={goBack}>
          <IconClose className="svg-color dark:svg-color" />
        </div>
        <div className="p-t-84 p-t-xs-48 p-b-36 p-b-xs-26 p-l-30 p-r-30 p-l-xs-20 p-r-xs-20 d-flex flex-column align-items-center gap-2">
          <div className="w-182px h-182px w-xs-120px h-xs-120px">
            {dataDetailMyItems?.sellbook?.nftInfo?.image?.split('.')?.pop() === 'mp4' ||
            dataDetailMyItems?.sellbook?.nftInfo?.metadata?.image?.split('.')?.pop() === 'mp4' ||
            dataDetailMyItems?.sellbook?.nftInfo?.extension === 'mp4' ? (
              <video
                muted
                playsInline
                webkit-playsinline="true"
                controlsList="nodownload"
                className="w-100 h-100 border-radius-16"
                style={{ objectFit: 'cover' }}
                onError={HandleMediaError}
                key={(
                  dataDetailMyItems?.sellbook?.nftInfo?.image || dataDetailMyItems?.sellbook?.nftInfo?.metadata?.image
                )
                  ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                  .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}
              >
                <source
                  src={(
                    dataDetailMyItems?.sellbook?.nftInfo?.image || dataDetailMyItems?.sellbook?.nftInfo?.metadata?.image
                  )
                    ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                    .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}
                  type="video/mp4"
                />
              </video>
            ) : (
              <img
                src={
                  dataDetailMyItems?.sellbook?.nftInfo?.alt_url ||
                  dataDetailMyItems?.sellbook?.nftInfo?.image ||
                  dataDetailMyItems?.sellbook?.nftInfo?.metadata?.alt_url ||
                  dataDetailMyItems?.sellbook?.nftInfo?.metadata?.image
                    ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                    .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')
                }
                alt=""
                draggable={false}
                className="w-100 h-100"
                style={{ objectFit: 'cover' }}
                onError={HandleMediaError}
              />
            )}
          </div>
          <div className="text-center fs-20 fs-xs-16 fw-9 fw-xs-7 lh-23 lh-xs-inherit p-t-16 p-t-xs-0">
            Cancel Listing
          </div>
          <span className="fs-12 fs-xs-10 title-input-modal fw-6 p-l-34 p-r-34 text-center lh-16 lh-xs-inherit">
            <span className="fw-7 color-primary">{dataDetailMyItems?.sellbook?.nftInfo?.name} </span>
            has been canceled for listing.
          </span>
          <button className="button btn-detail-nft m-t-32 m-t-xs-28 mb-0" onClick={() => goBack()}>
            Go back your items
          </button>
        </div>
      </div>
    </ReactModal>
  );
};
export default CancelListing;
