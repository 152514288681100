import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import icon_wallet_act from '../../assets/img/icon_wallet_act.svg';
import { ReactComponent as Copy } from '../../assets/icon/copy3.svg';
import { ReactComponent as BtnCopy } from '../../assets/icon/btn_copy.svg';
import { useThemeMode } from '../common/AppStoreType';
import { accountRestApi, controllers } from '../../abc/background/init';
import { useDispatch } from 'react-redux';
import QRCode from 'react-qr-code';
import { isMobile } from 'react-device-detect';
import { CircularProgress } from '@mui/material';
import CSnackbar from 'components/common/CSnackbar';
import useCopyToClipBoard from 'hooks/useCopyToClipboard';
const ABCWalletCode = ({ show, onHide, typeModal, openWinningComplete }) => {
  const { abcController, accountController } = controllers;
  const dispatch = useDispatch();
  const { isDarkMode } = useThemeMode();
  const [qrCode, setQrCode] = useState('');
  const [qrSecret, setQrSecret] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const login = window.localStorage.getItem('login');

  const { copyToClipBoard, copyResult, setCopyResult } = useCopyToClipBoard();

  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    type: '',
    message: '',
  });

  useEffect(() => {
    setOpenSnackbar({
      open: copyResult,
      type: 'success',
      message: 'Copied!',
    });
  }, [copyResult]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, '').slice(0, 6);
    setInputValue(numericValue);
  };
  const handleComplete = async () => {
    console.log('!! verificationCode = ', inputValue);

    setIsLoading(true);
    try {
      // optToken : 입력 받은 OTP 값을 입력 받은 후 아래 코드 실행
      const twofaResetCode = await accountController.verifyTwoFactorGen({ token: inputValue });
      console.log('!! OTP 등록 = ', qrSecret, twofaResetCode);

      if (twofaResetCode) {
        // dispatch(setTwoFa({ secret: qrSecret, reset: twofaResetCode }));
        setResetCode(twofaResetCode);

        const abcAuth = JSON.parse(window.localStorage.getItem('abcAuth') as string);
        const { user, wallets } = await accountRestApi.getWalletsAndUserByAbcUid(abcAuth);
        console.log('!! user =', user);

        await accountController.recoverShare({ password: '!owdin001', user, wallets, keepDB: false }, dispatch);

        setIsLoading(false);
        alert('Registration has been completed.');
        onHide();
      } else {
        setIsLoading(false);
        alert('Verification code error.');
        setInputValue('');
      }
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
      alert('Verification code error.');
      setInputValue('');
    }
  };
  useEffect(() => {
    const abcUser = JSON.parse(window.localStorage.getItem('abcUser') as string);

    const getQrCode = async () => {
      const { qrcode, secret } = await accountController.generateTwoFactor({ reset: false });
      console.log('!! OTP =', qrcode, secret);
      const temp = `otpauth://totp/Talken: ${abcUser?.email}?secret=${secret}`;

      setQrCode(temp);
      setQrSecret(secret);
      // console.log('!! OTP Secret : ', secret);
      // console.log('!! OTP QR : ', temp);
    };
    if (login === 'sns' && !abcUser?.twoFactorEnabled) {
      getQrCode();
    }
  }, []);

  const handleCopy = (textToCopy) => {
    try {
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      textArea.style.position = 'fixed';
      textArea.style.top = '0';
      textArea.style.left = '0';
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      console.log(textToCopy);
      alert('Copied successfully!');
    } catch (error) {
      console.error('Unable to copy: ', error);
      alert('Copy failed. Please copy manually.');
    }
  };

  function isANumber(str) {
    return !/\D/.test(str);
  }

  const truncateString = (str: string, firstCharCount: number, endCharCount: number, dotCount: number) => {
    if (str.length <= firstCharCount + endCharCount) {
      return str; // No truncation needed
    }

    const firstPortion = str.slice(0, firstCharCount);
    const endPortion = str.slice(-endCharCount);
    const dots = '.'.repeat(dotCount);

    return `${firstPortion}${dots}${endPortion}`;
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar({
      open: false,
      type: 'info',
      message: '',
    });
  };
  const getOS = () => {
    const platform = navigator.platform.toLowerCase();

    if (platform.includes('win')) {
      return 'Windows';
    } else if (platform.includes('mac')) {
      return 'MacOS';
    } else if (platform.includes('iphone') || platform.includes('ipad') || platform.includes('ipod')) {
      return 'iOS';
    } else if (platform.includes('android')) {
      return 'Android';
    } else {
      return 'Other';
    }
  };

  const generateLink = () => {
    const abcUser = JSON.parse(window.localStorage.getItem('abcUser') as string);
    const os = getOS();
    let link;

    switch (os) {
      case 'Windows':
        link = 'https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DWindows&oco=0';
        break;
      case 'Android':
        link = `otpauth://totp/TalkenMarket: ${abcUser?.email}?secret=${qrSecret}`;
        break;
      case 'Other':
        link = `otpauth://totp/TalkenMarket: ${abcUser?.email}?secret=${qrSecret}`;
        break;
      case 'iOS':
        link = 'https://apps.apple.com/kr/app/google-authenticator/id388497605';
        break;
      case 'MacOS':
        link = 'https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DiOS&oco=0';
        break;
      default:
        link = 'https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid&oco=0';
        break;
    }

    return link;
  };

  const osLink = generateLink();
  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className="model-wallet-code">
        <div className="model-wallet-atc">
          <div className="otp-code-modal">
            <div className="logo-wallet-act">
              <img className="pe-2" src={icon_wallet_act} alt="icon_wallet_act" />
            </div>
            <div className="title-wallet-act">Activate Your ABC Wallet</div>
            <div className="title-intro">
              After scanning the QR code below in GooGle Authenticator, Please enter 6 digits of the authentication
              number.
            </div>
            <div className="qr-code border-radius-4">
              <QRCode className="p-2 bg-white border-radius-4" style={{}} value={qrCode} size={147} />
            </div>
            <div className="d-flex align-items-center justify-content-center p-b-16 ps-4">
              <span className="txt-token">{truncateString(qrSecret, 12, 12, 3)}</span>
              {/* <button
                className="align-items-center border-0 btn-circle d-flex m-r-10 rounded-circle"
                onClick={() => copyToClipBoard(qrSecret)}
              >
                <Copy />
              </button> */}
              <BtnCopy className="hover-cusor-pointer" onClick={() => copyToClipBoard(qrSecret)} />
            </div>
            <div className="d-flex justify-content-center">
              <button className="button m-0 btn-auth w-171px" onClick={() => window.open(osLink)}>
                Run Google Authenticator
              </button>
            </div>
            <div className="body-otp padding-24px-important">
              <div className="title-input-otp pb-0 lh-16">Verification Code</div>
              <div className="m-b-24 m-t-8 pb-0 pt-0 wrapper-input-otp">
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder="Please enter verification code."
                />
              </div>
              <button
                className={`button m-0 btn-detail-nft ${!inputValue ? 'disabled' : ''}`}
                onClick={() => handleComplete()}
                disabled={!inputValue}
              >
                {isLoading ? <CircularProgress size={30} color={'inherit'} /> : <>Complete</>}
              </button>
            </div>
          </div>
        </div>
      </div>
      <CSnackbar
        open={openSnackbar.open}
        type={openSnackbar.type}
        message={openSnackbar.message}
        handleClose={handleCloseSnackbar}
      />
    </ReactModal>
  );
};
export default ABCWalletCode;
