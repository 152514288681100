import CountDownTimer from 'components/TimeCounter/CountDownTimer';
import { Accordion } from 'react-bootstrap';
import { useThemeMode } from 'components/common/AppStoreType';
import useScreenSize from 'components/common/useScreenSize';
import { useEffect, useState } from 'react';
import DetailNFTModal from 'components/detailnftmodal/DetailNFTModal';
import OTPCodeModal from 'components/detailnftmodal/OTPCodeModal';
import { ReactComponent as CancelLogo } from 'assets/svg/icon-cancel.svg';
import WinningBidModal from 'components/detailnftmodal/WinningBidModal';
import WinningBidComplete from 'components/detailnftmodal/WinningBidComplete';
import EditListing from 'components/detailnftmodal/EditListing';
import EditListingComplete from 'components/detailnftmodal/EditListingComplete';
import CancelListing from 'components/detailnftmodal/CancelListing';
import CancelOffer from 'components/detailnftmodal/CancelOffer';
import axios from 'axios';
import { getBidListBySellbookId, getSellbook } from '../../services/services';
import { SUCCESS } from '../../config';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPrice } from 'utils/getPrice';
import { getActiveWallet } from '../../utils/getActiveWallet';
import FullfillModal from 'components/detailnftmodal/FullfillModal';
import PurchaseCompleteModal from 'components/detailnftmodal/PurchaseCompleteModal';
import ErrorModal from 'components/detailnftmodal/ErrorModal';
type AuctionSaleAndPrice = {
  myitem: boolean;
  dataDetailMyItems?: any;
};
const AuctionSaleAndPrice: React.FC<AuctionSaleAndPrice> = ({ myitem, dataDetailMyItems }) => {
  if (!dataDetailMyItems) console.log('!!!! AuctionSaleAndPrice : dataDetailMyItems null or undefined');
  const navigate = useNavigate();
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [openFullfillModal, setOpenFullfillModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openOTPCodeModal, setOpenOTPCodeModal] = useState(false);
  const [openWinningBid, setOpenWinningBid] = useState(false);
  const [openWinningBidComplete, setOpenWinningBidComplete] = useState(false);
  const [openEditListing, setOpenEditListing] = useState(false);
  const [openListingComplete, setOpenListingComplete] = useState(false);
  const [typeSale, setTypeSale] = useState('fixedsale');
  const [openCancelListing, setOpenCancelListing] = useState(false);
  const [openCancelOffer, setOpenCancelOffer] = useState(false);
  const [openPurchaseComplete, setOpenPurchaseComplete] = useState(false);
  const { isDarkMode } = useThemeMode();
  const [countDownFinish, setCountDownFinish] = useState(false);
  const [highestPrice, setHighestPrice] = useState(0);
  const [topBid, setTopBid] = useState();
  const [reload, setReload] = useState(false);
  const [selectedBid, setSelectedBid] = useState();
  const [sellbook, setSellbook] = useState(dataDetailMyItems?.sellbook);
  const [topPrice, setTopPrice] = useState(dataDetailMyItems?.sellbook?.topPrice);
  const [errorInfo, setErrorInfo] = useState('');

  const user = useSelector((state: any) => state.user.user);
  const uid = user.uid;
  const checkLogin = user.uid !== ''; // window.localStorage.getItem('nftapiJwtToken');
  const abcUser = JSON.parse(window.localStorage.getItem('abcUser'));
  const login = localStorage.getItem('login');

  const myWallet = getActiveWallet();
  console.log('sellbook::::', sellbook);
  useEffect(() => {
    if (!dataDetailMyItems) return;
    setSellbook(dataDetailMyItems?.sellbook);
    setTopPrice(dataDetailMyItems?.sellbook?.topPrice);
  }, [dataDetailMyItems]);

  const OFFERS_DUMMY_DATA = [1, 2, 3, 4].map((id) => ({
    id: id,
    priceNft: '50 KlAY',
    priceUsd: '50.00',
    floorDifference: '4% Below',
    quantity: 1,
    expiration: 'In 9 minutes',
    from: 'C2FDAD',
  }));

  const screenSize = useScreenSize();
  const handleCloseDetailModal = async () => {
    setOpenOTPCodeModal(false);
  };
  const handeCheckCountDownFinish = () => {
    setCountDownFinish(true);
  };

  const saleType = sellbook?.type ?? 1; // 1 = Fixed Price. 2 = Timed Auction

  const [ethPrice, setEthPrice] = useState(0);
  const [klayPrice, setKlayPrice] = useState(0);
  const [maticPrice, setMaticPrice] = useState(0);
  const [bnbPrice, setBnbPrice] = useState(0);
  const [talkPrice, setTalkPrice] = useState(0);
  const [bidList, setBidList] = useState([]);

  const getCoinPrice = async () => {
    try {
      const url = 'https://bcn-api.talken.io/coinmarketcap/cmcQuotes?cmcIds=1027,1839,4256,3890,11552';

      const response = await axios(url);
      const ethUsd = response.data.data[1027].quote.USD.price;
      const ethKrw = response.data.data[1027].quote.KRW.price;
      const klayUsd = response.data.data[4256].quote.USD.price;
      const klayKrw = response.data.data[4256].quote.KRW.price;
      const maticUsd = response.data.data[3890].quote.USD.price;
      const maticKrw = response.data.data[3890].quote.KRW.price;
      const bnbUsd = response.data.data[1839].quote.USD.price;
      const bnbKrw = response.data.data[1839].quote.KRW.price;
      const talkUsd = response.data.data[11552].quote.USD.price;
      const talkKrw = response.data.data[11552].quote.KRW.price;
      setEthPrice(parseFloat(ethUsd));
      setKlayPrice(parseFloat(klayUsd));
      setMaticPrice(parseFloat(maticUsd));
      setBnbPrice(parseFloat(bnbUsd));
      setTalkPrice(parseFloat(talkUsd));
      // }
    } catch (error: any) {
      console.log(new Error(error));
    }
  };
  useEffect(() => {
    getCoinPrice();
  }, []);

  const fetchBidList = async () => {
    const res = await getBidListBySellbookId(sellbook?.id);
    if (res.data.status === SUCCESS && res.data.data.length > 0) {
      const highestPriceItem = res.data.data.reduce((prev: any, current: any) =>
        prev.price > current.price ? prev : current
      );
      const result = highestPriceItem ? highestPriceItem.price : null;
      setHighestPrice(result);
      setTopBid(highestPriceItem);

      /*
        {
            id: id,
            priceNft: '50 KlAY',
            priceUsd: '50.00',
            floorDifference: '4% Below',
            quantity: 1,
            expiration: 'In 9 minutes',
            from: 'C2FDAD',
        }
       */
      // TODO : Adjust Data format
      const arranged = res.data.data.map((bid) => {
        const left = new Date(new Date(sellbook?.endDate).getTime() - new Date().getTime()).getMinutes();
        const usd =
          sellbook?.quote === 'KLAY'
            ? (parseFloat(bid.price) * klayPrice).toFixed(2)
            : sellbook?.quote === 'MATIC'
            ? (parseFloat(bid.price) * maticPrice).toFixed(2)
            : sellbook?.quote === 'TALK'
            ? (parseFloat(bid.price) * talkPrice).toFixed(2)
            : sellbook?.quote === 'ETH'
            ? (parseFloat(bid.price) * ethPrice).toFixed(2)
            : sellbook?.quote === 'BNB'
            ? (parseFloat(bid.price) * bnbPrice).toFixed(2)
            : parseFloat(bid.price).toFixed(2);
        return {
          ...bid,
          nftInfo: sellbook.nftInfo,
          priceNft: `${bid.price} ${sellbook.quote}`,
          priceUsd: usd,
          quantity: 1,
          expiration: `${left} minutes`,
          from: bid.uid,
          floorDifference: `${parseInt(((parseFloat(bid.price) * 100) / sellbook.price).toString())}% Above`,
        };
      });
      setBidList(arranged);
    }
  };
  const fetchSellbook = async () => {
    const result = await getSellbook(sellbook?.id);
    setSellbook(result?.data?.data?.sellbook);
    setTopPrice(result?.data?.data?.sellbook?.topPrice);
  };

  useEffect(() => {
    console.log('!!!!! get coin prices from coinmarketcap ...');
    getCoinPrice();
  }, []);

  useEffect(() => {
    if (sellbook?.type === 2) fetchBidList();
    if (reload && sellbook?.id) fetchSellbook();
    if (reload) setReload(false);
  }, [reload, klayPrice, talkPrice, ethPrice, maticPrice, bnbPrice]);

  return (
    <div className="auction-sale-and-price">
      {/* bidding time */}
      {/* <div className={`box-count-down ${isDarkMode ? '' : 'icon-countdown-lightmode'}`}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="10" cy="10" r="9.0711" stroke="white" stroke-width="1.8578" />
                  <path
                    d="M9.76758 5.34766V10.9291H14.1862"
                    stroke="white"
                    strokeWidth="1.8578"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

              </div> */}
      {!countDownFinish && (
        <CountDownTimer
          handeCheckCountDownFinish={handeCheckCountDownFinish}
          targetDate={sellbook?.endDate}
          salesEnd={sellbook?.endDate ?? '2024-12-31'}
        />
      )}
      {/* bidding period */}
      {/* <div className="wrapper-bidding-period">
        <div className="bidding-period-item">
          <div className="title-bidding-period">Listed on</div>
          <div className="detailed-bidding-period">{new Date(sellbook?.startDate).toLocaleString()}</div>
        </div>
        <div className="bidding-period-item">
          <div className="title-bidding-period">Sales end</div>
          <div className="detailed-bidding-period">{new Date(sellbook?.endDate ?? '2024-12-31').toLocaleString()}</div>
        </div>
      </div> */}
      <div className="m-b-24 m-b-xs-16"></div>
      {saleType === 2 ? (
        <>
          <div className="wrapper-current-price m-b-24 m-b-xs-16">
            <div className="title-current-price">Top Bid</div>
            <div className="wrapper-price">
              <div className="nft-price">{`${topPrice} ${sellbook?.quote}`}</div>
              <div className="usd-price">
                ($
                {sellbook?.quote === 'KLAY'
                  ? parseFloat((parseFloat(topPrice) * klayPrice).toString()).toFixed(2)
                  : sellbook?.quote === 'MATIC'
                  ? parseFloat((parseFloat(topPrice) * maticPrice).toString()).toFixed(2)
                  : sellbook?.quote === 'BNB'
                  ? parseFloat((parseFloat(topPrice) * bnbPrice).toString()).toFixed(2)
                  : sellbook?.quote === 'ETH'
                  ? parseFloat((parseFloat(topPrice) * ethPrice).toString()).toFixed(2)
                  : sellbook?.quote === 'TALK'
                  ? parseFloat((parseFloat(topPrice) * talkPrice).toString()).toFixed(2)
                  : topPrice}
                )
              </div>
            </div>
          </div>
          {/* offer table  */}
          <div className="section-offers-table m-b-xs-16">
            <Accordion defaultActiveKey={['0']} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div className={`wrapper-header-offers ${isDarkMode ? '' : 'icon-offers-lightmode'}`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="2" y="5" width="21" height="3" rx="1.5" fill="white" />
                      <rect x="2" y="11" width="21" height="3" rx="1.5" fill="white" />
                      <rect x="2" y="17" width="21" height="3" rx="1.5" fill="white" />
                    </svg>
                    <div className="title-offers">Offers</div>
                  </div>
                </Accordion.Header>
                <div className="box-border">
                  <Accordion.Body className="padding-body-offers">
                    <table className="w-100">
                      <thead className="text-start header-table-offers">
                        <tr>
                          <th className="width-price">Price</th>
                          <th className=" width-floor-difference">Floor Difference</th>
                          <th>Quantity</th>
                          {/*<th>Expiration</th>*/}
                          {screenSize > 768 && <th>From</th>}
                          {screenSize <= 768 ? <th>From</th> : <th></th>}
                        </tr>
                      </thead>
                      <tbody className="text-start body-table-offers">
                        {bidList &&
                          bidList.length > 0 &&
                          bidList.map((offer) => (
                            <tr key={offer.id}>
                              <td className="width-price">
                                <div className="offer-price-nft">{offer.priceNft}</div>
                                {screenSize > 768 && <div className="offer-price-usd">$ {offer.priceUsd}</div>}
                              </td>
                              <td className="width-floor-difference">{offer.floorDifference}</td>
                              <td>{offer.quantity}</td>
                              {/*<td>{offer.expiration}</td>*/}
                              {screenSize > 768 && <td className="offer-form-column">{offer.bidsUser.name}</td>}
                              <td>
                                {myitem && checkLogin ? (
                                  <button
                                    className="btn-offer-table button"
                                    onClick={() => {
                                      setOpenWinningBid(true);
                                      setSelectedBid(offer);
                                    }}
                                  >
                                    Accept
                                  </button>
                                ) : myWallet.toLowerCase() === offer.wallet.toLowerCase() ? (
                                  <button
                                    className="btn-offer-table button"
                                    onClick={() => {
                                      setOpenCancelOffer(true);
                                      setSelectedBid(offer);
                                    }}
                                    disabled={uid !== offer.uid}
                                  >
                                    Cancel
                                  </button>
                                ) : (
                                  ''
                                )}
                              </td>
                              {screenSize <= 768 && <td className="offer-form-column">{offer.bidsUser.name}</td>}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Accordion.Body>
                </div>
              </Accordion.Item>
            </Accordion>
            {myitem && checkLogin ? (
              <div className={`${screenSize < 769 ? 'bg-button-fixed' : ''}`}>
                <button
                  className="btn-detail-nft button  m-xs-0"
                  onClick={() => {
                    if (!checkLogin) return navigate('/accounts');
                    if (!abcUser?.twoFactorEnabled && login === 'sns') {
                      alert('Please activate ABC Wallet first');
                      return;
                    }
                    setOpenEditListing(true);
                    setTypeSale(sellbook.type === 2 ? 'auctionsale' : 'fixedsale');
                  }}
                  // disabled={bidList.length > 0} // TODO : Bid 참여가 없는 경우에만 편집 가능
                >
                  {checkLogin ? 'Edit Listing' : 'Place Bid'}
                </button>
              </div>
            ) : (
              <div className={`${screenSize < 769 ? 'bg-button-fixed' : ''}`}>
                <button
                  className="btn-detail-nft button  m-xs-0"
                  onClick={() => {
                    if (!checkLogin) return navigate('/accounts');
                    if (!abcUser?.twoFactorEnabled && login === 'sns') {
                      alert('Please activate ABC Wallet first');
                      return;
                    }
                    setOpenDetailModal(true);
                    setTypeSale(sellbook.type === 2 ? 'auctionsale' : 'fixedsale');
                  }}
                >
                  Place Bid
                </button>
              </div>
            )}
          </div>
        </>
      ) : (
        // current price
        <div className="section-current-price">
          <div className="wrapper-current-price">
            <div className="title-current-price">Current Price</div>
            <div className="wrapper-price">
              <div className="nft-price">{getPrice(Number(sellbook?.price), sellbook?.quote)}</div>
              <div className="usd-price">
                ($
                {sellbook?.quote === 'KLAY'
                  ? parseFloat((sellbook?.price * klayPrice).toString()).toFixed(2)
                  : sellbook?.quote === 'MATIC'
                  ? parseFloat((sellbook?.price * maticPrice).toString()).toFixed(2)
                  : sellbook?.quote === 'BNB'
                  ? parseFloat((sellbook?.price * bnbPrice).toString()).toFixed(2)
                  : sellbook?.quote === 'ETH'
                  ? parseFloat((sellbook?.price * ethPrice).toString()).toFixed(2)
                  : sellbook?.quote === 'TALK'
                  ? parseFloat((sellbook?.price * talkPrice).toString()).toFixed(2)
                  : sellbook?.price}
                )
              </div>
            </div>
          </div>
          {myitem && checkLogin ? (
            <div className={`${screenSize < 769 ? 'bg-button-fixed' : ''}`}>
              <button
                className="btn-detail-nft button  m-xs-0"
                onClick={() => {
                  if (!checkLogin) return navigate('/accounts');
                  if (!abcUser?.twoFactorEnabled && login === 'sns') {
                    alert('Please activate ABC Wallet first');
                    return;
                  }
                  setOpenEditListing(true);
                  setTypeSale(sellbook?.type === 2 ? 'auctionsale' : 'fixedsale');
                }}
              >
                {checkLogin ? 'Edit Listing' : 'Place Bid'}
              </button>
            </div>
          ) : (
            <div className={`${screenSize < 769 ? 'bg-button-fixed' : ''}`}>
              <button
                className="btn-detail-nft button  m-xs-0"
                onClick={() => {
                  if (!checkLogin) return navigate('/accounts');
                  if (!abcUser?.twoFactorEnabled && login === 'sns') {
                    alert('Please activate ABC Wallet first');
                    return;
                  }
                  setOpenDetailModal(true);
                  setTypeSale(sellbook?.type === 2 ? 'auctionsale' : 'fixedsale');
                }}
              >
                Purchase
              </button>
            </div>
          )}
        </div>
      )}
      {openDetailModal && (
        <DetailNFTModal
          show={openDetailModal}
          onHide={() => setOpenDetailModal(false)}
          typeSale={typeSale}
          openOTPCodeModal={() => setOpenOTPCodeModal(true)}
          sellData={sellbook}
          klayPrice={klayPrice}
          maticPrice={maticPrice}
          bnbPrice={bnbPrice}
          ethPrice={ethPrice}
          talkPrice={talkPrice}
          floorPrice={highestPrice}
          setReload={setReload}
          setTopPrice={setTopPrice}
          topPrice={topPrice}
          openFullfill={() => setOpenFullfillModal(true)}
          openPurchaseComplete={() => setOpenPurchaseComplete(true)}
        />
      )}
      {openFullfillModal && <FullfillModal show={openFullfillModal} onHide={() => setOpenFullfillModal(false)} />}
      {openPurchaseComplete && (
        <PurchaseCompleteModal
          show={openPurchaseComplete}
          onHide={() => {
            setOpenPurchaseComplete(false);
          }}
          dataDetailMyItems={dataDetailMyItems}
        />
      )}
      {openOTPCodeModal && (
        <OTPCodeModal
          show={openOTPCodeModal}
          onHide={handleCloseDetailModal}
          typeModal={typeSale}
          openWinningComplete={() => setOpenWinningBidComplete(true)}
        />
      )}
      {openWinningBid && (
        <WinningBidModal
          show={openWinningBid}
          onHide={() => setOpenWinningBid(false)}
          openOTPCodeModal={() => {
            // setOpenOTPCodeModal(true);
            setTypeSale('winningbid');
          }}
          selected={selectedBid}
        />
      )}
      {openWinningBidComplete && (
        <WinningBidComplete
          show={openWinningBidComplete}
          onHide={() => {
            setOpenWinningBidComplete(false);
            setOpenOTPCodeModal(false);
          }}
        />
      )}
      {openEditListing && (
        <EditListing
          show={openEditListing}
          onHide={() => setOpenEditListing(false)}
          typeSale={typeSale}
          confirm={() => setOpenListingComplete(true)}
          cancel={() => setOpenCancelListing(true)}
          dataDetailMyItems={dataDetailMyItems}
          isEmptyBids={bidList?.length < 1}
          openError={(error) => {
            setErrorInfo(error);
            setOpenErrorModal(true);
          }}
        />
      )}
      {openListingComplete && (
        <EditListingComplete
          show={openListingComplete}
          dataDetailMyItems={dataDetailMyItems}
          onHide={() => {
            setOpenListingComplete(false);
          }}
        />
      )}
      {openCancelListing && (
        <CancelListing
          show={openCancelListing}
          dataDetailMyItems={dataDetailMyItems}
          onHide={() => {
            setOpenCancelListing(false);
          }}
        />
      )}
      {openCancelOffer && (
        <CancelOffer
          show={openCancelOffer}
          onHide={() => {
            setOpenCancelOffer(false);
          }}
          selected={selectedBid}
          dataDetailMyItems={dataDetailMyItems}
          setReload={setReload}
        />
      )}
      {openErrorModal && <ErrorModal show={openErrorModal} onHide={() => setOpenErrorModal(false)} error={errorInfo} />}
    </div>
  );
};

export default AuctionSaleAndPrice;
