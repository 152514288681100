import React, { useEffect } from 'react';
const { REACT_APP_INFURA_ID } = process.env;
import { useThemeMode } from 'components/common/AppStoreType';
import WormholeBridge, { Theme, OPACITY, WormholeConnectConfig } from '@colligence/wormhole-connect';
import { ChainId, FAILURE, RPC_URLS, SUCCESS } from '../../config';
import { CandlestickChart } from '@mui/icons-material';
import lightblue from '@mui/material/colors/lightBlue';
import grey from '@mui/material/colors/grey';
import green from '@mui/material/colors/green';
import orange from '@mui/material/colors/orange';
import red from '@mui/material/colors/red';
import { useMediaQuery } from 'react-responsive';

const RPC_URL_MAINNET = `https://mainnet.infura.io/v3/${REACT_APP_INFURA_ID}`;
const RPC_URL_SEPOLIA = `https://sepolia.infura.io/v3/${REACT_APP_INFURA_ID}`;

function TalkenBridge() {
  const isMobile = useMediaQuery({
    query: '(max-width: 640px)',
  });
  const { isDarkMode } = useThemeMode();
  function mount() {
    const script = document.createElement('script');
    script.src = 'https://www.unpkg.com/@colligence/wormhole-connect@0.2.3/dist/main.js';
    script.async = true;
    script.type = 'module';

    const link = document.createElement('link');
    link.href = 'https://www.unpkg.com/@colligence/wormhole-connect@0.2.3/dist/main.css';

    document.body.appendChild(script);
    document.body.appendChild(link);
  }
  useEffect(() => {
    mount();
  }, []);

  let config: WormholeConnectConfig;
  const customized: Theme = {
    font: {
      primary: 'Roboto',
      header: 'Poppins',
    },
    primary: grey,
    secondary: grey,
    divider: '#ffffff' + OPACITY[20],
    background: {
      default: isDarkMode ? '#02021e' : '#ffffff',
    },
    text: {
      primary: isDarkMode ? '#ffffff' : '#2b2a5c',
      secondary: grey[500],
    },
    error: red,
    info: lightblue,
    success: green,
    warning: orange,
    button: {
      primary: isDarkMode ? '#ffffff' + OPACITY[10] : '#f0f5ff',
      primaryText: '#ffffff',
      disabled: '#ffffff' + OPACITY[10],
      disabledText: '#ffffff' + OPACITY[40],
      action: orange[300],
      actionText: '#000000',
      hover: '#ffffff' + OPACITY[7],
    },
    options: {
      hover: '#474747',
      select: '#5b5b5b',
    },
    card: {
      background: isDarkMode ? '#1b1b34' : '#f0f5ff',
      secondary: '#ffffff' + OPACITY[10],
      elevation: 'none',
    },
    popover: {
      background: isDarkMode ? '#1b2033' : '#ffffff',
      secondary: '#ffffff' + OPACITY[10],
      elevation: 'none',
    },
    modal: {
      background: isDarkMode ? '#02021e' : '#ffffff',
    },
  };

  if (process.env.REACT_APP_PHASE === 'development') {
    config = {
      customTheme: customized,
      env: 'testnet',
      networks: ['goerli', 'sepolia', 'bsc', 'mumbai', 'klaytn', 'solana'],
      tokens: [
        'ETH',
        'WETH',
        'BNB',
        'WBNB',
        'MATIC',
        'WMATIC',
        'KLAY',
        'WKLAY',
        'SOL',
        'WSOL',
        'TALK',
        'USDCeth',
        'USDCpolygon',
        'USDT',
      ],
      walletConnectProjectId: '41fc6b7a264d80579519dd4ee20ea4a1',
    };
  } else {
    config = {
      customTheme: customized,
      env: 'mainnet',
      networks: ['ethereum', 'bsc', 'polygon', 'klaytn', 'solana'],
      tokens: [
        'ETH',
        'WETH',
        'BNB',
        'WBNB',
        'MATIC',
        'WMATIC',
        'KLAY',
        'WKLAY',
        'SOL',
        'WSOL',
        'TALK',
        'BONK',
        'USDCeth',
        'USDCpolygon',
        'USDT',
      ],
      rpcs: {
        solana: process.env.REACT_APP_SOLANA_RPC,
      },
      walletConnectProjectId: '41fc6b7a264d80579519dd4ee20ea4a1',
    };
  }

  return (
    <div className="featured-page min-height-content">
      <div className="section-02">
        <div className="featured-collections">
          <div className="wrapper-header title-header">
            <div className="header-name pb-3" style={{ paddingTop: '10px' }}>
              Bridge
            </div>
          </div>
          {/* <div style={{ marginBottom: isMobile ? '-40px' : '-60px' }} /> */}
        </div>
      </div>
      <div style={{ margin: isMobile ? '-80px 5px' : '-130px 0 80px 0', paddingBottom: isMobile ? '100px' : '0px' }}>
        <WormholeBridge config={config} />
      </div>
    </div>
  );
}

export default TalkenBridge;
