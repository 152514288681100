import { useThemeMode } from 'components/common/AppStoreType';
import ReactModal from 'react-modal';
import { ReactComponent as IconClose } from 'assets/icon/close_icon.svg';
import binance from 'assets/img/icon_binance.png';
import klay from '../../assets/icon/klaytn.png';
import polygon from '../../assets/icon/polygon.png';
import eth from '../../assets/icon/eth.png';
import talk from '../../talkclaim/talk.svg';
import talkKlaytn from '../../assets/icon/talkKlaytn.svg';
import talkPolygon from '../../assets/icon/talkPolygon.svg';
import talkBinance from '../../assets/icon/talkBinance.svg';
import Select from 'react-select';
import { useMediaQuery } from 'react-responsive';
import useScreenSize from 'components/common/useScreenSize';
import { ReactComponent as Link } from '../../assets/icon/link.svg';
import { ReactComponent as Confetti } from '../../assets/icon/confetti.svg';
import { useEffect, useRef, useState } from 'react';
import { SCAN_URL } from '../../config';
import env from '../../env';

type TransferSuccessModal = {
  show: any;
  onHide: any;
  typeModal: string;
  openWinningComplete: any;
  data: any;
};
const TransferSuccessModal: React.FC<TransferSuccessModal> = ({
  show,
  onHide,
  typeModal,
  openWinningComplete,
  data,
}) => {
  console.log(data);

  const { isDarkMode } = useThemeMode();
  const screenSize = useScreenSize();
  const isMobile = useMediaQuery({
    query: '(max-width: 641px)',
  });
  const stylesPayType = {
    control: (base, state) => ({
      ...base,
      background: isDarkMode ? '#202052' : '#FFFFFF1A',
      borderRadius: isMobile ? '6px' : '10px',
      borderColor: state.isFocused ? '#525298' : '#525298',
      boxShadow: state.isFocused ? 'none' : 'none',
      fontSize: isMobile ? '11px' : '16px',
      fontWeight: 500,
      width: '100%',
      height: isMobile ? '32px' : '50px',
      minHeight: isMobile ? '32px' : '38px',
      cursor: 'pointer',
      ':hover': {
        borderColor: '#525298',
      },
    }),
    singleValue: (base, state) => ({
      ...base,
      color: state.selectProps.menuIsOpen ? '#FFFFFF' : isDarkMode ? '#8787D6' : '#000000',
    }),
    valueContainer: (base) => ({
      ...base,
      height: '100%',
      padding: isMobile ? '8px' : '2px 8px',
    }),
    option: (base, state) => ({
      ...base,
      fontSize: isMobile ? '11px' : '16px',
      fontWeight: 500,
      width: '100%',
      color: state.isSelected ? '#777777' : '#777777',
      backgroundColor: state.isSelected ? '#F0F5FF' : '#FFFFFF',
      ':active': {
        // backgroundColor: state.isSelected ? '#FFFFFF' : '#FFFFFF',
      },
      ':hover': {
        backgroundColor: '#2F8AF5',
        color: '#FFFFFF',
      },
      cursor: 'pointer',
    }),

    menu: (base) => ({
      ...base,
      fontSize: isMobile ? '11px' : '14px',
      background: 'white',
      borderRadius: isMobile ? '6px' : '10px',
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      fontSize: '12px',
      padding: '0',
      borderRadius: isMobile ? '6px' : '10px',
      height: 'auto',
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: state.selectProps.menuIsOpen ? '#ffffff' : isDarkMode ? '#525298' : '#000000',
      placeContent: 'center',
      padding: isMobile ? '4px' : '8px',
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
  };
  const [selectedOption, setSelectedOption] = useState(data?.value);
  const options = [
    { value: 0, label: 'TALK', customAbbreviation: talk },
    { value: 1, label: 'TALK', customAbbreviation: talkBinance },
    { value: 2, label: 'TALK', customAbbreviation: talkPolygon },
    { value: 3, label: 'TALK', customAbbreviation: talkKlaytn },
    { value: 4, label: 'ETH', customAbbreviation: eth },
    { value: 5, label: 'BNB', customAbbreviation: binance },
    { value: 6, label: 'MATIC', customAbbreviation: polygon },
    { value: 7, label: 'KLAY', customAbbreviation: klay },
  ];

  const [scanUrl, setScanUrl] = useState(SCAN_URL[env.REACT_APP_TARGET_NETWORK_KLAY]);
  useEffect(() => {
    let scanner;
    switch (data?.value) {
      case 0:
        scanner = SCAN_URL[env.REACT_APP_TARGET_NETWORK_ETH];
        break;
      case 1:
        scanner = SCAN_URL[env.REACT_APP_TARGET_NETWORK_BNB];
        break;
      case 2:
        scanner = SCAN_URL[env.REACT_APP_TARGET_NETWORK_MATIC];
        break;
      case 3:
        scanner = SCAN_URL[env.REACT_APP_TARGET_NETWORK_KLAY];
        break;
      case 4:
        scanner = SCAN_URL[env.REACT_APP_TARGET_NETWORK_ETH];
        break;
      case 5:
        scanner = SCAN_URL[env.REACT_APP_TARGET_NETWORK_BNB];
        break;
      case 6:
        scanner = SCAN_URL[env.REACT_APP_TARGET_NETWORK_MATIC];
        break;
      case 7:
        scanner = SCAN_URL[env.REACT_APP_TARGET_NETWORK_KLAY];
        break;
    }
    setScanUrl(scanner);
  }, [data]);

  const formatOptionLabel = ({ value, label, customAbbreviation }) => (
    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      <img style={{ width: '20px', height: '20px' }} src={customAbbreviation} className="m-r-10"></img>
      <div>{label}</div>
    </div>
  );
  const handleConfirmModal = async () => {
    openWinningComplete();
    onHide();
  };
  const handleTypeSelect = (e) => {
    setSelectedOption(e.value);
  };
  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className="otp-code-modal otp-code-modal-cust">
        <div className="close-button" onClick={onHide}>
          <IconClose className="close-icon dark:close-icon" width={16} height={16} />
        </div>
        {/* <div className="header-otp">Transfer Successful</div> */}
        {/* <div className="line-banner"></div> */}
        <div className="d-flex flex-center flex-column gap-8px m-t-56 m-t-xs-24 w-100">
          <Confetti />
          <div className="color-08">Your item transfer was successful.</div>
        </div>
        <div className="body-otp">
          <div className="title-input-otp">Token</div>
          <div className={`${screenSize < 641 ? 'm-b-12' : 'm-b-24'}`}>
            <Select
              isDisabled={true}
              formatOptionLabel={formatOptionLabel}
              options={options}
              styles={stylesPayType}
              isSearchable={false}
              onChange={handleTypeSelect}
              value={options.filter(function (option) {
                return option.value === selectedOption;
              })}
              placeholder={'Select Token'}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="title-input-otp mb-1">Amount</div>
          <div className={`wrapper-input-otp p-0 ${screenSize < 641 ? 'm-b-12' : 'm-b-24'}`}>
            <input
              className="color-08-important"
              type="text"
              placeholder="Amount"
              value={data?.balance}
              disabled={true}
            />
          </div>
          <div className="title-input-otp mb-1">Recipient wallet address</div>
          <div className={`wrapper-input-otp p-0 ${screenSize < 641 ? 'm-b-12' : 'm-b-24'}`}>
            <input
              type="text"
              className="color-08-important"
              placeholder="Enter the wallet adress"
              value={data?.address}
              disabled={true}
            />
          </div>
          <div className="title-input-otp mb-1">TXID</div>
          <div className={`wrapper-input-otp position-relative p-0 ${screenSize < 641 ? 'm-b-12' : 'm-b-24'}`}>
            <input
              className="color-08-important"
              type="text"
              placeholder="TXID"
              value={data?.txid?.slice(0, 11) + '...' + data?.txid?.slice(-9)}
              disabled={true}
            />
            <button
              className="border-0 rounded-circle btn-circle position-absolute left-92 left-xs-90 bg-box-modal flex-center w-26px h-26px w-xs-20px h-xs-20px"
              onClick={() => window.open(`${scanUrl}tx/${data?.txid}`)}
            >
              <Link className="close-icon dark:close-icon" width={isMobile ? 8 : 10} height={isMobile ? 8 : 10} />
            </button>
          </div>
          <button className="button m-0 btn-detail-nft" onClick={handleConfirmModal}>
            Confirm
          </button>
        </div>
      </div>
    </ReactModal>
  );
};
export default TransferSuccessModal;
