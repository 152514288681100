import React from 'react';
import { FeaturedTypes } from '../../types/FeaturedTypes';
import { Link } from 'react-router-dom';
import { splitString } from '../../utils/splitString';
import { useMediaQuery } from 'react-responsive';
import { getComma } from '../../utils/getComma';

type FeaturedCardProps = {
  item: FeaturedTypes;
  page: string;
};
const FeaturedCard: React.FC<FeaturedCardProps> = ({ item, page }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 640px)',
  });
  return (
    <Link to={`/creator/${item.id}`} className="custom-link w-100 d-sm-inline-block">
      <button className="grid-item button">
        <div className="banner-image">
          <img src={item.mobileBanner ? item.mobileBanner : item.banner} alt="" style={{ objectFit: 'cover' }} />
          {/*<LazyImage src={item.banner} />*/}
        </div>
        <div className="wrapper-content">
          <div className="avatar">
            <img src={item.image} data-qa-component="campaign-avatar-image" alt={item.name.en} />
          </div>
          <div className="name-label">{splitString(item.name.en, 20)}</div>
          {page === 'creator' ? (
            <div className="total-item">
              Total Items:{' '}
              {item.companyId?.toUpperCase() === 'ETERNALEDTIONS'
                ? 6
                : item.companyId?.toUpperCase() === 'NURITOPIA'
                ? 98
                : item.companyId?.toUpperCase() === 'DOKDOVERSE'
                ? getComma(54021)
                : getComma(item?.totalAmount) ?? 0}
            </div>
          ) : page === 'homepage' ? (
            <div className="total-item">
              Total Items:{' '}
              {item.companyId?.toUpperCase() === 'ETERNALEDTIONS'
                ? 6
                : item.companyId?.toUpperCase() === 'NURITOPIA'
                ? 98
                : item.companyId?.toUpperCase() === 'DOKDOVERSE'
                ? getComma(54021)
                : getComma(item?.totalAmount) ?? 0}
            </div>
          ) : (
            ''
          )}
        </div>
      </button>
    </Link>
  );
};

export default FeaturedCard;
