// @ts-nocheck
import React, { ChangeEventHandler, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useRoutes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import updateUserRedux from './utils/updateUserRedux';
import ScrollToTop from 'components/common/ScrollToTop';
import Router from './routes/Router';
import './design/sass/main.scss';
import './components/togglelightmode/toggle-mode.css';
import { useThemeMode } from 'components/common/AppStoreType';
import GoogleAnalytics from 'react-ga4';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { accountRestApi, controllers } from './abc/background/init';
const { accountController, abcController } = controllers;
import HelperUtil from 'utils/HelperUtil';
import { checkWasm } from 'abc/sandbox';
import { getSession } from './services/services';
import { useOpenModal } from 'components/common/AppStore';
import LicenseAgreementABC from 'components/activateWallet/LicenseAgreementABC';

const delay = (time = 1000) => new Promise((resolve) => setTimeout(resolve, time));
let abcFlag = false;

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);
  const routing = useRoutes(Router());
  const { pathname } = useLocation();
  const { isDarkMode, lightMode, darkMode } = useThemeMode();
  const setDark = () => {
    localStorage.setItem('theme', 'dark');
    document.documentElement.setAttribute('data-theme', 'dark');
    darkMode();
  };

  const setLight = () => {
    localStorage.setItem('theme', 'light');
    document.documentElement.setAttribute('data-theme', 'light');
    lightMode();
  };

  useEffect(() => {
    let title = 'Talken Market';
    switch (pathname) {
      case '/':
        title = 'Talken Market';
        break;
      case '/drops':
        title = 'Talken Drops';
        break;
      case '/market':
        title = 'Talken Market';
        break;
      case '/bridge':
        title = 'Talken Bridge';
        break;
      case '/faucet':
        title = 'Talken Faucet';
        break;
    }
    document.title = title;
  }, [pathname]);

  const { openModal } = useOpenModal();

  // const abcRecover = async () => {
  //   console.log('abcRecover!!!');
  //   // const { appInfo } = this.props;
  //   // const isABCApp = window.Android?.activeABCWallet || window.webkit?.messageHandlers?.activeABCWallet;
  //   // Old app 방어코드
  //   // if (appInfo?.osType === "IOS" && !isABCApp) return console.log("This is old IOS app...");
  //   // if (appInfo?.osType === "AOS" && !isABCApp) return console.log("This is old AOS app...");
  //
  //   let abcAuth = JSON.parse(window.localStorage.getItem('abcAuth'));
  //   if (!abcAuth) {
  //     const abcLoginRes = await HelperUtil.abcLogin();
  //     if (!abcLoginRes) return;
  //     // console.log('======>abcLogin@App.js:::', abcLoginRes);
  //     const flCreate = abcLoginRes?.data?.msg;
  //     if (flCreate) return; // 신규 가입자 생성
  //   }
  //
  //   // const abcUser = JSON.parse(window.localStorage.getItem('abcUser'));
  //   abcAuth = JSON.parse(window.localStorage.getItem('abcAuth'));
  //   const EncPV = secureLocalStorage.getItem('EncPV');
  //   if (!EncPV && abcAuth) {
  //     const { user, wallets } = await accountRestApi.getWalletsAndUserByAbcUid();
  //     console.log('abcUser:::', user);
  //     console.log('abcWallets:::', wallets);
  //     if (!user) {
  //       const getSessionRes = await axios
  //         .get(`/auth/getSession`)
  //         .catch((error) => console.log(`getSessionErr:::${error.message}`));
  //       if (getSessionRes) {
  //         const email = JSON.parse(getSessionRes.data.providerAuthInfo.provider_data)?.email;
  //         await accountController.createMpcBaseAccount(
  //           { accountName: email, password: '!owdin001', email: email },
  //           null
  //         );
  //         window.location.reload(); // abc 유저가입은 되어있고 mpc 지갑생성 안된경우
  //       }
  //     }
  //     await accountController.recoverShare({ password: '!owdin001', user, wallets, keepDB: false }, null);
  //     await delay(100);
  //     window.location.reload();
  //   }
  // };

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const defaultDark = storedTheme === 'dark' || storedTheme === null;
    // storedTheme === 'dark' || (storedTheme === null && prefersDark);
    if (defaultDark) setDark();
    else setLight();
  }, []);

  const initWasm = async () => {
    return new Promise(function (resolve, reject) {
      function waitWasmInit() {
        const result = checkWasm();
        if (!result) window.setTimeout(waitWasmInit, 100);
        else resolve(result);
      }
      waitWasmInit();
    });
  };
  let isLoad = false;
  useEffect(() => {
    if (isLoad) return;
    updateUserRedux(dispatch);
    const wasmInitAndRecover = async () => {
      if (abcFlag) return;
      abcFlag = true;
      await initWasm();
      // const abcAuth = JSON.parse(window.localStorage.getItem('abcAuth'));
      // const EncPV = secureLocalStorage.getItem('EncPV');
      // if (!EncPV || !abcAuth) await HelperUtil.abcRecover();
      const rlt = await getSession();
      const login = window.localStorage.getItem('login');
      if (rlt.data?.providerAuthInfo && login === 'sns') {
        await HelperUtil.abcRecoverNew();
        // const data = JSON.parse(rlt.data?.providerAuthInfo?.provider_data);
        // const email = data.email;
        // // ABC 지갑 가입 여부 확인
        // const isExist = await abcController.getUser({
        //   email,
        //   successIfUserExist: true,
        // });
        // console.log(`!! ${email} ABC is exist =`, isExist);

        // if (isExist) {
        //   await HelperUtil.abcRecover();
        // } else {
        //   openModal();
        // }
      }
    };

    wasmInitAndRecover();
    isLoad = true;
  }, []);
  useEffect(() => {
    console.log('this is redux user data:::', user);
  }, [user]);

  GoogleAnalytics.set({
    pathname,
    title: 'apps.talken.io' + pathname,
  });
  GoogleAnalytics.send({ hitType: 'pageview', page: pathname });

  return (
    <div>
      <ScrollToTop />
      {routing}
      <LicenseAgreementABC />
    </div>
  );
}

export default App;
