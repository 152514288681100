import { useState } from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as IconClose } from 'assets/icon/close_icon.svg';
import check_connect from '../../assets/icon/check_connect.png';
import { CircularProgress } from '@mui/material';

type ReferralProgram = {
  show: any;
  onHide: any;
};
const ReferralProgramModal: React.FC<ReferralProgram> = ({ show, onHide }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleConfirmModal = async () => {
    setIsLoading(true);
    onHide();
  };
  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className="modal-primary w-430px w-xs-338px h-445px h-xs-424px padding-modal mode-color-8787d6">
        <div className="close-button-modal" onClick={onHide}>
          <IconClose className="close-icon dark:close-icon" />
        </div>
        <div className="fs-24 fs-xs-16 fw-bold text-center">Talken Referral Program</div>
        <div className="line-banner"></div>
        <div className="referral-body m-b-24 m-b-xs-16">
          <div className="fs-14 fw-6 m-b-8 text-base text-center">Terms of participation and rewards</div>
          <div className="d-flex flex-column gap-4px">
            <div className="flex-start gap-8px">
              <img src={check_connect} alt="Check Icon" width={24} />
              <div className="fw-4 lh-15 lh-xs-inherit">Invitees must sign up with a UID or referral link.</div>
            </div>
            <div className="flex-start gap-8px">
              <img src={check_connect} alt="Check Icon" width={24} />
              <div className="fw-4 lh-15 lh-xs-inherit">
                The invitee will need to activate ABC Wallet, which is provided by default.
              </div>
            </div>
            <div className="flex-start gap-8px">
              <img src={check_connect} alt="Check Icon" width={24} />
              <div className="fw-4 lh-15 lh-xs-inherit">
                If the above two conditions are met, both the inviter and the invitee earn rewards.
              </div>
            </div>
            <div className="flex-start gap-8px">
              <img src={check_connect} alt="Check Icon" width={24} />
              <div className="fw-4 lh-15 lh-xs-inherit">Inviters can invite up to 1,000 people per month.</div>
            </div>
            <div className="flex-start gap-8px">
              <img src={check_connect} alt="Check Icon" width={24} />
              <div className="fw-4 lh-15 lh-xs-inherit">Reward claims can be made on the ‘My Rewards’.</div>
            </div>
            <div className="flex-start gap-8px">
              <img src={check_connect} alt="Check Icon" width={24} />
              <div className="fw-4 lh-15 lh-xs-inherit">
                Reward quantities and earning conditions are subject to change based on market conditions.
              </div>
            </div>
          </div>
        </div>
        <button className="btn-detail-nft button m-0 h-50px h-xs-40px" onClick={handleConfirmModal}>
          {isLoading ? <CircularProgress size={30} color={'inherit'} /> : <>Confirm</>}
        </button>
      </div>
    </ReactModal>
  );
};
export default ReferralProgramModal;
