import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import see_collection from '../../assets/icon/see_collection.png';
import icon_properties from '../../assets/svg/icon_properties.svg';
import icon_properties_black from '../../assets/svg/icon_properties_black.svg';
import icon_note from '../../assets/svg/note.svg';
import icon_details from '../../assets/svg/icon_details.svg';
import icon_details_black from '../../assets/svg/icon_details_black.svg';
import klaytn_white from '../../assets/icon/klaytn_white.png';
import website_icon from '../../assets/icon/website_icon.svg';
import about_img from '../../assets/svg/default_about.svg';
import arrow_up_right from '../../assets/icon/arrow_up_right.png';
import arrow_up_right_black from '../../assets/icon/arrow_up_right_black.png';
import icon_discord from '../../assets/img/icon_discord.png';
import icon_twitter from '../../assets/img/icon_twitter.png';
import icon_instagram from '../../assets/img/icon_instagram.png';
import icon_share from '../../assets/img/icon_share.png';
import ic_dropdown from '../../assets/svg/dropdown_button_dots.svg';
import { getNetworkNameByChainId } from '../../utils/getNetworkNameByChainId';
import useOnClickOutside from 'components/common/useOnClickOutside';
import { getCollectionByContractAddress, getUserById } from '../../services/services';
import { SCAN_URL, SUCCESS } from '../../config';
import { MBoxTypes } from '../../types/MBoxTypes';
import { useWeb3React } from '@web3-react/core';
import { Accordion } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import ArrowCarouselItemDetails from 'components/common/ArrowCarouselItemDetails';
import { MBoxItemTypes } from '../../types/MBoxItemTypes';
import splitAddress from '../../utils/splitAddress';
import ConvertUrlsToLinks from '../../utils/convertUrlsToLinks';
import { getNetworkNameById } from '../../utils/getNetworkNameById';
import { FeaturedTypes } from '../../types/FeaturedTypes';
import { getPrice } from '../../utils/getPrice';
import useCopyToClipBoard from '../../hooks/useCopyToClipboard';
import CSnackbar from '../../components/common/CSnackbar';
import { moveToScope } from '../../utils/moveToScope';
import useOnClickOutsideDropdown from 'components/common/useOnClickOutside';
import ReactModal from 'react-modal';
import close_icon from '../../assets/icon/close_icon.svg';
import arrow_btn_back_dark from '../../assets/svg/arrow_btn_back_dark.svg';
import arrow_btn_back_light from '../../assets/svg/arrow_btn_back_light.svg';
import talken_logo from '../../../src/talkclaim/talk.png';
import ReactTooltip from 'react-tooltip';
import env from '../../env';
import GetSnsButtons from 'components/getsnsbuttons/GetSnsButtons';
import { useThemeMode } from 'components/common/AppStoreType';
import AuctionSaleAndPrice from 'components/auctionsaleandprice/AuctionSaleAndPrice';
import useScreenSize from 'components/common/useScreenSize';
import { useSelector } from 'react-redux';
import { getBidListBySellbookId, getSellbook } from '../../services/services';
import axios from 'axios';
import makeBlockie from 'ethereum-blockies-base64';
import { getActiveWallet } from '../../utils/getActiveWallet';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { HandleMediaError } from 'components/common/HandleMediaError';

type ExMBoxType = MBoxTypes & {
  companyLogo: string;
  companyName: string;
};

type LinkTypes = {
  type: string;
  url: string;
  useExternalUrl: boolean;
};

type ExMBoxItemTypes = MBoxItemTypes & {
  boxContractAddress: string;
  fiatPrice: number | null;
};

type SellData = {
  sellbook?: any;
};

const carouselOption = {
  additionalTransfrom: 0,
  arrows: false,
  autoPlay: false,
  autoPlaySpeed: 5000,
  draggable: true,
  focusOnSelect: false,
  keyBoardControl: true,
  minimumTouchDrag: 80,
  pauseOnHover: true,
  renderArrowsWhenDisabled: false,
  renderButtonGroupOutside: true,
  renderDotsOutside: true,
  rewind: false,
  rewindWithAnimation: false,
  rtl: false,
  shouldResetAutoplay: true,
  showDots: true,
  // slidesToSlide: 1,
  swipeable: true,
  infinite: true,
};

const SellDetails = () => {
  const { isDarkMode } = useThemeMode();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  const { copyToClipBoard, copyResult, setCopyResult } = useCopyToClipBoard();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // useOnClickOutside(ref, () => setDropdownOpen(false));
  const videoRef = useRef<HTMLVideoElement>(null);

  const user = useSelector((state: any) => state.user.user);
  const uid = user.uid;
  const myWallet = getActiveWallet();

  const { library } = useWeb3React();
  const [mBoxInfo, setMBoxInfo] = useState<ExMBoxType | null>(null);
  const [randomItems, setRandomItems] = useState<ExMBoxItemTypes[] | null>(null);
  const [itemInfo, setItemInfo] = useState<MBoxItemTypes | null>(null);
  const [featuredInfo, setFeaturedInfo] = useState<FeaturedTypes | null>(null);
  const [collectionInfo, setCollectionInfo] = useState<any>();
  const [needLoad, setNeedLoad] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    type: '',
    message: '',
  });
  const [accordionActiveKeys, setAccordionActiveKeys] = useState(['1']);
  const outLinkIcon = isDarkMode ? arrow_up_right : arrow_up_right_black;
  const arrow_btn_back = isDarkMode ? arrow_btn_back_dark : arrow_btn_back_light;
  const handleCloseSnackbar = () => {
    setOpenSnackbar({
      open: false,
      type: 'info',
      message: '',
    });
    setCopyResult(false);
  };

  interface SellDataType {
    sellbook: any;
  }
  const [isZoomImage, setIsZoomImage] = React.useState(false);
  const { state } = useLocation();
  // console.log('!! this is props handed over:::', state);
  const [sellItem, setSellItem] = useState(null);
  const [sellData, setSellData] = useState<SellDataType>();
  const [owner, setOwner] = useState<any>();
  const [isImg, setIsImg] = useState<boolean>(true);
  const [traits, setTraits] = useState([]);
  // console.log('state:::', state);
  console.log('sellItem:::', sellItem);
  // console.log('traits::::', traits);
  const fetchSellbook = async () => {
    if (state?.item) {
      if (state?.item?.nftInfo?.attributes?.length || state?.item?.metadata?.attributes?.length) {
        setAccordionActiveKeys([...accordionActiveKeys, '0']);
      }
      setSellItem(state?.item);
      setSellData({ sellbook: state?.item });
      getOwner(state?.item);
      return;
    } else if (state && !state?.item) {
      if (state?.nftInfo?.attributes?.length || state?.item?.metadata?.attributes?.length) {
        setAccordionActiveKeys([...accordionActiveKeys, '0']);
      }
      setSellItem(state);
      setSellData({ sellbook: state });
      getOwner(state);
      return;
    } else if (params.sellbookId) {
      const result = await getSellbook(params.sellbookId);
      const sellbook = result?.data?.data?.sellbook;
      if (sellbook?.nftInfo?.attributes?.length > 0) {
        if (sellbook?.nftInfo?.attributes[0]?.properties?.length) {
          setAccordionActiveKeys([...accordionActiveKeys, '0']);
        }
      } else if (sellbook?.nftInfo?.metadata?.attributes?.length) {
        if (sellbook?.nftInfo?.metadata?.attributes?.length) {
          setAccordionActiveKeys([...accordionActiveKeys, '0']);
        }
      }
      setSellItem(sellbook);
      setSellData({ sellbook: sellbook });
      getOwner(sellbook);
    }
  };

  const getTargetChainId = (chain: string) => {
    switch (chain) {
      case 'ethereum':
        return env.REACT_APP_TARGET_NETWORK_ETH;
      case 'binance':
        return env.REACT_APP_TARGET_NETWORK_BNB;
      case 'polygon':
        return env.REACT_APP_TARGET_NETWORK_MATIC;
      case 'klaytn':
      default:
        return env.REACT_APP_TARGET_NETWORK_KLAY;
    }
  };
  const [scanner, setScanner] = useState('');
  const [target, setTarget] = useState(8217);
  useEffect(() => {
    const targetChain = getTargetChainId(sellItem?.nftInfo?.chain);
    setTarget(targetChain);
    setScanner(SCAN_URL[targetChain]);
    if (sellItem?.nftInfo?.attributes?.length > 0) {
      setAccordionActiveKeys([...accordionActiveKeys, '0']);
      setTraits(sellItem?.nftInfo?.attributes);
    } else if (sellItem?.nftInfo?.metadata?.attributes?.length > 0) {
      setTraits(sellItem?.nftInfo?.metadata?.attributes);
    }
  }, [sellItem]);

  const onChangeAccordion = (e) => {
    setAccordionActiveKeys(e);
  };
  useEffect(() => {
    if (needLoad) {
      fetchSellbook();
      fetchTraits();
    }
    setNeedLoad(false);
  }, [needLoad, state, params]);

  const getOwner = async (item: any) => {
    const res = await getUserById(item?.uid);
    if (res.status === 200) {
      setOwner(res.data?.user);
    }
  };

  function toStringByFormatting(source: Date) {
    const year = source.getFullYear();
    const month = source.getMonth() + 1;
    const day = source.getDate();

    return [year, month, day].join('.');
  }

  const handleClickSeeMore = () => {
    if (mBoxInfo) {
      if (mBoxInfo?.isCollection) {
        if (mBoxInfo?.mysteryboxItems && mBoxInfo?.mysteryboxItems[0].id) {
          navigate(`/collection/${mBoxInfo?.id}/${mBoxInfo?.mysteryboxItems[0]?.id}`);
        }
      } else {
        if (mBoxInfo?.isAirdrop) {
          if (mBoxInfo?.mysteryboxItems && mBoxInfo?.mysteryboxItems[0]?.id) {
            navigate(`/airdrop/${mBoxInfo?.id}/${mBoxInfo?.mysteryboxItems[0]?.id}`);
          }
        } else {
          navigate(`/mbox/${mBoxInfo?.id}`);
        }
      }
    }
  };

  const getSnsButtons = () => {
    if (featuredInfo && featuredInfo?.links) {
      const test = featuredInfo?.links?.map((link: LinkTypes) => {
        return (
          <div
            style={{
              cursor: 'pointer',
            }}
            key={link.type}
            className="info-item hide-max-1024px"
            onClick={() => window.open(link.url)}
          >
            <div className="image-item hide-max-1024px">
              {link.type === 'SITE' && (
                <img src={website_icon} alt="Website Icon" data-for="tooltip-website" data-tip />
              )}
              {link.type === 'DISCORD' && (
                <img src={icon_discord} alt="Website Icon" data-for="tooltip-discord" data-tip />
              )}
              {link.type === 'TWITTER' && (
                <img src={icon_twitter} alt="Website Icon" data-for="tooltip-twitter" data-tip />
              )}
              {link.type === 'INSTAGRAM' && (
                <img src={icon_instagram} alt="Website Icon" data-for="tooltip-instagram" data-tip />
              )}
            </div>
            <ReactTooltip
              id="tooltip-website"
              getContent={(dataTip) => 'Website'}
              type={'light'}
              offset={{ top: 10 }}
              effect={'solid'}
            />
            <ReactTooltip
              id="tooltip-discord"
              getContent={(dataTip) => 'Discord'}
              type={'light'}
              offset={{ top: 10 }}
              effect={'solid'}
            />
            <ReactTooltip
              id="tooltip-twitter"
              getContent={(dataTip) => 'Twitter'}
              type={'light'}
              offset={{ top: 10 }}
              effect={'solid'}
            />
            <ReactTooltip
              id="tooltip-instagram"
              getContent={(dataTip) => 'Instagram'}
              type={'light'}
              offset={{ top: 10 }}
              effect={'solid'}
            />
          </div>
        );
      });
      return test;
    } else {
      return null;
    }
  };

  useEffect(() => {
    setOpenSnackbar({
      open: copyResult,
      type: 'success',
      message: 'Copied!',
    });
  }, [copyResult]);

  const fetchTraits = () => {
    if (state?.item) {
      const traits =
        state?.item?.nftInfo?.attributes ||
        state?.item?.nftInfo?.metadata?.attributes ||
        state?.item?.nftInfo?.metadata?.traits ||
        [];
      if (traits.length > 0) {
        setAccordionActiveKeys([...accordionActiveKeys, '0']);
        setTraits(traits);
      }
    }
  };

  useEffect(() => {
    videoRef.current?.load();
  }, [itemInfo]);

  useEffect(() => {
    const fetchCollection = async () => {
      if (params?.contractAddress) {
        const collectionRes = await getCollectionByContractAddress(params.contractAddress);
        if (collectionRes?.data) {
          setCollectionInfo(collectionRes.data?.data);
        }
      }
    };
    fetchCollection();
  }, []);

  const refDropdown = useRef() as MutableRefObject<HTMLDivElement>;
  // useOnClickOutsideDropdown(refDropdown, () => setDropdownOpen(false));

  const screenSize = useScreenSize();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleContractAddressGetOutLink = () => {
    moveToScope(
      sellItem?.nftInfo?.chain === 'klaytn'
        ? env.REACT_APP_TARGET_NETWORK_KLAY
        : sellItem?.nftInfo?.chain === 'ethereum'
        ? env.REACT_APP_TARGET_NETWORK_ETH
        : sellItem?.nftInfo?.chain === 'binance'
        ? env.REACT_APP_TARGET_NETWORK_BNB
        : sellItem?.nftInfo?.chain === 'polygon'
        ? env.REACT_APP_TARGET_NETWORK_MATIC
        : env.REACT_APP_TARGET_NETWORK,
      sellItem?.nftInfo?.contractAddress,
      true
    );
  };
  const handleMBoxInfoTokenId = () => {
    window.open(`${scanner}nft/${mBoxInfo?.boxContractAddress}/${params?.tokenId}`);
  };
  const handleSellItemTokenId = (chain: string) => {
    window.open(`${scanner}nft/${sellItem?.nftInfo?.contractAddress}/${sellItem?.tokenId}`);
  };
  const hasCJK = (string) => {
    const regex = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f\u3131-\uD79D]/;
    return string?.match(regex) ? true : false;
  };
  const itemName = sellItem?.nftInfo?.metadata?.name
    ? sellItem?.nftInfo?.metadata?.name
    : typeof sellItem?.nftInfo?.name === 'string'
    ? sellItem?.nftInfo?.name
    : sellItem?.nftInfo?.name?.en
    ? sellItem?.nftInfo?.name?.en
    : sellItem?.nftInfo?.metadata?.name;
  const isProduction = process.env.REACT_APP_PHASE === 'production';
  return (
    <main className="collectibles-item-details-container min-height-content">
      {/*{mBoxInfo && itemInfo && (*/}
      <div className="collectibles-details-wp">
        <button className="back-button" onClick={() => navigate(-1)}>
          <img src={arrow_btn_back} alt="arrow back" /> Back
        </button>
        <div className="product-details">
          <div className="details-box-mobile">
            <div className="banner-dropdown" ref={ref}>
              <div className="logo">
                <img
                  src={
                    owner?.profile_image
                      ? owner?.profile_image
                      : owner?.eth_address
                      ? makeBlockie(owner?.eth_address?.toLowerCase())
                      : owner?.abc_address
                      ? makeBlockie(owner?.abc_address?.toLowerCase())
                      : talken_logo
                  }
                  alt={mBoxInfo?.featured?.company.name.en || 'talken'}
                  className="logo-img"
                />
                <div className="logo-info">
                  <div className="creator">Owned by</div>
                  <div className="name  text-over-ellip">{owner?.name || 'Talken'}</div>
                </div>
              </div>
              {/* list sns icon */}
              <GetSnsButtons
                chainId={mBoxInfo?.chainId}
                boxContractAddress={mBoxInfo?.boxContractAddress}
                links={featuredInfo?.links}
              />
            </div>
            {/* <div className="line-banner"></div> */}
          </div>
          <div className="wrapper-left">
            <div className="showcase-box">
              {/*<img src={itemInfo.itemImage} alt="" className="thumbnail" />*/}
              {sellItem?.nftInfo?.image?.split('.')?.pop() === 'mp4' ||
              sellItem?.nftInfo?.extension === 'mp4' ||
              sellItem?.nftInfo?.metadata?.image?.split('.')?.pop() === 'mp4' ||
              sellItem?.nftInfo?.metadata?.extension === 'mp4' ||
              sellItem?.nftInfo?.animation_url ||
              sellItem?.nftInfo?.metadata?.animation_url ? (
                <video
                  ref={videoRef}
                  muted
                  controls
                  autoPlay
                  playsInline
                  webkit-playsinline="true"
                  className="thumbnail"
                  loop
                  onError={HandleMediaError}
                  key={`${(
                    sellItem?.nftInfo?.animation_url ||
                    sellItem?.nftInfo?.metadata?.animation_url ||
                    sellItem?.nftInfo?.image ||
                    sellItem?.nftInfo?.metadata?.image
                  )
                    ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                    .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}#t=0.001`}
                >
                  <source
                    src={`${(
                      sellItem?.nftInfo?.animation_url ||
                      sellItem?.nftInfo?.metadata?.animation_url ||
                      sellItem?.nftInfo?.image ||
                      sellItem?.nftInfo?.metadata?.image
                    )
                      ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                      .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}#t=0.001`}
                    type="video/mp4"
                  />
                </video>
              ) : (
                <>
                  <div className="bg-linear hidden-mobile hidden-tablet"></div>
                  <img
                    className="thumbnail"
                    // style={{ objectFit: 'cover' }}
                    src={
                      sellItem?.nftInfo?.alt_url ||
                      sellItem?.nftInfo?.image ||
                      sellItem?.nftInfo?.metadata?.alt_url ||
                      sellItem?.nftInfo?.metadata?.image
                        ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                        .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')
                    }
                    alt=""
                    onError={HandleMediaError}
                  />
                  {/* modal zoom image */}
                  <div className="zoom-image" onClick={() => setIsZoomImage(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 41 40" fill="none">
                      <g filter="url(#filter0_b_5137_8622)">
                        <circle cx="20.5098" cy="20" r="20" fill="white" fillOpacity="0.8" />
                      </g>
                      <path
                        d="M29.5098 17V11H23.5098"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.5098 23V29H17.5098"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M29.5098 11L22.0098 18.5"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.0098 21.5L11.5098 29"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <defs>
                        <filter
                          id="filter0_b_5137_8622"
                          x="-4.49023"
                          y="-5"
                          width="50"
                          height="50"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood floodOpacity="0" result="BackgroundImageFix" />
                          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.5" />
                          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5137_8622" />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_backgroundBlur_5137_8622"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                  <ReactModal isOpen={isZoomImage} className={'modal-zoom-image'}>
                    <div className="close-modal" onClick={() => setIsZoomImage(false)}>
                      <img src={close_icon} alt="Close Icon" />
                    </div>
                    <img
                      className="image"
                      src={
                        sellItem?.nftInfo?.alt_url ||
                        sellItem?.nftInfo?.image ||
                        sellItem?.nftInfo?.metadata?.alt_url ||
                        sellItem?.nftInfo?.metadata?.image
                          ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                          .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')
                      }
                      alt=""
                    />
                  </ReactModal>
                </>
              )}
              {/* <canvas className="canvas-card" width="1125" height="1125" style={{ width: '900px', height: '900px' }}></canvas> */}
            </div>
            {/* dropdown change color */}
            {/*<Accordion defaultActiveKey={['0']} alwaysOpen>*/}
            {screenSize > 640 && (
              <Accordion activeKey={accordionActiveKeys} alwaysOpen onSelect={onChangeAccordion}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <div className="content-left">
                      <div className="image-properties">
                        {isDarkMode ? (
                          <img src={icon_properties} alt="Traits" />
                        ) : (
                          <img src={icon_properties_black} alt="Traits" />
                        )}
                      </div>
                      <div className="title-properties">Traits</div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="accordion-properties">
                    <div className="padding-content">
                      {traits?.length > 0 && (
                        <>
                          {traits?.map((key, index) => {
                            const tooltipTrait = `tooltipTrait-${index}`;

                            if (key['trait_type']) {
                              return (
                                <div className="item-properties" key={index}>
                                  <div
                                    className={`content-01 ${hasCJK(key['trait_type']) ? 'cjk-font' : ''}`}
                                    data-for={key['trait_type']?.length > 15 ? tooltipTrait : undefined}
                                    data-tip={key['trait_type']?.length > 15 ? 'true' : undefined}
                                  >
                                    {key['trait_type']}
                                  </div>
                                  <div
                                    className={`content-02 ${hasCJK(key['value']) ? 'cjk-font' : ''}`}
                                    data-for={key['value']?.length > 30 ? tooltipTrait : undefined}
                                    data-tip={key['value']?.length > 30 ? 'true' : undefined}
                                  >
                                    {key['value']}
                                  </div>
                                  {(key['value']?.length > 30 || key['trait_type']?.length > 15) && (
                                    <ReactTooltip
                                      key={tooltipTrait}
                                      id={tooltipTrait}
                                      className="tooltip-box-trait"
                                      multiline={true}
                                      place={`${screenSize < 769 ? 'bottom' : 'top'}`}
                                      effect={'solid'}
                                    >
                                      <ul className="mt-0 p-l-10">
                                        <li
                                          className={`text-start text-uppercase ${
                                            hasCJK(key['trait_type']) && 'cjk-font'
                                          }`}
                                        >
                                          {key['trait_type']}
                                        </li>
                                        <li className={`text-start ${hasCJK(key['value']) && 'cjk-font'}`}>
                                          {key['value']}
                                        </li>
                                      </ul>
                                    </ReactTooltip>
                                  )}
                                </div>
                              );
                            } else {
                              return undefined;
                            }
                          })}
                        </>
                      )}
                      {sellItem?.nftInfo?.attributes?.length > 0 &&
                        sellItem?.nftInfo?.attributes[0]?.properties?.map((key, index) => {
                          if (key['type']) {
                            return (
                              <div className="item-properties">
                                <div className="content-01">{key['type']}</div>
                                <div className="content-02">{key['name']}</div>
                              </div>
                            );
                          } else return undefined;
                        })}
                      {sellItem?.nftInfo?.metadata?.attributes?.length > 0 ??
                        sellItem?.nftInfo?.attributes[0]?.properties?.map((key, index) => {
                          if (key['type']) {
                            return (
                              <div className="item-properties">
                                <div className="content-01">{key['type']}</div>
                                <div className="content-02">{key['name']}</div>
                              </div>
                            );
                          } else return undefined;
                        })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                {!isProduction && (
                  <Accordion.Item eventKey="2" className="border-bottom-0">
                    <Accordion.Header>
                      <div className="content-left">
                        <div className="image-properties">
                          {isDarkMode ? <img src={icon_note} alt="About" /> : <img src={icon_note} alt="About" />}
                        </div>
                        <div className="title-properties">
                          About &nbsp;
                          {collectionInfo?.collection?.openseaInfo ? collectionInfo?.collection?.openseaInfo?.name : ''}
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body className="accordion-properties">
                      <div className="d-flex align-items-start">
                        <div className="img-about">
                          {collectionInfo?.collection?.openseaInfo && (
                            <img src={collectionInfo?.collection?.openseaInfo?.image_url || about_img} />
                          )}
                        </div>
                        <div className="d-flex item-about-container">
                          <p className="m-b-6 text-pre-wrap">{collectionInfo?.collection?.openseaInfo?.description}</p>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <div className="content-left">
                      <div className="image-details">
                        {isDarkMode ? (
                          <img src={icon_details} alt="Details" />
                        ) : (
                          <img src={icon_details_black} alt="Details" />
                        )}
                      </div>
                      <div className="title-details">Details</div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="accordion-details">
                    <div className="padding-content">
                      <div className="item-details">
                        <div className="name">Contract Address</div>
                        <div className="info-name" onClick={handleContractAddressGetOutLink}>
                          {splitAddress(sellItem?.nftInfo?.contractAddress)}
                          <img src={outLinkIcon} />
                        </div>
                      </div>
                      <div className="item-details">
                        <div className="name">Token ID</div>
                        <div className="info-name" onClick={() => handleSellItemTokenId(sellItem?.nftInfo?.chain)}>
                          {splitAddress(sellItem?.tokenId)}
                          <img src={outLinkIcon} />
                        </div>
                      </div>
                      <div className="item-details">
                        <div className="name">Token Standard</div>
                        <div className="info-name">
                          {sellItem?.nftInfo?.contract_type === 'ERC1155' || sellItem?.nftInfo?.contract_type === 'mt'
                            ? 'ERC-1155'
                            : 'ERC-721'}
                        </div>
                      </div>
                      <div className="item-details">
                        <div className="name">Chain</div>
                        <div className="info-name" style={{ textTransform: 'capitalize' }}>
                          {getNetworkNameById(
                            sellItem?.nftInfo?.chain === 'ethereum'
                              ? env.REACT_APP_TARGET_NETWORK_ETH
                              : sellItem?.nftInfo?.chain === 'klaytn'
                              ? env.REACT_APP_TARGET_NETWORK_KLAY
                              : sellItem?.nftInfo?.chain === 'polygon'
                              ? env.REACT_APP_TARGET_NETWORK_MATIC
                              : sellItem?.nftInfo?.chain === 'binance'
                              ? env.REACT_APP_TARGET_NETWORK_BNB
                              : env.REACT_APP_TARGET_NETWORK
                          )}
                        </div>
                      </div>
                      {/*<div className="item-details">*/}
                      {/*  <div className="name">Last Updated</div>*/}
                      {/*  <div className="info-name">{toStringByFormatting(new Date(sellItem?.updatedAt))}</div>*/}
                      {/*</div>*/}
                      {/* <div className="item-details">
                      <div className="name">Creator Earnings</div>
                      <div className="info-name">{sellItem?.creatorFee !== null ? sellItem?.creatorFee : 0}%</div>
                    </div> */}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
          </div>

          <div className="details-box">
            <div className="banner-dropdown" ref={ref}>
              <div className="logo">
                <img
                  src={
                    owner?.profile_image
                      ? owner?.profile_image
                      : owner?.eth_address
                      ? makeBlockie(owner?.eth_address?.toLowerCase())
                      : owner?.abc_address
                      ? makeBlockie(owner?.abc_address?.toLowerCase())
                      : talken_logo
                  }
                  alt={mBoxInfo?.featured?.company.name.en || 'talken'}
                  className="logo-img"
                />
                <div className="logo-info">
                  <div className="creator">Owned by</div>
                  <div className="name text-over-ellip">{owner?.name || 'Talken'}</div>
                </div>
              </div>
              {/* list sns icon  */}
              <GetSnsButtons
                links={featuredInfo?.links}
                chainId={mBoxInfo?.chainId}
                boxContractAddress={mBoxInfo?.boxContractAddress}
              />
            </div>
            {/* auction sale and fixed price mobile  */}
            {/* {screenSize < 640 && sellData && (
              <AuctionSaleAndPrice
                myitem={myWallet?.toLowerCase() === sellItem?.wallet.toLowerCase()}
                dataDetailMyItems={sellData}
              />
            )} */}
            {/* <div className="line-banner"></div> */}
            <div className={`name-product ${hasCJK(itemName) ? 'cjk-font' : ''}`}>
              {sellItem?.nftInfo?.metadata?.name
                ? sellItem?.nftInfo?.metadata?.name
                : typeof sellItem?.nftInfo?.name === 'string'
                ? sellItem?.nftInfo?.name
                : sellItem?.nftInfo?.name?.en
                ? sellItem?.nftInfo?.name?.en
                : sellItem?.nftInfo?.metadata?.name}{' '}
            </div>
            <div
              className={`sub-product ${
                hasCJK(sellItem?.nftInfo?.description || sellItem?.nftInfo?.metadata?.description || 'No description')
                  ? 'cjk-font'
                  : ''
              }${screenSize < 640 ? ' border-desciption' : ''}`}
            >
              {/*<ConvertUrlsToLinks text={itemInfo?.description} />*/}
              <ConvertUrlsToLinks
                text={sellItem?.nftInfo?.description || sellItem?.nftInfo?.metadata?.description || 'No description'}
              />
            </div>
            {/*<a*/}
            {/*  target="_blank"*/}
            {/*  href="https://polygonscan.com/token/0xF3e34e2022029A7eCb38d7373f7171f478670B20?a=48"*/}
            {/*  className="authenticity-button"*/}
            {/*  rel="noreferrer"*/}
            {/*>*/}
            {/*  <img src={ic_authenticity} alt="authenticity-icon" />*/}
            {/*  Authenticity*/}
            {/*</a>*/}
            {/* <div className="list-item">
                <div className="item">
                  <div className="label">Token ID</div>
                  <div className="value">{splitAddress(params.id)}</div>
                </div>
                <div className="item">
                  <div className="label">Created Date</div>
                  <div className="value">{toStringByFormatting(new Date(mBoxInfo.createdAt!))}</div>
                </div>
                <div className="item">
                  <div className="label">Release Date</div>
                  <div className="value">{toStringByFormatting(new Date(mBoxInfo.releaseDatetime!))}</div>
                </div>
                <div className="item">
                  <div className="label">Total Items</div>
                  <div className="value">{itemInfo.issueAmount}</div>
                </div>
                <div className="item">
                  <div className="label" data-qa-component="token-type-label">
                    Token Standard
                  </div>
                  <div className="value" data-qa-component="token-type-value">
                    ERC-721
                  </div>
                </div>
                <div className="item">
                  <div className="label">Chain</div>
                  <div className="value" style={{ textTransform: 'capitalize' }}>
                    {getNetworkNameByChainId(env.REACT_APP_TARGET_NETWORK_KLAY ?? 8217)}
                  </div>
                </div>
              </div> */}
            {/* auction sale and fixed price pc  */}
            {sellData && (
              <AuctionSaleAndPrice
                myitem={myWallet?.toLowerCase() === sellItem?.wallet?.toLowerCase()}
                dataDetailMyItems={sellData}
              />
            )}
            {/* <div className="list-trade"></div> */}
            {screenSize <= 640 && (
              <div className="traits-and-detail-mb">
                <Accordion activeKey={accordionActiveKeys} alwaysOpen onSelect={onChangeAccordion}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="content-left">
                        <div className="image-properties">
                          {isDarkMode ? (
                            <img src={icon_properties} alt="Traits" />
                          ) : (
                            <img src={icon_properties_black} alt="Traits" />
                          )}
                        </div>
                        <div className="title-properties">Traits</div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body className="accordion-properties">
                      <div className="padding-content">
                        {traits?.length > 0 && (
                          <>
                            {traits?.map((key, index) => {
                              const tooltipTrait = `tooltipTrait-${index}`;

                              if (key['trait_type']) {
                                return (
                                  <div className="item-properties" key={index}>
                                    <div
                                      className={`content-01 ${hasCJK(key['trait_type']) ? 'cjk-font' : ''}`}
                                      data-for={key['trait_type']?.length > 8 ? tooltipTrait : undefined}
                                      data-tip={key['trait_type']?.length > 8 ? 'true' : undefined}
                                    >
                                      {key['trait_type']}
                                    </div>
                                    <div
                                      className={`content-02 ${hasCJK(key['value']) ? 'cjk-font' : ''}`}
                                      data-for={key['value']?.length > 16 ? tooltipTrait : undefined}
                                      data-tip={key['value']?.length > 16 ? 'true' : undefined}
                                    >
                                      {key['value']}
                                    </div>
                                    {(key['value']?.length > 16 || key['trait_type']?.length > 8) && (
                                      <ReactTooltip
                                        key={tooltipTrait}
                                        id={tooltipTrait}
                                        className="tooltip-box-trait"
                                        multiline={true}
                                        place={`${screenSize < 769 ? 'bottom' : 'top'}`}
                                        effect={'solid'}
                                      >
                                        <ul className="mt-0 p-l-10">
                                          <li
                                            className={`text-start text-uppercase ${
                                              hasCJK(key['trait_type']) && 'cjk-font'
                                            }`}
                                          >
                                            {key['trait_type']}
                                          </li>
                                          <li className={`text-start ${hasCJK(key['value']) && 'cjk-font'}`}>
                                            {key['value']}
                                          </li>
                                        </ul>
                                      </ReactTooltip>
                                    )}
                                  </div>
                                );
                              } else {
                                return undefined;
                              }
                            })}
                          </>
                        )}
                        {sellItem?.nftInfo?.attributes?.length > 0 &&
                          sellItem?.nftInfo?.attributes[0]?.properties?.map((key, index) => {
                            if (key['type']) {
                              return (
                                <div className="item-properties">
                                  <div className="content-01">{key['type']}</div>
                                  <div className="content-02">{key['name']}</div>
                                </div>
                              );
                            } else return undefined;
                          })}
                        {sellItem?.nftInfo?.metadata?.attributes?.length > 0 ??
                          sellItem?.nftInfo?.attributes[0]?.properties?.map((key, index) => {
                            if (key['type']) {
                              return (
                                <div className="item-properties">
                                  <div className="content-01">{key['type']}</div>
                                  <div className="content-02">{key['name']}</div>
                                </div>
                              );
                            } else return undefined;
                          })}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  {!isProduction && (
                    <Accordion.Item eventKey="2" className="border-bottom-0">
                      <Accordion.Header>
                        <div className="content-left">
                          <div className="image-properties">
                            {isDarkMode ? <img src={icon_note} alt="About" /> : <img src={icon_note} alt="About" />}
                          </div>
                          <div className="title-properties">
                            About &nbsp;
                            {collectionInfo?.collection?.openseaInfo
                              ? collectionInfo?.collection?.openseaInfo?.name
                              : ''}
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body className="accordion-properties">
                        <div className="d-flex align-items-start">
                          <div className="img-about">
                            {collectionInfo?.collection?.openseaInfo && (
                              <img src={collectionInfo?.collection?.openseaInfo?.image_url || about_img} />
                            )}
                          </div>
                          <div className="d-flex flex-column item-about-container">
                            <p className="m-b-6 text-pre-wrap">
                              {collectionInfo?.collection?.openseaInfo?.description}
                            </p>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <div className="content-left">
                        <div className="image-properties">
                          {isDarkMode ? (
                            <img src={icon_details} alt="Details" />
                          ) : (
                            <img src={icon_details_black} alt="Details" />
                          )}
                        </div>
                        <div className="title-details">Details</div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body className="accordion-details">
                      <div className="padding-content">
                        <div className="item-details">
                          <div className="name">Contract Address</div>
                          <div className="info-name" onClick={handleContractAddressGetOutLink}>
                            {splitAddress(sellItem?.nftInfo?.contractAddress)}
                            <img src={outLinkIcon} />
                          </div>
                        </div>
                        <div className="item-details">
                          <div className="name">Token ID</div>
                          <div className="info-name" onClick={() => handleSellItemTokenId(sellItem?.nftInfo?.chain)}>
                            {splitAddress(sellItem?.tokenId)}
                            <img src={outLinkIcon} />
                          </div>
                        </div>
                        <div className="item-details">
                          <div className="name">Token Standard</div>
                          <div className="info-name">
                            {sellItem?.nftInfo?.contract_type === 'ERC1155' || sellItem?.nftInfo?.contract_type === 'mt'
                              ? 'ERC-1155'
                              : 'ERC-721'}
                          </div>
                        </div>
                        <div className="item-details">
                          <div className="name">Chain</div>
                          <div className="info-name" style={{ textTransform: 'capitalize' }}>
                            {getNetworkNameById(
                              sellItem?.nftInfo?.chain === 'ethereum'
                                ? env.REACT_APP_TARGET_NETWORK_ETH
                                : sellItem?.nftInfo?.chain === 'klaytn'
                                ? env.REACT_APP_TARGET_NETWORK_KLAY
                                : sellItem?.nftInfo?.chain === 'polygon'
                                ? env.REACT_APP_TARGET_NETWORK_MATIC
                                : sellItem?.nftInfo?.chain === 'binance'
                                ? env.REACT_APP_TARGET_NETWORK_BNB
                                : env.REACT_APP_TARGET_NETWORK
                            )}
                          </div>
                        </div>
                        {/*<div className="item-details">*/}
                        {/*  <div className="name">Last Updated</div>*/}
                        {/*  <div className="info-name">{toStringByFormatting(new Date(sellItem?.updatedAt))}</div>*/}
                        {/*</div>*/}
                        {/* <div className="item-details">
                      <div className="name">Creator Earnings</div>
                      <div className="info-name">{sellItem?.creatorFee !== null ? sellItem?.creatorFee : 0}%</div>
                    </div> */}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            )}
            {/* <div className="wrapper-user-details">
              <div className="title-details">Collection this item belongs to</div>
              <div className="wrapper-content-details">
                <div className="wrapper-user">
                  <div className="avt-user">
                    <img src={itemInfo?.itemImage} alt="icon avatar" />
                  </div>
                  <div className="user-name">{mBoxInfo?.title.en}</div>
                </div>
                <div
                  to={`/collection/${itemInfo.infoId}/${itemInfo.id}`}
                  onClick={handleClickSeeMore}
                >
                  <div className="wrapper-see-collection">
                    <div className="image-see-collection">
                        <img src={see_collection} alt="Icon See Collection" />
                      </div>
                    <div className="title">See this collection</div>
                  </div>
                </div>
              </div>
              <div className="details-info">{mBoxInfo?.introduction.en}</div>
            </div> */}
            {/*<div className="wrapper-other-items">*/}
            {/*  <div className="wrapper-head">*/}
            {/*    <div className="title-items">Other Items</div>*/}
            {/*    <Link className="seemore-otheritems" to={`/creator/${mBoxInfo?.featured?.companyId}`}>*/}
            {/*      See more*/}
            {/*    </Link>*/}
            {/*  </div>*/}
            {/*  <Carousel*/}
            {/*    additionalTransfrom={0}*/}
            {/*    arrows={false}*/}
            {/*    autoPlay={false}*/}
            {/*    autoPlaySpeed={5000}*/}
            {/*    draggable*/}
            {/*    focusOnSelect={false}*/}
            {/*    keyBoardControl*/}
            {/*    minimumTouchDrag={80}*/}
            {/*    pauseOnHover*/}
            {/*    renderArrowsWhenDisabled={false}*/}
            {/*    renderButtonGroupOutside={true}*/}
            {/*    renderDotsOutside={true}*/}
            {/*    rewind={false}*/}
            {/*    rewindWithAnimation={false}*/}
            {/*    rtl={false}*/}
            {/*    shouldResetAutoplay*/}
            {/*    showDots={false}*/}
            {/*    // slidesToSlide={1}*/}
            {/*    swipeable*/}
            {/*    infinite*/}
            {/*    customButtonGroup={<ArrowCarouselItemDetails />}*/}
            {/*    removeArrowOnDeviceType=""*/}
            {/*    dotListClass="dot-other-items"*/}
            {/*    // centerMode={false}*/}
            {/*    className="carousel-other-items"*/}
            {/*    // itemClass=""*/}
            {/*    responsive={{*/}
            {/*      desktop: {*/}
            {/*        breakpoint: {*/}
            {/*          max: 3000,*/}
            {/*          min: 1420,*/}
            {/*        },*/}
            {/*        items: 5,*/}
            {/*        partialVisibilityGutter: 40,*/}
            {/*      },*/}
            {/*      mobile: {*/}
            {/*        breakpoint: {*/}
            {/*          max: 640,*/}
            {/*          min: 0,*/}
            {/*        },*/}
            {/*        items: 2,*/}
            {/*        partialVisibilityGutter: 30,*/}
            {/*      },*/}
            {/*      tablet: {*/}
            {/*        breakpoint: {*/}
            {/*          max: 1024,*/}
            {/*          min: 640,*/}
            {/*        },*/}
            {/*        items: 2,*/}
            {/*        partialVisibilityGutter: 30,*/}
            {/*      },*/}
            {/*      laptopLarge: {*/}
            {/*        breakpoint: {*/}
            {/*          max: 1420,*/}
            {/*          min: 1180,*/}
            {/*        },*/}
            {/*        items: 4,*/}
            {/*        partialVisibilityGutter: 30,*/}
            {/*      },*/}
            {/*      laptop: {*/}
            {/*        breakpoint: {*/}
            {/*          max: 1180,*/}
            {/*          min: 1024,*/}
            {/*        },*/}
            {/*        items: 3,*/}
            {/*        partialVisibilityGutter: 30,*/}
            {/*      },*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    {randomItems &&*/}
            {/*      randomItems.map((item, index) => {*/}
            {/*        return (*/}
            {/*          <Link*/}
            {/*            to={`/klaytn/${item?.boxContractAddress}/${item?.no}/${item?.id}`}*/}
            {/*            className="item-other button"*/}
            {/*            key={index}*/}
            {/*          >*/}
            {/*            <div className="item-image">*/}
            {/*              <img src={item?.itemImage} alt="Image Item" />*/}
            {/*            </div>*/}
            {/*            <div className="info-item-detail">*/}
            {/*              <div className="id-card">{item?.name}</div>*/}
            {/*              <div className="price-item">*/}
            {/*                {getPrice(*/}
            {/*                  mBoxInfo?.fiatPrice || Number(mBoxInfo?.price),*/}
            {/*                  mBoxInfo?.fiatPrice && mBoxInfo?.fiatPrice !== 0 ? 'KRW' : sellItem?.quote*/}
            {/*                )}*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*          </Link>*/}
            {/*        );*/}
            {/*      })}*/}
            {/*  </Carousel>*/}
            {/*</div>*/}
            {/*{mboxInfo?.useRevealLockup && !countDownFinish ? (*/}
            {/*  <>*/}
            {/*    {!countDownFinish && (*/}
            {/*      <CountDownTimer*/}
            {/*        handeCheckCountDownFinish={handeCheckCountDownFinish}*/}
            {/*        targetDate={new Date(mboxInfo?.afterRelease)}*/}
            {/*      />*/}
            {/*    )}*/}
            {/*  </>*/}
            {/*) : (*/}
            {/*  <>*/}
            {/*    {status || balance === 0 ? null : (*/}
            {/*      <button*/}
            {/*        className="btn-trade status"*/}
            {/*        onClick={handleRevealClick}*/}
            {/*      >*/}
            {/*        {isLoading ? (*/}
            {/*          <CircularProgress size={30} color={'inherit'} />*/}
            {/*        ) : (*/}
            {/*          <>*/}
            {/*            <img src={ic_sell} alt="sell" />*/}
            {/*            Reveal*/}
            {/*          </>*/}
            {/*        )}*/}
            {/*        /!*<img src={ic_trade} alt="trade" />*!/*/}
            {/*      </button>*/}
            {/*    )}*/}
            {/*  </>*/}
            {/*)}*/}
          </div>
        </div>
        {/* <div className="price-history"> */}
        {/*<div className="price-history-label">*/}
        {/*  <img src={price_history_lg} alt="price-history" />*/}
        {/*  Price History*/}
        {/*</div>*/}
        {/*<div className="list-price-history"></div>*/}
        {/* </div> */}
        {/*<div className="my-revealed-items">My revealed items</div>*/}
        {/*<div className="marketplace-items">*/}
        {/*  {mboxInfo &&*/}
        {/*    revealItems.map((item, index) => {*/}
        {/*      return (*/}
        {/*        <Link to={`/klaytn/nft/${index}`} key={index}>*/}
        {/*          <div className="item_product" key={index}>*/}
        {/*            <div className="item_product_detail MARKETPLACE_TOTAL_KEY fw-600">*/}
        {/*              /!*<div className="total_item">Total Run: 50</div>*!/*/}
        {/*              <div className="total_item">Index: {item.no}</div>*/}
        {/*            </div>*/}
        {/*            <div className="item_product_detail MARKETPLACE_TYPE_KEY fw-600">*/}
        {/*              <div>erc721</div>*/}
        {/*            </div>*/}
        {/*            <div className="item_product_detail MARKETPLACE_GRAPHICS_KEY">*/}
        {/*              <div className="card-image">*/}
        {/*                {item.extension === 'mp4' ? (*/}
        {/*                  <video*/}
        {/*                    autoPlay*/}
        {/*                    controls*/}
        {/*                    controlsList="nodownload"*/}
        {/*                    muted*/}
        {/*                    loop*/}
        {/*                    width={'100%'}*/}
        {/*                  >*/}
        {/*                    <source src={item.image} type="video/mp4" />*/}
        {/*                  </video>*/}
        {/*                ) : (*/}
        {/*                  <img src={item.image} alt="" />*/}
        {/*                )}*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <div className="item_product_detail MARKETPLACE_AUTHOR_KEY">*/}
        {/*              <div className="owner_product">*/}
        {/*                <div className="owner_product_box">*/}
        {/*                  <div className="owner_product_avatar">*/}
        {/*                    <img src={mboxInfo?.packageImage} alt="" />*/}
        {/*                  </div>*/}
        {/*                  <div className="">{mboxInfo?.title.en}</d>*/}
        {/*                </div>*/}
        {/* <div> */}
        {/* <Link to="/sale"> */}
        {/* <div className="status ">Buy Now</div> */}
        {/* </Link> */}
        {/* </div> */}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <div className="item_product_detail MARKETPLACE_NAME_KEY">*/}
        {/*              <div className="product_name ">{item.name}</div>*/}
        {/*            </div>*/}
        {/*            <div className="item_product_detail MARKETPLACE_BID_KEY">*/}
        {/*              <div className="box-price">*/}
        {/*                <div className="price ">Price</div>*/}
        {/*                <div className="currency ">{`${*/}
        {/*                  mboxInfo.isCollection ? item?.price : mboxInfo.price*/}
        {/*                } ${mboxInfo?.quote}`}</div>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <div className="item_product_detail MARKETPLACE_NAME_TIME">*/}
        {/*              <div>*/}
        {/*                <div className="remaining ">Rarity</div>*/}
        {/*                <div className="remaining-total ">*/}
        {/*                  {getRarityToString(item.rarity)}*/}
        {/*                </div>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </Link>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*</div>*/}
        <CSnackbar
          open={openSnackbar?.open}
          type={openSnackbar?.type}
          message={openSnackbar?.message}
          handleClose={handleCloseSnackbar}
        />
      </div>
      {/*)}*/}
    </main>
  );
};

export default SellDetails;
