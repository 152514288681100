import { ChainId } from '../config';

// Array of available nodes to connect to

export const nodesMainnet = `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`;
export const nodesSepolia = `https://sepolia.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`;
export const nodesCypress = 'https://klaytn-en.kommunedao.xyz:8651';
export const nodesBaobab = 'https://api.baobab.klaytn.net:8651';
export const nodesBscmain = 'https://bsc-dataseed.binance.org';
export const nodesBsctest = 'https://data-seed-prebsc-1-s1.binance.org:8545';
export const nodesMumbai = 'https://polygon-mumbai-pokt.nodies.app';
export const nodesAmoy = 'https://rpc-amoy.polygon.technology';
export const nodesPolygon = 'https://polygon-rpc.com';

export const getSelectedNodeUrl = (chainId: number) => {
  switch (chainId) {
    case ChainId.MAINNET:
      return nodesMainnet;
    case ChainId.SEPOLIA:
      return nodesSepolia;
    case ChainId.KLAYTN:
      return nodesCypress;
    case ChainId.BAOBAB:
      return nodesBaobab;
    case ChainId.BSCMAINNET:
      return nodesBscmain;
    case ChainId.BSCTESTNET:
      return nodesBsctest;
    case ChainId.MUMBAI:
      return nodesMumbai;
    case ChainId.POLYGON:
      return nodesPolygon;
    case ChainId.AMOY:
      return nodesAmoy;
    default:
      return nodesCypress;
  }
};

export default getSelectedNodeUrl;
