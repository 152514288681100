import React, { useEffect, useState } from 'react';
import { getCollectibleList, getCollectibleListInfi, getSellBooks } from '../../services/services';
import SearchBar from 'components/searchbar/SearchBar';
import { MBoxTypes } from '../../types/MBoxTypes';
import { Link } from 'react-router-dom';
import { getPrice } from 'utils/getPrice';
import { getNetworkNameByChainId } from '../../utils/getNetworkNameByChainId';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getRarityToString } from 'utils/getRarityToString';
import eternal from 'assets/icon/eternal_editions.png';
import talk from '../../talkclaim/talk.png';
import env from '../../env';
import { getPriceRecentlyListedItems } from '../../utils/getPriceRecentlyListedItems';
import { useMediaQuery } from 'react-responsive';
import { getContractName } from '../../utils/transactions';
import { Col, Form } from 'react-bootstrap';
import { ChainId } from '../../config';
import useScreenSize from 'components/common/useScreenSize';
import { HandleMediaError } from 'components/common/HandleMediaError';
type ExMBoxType = MBoxTypes & {
  remainingAmount: number | null;
};
const RecentlyListedItems = () => {
  const [collectibleList, setCollectibleList] = useState<ExMBoxType[]>([]);
  const [sellbooks, setSellbooks] = useState([]);

  const [keyword, setKeyword] = useState('');
  const [sortBy, setSortBy] = useState('dropsOpenDatetime:DESC');
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [selectType, setSelectType] = useState({
    fixed: false,
    auction: false,
  });
  const [selectChains, setSelectChains] = useState({
    klaytn: false,
    ethereum: false,
    polygon: false,
    binance: false,
  });
  const selectList = [
    { value: 'dropsOpenDatetime:DESC', label: 'Recently listed' },
    { value: 'createdAt:DESC', label: 'Recently created' },
    { value: 'price:ASC', label: 'Price low to high' },
    { value: 'price:DESC', label: 'Price high to low' },
  ];
  const categoryList = [
    { value: '1', label: 'Category1' },
    { value: '2', label: 'Category2' },
  ];
  const isMobile = useMediaQuery({
    query: '(max-width: 640px)',
  });
  //hot items
  const fetchCollectibleList = async (keyword?: string, sortBy?: string) => {
    const res = await getSellBooks(page);
    if (res?.data?.data) {
      const newList = await Promise.all(
        res.data?.data.sellbooks.map(async (sell: any) => {
          const name = await getContractName(
            sell.nftInfo.contractAddress,
            sell.nftInfo.chain === 'ethereum'
              ? env.REACT_APP_TARGET_NETWORK_ETH
              : sell.nftInfo.chain === 'polygon'
              ? env.REACT_APP_TARGET_NETWORK_MATIC
              : sell.nftInfo.chain === 'klaytn'
              ? env.REACT_APP_TARGET_NETWORK_KLAY
              : sell.nftInfo.chain === 'binance'
              ? env.REACT_APP_TARGET_NETWORK_BNB
              : env.REACT_APP_TARGET_NETWORK
          );
          return {
            ...sell,
            collectionName: name,
          };
        })
      );
      let _newList = newList.slice().filter((item) => {
        if (selectType.fixed && selectType.auction) return item.type === 1 || item.type === 2;
        if (selectType.fixed) return item.type === 1;
        if (selectType.auction) return item.type === 2;
        else return true; // All
      });
      _newList = _newList.slice().filter((item) => {
        const chain = item.chainId;

        const selected = [];
        if (selectChains.ethereum) {
          selected.push(ChainId.MAINNET);
          selected.push(ChainId.SEPOLIA);
        }
        if (selectChains.polygon) {
          selected.push(ChainId.POLYGON);
          selected.push(ChainId.MUMBAI);
          selected.push(ChainId.AMOY);
        }
        if (selectChains.klaytn) {
          selected.push(ChainId.KLAYTN);
          selected.push(ChainId.BAOBAB);
        }
        if (selectChains.binance) {
          selected.push(ChainId.BSCMAINNET);
          selected.push(ChainId.BSCTESTNET);
        }
        // All
        if (!selectChains.klaytn && !selectChains.ethereum && !selectChains.polygon && !selectChains.binance) {
          selected.push(ChainId.MAINNET);
          selected.push(ChainId.SEPOLIA);
          selected.push(ChainId.KLAYTN);
          selected.push(ChainId.BAOBAB);
          selected.push(ChainId.POLYGON);
          selected.push(ChainId.MUMBAI);
          selected.push(ChainId.AMOY);
          selected.push(ChainId.BSCMAINNET);
          selected.push(ChainId.BSCTESTNET);
        }

        return selected.includes(chain);
      });
      _newList = _newList.filter((item) => {
        if (typeof item.nftInfo?.name === 'string') {
          return item.nftInfo?.name?.toLowerCase().includes(keyword.toLowerCase());
        } else {
          return item.nftInfo?.name?.en.toLowerCase().includes(keyword.toLowerCase());
        }
      });
      _newList = _newList.sort((a: any, b: any) => {
        if (sortBy === 'createdAt:DESC')
          return new Date(b.nftInfo.updatedAt).getTime() - new Date(a.nftInfo.updatedAt).getTime();
        else if (sortBy === 'dropsOpenDatetime:DESC')
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        else if (sortBy === 'price:ASC') return a.price - b.price;
        else if (sortBy === 'price:DESC') return b.price - a.price;
        else return;
      });
      setSellbooks(_newList);
      // setSellbooks(res.data.data?.sellbooks);
    }
  };

  useEffect(() => {
    fetchCollectibleList(keyword, sortBy);
  }, [keyword, sortBy, selectChains, selectType]);
  const fetchCollectibleListScroll = async (page) => {
    setPage(page);
    const res = await getSellBooks(page);
    if (res?.data?.data) {
      // const newList = await Promise.all(
      //   res.data.data.map(async (item: any) => {
      //     const id = Math.floor(Math.random() * item.mysteryboxItems[0].issueAmount) + 1;
      //     const milliseconds = new Date().getTime() - Date.parse(item.releaseDatetime);
      //     return {
      //       ...item,
      //       itemId: id,
      //       onsale: milliseconds >= 0 ? true : false,
      //     };
      //   })
      // );
      // setCollectibleList([...collectibleList, ...newList]);
      // setTotalRows(res?.data?.data?.headers?.x_pages_count || 0);
      setSellbooks([...sellbooks, res.data.data?.sellbooks]);
    }
  };
  const screenSize = useScreenSize();
  return (
    <div className="featured-page min-height-content">
      <div className="section-02">
        {/* Featured Collections */}
        <div className="featured-collections padding-bottom-30">
          <div className="wrapper-header title-header">{/* <div className="header-name pb-2">Market</div> */}</div>
          <div className="p-r-12 p-r-xs-0">
            <SearchBar
              titlePage="Market"
              onSearch={(search: string) => {
                setKeyword(search?.trim());
              }}
              onSelect={(value: string) => {
                setSortBy(value);
              }}
              onSelectCategoryAll={() => {
                console.log('all');
              }}
              onSelectCategory={(value: string) => {
                console.log(value);
              }}
              showFirstTwoSelects={true}
              selectList={selectList}
              categoryList={categoryList}
              totalItems={sellbooks?.length}
              setSelectChains={setSelectChains}
              setSelectType={setSelectType}
              selectChains={selectChains}
              selectType={selectType}
            ></SearchBar>
          </div>
          <InfiniteScroll
            className="hot-collectibles w-100 d-grid grid-col-4 grid-col-xs-2 responsive-list-nft gap-4px"
            dataLength={collectibleList.length}
            next={() => fetchCollectibleListScroll(page + 1)}
            hasMore={page < totalRows}
            loader={<h4>Loading...</h4>}
          >
            {sellbooks.map((item: any, index) => {
              return (
                <Link
                  to={`/sellbook/${item.id}/${item.nftInfo?.chain}/${item.nftInfo?.contractAddress}/${item.tokenId}`}
                  state={{
                    item: {
                      ...item,
                    },
                  }}
                  className="button custom-box"
                  key={index}
                >
                  <div className="hot-ollectibles-wrapper">
                    <div className="hot-ollectibles-item">
                      <div className="hot-items-editions">
                        <img src={talk} alt="eternal" className="icon-eternal" />
                      </div>
                      <div className="chain-name">
                        {getNetworkNameByChainId(
                          item?.nftInfo?.chain === 'klaytn'
                            ? env.REACT_APP_TARGET_NETWORK_KLAY
                            : item?.nftInfo?.chain === 'ethereum'
                            ? env.REACT_APP_TARGET_NETWORK_ETH
                            : item?.nftInfo?.chain === 'binance'
                            ? env.REACT_APP_TARGET_NETWORK_BNB
                            : item?.nftInfo?.chain === 'polygon'
                            ? env.REACT_APP_TARGET_NETWORK_MATIC
                            : env.REACT_APP_TARGET_NETWORK
                        )}
                      </div>
                    </div>
                    {/* <div className="hot-ollectibles-item" style={{ textTransform: 'capitalize' }}>
                      <div>{item?.nftInfo?.chain?.toLowerCase()}</div>
                    </div> */}
                    <div className="hot-ollectibles-item position-relative">
                      <div
                        className="img-token"
                        // style={{
                        //   width: '100%',
                        //   height: isMobile ? '156px' : '220px',
                        //   margin: isMobile ? '14px auto 14px' : '14px auto 26px',
                        // }}
                      >
                        {item?.nftInfo?.image?.split('.')?.pop() === 'mp4' ||
                        item?.nftInfo?.extension === 'mp4' ||
                        item?.nftInfo?.metadata?.image?.split('.')?.pop() === 'mp4' ||
                        item?.nftInfo?.metadata?.extension === 'mp4' ? (
                          <video
                            playsInline
                            webkit-playsinline="true"
                            muted
                            controlsList="nodownload"
                            width={'100%'}
                            onError={HandleMediaError}
                          >
                            <source src={`${item?.nftInfo?.image}#t=0.001`} type="video/mp4" />
                          </video>
                        ) : (
                          <img
                            src={
                              item?.nftInfo?.alt_url ||
                              item?.nftInfo?.image ||
                              item?.nftInfo?.metadata?.alt_url ||
                              item?.nftInfo?.metadata?.image
                                ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                                .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')
                            }
                            alt=""
                            draggable={false}
                            onError={HandleMediaError}
                          />
                        )}
                        <div className="wrapper-price-type">
                          <div className={`price-type-home ${screenSize < 640 ? 'fs-xs-10' : ''}`}>
                            {item.type === 1 ? 'Fixed price' : 'Auction'}
                          </div>
                          <div className={`fs-14 fw-7 ${screenSize < 640 ? 'fs-xs-12' : ''}`}>
                            {getPriceRecentlyListedItems(item?.fiatPrice || item?.topPrice)}
                            <span className="m-l-4 text-uppercase">{item.quote}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="name-nft-card">{item.collectionName}</div>
                    <div className="fw-7 line-clamp-1 fs-xs-14 w-100 p-t-4">
                      {item.nftInfo?.metadata?.name
                        ? item.nftInfo?.metadata?.name
                        : typeof item.nftInfo?.name === 'string'
                        ? item.nftInfo?.name
                        : item.nftInfo?.name?.en}
                    </div>
                    {/* <div className="name-nft-card m-l-12 m-r-12 m-b-12 m-b-xs-10">
                      <div className="fs-18 fs-xs-12">
                        {getPriceRecentlyListedItems(item?.fiatPrice || item?.price)}
                        <span className="fs-14 fs-xs-10 m-l-4 text-uppercase">{item.quote}</span>
                      </div>
                    </div> */}
                  </div>
                </Link>
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default RecentlyListedItems;
