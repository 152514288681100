import React, { useEffect, useRef, useState } from 'react';
import { ethers } from 'ethers';
import ReactModal from 'react-modal';
import close_icon from '../../assets/icon/close_icon.svg';
import close_icon_black from '../../assets/icon/close_icon_black.svg';
import pay_creditcard from '../../assets/img/pay-creditcard.png';
import pay_creditcard_gray from '../../assets/img/pay-creditcard-gray.png';
import pay_talken_credit from '../../assets/img/pay_talken_credit.png';
import pay_crypto from '../../assets/img/pay-crypto.png';
import pay_appstore from '../../assets/img/pay_appstore.png';
import pay_googleplay from '../../assets/img/pay_googleplay.png';
import { MBoxItemTypes } from '../../types/MBoxItemTypes';
import { MBoxTypes } from '../../types/MBoxTypes';
import { parseEther, parseUnits } from 'ethers/lib/utils';
import contracts from '../../config/constants/contracts';
import { approveKIP7, buyItem } from '../../utils/transactions';
import { FAILURE, SUCCESS, getTargetChainId, targetNetwork } from '../../config';
import { registerBuy, verifyReceiptIos, verifyReceiptAos, buyKeyCredit } from '../../services/services';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import { CircularProgress, Typography } from '@mui/material';
import { checkConnectWallet, checkKaikasWallet, getTargetNetworkName } from '../../utils/wallet';
import { buyKey, claimAirDrop, getKeyRemains } from '../../utils/marketTransactions';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { BigNumber } from 'ethers';
import { useThemeMode } from 'components/common/AppStoreType';
import { getUA } from 'react-device-detect';
import getAbcWallet from '../../utils/getAbcWallet';
import HelperUtil from '../../utils/HelperUtil';
import { getActiveWallet } from '../../utils/getActiveWallet';
import { setupNetwork } from '../../utils/wallet';
import env from '../../env';
// import { injected, walletconnectv2 } from '../../hooks/connectors';
// import env from '../../env';
const ua = getUA;
const uaArr = ua.split(' ');
const osType = uaArr.find((el) => el.indexOf('osType:') > -1)?.split(':')[1];
const appName = uaArr.find((el) => el.indexOf('appName:') > -1)?.split(':')[1];
const isTalkenApp = appName === 'Talken';

declare global {
  interface Window {
    webkit: any;
    TalkenInterface: any;
    confirmCollectNftSuccess: any;
    confirmCollectNftFail: any;
  }
}
type ExMBoxType = MBoxTypes & {
  companyLogo: string;
  companyName: string;
};

type ExMBoxItemTypes = MBoxItemTypes & {
  collectionInfo: any;
  companyLogo: string;
  companyName: string;
  price: number;
  quote: string;
  index: number;
};

type PaymentWalletsProps = {
  show: any;
  onHide: any;
  openPaymentWalletsSuccess: any;
  itemInfo: any;
  isCollection: boolean;
};
const PaymentWallets: React.FC<PaymentWalletsProps> = ({
  show,
  onHide,
  openPaymentWalletsSuccess,
  itemInfo,
  isCollection,
}) => {
  const { account, library, chainId, activate } = useActiveWeb3React();
  const user = useSelector((state: any) => state.user.user);
  const uid = user.uid;
  const wallet = useSelector((state: any) => state.wallet);
  const [isModalOpenSuccess, setModalOpenSuccess] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState<number>(0);
  const [remains, setRemains] = useState(0);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isBuying, setIsBuying] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [buyItemInfo, setBuyItemInfo] = useState<ExMBoxItemTypes | ExMBoxType | null>(null);
  const ref = useRef();
  const [isIap, setIsIap] = useState(false);

  // get abc wallet address and check OTP ready
  // const { isAbcReady, abcAddress } = getAbcWallet();
  const myWallet = getActiveWallet();
  const login = window.localStorage.getItem('login');

  // const connectWallet = async () => {
  //   const login = window.localStorage.getItem('login') ?? 'sns';
  //   if (login === 'metamask') {
  //     await activate(injected, undefined, true);
  //   } else if (login === 'walletconnector') {
  //     const wc2 = walletconnectv2(env.REACT_APP_TARGET_NETWORK_KLAY);
  //     await activate(wc2, undefined, true);
  //   }
  // };
  // useEffect(() => {
  //   connectWallet();
  // }, []);

  const [target, setTarget] = useState(0);
  useEffect(() => {
    setTarget(itemInfo?.collectionInfo?.chainId);
  }, [itemInfo]);

  const useOnClickOutsideSuccess = (ref: any, handler: any) => {
    useEffect(() => {
      const listener = (event: any) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }, [ref, handler]);
  };

  useOnClickOutsideSuccess(ref, () => setModalOpenSuccess(false));

  useEffect(() => {
    window.confirmCollectNftSuccess = handleCollectSuccess;
    window.confirmCollectNftFail = handleCollectFailed;
    return () => {
      window.confirmCollectNftSuccess = undefined;
      window.confirmCollectNftFail = undefined;
    };
  }, [itemInfo]);
  const handleCollectSuccess = async (nativeResponse: string) => {
    console.log('handleCollectSuccess');
    if (osType === 'IOS') {
      const receipt = nativeResponse.substring(nativeResponse.indexOf('/') + 1, nativeResponse.length);
      const data = { receipt: receipt };
      const res = await verifyReceiptIos(data);
      if (res?.data?.data?.status === 0) {
        // console.log("verifyReceiptIOS succeeded=====>",JSON.stringify(res?.data));
        sendCollect(nativeResponse);
      } else {
        alert('itunes verifyReceipt failed');
      }
    } else {
      const receipt = nativeResponse.substring(0, nativeResponse.indexOf('/'));
      let productId;
      if (isCollection) productId = `c${itemInfo.id}${itemInfo.featuredId.toLowerCase()}`;
      else productId = `m${itemInfo.infoId}${itemInfo.featuredId.toLowerCase()}`;
      const data = { receipt, productId };
      const res = await verifyReceiptAos(data);
      if (res?.data?.data?.receipt?.consumptionState === 1 && res?.data?.data?.receipt?.purchaseState === 0) {
        // console.log("verifyReceiptAOS succeeded=====>",JSON.stringify(res?.data));
        sendCollect(nativeResponse);
      } else {
        alert('Google Play verifyReceipt failed');
      }
    }
    setIsBuying(false);
    openPaymentWalletsSuccess();
    onHide();
  };
  const handleCollectFailed = (nativeResponse: string) => {
    // alert('IAP failed');
  };
  const sendCollect = async (nativeResponse: string) => {
    if (isCollection) {
      // TODO: Collection IAP
    } else {
      console.log('buy mbox');
      try {
        const data = {
          contract: itemInfo.boxContractAddress,
          iapOrderId: nativeResponse,
        };
        const result = await buyKeyCredit(data);
        const receipt = result.data.receipt;
        console.log('buyKeyCreditReceipt::', receipt);
        if (receipt) {
          // TODO : 뭔가 덜 된 것 같은데.....
          const provider = await HelperUtil.getAbcWeb3Provider(target);
          const isKaikas = checkKaikasWallet(wallet, getTargetNetworkName(itemInfo.chainId) ?? '');
          const left = await getKeyRemains(itemInfo.keyContractAddress, itemInfo.boxContractAddress, null, false);
          setRemains(left);
          const data = {
            mysterybox_id: itemInfo.id,
            buyer: uid,
            buyer_address: myWallet,
            isSent: true,
            txHash: receipt.transactionHash,
            fiatPrice: itemInfo.fiatPrice,
            iapOrderId: nativeResponse,
          };
          const res = await registerBuy(data);

          if (res.data.status === SUCCESS) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } catch (error: any) {
        console.log(error.message);
        return false;
      }
    }
  };

  const handleClickBuy = async () => {
    setIsBuying(true);
    let result = false;
    if (selectedPayment === 1) {
      console.log('purchase with credit card');
    } else if (selectedPayment === 2) {
      console.log('purchase with crypto');
      result = await handleClickCrypto();
      setIsBuying(false);
    } else if (selectedPayment === 3) {
      console.log('purchase with app store');
      result = await handleClickIap();
      setIsBuying(false);
    } else {
      console.log('purchase with google');
      result = await handleClickIap();
      setIsBuying(false);
    }

    if (result) {
      openPaymentWalletsSuccess();
      onHide();
    }
  };

  const handleClickCrypto = async () => {
    const isKaikas = checkKaikasWallet(wallet, getTargetNetworkName(itemInfo.chainId) ?? '');
    if (!user.eth_address) {
      // TODO: 지갑 등록이 안되있을 경우의 처리 추가.
      setErrMsg('Please add wallet first. (My Profile > Wallet Address)');
      return false;
    }
    let provider, _provider;
    if (login === 'sns') {
      console.log('--------------------->', target);
      provider = await HelperUtil.getAbcWeb3Provider(target);
      // console.log('!! handleClickCrypto getAbcWeb3Provider = ', provider);
    } else {
      _provider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await _provider.getNetwork();
      if (target !== network.chainId) {
        onHide();
        await setupNetwork(parseInt(target?.toString() ?? env.REACT_APP_TARGET_NETWORK.toString()));
        return;
      }
    }

    if (isCollection) {
      if (!itemInfo.collectionInfo.isAirdrop) {
        // Collection
        const contract = itemInfo?.collectionInfo?.boxContractAddress;
        const quote = itemInfo?.collectionInfo?.quote;
        const index = itemInfo?.no - 1 ?? 0;
        const amount = 1;
        let quoteToken: string;
        let payment: BigNumber;
        console.log('ii:::', itemInfo, quote, chainId, target);
        if (quote === 'klay' || quote === 'wklay') {
          quoteToken = quote === 'klay' ? contracts.klay[target] : contracts.wklay[target];
          payment = parseEther(itemInfo?.price?.toString() ?? '0').mul(amount);
        } else if (quote === 'usdt' || quote === 'usdc') {
          quoteToken = quote === 'usdt' ? contracts.usdt[target] : contracts.usdc[target];
          payment = parseUnits(itemInfo?.price?.toString() ?? '0', 6).mul(amount);
        } else if (quote === 'matic') {
          quoteToken = contracts.matic[target];
          payment = parseEther(itemInfo?.price?.toString() ?? '0').mul(amount);
        } else if (quote === 'talk') {
          quoteToken = contracts.talken[target];
          payment = parseEther(itemInfo?.price?.toString() ?? '0').mul(amount);
        } else {
          quoteToken = '0x0000000000000000000000000000000000000000';
          payment = parseEther(itemInfo?.price?.toString() ?? '0').mul(amount);
        }

        if (quote !== 'klay' && quote !== 'matic') {
          const rlt = await approveKIP7(
            quoteToken!,
            contract,
            payment?.toString(),
            myWallet,
            login === 'sns' ? provider : library,
            isKaikas
          );
          if (rlt === FAILURE) return false;
        }
        try {
          const result = await buyItem(
            contract,
            index,
            1,
            payment?.toString(),
            quoteToken!,
            myWallet,
            login === 'sns' ? provider : library,
            isKaikas
          );
          if (result.status === SUCCESS) {
            // const left = await getItemAmount(
            //   contract,
            //   index,
            //   collectionItemInfo?.collectionInfo?.isCollection === true ? 2 : 1,
            //   account,
            //   library
            // );

            const data = {
              mysterybox_id: itemInfo?.collectionInfo?.id,
              buyer: uid,
              buyer_address: myWallet,
              isSent: true,
              txHash: result?.txHash,
              price: itemInfo?.price,
              itemId: itemInfo?.id,
              tokenId: result?.tokenId,
            };

            const res = await registerBuy(data);
            if (res.data.status === SUCCESS) {
              // setOpenSnackbar({
              //   open: true,
              //   type: 'success',
              //   message: 'Success',
              // });
              console.log('success');
              return true;
            } else {
              console.log('registerBuy failure');
              return false;
            }
          } else {
            console.log(result?.error);
            alert(result?.error);
            return false;
          }
        } catch (e: any) {
          console.log(e);
          if (e.data?.code === 3) {
            setErrMsg('Can not mint over than hard cap limit');
          } else {
            setErrMsg('Not sufficient Klay balance!');
          }
          return false;
        }
      } else {
        // AirDop
        console.log('claim');
        console.log(itemInfo);
        const contract = itemInfo?.collectionInfo?.boxContractAddress;
        try {
          const result = await claimAirDrop(contract, myWallet, login === 'sns' ? provider : library, isKaikas);
          if (result.status === SUCCESS) {
            const data = {
              mysterybox_id: itemInfo?.collectionInfo?.id,
              buyer: uid,
              buyer_address: myWallet,
              isSent: true,
              txHash: result.txHash,
              price: 0,
              itemId: itemInfo.id,
            };

            const res = await registerBuy(data);
            if (res.data.status === SUCCESS) {
              console.log('success');
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } catch (error: any) {
          if (error.data.message === 'execution reverted: should buy all collections before')
            setErrMsg('Free claim condition is not fulfilled!');
          return false;
        }
      }
    } else {
      console.log('buy mbox');
      try {
        if (itemInfo) {
          // chainid 로 네트워크 확인(eth, klaytn) 후 해당 지갑 연결 체크
          const check = await checkConnectWallet(itemInfo.chainId, wallet, activate);
          if (!check) {
            // 지갑 연결 화면띄우고 종료
            // setIsLoading(false);
            // setLoginOpen(true);
            return false;
          }
          const amount = 1;
          const price = itemInfo.price ?? 0;
          const quote = itemInfo.quote;

          let quoteToken: string;
          let payment: BigNumber;
          if (quote === 'klay' || quote === 'wklay') {
            quoteToken = quote === 'klay' ? contracts.klay[target] : contracts.wklay[target];
            payment = parseEther(price?.toString() ?? '0').mul(amount);
          } else if (quote === 'usdt' || quote === 'usdc') {
            quoteToken = quote === 'usdt' ? contracts.usdt[target] : contracts.usdc[target];
            payment = parseUnits(price?.toString() ?? '0', 6).mul(amount);
          } else if (quote === 'matic') {
            quoteToken = contracts.matic[target];
            payment = parseEther(itemInfo?.price?.toString() ?? '0').mul(amount);
          }

          if (quote !== 'klay') {
            const rlt = await approveKIP7(
              quoteToken!,
              itemInfo.boxContractAddress,
              payment?.toString(),
              myWallet,
              login === 'sns' ? provider : library,
              isKaikas
            );
            if (rlt === FAILURE) return false;
          }

          const result = await buyKey(
            itemInfo.boxContractAddress,
            1,
            payment?.toString(),
            quoteToken!,
            myWallet,
            login === 'sns' ? provider : library,
            isKaikas
          );
          if (result.status === SUCCESS) {
            const left = await getKeyRemains(itemInfo.keyContractAddress, itemInfo.boxContractAddress, null, false);
            setRemains(left);

            const data = {
              mysterybox_id: itemInfo.id,
              buyer: uid,
              buyer_address: myWallet,
              isSent: true,
              txHash: result?.txHash,
              price: itemInfo.price,
            };
            const res = await registerBuy(data);
            if (res.data.status === SUCCESS) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } catch (error: any) {
        console.log(error);
        // if (error.code == '-32603') setErrMsg('Not sufficient Klay balance!');
        if (error.data?.code === 3) {
          setErrMsg('Can not mint over than hard cap limit');
        } else {
          setErrMsg('Not sufficient Klay balance!');
        }
        return false;
      }
    }
  };

  const handleClickIap = async () => {
    if (!user.eth_address) {
      setErrMsg('Please add wallet first. (My Profile > Wallet Address)');
      return false;
    }
    if (!isTalkenApp) {
      setErrMsg(`Please Use Talken app.`);
      return false;
    }
    if (!itemInfo) return false;
    if (!itemInfo.fiatPrice && !itemInfo?.collectionInfo?.fiatPrice) {
      setErrMsg(`Please buy other method.`);
      return false;
    }
    // // TODO: Collection IAP
    // if (isCollection) {
    //   setErrMsg(`Can't buy Collection iap yet.`);
    //   return false;
    // }
    let productId;
    if (isCollection) productId = `c${itemInfo.id}${itemInfo.collectionInfo?.featuredId?.toLowerCase()}`;
    else productId = `m${itemInfo.id}${itemInfo.featuredId.toLowerCase()}`;
    if (osType === 'IOS') window.webkit.messageHandlers.collectNFT.postMessage(productId);
    else {
      window.TalkenInterface.collectNFT(productId);
    }
    return false;
  };

  const downloadClick = (buttonType: string) => {
    if (buttonType === 'IOS') window.open('https://itunes.apple.com/kr/app/apple-store/id1459475831');
    else window.open('http://play.google.com/store/apps/details?id=io.talken.wallet');
  };

  useEffect(() => {
    // fiatPrice가 있을 경우 IAP
    if (itemInfo) setBuyItemInfo(itemInfo);
    if (isCollection) {
      setIsIap(
        itemInfo?.collectionInfo?.fiatPrice !== null &&
          itemInfo?.collectionInfo?.fiatPrice !== 0 &&
          itemInfo?.collectionInfo?.fiatPrice !== undefined
      );
    } else {
      setIsIap(itemInfo?.fiatPrice !== null && itemInfo?.fiatPrice !== 0 && itemInfo?.fiatPrice !== undefined);
    }
  }, [itemInfo, isCollection]);
  const { isDarkMode } = useThemeMode();

  const deniedView = (
    // IAP 이면서 AppView가 아닌 경우 스토어로 연결
    <div className="modal-dialog-payment" style={{ height: isMobile ? '' : '410px' }}>
      <div className="header">
        <div className="title">Buy on the Talken mobile app</div>
        {/* <button onClick={() => handleClickIap()}>test</button> */}
        <div className="close-button" onClick={onHide}>
          {isDarkMode ? <img src={close_icon} alt="icon close" /> : <img src={close_icon_black} alt="icon close" />}
        </div>
      </div>
      <div className="line"></div>
      <div className="sub-title">Install the Talken app now from the App Store or Google Play</div>
      <div className={isTalkenApp ? 'grid-payments-one' : 'grid-payments'}>
        <>
          <div
            className={`payment-box ${selectedPayment === 3 ? 'active' : ''}`}
            onClick={() => {
              downloadClick('IOS');
            }}
          >
            <div className="pay-item">
              <img src={pay_appstore} alt="App Store" />
            </div>
            <div className="pay-name">App Store</div>
          </div>
          <div
            className={`payment-box ${selectedPayment === 4 ? 'active' : ''}`}
            onClick={() => {
              downloadClick('AOS');
            }}
          >
            <div className="pay-item">
              <img src={pay_googleplay} alt="Google Play" />
            </div>
            <div className="pay-name">Google Play</div>
          </div>
        </>
      </div>
    </div>
  );

  const paymentView = (
    // AppView or not IAP
    <div className="modal-dialog-payment" style={{ height: isMobile ? '' : 'fit-content' }}>
      <div className="header">
        <div className="title">How would you like to pay</div>
        <div className="close-button" onClick={onHide}>
          {isDarkMode ? <img src={close_icon} alt="icon close" /> : <img src={close_icon_black} alt="icon close" />}
        </div>
      </div>
      <div className="line"></div>
      <div className="sub-title">Please choose one of the payment methods below.</div>
      <div className={isTalkenApp ? 'grid-payments-one' : 'grid-payments'}>
        <>
          {osType === 'IOS' ? (
            <div
              className={`payment-box ${selectedPayment === 3 ? 'active' : ''}`}
              onClick={() => {
                setSelectedPayment(3);
                setIsDisabled(false);
              }}
            >
              <div className="pay-item">
                <img src={pay_appstore} alt="App Store" />
              </div>
              <div className="pay-name">App Store</div>
            </div>
          ) : osType === 'AOS' ? (
            <div
              className={`payment-box ${selectedPayment === 4 ? 'active' : ''}`}
              onClick={() => {
                setSelectedPayment(4);
                setIsDisabled(false);
              }}
            >
              <div className="pay-item">
                <img src={pay_googleplay} alt="Google Play" />
              </div>
              <div className="pay-name">Google Play</div>
            </div>
          ) : (
            <div
              className={`payment-box disabled ${selectedPayment === 1 ? 'active' : ''}`}
              // onClick={() => {
              //   setSelectedPayment(1);
              //   setIsDisabled(false);
              // }}
            >
              <div className="pay-item pay-talken">
                <img
                  // src={pay_creditcard}
                  // src={pay_creditcard_gray}
                  src={pay_talken_credit}
                  alt="Credit Card"
                  style={{ filter: 'contrast(100%)' }}
                />
              </div>
              <div className="pay-name">Talken Credit</div>
            </div>
          )}
        </>
        {!isTalkenApp && (
          <div
            className={`payment-box ${selectedPayment === 2 ? 'active' : ''}`}
            onClick={() => {
              setSelectedPayment(2);
              setIsDisabled(false);
              // handleClickCrypto();
            }}
          >
            <div className="pay-item">
              <img src={pay_crypto} alt="Crypto" />
            </div>
            <div className="pay-name">Crypto</div>
          </div>
        )}
      </div>
      <div className="custom-submit">
        <button
          disabled={isDisabled || isBuying}
          type="submit"
          className={`payments-btn-submit button fw-600  ${isDisabled || isBuying ? 'disable' : ''}`}
          // className="payments-btn-submit button fw-600 disable"
          onClick={handleClickBuy}
        >
          {isBuying ? (
            <CircularProgress size={30} color={'inherit'} />
          ) : buyItemInfo?.price === 0 ? (
            'Get Now'
          ) : (
            'Buy Now'
          )}
        </button>
        {/* <div className="payment-error">
          <Typography>{errMsg}</Typography>
        </div> */}
      </div>
    </div>
  );
  return (
    <ReactModal
      preventScroll={true}
      isOpen={show}
      contentLabel="onRequestClose Example"
      onRequestClose={onHide}
      className="Modal"
      overlayClassName="payments-wallets-overlay"
      shouldCloseOnOverlayClick={!isBuying}
    >
      {isIap && !isTalkenApp ? deniedView : paymentView}
    </ReactModal>
  );
};

export default PaymentWallets;
