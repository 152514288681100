/*
 * seaport-js
 */

import { BigNumber, utils } from 'ethers';
import { Seaport } from '@colligence/seaport-js';
import { CreateOrderInput, Order, OrderComponents, OrderWithCounter, Signer } from '@colligence/seaport-js/lib/types';
import { JsonRpcProvider } from '@ethersproject/providers';
// import * as process from 'process';

export interface OrderData {
  signer: JsonRpcProvider | Signer;
  tokenContractAddress: string;
  tokenId: string;
  itemType: number;
  price: string;
  quoteContractAddress: string;
  endTime: string;
  creatorAddress?: string;
  quote?: string;
}

const {
  REACT_APP_CONDUIT_KEYS_TO_CONDUIT,
  REACT_APP_CROSS_CHAIN_DEFAULT_CONDUIT_KEY,
  REACT_APP_TRADING_FEE,
  REACT_APP_CREATOR_FEE,
  REACT_APP_TREASURY,
  REACT_APP_KLAYTN_FEE_PAYER_ADDRESS,
} = process.env;

export const seaport = {
  getSeaport_v1_4: (signer: JsonRpcProvider | Signer) => {
    return new Seaport(signer, {
      // @ts-ignore
      conduitKeyToConduit: REACT_APP_CONDUIT_KEYS_TO_CONDUIT,
      overrides: { defaultConduitKey: REACT_APP_CROSS_CHAIN_DEFAULT_CONDUIT_KEY },
      seaportVersion: '1.4',
    });
  },
  createOrder: async (data: OrderData) => {
    const {
      signer,
      tokenContractAddress,
      tokenId,
      itemType,
      price,
      quoteContractAddress,
      endTime,
      creatorAddress,
      quote,
    } = data;

    const priceBN = utils.parseUnits(price, quote.toUpperCase() === 'USDC' || quote.toUpperCase() === 'USDT' ? 6 : 18); // USDT decimal 6
    const tradingFee = priceBN.mul(REACT_APP_TRADING_FEE).div(1000).toString();
    // const creatorShare = priceBN.mul(REACT_APP_CREATOR_FEE).div(1000).toString();
    const profit = priceBN.sub(tradingFee).toString();
    const token = quoteContractAddress;
    // console.log('prices:::', priceBN.toString(), tradingFee, creatorShare, profit);
    const consideration = [];
    consideration.push(
      quoteContractAddress
        ? { token, amount: profit, endAmount: profit, recipient: undefined }
        : { amount: profit, endAmount: profit, recipient: undefined }
    );
    // consideration.push({ token, amount: profit, endAmount: profit, recipient: undefined });
    consideration.push({ token, amount: tradingFee, endAmount: tradingFee, recipient: REACT_APP_TREASURY });
    // if (creatorAddress)
    //   consideration.push({ token, amount: creatorShare, endAmount: creatorShare, recipient: creatorAddress });

    const input = {
      offer: [{ itemType, token: tokenContractAddress, identifier: tokenId, amount: '1' }],
      consideration,
      endTime,
      // zone: REACT_APP_KLAYTN_FEE_PAYER_ADDRESS,
      // restrictedByZone: true,
    };

    console.log('!! createOrder : ', input);
    const order = await seaport.seaportCreateOrder(signer, input);

    return order;
  },
  cancelOrders: async (signer: JsonRpcProvider | Signer, orders: OrderComponents[]) => {
    let receipt;
    try {
      const seaport_v1_4 = seaport.getSeaport_v1_4(signer);
      const tx = await seaport_v1_4.cancelOrders(orders).transact();
      receipt = await tx.wait();
      console.log('!! cancelOrders : ', receipt);
    } catch (err: any) {
      console.log('!! cancelOrders failed :::: ', err.message);
      // if (err?.message && err?.message.includes('Incorrect twofactor verify token')) alert(err.message);
      if (err?.message && err?.message.includes('Incorrect twofactor verify token'))
        return 'Incorrect twofactor verify token';
      return err.message;
    }
    return receipt;
  },
  validate: async (signer: JsonRpcProvider | Signer, orders: Order[]) => {
    let receipt;
    try {
      const seaport_v1_4 = seaport.getSeaport_v1_4(signer);
      const tx = await seaport_v1_4.validate(orders).transact();
      receipt = await tx.wait();
      console.log('!! validate : ', receipt);
    } catch (err: any) {
      console.log('!! validate : ', err.message);
      if (err?.message && err?.message.includes('Incorrect twofactor verify token')) alert(err.message);
    }
    return receipt;
  },
  bidOffer: async (data: OrderData) => {
    const { signer, tokenContractAddress, tokenId, price, quoteContractAddress, endTime, quote } = data;

    const priceBN = utils.parseUnits(price, quote.toUpperCase() === 'USDC' || quote.toUpperCase() === 'USDT' ? 6 : 18); // USDT decimal 6
    const tradingFee = priceBN.mul(REACT_APP_TRADING_FEE).div(1000).toString();
    const consideration = [];
    consideration.push({
      token: quoteContractAddress,
      amount: tradingFee,
      endAmount: tradingFee,
      recipient: REACT_APP_TREASURY,
    });
    consideration.push({ itemType: 2, token: tokenContractAddress, identifier: tokenId, amount: '1' });
    const input = {
      offer: [
        quoteContractAddress
          ? { token: quoteContractAddress, amount: priceBN.toString() }
          : { amount: priceBN.toString() },
      ],
      consideration,
      // endTime,
      // zone: REACT_APP_KLAYTN_FEE_PAYER_ADDRESS,
      // restrictedByZone: true,
    };

    console.log('------ bidOffer ----->', input);
    const order = await seaport.seaportCreateOrder(signer, input);
    console.log('!! bidOffer : ', order);

    return order;
  },
  seaportCreateOrder: async (signer: JsonRpcProvider | Signer, input: CreateOrderInput) => {
    let order;
    try {
      console.log('!! seaportCreateOrderInput : ', input);
      const seaport_v1_4 = seaport.getSeaport_v1_4(signer);
      const { executeAllActions } = await seaport_v1_4.createOrder(input);
      order = await executeAllActions();
      console.log('!! seaportCreateOrder : ', order);
    } catch (err: any) {
      console.log('!! seaportCreateOrder error:::', err.message);
      if (err?.message && err?.message.includes('Incorrect twofactor verify token')) alert(err.message);
      return err.message;
    }
    return order;
  },
  seaportFulfillOrder: async (signer: JsonRpcProvider | Signer, order: OrderWithCounter | undefined) => {
    let receipt;
    try {
      const seaport_v1_4 = seaport.getSeaport_v1_4(signer);
      const { executeAllActions } = await seaport_v1_4.fulfillOrder({ order });
      const transaction = await executeAllActions();
      receipt = await transaction.wait();
      console.log('!! seaportFulfillOrder receipt : ', receipt);
    } catch (err: any) {
      console.log('!! seaportFulfillOrder error:::', err.message);
      // if (err?.message && err?.message.includes('Incorrect twofactor verify token')) alert(err.message);
      if (err?.message && err?.message.includes('Incorrect twofactor verify token'))
        return 'Incorrect twofactor verify token';
      if (err.message === 'Incorrect twofactor verify token') return err.message;
      return err.message;
    }
    return receipt;
  },
};
