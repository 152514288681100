import React, { useState } from 'react';
import ReactModal from 'react-modal';
import icon_wallet_act from '../../assets/img/icon_wallet_act.svg';
import checked_all_connect from '../../assets/icon/checked_all_connect.png';
import check_all_connect from '../../assets/icon/check_all_connect.png';
import check_all_connect_light from '../../assets/icon/check_all_connect_light.png';
import checked_connect from '../../assets/icon/checked_connect.png';
import check_connect from '../../assets/icon/check_connect.png';
import check_connect_light from '../../assets/icon/check_connect_light.png';
import { useThemeMode } from '../common/AppStoreType';
import { useOpenModal } from 'components/common/AppStore';
import HelperUtil from '../../utils/HelperUtil';

const LicenseAgreementABC = () => {
  const [loading, setLoading] = useState(false);
  const [isCheckABC, setIsCheckABC] = useState({
    check1: false,
    check2: false,
    check3: false,
    check4: false,
    check5: false,
  });
  const isCheckAllABC =
    isCheckABC.check1 && isCheckABC.check2 && isCheckABC.check3 && isCheckABC.check4 && isCheckABC.check5;
  const handleSignUp = async () => {
    setLoading(true);
    await HelperUtil.abcRecover(isCheckABC);
    setLoading(false);
    closeModal();
  };
  const { isDarkMode } = useThemeMode();
  const handleCheckABC = (check: 'check1' | 'check2' | 'check3' | 'check4' | 'check5') => {
    const newCheck = { ...isCheckABC, [check]: !isCheckABC[check] };
    setIsCheckABC(newCheck);
  };
  const handleCheckAllABC = () => {
    setIsCheckABC({
      check1: !isCheckAllABC,
      check2: !isCheckAllABC,
      check3: !isCheckAllABC,
      check4: !isCheckAllABC,
      check5: !isCheckAllABC,
    });
  };

  const { isOpen, closeModal } = useOpenModal();
  return (
    <ReactModal isOpen={isOpen} overlayClassName="detail-nft-overlay" className="Modal" ariaHideApp={false}>
      <div className="model-wallet-atc">
        <div className="otp-code-modal">
          <div className="logo-wallet-act">
            <img className="pe-2" src={icon_wallet_act} alt="icon_wallet_act" />
          </div>
          <div className="title-wallet-act">Create ABC Wallet</div>
          <div className="agree-background">
            <div className="agree-all">
              <div className="icon-check-all" onClick={handleCheckAllABC}>
                {isCheckAllABC ? (
                  <img src={checked_all_connect} alt="Check All" />
                ) : isDarkMode ? (
                  <img src={check_all_connect} alt="Check All" />
                ) : (
                  <img src={check_all_connect_light} alt="Check All" />
                )}
              </div>
              <div className="content-agree">ABC Wallet의 모든 약관에 동의합니다.</div>
            </div>
            <div className="wrapper-agree">
              <div className="agree-item">
                <div className="icon-check-agree" onClick={() => handleCheckABC('check1')}>
                  {isCheckABC.check1 ? (
                    <img src={checked_connect} alt="Check Icon" />
                  ) : isDarkMode ? (
                    <img src={check_connect} alt="Check Icon" />
                  ) : (
                    <img src={check_connect_light} alt="Check Icon" />
                  )}
                </div>
                <div className="wrapper-agree-left">
                  <span>*</span>
                  <div>I am over 14 years old.</div>
                </div>
              </div>
              <div className="agree-item">
                <div className="icon-check-agree" onClick={() => handleCheckABC('check2')}>
                  {isCheckABC.check2 ? (
                    <img src={checked_connect} alt="Check Icon" />
                  ) : isDarkMode ? (
                    <img src={check_connect} alt="Check Icon" />
                  ) : (
                    <img src={check_connect_light} alt="Check Icon" />
                  )}
                </div>
                <div className="wrapper-agree-left">
                  <span>*</span>
                  <div>I agree to</div>
                  <a
                    href="https://api.id.myabcwallet.com/query/terms?language=2&service=4"
                    target={'_blank'}
                    className="text-terms a"
                  >
                    the Terms of Use.
                  </a>
                </div>
              </div>
              <div className="agree-item">
                <div className="icon-check-agree" onClick={() => handleCheckABC('check3')}>
                  {isCheckABC.check3 ? (
                    <img src={checked_connect} alt="Check Icon" />
                  ) : isDarkMode ? (
                    <img src={check_connect} alt="Check Icon" />
                  ) : (
                    <img src={check_connect_light} alt="Check Icon" />
                  )}
                </div>
                <div className="wrapper-agree-left">
                  <span>*</span>
                  <div>I agree to</div>
                  <a
                    href="https://api.id.myabcwallet.com/query/privacy?language=2&service=4"
                    target={'_blank'}
                    className="text-terms a"
                  >
                    the Privacy Policy.
                  </a>
                </div>
              </div>
              <div className="agree-item">
                <div className="icon-check-agree" onClick={() => handleCheckABC('check4')}>
                  {isCheckABC.check4 ? (
                    <img src={checked_connect} alt="Check Icon" />
                  ) : isDarkMode ? (
                    <img src={check_connect} alt="Check Icon" />
                  ) : (
                    <img src={check_connect_light} alt="Check Icon" />
                  )}
                </div>
                <div className="wrapper-agree-left">
                  <span>*</span>
                  <div>I agree to provide</div>
                  <a
                    href="https://api.id.myabcwallet.com/query/third-party?language=2&service=4"
                    target={'_blank'}
                    className="text-terms a"
                  >
                    personal information to third-party.
                  </a>
                </div>
              </div>
              <div className="agree-item">
                <div className="icon-check-agree" onClick={() => handleCheckABC('check5')}>
                  {isCheckABC.check5 ? (
                    <img src={checked_connect} alt="Check Icon" />
                  ) : isDarkMode ? (
                    <img src={check_connect} alt="Check Icon" />
                  ) : (
                    <img src={check_connect_light} alt="Check Icon" />
                  )}
                </div>
                <div className="wrapper-agree-left">
                  <div>I agree to receive</div>
                  <a
                    href="https://api.id.myabcwallet.com/query/marketing?language=2&service=4"
                    target={'_blank'}
                    className="text-terms a"
                  >
                    marketing information.
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="body-otp">
            <button className="button m-0 btn-detail-nft" onClick={handleSignUp} disabled={loading}>
              {loading ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              ) : (
                'Sign Up'
              )}
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default LicenseAgreementABC;
