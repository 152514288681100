import React, { useEffect, useState } from 'react';
import search_icon_dark from '../../assets/svg/search_icon_dark.svg';
import search_icon_light from '../../assets/svg/search_icon_light.svg';
import search_icon_dark_2 from '../../assets/svg/search_icon_dark_2.svg';
import search_icon_light_2 from '../../assets/svg/search_icon_light_2.svg';
import { useThemeMode } from 'components/common/AppStoreType';
import { ReactComponent as MenuSelect } from '../../assets/icon/menuselect.svg';
import { ReactComponent as ArrowUpDown } from '../../assets/svg/arrow_up_down.svg';
import { MBoxTypes } from '../../types/MBoxTypes';
import { isMobile } from 'react-device-detect';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';

interface SearchBarProps {
  onSearch: (search: string) => void;
  onSelect: (search: string) => void;
  onSelectCategory: (value: string) => void;
  onSelectCategoryAll: () => void;
  selectList: SelectListProps[];
  categoryList: CategoryListProps[];
  showFirstTwoSelects?: boolean;
  totalItems?: number;
  setSelectType?: any;
  setSelectChains?: any;
  selectChains?: any;
  selectType?: any;
  titlePage?: any;
}
interface SelectListProps {
  value: string;
  label: string;
}

interface CategoryListProps {
  value: string;
  label: string;
}
type ExMBoxTypes = MBoxTypes & {
  companyimage: string;
  companyname: { ko: string; en: string };
};
const optionsChains = [
  { value: 'Chains', label: 'Chains' },
  { value: 'Ethereum', label: 'Ethereum' },
  { value: 'Polygon', label: 'Polygon' },
  { value: 'Klaytn', label: 'Klaytn' },
  { value: 'Binance', label: 'Binance' },
];
const optionsType = [
  { value: 'Type', label: 'Type' },
  { value: 'Fixed', label: 'Fixed' },
  { value: 'Auction', label: 'Auction' },
];
const SearchBar: React.FC<SearchBarProps> = ({
  onSearch,
  onSelect,
  onSelectCategory,
  onSelectCategoryAll,
  selectList,
  categoryList,
  showFirstTwoSelects,
  totalItems,
  setSelectType,
  setSelectChains,
  selectChains,
  selectType,
  titlePage,
}) => {
  const { isDarkMode } = useThemeMode();
  const [inputValue, setInputValue] = useState('');
  const [isCategorySelect, setIsCategorySelect] = useState(0);
  const [isExpanded, setExpanded] = useState(false);
  const [isClickAllChains, setIsClickAllChains] = useState(true);
  const [isClickAllType, setIsClickAllType] = useState(true);
  const [isTotalFilter, setIsTotalFilter] = useState(0);
  const [isShowSelectMB, setIsShowSelectMB] = useState<boolean>(false);
  // const [count, setCount] = useState(0);
  const handleSearch = (search: string) => {
    onSearch(search);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch(inputValue);
    }
  };
  const handleExpand = () => {
    if (!isExpanded) setIsShowSelectMB(false);
    setExpanded(!isExpanded);
  };
  const handleSort = () => {
    if (!isShowSelectMB) setExpanded(false);
    setIsShowSelectMB(!isShowSelectMB);
  };

  const [isMultiple, setIsMultiple] = useState(false);

  const styleSelectRecently = {
    control: (base, state) => ({
      ...base,
      background: isDarkMode ? '#02021e' : '#ffffff',
      borderRadius: isMobile ? '6px' : '6px',
      borderColor: isDarkMode ? '#525298' : '#D9D9D9',
      boxShadow: state.isFocused ? 'none' : 'none',
      fontSize: isMobile ? '12px' : '16px',
      fontWeight: 400,
      width: isMobile ? '100%' : '200px',
      height: '44px',
      minHeight: isMobile ? '32px' : '38px',
      cursor: 'pointer',
    }),
    singleValue: (base) => ({
      ...base,
      color: isDarkMode ? '#525298' : '#000000',
    }),
    valueContainer: (base) => ({
      ...base,
      height: '100%',
      placeContent: isMobile ? 'left' : 'center',
      marginLeft: isMobile ? '6px' : '2px',
    }),
    option: (base, state) => ({
      ...base,
      fontSize: isMobile ? '12px' : '16px',
      fontWeight: 400,
      width: isMobile ? '100%' : '200px',
      color: state.isSelected ? '#2f8af5' : isDarkMode ? '#525298' : '#000000',
      backgroundColor: isDarkMode ? '#202052' : '#ffffff',
      ':active': {
        backgroundColor: state.isSelected ? '#23235c' : '#23235c',
        color: '#2f8af5',
      },
      ':hover': {
        // backgroundColor: '#23235c',
        color: '#2f8af5',
      },
      cursor: 'pointer',
    }),

    menu: (base) => ({
      ...base,
      fontSize: isMobile ? '12px' : '16px',
      background: isDarkMode ? '#202052' : '',
      borderColor: isDarkMode ? '#525298' : '',
      // override border radius to match the box
      borderRadius: isMobile ? '6px' : '6px',
      // kill the gap
      marginTop: 0,
      width: isMobile ? '100%' : '200px',
    }),
    menuList: (base) => ({
      ...base,
      fontSize: '1px',
      // kill the white space on last option
      padding: '0',
      width: isMobile ? '100%' : '200px',
      borderRadius: isMobile ? '6px' : '6px',
      borderColor: isDarkMode ? '#525298' : '',
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: isDarkMode ? '#525298' : '#000000',
      placeContent: isMobile ? 'left' : 'center',
      padding: isMobile ? '4px' : '8px',
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
  };
  useEffect(() => {
    if (!selectChains?.ethereum && !selectChains?.polygon && !selectChains?.klaytn && !selectChains?.binance) {
      setIsClickAllChains(true);
    }
    if (!selectType?.fixed && !selectType?.auction) {
      setIsClickAllType(true);
    }
    const setClickFixed = selectType?.fixed ? 1 : 0;
    const setClickAuction = selectType?.auction ? 1 : 0;
    const setClickEthereum = selectChains?.ethereum ? 1 : 0;
    const setClickPolygon = selectChains?.polygon ? 1 : 0;
    const setClickKlaytn = selectChains?.klaytn ? 1 : 0;
    const setClickBNB = selectChains?.binance ? 1 : 0;
    setIsTotalFilter(
      setClickFixed + setClickAuction + setClickEthereum + setClickPolygon + setClickKlaytn + setClickBNB
    );
  }, [selectChains, selectType]);

  const handleClickedChains = (chain: 'ethereum' | 'polygon' | 'klaytn' | 'binance') => {
    const newSelectChains = { ...selectChains, [chain]: !selectChains[chain] };
    setSelectChains(newSelectChains);
    setIsClickAllChains(false);
  };
  const clickButtonAllChains = () => {
    setIsClickAllChains(true);
    setSelectChains({
      klaytn: false,
      ethereum: false,
      polygon: false,
      binance: false,
    });
  };
  const handleClickedType = (type: 'fixed' | 'auction') => {
    const newSelectType = { ...selectType, [type]: !selectType[type] };
    setSelectType(newSelectType);
    setIsClickAllType(false);
  };
  const clickButtonAllType = () => {
    setIsClickAllType(true);
    setSelectType({
      fixed: false,
      auction: false,
    });
  };
  return (
    <div
      className={`search-bar ${
        titlePage == 'Creators'
          ? isExpanded
            ? ''
            : 'p-b-20 p-b-xs-0'
          : isExpanded
          ? 'm-b-xs-16 p-b-6'
          : 'p-b-22 m-b-xs-16'
      }`}
    >
      <div className="search hidden-pc h-xs-40px">
        <input
          className="search-input"
          placeholder="Search"
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
        ></input>
        <div className="search-icon">
          {isDarkMode ? (
            <img src={search_icon_dark_2} alt="search icon dark" />
          ) : (
            <img src={search_icon_light_2} alt="search icon" />
          )}
        </div>
      </div>
      <div className="">
        <div className="align-items-center d-flex justify-content-between">
          <div className="p-l-10 p-l-xs-0">
            <div className="category ps-0">
              <div className="fs-32 fw-7 fs-xs-26 fw-xs-9 lh-38 lh-xs-30">{titlePage}</div>
              <div className="w-1px"></div>
              <div
                key={0}
                className={` ${isCategorySelect == 0 ? 'category-item category-item--select' : 'category-item'}`}
                onClick={() => {
                  onSelectCategoryAll();
                  setIsCategorySelect(0);
                }}
              >
                All
              </div>
              <div className="total-items">{totalItems}</div>
              {showFirstTwoSelects && (
                <div
                  className={`button  pointer-event rounded-3 d-flex justify-content-between align-items-center  gap-10px dark:svg-path-updown svg-path-black h-30px hidden-mobile hidden-tablet ms-2 p-l-6 p-r-6 hover-opa-7 ${
                    isExpanded
                      ? 'dark:svg-path-updown-select updown-border-select'
                      : 'dark:svg-path-updown updown-border '
                  } ${isTotalFilter > 0 ? ' w-auto' : ' w-30px'}`}
                  onClick={() => handleExpand()}
                >
                  <MenuSelect width={16} height={14} />
                  {isTotalFilter > 0 && <div className="fw-400 fs-20 color-2f8af5">{isTotalFilter}</div>}
                </div>
              )}
              {/* {categoryList.map((item, index) => (
                <div
                  key={index + 1}
                  className={` ${
                    isCategorySelect == index + 1 ? 'category-item category-item--select' : 'category-item'
                  }`}
                  onClick={() => {
                    onSelectCategory(item.value);
                    setIsCategorySelect(index + 1);
                  }}
                >
                  {item.label}
                </div>
              ))} */}
            </div>
          </div>
          <div className="align-items-center d-flex gap-10px h-44px h-xs-31px justify-content-between">
            <div className="d-flex justify-content-end pe-0 hidden-mobile hidden-tablet">
              <Select
                isSearchable={false}
                options={selectList}
                defaultValue={selectList[0]}
                styles={styleSelectRecently}
                components={{
                  IndicatorSeparator: () => null,
                }}
                onChange={(selectedOption) => onSelect(selectedOption?.value)}
              />
            </div>
            <div className="search hidden-mobile hidden-tablet w-280px">
              <input
                className="search-input"
                placeholder="Search"
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
              ></input>
              <div className="search-icon">
                {isDarkMode ? (
                  <img src={search_icon_dark} alt="search icon dark" />
                ) : (
                  <img src={search_icon_light} alt="search icon" />
                )}
              </div>
            </div>
            <div
              className={`${
                isShowSelectMB
                  ? 'dark:stroke-updown-select stroke-black updown-border-select'
                  : 'dark:stroke-updown stroke-black updown-border'
              } align-items-center button d-flex gap-10px h-100 hidden-pc justify-content-between max-h-30px max-w-30px pe-2 pointer-event ps-2 rounded-3 w-auto`}
              onClick={() => handleSort()}
            >
              <ArrowUpDown width={21} height={16} />
            </div>
            {showFirstTwoSelects && (
              <div
                className={`button  pointer-event rounded-3 d-flex justify-content-between align-items-center w-auto gap-10px dark:svg-path-updown svg-path-black h-100 hidden-pc ${
                  isExpanded
                    ? 'dark:svg-path-updown-select updown-border-select'
                    : 'dark:svg-path-updown updown-border '
                } ${isMobile ? ' ps-2 pe-2 max-h-30px' : ' ms-2 p-l-10 p-r-10 hover-opa-7'}${
                  isTotalFilter > 0 ? ' ' : isMobile ? ' max-w-30px' : ' '
                }`}
                onClick={() => handleExpand()}
              >
                <MenuSelect width={21} height={16} />
                {isTotalFilter > 0 && <div className="fw-400 fs-12 color-2f8af5">{isTotalFilter}</div>}
              </div>
            )}
          </div>
        </div>
        {isShowSelectMB && (
          <div className="p-l-10 p-l-xs-0 p-t-11 pe-0 hidden-pc">
            <Select
              isSearchable={false}
              options={selectList}
              defaultValue={selectList[0]}
              styles={styleSelectRecently}
              components={{
                IndicatorSeparator: () => null,
              }}
              onChange={(selectedOption) => onSelect(selectedOption?.value)}
            />
          </div>
        )}
        {isExpanded && (
          <div
            className={`${
              isMobile ? ' d-grid' : 'd-flex'
            } w-100 fs-16 fs-xs-12 p-t-20 p-l-10 fw-7 gap-32px gap-xs-4px p-l-xs-0`}
          >
            <div className="d-flex gap-16px lh-16">
              <div className={isMobile ? 'w-12 min-w-50px' : ''}>Chains</div>
              <div className="d-flex flex-wrap dark:text-525298 gap-16px gap-0-16 text-black fw-400">
                <Form.Check
                  checked={isClickAllChains}
                  label="All"
                  id="all-chains"
                  onChange={clickButtonAllChains}
                  className={isClickAllChains ? 'text-2f8af5' : ''}
                />
                <Form.Check
                  checked={selectChains['ethereum']}
                  label="Ethereum"
                  id="ethereum"
                  onChange={() => handleClickedChains('ethereum')}
                  className={selectChains['ethereum'] ? 'text-2f8af5' : ''}
                />
                <Form.Check
                  checked={selectChains['binance']}
                  label="BNB Chain"
                  id="binance"
                  onChange={() => handleClickedChains('binance')}
                  className={selectChains['binance'] ? 'text-2f8af5' : ''}
                />
                <Form.Check
                  checked={selectChains['polygon']}
                  label="Polygon"
                  id="polygon"
                  onChange={() => handleClickedChains('polygon')}
                  className={selectChains['polygon'] ? 'text-2f8af5' : ''}
                />
                <Form.Check
                  checked={selectChains['klaytn']}
                  label="Klaytn"
                  id="klaytn"
                  onChange={() => handleClickedChains('klaytn')}
                  className={selectChains['klaytn'] ? 'text-2f8af5' : ''}
                />
              </div>
            </div>
            <div className="d-flex gap-16px lh-16">
              <div className={isMobile ? 'w-12 min-w-50px' : ''}>Type</div>
              <div className="d-flex dark:text-525298 gap-16px text-black fw-400">
                <Form.Check
                  checked={isClickAllType}
                  label="All"
                  id="all-types"
                  onChange={clickButtonAllType}
                  className={isClickAllType ? 'text-2f8af5' : ''}
                />
                <Form.Check
                  checked={selectType['fixed']}
                  label="Fixed"
                  id="fixed"
                  onChange={() => handleClickedType('fixed')}
                  className={selectType['fixed'] ? 'text-2f8af5' : ''}
                />
                <Form.Check
                  checked={selectType['auction']}
                  label="Auction"
                  id="auction"
                  onChange={() => handleClickedType('auction')}
                  className={selectType['auction'] ? 'text-2f8af5' : ''}
                />
              </div>
            </div>
          </div>
          // <div className={`p-l-10 ${isMobile ? '' : 'col-6'}`}>
          //   <div className="mt-3 fs-xs-12 d-flex gap-4 gap-xs-16px m-b-10">
          //     <div className={`d-flex justify-content-between gap-14px gap-xs-12px ${isMobile ? 'col-3' : 'col-2'}`}>
          //       <span className={`pt-2`}>Chains</span>
          //       <div
          //         className={`button gray-border p-l-10 p-r-10 py-2 pointer-event rounded-2 text-center ${
          //           isMobile ? 'h-xs-36px' : 'hover-opa-7'
          //         } ${isClickAllChains ? 'bg-clicked-item-filter' : ''}`}
          //         onClick={clickButtonAllChains}
          //       >
          //         All
          //       </div>
          //     </div>
          //     <div className="d-flex gap-20px gap-xs-12px flex-wrap">
          //       <div
          //         className={`button gray-border p-l-10 p-r-10 py-2 pointer-event rounded-2 text-center ${
          //           isMobile ? '' : 'hover-opa-7'
          //         } ${selectChains.ethereum ? 'bg-clicked-item-filter' : ''}`}
          //         onClick={() => handleClickedChains('ethereum')}
          //       >
          //         Ethereum
          //       </div>
          //       <div
          //         className={`button gray-border p-l-10 p-r-10 py-2 pointer-event rounded-2 text-center ${
          //           isMobile ? '' : 'hover-opa-7'
          //         } ${selectChains.polygon ? 'bg-clicked-item-filter' : ''}`}
          //         onClick={() => handleClickedChains('polygon')}
          //       >
          //         Polygon
          //       </div>
          //       <div
          //         className={`button gray-border p-l-10 p-r-10 py-2 pointer-event rounded-2 text-center ${
          //           isMobile ? '' : 'hover-opa-7'
          //         } ${selectChains.klaytn ? 'bg-clicked-item-filter' : ''}`}
          //         onClick={() => handleClickedChains('klaytn')}
          //       >
          //         Klaytn
          //       </div>
          //     </div>
          //   </div>
          //   <div className="mt-3 fs-xs-12 d-flex gap-4 gap-xs-16px m-b-10">
          //     <div
          //       className={`d-flex justify-content-between gap-14px gap-xs-23px ${
          //         isMobile ? 'col-3 h-xs-36px' : 'col-2'
          //       }`}
          //     >
          //       <span className={`pt-2`}>Type</span>
          //       <div
          //         className={`button gray-border p-l-10 p-r-10 py-2 pointer-event rounded-2 text-center ${
          //           isMobile ? '' : 'hover-opa-7'
          //         } ${isClickAllType ? 'bg-clicked-item-filter' : ''}`}
          //         onClick={clickButtonAllType}
          //       >
          //         All
          //       </div>
          //     </div>
          //     <div className="d-flex gap-20px gap-xs-12px flex-wrap">
          //       <div
          //         className={`button gray-border p-l-10 p-r-10 py-2 pointer-event rounded-2 text-center ${
          //           isMobile ? '' : 'hover-opa-7'
          //         } ${selectType.fixed ? 'bg-clicked-item-filter' : ''}`}
          //         onClick={() => handleClickedType('fixed')}
          //       >
          //         Fixed price
          //       </div>
          //       <div
          //         className={`button gray-border p-l-10 p-r-10 py-2 pointer-event rounded-2 text-center ${
          //           isMobile ? '' : 'hover-opa-7'
          //         } ${selectType.auction ? 'bg-clicked-item-filter' : ''}`}
          //         onClick={() => handleClickedType('auction')}
          //       >
          //         Auction
          //       </div>
          //     </div>
          //   </div>
          // </div>
        )}
      </div>
    </div>
  );
};
export default SearchBar;
