export const getPrice = (price: number, quote: string) => {
  // 가격이 제공되었는지 그리고 참 값인지 확인합니다.
  if (price) {
    // 가격을 문자열로 변환합니다.
    const priceString = price.toString();
    // 소수점 위치를 찾습니다.
    const decimalIndex = priceString.indexOf('.');
    // 만약 소수점이 없다면 그냥 정수 부분에 천 단위로 쉼표를 추가합니다.
    if (decimalIndex === -1) {
      return `${priceString.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${quote === 'ETHEREUM' ? 'ETH' : quote}`;
    } else {
      // 정수 부분과 소수 부분을 분리합니다.
      const integerPart = priceString.slice(0, decimalIndex);
      const decimalPart = priceString.slice(decimalIndex);
      // 정수 부분에 천 단위로 쉼표를 추가하고 소수 부분과 화폐 단위를 붙여 반환합니다.
      return `${integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${decimalPart} ${
        quote === 'ETHEREUM' ? 'ETH' : quote
      }`;
    }
  } else {
    // 가격이 제공되지 않았다면 '무료'를 반환합니다.
    return 'FREE';
  }
};
