import React, { useState } from 'react';
import LoginForm from 'components/auth/loginForm';
import SignupForm from 'components/auth/signupForm';
import Popup from 'reactjs-popup';
// import icon_insta from '../../assets/icon/instagram.png';
// import icon_twitter from '../../assets/icon/twitter.png';
// import icon_discord from '../../assets/icon/discord.png';
import icon_insta from '../../assets/img/icon_instagram.png';
import icon_insta_black from '../../assets/img/icon_instagram_black.png';
import icon_twitter from '../../assets/img/icon_twitter.png';
import icon_twitter_black from '../../assets/img/icon_twitter_black.png';
import icon_discord from '../../assets/img/icon_discord.png';
import icon_discord_black from '../../assets/img/icon_discord_black.png';
import docs from '../../assets/icon/docs.png';
import docs_black from '../../assets/icon/docs_black.png';
import close_icon from '../../assets/icon/close_icon.svg';
import close_icon_black from '../../assets/icon/close_icon_black.svg';
import about from '../../assets/icon/about_02.svg';
import about_black from '../../assets/icon/about_02_black.svg';
import blog from '../../assets/icon/blog_02.svg';
import blog_black from '../../assets/icon/blog_02_black.svg';
import help from '../../assets/icon/help_02.svg';
import help_black from '../../assets/icon/help_02_black.svg';
import wallet_blue from '../../assets/icon/wallet_blue.svg';
import UsernameBox from 'components/common/UsernameBox';
import { useModalWalletsStore, useSidebarStore } from 'components/common/AppStore';
import WalletConnector from '../auth/WalletConnector/WalletConnector';
import { useThemeMode } from 'components/common/AppStoreType';
import { getUA } from 'react-device-detect';
const appInitialState = {
  osType: '',
  appName: '',
  appVersion: '',
};
const SidebarMb = () => {
  const [open, setOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [signupOpen, setSignupOpen] = useState(false);
  const { updateOpenWallet } = useModalWalletsStore();
  const closeLogin = () => {
    setLoginOpen(false);
  };
  const accessToken = localStorage.getItem('nftapiJwtToken');

  const closeSignup = () => {
    setSignupOpen(false);
  };
  const overlayStyle = { background: 'rgba(0,0,0,0.8)' };
  const closeOnDocumentClick = false;
  const lockScroll = true;
  const { isOpen, closeSidebar } = useSidebarStore();
  const { isDarkMode } = useThemeMode();
  const appInfo = appInitialState;
  const ua = getUA;
  const arr = ua.split(' ');
  const osType = arr.find((el) => {
    return el.indexOf('osType:') > -1;
  });
  const appName = arr.find((el) => {
    return el.indexOf('appName:') > -1;
  });
  const appVersion = arr.find((el) => {
    return el.indexOf('appVersion:') > -1;
  });
  if (osType) appInfo.osType = osType.split(':')[1];
  if (appName) appInfo.appName = appName.split(':')[1];
  if (appVersion) appInfo.appVersion = appVersion.split(':')[1];
  const isTalkenApp = appInfo.appName === 'Talken';
  return (
    <div className={`side-bar ${isOpen ? 'show' : ''}`} onClick={closeSidebar}>
      {/* sidebar before login new design */}
      {!accessToken ? (
        <div className="wrapper-backdrop" onClick={(e) => e.stopPropagation()}>
          <div className="close-sidebar">
            <div className="icon-close" onClick={closeSidebar}>
              {isDarkMode ? <img src={close_icon} alt="Close Icon" /> : <img src={close_icon_black} alt="Close Icon" />}
            </div>
          </div>
          {/* <div
            className="login-signup"
            onClick={() => {
              setLoginOpen(true);
              closeSidebar();
            }}
          >
            <span>Connect Wallet</span>
          </div> */}
          <div className="wrapper">
            <a
              href={isTalkenApp ? 'https://talken.io/static/intro/en.html' : 'https://talken.io/'}
              className="wrapper-item button"
              target={'_blank'}
            >
              {isDarkMode ? <img src={about} alt="About Icon" /> : <img src={about_black} alt="About Icon" />}
              About
            </a>
            <a href="https://talken-io.medium.com/" className="wrapper-item button" target={'_blank'}>
              {isDarkMode ? <img src={blog} alt="Blog Icon" /> : <img src={blog_black} alt="Blog Icon" />}
              Blog
            </a>
            <a href="https://docs.talken.io/talken-docs/" className="wrapper-item button" target={'_blank'}>
              {isDarkMode ? (
                <img src={docs} style={{ width: '24px' }} alt="Docs Icon" />
              ) : (
                <img src={docs_black} style={{ width: '24px' }} alt="Docs Icon" />
              )}
              Docs
            </a>
            <a
              href="//talkensupport.zendesk.com/hc/en-us/requests/new"
              className="wrapper-item button"
              target={'_blank'}
            >
              {isDarkMode ? <img src={help} alt="Help Icon" /> : <img src={help_black} alt="Help Icon" />}
              Help
            </a>
            <a href="//studio.talken.io" className="wrapper-item button" target={'_blank'}>
              {isDarkMode ? <img src={help} alt="Help Icon" /> : <img src={help_black} alt="Help Icon" />}
              Talken Studio
            </a>
          </div>
          <div className="fanpage-icons-sidebar">
            <a href="https://twitter.com/Talken_" target={'_blank'} rel="noreferrer">
              {isDarkMode ? <img src={icon_twitter} alt="" /> : <img src={icon_twitter_black} alt="" />}
            </a>
            <a href="https://discord.gg/S33c5DA9cW" target={'_blank'} rel="noreferrer">
              {isDarkMode ? <img src={icon_discord} alt="" /> : <img src={icon_discord_black} alt="" />}
            </a>
            <a href="https://www.instagram.com/talken_nft" target={'_blank'} rel="noreferrer">
              {isDarkMode ? <img src={icon_insta} alt="" /> : <img src={icon_insta_black} alt="" />}
            </a>
          </div>
        </div>
      ) : (
        <div className="wrapper-backdrop" onClick={(e) => e.stopPropagation()}>
          <UsernameBox />
        </div>
      )}
      {/* sidebar after login */}

      {/* popup log in */}
      <Popup
        modal
        open={loginOpen}
        onOpen={closeSignup}
        onClose={closeLogin}
        {...{ overlayStyle, closeOnDocumentClick, lockScroll }}
      >
        {/* <LoginForm close={closeLogin} onConfirm={() => setSignupOpen(true)} />*/}
        <WalletConnector close={closeLogin} onConfirm={() => setSignupOpen(true)} />
      </Popup>
      {/* popup sign up */}
      <Popup
        modal
        open={signupOpen}
        onOpen={closeLogin}
        onClose={closeSignup}
        {...{ overlayStyle, closeOnDocumentClick, lockScroll }}
      >
        <SignupForm close={closeSignup} onConfirm={() => setLoginOpen(true)} />
      </Popup>
    </div>
  );
};

export default SidebarMb;
