import ReactModal from 'react-modal';
import { ReactComponent as ModalSvg } from 'assets/svg/modal.svg';
import React from 'react';

const FullfillModal = ({ show, onHide }) => {
  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className="modal-primary w-430px h-248px w-xs-338px h-xs-240px">
        <div className="p-t-24 p-t-xs-16 p-b-32 p-b-xs-26 p-l-24 p-r-24 p-l-xs-20 p-r-xs-20 d-flex flex-column align-items-center gap-8px">
          <ModalSvg />
          <div className="text-center fs-20 fs-xs-16 fw-9 fw-xs-7 m-t-10 lh-24">
            Does not have the balances needed to fulfill.
          </div>
          <button className="button btn-detail-nft m-t-16 m-t-xs-24 mb-0 h-50px" onClick={onHide}>
            Confirm
          </button>
        </div>
      </div>
    </ReactModal>
  );
};
export default FullfillModal;
