import env from '../env';

export function getTargetNetwork() {
    const chainId = parseInt(window.localStorage.getItem('target'));

    let target;

    switch (chainId ?? env.REACT_APP_TARGET_NETWORK) {
        case 11155111:
            target = 1;
            break;
        case 8217:
            target = 2;
            break;
        case 1001:
            target = 3;
            break;
        case 56:
            target = 4;
            break;
        case 97:
            target = 5;
            break;
        case 137:
            target = 6;
            break;
        case 80002:
            target = 7;
            break;
        case 1:
        default:
            target = 0;
            break;
    }

    return target;
}
