import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import klaytn_white from '../../assets/icon/klaytn_white.png';
import klaytn_black from '../../assets/icon/klaytn_black.png';
import website_icon from '../../assets/icon/website_icon.svg';
import icon_discord from '../../assets/img/icon_discord.png';
import icon_instagram from '../../assets/img/icon_instagram.png';
import icon_twitter from '../../assets/img/icon_twitter.png';
import icon_discord_black from '../../assets/img/icon_discord_black.png';
import icon_instagram_black from '../../assets/img/icon_instagram_black.png';
import icon_twitter_black from '../../assets/img/icon_twitter_black.png';
import website_icon_black from '../../assets/icon/website_icon_black.svg';
import icon_share from '../../assets/img/icon_share.png';
import icon_share_black from '../../assets/img/icon_share_black.png';
import ic_dropdown from '../../assets/svg/dropdown_button_dots.svg';
import ic_dropdown_black from '../../assets/svg/dropdown_button_dots_black.svg';
import ReactTooltip from 'react-tooltip';
import { useThemeMode } from 'components/common/AppStoreType';
import { useLocation, useMatch, useMatches } from 'react-router-dom';
import { moveToScope } from 'utils/moveToScope';
import useCopyToClipBoard from 'hooks/useCopyToClipboard';
import GetSnsMobileButtons from './GetSnsMobileButtons';
import CSnackbar from 'components/common/CSnackbar';
import { ReactComponent as IconTransfer } from 'assets/icon/transfer.svg';
import useScreenSize from 'components/common/useScreenSize';
import TransferItem from 'components/detailnftmodal/TransferItem';
import TransferItemOTP from 'components/detailnftmodal/TransferItemOTP';
import TransferItemSuccess from 'components/detailnftmodal/TransferItemSuccess';
import { useOpenTransferItem } from 'components/common/AppStore';

type LinkTypes = {
  type: string;
  url: string;
  useExternalUrl: boolean;
};

type GetSnsButtonsProps = {
  links?: LinkTypes[];
  isCreator?: boolean;
  chainId?: number;
  boxContractAddress?: any;
  explorer?: boolean;
  titleItem?: string;
  fromMyItems?: boolean;
};
const GetSnsButtons = ({
  links = [],
  isCreator,
  chainId,
  boxContractAddress,
  titleItem,
  explorer,
  fromMyItems,
}: GetSnsButtonsProps) => {
  const { isDarkMode } = useThemeMode();
  const refDropdown = useRef() as MutableRefObject<HTMLDivElement>;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { copyToClipBoard, copyResult, setCopyResult } = useCopyToClipBoard();
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    type: '',
    message: '',
  });

  const handleCloseSnackbar = () => {
    setOpenSnackbar({
      open: false,
      type: 'info',
      message: '',
    });
    setCopyResult(false);
  };
  useEffect(() => {
    setOpenSnackbar({
      open: copyResult,
      type: 'success',
      message: 'Copied!',
    });
  }, [copyResult]);

  const screenSize = useScreenSize();
  const { openTransferItem } = useOpenTransferItem();
  return (
    <>
      <div className="collection-info-right-sns">
        <div className="collection-info-left-details">
          {links &&
            links?.map((link: LinkTypes, index) => {
              return (
                <div
                  style={{
                    cursor: 'pointer',
                  }}
                  className={`info-item-sns ${isCreator ? 'hide-max-540px' : 'hide-max-1024px'}`}
                  onClick={() => window.open(link.url)}
                  key={index}
                >
                  <div className="image-item">
                    {link.type === 'SITE' &&
                      (isDarkMode ? (
                        <img src={website_icon} alt="Website Icon" data-for="tooltip-website" data-tip />
                      ) : (
                        <img src={website_icon_black} alt="Website Icon" data-for="tooltip-website" data-tip />
                      ))}
                    {link.type === 'DISCORD' &&
                      (isDarkMode ? (
                        <img src={icon_discord} alt="Website Icon" data-for="tooltip-discord" data-tip />
                      ) : (
                        <img src={icon_discord_black} alt="Website Icon" data-for="tooltip-discord" data-tip />
                      ))}
                    {link.type === 'TWITTER' &&
                      (isDarkMode ? (
                        <img src={icon_twitter} alt="Website Icon" data-for="tooltip-twitter" data-tip />
                      ) : (
                        <img src={icon_twitter_black} alt="Website Icon" data-for="tooltip-twitter" data-tip />
                      ))}
                    {link.type === 'INSTAGRAM' &&
                      (isDarkMode ? (
                        <img src={icon_instagram} alt="Website Icon" data-for="tooltip-instagram" data-tip />
                      ) : (
                        <img src={icon_instagram_black} alt="Website Icon" data-for="tooltip-instagram" data-tip />
                      ))}
                  </div>
                  <ReactTooltip
                    id="tooltip-website"
                    getContent={(dataTip) => 'Website'}
                    type={isDarkMode ? 'light' : 'dark'}
                    offset={{ top: 10 }}
                    effect={'solid'}
                  />
                  <ReactTooltip
                    id="tooltip-discord"
                    getContent={(dataTip) => 'Discord'}
                    type={isDarkMode ? 'light' : 'dark'}
                    offset={{ top: 10 }}
                    effect={'solid'}
                  />
                  <ReactTooltip
                    id="tooltip-twitter"
                    getContent={(dataTip) => 'Twitter'}
                    type={isDarkMode ? 'light' : 'dark'}
                    offset={{ top: 10 }}
                    effect={'solid'}
                  />
                  <ReactTooltip
                    id="tooltip-instagram"
                    getContent={(dataTip) => 'Instagram'}
                    type={isDarkMode ? 'light' : 'dark'}
                    offset={{ top: 10 }}
                    effect={'solid'}
                  />
                </div>
              );
            })}
          {links && links.length > 0 && (
            <div className={`dropdown ${isCreator ? 'hide-min-540px' : 'hide-min-1025px'}`} ref={refDropdown}>
              <div className="dropdown-button" onClick={() => setDropdownOpen((dropdownOpen) => !dropdownOpen)}>
                {isDarkMode ? <img src={ic_dropdown} alt="dropdown" /> : <img src={ic_dropdown_black} alt="dropdown" />}
              </div>
              {dropdownOpen && <GetSnsMobileButtons links={links} fromMyItems={fromMyItems} />}
            </div>
          )}
        </div>
        {/* {links && links.length > 0 && <div className="line-icon" />} */}

        <div className="collection-info-left-details">
          {fromMyItems ? (
            <>
              <div
                style={{ cursor: 'pointer' }}
                className="info-item-sns"
                data-for="tooltip-transfer"
                data-tip
                onClick={openTransferItem}
              >
                <IconTransfer className="svg-color dark:svg-color " />
                <ReactTooltip
                  id="tooltip-transfer"
                  getContent={(dataTip) => 'Transfer'}
                  type={isDarkMode ? 'light' : 'dark'}
                  offset={{ top: 10 }}
                  effect={'solid'}
                />
              </div>
              {screenSize > 540 && (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => copyToClipBoard(window.location.href)}
                  className="info-item-sns"
                  data-for="tooltip-copy"
                  data-tip
                >
                  <div className="image-item">
                    {isDarkMode ? (
                      <img src={icon_share} alt="Share Icon" width="20px" />
                    ) : (
                      <img src={icon_share_black} alt="Share Icon" width="20px" />
                    )}
                  </div>
                  <ReactTooltip
                    id="tooltip-copy"
                    getContent={(dataTip) => 'Copy'}
                    type={isDarkMode ? 'light' : 'dark'}
                    offset={{ top: 10 }}
                    effect={'solid'}
                  />
                </div>
              )}
            </>
          ) : (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => copyToClipBoard(window.location.href)}
              className="info-item-sns"
              data-for="tooltip-copy"
              data-tip
            >
              <div className="image-item">
                {isDarkMode ? (
                  <img src={icon_share} alt="Share Icon" width="20px" />
                ) : (
                  <img src={icon_share_black} alt="Share Icon" width="20px" />
                )}
              </div>
              <ReactTooltip
                id="tooltip-copy"
                getContent={(dataTip) => 'Copy'}
                type={isDarkMode ? 'light' : 'dark'}
                offset={{ top: 10 }}
                effect={'solid'}
              />
            </div>
          )}
        </div>
      </div>
      <CSnackbar
        open={openSnackbar.open}
        type={openSnackbar.type}
        message={openSnackbar.message}
        handleClose={handleCloseSnackbar}
      />
      {/*<TransferItem nameItem={titleItem} />*/}
      {/*<TransferItemOTP />*/}
      {/*<TransferItemSuccess selected={} />*/}
    </>
  );
};

export default GetSnsButtons;
