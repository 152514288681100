import ReactModal from 'react-modal';
import { ReactComponent as IconClose } from 'assets/icon/close_icon.svg';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import HelperUtil from '../../utils/HelperUtil';
import env from '../../env';
import { seaport } from '../../utils/seaport';
import { SUCCESS } from '../../config';
import { clearBids, registerHistory } from '../../services/services';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import getAbcWallet from '../../utils/getAbcWallet';
import { setupNetwork } from '../../utils/wallet';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
type WinningBidModal = {
  show: any;
  onHide: any;
  openOTPCodeModal: any;
  selected: any;
};
const WinningBidModal: React.FC<WinningBidModal> = ({ show, onHide, openOTPCodeModal, selected }) => {
  const { account, library, chainId } = useActiveWeb3React();
  const { isAbcReady, abcAddress } = getAbcWallet();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const login = window.localStorage.getItem('login');

  const user = useSelector((state: any) => state.user.user);
  const uid = user.uid;

  const [target, setTarget] = useState(0);
  useEffect(() => {
    switch (selected?.nftInfo?.chain) {
      case 'ethereum':
        setTarget(env.REACT_APP_TARGET_NETWORK_ETH);
        break;
      case 'klaytn':
        setTarget(env.REACT_APP_TARGET_NETWORK_KLAY);
        break;
      case 'binance':
        setTarget(env.REACT_APP_TARGET_NETWORK_BNB);
        break;
      case 'polygon':
        setTarget(env.REACT_APP_TARGET_NETWORK_MATIC);
        break;
    }
  }, [selected]);

  const truncateString = (str: string, firstCharCount: number, endCharCount: number, dotCount: number) => {
    if (!str) return '';

    if (str.length <= firstCharCount + endCharCount) {
      return str; // No truncation needed
    }

    const firstPortion = str.slice(0, firstCharCount);
    const endPortion = str.slice(-endCharCount);
    const dots = '.'.repeat(dotCount);

    return `${firstPortion}${dots}${endPortion}`;
  };

  const handleConfirmModal = async () => {
    setIsLoading(true);

    // TODO : fulfillment
    try {
      console.log('!! start fulfillment ...');
      if (target === 0) return;
      let provider;
      if (login === 'sns') {
        provider = await HelperUtil.getAbcWeb3Provider(target);
      } else {
        await setupNetwork(parseInt(target.toString() ?? env.REACT_APP_TARGET_NETWORK.toString()));
      }

      try {
        const receipt = await seaport.seaportFulfillOrder(
          login === 'sns' ? provider : library.getSigner(),
          selected.bidInfo
        );
        console.log('!! receipt : ', receipt);
        if (receipt.status === SUCCESS) {
          // TODO : Clear Bids & Remove sellbook Item
          let result = await clearBids(selected.sellbookId);
          console.log('!! clearBids result :::::', result);

          // TODO : Store History
          const data = {
            fromUid: uid,
            toUid: selected.uid,
            nftInfo: selected?.nftInfo,
            price: selected.price,
            type: 'AUCTION_BUY',
            txHash: receipt.transactionHash,
            quote: selected.quote,
          };
          result = await registerHistory(data);
          console.log('!! registerHistory result :::::', result);
          onHide();
          // TODO : to go where ???
          navigate('/my-items');
        } else {
          if (receipt === 'Incorrect twofactor verify token') alert('Incorrect twofactor verify token');
          if (receipt === 'MetaMask Tx Signature: User denied transaction signature.')
            return alert('User denied transaction signature.');
          if (receipt === 'MetaMask Tx Signature: User denied message signature.')
            return alert('User denied message signature.');
          if (receipt === 'The offerer does not have the amount needed to create or fulfill.')
            return alert('The offerer does not have the amount needed to create or fulfill.');
          console.log('!!!!! fulfillment failed');
          return;
        }
      } catch (e: any) {
        console.log('!!!!! fulfillment error', e.message);
      }
    } catch (err: any) {
      console.log(err.message);
      alert(err.message);
      onHide();
    } finally {
      setIsLoading(false);
    }

    // onHide();
    // openOTPCodeModal();
  };

  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className="modal-primary w-428px w-xs-338px h-472px h-xs-400px p-l-24 p-r-24 p-l-xs-20 p-r-xs-20">
        <div className="close-button-modal" onClick={onHide}>
          <IconClose className="svg-color dark:svg-color" />
        </div>
        <div className="fs-24 fs-xs-16 p-t-xs-20 p-t-24 fw-7 text-center lh-30">Winning Bid</div>
        <div className="line-banner"></div>
        <div className="p-t-xs-12 p-b-xs-28 p-b-32">
          <div className="p-b-xs-16 p-b-24 lh-74">
            <div className="title-input-modal fs-14 p-b-8 fs-xs-10 lh-16">Price</div>
            <div className="border-radius-8 border-radius-xs-6 p-t-xs-8 p-b-xs-8 p-l-xs-16 p-r-xs-16 p-l-20 p-r-20 p-t-12 p-b-12 h-xs-32 h-48 flex-start gap-10px bg-input-modal lh-26">
              <div className="fw-7 fs-xs-12">{selected?.priceNft}</div>
              <div className="fs-16 fs-xs-12 title-input-modal">${selected?.priceUsd}</div>
            </div>
          </div>
          <div className="p-b-xs-16 p-b-24 lh-74">
            <div className="title-input-modal fs-14 p-b-8 fs-xs-10 lh-16">Floor Difference</div>
            <div className="border-radius-8 border-radius-xs-6 p-t-xs-8 p-b-xs-8 p-l-xs-16 p-r-xs-16 p-l-20 p-r-20 p-t-12 p-b-12 h-xs-32 h-48 bg-input-modal lh-26">
              <div className="fw-7 fs-xs-12">{selected?.floorDifference}</div>
            </div>
          </div>
          <div className="p-b-xs-16 p-b-24 lh-74">
            <div className="title-input-modal fs-14 p-b-8 fs-xs-10 lh-16">From</div>
            <div className="border-radius-8 border-radius-xs-6 p-t-xs-8 p-b-xs-8 p-l-xs-16 p-r-xs-16 p-l-20 p-r-20 p-t-12 p-b-12 h-xs-32 h-48 bg-input-modal lh-26">
              <div className="fw-7 fs-xs-12">{truncateString(selected?.wallet, 10, 10, 3)}</div>
            </div>
          </div>
          <button className="btn-detail-nft button h-50px h-xs-40px m-0" onClick={handleConfirmModal}>
            {isLoading ? <CircularProgress size={30} color={'inherit'} /> : <>Accept</>}
          </button>
        </div>
      </div>
    </ReactModal>
  );
};
export default WinningBidModal;
