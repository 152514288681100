import { useThemeMode } from 'components/common/AppStoreType';
import ReactModal from 'react-modal';
import close_icon from '../../assets/icon/close_icon.svg';
import close_icon_black from '../../assets/icon/close_icon_black.svg';
import klay from '../../assets/svg/klaytnFaucet.svg';
import polygon from '../../assets/icon/polygon.svg';
import binance from 'assets/img/icon_binance.png';
import eth from '../../assets/icon/eth.png';
import talk from '../../talkclaim/talk.svg';
import Select from 'react-select';
import { useMediaQuery } from 'react-responsive';
import useScreenSize from 'components/common/useScreenSize';
import { useEffect, useRef, useState } from 'react';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import getAbcWallet from '../../utils/getAbcWallet';
import {
  erc20Transfer,
  ethTransfer,
  getBnbBalance,
  getErc20Balance,
  getEthBalance,
  getKlayBalance,
  getMaticBalance,
} from '../../utils/transactions';
import HelperUtil from '../../utils/HelperUtil';
import contracts from '../../config/constants/contracts';
import { ChainId } from '../../config';
import { setupNetwork } from '../../utils/wallet';
import env from '../../env';
import { getActiveWallet } from '../../utils/getActiveWallet';
import { ethers } from 'ethers';
import { CircularProgress } from '@mui/material';
import { SUCCESS, CANCELED } from '../../config';
import talkKlaytn from '../../assets/icon/talkKlaytn.svg';
import talkPolygon from '../../assets/icon/talkPolygon.svg';
import talkBinance from '../../assets/icon/talkBinance.svg';
import close_icon_8787d6 from '../../assets/icon/close_icon_8787d6.svg';

type TransferModal = {
  show: any;
  onHide: any;
  typeModal: string;
  openWinningComplete: any;
  data: any;
  setReload?: any;
};
const TransferModal: React.FC<TransferModal> = ({ show, onHide, typeModal, openWinningComplete, data, setReload }) => {
  const { isDarkMode } = useThemeMode();
  const screenSize = useScreenSize();
  const isMobile = useMediaQuery({
    query: '(max-width: 641px)',
  });
  const stylesPayType = {
    control: (base, state) => ({
      ...base,
      background: isDarkMode ? '#202052' : '#FFFFFF1A',
      borderRadius: isMobile ? '6px' : '10px',
      borderColor: state.selectProps.menuIsOpen ? '#2F8AF5' : '#525298',
      boxShadow: state.isFocused ? 'none' : 'none',
      fontSize: isMobile ? '11px' : '16px',
      fontWeight: 500,
      width: '100%',
      height: isMobile ? '32px' : '50px',
      minHeight: isMobile ? '32px' : '38px',
      cursor: 'pointer',
      ':hover': {
        borderColor: !state.selectProps.menuIsOpen ? '#525298' : '#2F8AF5',
      },
    }),
    singleValue: (base, state) => ({
      ...base,
      color: state.selectProps.menuIsOpen ? '#FFFFFF' : isDarkMode ? '#FFFFFF' : '#000000',
    }),
    valueContainer: (base) => ({
      ...base,
      height: '100%',
      padding: isMobile ? '8px' : '2px 8px',
    }),
    option: (base, state) => ({
      ...base,
      fontSize: isMobile ? '11px' : '16px',
      fontWeight: 500,
      width: '100%',
      color: state.isSelected ? '#777777' : '#777777',
      backgroundColor: state.isSelected ? '#202052' : '#202052',
      ':active': {
        // backgroundColor: state.isSelected ? '#FFFFFF' : '#FFFFFF',
      },
      ':hover': {
        backgroundColor: '#303073',
        color: '#FFFFFF',
      },
      cursor: 'pointer',
    }),

    menu: (base) => ({
      ...base,
      fontSize: isMobile ? '11px' : '14px',
      background: '#303073',
      borderRadius: isMobile ? '6px' : '10px',
      marginTop: 0,
      border: '1px solid #2f8af5',
      overflowY: 'auto',
    }),
    menuList: (base) => ({
      ...base,
      fontSize: '12px',
      padding: '0',
      borderRadius: isMobile ? '6px' : '10px',
      height: 'auto',
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: !state.selectProps.menuIsOpen ? '#525298' : isDarkMode ? '#2F8AF5' : '#000000',
      placeContent: 'center',
      padding: isMobile ? '4px' : '8px',
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
  };
  const [selectedOption, setSelectedOption] = useState(data?.value);
  const options = [
    { value: 0, label: 'TALK', customAbbreviation: talk },
    { value: 1, label: 'TALK', customAbbreviation: talkBinance },
    { value: 2, label: 'TALK', customAbbreviation: talkPolygon },
    { value: 3, label: 'TALK', customAbbreviation: talkKlaytn },
    { value: 4, label: 'ETH', customAbbreviation: eth },
    { value: 5, label: 'BNB', customAbbreviation: binance },
    { value: 6, label: 'MATIC', customAbbreviation: polygon },
    { value: 7, label: 'KLAY', customAbbreviation: klay },
  ];

  const { account, library, chainId, activate } = useActiveWeb3React();
  const { isAbcReady, abcAddress } = getAbcWallet();
  const myWallet = getActiveWallet();
  const login = window.localStorage.getItem('login');
  const [txHash, setTxHash] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const formatOptionLabel = ({ value, label, customAbbreviation }) => (
    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      <img style={{ width: '20px', height: '20px' }} src={customAbbreviation} className="m-r-10"></img>
      <div>{label}</div>
    </div>
  );

  const [target, setTarget] = useState(0);

  const [ischangeSelect, setIschangeSelect] = useState(false);
  const [placeholderAmount, setPlaceholderAmount] = useState(data?.balance);

  const [ethBal, setEthBal] = useState('0.0');
  const [klayBal, setKlayBal] = useState('0.0');
  const [maticBal, setMaticBal] = useState('0.0');
  const [bnbBal, setBnbBal] = useState('0.0');
  const [talkBals, setTalkBals] = useState([]);

  const fetchKlayBalance = async () => {
    const value = await getKlayBalance(myWallet);
    setKlayBal(value);
  };
  const fetchEthBalance = async () => {
    const value = await getEthBalance(myWallet);
    setEthBal(value);
  };
  const fetchMaticBalance = async () => {
    const value = await getMaticBalance(myWallet);
    setMaticBal(value);
  };
  const fetchBnbBalance = async () => {
    const value = await getBnbBalance(myWallet);
    setBnbBal(value);
  };
  const fetchTalkBalance = async () => {
    const Chains = [
      env.REACT_APP_TARGET_NETWORK_ETH,
      env.REACT_APP_TARGET_NETWORK_MATIC,
      env.REACT_APP_TARGET_NETWORK_KLAY,
      env.REACT_APP_TARGET_NETWORK_BNB,
    ];
    const values: string[] = [];
    for (let i = 0; i < Chains.length; i++) {
      const value = await getErc20Balance(contracts?.talken[Chains[i]], myWallet, Chains[i], 18);
      values.push(value);
    }
    console.log('!! TALK balances on chains : ', values);
    setTalkBals(values);
  };
  useEffect(() => {
    if (myWallet) fetchKlayBalance();
  }, [myWallet]);
  useEffect(() => {
    if (myWallet) fetchEthBalance();
  }, [myWallet]);
  useEffect(() => {
    if (myWallet) fetchMaticBalance();
  }, [myWallet]);
  useEffect(() => {
    if (myWallet) fetchBnbBalance();
  }, [myWallet]);
  useEffect(() => {
    if (myWallet) fetchTalkBalance();
  }, [myWallet]);

  useEffect(() => {
    switch (selectedOption) {
      case 0:
        setTarget(env.REACT_APP_TARGET_NETWORK_ETH);
        if (ischangeSelect) setPlaceholderAmount(talkBals[0]);
        break;
      case 1:
        setTarget(env.REACT_APP_TARGET_NETWORK_BNB);
        if (ischangeSelect) setPlaceholderAmount(talkBals[3]);
        break;
      case 2:
        setTarget(env.REACT_APP_TARGET_NETWORK_MATIC);
        if (ischangeSelect) setPlaceholderAmount(talkBals[1]);
        break;
      case 3:
        setTarget(env.REACT_APP_TARGET_NETWORK_KLAY);
        if (ischangeSelect) setPlaceholderAmount(talkBals[2]);
        break;
      case 4:
        setTarget(env.REACT_APP_TARGET_NETWORK_ETH);
        if (ischangeSelect) setPlaceholderAmount(ethBal);
        break;
      case 5:
        setTarget(env.REACT_APP_TARGET_NETWORK_BNB);
        if (ischangeSelect) setPlaceholderAmount(bnbBal);
        break;
      case 6:
        setTarget(env.REACT_APP_TARGET_NETWORK_MATIC);
        if (ischangeSelect) setPlaceholderAmount(maticBal);
        break;
      case 7:
        setTarget(env.REACT_APP_TARGET_NETWORK_KLAY);
        if (ischangeSelect) setPlaceholderAmount(klayBal);
        break;
    }
  }, [selectedOption]);

  const switchNetwork = async (chain: number) => {
    if (chain !== 0) await setupNetwork(chain, false, onHide);
  };
  useEffect(() => {
    if (login !== 'sns') switchNetwork(target);
  }, [target]);

  const handleConfirmModal = async () => {
    const numericValue = parseFloat(inputValue);
    const numericValueMinIncrement = HelperUtil.ethAddressValidate(inputAddress);

    if (isNaN(numericValue)) {
      alert('Please enter the token quantity.');
      return;
    }
    if (!numericValueMinIncrement) {
      alert('Invalid wallet address. Please check again.');
      return;
    }
    if (numericValue <= 0) {
      alert('Amount should be greater than zero!!');
      return;
    }
    if (numericValue > parseFloat(placeholderAmount)) {
      alert('Amount exceeds the maximum value.');
      return;
    }
    setIsLoading(true);
    // console.log('======>', { selectedOption, inputValue, inputAddress });

    let provider = null;
    if (login === 'sns') {
      // TODO : How to support multiple chains !! 현재 Klaytn 만 지원이 됨.
      provider = await HelperUtil.getAbcWeb3Provider(target);
    }

    if (selectedOption > 3) {
      const { state: res, error } = await ethTransfer(
        inputAddress,
        ethers.utils.parseEther(inputValue).toHexString(),
        myWallet,
        login === 'sns' ? provider : library,
        false,
        // Use the callback to perform actions after state is updated
        (updatedTxHash) => {
          if (updatedTxHash && updatedTxHash !== '') {
            openWinningComplete({
              value: selectedOption,
              balance: inputValue,
              address: inputAddress,
              txid: updatedTxHash,
            });
          }
        },
        target
      );
      if (res === SUCCESS) {
        console.log('!! Transfer NFT : success');
        // setReload(true);
      } else {
        console.log('!! Transfer NFT : failed');
        if (login === 'sns') {
          if (res !== CANCELED) {
            alert(error);
          } else {
            // alert('User canceled transaction');
          }
        }
        setInputAddress('');
        setIsLoading(false);
        return;
      }
    } else {
      const res = await erc20Transfer(
        contracts.talken[target],
        inputAddress,
        ethers.utils.parseEther(inputValue).toString(),
        myWallet,
        login === 'sns' ? provider : library,
        false,
        (updatedTxHash) => {
          if (updatedTxHash && updatedTxHash !== '') {
            openWinningComplete({
              value: selectedOption,
              balance: inputValue,
              address: inputAddress,
              txid: updatedTxHash,
            });
          }
        }
      );
      if (res === SUCCESS) {
        console.log('!! Transfer NFT : success');
        // setReload(true);
      } else {
        console.log('!! Transfer NFT : failed');
        if (login === 'sns' && res !== CANCELED) alert('Incorrect twofactor verify token');
        setInputAddress('');
        setIsLoading(false);
        return;
      }
    }

    setInputAddress('');
    setIsLoading(false);
    if (setReload) setReload(true);
    onHide();
  };
  const handleTypeSelect = (e) => {
    setIschangeSelect(true);
    setSelectedOption(e.value);
  };

  const [inputValue, setInputValue] = useState('');
  const [inputAddress, setInputAddress] = useState('');

  const handlePasteFromClipboard = () => {
    navigator.clipboard.readText().then((text) => {
      setInputAddress(text);
    });
  };
  const handleSendAll = () => {
    setInputValue(parseFloat(placeholderAmount).toFixed(8));
  };
  const inputRef = useRef();

  const handleInputChange = (e) => {
    const value = e.target.value;

    // const regex = /^\d*\.?\d*$/;
    const regex = /^\d*\.?\d{0,8}$/;

    if (regex.test(value)) {
      setInputValue(value);
    }
  };
  const isDecimalWithoutFraction = inputValue.endsWith('.');
  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className="otp-code-modal otp-code-modal-custom">
        <div className="body-otp">
          <div className="close-button" onClick={onHide}>
            {isDarkMode ? (
              <img src={close_icon_8787d6} alt="icon close" />
            ) : (
              <img src={close_icon_black} alt="icon close" />
            )}
          </div>
          <div className="title-input-otp m-t-30">Choose token</div>
          <div className={`${screenSize < 641 ? 'mb-4' : 'm-b-26'}`}>
            <Select
              formatOptionLabel={formatOptionLabel}
              options={options}
              styles={stylesPayType}
              isSearchable={false}
              onChange={handleTypeSelect}
              value={options.filter(function (option) {
                return option.value === selectedOption;
              })}
              placeholder={'Select Token'}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="title-input-otp mb-1">Amount</div>
          <div className={`wrapper-input-otp p-0 ${screenSize < 641 ? 'mb-4' : 'm-b-26'}`}>
            <input
              className="fs-16 fs-xs-12"
              type="text"
              ref={inputRef}
              value={inputValue}
              onChange={handleInputChange}
              placeholder={`Balance: ${placeholderAmount > 0 ? parseFloat(placeholderAmount).toFixed(8) : 0}`}
            />
            <button className="btn-paste-otp button" onClick={handleSendAll}>
              Send All
            </button>
          </div>
          <div className="title-input-otp mb-1">Recipient wallet address</div>
          <div className={`wrapper-input-otp p-0 ${screenSize < 641 ? 'mb-4' : 'm-b-26'}`}>
            <input
              className="fs-16 fs-xs-12"
              type="text"
              placeholder="Enter the wallet adress"
              ref={inputRef}
              value={inputAddress}
              onChange={(e) => setInputAddress(e.target.value)}
            />
            <button className="btn-paste-otp button" onClick={handlePasteFromClipboard}>
              Paste
            </button>
          </div>
          <button
            className={`button m-0 btn-detail-nft ${isDecimalWithoutFraction ? 'disabled' : ''}`}
            onClick={handleConfirmModal}
            disabled={isDecimalWithoutFraction}
          >
            {isLoading ? <CircularProgress size={30} color={'inherit'} /> : <>Send</>}
          </button>
        </div>
      </div>
    </ReactModal>
  );
};
export default TransferModal;
