import ReactModal from 'react-modal';
import { ReactComponent as IconClose } from 'assets/icon/close_icon.svg';
import { ReactComponent as ModalSvg } from 'assets/svg/modal.svg';
import { useNavigate } from 'react-router-dom';
type EditListingComplete = {
  show: any;
  onHide: any;
  dataDetailMyItems?: any;
};
const EditListingComplete: React.FC<EditListingComplete> = ({ show, onHide, dataDetailMyItems }) => {
  const navigate = useNavigate();
  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className="modal-primary w-430px w-xs-338px h-fit-content">
        <div className="p-24 p-xs-20">
          <div className="p-b-8 p-b-xs-4 d-flex flex-column align-items-center gap-2">
            <ModalSvg />
            <div className="text-center fs-20 fs-xs-16 fw-9 fw-xs-7 lh-24 lh-xs-inherit">Edit Completed</div>
            <span className="fs-12 fs-xs-10 p-b-16 p-b-xs-8 title-input-modal fw-400 p-l-10 p-r-10 text-center opacity-7">
              Your request for{' '}
              <span className="color-primary opacity-10 fw-7">
                {typeof dataDetailMyItems?.sellbook?.nftInfo?.name === 'string'
                  ? dataDetailMyItems?.sellbook?.nftInfo?.name?.length < 15
                    ? dataDetailMyItems?.sellbook?.nftInfo?.name
                    : dataDetailMyItems?.sellbook?.nftInfo?.name?.substring(0, 15) + '...'
                  : dataDetailMyItems?.sellbook?.nftInfo?.contractName
                  ? dataDetailMyItems?.sellbook?.nftInfo?.contractName
                  : dataDetailMyItems?.sellbook?.nftInfo?.metadata?.name
                  ? dataDetailMyItems?.sellbook?.nftInfo?.metadata?.name
                  : dataDetailMyItems?.sellbook?.nftInfo?.name?.en}
              </span>{' '}
              has been successfully completed
            </span>
            <button className="my-auto button btn-detail-nft" onClick={() => navigate('/my-items')}>
              View Listing
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};
export default EditListingComplete;
