import { ChainId } from '../../config';

export default {
  klay: {
    [ChainId.MAINNET]: '',
    [ChainId.SEPOLIA]: '',
    [ChainId.KLAYTN]: '0x0000000000000000000000000000000000000000',
    [ChainId.BAOBAB]: '0x0000000000000000000000000000000000000000',
    [ChainId.TMP]: '0x0000000000000000000000000000000000000000',
  },
  wklay: {
    [ChainId.MAINNET]: '',
    [ChainId.SEPOLIA]: '',
    [ChainId.KLAYTN]: '0x19Aac5f612f524B754CA7e7c41cbFa2E981A4432',
    [ChainId.BAOBAB]: '0x043c471bEe060e00A56CcD02c0Ca286808a5A436',
    [ChainId.TMP]: '0xa548e0db49b7a03c427d9bafbc92b6fc7382d0e7',
  },
  witnet: {
    [ChainId.MAINNET]: '',
    [ChainId.SEPOLIA]: '',
    [ChainId.KLAYTN]: '0x1ebD93231a7fE551E1d6405404Df34909eff4c2C',
    [ChainId.BAOBAB]: '0xB4B2E2e00e9d6E5490d55623E4F403EC84c6D33f',
    [ChainId.TMP]: '0x95250dFC15CC25d744c33cC6B458CB3FB6B1Ce3a',
  },
  usdt: {
    [ChainId.MAINNET]: '',
    [ChainId.SEPOLIA]: '',
    [ChainId.KLAYTN]: '0xcee8faf64bb97a73bb51e115aa89c17ffa8dd167',
    [ChainId.BAOBAB]: '0x1459c0eA227092987FC92b824001eBA3d7dB920F',
    [ChainId.TMP]: '',
  },
  usdc: {
    [ChainId.MAINNET]: '',
    [ChainId.SEPOLIA]: '',
    [ChainId.KLAYTN]: '0x754288077d0ff82af7a5317c7cb8c444d421d103',
    [ChainId.BAOBAB]: '0x273b95A65856B7A7755471F9f3F109E8AE721e30',
    [ChainId.TMP]: '',
  },
  talk: {
    [ChainId.MAINNET]: '0xCAabCaA4ca42e1d86dE1a201c818639def0ba7A7',
    [ChainId.SEPOLIA]: '0x67FD18Cc70A7f8C26508c59c906B39B2A079866d',
    [ChainId.KLAYTN]: '',
    [ChainId.BAOBAB]: '',
    [ChainId.TMP]: '',
  },
  matic: {
    // [ChainId.POLYGON]: '0x624ce98D2d27b20b8f8d521723Df8fC4db71D79D',
    // [ChainId.AMOY]: '0x1a4cC30f2aA0377b0c3bc9848766D90cb4404124',
    [ChainId.POLYGON]: '0x0000000000000000000000000000000000000000',
    [ChainId.AMOY]: '0x0000000000000000000000000000000000000000',
  },
  bnb: {
    [ChainId.BSCMAINNET]: '0xb8c77482e45f1f44de1745f52c74426c631bdd52',
    [ChainId.BSCTESTNET]: '0x094616F0BdFB0b526bD735Bf66Eca0Ad254ca81F',
  },
  bridge: {
    [ChainId.MAINNET]: '',
    [ChainId.SEPOLIA]: '0xa3885CFDe9AA6CC42Ad3eE43f241b1607A794299',
    [ChainId.KLAYTN]: '',
    [ChainId.BAOBAB]: '0x0CB46dC603678b5073fB4e261E56f9A6283dc4B8',
    [ChainId.POLYGON]: '',
    [ChainId.MUMBAI]: '0xce565ceD16D139fa13558Ab56060Ff4ddb22F799',
    [ChainId.BSCMAINNET]: '',
    [ChainId.BSCTESTNET]: '0xEFAF7c09aAd784F89A0ecEfaA72E3EA28C61c8E0',
    [ChainId.TMP]: '',
  },
  talken: {
    [ChainId.MAINNET]: '0xCAabCaA4ca42e1d86dE1a201c818639def0ba7A7',
    [ChainId.GOERLI]: '0xeA4257612eE631383D0C8468cCCe590E37bc6f97',
    [ChainId.SEPOLIA]: '0x1cc20f446679cf79436cC8d5F6c3EB60B1954463',
    [ChainId.KLAYTN]: '0x55863cb9a7f92c134e9f8edb6c655b92d60493b5',
    [ChainId.BAOBAB]: '0x156e8d865301a68567ecf8fa7c30b83ab312c6cf',
    [ChainId.POLYGON]: '0xE7e72bE51C3b4E1F3cEB34e177e1bA1c1744fD7d',
    [ChainId.MUMBAI]: '0x83EF185441fA4eACF3AB19984eaB4D7341b317Ac',
    [ChainId.BSCMAINNET]: '0x7D61D3752F759c71f5291358408947aFf7b0Ff2c',
    [ChainId.BSCTESTNET]: '0x479c24B16A7F79Ea56E3F990976c834400f5ba0C',
    [ChainId.TMP]: '',
  },
};
