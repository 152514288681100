import ReactModal from 'react-modal';
import { ReactComponent as IconClose } from 'assets/icon/close_icon.svg';
import { ReactComponent as IconWarning } from 'assets/icon/warning.svg';
import { useOpenTransferItem, useOpenTransferItemOTP } from 'components/common/AppStore';
import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { nftTransferFrom } from '../../utils/transactions';
import { useWeb3React } from '@web3-react/core';
import { useSelector } from 'react-redux';
import getAbcWallet from '../../utils/getAbcWallet';
import { getActiveWallet } from '../../utils/getActiveWallet';
import HelperUtil from '../../utils/HelperUtil';
import env from '../../env';
import { SUCCESS, CANCELED } from '../../config';
import { setupNetwork } from '../../utils/wallet';
type TransferItem = {
  selected: any;
  setReload?: any;
  openWinningComplete?: any;
};

const TransferItem: React.FC<TransferItem> = ({ selected, setReload, openWinningComplete }) => {
  const [recipient, setRecipient] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const login = window.localStorage.getItem('login');
  const { account, chainId, library } = useWeb3React();
  const user = useSelector((state: any) => state.user.user);
  const uid = user.uid;
  const { isAbcReady, abcAddress } = getAbcWallet();
  const myWallet = getActiveWallet();

  const [target, setTarget] = useState(0);
  const [is1155, setIs1155] = useState(false);
  const [balance, setBalance] = useState(0);
  const [amount, setAmount] = useState(1);
  useEffect(() => {
    if (!selected) return;
    setBalance(parseInt(selected?.amount) || parseInt(selected?.balance, 16));
    setIs1155(selected.contract_type === 'ERC1155' || selected.kind === 'mt');
    switch (selected.chain) {
      case 'ethereum':
        setTarget(env.REACT_APP_TARGET_NETWORK_ETH);
        break;
      case 'klaytn':
        setTarget(env.REACT_APP_TARGET_NETWORK_KLAY);
        break;
      case 'binance':
        setTarget(env.REACT_APP_TARGET_NETWORK_BNB);
        break;
      case 'polygon':
        setTarget(env.REACT_APP_TARGET_NETWORK_MATIC);
        break;
    }
  }, [selected]);

  const switchNetwork = async () => {
    if (login !== 'sns' && target !== 0) {
      await setupNetwork(target, true, closeTransferItem);
    }
  };
  useEffect(() => {
    switchNetwork();
  }, [target]);

  const handleInput = (event) => {
    setRecipient(event.target.value);
  };
  const handlePasteFromClipboard = () => {
    navigator.clipboard.readText().then((text) => {
      setRecipient(text);
    });
  };
  const checkAmount = (amount) => {
    const limitAmount = Number(selected?.balance) || selected?.amount;
    if (amount > limitAmount) return false;
    else return true;
  };
  const handleConfirmModal = async () => {
    const ValueRe = HelperUtil.ethAddressValidate(recipient);

    if (!ValueRe) {
      alert('Invalid wallet address. Please check again.');
      return;
    }
    if (is1155) {
      const limitAmount = Number(selected?.balance) || selected?.amount;
      if (amount > limitAmount || amount < 1) {
        alert('Check amount.');
        setAmount(0);
        return;
      }
    }
    setIsLoading(true);

    let provider;
    if (login === 'sns') {
      provider = await HelperUtil.getAbcWeb3Provider(target);
    }

    const result = await nftTransferFrom(
      selected.contractAddress,
      recipient,
      target === env.REACT_APP_TARGET_NETWORK_KLAY ? parseInt(selected?.extras?.tokenId, 16) : selected.token_id,
      is1155,
      amount,
      myWallet,
      login === 'sns' ? provider : library,
      false, // ???
      (updatedTxHash) => {
        if (updatedTxHash && updatedTxHash !== '') {
          openWinningComplete({
            recipient: recipient,
            txid: updatedTxHash,
            chain: selected?.chain,
            amount: amount,
          });
        }
      }
    );
    if (result === SUCCESS) {
      console.log('!! Transfer NFT : success');
      // setReload(true);
    } else {
      console.log('!! Transfer NFT : failed');
      if (result !== CANCELED) alert('Incorrect twofactor verify token');
      setIsLoading(false);
      setRecipient('');
      return;
    }
    setRecipient('');
    setIsLoading(false);
    closeTransferItem();
  };
  const { isOpenTransferItem, closeTransferItem } = useOpenTransferItem();
  return (
    <ReactModal
      isOpen={isOpenTransferItem}
      onRequestClose={closeTransferItem}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className="modal-primary w-540px w-xs-338px h-fit-content padding-modal mode-color-8787d6">
        <div className="close-button-modal" onClick={closeTransferItem}>
          <IconClose className="close-icon dark:close-icon" />
        </div>
        <div className="fs-24 fs-xs-16 fw-bold text-center">Transfer Item</div>
        <div className="line-banner"></div>
        <div className="">
          <div className="fs-14 fs-xs-12 p-b-8 title-input-modal mode-color-8787d6 lh-16 lh-xs-inherit">Item</div>
          <div className="detail-box-modal fs-xs-12 m-b-24 m-b-xs-16  mode-color-8787d6">
            {selected?.metadata?.name
              ? selected?.metadata?.name
              : typeof selected?.name === 'string'
              ? selected?.name
              : selected?.name?.en}
          </div>
          {is1155 && (
            <div>
              <div className="fs-14 fs-xs-12 p-b-8 title-input-modal mode-color-8787d6 lh-16 lh-xs-inherit">
                Quantity
              </div>
              <div className="wrapper-input-otp p-0 m-b-24 m-b-xs-16 position-relative">
                <input
                  type="text"
                  value={amount}
                  placeholder="Enter the amount"
                  onChange={(e) => {
                    const input = e.target.value.trim();
                    if (/^\d*\.?\d*$/.test(input)) {
                      const limitAmount = Number(selected?.balance) || selected?.amount;
                      if (Number(input) > limitAmount) {
                        alert('Check amount.');
                        setAmount(0);
                      } else {
                        setAmount(parseInt(input || '0'));
                      }
                    } else {
                      // Handle non-numeric input here
                    }
                  }}
                  className="mode-color-8787d6"
                />
                <div className="input-total-token">{`You own ${Number(selected?.balance) || selected?.amount}`}</div>
              </div>
            </div>
          )}
          <div className="fs-14 fs-xs-12 p-b-8 title-input-modal mode-color-8787d6 lh-16 lh-xs-inherit">
            Recipient wallet address
          </div>
          <div className="wrapper-input-otp p-0">
            <input type="text" value={recipient} placeholder="Enter the wallet adress" onChange={handleInput} />
            <button className="btn-paste-otp button" onClick={handlePasteFromClipboard}>
              Paste
            </button>
          </div>
          <div className="flex-start gap-4px p-t-8 p-b-20 p-b-xs-16">
            <IconWarning />
            <div className="fs-14 fs-xs-10 fs-xs-10 color-secondary lh-16 lh-xs-inherit">
              Items sent to the wrong address cannot be recovered.
            </div>
          </div>
          <div className="btn-detail-nft button m-0 h-50px h-xs-40px" onClick={handleConfirmModal}>
            {isLoading ? <CircularProgress size={30} color={'inherit'} /> : <>Send</>}
          </div>
        </div>
      </div>
    </ReactModal>
  );
};
export default TransferItem;
