import ReactModal from 'react-modal';
import close_icon from '../../assets/icon/close_icon.svg';
import close_icon_black from '../../assets/icon/close_icon_black.svg';
import talkKlaytn from '../../assets/icon/talkKlaytn.svg';
import { useThemeMode } from 'components/common/AppStoreType';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as Link } from '../../assets/icon/link.svg';
import { ReactComponent as IconClose } from '../../assets/icon/close_icon.svg';
import { getPrice } from '../../utils/getPrice';

type ClaimRewardsSuccess = {
  show: any;
  onHide: any;
  claimableAmount: any;
};

const ClaimRewardsSuccess: React.FC<ClaimRewardsSuccess> = ({ show, onHide, claimableAmount }) => {
  const { isDarkMode } = useThemeMode();
  const isMobile = useMediaQuery({
    query: '(max-width: 641px)',
  });
  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className="otp-code-modal otp-code-modal-custom-claim-success">
        <div className="close-button" onClick={onHide}>
          <IconClose className="w-100 h-100 close-icon dark:close-icon" />
        </div>
        <div className="header-otp">Successful</div>
        <div className="line-banner"></div>
        <div className="body-otp pt-0">
          <div className="p-b-xs-10 title-input-otp text-center text-white opacity-7 fs-14 fw-6 m-b-16">
            Claimed Rewards
          </div>
          <div className="wrapper-total-claim wrapper-total-claim-custom m-b-8">
            {isMobile ? (
              <img width={32} height={32} src={talkKlaytn} alt="" />
            ) : (
              <img width={32} height={32} src={talkKlaytn} alt="" />
            )}
            <div className="fs-24 fw-7">{claimableAmount ? getPrice(claimableAmount, '') : 0}</div>
          </div>
          <div className="fs-12 fw-400 text-center opacity-7">
            Congratulations. It may take up to 5 minutes to complete the transfer.
          </div>
          <button className="button m-t-24 m-b-8 btn-detail-nft" onClick={onHide}>
            Confirm
          </button>
        </div>
      </div>
    </ReactModal>
  );
};
export default ClaimRewardsSuccess;
