import { useWeb3React } from '@web3-react/core';
import getAbcWallet from './getAbcWallet';

export const getActiveWallet = () => {
  const { account, library } = useWeb3React();
  const { isAbcReady, abcAddress } = getAbcWallet();

  const login = window.localStorage.getItem('login');
  if (login === 'sns') {
    return abcAddress;
  } else if ((login === 'metamask' || login === 'walletconnector') && library) {
    return account;
  }
  return '';
};
