import { customAxios, apiAuthAxios } from './customAxios';
import queryString from 'query-string';
import { AbcSnsAddUserDto } from '../abc/main/abc/interface';

export const getAccount = async (token: string) => {
  return await customAxios.get('/api/service/profile/@me', {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateAccount = async (address: string, data: any) => {
  const token = window.localStorage.getItem('nftapiJwtToken');
  return await customAxios.post(`/api/service/profile/${address}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getEventList = async () => {
  return await customAxios.get('/api/service/featured?page=1&limit=5&isEvent=true');
};

export const getFeaturedList = async () => {
  return await customAxios.get('/api/service/featured?page=1&limit=5');
};

export const getFeaturedCollectionsInfi = async (props) => {
  return await customAxios.get(
    `/api/service/featured?page=${props?.page}&limit=100${props?.keyword ? `&keyword=${props?.keyword}` : ''}${
      props?.sortBy ? `&sortBy=${props?.sortBy}` : ''
    }`
  );
};
export const getFeaturedCollections = async (limit: number) => {
  return await customAxios.get(`/api/service/featured?page=1&limit=${limit}`);
};

export const getFeaturedCollectionsInfinite = async (page: number) => {
  return await customAxios.get(`/api/service/featured?page=${page}&limit=8`);
};

export const getFeaturedById = async (id: string) => {
  return await customAxios.get(`/api/service/featured/${id}`);
};

export const getCollectionByContractAddress = async (contractAddress: string) => {
  return await customAxios.get(`/api/service/collections/getCollection?contractAddress=${contractAddress}`);
};

export const getMysteryBoxInfo = async (id: string) => {
  return await customAxios.get(`/api/service/mysterybox/${id}`);
};

export const getCollectionInfo = async (id: string) => {
  return await customAxios.get(`/api/service/mysterybox/${id}`);
};

export const getMboxListByFeaturedId = async (id: string) => {
  return await customAxios.get(`/api/service/featured/${id}/mysterybox?page=1&limit=50`);
};
export const getCollectibleListInfi = async (props) => {
  return await customAxios.get(
    `/api/service/collectibles?page=${props?.page}&limit=5${props?.keyword ? `&keyword=${props?.keyword}` : ''}${
      props?.sortBy ? `&sortBy=${props?.sortBy}` : ''
    }`
  );
};
export const getCollectibleList = async () => {
  return await customAxios.get(`/api/service/collectibles`);
};

export const getCollectionList = async (isCollection?: boolean) => {
  return await customAxios.get(`/api/service/mysterybox?page=1&limit=100${
    isCollection ? `&isCollection=${isCollection}` : ''
  }
`);
};

export const getCollectionListInfi = async (props) => {
  return await customAxios.get(`/api/service/mysterybox?page=${props?.page}&limit=${props?.limit}${
    props?.isCollection ? `&isCollection=${props?.isCollection}` : ''
  }${props?.keyword ? `&keyword=${props?.keyword}` : ''}${props?.sortBy ? `&sortBy=${props?.sortBy}` : ''}
`);
};
export const getAirdropListInfi = async (props) => {
  return await customAxios.get(
    `/api/service/mysterybox/airdrops?page=${props?.page}&limit=5${
      props?.isCollection ? `&isCollection=${props?.isCollection}` : ''
    }${props?.keyword ? `&keyword=${props?.keyword}` : ''}${props?.sortBy ? `&sortBy=${props?.sortBy}` : ''}`
  );
};
export const getAirdropList = async () => {
  return await customAxios.get(`/api/service/mysterybox/airdrops?page=1&limit=5`);
};

export const getMboxItemListMboxId = async (id: string) => {
  return await customAxios.get(`/api/service/mysterybox/${id}/items?page=1&limit=100
`);
};

export const registerBuy = async (data: any) => {
  const token = window.localStorage.getItem('nftapiJwtToken');
  return await apiAuthAxios.post('/api/service/drops', data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const verifyReceiptIos = async (data: any) => {
  return await customAxios.post('/api/iap/verifyReceiptIos', data);
};

export const verifyReceiptAos = async (data: any) => {
  return await customAxios.post('/api/iap/verifyReceiptAos', data);
};

export const getMyMBoxList = async (uid: string, sort: string) => {
  return await customAxios.get(`/api/service/drops?buyer=${uid}&sortBy=createdAt:${sort}`);
};

export const getMyDbOnlyList = async (uid: string, sort: string) => {
  return await customAxios.get(`/api/service/drops?buyer=${uid}&sortBy=createdAt:${sort}&dbOnly=true`);
};

export const getItemPrice = async (metaLink: string) => {
  return await customAxios.get(`/api/service/mysterybox/uri-items?uri=${metaLink}`);
};

export const getClaimableCount = async (mysteryBoxId: number, buyer: string) => {
  return await customAxios.get(`/api/service/drops/claimableCount?mysteryBoxId=${mysteryBoxId}&buyer=${buyer}`);
};

export const getFreeDroppedCount = async (mysteryBoxId: number, buyer: string) => {
  return await customAxios.get(`/api/service/drops/freeDroppedCount?mysteryBoxId=${mysteryBoxId}&buyer=${buyer}`);
};

export const getHistory = async (buyer: string) => {
  return await customAxios.get(`/api/service/drops/history?buyer=${buyer}&limit=100`);
};

export const getItemHistory = async (buyer: string) => {
  return await customAxios.get(`/api/service/histories/${buyer}`);
};

export const requestClaim = async (data: any) => {
  const token = window.localStorage.getItem('nftapiJwtToken');
  return await customAxios.post('/api/service/drops/requestClaim', data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getRewardsClaim = async (uid: string) => {
  return await customAxios.get(`/api/service/drops/getRewardsClaim?uid=${uid}`);
};

export const getRewardsSent = async (uid: string) => {
  return await customAxios.get(`/api/service/drops/getRewardsSent?uid=${uid}`);
};

export const requestRewardClaim = async (data: any) => {
  return await customAxios.post('/api/service/drops/requestRewardClaim', data);
};

export const buyKeyCredit = async (data: any) => {
  const token = window.localStorage.getItem('nftapiJwtToken');
  return await customAxios.post('/api/service/drops/buyKeyCredit', data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getRandomItemListByCompanyId = async (companyId: string) => {
  return await customAxios.get(`/api/service/company/${companyId}/random-items`);
};

export const nicknameDuplicateCheck = async (nickname: string) => {
  return await customAxios.get(`/api/users/name/duplicate?name=${nickname}`);
};

export const dropsUsernameDuplicateCheck = async (nickname: string) => {
  const token = window.localStorage.getItem('nftapiJwtToken');
  return await customAxios.get(`/api/users/name/duplicate?name=${nickname}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const usernameDuplicateCheck = async (data: any) => {
  return await apiAuthAxios.post(`/auth/nameDuplicateCheck`, data);
};

export const refererUidCheck = async (data: any) => {
  return await apiAuthAxios.post(`/auth/refererUidCheck`, data);
};

export const getSession = async () => {
  return await apiAuthAxios.get('/auth/getSession');
};

export const userRegister = async (data: any) => {
  return await apiAuthAxios.post('/auth/register', data);
};

export const abcLogin = async (data: any) => {
  return await customAxios.post('/api/abc/login', data);
};

export const abcRefresh = async (data: any) => {
  return await customAxios.post('/api/abc/refresh', data);
};

export const abcJoin = async (dto: AbcSnsAddUserDto) => {
  return await customAxios.post(`api/abc/join`, {
    data: queryString.stringify({
      ...dto,
      serviceid: 'https://mw.myabcwallet.com',
    }),
  });
};

export const getUser = async () => {
  return await apiAuthAxios.get('/api/users/getUser');
};

export const updateUser = async (uid: string, data: any) => {
  const token = window.localStorage.getItem('nftapiJwtToken');
  return await customAxios.post(`/api/service/profile/userUpdate/${uid}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateAddress = async (data: any) => {
  const token = window.localStorage.getItem('nftapiJwtToken');
  return await customAxios.post('/api/service/profile/addressUpdate', data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteAddress = async (data: any) => {
  const token = window.localStorage.getItem('nftapiJwtToken');
  return await customAxios.post('/api/service/profile/addressDelete', data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const requestWalletLogin = async (data: any) => {
  return await apiAuthAxios.post('/auth/walletLogin?isWalletLogin=true', data);
};

export const requestNativeLogin = async (data: any) => {
  return await apiAuthAxios.post('/auth/talkenNative', data);
};

export const requestSetIsRevealed = async (data: any) => {
  return await customAxios.patch('/api/service/drops/isRevealed', data);
};

export const registerSell = async (data: any) => {
  return await apiAuthAxios.post('/api/service/sellbook', data);
};

export const getSellbookInfoByUID = async (uid: string) => {
  return await customAxios(`/api/service/sellbook/mine/${uid}`);
};

export const updateAbcAddress = async (address: string) => await apiAuthAxios.post(`api/users/abc/${address}`);

export const getSellBooks = async (page: number, perPage?: number, category?: string, type?: string, team?: string) => {
  let url = `/api/service/sellbook?page=${page}&limit=${perPage ? perPage : 1000}&sortBy=createdAt:DESC`;

  if (category && category.toLowerCase() !== 'all') url = `${url}&category=${category}`;
  if (type && type !== '0') url = `${url}&type=${type}`;
  if (team && team.toLowerCase() !== 'default') url = `${url}&team=${team}`;

  return await customAxios.get(url);
};

export const cancelSell = async (id: any, uid: any) => {
  return await apiAuthAxios.delete(`/api/service/sellbook/${id}/${uid}`);
};

export const getSellbook = async (id: any) => {
  return await apiAuthAxios.get(`/api/service/sellbook/${id}`);
};

export const registerBidOffer = async (data: any) => {
  return await apiAuthAxios.post('/api/service/bid', data);
};

export const getBidListBySellbookId = async (id: string) => {
  return await customAxios.get(`/api/service/bid/${id}`);
};

export const clearBids = async (id: any) => {
  return await apiAuthAxios.delete(`/api/service/bid/clear/${id}`);
};

export const deleteBid = async (id: any) => {
  return await apiAuthAxios.delete(`/api/service/bid/${id}`);
};

export const geMyHistories = async (uid: string) => {
  return await customAxios.get(`/api/service/histories/${uid}`);
};

export const registerHistory = async (data: any) => {
  return await apiAuthAxios.post('/api/service/histories', data);
};

export const getUserById = async (uid: string) => await apiAuthAxios.get(`/api/users/getUser/${uid}`);
