import ReactModal from 'react-modal';
import close_icon from '../../assets/icon/close_icon.svg';
import close_icon_black from '../../assets/icon/close_icon_black.svg';
import mockLogo from 'assets/svg/rare_logo.svg';
import { useThemeMode } from 'components/common/AppStoreType';
import useScreenSize from 'components/common/useScreenSize';
import Select from 'react-select';
import { useMediaQuery } from 'react-responsive';
import { Col, Row } from 'react-bootstrap';
import HelperUtil from '../../utils/HelperUtil';
import React, { useEffect, useRef, useState } from 'react';
import { seaport } from '../../utils/seaport';
import { Web3Provider } from '@ethersproject/providers';
import { Signer } from '@colligence/seaport-js/lib/types';
import { useSelector } from 'react-redux';
import getAbcWallet from '../../utils/getAbcWallet';
import { useWeb3React } from '@web3-react/core';
import { registerSell } from '../../services/services';
import { CircularProgress, TextField } from '@mui/material';
import contracts from '../../config/constants/contracts';
import env from '../../env';
import { getActiveWallet } from '../../utils/getActiveWallet';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import { DateRange } from 'react-date-range';

import format from 'date-fns/format';
import { addDays } from 'date-fns';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import dayjs from 'dayjs';
import { DateTimeField, MobileDateTimePicker } from '@mui/x-date-pickers';
import { setupNetwork } from '../../utils/wallet';

type ListNFTModal = {
  show: any;
  onHide: any;
  typeSale: any;
  onListingComplete: any;
  data: any;
};
const optionsTypeMoneyAuction = [{ value: 'talk', label: 'TALK' }];
const optionsTypeMoneyFixed = {
  ['ethereum']: [
    { value: 'eth', label: 'ETH' },
    { value: 'talk', label: 'TALK' },
  ],
  ['polygon']: [
    { value: 'matic', label: 'MATIC' },
    { value: 'talk', label: 'TALK' },
  ],
  ['klaytn']: [
    { value: 'klay', label: 'KLAY' },
    { value: 'talk', label: 'TALK' },
  ],
  ['binance']: [
    { value: 'bnb', label: 'BNB' },
    { value: 'talk', label: 'TALK' },
  ],
};

const optionsSaleType = [
  { value: 'fixedPrice', label: 'Fixed Price' },
  { value: 'timeAuction', label: 'Timed Auction' },
];
const today = new Date().toLocaleDateString();
const oneHour = new Date(new Date().getTime() + 3600000).toLocaleDateString();
const threeHour = new Date(new Date().getTime() + 10800000).toLocaleDateString();
const sixHour = new Date(new Date().getTime() + 21600000).toLocaleDateString();
const oneDay = new Date(new Date().getTime() + 86400000).toLocaleDateString();
const threeDays = new Date(new Date().getTime() + 259200000).toLocaleDateString();
const sevenDays = new Date(new Date().getTime() + 604800000).toLocaleDateString();
const oneMonth = new Date(new Date().getTime() + 2592000000).toLocaleDateString();
const threeMonths = new Date(new Date().getTime() + 7776000000).toLocaleDateString();
const sixMonths = new Date(new Date().getTime() + 15552000000).toLocaleDateString();

const oneHourEpoch = new Date().getTime() + 3600000;
const sixHourEpoch = new Date().getTime() + 21600000;
const oneDayEpoch = new Date().getTime() + 86400000;
const threeDaysEpoch = new Date().getTime() + 259200000;
const sevenDaysEpoch = new Date().getTime() + 604800000;
const oneMonthEpoch = new Date().getTime() + 2592000000;
const threeMonthsEpoch = new Date().getTime() + 7776000000;
const sixMonthsEpoch = new Date().getTime() + 15552000000;

const optionsExpiration = [
  { value: 'oneHour', epoch: oneHourEpoch, label: `1 hour (${today} ~ ${oneHour})` },
  { value: 'sixHour', epoch: sixHourEpoch, label: `6 hour (${today} ~ ${sixHour})` },
  { value: 'oneDay', epoch: oneDayEpoch, label: `1 day (${today} ~ ${oneDay})` },
  { value: 'threeDays', epoch: threeDaysEpoch, label: `3 days (${today} ~ ${threeDays})` },
  { value: 'sevenDays', epoch: sevenDaysEpoch, label: `7 days (${today} ~ ${sevenDays})` },
  { value: 'oneMonth', epoch: oneMonthEpoch, label: `1 Month (${today} ~ ${oneMonth})` },
  { value: 'threeMonths', epoch: threeMonthsEpoch, label: `3 Months (${today} ~ ${threeMonths})` },
  { value: 'sixMonths', epoch: sixMonthsEpoch, label: `6 Months (${today} ~ ${sixMonths})` },
  { value: 'custom', epoch: '', label: 'Custom' },
];
const optionsDatePicker = [
  { value: 'custom', label: `Custom` },
  { value: 'oneHour', label: `1 hour` },
  { value: 'threeHour', label: `3 hour` },
  { value: 'sixHour', label: `6 hour` },
  { value: 'oneDay', label: `1 day` },
  { value: 'threeDays', label: `3 days` },
  { value: 'sevenDays', label: `7 days` },
  { value: 'oneMonth', label: `1 month` },
  { value: 'threeMonths', label: `3 months` },
  { value: 'sixMonths', label: `6 months` },
];
const ListNFTModal: React.FC<ListNFTModal> = ({ show, onHide, typeSale, onListingComplete, data }) => {
  const { isDarkMode } = useThemeMode();
  const [checkDisable, setCheckDisable] = useState(true);
  const [saleType, setSaleType] = useState('fixedPrice');
  const { account, chainId, library } = useActiveWeb3React();
  const user = useSelector((state: any) => state.user.user);
  const uid = user.uid;
  const { isAbcReady, abcAddress } = getAbcWallet();
  const myWallet = getActiveWallet();
  const login = window.localStorage.getItem('login');

  const [minIncrement, setMinIncrement] = useState(0);
  const [startPrice, setStartPrice] = useState(0);
  const [quote, setQuote] = useState(optionsTypeMoneyFixed[data.chain][0].value);
  const [expiration, setExpiration] = useState('oneMonth');
  const [datePicker, setDatePicker] = useState('');
  const [endDate, setEndDate] = useState(oneMonthEpoch);
  const [startDate, setStartDate] = useState(new Date());
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(false);
  const [formatDateTimeField, setFormatDateTimeField] = useState('LLL');
  const [valueMinDateTime, setValueMinDateTime] = React.useState(dayjs().add(1, 'hour'));
  const [valueDateTimePickerDefault, setValueDateTimePickerDefault] = React.useState(
    dayjs().add(1, 'month').subtract(1, 'minute')
  );
  const [valueDateTimePicker, setValueDateTimePicker] = React.useState(dayjs().add(1, 'month').subtract(1, 'minute'));
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);
  const formatDateTime = (date) => {
    return format(date, 'MM/dd/yyyy hh:mm a');
  };
  // open close
  const [open, setOpen] = useState(false);

  // get the target element to toggle
  const refOne = useRef(null);

  const getTargetChainId = () => {
    switch (data.chain) {
      case 'ethereum':
        return env.REACT_APP_TARGET_NETWORK_ETH;
      case 'binance':
        return env.REACT_APP_TARGET_NETWORK_BNB;
      case 'polygon':
        return env.REACT_APP_TARGET_NETWORK_MATIC;
      case 'klaytn':
      default:
        return env.REACT_APP_TARGET_NETWORK_KLAY;
    }
  };
  const [target, setTarget] = useState(getTargetChainId());
  useEffect(() => {
    const targetChain = getTargetChainId();
    setTarget(targetChain);
  }, [data]);

  const switchNetwork = async () => {
    if (login !== 'sns') {
      await setupNetwork(target, true, onHide);
    }
  };
  useEffect(() => {
    switchNetwork();
  }, [target]);

  const makeOrderHandler = async (
    provider: Web3Provider | Signer,
    contract: string,
    tokenId: string,
    itemType: number,
    creator?: string
  ) => {
    let sellData = null;
    try {
      console.log('!! start make order ...');
      console.log('Address:::', myWallet);
      console.log('Provider:::', provider);

      let order;
      try {
        // const chain = login !== 'sns' ? chainId : target;
        const chain = target;
        let quoteAddress = null;
        if (quote.toUpperCase() === 'TALK') {
          quoteAddress = contracts.talken[chain];
        }

        order = await seaport.createOrder({
          signer: provider,
          tokenContractAddress: contract,
          tokenId: tokenId.toString(),
          itemType,
          price: startPrice.toString(),
          quoteContractAddress: quoteAddress,
          endTime: Math.floor(endDate / 1000).toString(), // about one month
          creatorAddress: creator,
          quote,
        });
        setOrder(order);

        if (order && typeof order === 'object' && order?.parameters) {
          // TODO : Save order into DB
          const sellOrder = {
            uid: user.uid,
            ethAddress: myWallet,
            type: saleType === 'fixedPrice' ? 1 : 2,
            tokenId,
            sellInfo: order,
            price: startPrice,
            startDate: startDate,
            endDate: endDate,
            creatorFee: 7.5,
            minInc: saleType === 'fixedPrice' ? 0 : minIncrement,
            nftInfo: data, //프론트 accountTokens 배열의 item을 통째로
            quote,
            chainId: chain,
          };
          console.log('!! sellOrder for DB = ', sellOrder);

          const result = await registerSell(sellOrder);
          console.log('!! Sell Result : ', result);
          sellData = result.data.data;
          if (result.status === 200) {
            console.log('... success !!');
          } else {
            console.log('... Failed !!');
          }
        } else {
          return order;
        }
      } catch (e: any) {
        console.log('!!!!! create order error', e.message);
      }
    } catch (err: any) {
      console.log(err.message);
      alert(err.message);
    } finally {
      setLoading(false);
    }
    return sellData;
  };

  const handleSetSale = async () => {
    const valueMinDateTimeMs = valueMinDateTime.valueOf();
    const valueDateTimePickerMs = valueDateTimePicker.valueOf();
    console.log('valueDateTimePickerMs - valueMinDateTimeMs', valueDateTimePickerMs - valueMinDateTimeMs);
    if (valueDateTimePickerMs <= valueMinDateTimeMs) {
      alert('The time period must be greater than 1 hour');
      return;
    }
    if (
      startPrice?.toString()?.trim() === '' ||
      startPrice?.toString()?.trim() === undefined ||
      startPrice?.toString()?.includes(',') ||
      isNaN(startPrice)
    ) {
      alert('Invalid price. Please check again.');
      return;
    }
    if (saleType == 'fixedPrice' && Number(startPrice) <= 0) {
      alert('Price must be > 0');
    } else if (saleType == 'timeAuction' && (Number(startPrice) <= 0 || Number(minIncrement) <= 0)) {
      alert('Price and Bid Increments must be > 0');
    } else {
      setLoading(true);

      console.log('!! Start sale : ', { saleType, startPrice, quote, minIncrement, expiration, data });
      let provider = null;
      if (login === 'sns') {
        provider = await HelperUtil.getAbcWeb3Provider(target);
        // console.log('provider:::', provider, provider?.provider.chainId);
      }

      // TODO : console.log all selected and input values here...
      const tokenId = data.chain === 'klaytn' ? parseInt(data.extras?.tokenId, 16).toString() : data.token_id;
      const sellData = await makeOrderHandler(
        login === 'sns' ? provider : library.getSigner(),
        data.contractAddress,
        tokenId,
        data?.contract_type === 'ERC1155' ? 3 : 2,
        data?.creatorAddress
      );
      console.log('selldata:::', sellData);
      if (!sellData) return;
      if (sellData === 'Incorrect twofactor verify token') return alert('Incorrect twofactor verify token');
      if (sellData === 'MetaMask Tx Signature: User denied transaction signature.')
        return alert('User denied transaction signature.');
      if (sellData === 'MetaMask Typed Message Signature: User denied message signature.')
        return alert('User denied message signature.');
      if (typeof sellData !== 'object' && sellData.includes('call revert exception'))
        return alert('call revert exception.');
      if (typeof sellData !== 'object' && sellData.includes('missing revert data in call exception'))
        return alert('missing revert data in call exception.');
      if (typeof sellData !== 'object' && sellData.includes('transaction underpriced'))
        return alert('transaction underpriced.');
      if (typeof sellData !== 'object' && sellData.includes('Invalid unsigned tx to hash for mpc sign'))
        return alert('Invalid unsigned tx to hash for mpc sign.');
      if (typeof sellData !== 'object' && sellData.includes('insufficient funds for intrinsic transaction cost'))
        return alert('insufficient funds for intrinsic transaction cost.');

      onHide();
      onListingComplete(sellData);
    }
  };
  const screenSize = useScreenSize();
  const isMobile = useMediaQuery({
    query: '(max-width: 641px)',
  });
  const stylesTypeMoney = {
    control: (base, state) => ({
      ...base,
      background: isDarkMode ? '#202052' : '#FFFFFF1A',
      borderRadius: isMobile ? '4px' : '4px',
      borderColor: state.isFocused ? '#2F8AF5' : '#525298',
      boxShadow: state.isFocused ? 'none' : 'none',
      fontSize: isMobile ? '9px' : '12px',
      fontWeight: 500,
      width: isMobile ? '62px' : '80px',
      height: isMobile ? '28px' : '34px',
      minHeight: isMobile ? '28px' : '34px',
      cursor: 'pointer',
    }),
    singleValue: (base, state) => ({
      ...base,
      color: state.selectProps.menuIsOpen ? '#2F8AF5' : isDarkMode ? '#525298' : '#000000',
      marginLeft: isMobile ? '2px' : '8px',
    }),
    valueContainer: (base) => ({
      ...base,
      height: '100%',
      placeContent: 'center',
      marginLeft: isMobile ? '2px' : '2px',
      padding: isMobile ? '2px 0px' : '',
    }),
    option: (base, state) => ({
      ...base,
      fontSize: isMobile ? '9px' : '12px',
      fontWeight: 500,
      width: isMobile ? '62px' : '80px',
      color: state.isSelected ? '#2F8AF5' : '#777777',
      backgroundColor: state.isSelected ? '#F0F5FF' : '#FFFFFF',
      ':active': {
        // backgroundColor: state.isSelected ? '#FFFFFF' : '#FFFFFF',
      },
      ':hover': {
        backgroundColor: '#2F8AF5',
        color: '#FFFFFF',
      },
      cursor: 'pointer',
    }),

    menu: (base) => ({
      ...base,
      fontSize: isMobile ? '9px' : '12px',
      background: '#FFFFFF',
      // override border radius to match the box
      borderRadius: isMobile ? '4px' : '4px',
      // kill the gap
      marginTop: 0,
      width: isMobile ? '62px' : '80px',
    }),
    menuList: (base) => ({
      ...base,
      fontSize: '12px',
      // kill the white space on last option
      padding: '0',
      width: isMobile ? '62px' : '80px',
      borderRadius: isMobile ? '4px' : '4px',
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: state.selectProps.menuIsOpen ? '#2F8AF5' : isDarkMode ? '#525298' : '#000000',
      placeContent: 'center',
      padding: isMobile ? '4px' : '6px',
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      width: isMobile ? '24px' : '28px',
    }),
  };
  const stylesPayType = {
    control: (base, state) => ({
      ...base,
      background: isDarkMode ? '#202052' : '#FFFFFF1A',
      borderRadius: isMobile ? '6px' : '10px',
      borderColor: state.isFocused ? '#2F8AF5' : '#525298',
      boxShadow: state.isFocused ? 'none' : 'none',
      fontSize: isMobile ? '11px' : '16px',
      fontWeight: 500,
      width: '100%',
      height: isMobile ? '32px' : '50px',
      minHeight: isMobile ? '32px' : '38px',
      cursor: 'pointer',
    }),
    singleValue: (base, state) => ({
      ...base,
      color: state.selectProps.menuIsOpen ? '#2F8AF5' : isDarkMode ? '#2F8AF5' : '#000000',
    }),
    valueContainer: (base) => ({
      ...base,
      height: '100%',
      padding: isMobile ? '8px' : '2px 8px',
    }),
    option: (base, state) => ({
      ...base,
      fontSize: isMobile ? '11px' : '16px',
      fontWeight: 500,
      width: '100%',
      color: state.isSelected ? '#2F8AF5' : '#777777',
      backgroundColor: state.isSelected ? '#202052' : '#202052',
      ':hover': {
        backgroundColor: '#303073',
        color: '#FFFFFF',
      },
      cursor: 'pointer',
    }),

    menu: (base) => ({
      ...base,
      fontSize: isMobile ? '11px' : '14px',
      background: '#202052',
      borderRadius: isMobile ? '6px' : '10px',
      border: '1px solid #2F8AF5',
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      fontSize: '12px',
      padding: '0',
      borderRadius: isMobile ? '6px' : '10px',
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: state.selectProps.menuIsOpen ? '#2F8AF5' : isDarkMode ? '#525298' : '#000000',
      placeContent: 'center',
      padding: isMobile ? '4px' : '8px',
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
  };
  const handleSaleType = (selected) => {
    setSaleType(selected.value);
    handleQuote(selected.value === 'fixedPrice' ? optionsTypeMoneyFixed[data.chain][0] : optionsTypeMoneyAuction[0]);
  };
  const checkDisableButton = () => {
    if (saleType === 'fixedPrice') {
      if (expiration === '') {
        setCheckDisable(true);
      } else {
        setCheckDisable(false);
      }
    } else {
      if (
        minIncrement?.toString()?.trim() === '' ||
        minIncrement?.toString()?.trim() === undefined ||
        expiration === ''
      ) {
        setCheckDisable(true);
      } else {
        setCheckDisable(false);
      }
    }
  };
  const handleMinIncrement = (event) => {
    setMinIncrement(event.target.value);
  };

  const handleStartPrice = (event) => {
    setStartPrice(event.target.value);
  };

  const handleQuote = (selected) => {
    setQuote(selected.value);
  };

  const handleExpiration = (selected) => {
    if (selected.value == 'custom') {
      setValueMinDateTime(dayjs().add(1, 'hour'));
    }
    setExpiration(selected.value);
    setEndDate(selected.epoch);
  };
  const handleDateTimeChange = (newDateTime) => {
    console.log('New DateTime:', newDateTime);
    const newDateTimeWithoutSeconds = dayjs(newDateTime).startOf('minute');
    const newDateTimeInMs = newDateTimeWithoutSeconds.valueOf();
    setValueMinDateTime(dayjs().add(1, 'hour'));
    setValueDateTimePicker(newDateTime);
    setEndDate(newDateTimeInMs);
  };
  const handleClickDateTimeField = () => {
    console.log('hahahahahahah');
  };
  const handleFormat1 = () => {
    setFormatDateTimeField('DD/MM/YYYY hh:mm A');
  };
  const handleFormat2 = () => {
    setFormatDateTimeField('LLL');
  };
  useEffect(() => {
    checkDisableButton();
    console.log('heheh', checkDisable);
  }, [saleType, startPrice, minIncrement, expiration, quote]);
  const minDateTime = new Date(new Date().getTime() + 1800000).toLocaleDateString();
  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className="list-nft-modal" style={{ maxHeight: expiration === 'custom' ? '631px' : '533px' }}>
        <div className="close-button" onClick={onHide}>
          {isDarkMode ? <img src={close_icon} alt="icon close" /> : <img src={close_icon_black} alt="icon close" />}
        </div>
        <div className="title-nft line-clamp-1">
          {data?.metadata?.name ? data?.metadata?.name : typeof data?.name === 'string' ? data?.name : data?.name?.en}
        </div>
        <div className="info-creator">
          {/* <img src={data?.companyimage} alt="logo" className="logo-img" /> */}
          <div className="logo-info">
            {/* <div className="creator">Creator</div> */}
            <div className="name">{data?.companyname?.en}</div>
          </div>
        </div>
        <div className="line-banner"></div>
        {/*sale type*/}
        <div className="title-set-price">Sale Type</div>
        <div className={`${screenSize < 641 ? 'mb-1' : 'mb-4'}`}>
          <Select
            onChange={handleSaleType}
            options={optionsSaleType}
            styles={stylesPayType}
            value={optionsSaleType.filter(function (option) {
              return option.value === saleType;
            })}
            placeholder={'Select Pay type'}
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
        {/*sale type*/}
        <div className="title-set-price">Set a Price</div>
        <Row className="d-flex">
          <Col xs={saleType == 'timeAuction' && screenSize > 639 ? '6' : '12'}>
            <div className="title-select fs-12 p-b-4">{saleType === 'timeAuction' ? 'Starting Price' : 'Price'}</div>
            <div className="wrapper-set-price">
              <input
                type="text"
                placeholder="Amount"
                onChange={handleStartPrice}
                className="input-start-price"
                value={startPrice}
              />
              <div className="position-absolute top-8px right-8px top-xs-2px right-xs-4px">
                <Select
                  onChange={handleQuote}
                  options={saleType === 'fixedPrice' ? optionsTypeMoneyFixed[data.chain] : optionsTypeMoneyAuction}
                  styles={stylesTypeMoney}
                  value={optionsTypeMoneyAuction
                    .concat(optionsTypeMoneyFixed[data.chain])
                    .find((option) => option.value === quote)}
                  isSearchable={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </Col>
          {saleType == 'timeAuction' && (
            <Col>
              <div className={`title-select fs-12 p-b-6 ${screenSize < 639 ? 'p-t-8' : ''}`}>
                Minimum Bid Increments
              </div>
              <div className="wrapper-set-price">
                <input type="text" placeholder="Amount" onChange={handleMinIncrement} value={minIncrement} />
                {/*<div className="position-absolute top-0 end-0">*/}
                {/*  <Select*/}
                {/*    options={optionsTypeMoney}*/}
                {/*    styles={stylesTypeMoney}*/}
                {/*    components={{*/}
                {/*      IndicatorSeparator: () => null,*/}
                {/*    }}*/}
                {/*    isSearchable={false}*/}
                {/*  />*/}
                {/*</div>*/}
              </div>
            </Col>
          )}
        </Row>
        {/*sale type*/}
        <div className="title-set-price">Duration</div>
        <div
          className={`justify-content-between ${
            isMobile ? (expiration === 'custom' ? 'm-b-xs-0' : 'm-b-xs-32') : expiration === 'custom' ? 'mb-3' : 'mb-3'
          }`}
        >
          <Select
            menuPosition="fixed"
            onChange={handleExpiration}
            options={optionsExpiration}
            styles={stylesPayType}
            isSearchable={false}
            value={optionsExpiration.filter(function (option) {
              return option.value === expiration;
            })}
            placeholder={'Select Pay type'}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
          {/*<Select*/}
          {/*  // onChange={handleExpiration}*/}
          {/*  options={optionsDatePicker}*/}
          {/*  styles={styleDatePicker}*/}
          {/*  isSearchable={false}*/}
          {/*  value={optionsDatePicker.filter(function (option) {*/}
          {/*    return option.value === datePicker;*/}
          {/*  })}*/}
          {/*  placeholder={'Custom'}*/}
          {/*  components={{*/}
          {/*    IndicatorSeparator: () => null,*/}
          {/*  }}*/}
          {/*/>*/}
        </div>
        {expiration == 'custom' && (
          // <div className={`d-flex justify-content-between ${isMobile ? 'mb-3' : ''}`}>
          //   <div className="pt-0">
          //     <LocalizationProvider dateAdapter={AdapterDayjs}>
          //       <DemoContainer components={['DateTimeField']}>
          //         <DateTimeField
          //           // className="calendarElement"
          //           format="DD/MM/YYYY hh:mm A"
          //           value={dayjs()}
          //           readOnly={true}
          //         />
          //       </DemoContainer>
          //     </LocalizationProvider>
          //   </div>
          //   <div className={`${isMobile ? 'pt-1' : 'pt-3'}`}>~</div>
          <div className={`${isMobile ? 'pb-3' : ''}`}>
            <div className={`${isMobile ? 'title-set-price fs-14' : 'title-set-price fs-14'}`}>
              Listing expiration date
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <DemoContainer components={['MobileDateTimePicker']}>
                <DemoItem>
                  <MobileDateTimePicker
                    minDateTime={valueMinDateTime}
                    format="DD/MM/YYYY hh:mm A"
                    defaultValue={valueDateTimePickerDefault}
                    onChange={handleDateTimeChange}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </div>
          // </div>
        )}

        {/* Fees */}
        {/* <div className="title-set-price">Fees</div>
        <div className={`wrapper-bidding-period ${screenSize < 641 ? 'pt-12' : ''}`}>
          <div className="bidding-period-item">
            <div className="title-bidding-period">Listing Price</div>
            <div className="detailed-bidding-period">0.5%</div>
          </div>
          <div className="bidding-period-item">
            <div className="title-bidding-period">Creator Royalty</div>
            <div className="detailed-bidding-period">20%</div>
          </div>
          <div className="bidding-period-item">
            <div className="title-bidding-period">Service Fee</div>
            <div className="detailed-bidding-period">000 KLAY</div>
          </div>
        </div>
        <div className={`line-banner ${screenSize < 641 ? 'my-3' : 'my-4'}`}></div> */}
        {/* current price */}
        {/* <div className="wrapper-bidding-period">
          <div className="bidding-period-item">
            <div className="title-bidding-period">Total Potential Earnings</div>
            <div className="detailed-bidding-period">2,700 KLAY</div>
          </div>
        </div> */}

        <button
          className="btn-detail-nft mb-0 button h-50px h-xs-40px btn-disable"
          onClick={handleSetSale}
          disabled={loading || checkDisable}
        >
          {loading ? <CircularProgress size={30} color={'inherit'} /> : 'Complete Listing'}
        </button>
      </div>
    </ReactModal>
  );
};
export default ListNFTModal;
