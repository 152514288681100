import React, { MutableRefObject, useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import ic_send_to_my_wallet from '../../assets/svg/send_my_wallet_icon.svg';
import gift_token_icon from '../../assets/svg/gift_token_icon.svg';
import ic_dropdown from '../../assets/svg/dropdown_button_dots.svg';
import ic_authenticity from '../../assets/icon/info_blue.svg';
// import price_history_lg from '../../assets/svg/price_history_logo.svg';
// import ic_trade from '../../assets/svg/trade_icon.svg';
import ic_sell from '../../assets/svg/sell_icon.svg';
import klaytn_white from '../../assets/icon/klaytn_white.png';
import website_icon from '../../assets/icon/website_icon.svg';
import icon_discord from '../../assets/img/icon_discord.png';
import icon_twitter from '../../assets/img/icon_twitter.png';
import icon_instagram from '../../assets/img/icon_instagram.png';
import icon_share from '../../assets/img/icon_share.png';
import arrow_btn_back_dark from '../../assets/svg/arrow_btn_back_dark.svg';
import arrow_btn_back_light from '../../assets/svg/arrow_btn_back_light.svg';
import WarningForm from 'components/collectibles_modals/warning';
import SendingForm from '../../components/collectibles_modals/sending';
import SuccessForm from 'components/collectibles_modals/success';
import Popup from 'reactjs-popup';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-multi-carousel/lib/styles.css';
import {
  claimMysteryBox,
  getItemBalance,
  getItemMetadata,
  getKeyBalance,
  // getKeyMetadata,
  getTokenIds,
} from '../../utils/marketTransactions';
import ConvertUrlsToLinks from '../../utils/convertUrlsToLinks';
import {
  injected,
  kaikas,
  abc,
  // walletconnect,
  // walletconnectv2,
  // talkenwallet,
} from '../../hooks/connectors';
import Caver from 'caver-js';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import WalletConnector from 'components/auth/WalletConnector/WalletConnector';
import { getContractName, setApproveForAll } from '../../utils/transactions';
import { CircularProgress } from '@mui/material';
import CSnackbar from '../../components/common/CSnackbar';
import axios from 'axios';
import { ResRevealItemType } from '../../types/ResRevealItemType';
import { MBoxTypes } from '../../types/MBoxTypes';
import {
  checkConnectWallet,
  checkKaikas,
  checkKaikasWallet,
  getTargetNetworkName,
  getTargetWallet,
} from '../../utils/wallet';
import { useWeb3React } from '@web3-react/core';
import { useSelector } from 'react-redux';
import CountDownTimer from '../../components/TimeCounter/CountDownTimer';
import { getRarityToString } from '../../utils/getRarityToString';
import { getNetworkNameByChainId } from '../../utils/getNetworkNameByChainId';
import { getNetworkNameById } from '../../utils/getNetworkNameById';
import useOnClickOutside from 'components/common/useOnClickOutside';
import {
  getItemPrice,
  getClaimableCount,
  requestClaim,
  getFeaturedById,
  requestSetIsRevealed,
  getCollectionByContractAddress,
} from '../../services/services';
import { getPrice } from '../../utils/getPrice';
import { FeaturedTypes } from '../../types/FeaturedTypes';
import { moveToScope } from '../../utils/moveToScope';
import useCopyToClipBoard from '../../hooks/useCopyToClipboard';
import useOnClickOutsideDropdown from 'components/common/useOnClickOutside';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import GetSnsButtons from 'components/getsnsbuttons/GetSnsButtons';
import { useThemeMode } from 'components/common/AppStoreType';
import { FAILURE, SCAN_URL, SUCCESS } from '../../config';
import AuctionSaleAndPrice from 'components/auctionsaleandprice/AuctionSaleAndPrice';
import useScreenSize from 'components/common/useScreenSize';
import { Accordion } from 'react-bootstrap';
import icon_properties from '../../assets/svg/icon_properties.svg';
import icon_properties_black from '../../assets/svg/icon_properties_black.svg';
import icon_details from '../../assets/svg/icon_details.svg';
import icon_details_black from '../../assets/svg/icon_details_black.svg';
import arrow_up_right from '../../assets/icon/arrow_up_right.png';
import arrow_up_right_black from '../../assets/icon/arrow_up_right_black.png';
import iconTest from 'assets/img/home_10_avt.jpg';
import Carousel from 'react-multi-carousel';
import ArrowCarouselItemDetails from 'components/common/ArrowCarouselItemDetails';
import getAbcWallet from '../../utils/getAbcWallet';
import HelperUtil from '../../utils/HelperUtil';
import { getActiveWallet } from '../../utils/getActiveWallet';
import talken_logo from '../../../src/talkclaim/talk.png';
import makeBlockie from 'ethereum-blockies-base64';
import splitAddress from '../../utils/splitAddress';
import env from '../../env';
import ListNFTModal from 'components/ListNFTModal/ListNFTModal';
import ListingCompleteModal from 'components/detailnftmodal/ListingCompleteModal';
import { isMobile } from 'react-device-detect';
import { HandleMediaError } from 'components/common/HandleMediaError';
const overlayStyle = { background: 'rgba(0,0,0,0.8)' };
const closeOnDocumentClick = false;
const lockScroll = true;
import icon_note from '../../assets/svg/note.svg';

type ExMBoxTypes = MBoxTypes & {
  companyimage: string;
  companyname: { ko: string; en: string };
  contractAddress?: string;
  alt_url?: string;
};
type LinkTypes = {
  type: string;
  url: string;
  useExternalUrl: boolean;
};
const MyCollectiblesDetails = () => {
  const { deactivate, account, library } = useActiveWeb3React();
  // const { isAbcReady, abcAddress } = getAbcWallet();
  // const isWalletConnected = (account && library?.connection) || (isAbcReady && abcAddress);
  const myWallet = getActiveWallet();

  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  const location = useLocation();
  const user = useSelector((state: any) => state.user.user);
  const userEthAddr = user.eth_address?.toLowerCase();
  const uid = user.uid;
  const [loginOpen, setLoginOpen] = useState(false);
  const [signupOpen, setSignupOpen] = useState(false);
  const [mboxInfo, setMboxInfo] = useState<ExMBoxTypes | null>(null);
  const [collectionName, setCollectionName] = useState<any>('');
  const [collectionInfo, setCollectionInfo] = useState<any>();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [sendingOpen, setSendingOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [isReveal, setIsReveal] = useState(false);
  const [isRevealed, setIsRevealed] = useState(false);
  const [revealItems, setRevealItems] = useState<ResRevealItemType[]>([]);
  const [balance, setBalance] = useState(0);
  const [item, setItem] = useState(0);
  const [claimableCount, setClaimableCount] = useState(0);
  const [status, setStatus] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [countDownFinish, setCountDownFinish] = useState(false);
  const [scrollPercentPosition, setScrollPercentPosition] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isKaikas, setIsKaikas] = useState(false);
  const [featuredInfo, setFeaturedInfo] = useState<FeaturedTypes | null>(null);
  const { copyToClipBoard, copyResult, setCopyResult } = useCopyToClipBoard();
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    type: '',
    message: '',
  });
  const [isOpenListNFTModal, setIsOpenNFTModdal] = useState(false);
  const [isCompleteListing, setIsCompleteListing] = useState(false);
  const [sellData, setSellData] = useState({});
  const [accordionActiveKeys, setAccordionActiveKeys] = useState(['1']);
  const [traits, setTraits] = useState([]);
  const wallet = useSelector((state: any) => state.wallet);
  const { activate } = useWeb3React();
  const { isDarkMode } = useThemeMode();
  const navigate = useNavigate();
  const arrow_btn_back = isDarkMode ? arrow_btn_back_dark : arrow_btn_back_light;
  // useOnClickOutside(ref, () => setDropdownOpen(false));

  // useEffect(() => {
  //   if (!wallet || !mboxInfo) return;
  //   const network = getTargetNetworkName(mboxInfo?.chainId) ?? '';
  //   const checkKaikas = checkKaikasWallet(wallet, network);
  //   setIsKaikas(checkKaikas);
  // }, [mboxInfo, wallet]);

  const { state } = useLocation();
  console.log('!! this is props handed over:::', state);

  const handleCloseSnackbar = () => {
    setOpenSnackbar({
      open: false,
      type: 'info',
      message: '',
    });
  };

  const handeCheckCountDownFinish = () => {
    setCountDownFinish(true);
  };

  const closeWarning = () => {
    setWarningOpen(false);
  };

  const closeSending = () => {
    setSendingOpen(false);
  };

  const closeSuccess = () => {
    setSuccessOpen(false);
  };

  const closeLogin = () => {
    setLoginOpen(false);
  };
  const closeSignup = () => {
    setSignupOpen(false);
  };

  // const handleRevealClick = async () => {
  //   setIsLoading(true);
  //
  //   try {
  //     const provider = await HelperUtil.getAbcWeb3Provider();
  //
  //     const setApprove = await setApproveForAll(
  //       mboxInfo?.keyContractAddress,
  //       mboxInfo?.boxContractAddress,
  //       account ?? abcAddress,
  //       library ?? provider,
  //       isKaikas
  //     );
  //
  //     if (!setApprove) {
  //       setIsLoading(false);
  //       throw `setApproveFailed:::${setApprove}`;
  //     }
  //     const result: number = await claimMysteryBox(
  //       mboxInfo?.boxContractAddress,
  //       balance,
  //       account ?? abcAddress,
  //       library ?? provider,
  //       isKaikas
  //     );
  //     // setOpenSnackbar({
  //     //   show: true,
  //     //   color: result === SUCCESS ? 'green' : 'red',
  //     //   message: result === SUCCESS ? 'Success Reveal.' : 'Failed Reveal',
  //     // });
  //     const mysteryboxData = {
  //       mysterybox_id: mboxInfo?.id,
  //       buyer: uid,
  //       buyer_address: userEthAddr,
  //     };
  //     if (result === SUCCESS) {
  //       await requestSetIsRevealed(mysteryboxData);
  //     }
  //     setOpenSnackbar({
  //       open: true,
  //       type: 'success',
  //       message: 'Success',
  //     });
  //     fetchBalance();
  //     setIsRevealed(true);
  //   } catch (error) {
  //     console.log(error);
  //     setOpenSnackbar({
  //       open: true,
  //       type: 'error',
  //       message: 'Failed.',
  //     });
  //     // setOpenSnackbar({ show: true, color: 'red', message: 'Failed Reveal.' });
  //     setIsLoading(false);
  //     return false;
  //   }
  //   setIsLoading(false);
  // };

  // const handleClaimClick = async () => {
  //   setIsLoading(true);
  //   try {
  //     // const talkenData = localStorage.getItem('talken.data');
  //     // let _talkenData;
  //     // let talkenUid = null,
  //     //   talkenEthAddress = null;
  //     // if (talkenData) {
  //     //   _talkenData = JSON.parse(talkenData);
  //     //   talkenUid = _talkenData.uid;
  //     //   talkenEthAddress = account?.toLowerCase();
  //     // }
  //     const target_copy = Object.assign({}, library.provider);
  //     const isAbc = target_copy.isABC === true;
  //     const isKaikas = typeof target_copy._kaikas !== 'undefined';
  //     const chainId = await web3Provider.getSigner().getChainId();
  //     const talkenEthAddress = user.eth_address?.toLowerCase();
  //     const message = `apps.talken.io wants you to sign in with your Ethereum account. Talken Drops Signature Request Type: Claim NFTs: ${claimableCount}`;
  //     const domain = { chainId }; // All properties on a domain are optional
  //     const types = { Mail: [{ name: 'contents', type: 'string' }] };
  //     const mail = { contents: message }; // The data to sign
  //     const signature = await library
  //       .getSigner()
  //       ._signTypedData(domain, types, mail)
  //       .catch(() => deactivate());
  //     if (!signature) return; // 서명 거부
  //     const msgParams = JSON.stringify({ domain, types, mail });
  //     const data = {
  //       mysterybox_id: mboxInfo?.id,
  //       contract: mboxInfo?.boxContractAddress,
  //       msgParams,
  //       signature,
  //     };
  //     const res = await requestClaim(data);
  //     setOpenSnackbar({
  //       open: true,
  //       type: 'success',
  //       message: 'Success',
  //     });
  //     fetchBalance();
  //     fetchRevealItem();
  //   } catch (error) {
  //     console.log(error);
  //     setOpenSnackbar({
  //       open: true,
  //       type: 'error',
  //       message: 'Failed.',
  //     });
  //     setIsLoading(false);
  //     return false;
  //   }
  //   setIsLoading(false);
  // };

  function toStringByFormatting(source: Date) {
    const year = source.getFullYear();
    const month = source.getMonth() + 1;
    const day = source.getDate();

    return [year, month, day].join('.');
  }

  const fetchBalance = async () => {
    // TODO : 여기에 Reveal이 안된 Mystery Box가 있을 수 있나 ???
    // if (mboxInfo?.isCollection !== true && mboxInfo?.keyContractAddress !== null) {
    //   console.log('fetchBalance:::', mboxInfo?.keyContractAddress, account, library, isKaikas);
    //   const balance = await getKeyBalance(mboxInfo?.keyContractAddress, account, library, isKaikas);
    //   console.log('balance:::', balance);
    //   setBalance(balance);
    // }

    const items = await getItemBalance(
      mboxInfo?.boxContractAddress ?? mboxInfo?.contractAddress,
      myWallet,
      null,
      false
    );

    setItem(items);

    // const talkenData = localStorage.getItem('talken.data');
    // let _talkenData;
    // let talkenUid = null;
    // if (talkenData) {
    //   _talkenData = JSON.parse(talkenData);
    //   talkenUid = _talkenData.uid;
    // }
    if (uid) {
      const claimableCount = await getClaimableCount(mboxInfo?.id || 0, uid);
      setClaimableCount(claimableCount.data?.data || 0);
    }
  };

  const fetchRevealItem = async () => {
    const items = await getItemBalance(
      mboxInfo?.boxContractAddress ?? mboxInfo?.contractAddress,
      myWallet,
      null,
      false
    );
    let tokenURI: string[] = [];
    let tokenIds: number[] = [];
    if (items > 0) {
      tokenURI = await getItemMetadata(
        mboxInfo?.boxContractAddress ?? mboxInfo?.contractAddress,
        items,
        myWallet,
        null,
        false
      ); // library not using
      // TODO : Comment out to display only revealed items
      // } else {
      //   tokenURI[0] = await getKeyMetadata(
      //     mboxInfo?.keyContractAddress,
      //     account,
      //     library
      //   );
      tokenIds = await getTokenIds(
        mboxInfo?.boxContractAddress ?? mboxInfo?.contractAddress,
        items,
        myWallet,
        null,
        false
      ); // library not using
    }

    if (tokenURI.length > 0) {
      const result = await Promise.all(
        tokenURI?.map(async (uri, index) => {
          const res = await axios.get(uri);
          const rlt = await getItemPrice(uri);
          res.data.price = rlt.data?.data?.price ?? '-';
          res.data.no = rlt.data?.data?.no;
          res.data.tokenId = tokenIds[index];
          return res.data;
        })
      );
      console.log(result);
      setRevealItems(result);
    }
  };

  const handleImgError = (e: any, item: any) => {
    // target 이미지 로드가 되지않을 경우 ipfs의 원본을 보여준다.
    e.target.src = item.image;
  };

  useEffect(() => {
    try {
      if (mboxInfo && uid) {
        // if (mboxInfo && account && library?.connection) {
        // const targetWallet = getTargetWallet(mboxInfo?.chainId, wallet);
        // const network = getTargetNetworkName(mboxInfo?.chainId) ?? '';
        // const isKaikas = checkKaikasWallet(wallet, network);
        // if (
        //   (isKaikas && targetWallet === 'metamask') ||
        //   (!isKaikas && targetWallet === 'kaikas')
        // ) {
        //   checkConnectWallet(location.state.item.chainId, wallet, activate);
        //   return;
        // }
        fetchBalance();
        fetchRevealItem();
        const date = new Date(mboxInfo?.afterRelease);
        const lockup = date.getTime() / 1000; // Launch

        if (Date.now() / 1000 >= lockup) {
          setStatus(false);
        }
      }
    } catch (e) {
      console.log(e);
    }
    // 리빌 버튼 표시 조건
    // reveal.status || reveal.balance === 0 || isLoading
  }, [mboxInfo, balance, account, library]);

  const fetchCollectionName = async (contract) => {
    const name = await getContractName(contract, env.REACT_APP_TARGET_NETWORK_KLAY ?? env.REACT_APP_TARGET_NETWORK);
    setCollectionName(name);
  };

  const getTargetChainId = (chain: string) => {
    switch (chain) {
      case 'ethereum':
        return env.REACT_APP_TARGET_NETWORK_ETH;
      case 'binance':
        return env.REACT_APP_TARGET_NETWORK_BNB;
      case 'polygon':
        return env.REACT_APP_TARGET_NETWORK_MATIC;
      case 'klaytn':
      default:
        return env.REACT_APP_TARGET_NETWORK_KLAY;
    }
  };
  const [scanner, setScanner] = useState('');
  useEffect(() => {
    const targetChain = getTargetChainId(state.item.chain);
    setScanner(SCAN_URL[targetChain]);
  }, [state]);

  useEffect(() => {
    console.log('state:::', state);
    if (state.item) {
      const traits =
        state?.item?.attributes || state?.item?.metadata?.attributes || state?.item?.metadata?.traits || [];
      if (traits.length > 0) {
        setAccordionActiveKeys([...accordionActiveKeys, '0']);
        setTraits(traits);
      }
      setMboxInfo(state.item);
      fetchCollectionName(state.item.contractAddress);
    }
  }, []);
  const onChangeAccordion = (e) => {
    setAccordionActiveKeys(e);
  };
  useEffect(() => {
    setOpenSnackbar({
      open: copyResult,
      type: 'success',
      message: 'Copied!',
    });
  }, [copyResult]);

  useEffect(() => {
    const fetchFeatured = async () => {
      if (mboxInfo?.featuredId) {
        const featuredRes = await getFeaturedById(mboxInfo.featuredId);
        if (featuredRes.data !== '') {
          setFeaturedInfo(featuredRes.data);
        }
      }
    };

    fetchFeatured();
  }, [mboxInfo]);

  useEffect(() => {
    const fetchCollection = async () => {
      if (state?.item) {
        const collectionRes = await getCollectionByContractAddress(state?.item.contractAddress);
        console.log('collection::::', collectionRes);
        if (collectionRes.data) {
          setCollectionInfo(collectionRes.data);
        }
      }
    };
    fetchCollection();
  }, []);

  const screenSize = useScreenSize();
  const outLinkIcon = isDarkMode ? arrow_up_right : arrow_up_right_black;
  const handleContractAddressGetOutLink = () => {
    moveToScope(
      state.item?.chain === 'klaytn'
        ? env.REACT_APP_TARGET_NETWORK_KLAY
        : state.item?.chain === 'ethereum'
        ? env.REACT_APP_TARGET_NETWORK_ETH
        : state.item?.chain === 'binance'
        ? env.REACT_APP_TARGET_NETWORK_BNB
        : state.item?.chain === 'polygon'
        ? env.REACT_APP_TARGET_NETWORK_MATIC
        : env.REACT_APP_TARGET_NETWORK,
      state.item?.contractAddress,
      true
    );
  };
  const handleTokenIdGetOutLink = () => {
    // window.open(
    //     `${scanner}nft/${state.item?.contractAddress}/${
    //         state.item?.chain === 'klaytn' ? parseInt(state.item?.extras?.tokenId, 16) : parseInt(state.item?.token_id)
    //     }`
    // );
    let url = '';
    let prefix = '';
    if (state.item?.chain === 'klaytn') {
      prefix = process.env.REACT_APP_PHASE === 'production' ? 'www' : 'baobab';
      url = `https://${prefix}.klaytnfinder.io/nft/${state.item?.contractAddress}/${
        parseInt(state.item?.extras?.tokenId, 16) || state.item?.token_id
      }`;
    } else if (state.item?.chain === 'ethereum') {
      prefix = process.env.REACT_APP_PHASE === 'production' ? 'www' : 'sepolia';
      url = `https://${prefix}.etherscan.io/nft/${state.item?.contractAddress}/${
        parseInt(state.item?.extras?.tokenId, 16) || state.item?.token_id
      }`;
    } else if (state.item?.chain === 'binance') {
      prefix = process.env.REACT_APP_PHASE === 'production' ? 'www' : 'testnet';
      url = `https://${prefix}.bscscan.com/nft/${state.item?.contractAddress}/${
        parseInt(state.item?.extras?.tokenId, 16) || state.item?.token_id
      }`;
    } else if (state.item?.chain === 'polygon') {
      prefix = process.env.REACT_APP_PHASE === 'production' ? 'www' : 'amoy';
      url = `https://${prefix}.polygonscan.com/nft/${state.item?.contractAddress}/${
        parseInt(state.item?.extras?.tokenId, 16) || state.item?.token_id
      }`;
    }
    window.open(url, '_blank');
  };
  const hasCJK = (string) => {
    const regex = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f\u3131-\uD79D]/;
    return string?.match(regex) ? true : false;
  };
  const isProduction = process.env.REACT_APP_PHASE === 'production';
  return (
    <main className="collectibles-details-container min-height-content">
      <div className="collectibles-details-wp">
        <Link to={'/my-items'}>
          <button className="back-button">
            <img src={arrow_btn_back} alt="arrow back" /> Back
          </button>
        </Link>
        <div className="product-details">
          <div className="details-box-mobile">
            <div className="banner-dropdown" ref={ref}>
              <div className="logo">
                <img
                  src={
                    user?.profile_image
                      ? user?.profile_image
                      : user?.eth_address
                      ? makeBlockie(user?.eth_address?.toLowerCase())
                      : user?.abc_address
                      ? makeBlockie(user?.abc_address?.toLowerCase())
                      : talken_logo
                  }
                  alt="Sweet"
                  className="logo-img"
                />
                <div className="logo-info">
                  <div className="creator">Owned by</div>
                  <div className="name">{user.name}</div>
                </div>
              </div>
              {/* list sns icon */}
              {/* <div className="collection-info-right">
                <div className="collection-info-left-details">
                  <div className="info-item hide-max-1024px">
                    <div
                      className="image-item"
                      style={{ cursor: 'pointer' }}
                      onClick={() => moveToScope(mboxInfo?.chainId, mboxInfo?.boxContractAddress, true)}
                    >
                      <img src={klaytn_white} alt="website icon" />
                    </div>
                  </div>
                  {getSnsButtons()}
                  <div className="dropdown hide-min-1025px" ref={refDropdown}>
                    <div className="dropdown-button" onClick={() => setDropdownOpen((dropdownOpen) => !dropdownOpen)}>
                      <img src={ic_dropdown} alt="dropdown" />
                    </div>
                    {dropdownOpen && getSnsMobileButtons()}
                  </div>
                </div>
                <div className="line-icon" />
                <div className="collection-info-left-details">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => copyToClipBoard(window.location.href)}
                    className="info-item"
                  >
                    <div className="image-item">
                      <img src={icon_share} alt="Twitter Icon" width="20px" />
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <GetSnsButtons
                links={featuredInfo?.links}
                chainId={mboxInfo?.chainId}
                boxContractAddress={mboxInfo?.boxContractAddress}
                titleItem={mboxInfo?.title?.en}
                fromMyItems
              /> */}
              {/* <div className="dropdown">
                <div
                  className="dropdown-button"
                  onClick={() =>
                    setDropdownOpen((dropdownOpen) => !dropdownOpen)
                  }
                >
                  <img src={ic_dropdown} alt="dropdown" />
                </div>
                {dropdownOpen && (
                  <ul className="dropdown-box">
                    <li className="list-dropdown-item">
                      <button
                        className="dropdown-item-nft "
                        onClick={() => {
                          setWarningOpen(true);
                          setDropdownOpen(false);
                        }}
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18 15V16C18 17.1 17.1 18 16 18H2C1.46957 18 0.960859 17.7893 0.585786 17.4142C0.210714 17.0391 0 16.5304 0 16V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H16C17.1 0 18 0.9 18 2V3H9C8.46957 3 7.96086 3.21071 7.58579 3.58579C7.21071 3.96086 7 4.46957 7 5V13C7 13.5304 7.21071 14.0391 7.58579 14.4142C7.96086 14.7893 8.46957 15 9 15H18ZM9 13H19V5H9V13ZM13 10.5C12.17 10.5 11.5 9.83 11.5 9C11.5 8.17 12.17 7.5 13 7.5C13.83 7.5 14.5 8.17 14.5 9C14.5 9.83 13.83 10.5 13 10.5Z"
                            fill="white"
                          />
                        </svg>
                        Send to My Wallet
                      </button>
                    </li>
                    <li className="list-dropdown-item">
                      <button
                        className="dropdown-item-nft"
                        onClick={() => {
                          setDropdownOpen(false);
                        }}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.75 18.9531C1.75 19.368 2.08516 19.7031 2.5 19.7031H9.20312V10.8906H1.75V18.9531ZM10.7969 19.7031H17.5C17.9148 19.7031 18.25 19.368 18.25 18.9531V10.8906H10.7969V19.7031ZM18.625 5.26562H15.1656C15.4844 4.76406 15.6719 4.16875 15.6719 3.53125C15.6719 1.74766 14.2211 0.296875 12.4375 0.296875C11.4672 0.296875 10.593 0.728125 10 1.40781C9.40703 0.728125 8.53281 0.296875 7.5625 0.296875C5.77891 0.296875 4.32812 1.74766 4.32812 3.53125C4.32812 4.16875 4.51328 4.76406 4.83438 5.26562H1.375C0.960156 5.26562 0.625 5.60078 0.625 6.01562V9.29688H9.20312V5.26562H10.7969V9.29688H19.375V6.01562C19.375 5.60078 19.0398 5.26562 18.625 5.26562ZM9.20312 5.17188H7.5625C6.65781 5.17188 5.92188 4.43594 5.92188 3.53125C5.92188 2.62656 6.65781 1.89062 7.5625 1.89062C8.46719 1.89062 9.20312 2.62656 9.20312 3.53125V5.17188ZM12.4375 5.17188H10.7969V3.53125C10.7969 2.62656 11.5328 1.89062 12.4375 1.89062C13.3422 1.89062 14.0781 2.62656 14.0781 3.53125C14.0781 4.43594 13.3422 5.17188 12.4375 5.17188Z"
                            fill="white"
                          />
                        </svg>
                        Gift this token
                      </button>
                    </li>
                  </ul>
                )}
              </div> */}
            </div>
            {screenSize > 768 && <div className="line-banner"></div>}
          </div>
          <div className="wrapper-left">
            <div className="showcase-box">
              {state.item?.image?.split('.')?.pop() === 'mp4' ||
              state.item?.extension === 'mp4' ||
              state.item?.metadata?.image?.split('.')?.pop() === 'mp4' ? (
                <video
                  playsInline
                  autoPlay
                  loop
                  controls
                  webkit-playsinline
                  muted
                  controlsList="nodownload"
                  width={'100%'}
                  onError={HandleMediaError}
                  key={(state.item?.image || state.item?.metadata?.image)
                    ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                    .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}
                >
                  <source
                    src={(state.item?.image || state.item?.metadata?.image)
                      ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                      .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}
                    type="video/mp4"
                  />
                </video>
              ) : (
                <img
                  src={
                    state.item?.alt_url ||
                    state.item?.image ||
                    state.item?.metadata?.alt_url ||
                    state.item?.metadata?.image
                      ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                      .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')
                  }
                  alt=""
                  draggable={false}
                  onError={HandleMediaError}
                />
              )}
              {(Number(state.item?.balance) > 1 || state.item?.amount > 1) && (
                <div className="collectibles-item-detail-amount">
                  {`x${Number(state.item?.balance) || state.item?.amount}`}
                </div>
              )}
              {/* <canvas className="canvas-card" width="1125" height="1125" style={{ width: '900px', height: '900px' }}></canvas> */}
            </div>
            <Accordion activeKey={accordionActiveKeys} alwaysOpen onSelect={onChangeAccordion}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div className="content-left">
                    <div className="image-properties">
                      {isDarkMode ? (
                        <img src={icon_properties} alt="Traits" />
                      ) : (
                        <img src={icon_properties_black} alt="Traits" />
                      )}
                    </div>
                    <div className="title-properties">Traits</div>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="accordion-properties">
                  <div className="padding-content">
                    {traits?.length > 0 && (
                      <>
                        {traits?.map((key, index) => {
                          const tooltipTrait = `tooltipTrait-${index}`;

                          if (key['trait_type']) {
                            return (
                              <div className="item-properties" key={index}>
                                <div
                                  className={`content-01 ${hasCJK(key['trait_type']) ? 'cjk-font' : ''}`}
                                  data-for={key['trait_type']?.length > 15 ? tooltipTrait : undefined}
                                  data-tip={key['trait_type']?.length > 15 ? 'true' : undefined}
                                >
                                  {key['trait_type']}
                                </div>
                                <div
                                  className={`content-02 ${hasCJK(key['value']) ? 'cjk-font' : ''}`}
                                  data-for={key['value']?.length > 30 ? tooltipTrait : undefined}
                                  data-tip={key['value']?.length > 30 ? 'true' : undefined}
                                >
                                  {key['value']}
                                </div>
                                {(key['value']?.length > 30 || key['trait_type']?.length > 15) && (
                                  <ReactTooltip
                                    key={tooltipTrait}
                                    id={tooltipTrait}
                                    className="tooltip-box-trait"
                                    multiline={true}
                                    place={`${screenSize < 769 ? 'bottom' : 'top'}`}
                                    effect={'solid'}
                                  >
                                    <ul className="mt-0 p-l-10">
                                      <li
                                        className={`text-start text-uppercase ${
                                          hasCJK(key['trait_type']) && 'cjk-font'
                                        }`}
                                      >
                                        {key['trait_type']}
                                      </li>
                                      <li className={`text-start ${hasCJK(key['value']) && 'cjk-font'}`}>
                                        {key['value']}
                                      </li>
                                    </ul>
                                  </ReactTooltip>
                                )}
                              </div>
                            );
                          } else {
                            return undefined;
                          }
                        })}
                      </>
                    )}
                    {traits?.length > 0 && (
                      <>
                        {traits[0]?.properties?.map((key, index) => {
                          const tooltipTrait = `tooltipTrait-${index}`;
                          if (key['type']) {
                            return (
                              <div className="item-properties" key={index}>
                                <div className={`content-01 ${hasCJK(key['type']) ? 'cjk-font' : ''}`}>
                                  {key['type']}
                                </div>
                                <div className={`content-02 ${hasCJK(key['name']) ? 'cjk-font' : ''}`}>
                                  {key['name']}
                                </div>
                                {(key['type']?.length > 15 || key['name']?.length > 30) && (
                                  <ReactTooltip
                                    key={tooltipTrait}
                                    id={tooltipTrait}
                                    className="tooltip-box-trait"
                                    multiline={true}
                                    place={`${screenSize < 769 ? 'bottom' : 'top'}`}
                                    effect={'solid'}
                                  >
                                    <ul className="mt-0 p-l-10">
                                      <li className={`text-start text-uppercase ${hasCJK(key['type']) && 'cjk-font'}`}>
                                        {key['type']}
                                      </li>
                                      <li className={`text-start ${hasCJK(key['name']) && 'cjk-font'}`}>
                                        {key['name']}
                                      </li>
                                    </ul>
                                  </ReactTooltip>
                                )}
                              </div>
                            );
                          } else {
                            return undefined;
                          }
                        })}
                      </>
                    )}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              {/*{!isProduction && (*/}
              {/*  <Accordion.Item eventKey="2">*/}
              {/*    <Accordion.Header>*/}
              {/*      <div className="content-left">*/}
              {/*        <div className="image-properties">*/}
              {/*          {isDarkMode ? <img src={icon_note} alt="About" /> : <img src={icon_note} alt="About" />}*/}
              {/*        </div>*/}
              {/*        <div className="title-properties">*/}
              {/*          About &nbsp;*/}
              {/*          {collectionInfo?.collection?.openseaInfo ? collectionInfo?.collection?.openseaInfo?.name : ''}*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </Accordion.Header>*/}
              {/*    <Accordion.Body className="accordion-properties">*/}
              {/*      <div className="d-flex align-items-start">*/}
              {/*        <div className="img-about">*/}
              {/*          {state?.item?.image && <img src={state?.item?.image} alt={'Img About'} />}*/}
              {/*        </div>*/}
              {/*        <div className="d-flex flex-column item-about-container">*/}
              {/*          <p className="m-b-6 text-pre-wrap">{collectionInfo?.collection?.openseaInfo?.description}</p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </Accordion.Body>*/}
              {/*  </Accordion.Item>*/}
              {/*)}*/}
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <div className="content-left">
                    <div className="image-details">
                      {isDarkMode ? (
                        <img src={icon_details} alt="Details" />
                      ) : (
                        <img src={icon_details_black} alt="Details" />
                      )}
                    </div>
                    <div className="title-details">Details</div>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="accordion-details">
                  <div className="padding-content">
                    <div className="item-details">
                      <div className="name">Contract Address</div>
                      <div className="info-name" onClick={handleContractAddressGetOutLink}>
                        {splitAddress(state.item?.contractAddress)}
                        <img src={outLinkIcon} />
                      </div>
                    </div>
                    <div className="item-details">
                      <div className="name">Token ID</div>
                      <div className="info-name" onClick={handleTokenIdGetOutLink}>
                        {splitAddress(parseInt(state.item?.extras?.tokenId, 16) || state.item?.token_id)}
                        <img src={outLinkIcon} />
                      </div>
                    </div>
                    <div className="item-details">
                      <div className="name">Token Standard</div>
                      <div className="info-name">
                        {state.item?.contract_type === 'ERC1155' || state.item?.kind === 'mt' ? 'ERC-1155' : 'ERC-721'}
                      </div>
                    </div>
                    <div className="item-details">
                      <div className="name">Chain</div>
                      <div className="info-name" style={{ textTransform: 'capitalize' }}>
                        {getNetworkNameById(
                          state.item?.chain === 'klaytn'
                            ? env.REACT_APP_TARGET_NETWORK_KLAY
                            : state.item?.chain === 'ethereum'
                            ? env.REACT_APP_TARGET_NETWORK_ETH
                            : state.item?.chain === 'binance'
                            ? env.REACT_APP_TARGET_NETWORK_BNB
                            : state.item?.chain === 'polygon'
                            ? env.REACT_APP_TARGET_NETWORK_MATIC
                            : env.REACT_APP_TARGET_NETWORK
                        )}
                      </div>
                    </div>
                    {/* <div className="item-details">
                      <div className="name">Last Updated</div>
                      <div className="info-name">2023.1.1</div>
                    </div> */}
                    {/* <div className="item-details">
                      <div className="name">Creator Earnings</div>
                      <div className="info-name">2.5%</div>
                    </div> */}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="details-box">
            <div className="banner-dropdown" ref={ref}>
              <div className="logo">
                <img
                  src={
                    user?.profile_image
                      ? user?.profile_image
                      : user?.eth_address
                      ? makeBlockie(user?.eth_address?.toLowerCase())
                      : user?.abc_address
                      ? makeBlockie(user?.abc_address?.toLowerCase())
                      : talken_logo
                  }
                  alt="Sweet"
                  className="logo-img"
                />
                <div className="logo-info">
                  <div className="creator">Owned by</div>
                  <div className="name">{user.name}</div>
                </div>
              </div>
              {/* list sns icon */}
              {/* <GetSnsButtons
                links={featuredInfo?.links}
                chainId={mboxInfo?.chainId}
                boxContractAddress={mboxInfo?.boxContractAddress}
                titleItem={mboxInfo?.title?.en}
                fromMyItems
              /> */}
              {/* <div className="dropdown">
                <div
                  className="dropdown-button"
                  onClick={() =>
                    setDropdownOpen((dropdownOpen) => !dropdownOpen)
                  }
                >
                  <img src={ic_dropdown} alt="dropdown" />
                </div>
                {dropdownOpen && (
                  <ul className="dropdown-box">
                    <li className="list-dropdown-item">
                      <button
                        className="dropdown-item-nft "
                        onClick={() => {
                          setWarningOpen(true);
                          setDropdownOpen(false);
                        }}
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18 15V16C18 17.1 17.1 18 16 18H2C1.46957 18 0.960859 17.7893 0.585786 17.4142C0.210714 17.0391 0 16.5304 0 16V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H16C17.1 0 18 0.9 18 2V3H9C8.46957 3 7.96086 3.21071 7.58579 3.58579C7.21071 3.96086 7 4.46957 7 5V13C7 13.5304 7.21071 14.0391 7.58579 14.4142C7.96086 14.7893 8.46957 15 9 15H18ZM9 13H19V5H9V13ZM13 10.5C12.17 10.5 11.5 9.83 11.5 9C11.5 8.17 12.17 7.5 13 7.5C13.83 7.5 14.5 8.17 14.5 9C14.5 9.83 13.83 10.5 13 10.5Z"
                            fill="white"
                          />
                        </svg>
                        Send to My Wallet
                      </button>
                    </li>
                    <li className="list-dropdown-item">
                      <button
                        className="dropdown-item-nft"
                        onClick={() => {
                          setDropdownOpen(false);
                        }}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.75 18.9531C1.75 19.368 2.08516 19.7031 2.5 19.7031H9.20312V10.8906H1.75V18.9531ZM10.7969 19.7031H17.5C17.9148 19.7031 18.25 19.368 18.25 18.9531V10.8906H10.7969V19.7031ZM18.625 5.26562H15.1656C15.4844 4.76406 15.6719 4.16875 15.6719 3.53125C15.6719 1.74766 14.2211 0.296875 12.4375 0.296875C11.4672 0.296875 10.593 0.728125 10 1.40781C9.40703 0.728125 8.53281 0.296875 7.5625 0.296875C5.77891 0.296875 4.32812 1.74766 4.32812 3.53125C4.32812 4.16875 4.51328 4.76406 4.83438 5.26562H1.375C0.960156 5.26562 0.625 5.60078 0.625 6.01562V9.29688H9.20312V5.26562H10.7969V9.29688H19.375V6.01562C19.375 5.60078 19.0398 5.26562 18.625 5.26562ZM9.20312 5.17188H7.5625C6.65781 5.17188 5.92188 4.43594 5.92188 3.53125C5.92188 2.62656 6.65781 1.89062 7.5625 1.89062C8.46719 1.89062 9.20312 2.62656 9.20312 3.53125V5.17188ZM12.4375 5.17188H10.7969V3.53125C10.7969 2.62656 11.5328 1.89062 12.4375 1.89062C13.3422 1.89062 14.0781 2.62656 14.0781 3.53125C14.0781 4.43594 13.3422 5.17188 12.4375 5.17188Z"
                            fill="white"
                          />
                        </svg>
                        Gift this token
                      </button>
                    </li>
                  </ul>
                )}
              </div> */}
            </div>
            {/* {screenSize < 640 && <AuctionSaleAndPrice myitem={true} dataDetailMyItems={location.state.item} />} */}
            {screenSize > 768 && <div className="line-banner"></div>}
            <div className="name-product">
              {state.item?.metadata?.name
                ? state.item?.metadata?.name
                : typeof state.item?.name === 'string'
                ? state.item?.name
                : state.item?.name?.en}
            </div>
            <div className={isMobile ? 'sub-product border-desciption' : 'sub-product'}>
              <ConvertUrlsToLinks
                text={state.item?.description || state.item?.metadata?.description || 'no description'}
              />
            </div>
            {screenSize > 768 && <div className="line-banner"></div>}
            <button
              className={`btn-detail-nft mb-0 button h-60px h-xs-48px m-t-xs-16 ${
                (state.item?.contract_type === 'ERC1155' || state.item?.kind === 'mt') && 'collectibles-disable-list'
              }`}
              onClick={() => {
                if (!(state.item?.contract_type === 'ERC1155' || state.item?.kind === 'mt')) {
                  setIsOpenNFTModdal(true);
                } else {
                  setOpenSnackbar({
                    open: true,
                    type: 'success',
                    message: 'NFT in ERC-1155 standard will be supported soon.',
                  });
                }
              }}
            >
              List for sale
            </button>
            {/*<a*/}
            {/*  target="_blank"*/}
            {/*  href="https://polygonscan.com/token/0xF3e34e2022029A7eCb38d7373f7171f478670B20?a=48"*/}
            {/*  className="authenticity-button"*/}
            {/*  rel="noreferrer"*/}
            {/*>*/}
            {/*  <img src={ic_authenticity} alt="authenticity-icon" />*/}
            {/*  Authenticity*/}
            {/*</a>*/}
            {/* <div className="list-item">
              <div className="item">
                <div className="label">
                  {mboxInfo?.isCollection ? 'Amount' : status || balance !== 0 ? 'Reveal Amount' : 'Amount'}
                </div>
                <div className="value">
                  {mboxInfo?.isCollection ? `${item}` : status || balance !== 0 ? `${balance}` : `${item}`}
                </div>
              </div>
              <div className="item">
                <div className="label">Release Date</div>
                <div className="value">{toStringByFormatting(new Date(mboxInfo?.releaseDatetime))}</div>
              </div>
              <div className="item">
                <div className="label">Date Acquired</div>
                <div className="value">
                  {toStringByFormatting(new Date(mboxInfo?.createdAt ? mboxInfo.createdAt.toString() : ''))}
                </div>
              </div>
              <div className="item">
                <div className="label">Total Items</div>
                <div className="value">{mboxInfo?.totalAmount}</div>
              </div>
              <div className="item">
                <div className="label" data-qa-component="token-type-label">
                  Token Standard
                </div>
                <div className="value" data-qa-component="token-type-value">
                  ERC-721
                </div>
              </div>
              <div className="item">
                <div className="label">Chain</div>
                <div className="value" style={{ textTransform: 'capitalize' }}>
                  {getNetworkNameByChainId(mboxInfo?.chainId)}
                </div>
              </div>
            </div> */}
            {/* <div className="list-trade"></div> */}
            {/* auction sale and fixed price pc  */}
            {/* {screenSize > 639 && <AuctionSaleAndPrice myitem={true} dataDetailMyItems={location.state.item} />} */}
            <div className="wrapper-detail-box-mobile">
              <Accordion activeKey={accordionActiveKeys} alwaysOpen onSelect={onChangeAccordion}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <div className="content-left">
                      <div className="image-properties">
                        {isDarkMode ? (
                          <img src={icon_properties} alt="Traits" />
                        ) : (
                          <img src={icon_properties_black} alt="Traits" />
                        )}
                      </div>
                      <div className="title-properties">Traits</div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="accordion-properties">
                    <div className="padding-content">
                      {traits?.length > 0 && (
                        <>
                          {traits?.map((key, index) => {
                            const tooltipTrait = `tooltipTrait-${index}`;

                            if (key['trait_type']) {
                              return (
                                <div className="item-properties" key={index}>
                                  <div
                                    className={`content-01 ${hasCJK(key['trait_type']) ? 'cjk-font' : ''}`}
                                    data-for={key['trait_type']?.length > 8 ? tooltipTrait : undefined}
                                    data-tip={key['trait_type']?.length > 8 ? 'true' : undefined}
                                  >
                                    {key['trait_type']}
                                  </div>
                                  <div
                                    className={`content-02 ${hasCJK(key['value']) ? 'cjk-font' : ''}`}
                                    data-for={key['value']?.length > 16 ? tooltipTrait : undefined}
                                    data-tip={key['value']?.length > 16 ? 'true' : undefined}
                                  >
                                    {key['value']}
                                  </div>
                                  {(key['value']?.length > 16 || key['trait_type']?.length > 8) && (
                                    <ReactTooltip
                                      key={tooltipTrait}
                                      id={tooltipTrait}
                                      className="tooltip-box-trait"
                                      multiline={true}
                                      place={`${screenSize < 769 ? 'bottom' : 'top'}`}
                                      effect={'solid'}
                                    >
                                      <ul className="mt-0 p-l-10">
                                        <li
                                          className={`text-start text-uppercase ${
                                            hasCJK(key['trait_type']) && 'cjk-font'
                                          }`}
                                        >
                                          {key['trait_type']}
                                        </li>
                                        <li className={`text-start ${hasCJK(key['value']) && 'cjk-font'}`}>
                                          {key['value']}
                                        </li>
                                      </ul>
                                    </ReactTooltip>
                                  )}
                                </div>
                              );
                            } else {
                              return undefined;
                            }
                          })}
                        </>
                      )}
                      {traits?.length > 0 && (
                        <>
                          {traits[0]?.properties?.map((key, index) => {
                            const tooltipTrait = `tooltipTrait-${index}`;
                            if (key['type']) {
                              return (
                                <div className="item-properties" key={index}>
                                  <div className={`content-01 ${hasCJK(key['type']) ? 'cjk-font' : ''}`}>
                                    {key['type']}
                                  </div>
                                  <div className={`content-02 ${hasCJK(key['name']) ? 'cjk-font' : ''}`}>
                                    {key['name']}
                                  </div>
                                  {(key['type']?.length > 8 || key['name']?.length > 16) && (
                                    <ReactTooltip
                                      key={tooltipTrait}
                                      id={tooltipTrait}
                                      className="tooltip-box-trait"
                                      multiline={true}
                                      place={`${screenSize < 769 ? 'bottom' : 'top'}`}
                                      effect={'solid'}
                                    >
                                      <ul className="mt-0 p-l-10">
                                        <li
                                          className={`text-start text-uppercase ${hasCJK(key['type']) && 'cjk-font'}`}
                                        >
                                          {key['type']}
                                        </li>
                                        <li className={`text-start ${hasCJK(key['name']) && 'cjk-font'}`}>
                                          {key['name']}
                                        </li>
                                      </ul>
                                    </ReactTooltip>
                                  )}
                                </div>
                              );
                            } else {
                              return undefined;
                            }
                          })}
                        </>
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <div className="content-left">
                      <div className="image-details">
                        {isDarkMode ? (
                          <img src={icon_details} alt="Details" />
                        ) : (
                          <img src={icon_details_black} alt="Details" />
                        )}
                      </div>
                      <div className="title-details">Details</div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="accordion-details">
                    <div className="padding-content">
                      <div className="item-details">
                        <div className="name">Contract Address</div>
                        <div className="info-name" onClick={handleContractAddressGetOutLink}>
                          {splitAddress(state.item?.contractAddress)}
                          <img src={outLinkIcon} />
                        </div>
                      </div>
                      <div className="item-details">
                        <div className="name">Token ID</div>
                        <div className="info-name" onClick={handleTokenIdGetOutLink}>
                          {splitAddress(parseInt(state.item?.extras?.tokenId, 16) || state.item?.token_id)}
                          <img src={outLinkIcon} />
                        </div>
                      </div>
                      <div className="item-details">
                        <div className="name">Token Standard</div>
                        <div className="info-name">
                          {state.item?.contract_type === 'ERC1155' || state.item?.kind === 'mt'
                            ? 'ERC-1155'
                            : 'ERC-721'}
                        </div>
                      </div>
                      <div className="item-details">
                        <div className="name">Chain</div>
                        <div className="info-name" style={{ textTransform: 'capitalize' }}>
                          {getNetworkNameById(
                            state.item?.chain === 'klaytn'
                              ? env.REACT_APP_TARGET_NETWORK_KLAY
                              : state.item?.chain === 'ethereum'
                              ? env.REACT_APP_TARGET_NETWORK_ETH
                              : state.item?.chain === 'binance'
                              ? env.REACT_APP_TARGET_NETWORK_BNB
                              : state.item?.chain === 'polygon'
                              ? env.REACT_APP_TARGET_NETWORK_MATIC
                              : env.REACT_APP_TARGET_NETWORK
                          )}
                        </div>
                      </div>
                      {/* <div className="item-details">
                        <div className="name">Last Updated</div>
                        <div className="info-name">2023.1.1</div>
                      </div> */}
                      {/* <div className="item-details">
                        <div className="name">Creator Earnings</div>
                        <div className="info-name">2.5%</div>
                      </div> */}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            {/* <div className="wrapper-user-details">
              <div className="title-details">Collection this item belongs to</div>
              <div className="wrapper-content-details">
                <div className="wrapper-user">
                  <div className="avt-user">
                    <img src={iconTest} alt="icon avatar" />
                  </div>
                  <div className="user-name">L’S WORLD_PART2 - JACK’S FAMILY</div>
                </div>
                <div>
                  <div className="wrapper-see-collection">
                    <div className="title">See this collection</div>
                  </div>
                </div>
              </div>
              <div className="details-info">
                The members of Jack’s family are embedded in the ground, where they guard their territory. They have
                been performing their job for 38 years now,
              </div>
            </div> */}
            {/* <div className="wrapper-other-items">
              <div className="wrapper-head">
                <div className="title-items">Other Items</div>
                <Link className="seemore-otheritems" to={`/creator`}>
                  See more
                </Link>
              </div>
              <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlay={false}
                autoPlaySpeed={5000}
                draggable
                focusOnSelect={false}
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={true}
                renderDotsOutside={true}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                swipeable
                infinite
                customButtonGroup={<ArrowCarouselItemDetails />}
                removeArrowOnDeviceType=""
                dotListClass="dot-other-items"
                // centerMode={false}
                className="carousel-other-items"
                // itemClass=""
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1420,
                    },
                    items: 5,
                    partialVisibilityGutter: 40,
                  },
                  mobile: {
                    breakpoint: {
                      max: 640,
                      min: 0,
                    },
                    items: 2,
                    partialVisibilityGutter: 30,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 640,
                    },
                    items: 2,
                    partialVisibilityGutter: 30,
                  },
                  laptopLarge: {
                    breakpoint: {
                      max: 1420,
                      min: 1180,
                    },
                    items: 4,
                    partialVisibilityGutter: 30,
                  },
                  laptop: {
                    breakpoint: {
                      max: 1180,
                      min: 1024,
                    },
                    items: 3,
                    partialVisibilityGutter: 30,
                  },
                }}
              >
                <Link to={`/`} className="item-other button">
                  <div className="item-image">
                    <img src={iconTest} alt="Image Item" />
                  </div>
                  <div className="info-item-detail">
                    <div className="id-card">LCT-T1</div>
                    <div className="price-item">12,34 klay</div>
                  </div>
                </Link>
              </Carousel>
            </div> */}
            {/*{mboxInfo?.useRevealLockup ? (*/}
            {/*  <>*/}
            {/*    {!countDownFinish && (*/}
            {/*      <CountDownTimer*/}
            {/*        handeCheckCountDownFinish={handeCheckCountDownFinish}*/}
            {/*        targetDate={new Date(mboxInfo?.afterRelease)}*/}
            {/*      />*/}
            {/*    )}*/}
            {/*  </>*/}
            {/*) : (*/}
            {/*  <>*/}
            {/*    {status || balance === 0 ? null : (*/}
            {/*      <button*/}
            {/*        className="btn-trade status"*/}
            {/*        onClick={handleRevealClick}*/}
            {/*      >*/}
            {/*        {isLoading ? (*/}
            {/*          <CircularProgress size={30} color={'inherit'} />*/}
            {/*        ) : (*/}
            {/*          <>*/}
            {/*            <img src={ic_sell} alt="sell" />*/}
            {/*            Reveal*/}
            {/*          </>*/}
            {/*        )}*/}
            {/*        /!*<img src={ic_trade} alt="trade" />*!/*/}
            {/*      </button>*/}
            {/*    )}*/}
            {/*  </>*/}
            {/*)}*/}

            {/* comment reveal button  */}
            {/* {mboxInfo?.useRevealLockup && !countDownFinish ? (
              <>
                {!countDownFinish && (
                  <CountDownTimer
                    handeCheckCountDownFinish={handeCheckCountDownFinish}
                    targetDate={new Date(mboxInfo?.afterRelease)}
                  />
                )}
              </>
            ) : (
              <>
                {!isWalletConnected ? (
                  <button className={'btn-trade status'} onClick={() => setLoginOpen(true)}>
                    Connect Wallet
                  </button>
                ) : claimableCount > 0 ? (
                  <button className="btn-trade status" onClick={handleClaimClick}>
                    {isLoading ? <CircularProgress size={30} color={'inherit'} /> : <>Transfer to Wallet</>}
                  </button>
                ) : status || balance === 0 ? null : (
                  <button className="btn-trade status" onClick={handleRevealClick}>
                    {isLoading ? (
                      <CircularProgress size={30} color={'inherit'} />
                    ) : (
                      <>
                        <img src={ic_sell} alt="sell" />
                        Reveal
                      </>
                    )}
                  </button>
                )}
              </>
            )} */}
          </div>
        </div>
        <div className="price-history">
          {/*<div className="price-history-label">*/}
          {/*  <img src={price_history_lg} alt="price-history" />*/}
          {/*  Price History*/}
          {/*</div>*/}
          {/*<div className="list-price-history"></div>*/}
        </div>
        {/*<div className="my-revealed-items">My items</div>*/}
        {/*<div className="marketplace-items">*/}
        {/*  {mboxInfo &&*/}
        {/*    revealItems.map((item, index) => {*/}
        {/*      return (*/}
        {/*        <Link to={`/klaytn/${mboxInfo.boxContractAddress}/${item.no}/${item.tokenId}`} key={index}>*/}
        {/*          <div className="item_product" key={index}>*/}
        {/*            <div className="item_product_detail MARKETPLACE_TOTAL_KEY fw-600">*/}
        {/*              /!*<div className="total_item">Total Run: 50</div>*!/*/}
        {/*              <div className="total_item">Index: {item.no}</div>*/}
        {/*            </div>*/}
        {/*            <div className="item_product_detail MARKETPLACE_TYPE_KEY">*/}
        {/*              <div>ERC-721</div>*/}
        {/*            </div>*/}
        {/*            <div className="item_product_detail MARKETPLACE_GRAPHICS_KEY">*/}
        {/*              <div className="card-image">*/}
        {/*                {item.alt_url === undefined && item.alt_url === '' && item.extension === 'mp4' ? (*/}
        {/*                  <video playsInline autoPlay controls controlsList="nodownload" muted loop width={'100%'}>*/}
        {/*                    <source src={item.alt_url} onError={(e) => handleImgError(e, item)} type="video/mp4" />*/}
        {/*                  </video>*/}
        {/*                ) : (*/}
        {/*                  <img src={item.alt_url} onError={(e) => handleImgError(e, item)} alt="" />*/}
        {/*                )}*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <div className="item_product_detail MARKETPLACE_AUTHOR_KEY">*/}
        {/*              <div className="owner_product">*/}
        {/*                <div className="owner_product_box">*/}
        {/*                  <div className="owner_product_avatar">*/}
        {/*                    <img src={mboxInfo?.companyimage} alt="" />*/}
        {/*                  </div>*/}
        {/*                  <div className="">{mboxInfo?.companyname?.en}</div>*/}
        {/*                </div>*/}
        {/*                <div>*/}
        {/*                  <Link to="/sale">*/}
        {/*                    <div className="status ">Buy Now</div>*/}
        {/*                  </Link>*/}
        {/*                </div>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <div className="item_product_detail MARKETPLACE_NAME_KEY">*/}
        {/*              <div className="product_name ">{item.name}</div>*/}
        {/*            </div>*/}
        {/*            <div className="item_product_detail MARKETPLACE_BID_KEY">*/}
        {/*              <div className="box-price">*/}
        {/*                <div className="price ">Price</div>*/}
        {/*                <div className="currency ">*/}
        {/*                  {`${*/}
        {/*                    mboxInfo.isCollection*/}
        {/*                      ? getPrice(Number(item?.price), mboxInfo.quote!)*/}
        {/*                      : getPrice(Number(mboxInfo.price), mboxInfo.quote!)*/}
        {/*                  }`}*/}
        {/*                </div>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <div className="item_product_detail MARKETPLACE_NAME_TIME">*/}
        {/*              <div>*/}
        {/*                <div className="remaining ">Rarity</div>*/}
        {/*                <div className="remaining-total ">{getRarityToString(item.rarity)}</div>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </Link>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*</div>*/}
        <Popup open={warningOpen} onClose={closeWarning} modal {...{ closeOnDocumentClick, lockScroll, overlayStyle }}>
          <WarningForm close={closeWarning} onConfirm={() => setSendingOpen(true)} />
        </Popup>

        <Popup
          modal
          open={sendingOpen}
          onOpen={closeWarning}
          onClose={closeSending}
          {...{ closeOnDocumentClick, overlayStyle }}
        >
          <SendingForm close={closeSending} onConfirm={() => setSuccessOpen(true)} />
        </Popup>

        <Popup
          modal
          open={successOpen}
          onOpen={closeSending}
          onClose={closeSuccess}
          {...{ closeOnDocumentClick, overlayStyle }}
        >
          <SuccessForm close={closeSuccess} />
        </Popup>
        <Popup
          modal
          open={loginOpen}
          onOpen={closeSignup}
          onClose={closeLogin}
          {...{ overlayStyle, closeOnDocumentClick, lockScroll }}
        >
          {/* <LoginForm close={closeLogin} onConfirm={() => setSignupOpen(true)} /> */}
          <WalletConnector close={closeLogin} onConfirm={() => setSignupOpen(true)} />
        </Popup>
        <CSnackbar
          open={openSnackbar.open}
          type={openSnackbar.type}
          message={openSnackbar.message}
          handleClose={handleCloseSnackbar}
        />
        {isOpenListNFTModal && (
          <ListNFTModal
            show={isOpenListNFTModal}
            onHide={() => setIsOpenNFTModdal(false)}
            typeSale={true}
            onListingComplete={(sellData) => {
              setIsCompleteListing(true);
              setSellData(sellData);
            }}
            data={state.item}
          ></ListNFTModal>
        )}
        {isCompleteListing && (
          <ListingCompleteModal
            show={isCompleteListing}
            onHide={() => {
              setIsCompleteListing(false);
              window.location.reload();
            }}
            data={state.item}
            sellData={sellData}
          ></ListingCompleteModal>
        )}
      </div>
    </main>
  );
};

export default MyCollectiblesDetails;
