import arrow_left_carousel from '../../assets/icon/arrow_left_carousel.png';
import arrow_right_carousel from '../../assets/icon/arrow_right_carousel.png';
const ArrowFirstCarousel = ({ className, to, onClick }: any) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`button ${className} d-block arrow-first-carousel`}
      aria-label={to}
    >
      {to === 'prev' ? (
        <img src={arrow_left_carousel} alt="arrow left" />
      ) : (
        <img src={arrow_right_carousel} alt="arrow left" />
      )}
    </button>
  );
};
export default ArrowFirstCarousel;
