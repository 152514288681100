import { InjectedConnector } from '@web3-react/injected-connector';
import { InjectedConnector as KlaytnConnector } from 'klaytn-connector';
import { InjectedConnector as AbcConnector } from 'abc-connector';
// import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletConnectV2Connector } from '@colligence/walletconnectv2-connector';
import { ChainId, RPC_URLS } from '../config';
import env from '../env';
// import QRCodeModal from 'talken-qrcode-modal';

export const injected = new InjectedConnector({
  supportedChainIds: [
    ChainId.BAOBAB,
    ChainId.KLAYTN,
    ChainId.MUMBAI,
    ChainId.POLYGON,
    ChainId.SEPOLIA,
    ChainId.MAINNET,
    ChainId.BSCMAINNET,
    ChainId.BSCTESTNET,
    ChainId.AMOY,
  ],
});

export const kaikas = new KlaytnConnector({
  supportedChainIds: [
    ChainId.BAOBAB,
    ChainId.KLAYTN,
    ChainId.MUMBAI,
    ChainId.POLYGON,
    ChainId.SEPOLIA,
    ChainId.MAINNET,
    ChainId.BSCMAINNET,
    ChainId.BSCTESTNET,
    ChainId.AMOY,
  ],
});

export const abc = new AbcConnector({
  supportedChainIds: [
    ChainId.BAOBAB,
    ChainId.KLAYTN,
    ChainId.MUMBAI,
    ChainId.POLYGON,
    ChainId.SEPOLIA,
    ChainId.MAINNET,
    ChainId.BSCMAINNET,
    ChainId.BSCTESTNET,
    ChainId.AMOY,
  ],
});

// export const walletconnect = (useQR: boolean) => {
//   // const chainId = parseInt(targetNetwork, 16);
//   return new WalletConnectConnector({
//     // supportedChainIds: [ChainId.TMP, ChainId.BAOBAB, ChainId.KLAYTN],
//     // rpc: { [chainId]: RPC_URLS[chainId] },
//     rpc: {
//       // [ChainId.BAOBAB]: RPC_URLS[ChainId.BAOBAB],
//       // [ChainId.KLAYTN]: RPC_URLS[ChainId.KLAYTN],
//       [env.REACT_APP_TARGET_NETWORK_KLAY]:
//         RPC_URLS[env.REACT_APP_TARGET_NETWORK_KLAY],
//     },
//     bridge: 'https://bridge.walletconnect.org',
//     qrcode: useQR,
//   });
// };

export const walletconnectv2 = (chainId: number) => {
  // const chainId = parseInt(targetNetwork, 16);
  return new WalletConnectV2Connector({
    supportedChainIds: [
      ChainId.BAOBAB,
      ChainId.KLAYTN,
      ChainId.MUMBAI,
      ChainId.POLYGON,
      ChainId.SEPOLIA,
      ChainId.MAINNET,
      ChainId.BSCMAINNET,
      ChainId.BSCTESTNET,
      ChainId.AMOY,
    ],
    desiredChainId: chainId,
    projectId: process.env.REACT_APP_WC_PROJECT_ID,
  });
};

// export const talkenwallet = (useQR: boolean) => {
//   // const chainId = parseInt(targetNetwork, 16);
//   return new WalletConnectConnector({
//     // supportedChainIds: [ChainId.TMP, ChainId.BAOBAB, ChainId.KLAYTN],
//     // rpc: { [chainId]: RPC_URLS[chainId] },
//     rpc: {
//       // [ChainId.BAOBAB]: RPC_URLS[ChainId.BAOBAB],
//       // [ChainId.KLAYTN]: RPC_URLS[ChainId.KLAYTN],
//       [env.REACT_APP_TARGET_NETWORK_KLAY]:
//         RPC_URLS[env.REACT_APP_TARGET_NETWORK_KLAY],
//     },
//     bridge: 'https://bridge.walletconnect.org',
//     qrcode: useQR,
//     qrcodeModal: QRCodeModal,
//   });
// };
