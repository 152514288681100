export const getNetworkNameByChainId = (chainId: number | undefined) => {
  let result = '';
  if (chainId) {
    if (chainId === 8217 || chainId === 1001) {
      result = 'Klaytn';
    } else if (chainId === 1 || chainId === 3) {
      result = 'Ethereum';
    } else if (chainId === 56 || chainId === 97) {
      result = 'BNB Chain';
    } else if (chainId === 137 || chainId === 80002) {
      result = 'Polygon';
    }
  } else {
    result = '-';
  }

  return result;
};
