import { useThemeMode } from 'components/common/AppStoreType';
import ReactModal from 'react-modal';
import close_icon from '../../assets/icon/close_icon.svg';
import close_icon_black from '../../assets/icon/close_icon_black.svg';
import klay from '../../assets/icon/klaytn.png';
import polygon from '../../assets/icon/polygon.png';
import eth from '../../assets/icon/eth.png';
import talk from '../../talkclaim/talk.png';
import Select, { components } from 'react-select';
import { useMediaQuery } from 'react-responsive';
import useScreenSize from 'components/common/useScreenSize';
import { ReactComponent as Link } from '../../assets/icon/link.svg';
import React, { useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import klaytn from '../../assets/icon/klaytn.png';

type TransferSuccessWklay = {
  show: any;
  onHide: any;
  typeModal: string;
  openWinningComplete: any;
  data: any;
};
const TransferSuccessWklay: React.FC<TransferSuccessWklay> = ({
  show,
  onHide,
  typeModal,
  openWinningComplete,
  data,
}) => {
  console.log('hahahaha', data);

  const { isDarkMode } = useThemeMode();
  const screenSize = useScreenSize();
  const isMobile = useMediaQuery({
    query: '(max-width: 641px)',
  });
  const stylesPayType = {
    control: (base, state) => ({
      ...base,
      background: 'transparent',
      borderRadius: isMobile ? '6px' : '10px',
      borderColor: state.isFocused ? '#CCCCCC' : '#CCCCCC',
      boxShadow: state.isFocused ? 'none' : 'none',
      fontSize: isMobile ? '11px' : '14px',
      fontWeight: 500,
      width: '100%',
      height: isMobile ? '32px' : '48px',
      minHeight: isMobile ? '32px' : '38px',
      cursor: 'pointer',
    }),
    singleValue: (base) => ({
      ...base,
      color: isDarkMode ? '#FFFFFF' : '#000000',
    }),
    valueContainer: (base) => ({
      ...base,
      height: '100%',
      padding: isMobile ? '8px' : '2px 8px',
    }),
    option: (base, state) => ({
      ...base,
      fontSize: isMobile ? '11px' : '14px',
      fontWeight: 500,
      width: '100%',

      color: state.isSelected ? '#944FFF' : '#8C8C8C',
      backgroundColor: state.isSelected ? '#F0F5FF' : '#FFFFFF',
      ':active': {
        // backgroundColor: state.isSelected ? '#FFFFFF' : '#FFFFFF',
      },
      ':hover': {
        backgroundColor: '#944FFF',
        color: '#FFFFFF',
      },
      cursor: 'pointer',
    }),

    menu: (base) => ({
      ...base,
      fontSize: isMobile ? '11px' : '14px',
      background: 'white',
      borderRadius: isMobile ? '6px' : '10px',
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      fontSize: '12px',
      padding: '0',
      borderRadius: isMobile ? '6px' : '10px',
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: isDarkMode ? '#FFFFFF' : '#000000',
      placeContent: 'center',
      padding: isMobile ? '4px' : '8px',
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
  };
  const [selectedOption, setSelectedOption] = useState(data?.value);
  const getDefaultSelectedOption1 = (selectedOption) => {
    return selectedOption === 0 ? 1 : 0;
  };
  const [selectedOption1, setSelectedOption1] = useState(getDefaultSelectedOption1(selectedOption));

  const options = [
    { value: 0, label: 'KLAY', customAbbreviation: klay },
    { value: 1, label: 'WKLAY', customAbbreviation: klay },
  ];

  const formatOptionLabel = ({ value, label, customAbbreviation }) => (
    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      <img style={{ width: '20px', height: '20px' }} src={customAbbreviation} className="rounded-5"></img>
      <div>{label}</div>
    </div>
  );
  const handleConfirmModal = async () => {
    openWinningComplete();
    onHide();
  };
  const handleTypeSelect = (e) => {
    setSelectedOption(e.value);
  };
  const CustomSelectControl = (props) => <components.Control {...props}>{props.children}</components.Control>;
  const NoDropdownIndicator = () => null;

  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className="otp-code-modal otp-code-modal-cust">
        <div className="close-button" onClick={onHide}>
          {isDarkMode ? <img src={close_icon} alt="icon close" /> : <img src={close_icon_black} alt="icon close" />}
        </div>
        <div className="header-otp">Successful</div>
        <div className="line-banner"></div>
        <div className="body-otp">
          <div className="title-input-otp">From token</div>
          <div className={`${screenSize < 641 ? 'mb-4' : 'mb-36'}`}>
            <Select
              formatOptionLabel={formatOptionLabel}
              options={options}
              styles={stylesPayType}
              isSearchable={false}
              onChange={handleTypeSelect}
              isDisabled={true}
              value={options.find((option) => option.value === selectedOption) || options[0]}
              placeholder={'Select Token'}
              components={{
                Control: CustomSelectControl,
                DropdownIndicator: NoDropdownIndicator,
              }}
            />
          </div>
          <div className="title-input-otp">To token</div>
          <div className={`${screenSize < 641 ? 'mb-4' : 'mb-36'}`}>
            <Select
              formatOptionLabel={formatOptionLabel}
              options={options}
              styles={stylesPayType}
              isSearchable={false}
              onChange={(selectedOption) => {
                setSelectedOption1(getDefaultSelectedOption1(selectedOption));
              }}
              isDisabled={true}
              value={options.find((option) => option.value === selectedOption1) || options[0]}
              placeholder={'Select Token'}
              components={{
                Control: CustomSelectControl,
                DropdownIndicator: NoDropdownIndicator,
              }}
            />
          </div>
          <div className="title-input-otp mb-1">Amount</div>
          <div className={`wrapper-input-otp p-0 ${screenSize < 641 ? 'mb-4' : 'mb-36'}`}>
            <input type="text" placeholder="Amount" value={data?.balance} disabled={true} />
          </div>
          {/*<div className="title-input-otp mb-1">Recipient wallet Address</div>*/}
          {/*<div className={`wrapper-input-otp p-0 ${screenSize < 641 ? 'mb-4' : 'mb-36'}`}>*/}
          {/*  <input type="text" placeholder="Enter the wallet adress" value={data?.address} disabled={true} />*/}
          {/*</div>*/}
          <div className="title-input-otp mb-1">TXID</div>
          <div className={`wrapper-input-otp position-relative p-0 ${screenSize < 641 ? 'mb-4' : 'mb-36'}`}>
            <input
              type="text"
              placeholder="TXID"
              value={data?.txid?.slice(0, 11) + '...' + data?.txid?.slice(-9)}
              disabled={true}
            />
            <button
              className="border-0 rounded-circle btn-circle m-r-10 position-absolute left-93 left-xs-90 bg-box-modal"
              onClick={() => window.open('https://klaytnfinder.io/tx/' + data?.txid)}
            >
              <Link />
            </button>
          </div>
          <button className="button m-0 btn-detail-nft" onClick={handleConfirmModal}>
            Confirm
          </button>
        </div>
      </div>
    </ReactModal>
  );
};
export default TransferSuccessWklay;
