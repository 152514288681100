import React from 'react';

type ConvertUrlsToLinksProps = {
  text: string;
};

const ConvertUrlsToLinks: React.FC<ConvertUrlsToLinksProps> = ({ text }) => {
  const moveTo = (url: string) => {
    window.open(url, '_blank');
  };
  const _text = typeof text === 'string' ? text.replaceAll('\n', ' \n') : '';
  const splitString: any = _text.split(' ');
  const regex = /(https?:\/\/[^\s]+)/g;
  splitString.forEach((word: string, index: any) => {
    if (regex.test(word)) {
      splitString[index] = (
        <span key={index} className="convert-url-link" onClick={() => moveTo(word)}>
          {word}
        </span>
      );
    }
  });

  const output = splitString
    .map((t: any, index: number) => <span key={index}>{t}</span>)
    .reduce((accu: any, elem: any) => {
      return accu === null ? [elem] : [...accu, ' ', elem];
    }, null);
  return output;
};

export default ConvertUrlsToLinks;
