import env from './env';

const { REACT_APP_INFURA_ID } = process.env;

export const ChainId = {
  MAINNET: 1,
  GOERLI: 5,
  SEPOLIA: 11155111,
  BSCMAINNET: 56,
  BSCTESTNET: 97,
  KLAYTN: 8217,
  BAOBAB: 1001,
  POLYGON: 137,
  MUMBAI: 80001,
  TMP: 203,
  AMOY: 80002,
};

export const BridgeChainId = {
  [ChainId.MAINNET]: 101,
  [ChainId.SEPOLIA]: 10121,
  [ChainId.BSCMAINNET]: 102,
  [ChainId.BSCTESTNET]: 10102,
  [ChainId.KLAYTN]: 150,
  [ChainId.BAOBAB]: 10150,
  [ChainId.POLYGON]: 109,
  [ChainId.MUMBAI]: 10109,
};

export const NETWORK_NAME = {
  [ChainId.MAINNET]: 'Ethereum Mainnet',
  [ChainId.SEPOLIA]: 'Ethereum Sepolia',
  [ChainId.KLAYTN]: 'Klaytn Cypress',
  [ChainId.BAOBAB]: 'Klaytn Baobab',
  [ChainId.TMP]: 'Klaytn TMP Testnet',
  [ChainId.BSCMAINNET]: 'BNB Chain Mainnet',
  [ChainId.BSCTESTNET]: 'BNB Chain Testnet',
  [ChainId.POLYGON]: 'Polygon Mainnet',
  [ChainId.MUMBAI]: 'Polygon Testnet',
  [ChainId.AMOY]: 'Polygon Amoy',
};

export const SCAN_URL = {
  [ChainId.MAINNET]: 'https://etherscan.io/',
  [ChainId.SEPOLIA]: 'https://sepolia.etherscan.io/',
  [ChainId.KLAYTN]: 'https://klaytnfinder.io/',
  [ChainId.BAOBAB]: 'https://baobab.klaytnfinder.io/',
  [ChainId.BSCMAINNET]: 'https://bscscan.com/',
  [ChainId.BSCTESTNET]: 'https://testnet.bscscan.com/',
  [ChainId.POLYGON]: 'https://polygonscan.com/',
  [ChainId.MUMBAI]: 'https://mumbai.polygonscan.com/',
  [ChainId.AMOY]: 'https://amoy.polygonscan.com/',
};

const RPC_URL_MAINNET = `https://mainnet.infura.io/v3/${REACT_APP_INFURA_ID}`;
const RPC_URL_SEPOLIA = `https://sepolia.infura.io/v3/${REACT_APP_INFURA_ID}`;

export const RPC_URLS = {
  [ChainId.MAINNET]: RPC_URL_MAINNET,
  [ChainId.SEPOLIA]: RPC_URL_SEPOLIA,
  [ChainId.BSCMAINNET]: 'https://bsc-dataseed.binance.org',
  [ChainId.BSCTESTNET]: 'https://data-seed-prebsc-2-s1.bnbchain.org:8545',
  [ChainId.BAOBAB]: 'https://klaytn-baobab.blockpi.network/v1/rpc/public',
  [ChainId.KLAYTN]: 'https://klaytn-en.kommunedao.xyz:8651',
  [ChainId.TMP]: 'https://klaytn.dev.tmpcic.studio',
  [ChainId.POLYGON]: 'https://polygon-rpc.com',
  [ChainId.MUMBAI]: 'https://polygon-mumbai-pokt.nodies.app',
  [ChainId.AMOY]: 'https://rpc-amoy.polygon.technology',
};

export const IPFS_URL = 'https://ipfs.io/ipfs/';
export const ALT_URL = env.REACT_APP_ALT_URL;
export const SUCCESS = 1;
export const FAILURE = 0;
export const CANCELED = 2;

export const targetNetwork = env.REACT_APP_TARGET_NETWORK_KLAY;
export const targetNetworkMsg = env.REACT_APP_TARGET_NETWORK_MSG;

export const getTargetChainId = (chain: string) => {
  switch (chain) {
    case 'ethereum':
      return env.REACT_APP_TARGET_NETWORK_ETH;
    case 'eth':
      return env.REACT_APP_TARGET_NETWORK_ETH;
    case 'usdt':
      return env.REACT_APP_TARGET_NETWORK_ETH;
    case 'usdc':
      return env.REACT_APP_TARGET_NETWORK_ETH;
    case 'binance':
      return env.REACT_APP_TARGET_NETWORK_BNB;
    case 'bnb':
      return env.REACT_APP_TARGET_NETWORK_BNB;
    case 'polygon':
      return env.REACT_APP_TARGET_NETWORK_MATIC;
    case 'matic':
      return env.REACT_APP_TARGET_NETWORK_MATIC;
    case 'klaytn':
    case 'klay':
    case 'wklay':
    default:
      return env.REACT_APP_TARGET_NETWORK_KLAY;
  }
};
