// @ts-nocheck
import { useEffect, useState } from 'react';
import './talkclaim.css';
import CLAIMER from './Claimer.json';
import { ethers } from 'ethers';
import axios from 'axios';
import { BigNumber as eBigNumber } from '@ethersproject/bignumber';
import HelperUtil from '../utils/HelperUtil';
import tokenAbi from '../config/abi/ERC20Token.json';
import { seaport } from '../utils/seaport';
import { OrderWithCounter } from '@colligence/seaport-js/lib/types';
import { RPC_URLS } from '../config';
import contracts from '../config/constants/contracts';

function Test() {
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState();
  const [offer, setOffer] = useState();
  const target = 1001;

  const test1Handler = async () => {
    try {
      setLoading(true);
      console.log(1);
      const abcUser = JSON.parse(window.localStorage.getItem('abcUser'));
      const ethAddress = abcUser?.accounts[0]?.ethAddress;
      const provider = await HelperUtil.getAbcWeb3Provider(target);
      console.log('provider:::', provider);

      console.log('!! My ABC Wallet Address ', ethAddress);
      const contract = new ethers.Contract('0x8BEbcD85a7F99679be82eD11d61a52020EFAfB4C', tokenAbi, provider);
      const claimBalanceTxn = await contract.balanceOf('0x34dcdc13ba31de50d9709e3804ebdd5a5b17a7bb');
      console.log('asdasdasdasd3', claimBalanceTxn);

      const params = [
        {
          from: '0x55F04E986eB305A65cB570791EED353f0a1D031e',
          to: '0x55F04E986eB305A65cB570791EED353f0a1D031e',
          value: ethers.utils.parseUnits('0.1', 'ether').toHexString(),
          gasLimit: '21000',
        },
      ];
      const transactionHash = await provider.send('eth_sendTransaction', params);
      console.log('transactionHash is ' + transactionHash);
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    } finally {
      setLoading(false);
    }
  };

  const makeOrderHandler = async () => {
    try {
      setLoading(true);
      console.log('!! start make order ...');
      const abcUser = JSON.parse(window.localStorage.getItem('abcUser'));
      const ethAddress = abcUser?.accounts[0]?.ethAddress;
      const provider = await HelperUtil.getAbcWeb3Provider(target);
      console.log('provider:::', provider, provider.provider.chainId);
      console.log('ABC Address:::', ethAddress);

      let order;
      try {
        // Fixed Price
        order = await seaport.createOrder({
          signer: provider,
          tokenContractAddress: '0x895314741fbc297d6de5a9f4d70c543955523e7b',
          tokenId: '508',
          itemType: 2,
          price: '1',
          quoteContractAddress: contracts.usdc[1001], // Taklen USDT
          endTime: '1701053342', // one month
          creatorAddress: '0x1716C4d49E9D81c17608CD9a45b1023ac9DF6c73',
          zone: '0xdc926E34E73292cD7c48c6fD7375af7D93435D36',
          quote: 'usdc',
        });
        // English Auction
        // order = await seaport.createOrder({
        //   signer: provider,
        //   tokenContractAddress: '0x7ec53338105c70b726283ab3bfd8110f8835198f',
        //   tokenId: '13',
        //   price: '0',
        //   quoteContractAddress: contracts.usdc[1001], // Taklen USDT
        //   endTime: '1701053342', // one month
        //   creatorAddress: '0x1716C4d49E9D81c17608CD9a45b1023ac9DF6c73',
        // });
        setOrder(order);
        console.log('!!!!!!!!!!!!!!!!!!', order);
      } catch (e) {
        console.log('!!!!! create order error', e.message);
      }
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    } finally {
      setLoading(false);
    }
  };

  const makeBidHandler = async () => {
    try {
      setLoading(true);
      console.log('!! start bid offer ...');
      const provider = new ethers.providers.JsonRpcProvider(RPC_URLS[1001]);
      const wallet = new ethers.Wallet(process.env.REACT_APP_OFFERER_PRIKEY);
      const signer = wallet.connect(provider);

      let offer;
      try {
        offer = await seaport.bidOffer({
          signer,
          tokenContractAddress: '0x7ec53338105c70b726283ab3bfd8110f8835198f',
          tokenId: '13',
          price: '1',
          quoteContractAddress: contracts.usdc[1001], // Taklen USDT
          endTime: '1701053342', // one month
          creatorAddress: '0x1716C4d49E9D81c17608CD9a45b1023ac9DF6c73',
        });
        setOffer(offer);
      } catch (e) {
        console.log('!!!!! bid offer error', e.message);
      }
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    } finally {
      setLoading(false);
    }
  };

  const cancelOrderHandler = async () => {
    setLoading(true);
    const provider = new ethers.providers.JsonRpcProvider(RPC_URLS[1001]);
    const wallet = new ethers.Wallet(process.env.REACT_APP_ZONE_PRIKEY);
    const signer = wallet.connect(provider);

    let params = [];
    params.push(order?.parameters);
    params.push(offer?.parameters);
    console.log('===========>', params);

    await seaport.cancelOrders(signer, params);
    setLoading(false);
  };

  const validateHandler = async () => {
    setLoading(true);
    const provider = new ethers.providers.JsonRpcProvider(RPC_URLS[1001]);
    const wallet = new ethers.Wallet(process.env.REACT_APP_ZONE_PRIKEY);
    const signer = wallet.connect(provider);

    let offers = [];
    offers.push(offer);

    await seaport.validate(signer, offers);
    setLoading(false);
  };

  const fulfillmentHandler = async () => {
    try {
      setLoading(true);
      console.log('!! start fulfillment ...');
      const abcUser = JSON.parse(window.localStorage.getItem('abcUser'));
      const ethAddress = abcUser?.accounts[0]?.ethAddress;
      const provider = await HelperUtil.getAbcWeb3Provider(target);
      console.log('provider:::', provider, provider.provider.chainId);
      console.log('ABC Address:::', ethAddress);

      try {
        const receipt = await seaport.seaportFulfillOrder(provider, order ?? offer);
        console.log('!! receipt : ', receipt);
      } catch (e) {
        console.log('!!!!! fulfillment error', e.message);
      }
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fulfillByZoneHandler = async () => {
    try {
      setLoading(true);
      console.log('!! start fulfillment by zone ...');
      const provider = new ethers.providers.JsonRpcProvider(RPC_URLS[1001]);
      const wallet = new ethers.Wallet(process.env.REACT_APP_ZONE_PRIKEY);
      const signer = wallet.connect(provider);

      try {
        const receipt = await seaport.seaportFulfillOrder(signer, offer);
        console.log('!! receipt : ', receipt);
      } catch (e) {
        console.log('!!!!! fulfillment by zone error', e.message);
      }
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getMyBalanceButton = () => {
    return (
      <button onClick={test1Handler} disabled={loading} className="cta-button claim-button">
        {loading ? 'Processing' : 'test1'}
      </button>
    );
  };
  const makeOrder = () => {
    // seaport test
    return (
      <button onClick={makeOrderHandler} disabled={loading} className="cta-button claim-button">
        {loading ? 'Processing' : 'Make Order'}
      </button>
    );
  };
  const bidOrder = () => {
    // seaport test
    return (
      <button onClick={makeBidHandler} disabled={loading} className="cta-button claim-button">
        {loading ? 'Processing' : 'Bid Offer by Offerer'}
      </button>
    );
  };
  const cancelOrder = () => {
    // seaport test
    return (
      <button onClick={cancelOrderHandler} disabled={loading} className="cta-button claim-button">
        {loading ? 'Processing' : 'Cancel Order by Zone'}
      </button>
    );
  };
  const fulfullment = () => {
    // seaport test
    return (
      <button onClick={fulfillmentHandler} disabled={loading} className="cta-button claim-button">
        {loading ? 'Processing' : 'Fulfillment by Seller'}
      </button>
    );
  };

  const fulfullByZone = () => {
    // seaport test
    return (
      <button onClick={fulfillByZoneHandler} disabled={loading} className="cta-button claim-button">
        {loading ? 'Processing' : 'Fulfillment by Zone'}
      </button>
    );
  };

  const validate = () => {
    // seaport test
    return (
      <button onClick={validateHandler} disabled={loading} className="cta-button claim-button">
        {loading ? 'Processing' : 'Validate Orders'}
      </button>
    );
  };

  return (
    <div className="main-app">
      <>
        <h1>Test Page</h1>
        <br />
        <div className="button-div">{getMyBalanceButton()}</div>
        <br />
        <br />
        <div className="button-div">{makeOrder()}</div>
        <br />
        <br />
        <div className="button-div">{bidOrder()}</div>
        <br />
        <br />
        <div className="button-div">{fulfullment()}</div>
        <br />
        <br />
        <div className="button-div">{cancelOrder()}</div>
        <br />
        <br />
        <div className="button-div">{validate()}</div>
        <br />
        <br />
        <div className="button-div">{fulfullByZone()}</div>
        <br />
      </>
    </div>
  );
}

export default Test;
