// @ts-nocheck
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-var-requires */

import axios from 'axios';
const mcache = require('memory-cache');
const isProduction = process.env.REACT_APP_PHASE === 'production';

const getAccountTokens = async (userEthAddr) => {
  if (!userEthAddr) return;

  const cached = mcache.get('getAccountTokens');
  if (cached) return cached;
  const klayNftListUrl = `${process.env.REACT_APP_API_SERVER}/api/service/blockchain/getAccountTokens?address=${userEthAddr}&isMainnet=${isProduction}`;
  let klayNftListRes = await axios.get(klayNftListUrl).catch((err) => console.log(`klayNftListResErr::${err.message}`));
  // 나중에 klayNftListRes?.data?.klayTokensArray 를 제거한다. klaytn 외 EVM 통합 klayNftListRes?.data?.tokens
  klayNftListRes = klayNftListRes?.data?.klayTokensArray || klayNftListRes?.data?.tokens || [];

  mcache.put('getAccountTokens', klayNftListRes, 5000);
  return klayNftListRes;
};

export default getAccountTokens;
