import React, { useEffect, useRef, useState } from 'react';
import { setEthereum, setActivatingConnector, setKlaytn } from '../../redux/slices/wallet';
import { useDispatch, useSelector } from 'react-redux';
import { BigNumber, ethers, utils } from 'ethers';
import {
  injected,
  kaikas,
  abc,
  // walletconnect,
  walletconnectv2,
  // talkenwallet,
} from '../../hooks/connectors';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import axios from 'axios';
import ReactModal from 'react-modal';
import { ReactComponent as IconClose } from '../../assets/icon/close_icon.svg';
import close_icon_black from '../../assets/icon/close_icon_black.svg';
import { useThemeMode } from 'components/common/AppStoreType';
import { useMediaQuery } from 'react-responsive';
import talk from '../../assets/icon/talkKlaytn.svg';
import check_connect from '../../assets/icon/check_connect.png';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { getPrice } from '../../utils/getPrice';
import check_all_connect from '../../assets/icon/check_all_connect.png';
import check_all_connect_light from '../../assets/icon/check_all_connect_light.png';
import checked_all_connect from '../../assets/icon/checked_all_connect.png';
const delay = (time = 1000) => new Promise((resolve) => setTimeout(resolve, time));
type ClaimRewards = {
  show: any;
  onHide: any;
  openComplete: any;
  claimableAmount: any;
};
const ClaimRewards: React.FC<ClaimRewards> = ({ show, onHide, openComplete, claimableAmount }) => {
  const dispatch = useDispatch();
  const { activate, deactivate, account, library } = useActiveWeb3React();
  const abcUser = JSON.parse(window.localStorage.getItem('abcUser'));
  const ethAddress = abcUser?.accounts[0]?.ethAddress;
  const isSns = window.localStorage.getItem('login') === 'sns';
  const [toAddress, setToAddress] = useState(isSns ? ethAddress : null);

  const [loading, setLoading] = useState(false);
  const { isDarkMode } = useThemeMode();
  const isMobile = useMediaQuery({ query: '(max-width: 641px)' });
  const [isCheckClaim, setIcheckClaim] = useState(false);
  const timer = useRef<number>();
  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);
  const toAddressCheck = async () => {
    if (!toAddress) {
      if (isSns) window.location.reload();
      if (!library) {
        await activate(injected, undefined, true);
        dispatch(setActivatingConnector(injected));
        window.localStorage.setItem('login', 'metamask');
      }
      const chainId = await library?.getSigner()?.getChainId();
      const address = await library?.getSigner()?.getAddress();
      const nonce = await library?.getSigner()?.getTransactionCount();
      const message = `Welcome to Talken! Click to sign in and accept the Talken Terms of Service (https://apps.talken.io/terms-conditions) and Privacy Policy (https://apps.talken.io/privacy-policy). This request will not trigger a blockchain transaction or cost any gas fees. Wallet address: ${address} Nonce: ${nonce}`;
      const domain = { chainId }; // All properties on a domain are optional
      const types = { Mail: [{ name: 'contents', type: 'string' }] };
      const mail = { contents: message }; // The data to sign
      const signature = await library
        ?.getSigner()
        ?._signTypedData(domain, types, mail)
        .catch(() => deactivate());
      if (!signature) return; // 서명 거부
      const signingAddress = ethers.utils.verifyTypedData(domain, types, mail, signature)?.toLowerCase();
      setToAddress(signingAddress);
      return signingAddress;
    } else return null;
  };
  const handleConfirmModal = async () => {
    try {
      if (loading) return;
      setLoading(true);
      await delay(1000);
      const signingAddress = await toAddressCheck();
      console.log('signingAddress:::', signingAddress);
      console.log('toAddress:::', signingAddress || toAddress);

      if (!signingAddress && !toAddress) return alert('Please click again for connect wallet.');

      const result = await axios.post(
        `${process.env.REACT_APP_API_SERVER}/api/reward/claimPoint`,
        { toAddress: signingAddress || toAddress },
        { withCredentials: true }
      );
      console.log('result:::', result);
      if (result.data?.status !== 1) {
        const errMsg = result.data?.message;
        // alert(errMsg);
        openComplete();
        onHide();
      } else {
        // alert('Claim success!!');
        openComplete();
        onHide();
      }

      // timer.current = window.setTimeout(() => {
      //   setLoading(false);
      //   openComplete();
      //   onHide();
      // }, 1000);
    } finally {
      setLoading(false);
    }
  };
  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className="otp-code-modal otp-code-modal-custom-claim-rewards">
        <div className="close-button" onClick={onHide}>
          <IconClose className="w-100 h-100 close-icon dark:close-icon" />
        </div>
        <div className="header-otp">Claim Rewards</div>
        <div className="line my-3"></div>
        <div className="body-otp body-otp-claim">
          <div className="p-b-20 p-b-xs-10 text-center text-white opacity-7 fs-14 fw-6">Claimable Rewards</div>
          <div className="wrapper-total-claim wrapper-total-claim-custom">
            <img width={32} height={32} src={talk} alt="" />
            <div className="fw-7">{claimableAmount ? getPrice(claimableAmount, '') : 0}</div>
          </div>
          <div className="p-t-20">
            <div className="note-claim-rewards">
              <img src={check_connect} width={20} alt="Check Icon" />
              <div>Reward TALK is Klaytn-based Wormhole Bridged TALK.</div>
            </div>
            <div className="note-claim-rewards">
              <img src={check_connect} width={20} alt="Check Icon" />
              <div>Do not transfer Wormhole Bridged TALK directly to the exchange.</div>
            </div>
            <div className="note-claim-rewards">
              <img src={check_connect} width={20} alt="Check Icon" />
              <div>
                <span>Wormhole Bridged TALK can be converted to ERC20 TALK at </span>
                <Link to="/bridge" className="color-2f8af5 text-decoration-underline hover-opa-7">
                  Talken Bridge
                </Link>
              </div>
            </div>
            <div className="note-claim-rewards">
              <img src={check_connect} width={20} alt="Check Icon" />
              <div>All remaining rewards will be claimed at once.</div>
            </div>
            <div className="note-claim-rewards">
              <img src={check_connect} width={20} alt="Check Icon" />
              <div>When you submit a claim, it will be processed through your currently connected wallet.</div>
            </div>
            <div className="note-claim-rewards">
              <img src={check_connect} width={20} alt="Check Icon" />
              <div>Because a claim is made on-chain in the blockchain, once it is executed it cannot be reversed.</div>
            </div>
          </div>
          <div className="d-flex gap-8px align-items-center">
            <div
              className="w-24px h-24px"
              onClick={() => {
                setIcheckClaim(!isCheckClaim);
              }}
            >
              {isCheckClaim ? (
                <img className="w-100" src={checked_all_connect} alt="Check" />
              ) : isDarkMode ? (
                <img className="w-100" src={check_all_connect} alt="Check All" />
              ) : (
                <img className="w-100" src={check_all_connect_light} alt="Check" />
              )}
            </div>
            <div className="fs-12 fw-400">I confirm that I have read, understand and agree to the above.</div>
          </div>
          <button
            className={`btn-detail-nft button mb-0 ${(!isCheckClaim || loading) && 'opacity-3'}`}
            onClick={handleConfirmModal}
            disabled={!isCheckClaim || loading}
          >
            {loading ? <CircularProgress size={30} color={'inherit'} /> : <>Claim</>}
          </button>
        </div>
      </div>
    </ReactModal>
  );
};
export default ClaimRewards;
