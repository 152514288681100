import ReactModal from 'react-modal';
import { ReactComponent as IconClose } from 'assets/icon/close_icon.svg';
import logoTwitter from 'assets/icon/logo_twitter.png';
import logoFacebook from 'assets/icon/logo_facebook.png';
import linkShare from 'assets/icon/share_black.png';
import { useNavigate } from 'react-router-dom';
import useCopyToClipBoard from 'hooks/useCopyToClipboard';
import CSnackbar from 'components/common/CSnackbar';
import { useEffect, useState } from 'react';
import { HandleMediaError } from 'components/common/HandleMediaError';

const ListingCompleteModal = ({ show, onHide, data, sellData }) => {
  const { copyToClipBoard, copyResult, setCopyResult } = useCopyToClipBoard();
  const handleConfirmModal = async () => {
    // onHide();
    moveToUrl();
  };
  const navigate = useNavigate();
  const moveToUrl = () => {
    const newData = { ...data };
    newData.sellbook = sellData;
    navigate(
      `/sellbook/${sellData?.id}/${sellData?.nftInfo?.chain}/${sellData?.nftInfo?.contractAddress}/${sellData?.tokenId}`,
      {
        state: {
          item: {
            ...newData?.sellbook,
          },
        },
      }
    );
    // const newData = { ...data };
    // newData.sellbook = sellData;
    // const stateData = {
    //   // ...data,
    //   ...newData,
    //   companyimage: data.featured?.company.image,
    //   companyname: data.featured?.company.name,
    // };
    // navigate('/my-items/details', {
    //   state: {
    //     item: {
    //       ...stateData,
    //     },
    //   },
    // });
  };
  const handleCopy = () => {
    const url =
      '/sellbook/' +
      sellData?.id +
      '/' +
      sellData?.nftInfo?.chain +
      '/' +
      sellData?.nftInfo?.contractAddress +
      '/' +
      sellData?.tokenId; // Update this with your desired URL
    const fullUrl = `${window.location.origin}${url}`;
    copyToClipBoard(fullUrl);
  };
  const handleShareTwitter = () => {
    const url =
      '/sellbook/' +
      sellData?.id +
      '/' +
      sellData?.nftInfo?.chain +
      '/' +
      sellData?.nftInfo?.contractAddress +
      '/' +
      sellData?.tokenId; // Update this with your desired URL
    const fullUrl = `${window.location.origin}${url}`;
    const quote = ''; // Replace with your desired quote
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      fullUrl
    )}&text=${encodeURIComponent(quote)}`;
    window.open(twitterShareUrl, '_blank');
  };
  const handleSharefb = () => {
    const url = '/my-items/details'; // Update this with your desired URL
    const fullUrl = `${window.location.origin}${url}`;
    const quote = ''; // Replace with your desired quote
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      fullUrl
    )}&quote=${encodeURIComponent(quote)}`;

    // Open the Facebook Share Dialog in a new window
    window.open(facebookShareUrl, '_blank');
  };
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    type: '',
    message: '',
  });
  const handleCloseSnackbar = () => {
    setOpenSnackbar({
      open: false,
      type: '',
      message: '',
    });
  };
  useEffect(() => {
    setOpenSnackbar({
      open: copyResult,
      type: 'success',
      message: 'Copied!',
    });
  }, [copyResult]);
  return (
    <>
      <ReactModal
        isOpen={show}
        onRequestClose={onHide}
        className="Modal"
        overlayClassName="detail-nft-overlay"
        ariaHideApp={false}
      >
        <div className="modal-primary w-430px w-xs-338px h-fit-content">
          <div className="p-24 p-xs-20 d-flex flex-column align-items-center gap-2">
            <div className="w-164px h-164px w-xs-120px h-xs-120px m-b-16">
              {data?.alt_url?.split('.')?.pop() === 'mp4' ||
              data?.extension === 'mp4' ||
              data?.metadata?.alt_url?.split('.')?.pop() === 'mp4' ||
              data?.metadata?.extension === 'mp4' ||
              data?.image?.split('.')?.pop() === 'mp4' ||
              data?.nftInfo?.image?.split('.')?.pop() === 'mp4' ? (
                <video
                  playsInline
                  webkit-playsinline="true"
                  muted
                  controlsList="nodownload"
                  width={'100%'}
                  className="h-100 object-fit-cover w-100 border-radius-16"
                  onError={HandleMediaError}
                  key={`${(data.image || data?.nftInfo?.image || data?.metadata?.image)
                    ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                    .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}#t=0.001`}
                >
                  <source
                    src={`${(data.image || data?.nftInfo?.image || data?.metadata?.image)
                      ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                      .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}#t=0.001`}
                    type="video/mp4"
                  />
                </video>
              ) : (
                <>
                  <img
                    src={
                      data?.alt_url ||
                      data?.image ||
                      data?.metadata?.alt_url ||
                      data?.metadata?.image
                        ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                        .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')
                    }
                    alt=""
                    draggable={false}
                    className="h-100 object-fit-cover w-100 border-radius-16"
                    onError={HandleMediaError}
                  />
                </>
              )}
            </div>
            <div className="text-center fs-20 fs-xs-16 fw-9 fw-xs-7">Listing Complete!</div>
            <span className="fs-12 fs-xs-10 p-b-16 p-b-xs-8 title-input-modal fw-6 p-l-34 p-r-34 text-center">
              <span className="fw-7 color-primary">
                {data?.metadata?.name
                  ? data?.metadata?.name
                  : typeof data?.name === 'string'
                  ? data?.name
                  : data?.name?.en}{' '}
              </span>
              has been listed for sale.
            </span>
            <div className="line-share-to fs-12 fs-xs-10">
              <span className="title-share-to"> Share to </span>
            </div>
            <div className="wrapper-share-sns bg-box-modal d-flex justify-content-evenly p-10 rounded-3 w-100 m-b-14 m-b-xs-8">
              <div className="icon-share-sns d-flex justify-content-center align-items-center" onClick={handleCopy}>
                <img src={linkShare} alt="" draggable={false} />
              </div>
              <div
                className="icon-share-sns d-flex justify-content-center align-items-center"
                onClick={handleShareTwitter}
              >
                <img src={logoTwitter} alt="" draggable={false} />
              </div>
              <div className="icon-share-sns d-flex justify-content-center align-items-center" onClick={handleSharefb}>
                <img src={logoFacebook} alt="" draggable={false} />
              </div>
            </div>
            <button className="my-auto button btn-detail-nft h-50px h-xs-40px" onClick={handleConfirmModal}>
              View Listing
            </button>
          </div>
        </div>
        <CSnackbar
          open={openSnackbar.open}
          type={openSnackbar.type}
          message={openSnackbar.message}
          handleClose={handleCloseSnackbar}
        />
      </ReactModal>
    </>
  );
};
export default ListingCompleteModal;
