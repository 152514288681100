import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-multi-carousel/lib/styles.css';
import close_icon from '../../assets/icon/close_icon.svg';
import PaymentWallets from 'components/modal/PaymentWallets';
import PaymentWalletsSuccess from 'components/modal/PaymentWalletsSuccess';
import { MBoxTypes } from '../../types/MBoxTypes';
import { getMboxItemListMboxId, getMysteryBoxInfo } from '../../services/services';
import { MBoxItemTypes } from '../../types/MBoxItemTypes';
import MBoxItemCard from '../../components/card/MBoxItemCard';
import { CircularProgress, ImageList, ImageListItem } from '@mui/material';
import { getKeyRemains } from '../../utils/marketTransactions';
import ConvertUrlsToLinks from '../../utils/convertUrlsToLinks';
import { SUCCESS } from '../../config';
import { useWeb3React } from '@web3-react/core';
import {
  checkConnectWallet,
  checkKaikas,
  checkKaikasWallet,
  getTargetNetworkName,
  getTargetWallet,
} from '../../utils/wallet';
import WalletConnector from '../../components/auth/WalletConnector/WalletConnector';
import Popup from 'reactjs-popup';
import CSnackbar from '../../components/common/CSnackbar';
import CountDownTimer from '../../components/TimeCounter/CountDownTimer';
import { useSelector } from 'react-redux';
import { getNetworkNameById } from '../../utils/getNetworkNameById';
import { getItemAmountNoSigner } from '../../utils/transactions';
import ReactModal from 'react-modal';
import useOnClickOutsideDropdown from 'components/common/useOnClickOutside';
import { FeaturedTypes } from 'types/FeaturedTypes';
import { getPrice } from '../../utils/getPrice';
import GetSnsButtons from 'components/getsnsbuttons/GetSnsButtons';
import { HandleMediaError } from 'components/common/HandleMediaError';

type ExMBoxType = MBoxTypes & {
  companyLogo: string;
  companyName: string;
};
const overlayStyle = { background: 'rgba(0,0,0,0.8)' };
const closeOnDocumentClick = false;
const lockScroll = true;

const SaleCollectibles = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { account, library, chainId } = useWeb3React();
  // const dropsAccount = useSelector((state: any) => state.account.account);
  const user = useSelector((state: any) => state.user.user);
  const uid = user.uid;
  const userEthAddr = user.eth_address?.toLowerCase();
  const [mBoxInfo, setMBoxInfo] = useState<ExMBoxType | null>(null);
  const [remains, setRemains] = useState(0);
  const [mBoxItemList, setMBoxItemList] = useState<MBoxItemTypes[]>([]);
  const [openPaymentWallets, setOpenPaymentWallets] = useState(false);
  const [openPaymentWalletsSuccess, setOpenPaymentWalletsSuccess] = useState(false);
  const [showCountDown, setShowCountDown] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [signupOpen, setSignupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countDownFinish, setCountDownFinish] = useState(false);
  const wallet = useSelector((state: any) => state.wallet);
  const { activate } = useWeb3React();
  const [isZoomImage, setIsZoomImage] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    type: '',
    message: '',
  });
  const [featuredInfo, setFeaturedInfo] = useState<FeaturedTypes | null>(null);
  const [quote, setQuote] = useState('');

  const handeCheckCountDownFinish = () => {
    setCountDownFinish(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar({
      open: false,
      type: 'info',
      message: '',
    });
  };

  const closeLogin = () => {
    setLoginOpen(false);
  };
  const closeSignup = () => {
    setSignupOpen(false);
  };

  const handleClosePaymentWallet = async () => {
    setOpenPaymentWalletsSuccess(false);
    if (location.state.item) getAvailability(location.state.item);
  };

  const getAvailability = async (info: ExMBoxType) => {
    const isKaikas = checkKaikasWallet(wallet, getTargetNetworkName(info?.chainId) ?? '');
    const left = await getKeyRemains(info.keyContractAddress, info.boxContractAddress, null, false);
    setRemains(left);
  };

  const moveToFeatured = () => {
    if (mBoxInfo) navigate(`/creator/${mBoxInfo.featuredId}`);
  };

  useEffect(() => {
    const fetchMysteryBoxInfo = async () => {
      const mboxInfoRes = await getMysteryBoxInfo(params.id!);
      if (mboxInfoRes.data.status === SUCCESS) {
        setMBoxInfo(mboxInfoRes.data.data);
      }
    };
    fetchMysteryBoxInfo();
  }, [params, library]);

  useEffect(() => {
    const fetchMboxItemList = async () => {
      if (mBoxInfo) {
        const mboxItemsRes = await getMboxItemListMboxId(params.id!);
        if (mboxItemsRes.status === 200) {
          if (mboxItemsRes.data.list) {
            const newList = await Promise.all(
              mboxItemsRes.data.list.map(async (item: MBoxTypes, index: number) => {
                const remaining = await getItemAmountNoSigner(
                  mBoxInfo.boxContractAddress,
                  index,
                  item?.isCollection === true ? 2 : 1, // 1 = MysteryBox, 2 = Collection
                  account,
                  mBoxInfo?.chainId ?? 8217
                );
                return { ...item, remainingAmount: remaining };
              })
            );
            setMBoxItemList(newList);
          }
        }
        setQuote(mBoxInfo.fiatPrice ? 'KRW' : mBoxInfo.quote || 'null');
      }
    };
    fetchMboxItemList();
  }, [mBoxInfo]);

  useEffect(() => {
    if (account && library?.connection && mBoxInfo) {
      const targetWallet = getTargetWallet(mBoxInfo?.chainId, wallet);
      const network = getTargetNetworkName(mBoxInfo?.chainId) ?? '';
      const isKaikas = checkKaikasWallet(wallet, network);
      if ((isKaikas && targetWallet === 'metamask') || (!isKaikas && targetWallet === 'kaikas')) {
        checkConnectWallet(mBoxInfo?.chainId, wallet, activate);
        return;
      }

      getAvailability(mBoxInfo);
    }
  }, [account, library, mBoxInfo]);

  useEffect(() => {
    if (mBoxInfo && mBoxInfo?.afterRelease) {
      const today = new Date();
      const targetDate = new Date(mBoxInfo.afterRelease);

      if (today < targetDate) setShowCountDown(true);
      else {
        setShowCountDown(false);
      }
    } else {
      setShowCountDown(false);
    }
  }, [mBoxInfo?.afterRelease, showCountDown, new Date()]);

  return (
    <main className="collection-container min-height-content">
      {mBoxInfo ? (
        <div>
          <div className="price-collection-view-page">
            <div className="price-collection-box">
              <div className="token-details-box-mobile">
                <div className="wrapper-head-token">
                  <div onClick={moveToFeatured}>
                    <div className="box-owner-product">
                      <button className="btn-avatar-owner-product">
                        <img src={mBoxInfo.featured?.company.image} alt={mBoxInfo.featured?.company.name.en} />
                      </button>
                      <div className="name-owner-product">
                        <div className="creator-title">Creator</div>
                        <button className="btn-name-owner-product">{mBoxInfo.featured?.company.name.en}</button>
                      </div>
                    </div>
                  </div>
                  {/* list sns icon */}
                  <GetSnsButtons
                    links={featuredInfo?.links}
                    chainId={mBoxInfo.chainId}
                    boxContractAddress={mBoxInfo?.boxContractAddress}
                  />
                </div>
              </div>
              <div className="token-showcase-box">
                {/* button zoom image */}
                <div className="zoom-image" onClick={() => setIsZoomImage(true)}>
                  <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <path d="M595.2 96l129.8 129.8-499.2 499.2L96 595.2V928h332.8l-129.8-129.8 499.2-499.2 129.8 129.8V96z"></path>
                  </svg>
                </div>
                {mBoxInfo.revealAnimation === null ? (
                  // <img
                  //   style={{ objectFit: 'cover' }}
                  //   src={mBoxInfo.packageImage}
                  //   alt=""
                  // />
                  <>
                    {mBoxInfo.packageImage.indexOf('.mp4') > -1 ? (
                      <div>
                        <video
                          controls
                          muted
                          autoPlay
                          playsInline
                          loop
                          onError={HandleMediaError}
                          key={mBoxInfo.packageImage
                            ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                            .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}
                        >
                          <source
                            src={mBoxInfo.packageImage
                              ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                              .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    ) : (
                      <>
                        <img
                          // style={{ objectFit: 'cover' }}
                          src={mBoxInfo.packageImage}
                          alt=""
                          onError={HandleMediaError}
                        />
                        {/* modal zoom image */}
                        <ReactModal isOpen={isZoomImage} className={'modal-zoom-image'}>
                          <div className="close-modal" onClick={() => setIsZoomImage(false)}>
                            <img src={close_icon} alt="Close Icon" />
                          </div>
                          <img className="image" src={mBoxInfo.packageImage} alt="" />
                        </ReactModal>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {mBoxInfo.revealAnimation.indexOf('.mp4') > -1 ? (
                      <div>
                        <video
                          controls
                          muted
                          autoPlay
                          playsInline
                          loop
                          onError={HandleMediaError}
                          key={mBoxInfo.revealAnimation
                            ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                            .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}
                        >
                          <source
                            src={mBoxInfo.revealAnimation
                              ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                              .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    ) : (
                      <>
                        <img src={mBoxInfo.revealAnimation} alt="" onError={HandleMediaError} />
                        {/* modal zoom image */}
                        <ReactModal isOpen={isZoomImage} className={'modal-zoom-image'}>
                          <div className="close-modal" onClick={() => setIsZoomImage(false)}>
                            <img src={close_icon} alt="Close Icon" />
                          </div>
                          <img className="image" src={mBoxInfo.revealAnimation} alt="" />
                        </ReactModal>
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="straight-line"></div>
              <div className="token-details-box">
                <div className="wrapper-head-token">
                  <div onClick={moveToFeatured}>
                    <div className="box-owner-product">
                      <button className="btn-avatar-owner-product">
                        <img src={mBoxInfo.featured?.company.image} alt={mBoxInfo.featured?.company.name.en} />
                      </button>
                      <div className="name-owner-product">
                        <div className="creator-title">Creator</div>
                        <button className="btn-name-owner-product">{mBoxInfo.featured?.company.name.en}</button>
                      </div>
                    </div>
                  </div>
                  {/* list sns icon */}
                  <GetSnsButtons
                    links={featuredInfo?.links}
                    chainId={mBoxInfo.chainId}
                    boxContractAddress={mBoxInfo?.boxContractAddress}
                  />
                </div>
                <div className="bottom-line" />
                <div>
                  <div className="btn-buy-now">Buy Now</div>
                </div>
                <div>
                  <div className="box-name-collection">
                    <div className="name-collection fw-600">{mBoxInfo.title.en}</div>
                  </div>
                </div>
                <div>
                  <div className="content-collection">
                    <ConvertUrlsToLinks text={mBoxInfo.introduction.en || 'no description'} />
                  </div>
                </div>
                {/*<div>*/}
                {/*  <a className="authenticity-button">*/}
                {/*    <img src={ic_info} style={{ marginRight: '5px' }} alt="" />{' '}*/}
                {/*    Authenticity*/}
                {/*  </a>*/}
                {/*</div>*/}
                <div>
                  <div className="box-price-collection">
                    <div className="box-price-detail-collection">
                      <div className="lable-top">Total Items</div>
                      <div className="lable-bottom fw-600">{mBoxInfo.totalAmount}</div>
                    </div>
                    <div className="box-price-detail-collection">
                      <div className="lable-top">Availability</div>
                      <div className="lable-bottom fw-600">{remains}</div>
                    </div>
                    <div className="box-price-detail-collection">
                      <div className="lable-top">Chain</div>
                      <div className="lable-bottom fw-600" style={{ textTransform: 'capitalize' }}>
                        {getNetworkNameById(mBoxInfo.chainId)}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="box-purchase-price">
                    <div className="lable-top">Purchase price</div>
                    <div className="lable-bottom fw-600">
                      {mBoxInfo?.price === 0 ? 'FREE' : getPrice(mBoxInfo?.fiatPrice || mBoxInfo?.price || 0, quote)}
                    </div>
                  </div>
                  {!countDownFinish && (
                    <CountDownTimer
                      handeCheckCountDownFinish={handeCheckCountDownFinish}
                      targetDate={new Date(mBoxInfo.releaseDatetime)}
                    />
                  )}
                  {account && library?.connection && userEthAddr !== '' ? (
                    <>
                      {countDownFinish && (
                        <button
                          className={'btn-sale-collection'}
                          disabled={isLoading || remains === 0}
                          onClick={() => setOpenPaymentWallets(true)}
                          // onClick={handleBuyClick}
                        >
                          {isLoading ? (
                            <CircularProgress size={30} color={'inherit'} />
                          ) : remains === 0 ? (
                            'Sold out'
                          ) : (
                            'Buy Now'
                          )}
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      className={'btn-sale-collection'}
                      onClick={() => {
                        if (uid !== '') {
                          setLoginOpen(true);
                        } else {
                          navigate('/accounts');
                        }
                      }}
                    >
                      Buy Now
                    </button>
                  )}
                  {/* <button className="btn-sale-collection disable">Sold out</button> */}
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className="title-sale-by-Collectors fw-600">Items in the mystery box</div>
                <div className="sub-title-sale-by-Collectors fw-600">You will get one of the items below.</div>
              </div>

              <div className="puzzle-container">
                <div>
                  <ImageList className="puzzle" cols={13}>
                    {mBoxItemList.map((item, index) => (
                      <ImageListItem key={index}>
                        <img src={item.itemImage} srcSet={item.itemImage} alt={''} loading="lazy" />
                        {/*<div style={{ position: 'absolute', color: 'white' }}>*/}
                        {/*  {index}*/}
                        {/*</div>*/}
                      </ImageListItem>
                    ))}
                  </ImageList>
                </div>
              </div>
              <div>
                <div className="title-sale-by-Collectors fw-600">{/*For Sale by Collectors*/}</div>
                <div className="sub-title-sale-by-Collectors fw-600">
                  {/*Sold out? No problem! Check out user listings below.*/}
                </div>
              </div>
              <div className="userSales">
                {/* <div className="filter-box">
                  <div className="search-box">
                    <img
                      src={ic_search}
                      style={{ margin: '0px 5px 0px 20px' }}
                      alt=""
                    />
                    <input
                      className="marketplace-search-textbox"
                      placeholder="Search NFT"
                    />
                  </div>
                  <div className="type-filter-box">
                    <div className="type-filter-box-left">
                      <div className="type-filter-item active">All</div>
                      <div className="type-filter-item">Buy Now</div>
                      <div className="type-filter-item">Auction</div>
                    </div>
                    <div className="type-filter-box-right">
                      <div className="dropdown-sort-type-collection"></div>
                      <div className=""></div>
                    </div>
                  </div>
                </div> */}
                <div className="marketplace-items">
                  {mBoxItemList.map((item, index) => (
                    <MBoxItemCard
                      key={index}
                      item={item}
                      mBoxInfo={mBoxInfo}
                      chainId={mBoxInfo?.chainId}
                      mBoxName={mBoxInfo?.title.en}
                      mBoxImage={mBoxInfo?.packageImage}
                      quote={quote}
                      price={mBoxInfo?.fiatPrice || mBoxInfo?.price}
                    />
                  ))}
                  <div className="list-carousel">
                    {/* {list_products.map((item, index) => (*/}
                    {/*  <div className="slide-item" key={index}>*/}
                    {/*    <Link to={'/sale'} className="button">*/}
                    {/*      <div className="hot-ollectibles-wrapper">*/}
                    {/*        <div className="header-left hot-ollectibles-item">*/}
                    {/*          <span className="total-run fw-600">*/}
                    {/*            Total Run: 35000*/}
                    {/*          </span>*/}
                    {/*        </div>*/}
                    {/*        <div className="hot-ollectibles-item">*/}
                    {/*          <div>erc721</div>*/}
                    {/*        </div>*/}
                    {/*        <div className="hot-ollectibles-item">*/}
                    {/*          <div className="img-token">*/}
                    {/*            <img src={home_11} alt="" />*/}
                    {/*          </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="hot-ollectibles-item">*/}
                    {/*          <div className="wrapper-item">*/}
                    {/*            <div className="content-left">*/}
                    {/*              <div className="avatar">*/}
                    {/*                <img src={home_13_avt} alt="" />*/}
                    {/*              </div>*/}
                    {/*              <div className="name-label">Elton John</div>*/}
                    {/*            </div>*/}
                    {/*            <div className="content-right">Buy Now</div>*/}
                    {/*          </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="hot-ollectibles-item">*/}
                    {/*          <div className="name-label">*/}
                    {/*            Elton John Rocket NFT Club Pass*/}
                    {/*          </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="hot-ollectibles-item">*/}
                    {/*          <div className="wrapper-price">*/}
                    {/*            <div className="price-header font-size-14">*/}
                    {/*              Price*/}
                    {/*            </div>*/}
                    {/*            <div className="current-price font-size-18">*/}
                    {/*              $29.99*/}
                    {/*            </div>*/}
                    {/*          </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="hot-ollectibles-item">*/}
                    {/*          <div className="wrapper-remaining">*/}
                    {/*            <div className="remaining-header font-size-14">*/}
                    {/*              Remaining{' '}*/}
                    {/*            </div>*/}
                    {/*            <div className="quantity-remaining font-size-18">*/}
                    {/*              26008*/}
                    {/*            </div>*/}
                    {/*          </div>*/}
                    {/*        </div>*/}
                    {/*      </div>*/}
                    {/*    </Link>*/}
                    {/*  </div>*/}
                    {/*))} */}
                  </div>

                  {/* not found item */}
                  {/* <div className="not-found">
                    <div className="image-not-found">
                      <img src={not_found} alt="" />
                    </div>
                    <div className="token-not-found">No tokens found...</div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <PaymentWallets
            itemInfo={mBoxInfo!}
            isCollection={false}
            show={openPaymentWallets}
            onHide={() => setOpenPaymentWallets(false)}
            openPaymentWalletsSuccess={() => setOpenPaymentWalletsSuccess(true)}
          />
          <PaymentWalletsSuccess
            itemInfo={mBoxInfo!}
            isCollection={false}
            show={openPaymentWalletsSuccess}
            onHide={handleClosePaymentWallet}
          />
          <Popup
            modal
            open={loginOpen}
            onOpen={closeSignup}
            onClose={closeLogin}
            {...{ overlayStyle, closeOnDocumentClick, lockScroll }}
          >
            {/* <LoginForm close={closeLogin} onConfirm={() => setSignupOpen(true)} /> */}
            <WalletConnector close={closeLogin} onConfirm={() => setSignupOpen(true)} />
          </Popup>
          <CSnackbar
            open={openSnackbar.open}
            type={openSnackbar.type}
            message={openSnackbar.message}
            handleClose={handleCloseSnackbar}
          />
        </div>
      ) : null}
    </main>
  );
};

export default SaleCollectibles;
