import React, { useEffect, useState } from 'react';
import close_icon from '../../assets/icon/close_icon.svg';
import close_icon_black from '../../assets/icon/close_icon_black.svg';
import ReactModal from 'react-modal';
import { useThemeMode } from '../common/AppStoreType';
import icon_metamask from '../../assets/img/metamask.svg';
import icon_walletconnect from '../../assets/img/icon_walletconnect.png';
import { abc, injected, kaikas, walletconnectv2 } from '../../hooks/connectors';
import { setActivatingConnector } from '../../redux/slices/wallet';
import env from '../../env';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import { getUA } from 'react-device-detect';
import { requestNativeLogin, requestWalletLogin, updateAddress } from '../../services/services';
import { setUser } from '../../redux/slices/user';

declare global {
  interface Window {
    appAuthCallback: any;
    webkit: any;
    TalkenInterface: any;
    klaytn: any;
  }
}
const appInitialState = {
  osType: '',
  appName: '',
  appVersion: '',
};

const ExternalWallet = ({ show, onHide, typeModal, openWinningComplete }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activate, deactivate, account, library } = useActiveWeb3React();
  const [doSign, setDoSign] = useState(false);
  const appInfo = appInitialState;
  const ua = getUA;
  const arr = ua.split(' ');
  const { isDarkMode } = useThemeMode();
  const isTalkenApp = appInfo.appName === 'Talken';

  const handleWalletLogin = async (id: number) => {
    setDoSign(true);
    try {
      // deactivate before handle wallet
      await deactivate();
      if (id === 0) {
        await activate(injected, undefined, true);
        dispatch(setActivatingConnector(injected));
        localStorage.setItem('subWallet', 'metamask');
      } else if (id === 4) {
        // const wc = walletconnect(true);
        // await activate(wc, undefined, true);
        const wc2 = walletconnectv2(env.REACT_APP_TARGET_NETWORK_KLAY);
        await activate(wc2, undefined, true);
        localStorage.setItem('subWallet', 'walletconnector');
      } else if (id === 1) {
        if (isTalkenApp) {
          // Talken App일 경우 메타마스크를 호출.
          await activate(injected, undefined, true);
          dispatch(setActivatingConnector(injected));
        } else {
          // const wc = talkenwallet(true);
          // await activate(wc, undefined, true);
          const wc2 = walletconnectv2(env.REACT_APP_TARGET_NETWORK_KLAY);
          await activate(wc2, undefined, true);
        }
      } else if (id === 2) {
        await activate(kaikas, undefined, true);
        await dispatch(setActivatingConnector(kaikas));
      } else if (id === 3) {
        await activate(abc, undefined, true);
        await dispatch(setActivatingConnector(abc));
      }
    } catch (error: any) {
      console.log(error.message);
      if (error.name === 'UnsupportedChainIdError') alert('Please check wallet Chain ID.');
      else alert(error.message);
    }
  };
  useEffect(() => {
    const walletLogin = async () => {
      if (!library || !doSign) return;
      try {
        const target_copy = Object.assign({}, library.provider);
        const isAbc = target_copy.isABC === true;
        const isKaikas = typeof target_copy._kaikas !== 'undefined';
        const chainId = await library.getSigner().getChainId();
        const message = `apps.talken.io wants you to sign in with your Ethereum account. Talken Drops Signature Request Type: Address verification`;
        const domain = { chainId }; // All properties on a domain are optional
        const types = { Mail: [{ name: 'contents', type: 'string' }] };
        const mail = { contents: message }; // The data to sign
        const signature = await library
          .getSigner()
          ._signTypedData(domain, types, mail)
          .catch(() => deactivate());
        if (!signature) return; // 서명 거부
        const msgParams = JSON.stringify({ domain, types, mail });
        const subWallet = window.localStorage.getItem('subWallet');
        const data = { msgParams, signature, subWallet };
        const res = await updateAddress(data);
        dispatch(setUser(res.data));
      } catch (error: any) {
        console.log(error.message);
      }
      setDoSign(false);
      onHide();
    };

    walletLogin();
  }, [library]);
  const handleSnsLogin = async (snsType: string) => {
    const lang = 'en';
    if (isTalkenApp && snsType !== 'apple') {
      // native app 분기
      // Google, Facebook 동일하게{ callback: "appAuthCallback" }
      // apple은 native 분기를 태우지 않는다.
      const data = Object.assign({}, { type: snsType, locale: lang }, { callback: 'appAuthCallback' });
      const _data = JSON.stringify(data);
      if (appInfo.osType === 'IOS') {
        window.webkit.messageHandlers.auth.postMessage(_data);
      } else {
        window.TalkenInterface.auth(_data);
      }
    } else {
      window.location.href = `${process.env.REACT_APP_API_SERVER}/auth/${snsType}?redirectUrl=/`;
    }
  };

  useEffect(() => {
    localStorage.removeItem('walletconnect');
    window.appAuthCallback = nativeAppAuthCallback;
    return () => {
      window.appAuthCallback = undefined;
    };
  }, []);
  const nativeAppAuthCallback = async (response: string) => {
    if (response) {
      const obj = JSON.parse(response);
      const res = await requestNativeLogin(obj);
      if (res.data === 'loginSuccess') location.replace('/');
      else if (res.data === 'notRegisterd') location.replace('/accounts/register');
      else alert('Native App Login Error!!');
    }
    console.log('No nativeAppAuthCallback response');
  };
  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className="model-connect-wallet">
        <div className="otp-code-modal">
          <div className="close-button" onClick={onHide}>
            {isDarkMode ? <img src={close_icon} alt="icon close" /> : <img src={close_icon_black} alt="icon close" />}
          </div>
          <div className="header-otp">Connect Wallet</div>
          <div className="line-banner"></div>
          <div className="list-external-wallet">
            <div className="box-item-wallet" onClick={() => handleWalletLogin(0)}>
              <div className="icon-wallet">
                <img src={icon_metamask} alt="MetaMask" />
              </div>
              <div className="name-wallet">MetaMask</div>
            </div>
            <div className="box-item-wallet" onClick={() => handleWalletLogin(4)}>
              <div className="icon-wallet">
                <img src={icon_walletconnect} alt="Icon WalletConnect" />
              </div>
              <div className="name-wallet">WalletConnect</div>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default ExternalWallet;
