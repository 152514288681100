import { useThemeMode } from 'components/common/AppStoreType';
import ReactModal from 'react-modal';
import close_icon from '../../assets/icon/close_icon.svg';
import close_icon_black from '../../assets/icon/close_icon_black.svg';
import { useEffect, useRef, useState } from 'react';
import { CircularProgress } from '@mui/material';

type TranferOTPModal = {
  show: any;
  onHide: any;
  typeModal: string;
  openWinningComplete: any;
};
const TranferOTPModal: React.FC<TranferOTPModal> = ({ show, onHide, typeModal, openWinningComplete }) => {
  const [loading, setLoading] = useState(false);
  const timer = useRef<number>();

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);
  const { isDarkMode } = useThemeMode();
  const handleConfirmModal = async () => {
    if (!loading) {
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setLoading(false);
        openWinningComplete();
        onHide();
      }, 1000);
    }
  };
  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className="otp-code-modal">
        <div className="close-button" onClick={onHide}>
          {isDarkMode ? <img src={close_icon} alt="icon close" /> : <img src={close_icon_black} alt="icon close" />}
        </div>
        <div className="header-otp">Transfer</div>
        <div className="line-banner"></div>
        <div className="body-otp">
          <div className="title-input-otp">2FA ( Google OTP Code)</div>
          <div className="wrapper-input-otp">
            <input type="text" placeholder="Enter the 6 digits" />
            <button className="btn-paste-otp button">Paste</button>
          </div>
          <button
            className="button m-0 btn-detail-nft btn-detail-nft-custom disabled"
            onClick={handleConfirmModal}
            disabled={loading}
          >
            {loading ? <CircularProgress size={30} color={'inherit'} /> : 'Send'}
          </button>
        </div>
      </div>
    </ReactModal>
  );
};
export default TranferOTPModal;
