import ReactModal from 'react-modal';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HandleMediaError } from 'components/common/HandleMediaError';

const PurchaseCompleteModal = ({ show, onHide, dataDetailMyItems }) => {
  const navigate = useNavigate();

  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onHide}
      overlayClassName="detail-nft-overlay"
      className="Modal"
      ariaHideApp={false}
    >
      <div className="modal-primary w-430px h-fit-content w-xs-338px">
        <div className="p-24 p-xs-20">
          <div className="d-flex flex-column align-items-center gap-8px">
            <div className="w-164px h-164px w-xs-120px h-xs-120px border-radius-16">
              {dataDetailMyItems?.sellbook?.nftInfo?.image?.split('.')?.pop() === 'mp4' ||
              dataDetailMyItems?.sellbook?.nftInfo?.extension === 'mp4' ||
              dataDetailMyItems?.sellbook?.nftInfo?.metadata?.image?.split('.')?.pop() === 'mp4' ||
              dataDetailMyItems?.sellbook?.nftInfo?.metadata?.extension === 'mp4' ||
              dataDetailMyItems?.sellbook?.nftInfo?.animation_url ||
              dataDetailMyItems?.sellbook?.nftInfo?.metadata?.animation_url ? (
                <video
                  muted
                  playsInline
                  webkit-playsinline="true"
                  controlsList="nodownload"
                  className="w-100 h-100 border-radius-16 thumbnail"
                  style={{ objectFit: 'cover' }}
                  onError={HandleMediaError}
                  key={`${(
                    dataDetailMyItems?.sellbook?.nftInfo?.animation_url ||
                    dataDetailMyItems?.sellbook?.nftInfo?.metadata?.animation_url ||
                    dataDetailMyItems?.sellbook?.nftInfo?.image ||
                    dataDetailMyItems?.sellbook?.nftInfo?.metadata?.image
                  )
                    ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                    .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}#t=0.001`}
                >
                  <source
                    src={`${(
                      dataDetailMyItems?.sellbook?.nftInfo?.animation_url ||
                      dataDetailMyItems?.sellbook?.nftInfo?.metadata?.animation_url ||
                      dataDetailMyItems?.sellbook?.nftInfo?.image ||
                      dataDetailMyItems?.sellbook?.nftInfo?.metadata?.image
                    )
                      ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                      .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}#t=0.001`}
                    type="video/mp4"
                  />
                </video>
              ) : (
                <img
                  src={
                    dataDetailMyItems?.sellbook?.nftInfo?.alt_url ||
                    dataDetailMyItems?.sellbook?.nftInfo?.image ||
                    dataDetailMyItems?.sellbook?.nftInfo?.metadata?.alt_url ||
                    dataDetailMyItems?.sellbook?.nftInfo?.metadata?.image
                      ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                      .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')
                  }
                  alt="icon nft"
                  className="w-100 h-100 border-radius-16"
                  style={{ objectFit: 'cover' }}
                  onError={HandleMediaError}
                />
              )}
            </div>
            <div className="text-center fs-20 fs-xs-16 fw-9 fw-xs-7 m-t-16 lh-23">
              Congratulation. The transaction has been completed successfully.
            </div>
            <button
              className="button btn-detail-nft m-t-16 m-t-xs-24 h-50px mb-0"
              onClick={() => {
                navigate('/my-items');
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};
export default PurchaseCompleteModal;
