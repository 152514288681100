import * as React from 'react';
// import { IQRCodeModalOptions, IAppRegistry } from '@walletconnect/types';
import {
    isMobile,
    formatIOSMobile,
    saveMobileLinkInfo,
    getMobileLinkRegistry,
    getWalletRegistryUrl,
    formatMobileRegistry,
} from '@walletconnect/browser-utils';

import { TextField, Box, Typography } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Header from './Header';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import QRCodeDisplay from './QRCodeDisplay';

import { TextMap } from '../types';
import { KLIP_MODAL_ID } from '../constants';
import { ChangeEvent, useState } from 'react';

interface ModalProps {
    text: TextMap;
    uri: string;
    onClose: any;
    qrcodeModalOptions?: any;
}

const modalStyle = {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '100%',
    width: 'calc(100% - 2rem)',
    bgcolor: 'common.white',
    color: 'common.black',
    border: 'none',
    borderRadius: '24px',
    boxShadow: 24,
    pt: 2,
    pb: 2,
    pl: 3,
    pr: 3,
};

function Modal(props: ModalProps) {
    const mobile = isMobile();

    const [loading, setLoading] = React.useState(false);
    const [fetched, setFetched] = React.useState(false);
    const [displayQRCode, setDisplayQRCode] = React.useState(!mobile);
    const [hasSingleLink, setHasSingleLink] = React.useState(false);
    const [singleLinkHref, setSingleLinkHref] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    // const [links, setLinks] = React.useState<IMobileRegistryEntry[]>([]);
    const [links, setLinks] = React.useState<any>([]);

    const [abcToken, setAbcToken] = React.useState('');

    const displayProps = {
        mobile,
        text: props.text,
        uri: props.uri,
        qrcodeModalOptions: props.qrcodeModalOptions,
    };

    const handleAbcTokenChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { value } = event.target;
        const numericValue = value.replace(/\D/g, '').slice(0, 6);
        // console.log(value);
        setAbcToken(numericValue);
    };

    const handlePasteFromClipboard = () => {
        navigator.clipboard.readText().then((text) => {
            setAbcToken(text);
        });
    };

    const getLinksIfNeeded = () => {
        React.useEffect(() => {
            const initLinks = async () => {
                if (fetched || loading || links.length > 0) {
                    return;
                }
                setLoading(true);
                try {
                    const url =
                        props.qrcodeModalOptions && props.qrcodeModalOptions.registryUrl
                            ? props.qrcodeModalOptions.registryUrl
                            : getWalletRegistryUrl();
                    const registryResponse = await fetch(url);
                    const registry = (await registryResponse.json()).listings;
                    const platform = mobile ? 'mobile' : 'desktop';
                    const _links = getMobileLinkRegistry(formatMobileRegistry(registry, platform), []);
                    setLoading(false);
                    setFetched(true);
                    setErrorMessage(!_links.length ? props.text.no_supported_wallets : '');
                    setLinks(_links);
                    const hasSingleLink = _links.length === 1;
                    if (hasSingleLink) {
                        setSingleLinkHref(formatIOSMobile(props.uri, _links[0]));
                        setDisplayQRCode(true);
                    }
                    setHasSingleLink(hasSingleLink);
                } catch (e) {
                    setLoading(false);
                    setFetched(true);
                    setErrorMessage(props.text.something_went_wrong);
                    console.error(e); // eslint-disable-line no-console
                }
            };
            initLinks();
        });
    };

    getLinksIfNeeded();

    return (
        <div id={KLIP_MODAL_ID} className="klip-qrcode__base animated fadeIn Modal detail-nft-overlay">
            <div className="otp-code-modal p-l-24 p-r-24 p-l-xs-20 p-r-xs-20 w-540px h-276px">
                <Header
                    onClose={() => {
                        props.onClose('');
                    }}
                />
                <div className="header-otp p-b-2 mode-color-8787d6">2FA authentication</div>
                <div className="line-banner"></div>
                <div className="p-b-32 p-b-xs-24">
                    <div className="title-input-otp mode-color-8787d6">Google OTP Code</div>
                    <div className="wrapper-input-otp">
                        <input
                            type="text"
                            placeholder="Enter the 6 digits"
                            value={abcToken}
                            onChange={handleAbcTokenChange}
                        />
                        <button className="btn-paste-otp button" onClick={handlePasteFromClipboard}>
                            Paste
                        </button>
                    </div>
                    <button
                        className={`button m-0 btn-detail-nft btn-detail-nft-custom h-50px h-xs-40px ${
                            abcToken ? '' : 'disabled'
                        }`}
                        onClick={() => {
                            props.onClose(abcToken);
                        }}
                        disabled={!abcToken}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
        // <div id={KLIP_MODAL_ID} className="klip-qrcode__base animated fadeIn">
        //     <div className="klip-modal__base">
        //         <Header
        //             onClose={() => {
        //                 props.onClose(abcToken);
        //             }}
        //         />
        //         <div className="klip-modal__single_wallet">
        //             <span className="klip-modal__description">
        //                 Please check the 6-digit code in Google Authenticator and enter it.
        //             </span>
        //             <div className="klip-modal__otp_wrapper">
        //                 <span className="klip-modal__description">Google OTP :</span>
        //                 <input
        //                     className="klip-modal__input"
        //                     placeholder="Please Enter"
        //                     // value={abcToken}
        //                     onChange={handleAbcTokenChange}
        //                 />
        //             </div>

        //             <button
        //                 className="klip-modal__button"
        //                 onClick={() => {
        //                     props.onClose(abcToken);
        //                 }}
        //             >
        //                 Confirm
        //             </button>
        //         </div>

        //         {/*<Box sx={modalStyle}>*/}
        //         {/*    Google OTP :*/}
        //         {/*    <Typography>Please check the 6-digit code in Google Authenticator and enter it.</Typography>*/}
        //         {/*    <TextField*/}
        //         {/*      sx={{ mt: 2 }}*/}
        //         {/*      inputProps={{ style: { color: '#999999' } }}*/}
        //         {/*      fullWidth*/}
        //         {/*      variant="standard"*/}
        //         {/*      label="Verification code"*/}
        //         {/*      placeholder="Please Enter"*/}
        //         {/*      // value={abcToken}*/}
        //         {/*      onChange={handleAbcTokenChange}*/}
        //         {/*    />*/}
        //         {/*</Box>*/}

        //         {/*{hasSingleLink && displayQRCode ? (*/}
        //         {/*    <div className="klip-modal__single_wallet">*/}
        //         {/*        <a*/}
        //         {/*            onClick={() => saveMobileLinkInfo({ name: links[0].name, href: singleLinkHref })}*/}
        //         {/*            href={singleLinkHref}*/}
        //         {/*            rel="noopener noreferrer"*/}
        //         {/*            target="_blank"*/}
        //         {/*        >*/}
        //         {/*            {props.text.connect_with + ' ' + (hasSingleLink ? links[0].name : '') + ' ›'}*/}
        //         {/*        </a>*/}
        //         {/*    </div>*/}
        //         {/*) : null}*/}
        //         {/*{displayQRCode || (!loading && !links.length) ? (*/}
        //         {/*    <QRCodeDisplay {...displayProps} />*/}
        //         {/*) : (*/}
        //         {/*    <div>{errorMessage}</div>*/}
        //         {/*)}*/}
        //     </div>
        // </div>
    );
}

export default Modal;
