import React, { MutableRefObject, useRef, useState, useEffect } from 'react';
import { ethers } from 'ethers';
import logo_header from '../../assets/svg/logo_header.svg';
import logo_header_lightmode from '../../assets/svg/logo_header_lightmode.svg';
import icon_insta from '../../assets/icon/instagram.png';
import icon_insta_black from '../../assets/img/icon_instagram_black.png';
import icon_twitter from '../../assets/icon/twitter.png';
import icon_twitter_black from '../../assets/img/icon_twitter_black.png';
import icon_discord from '../../assets/icon/discord.png';
import icon_discord_black from '../../assets/img/icon_discord_black.png';
import icon_ethereum from '../../assets/img/icon_ethereum.png';
import icon_binance from '../../assets/img/icon_binance.png';
import icon_polygon from 'assets/icon/icon_polygon.png';
import icon_klaytn from '../../assets/img/icon_klaytn.png';
import avatar_user from '../../assets/img/avatar_user.webp';
import search_icon from '../../assets/icon/search_icon.svg';
import wallet_blue from '../../assets/icon/wallet_blue.svg';
import wallet_white from '../../assets/icon/wallet_white.svg';
import talken_logo from '../../../src/talkclaim/talk.png';
import makeBlockie from 'ethereum-blockies-base64';
import nav_icon from '../../assets/icon/nav_icon.svg';
import nav_icon_lightmode from '../../assets/icon/nav_icon_lightmode.svg';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import 'reactjs-popup/dist/index.css';
import UsernameBox from 'components/common/UsernameBox';
import SidebarMb from 'components/sidebar/SidebarMb';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/Store';
import { useEagerConnect, useInactiveListener } from '../../hooks/useWallet';
import { useSidebarStore } from 'components/common/AppStore';
import useScreenSize from 'components/common/useScreenSize';
import splitAddress from '../../utils/splitAddress';
import { splitString } from '../../utils/splitString';
import { useThemeMode } from 'components/common/AppStoreType';
import { getUA } from 'react-device-detect';
import HelperUtil from 'utils/HelperUtil';
import tokenAbi from '../../config/abi/ERC20Token.json';
import { ReactComponent as IconDropdown } from 'assets/icon/arrow_down.svg';
import { ReactComponent as LogIn } from '../../assets/svg/icon_login.svg';

const isProduction = process.env.REACT_APP_PHASE === 'production';
const appInitialState = {
  osType: '',
  appName: '',
  appVersion: '',
};
const Navbar = () => {
  const ref = useRef() as MutableRefObject<HTMLButtonElement>;
  const [isModalOpen, setModalOpen] = useState(false);
  const { openSidebar } = useSidebarStore();
  const wallets = useSelector((state: any) => state.wallet);
  const user = useSelector((state: any) => state.user.user);
  const userEthAddr = user.eth_address?.toLowerCase();
  const userAbcAddr = user.abc_address?.toLowerCase();
  const isLogin = user.uid !== '';
  const isEthAddress = ethers.utils.isAddress(userEthAddr);
  // useOnClickOutside(ref, () => setModalOpen(false));
  const [open, setOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [signupOpen, setSignupOpen] = useState(false);
  const [scroll, setScroll] = useState(false);
  const { activatingConnector } = useSelector((state: RootState) => state.wallet);
  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager || !!activatingConnector);
  const appInfo = appInitialState;
  const ua = getUA;
  const arr = ua.split(' ');
  const osType = arr.find((el) => {
    return el.indexOf('osType:') > -1;
  });
  const appName = arr.find((el) => {
    return el.indexOf('appName:') > -1;
  });
  const appVersion = arr.find((el) => {
    return el.indexOf('appVersion:') > -1;
  });
  if (osType) appInfo.osType = osType.split(':')[1];
  if (appName) appInfo.appName = appName.split(':')[1];
  if (appVersion) appInfo.appVersion = appVersion.split(':')[1];
  const isTalkenApp = appInfo.appName === 'Talken';
  const screenSize = useScreenSize();
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleScroll = () => {
    if (window.scrollY > 66) {
      setScroll(true);
    } else {
      setScroll(false);
    }
    const nav = document.querySelector('.nav-home');
    nav?.classList.toggle('bg-scroll', screenSize > 1024 ? window.scrollY > 500 : window.scrollY > 200);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const { isDarkMode } = useThemeMode();
  const scrollToTop = () => {
    localStorage.removeItem('scrollPosition');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const isProduction = process.env.REACT_APP_PHASE === 'production';

  return (
    <div className={scroll ? 'nav-bar' : 'nav-bar scroll-top'} onMouseLeave={() => setModalOpen(false)}>
      <div
        className={`${location.pathname === '/' ? 'nav-home' : 'nav-other-page'}`}
        // style={
        //   isDarkMode
        //     ? {
        //         backgroundImage: `linear-gradient(
        //         rgb(0, 0, 0),
        //         rgb(0, 0, 0),
        //         rgba(0, 0, 0, 0.75),
        //         rgba(0, 0, 0, 0)
        //       )`,
        //       }
        //     : {
        //         backgroundImage: `linear-gradient(
        //       rgb(255, 255, 255),
        //       rgb(255, 255, 255),
        //       rgba(255, 255, 255, 0.75),
        //       rgba(255, 255, 255, 255)
        //     )`,
        //       }
        // }
      ></div>
      <div className="main-header-box">
        <div className="logo-header">
          <Link to={'/'} className="custom-logo-header" onClick={scrollToTop}>
            {isDarkMode ? <img src={logo_header} alt="" /> : <img src={logo_header_lightmode} alt="" />}
          </Link>
          {/* <div className="header-sub-div hidden-mobile">
            <a
              href="https://studio.talken.io/"
              target={'_self'}
              className={`${isDarkMode ? 'color-darkmode' : 'color-lightmode'}`}
            >
              Studio
            </a>
            <a
              href="https://talken.io/main"
              target={'_self'}
              className={`${isDarkMode ? 'color-darkmode' : 'color-lightmode'}`}
            >
              Community
            </a>
          </div> */}
          <div className="line-vertical"></div>
          <div className="header-drops-creator">
            {screenSize > 768 && (
              <NavLink to="/drops" className={(navData) => (navData.isActive ? 'active-header' : '')}>
                Drops
              </NavLink>
            )}
            <NavLink to="/market" className={(navData) => (navData.isActive ? 'active-header' : '')}>
              Market
            </NavLink>
            <>
              <a className={location.pathname === '/bridge' ? 'active-header' : ''} href="/bridge">
                Bridge
              </a>
            </>
            <NavLink
              to={isLogin ? '/rewards' : '/accounts?redirectUrl=/rewards'}
              className={(navData) => (navData.isActive ? 'active-header' : '')}
            >
              Rewards
            </NavLink>
            <NavLink to="/faucet" className={(navData) => (navData.isActive ? 'active-header' : '')}>
              Faucet
            </NavLink>
          </div>
        </div>
        <div className={`nav-right ${!isLogin && screenSize < 640 ? 'mt-0' : ''}`}>
          {/* <div className="contact-header">
            <a
              href={isTalkenApp ? 'https://talken.io/static/intro/en.html' : 'https://talken.io/'}
              target={'_blank'}
              className={`${isDarkMode ? 'color-darkmode' : 'color-lightmode'}`}
            >
              About
            </a>
            <a
              href="https://talken-io.medium.com/"
              target={'_blank'}
              className={`${isDarkMode ? 'color-darkmode' : 'color-lightmode'}`}
            >
              Blog
            </a>
            <a
              href="https://docs.talken.io/talken-docs/"
              target={'_blank'}
              className={`${isDarkMode ? 'color-darkmode' : 'color-lightmode'}`}
            >
              Docs
            </a>
            <a
              href="https://talkensupport.zendesk.com/hc/en-us/requests/new"
              target={'_blank'}
              className={`${isDarkMode ? 'color-darkmode' : 'color-lightmode'}`}
            >
              Help
            </a>
            {!isProduction && (
              <div
                className={`${isDarkMode ? 'color-darkmode' : 'color-lightmode'}`}
                onClick={async () => {
                  const abcUser = JSON.parse(window.localStorage.getItem('abcUser'));
                  const ethAddress = abcUser?.accounts[0]?.ethAddress;
                  const provider = await HelperUtil.getAbcWeb3Provider();
                  // console.log('provider:::', provider);
                  const signer = provider.getSigner();
                  console.log('ethAddress:::', ethAddress);
                  // const contract = new ethers.Contract('0x8BEbcD85a7F99679be82eD11d61a52020EFAfB4C', tokenAbi, signer);
                  // const claimBalanceTxn = await contract.balanceOf('0x34dcdc13ba31de50d9709e3804ebdd5a5b17a7bb');
                  // console.log('asdasdasdasd3', claimBalanceTxn);
                  const params = [
                    {
                      from: '0x55F04E986eB305A65cB570791EED353f0a1D031e',
                      to: '0x55F04E986eB305A65cB570791EED353f0a1D031e',
                      value: ethers.utils.parseUnits('0.1', 'ether').toHexString(),
                      gasLimit: '21000',
                    },
                  ];
                  const transactionHash = await provider.send('eth_sendTransaction', params);
                  console.log('transactionHash is ' + transactionHash);
                }}
              >
                test
              </div>
            )}
          </div>
          <div
            className="fanpage-icons"
            style={isDarkMode ? { borderLeft: '1px solid #ffffff' } : { borderLeft: '1px solid #1f1f1f' }}
          >
            <a href="https://twitter.com/Talken_" target={'_blank'} rel="noreferrer">
              {isDarkMode ? <img src={icon_twitter} alt="" /> : <img src={icon_twitter_black} alt="" />}
            </a>
            <a href="https://discord.gg/S33c5DA9cW" target={'_blank'} rel="noreferrer">
              {isDarkMode ? <img src={icon_discord} alt="" /> : <img src={icon_discord_black} alt="" />}
            </a>
            <a href="https://www.instagram.com/talken_nft" target={'_blank'} rel="noreferrer">
              {isDarkMode ? <img src={icon_insta} alt="" /> : <img src={icon_insta_black} alt="" />}
            </a>
          </div> */}

          {isLogin ? (
            // <div
            //   className="wallet-connected"
            //   onClick={() => setLoginOpen(true)}
            //   style={isDarkMode ? { color: '#ffffff' } : { color: '#1f1f1f' }}
            // >
            //   {/* {wallets.ethereum.address && wallets.ethereum.address !== '' && (
            //     <button type="button" className="item-wallet button">
            //       <img src={icon_ethereum} />
            //     </button>
            //   )}
            //   {wallets.binance.address && wallets.binance.address !== '' && (
            //     <button type="button" className="item-wallet button">
            //       <img src={icon_binance} />
            //     </button>
            //   )} */}
            //   {userEthAddr && (
            //     <button type="button" className="item-wallet button">
            //       <img src={icon_klaytn} />
            //     </button>
            //   )}
            //   <p className="account">{isEthAddress ? splitAddress(userEthAddr) : null}</p>
            // </div>
            ''
          ) : (
            <div className={`btn-wallets ${!isLogin && screenSize > 640 ? 'right-40' : 'mr-20'}`}>
              <button
                className="custom-btn button hidden-mobile"
                style={{ whiteSpace: 'nowrap' }}
                onClick={() => navigate(`/accounts?redirectUrl=${location.pathname}`)}
              >
                <div className="wrapper-btn">
                  <div className="wallets">Log In</div>
                </div>
              </button>
              {screenSize < 641 && (
                <button className="custom-btn custom-btn-login button hidden-pc" onClick={() => navigate('/accounts')}>
                  <div className="wrapper-btn btn-mobile">
                    <LogIn />
                  </div>
                </button>
              )}
            </div>
          )}
          {isLogin &&
            (screenSize > 640 ? (
              <>
                <div
                  className="wrapper-user"
                  onMouseEnter={() => setModalOpen(true)}
                  style={isDarkMode ? { color: '#ffffff' } : { color: '#1f1f1f' }}
                >
                  <div className="avatar-user">
                    <img
                      src={
                        user.profile_image ||
                        (isEthAddress ? makeBlockie(userEthAddr) : userAbcAddr ? makeBlockie(userAbcAddr) : talken_logo)
                      }
                      alt="profile-avatar"
                    />
                  </div>
                  {/*<p className="user-name mb-0">{splitString(user.name || '')}</p>*/}
                  <p className="user-name mb-0">{user.name || ''}</p>
                </div>
                <button
                  ref={ref}
                  className="username-dropdown button dark:fill-white fill-black"
                  onClick={() => setModalOpen(!isModalOpen)}
                >
                  <IconDropdown />
                  {/* {isDarkMode ? (
                  <svg
                    className="sc-196ec885-12 eKhfKP"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.092"
                    height="11.168"
                    viewBox="0 0 18.092 11.168"
                    opacity="0.8"
                  >
                    <path
                      id="Path_46142"
                      data-name="Path 46142"
                      d="M-10858.465-7358l6.925,6.926,6.925-6.926"
                      transform="translate(10860.586 7360.121)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeWidth="3"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    className="sc-196ec885-12 eKhfKP"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.092"
                    height="11.168"
                    viewBox="0 0 18.092 11.168"
                    opacity="0.8"
                  >
                    <path
                      id="Path_46142"
                      data-name="Path 46142"
                      d="M-10858.465-7358l6.925,6.926,6.925-6.926"
                      transform="translate(10860.586 7360.121)"
                      fill="none"
                      stroke="#1f1f1f"
                      strokeLinecap="round"
                      strokeWidth="3"
                    ></path>
                  </svg>
                )} */}
                </button>
                {/* user dropdown box */}
                <div onMouseLeave={() => setModalOpen(false)}>{isModalOpen && <UsernameBox />}</div>
              </>
            ) : (
              <div className="icon-nav">
                <button className="button flex-center" onClick={openSidebar}>
                  {isDarkMode ? (
                    <svg width="18" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="2" y="5" width="21" height="3" rx="1.5" fill="white" />
                      <rect x="2" y="11" width="21" height="3" rx="1.5" fill="white" />
                      <rect x="2" y="17" width="21" height="3" rx="1.5" fill="white" />
                    </svg>
                  ) : (
                    <svg width="18" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="2" y="5" width="21" height="3" rx="1.5" fill="black" />
                      <rect x="2" y="11" width="21" height="3" rx="1.5" fill="black" />
                      <rect x="2" y="17" width="21" height="3" rx="1.5" fill="black" />
                    </svg>
                  )}
                  {/* side bar */}
                </button>
                <SidebarMb />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
