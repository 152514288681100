import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from 'locales/langs/en.json';
import ko from 'locales/langs/ko.json';

const resources = {
  en: { translation: en },
  ko: { translation: ko },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || 'ko',
  fallbackLng: 'ko',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
